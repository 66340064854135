import {
	Anchor,
	Box,
	createStyles,
	Divider,
	Group,
	Stack,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
	useCreatePersona,
	useDeletePersona,
	useGetAiCustomModel,
	useGetPersonas,
	useSetAiCustomModel,
	useWorkspaceAiFeedback,
} from '@repo/api-codegen';
import {
	SettingsBlock,
	SettingsButton,
	SettingsSelect,
	SettingsSwitch,
} from '@repo/common/components';
import { AIAvatar } from '@repo/common/components/AI/Avatar';
import MarkdownRenderer from '@repo/common/components/MarkdownRenderer';
import {
	getToolDescription,
	getToolName,
} from '@repo/common/constants/aiTools';
import { Button, Text, TextArea, Title } from '@repo/foundations';
import dayjs from 'dayjs';
import { capitalize, toLower } from 'lodash-es';
import { useCallback, useState } from 'react';
import {
	useAgentTools,
	useAIPrivacySettings,
	useAuthUser,
	useDisableAgentTool,
	useEnableAgentTool,
	useGetAgentInstructions,
	useGetCustomDescriptionStyle,
	useToggleAIPrivacySetting,
	useUpdateAgentInstructions,
	useUpdateCustomDescriptionStyle,
} from '../../api';
import { useAIMemory } from '../../api/hooks/ai/useAIMemory';
import { useUpdateWorkspace } from '../../api/hooks/workspace/useUpdateWorkspace';
import type { IAgentTool } from '../../api/types';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import { useFeatureFlags } from '../../utils/featureFlags';
import { sentenceCase } from '../../utils/stringUtils';
import { closeAllModals, openModal } from '../ModalManager';
import { AIPersonaCard } from './AIPersonas/AIPersonaCard';
import { AIGovernanceFilters } from './GovernanceFilters/AIGovernanceFilters';

function ToolsSettings({ aiMemory }: { aiMemory: boolean }) {
	const { data: tools } = useAgentTools();
	const { mutateAsync: enableAgentTool } = useEnableAgentTool();
	const { mutateAsync: disableAgentTool } = useDisableAgentTool();

	const memoryTools = {
		toolNames: ['add_memory', 'delete_memory'],
		tools: [] as IAgentTool[],
		uiName: 'add_memory',
	};
	const docsTools = {
		toolNames: ['get_secoda_docs'],
		tools: [] as IAgentTool[],
		uiName: 'get_secoda_docs',
	};
	const sqlTools = {
		toolNames: ['run_sql'],
		tools: [] as IAgentTool[],
		uiName: 'run_sql',
	};

	tools?.tools.map((tool) => {
		if (memoryTools.toolNames.includes(tool.name)) {
			memoryTools.tools.push(tool);
		}

		if (docsTools.toolNames.includes(tool.name)) {
			docsTools.tools.push(tool);
		}

		if (sqlTools.toolNames.includes(tool.name)) {
			sqlTools.tools.push(tool);
		}
	});

	const renderTool = (config: {
		tools: IAgentTool[];
		uiName: string;
		toolNames: string[];
	}) => {
		if (config.uiName === memoryTools.uiName && !aiMemory) return null;
		const tool = config.tools.find((tool) =>
			config.toolNames.includes(tool.name)
		);
		if (!tool) return null;

		return (
			<SettingsSwitch
				disabled={false}
				key={tool.name}
				title={getToolName(
					tool.name,
					capitalize(toLower(sentenceCase(tool.name)))
				)}
				checked={tool.enabled}
				description={getToolDescription(tool.name, tool.frontend_description)}
				onChange={() => {
					const action = !tool.enabled ? enableAgentTool : disableAgentTool;
					// Toggle UI first to avoid delay
					action({ tool });
					config.tools.forEach((toggle) => {
						if (toggle.name !== tool.name) {
							action({ tool: toggle });
						}
					});
				}}
			/>
		);
	};

	return (
		<>
			{[sqlTools, docsTools, memoryTools].map((config) => renderTool(config))}
		</>
	);
}

const useStyles = createStyles((theme) => ({
	textBox: {
		'& textarea': {
			resize: 'vertical',
		},
	},
	saveButton: {
		marginTop: theme.spacing.md,
		marginBottom: theme.spacing.md,
		display: 'block',
		marginLeft: 'auto',
	},
	anchor: {
		textDecoration: 'underline',
		color: 'inherit',
	},
}));

function CustomInstructionsModal() {
	const { data: remoteInstructions } = useGetAgentInstructions();
	const { data: remoteDescriptionStyle } = useGetCustomDescriptionStyle();
	const { mutateAsync: updateAgentInstructions } = useUpdateAgentInstructions();
	const { mutateAsync: updateCustomDescriptionStyle } =
		useUpdateCustomDescriptionStyle();

	const [instructions, setInstructions] = useState<string>(
		remoteInstructions ?? ''
	);
	const [descriptionStyle, setDescriptionStyle] = useState<string>(
		remoteDescriptionStyle ?? ''
	);

	const { classes } = useStyles();

	const notifySuccessOnSave = useCallback((type: string) => {
		showNotification({
			title: `${type} saved`,
			message: '',
			autoClose: 1500,
		});
	}, []);

	const notifyErrorOnSave = useCallback(() => {
		showNotification({
			title: 'Something went wrong',
			message: 'Please enter the instructions again',
			autoClose: 1500,
		});
	}, []);

	const handleSave = async () => {
		try {
			await updateCustomDescriptionStyle({ instructions: descriptionStyle }),
				await updateAgentInstructions({ instructions }),
				notifySuccessOnSave('Secoda AI custom instructions');
			closeAllModals();
		} catch {
			notifyErrorOnSave();
		}
	};

	return (
		<>
			<Title size="sm" weight="semibold">
				Custom chat instructions{' '}
				<Text size="sm" span color="text/secondary/default">
					(Optional)
				</Text>
			</Title>
			<TextArea
				minRows={3}
				className={classes.textBox}
				value={instructions}
				onChange={(e) => setInstructions(e.target.value)}
			/>
			<Text size="sm" mb="sm" color="text/secondary/default">
				Provide custom instructions to Secoda AI. These instructions will be
				injected into the prompt.
			</Text>
			<Title size="sm" weight="semibold">
				Custom description instructions{' '}
				<Text size="sm" span color="text/secondary/default">
					(Optional)
				</Text>
			</Title>
			<TextArea
				minRows={3}
				className={classes.textBox}
				value={descriptionStyle}
				onChange={(e) => setDescriptionStyle(e.target.value)}
			/>
			<Text size="sm" mb="sm" color="text/secondary/default">
				Custom instructions for Secoda AI when generating descriptions for your
				resources.
			</Text>
			<Divider mb="lg" />
			<Group align="center" position="right" spacing="xs">
				<Button
					variant="default"
					onClick={() => {
						closeAllModals();
					}}
				>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleSave}>
					Save
				</Button>
			</Group>
		</>
	);
}

export function AIToolsSettings() {
	const { workspace } = useAuthUser();
	const { aiPersonas, allowXaiLlm, aiMemory, aiV3 } = useFeatureFlags();
	const { data: privacySettings } = useAIPrivacySettings();
	const { mutateAsync: toggleAIPrivacySetting } = useToggleAIPrivacySetting();
	const { mutateAsync: updateWorkspace } = useUpdateWorkspace(workspace.id);
	const { data: aiFeedback } = useWorkspaceAiFeedback({});
	const {
		data: customModel,
		isLoading: isLoadingModel,
		refetch: refetchModel,
	} = useGetAiCustomModel({});
	const enableAi = useAiEnabled();
	const { mutateAsync: setCustomModel } = useSetAiCustomModel({
		onSuccess: () => {
			refetchModel();
		},
	});

	const { classes } = useStyles();

	const { showMemoriesModal } = useAIMemory();

	const notifySuccessOnSave = useCallback((type: string) => {
		showNotification({
			title: `${type} saved`,
			message: '',
			autoClose: 1500,
		});
	}, []);

	const notifyErrorOnSave = useCallback(() => {
		showNotification({
			title: 'Something went wrong',
			message: 'Please enter the instructions again',
			autoClose: 1500,
		});
	}, []);

	const handleModelChange = useCallback(
		async (value: string) => {
			try {
				await setCustomModel({
					body: {
						model: value,
					},
				});
				notifySuccessOnSave('AI model');
			} catch {
				notifyErrorOnSave();
			}
		},
		[setCustomModel, notifySuccessOnSave, notifyErrorOnSave]
	);

	const showCustomInstructionsModal = () => {
		const id = 'custom-instructions-modal';
		openModal({
			title: 'Custom instructions',
			id,
			children: <CustomInstructionsModal />,
		});
	};

	const { data: personas, refetch } = useGetPersonas(
		{
			queryParams: {
				orderBy: 'created_at',
				order: 'desc',
			},
		},
		{
			select: (data) =>
				data.sort((a, b) => dayjs(b.created_at).diff(dayjs(a.created_at))),
		}
	);

	const { mutateAsync: createPersona } = useCreatePersona();
	const { mutateAsync: deletePersona } = useDeletePersona();

	const handleCreatePersona = useCallback(async () => {
		await createPersona({});
		await refetch();
	}, [createPersona, refetch]);

	const handleDeletePersona = useCallback(
		async (id: string) => {
			await deletePersona({
				pathParams: {
					personaId: id || '',
				},
			});
			await refetch();
		},
		[deletePersona, refetch]
	);

	return (
		<Stack spacing={0}>
			<Box mb="4xl">
				<SettingsSwitch
					leftComponent={<AIAvatar size="sm" speed="slow" />}
					onChange={(checked) =>
						updateWorkspace({
							data: { id: workspace.id, ai_search: checked },
						})
					}
					checked={workspace?.ai_search ?? false}
					title="Secoda AI"
					description={
						<>
							By using Secoda AI you agree to our{' '}
							<Anchor
								href="https://docs.secoda.co/policies/privacy-policy"
								target="_blank"
								className={classes.anchor}
							>
								Privacy Policy
							</Anchor>{' '}
							and our usage of{' '}
							<Anchor
								href="https://docs.secoda.co/features/ai-assistant/security"
								target="_blank"
								className={classes.anchor}
							>
								OpenAI
							</Anchor>{' '}
							and{' '}
							<Anchor
								href="https://docs.secoda.co/features/ai-assistant/security"
								target="_blank"
								className={classes.anchor}
							>
								Anthropic
							</Anchor>
						</>
					}
				/>
				{enableAi && !aiV3 && (
					<SettingsSelect
						title="Model"
						loading={isLoadingModel}
						description="Select the LLM Secoda AI uses for answers, documentation, and tasks"
						value={customModel?.model ?? 'anthropic.claude-3-7-sonnet-latest'}
						onChange={(value) => handleModelChange(value as string)}
						options={[
							{ value: 'openai.gpt-4.5-preview', label: 'GPT 4.5' },
							{
								value: 'anthropic.claude-3-7-sonnet-latest',
								label: 'Claude 3.7 Sonnet',
							},
							...(allowXaiLlm
								? [{ value: 'xai.grok-beta', label: 'Grok Beta' }]
								: []),
						]}
					/>
				)}
			</Box>

			{enableAi && (
				<Box>
					<Title size="md">Resource access</Title>
					<Text size="sm" color="text/secondary/default" mb="md">
						Create rules to include and exclude resources from Secoda AI
					</Text>
					<Box mb="4xl">
						<SettingsSwitch
							disabled={false}
							key={'ai_mask_pii'}
							title={'Mask PII content'}
							checked={
								privacySettings?.mask_pii_on_response_generation || false
							}
							description={
								'When querying resources, try to mask data marked as PII before being processed by Secoda AI'
							}
							onChange={() => {
								const val = !privacySettings?.mask_pii_on_response_generation;
								toggleAIPrivacySetting({
									key: 'mask_pii_on_response_generation',
									value: val,
								});
							}}
						/>
						<SettingsBlock title="">
							<AIGovernanceFilters />
						</SettingsBlock>
					</Box>
					<Title size="md" mb="md">
						Tools
					</Title>
					<Box mb="4xl">
						<ToolsSettings aiMemory={aiMemory} />
					</Box>
					<Title size="md" mb="md">
						Customize
					</Title>
					<Box mb="4xl">
						<SettingsButton
							title="Custom instructions"
							description="Add custom instructions for chat and descriptions. These settings are applied to all users"
							buttonText="Manage instructions"
							onClick={showCustomInstructionsModal}
						/>
						{aiMemory && (
							<SettingsButton
								title="Memory"
								description="Secoda AI will become more helpful as you chat, picking up on details and preferences to tailor its responses to you"
								buttonText="Manage memories"
								onClick={showMemoriesModal}
							/>
						)}
					</Box>

					{aiPersonas && (
						<>
							<Title size="md" mb="md">
								Personas
							</Title>
							<Box mb="4xl">
								<SettingsButton
									iconName="plus"
									variant="tertiary"
									title={`${personas?.length || 0} ${
										personas?.length === 1 ? 'persona' : 'personas'
									}`}
									onClick={handleCreatePersona}
									aria-label="Create persona"
									tooltip="Create persona"
								/>
								{personas?.map((persona) => (
									<AIPersonaCard
										key={persona.id}
										id={persona.id}
										name={persona.name}
										description={persona.description}
										avatarURL={persona.avatar_url}
										customInstructions={persona.custom_instructions}
										onDelete={handleDeletePersona}
										teams={persona.teams}
									/>
								))}
							</Box>
						</>
					)}
					<Box mt="xl">
						{aiFeedback && (
							<SettingsBlock
								title="Suggestions"
								description="Automated tips to improve the quality of your AI responses"
							>
								<MarkdownRenderer>
									{aiFeedback?.documentation_suggestions || ''}
								</MarkdownRenderer>
								<MarkdownRenderer>
									{aiFeedback?.blind_spots || ''}
								</MarkdownRenderer>
							</SettingsBlock>
						)}
					</Box>
				</Box>
			)}
		</Stack>
	);
}
