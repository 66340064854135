import { Divider, Group, Stack } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { showNotification } from '@mantine/notifications';
import type { DataAccessRequestCreateIn } from '@repo/api-codegen';
import {
	apiQueryKey,
	useCreateAccessRequest,
	useUpdateAccessRequest,
} from '@repo/api-codegen';
import { integrationList } from '@repo/common/constants/integration/integrations';
import { Banner, Button, Select, Text, TextArea } from '@repo/foundations';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { queryClient, useIntegrationList } from '../../../api';
import IntegrationLogo from '../../IntegrationLogo';
import { ResourceSelector } from './ResourceSelector';

export interface DataAccessRequestCreateModalProps {
	onClose: () => void;
	request?: Partial<DataAccessRequestCreateIn> & { id?: string };
	withIntegrationSelector?: boolean;
	withResourcesSelector?: boolean;
}

export function DataAccessRequestCreateModal({
	onClose,
	request,
	withIntegrationSelector = true,
	withResourcesSelector = true,
}: DataAccessRequestCreateModalProps) {
	const { data: integrationsResult } = useIntegrationList({});

	const { mutate: createAccessRequest, isLoading } = useCreateAccessRequest({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: apiQueryKey('integration/data-access-requests'),
			});
			showNotification({
				title: 'Access request created',
				message: 'Your request has been created and is pending approval',
				color: 'green',
			});
		},
	});

	const { mutate: updateAccessRequest } = useUpdateAccessRequest({
		onSuccess: () => {
			showNotification({
				title: 'Access request updated',
				message: 'Your request has been updated',
				color: 'green',
			});
		},
	});

	const integrationOptions = useMemo(
		() =>
			(integrationsResult?.results ?? [])
				.filter(
					(integration) =>
						integrationList.find(
							(localIntegration) =>
								localIntegration.type === integration.type &&
								localIntegration.supportDataAccessRequest
						) && integration.access_request_enabled
				)
				.map((integration) => ({
					label: integration.name,
					value: integration.id,
					icon: (
						<IntegrationLogo integrationType={integration.type} size={16} />
					),
				})),
		[integrationsResult]
	);

	const formik = useFormik({
		initialValues: {
			integrationId: request?.integration_id,
			resources: request?.requested_resources,
			duration: request?.requested_expires_at
				? dayjs(request.requested_expires_at).toDate()
				: undefined,
			reason: request?.requested_text ?? '',
		},
		validationSchema: Yup.object().shape({
			integrationId: Yup.string(),
			resources: Yup.array(),
			duration: Yup.date().nullable(),
		}),
		validateOnBlur: false,
		validateOnChange: true,
		onSubmit: async (values) => {
			if (!values.resources || values.resources.length === 0) {
				formik.setFieldError('resources', 'At least one resource is required');
				return;
			}

			if (request?.id) {
				await updateAccessRequest({
					pathParams: {
						requestId: request.id,
					},
					body: {
						integration_id: values.integrationId!,
						requested_resources: values.resources,
						requested_expires_at: values.duration
							? dayjs(values.duration).toISOString()
							: null,
						requested_text: values.reason,
					},
				});
			} else {
				await createAccessRequest({
					body: {
						integration_id: values.integrationId!,
						requested_resources: values.resources,
						requested_expires_at: values.duration
							? dayjs(values.duration).toISOString()
							: null,
						requested_text: values.reason,
					},
				});
			}
			onClose();
		},
	});

	useEffect(() => {
		if (!formik.values.integrationId && integrationOptions?.length === 1) {
			formik.setFieldValue('integrationId', integrationOptions[0].value);
		}
	}, [formik, integrationOptions]);

	const handleClose = useCallback(() => {
		formik.resetForm();
		onClose();
	}, [formik, onClose]);

	return (
		<form onSubmit={formik.handleSubmit}>
			<Stack spacing="md">
				{withIntegrationSelector && (
					<>
						{integrationOptions.length === 0 && (
							<Banner
								tone="warning"
								message="Access requests are not enabled for any integrations. Contact admin for access."
								inCard
							/>
						)}
						<Select
							data={integrationOptions}
							label="Integration"
							name="integrationId"
							placeholder="Select"
							value={formik.values.integrationId}
							error={formik.errors.integrationId}
							onChange={(value) => {
								formik.setFieldValue('integrationId', value);
								formik.setFieldValue('resources', [], true);
							}}
							onBlur={formik.handleBlur}
						/>
					</>
				)}
				{withResourcesSelector && (
					<ResourceSelector
						integrationId={formik.values.integrationId}
						label="Resources"
						name="resources"
						placeholder="Select"
						initialSelectedValues={formik.values.resources ?? []}
						error={formik.errors.resources}
						onChange={(resourcesValue) => {
							formik.setFieldValue('resources', resourcesValue, true);
							formik.validateField('resources');
						}}
						onBlur={formik.handleBlur}
						disabled={!formik.values.integrationId}
					/>
				)}
				<DateTimePicker
					label="Access end date"
					placeholder="Indefinite"
					name="duration"
					value={formik.values.duration}
					onChange={(durationValue) =>
						formik.setFieldValue('duration', durationValue, true)
					}
					clearable
					error={formik.errors.duration}
					onBlur={formik.handleBlur}
					popoverProps={{
						withinPortal: true,
					}}
				/>
				<TextArea
					name="reason"
					label="Reason"
					optional
					minRows={3}
					value={formik.values.reason}
					error={formik.errors.reason}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
				/>
			</Stack>
			<Divider my="md" orientation="horizontal" />
			<Group spacing="md" position="apart" align="baseline">
				<Text size="sm">
					Learn more about{' '}
					<a
						href="https://docs.secoda.co/features/access-requests"
						target="_blank"
					>
						access requests
					</a>
				</Text>
				<Group spacing="xs">
					<Button onClick={handleClose}>Cancel</Button>
					<Button
						variant="primary"
						type="submit"
						disabled={
							isLoading ||
							!formik.isValid ||
							(formik.values.resources?.length || 0) === 0
						}
						loading={isLoading}
					>
						{request?.id ? 'Update request' : 'Create request'}
					</Button>
				</Group>
			</Group>
		</form>
	);
}
