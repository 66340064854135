import {
	Box,
	createStyles,
	Group,
	Menu,
	Tooltip,
	UnstyledButton,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import { Icon, IconButton, Text } from '@repo/foundations';
import type { ITag } from '../../../../api';
import {
	invalidateTagList,
	queryClient,
	tagsQueryKeyFactory,
	useDeleteTag,
	useTagGroupList,
	useUpdateTag,
} from '../../../../api';
import IntegrationLogo from '../../../IntegrationLogo';
import {
	closeAllModals,
	openDeleteConfirmModal,
	openModal,
} from '../../../ModalManager';
import { TagModal } from '../Modal/TagModal';

interface ITagCardProps {
	tag: ITag;
	allTags: Array<string>;
	propertyLabel?: string;
	customPropertyId?: string;
}

const useStyles = createStyles(
	(theme, { hasDescription }: { hasDescription: boolean }) => ({
		container: {
			padding: theme.spacing.sm,
			paddingBottom: hasDescription ? 0 : theme.spacing.sm,
			height: theme.other.space[10],
		},
		descriptionText: {
			// Left padding is custom to aligned with the title tag above the
			// description.
			paddingLeft: theme.other.space[10],
			paddingRight: theme.spacing.sm,
			paddingTop: 0,
			paddingBottom: theme.spacing.sm,
		},
	})
);

function TagCard({
	tag,
	propertyLabel,
	allTags,
	customPropertyId,
}: ITagCardProps) {
	const { theme, classes } = useStyles({ hasDescription: !!tag.description });

	const { hovered, ref } = useHover();

	const { mutateAsync: deleteTag } = useDeleteTag({
		options: {
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: tagsQueryKeyFactory.list(),
				});
			},
		},
	});

	const { data: tagGroups } = useTagGroupList({
		options: {
			select: (data) =>
				data.results.sort((a, b) => a.name.localeCompare(b.name)),
		},
	});

	const { mutateAsync: updateTag } = useUpdateTag({
		options: {
			onSuccess: () => invalidateTagList(),
		},
		disableOptimisticUpdate: true,
		disableInvalidation: true,
	});

	const handleUpdate = (tagGroupID: string) => {
		updateTag({
			data: {
				id: tag.id,
				tag_group_id: tagGroupID,
			},
		});
	};

	const handleToggleVisibility = () =>
		updateTag({
			data: {
				id: tag.id,
				visible: !tag.visible,
			},
		});

	const openDeleteModal = () =>
		openDeleteConfirmModal({
			title: `Delete ${tag.name}`,
			description:
				"This tag will be removed from all resources. This action can't be undone.",
			confirmLabel: 'Delete',
			onConfirm: () => {
				deleteTag({
					id: tag.id,
				});
			},
		});

	const tagGroupsExist = tagGroups && tagGroups.length !== 0;
	const currentTagGroup =
		tagGroupsExist && tag.tag_group_id
			? tagGroups.find((tg) => tg.id === tag.tag_group_id)
			: null;
	const tagFromIntegration = !!tag?.integration;

	const openEditModal = () => {
		openModal({
			title: `Edit ${tag.name}`,
			children: (
				<TagModal tag={tag} allTags={allTags} onClose={closeAllModals} />
			),
		});
	};

	return (
		<>
			<Group className={classes.container} ref={ref} position="apart" noWrap>
				<Group spacing="xs" noWrap>
					<Icon
						name="circle"
						style={{
							fill: tag.color,
							color: tag.color,
							width: 12,
							stroke: 'none',
						}}
					/>
					<Text size="sm" weight="bold">
						{tag.name}
					</Text>
					{tagFromIntegration && (
						<Box p="xs">
							<RichTooltip
								maxWidth={theme.other.space[60]}
								title={`Tag comes from ${tag?.integration.name}`}
								body={
									<Text size="sm" color={'text/secondary/default'}>
										To maintain in Secoda, go to integration settings.
									</Text>
								}
								linkLabel={`${tag?.integration.name} settings`}
								linkUrl={`/integrations/${tag?.integration.id}`}
							>
								<IntegrationLogo
									integrationType={tag?.integration.type}
									size={theme.other.iconSize.sm}
								/>
							</RichTooltip>
						</Box>
					)}
					{!tag.visible && (
						<Tooltip label="Tag is hidden in resources" openDelay={300}>
							<Icon name={tag.visible ? 'eye' : 'eyeOff'} />
						</Tooltip>
					)}
				</Group>
				<Group spacing={0} noWrap>
					{!tagFromIntegration && hovered && (
						<IconButton
							onClick={openEditModal}
							iconName="pencil"
							variant="tertiary"
						/>
					)}
					<Menu>
						<Menu.Target>
							<Box>
								{hovered && <IconButton iconName="dots" variant="tertiary" />}
							</Box>
						</Menu.Target>
						<Menu.Dropdown>
							{!customPropertyId && (
								<>
									{!tagFromIntegration && (
										<Menu.Item
											icon={<Icon name="pencil" />}
											onClick={openEditModal}
										>
											Edit tag
										</Menu.Item>
									)}
									<Menu.Item
										icon={<Icon name="arrowRight" />}
										rightSection={<Icon name="chevronRight" />}
									>
										<Menu
											position="right"
											trigger="hover"
											offset={theme.other.space[8]}
										>
											<Menu.Target>
												<UnstyledButton w="100%">
													<Text size="sm">
														{currentTagGroup
															? 'Move to another group'
															: 'Move to group'}
													</Text>
												</UnstyledButton>
											</Menu.Target>
											<Menu.Dropdown>
												{currentTagGroup && (
													<Menu.Item
														key={tag.tag_group_id}
														disabled
														rightSection={<Icon name="check" />}
													>
														{currentTagGroup.name} (
														{currentTagGroup.tags?.length || 0})
													</Menu.Item>
												)}
												{tagGroupsExist &&
													tagGroups
														.filter(
															(tagGroup) => tagGroup.id !== currentTagGroup?.id
														)
														.map((tagGroup) => (
															<Menu.Item
																key={tagGroup.id}
																onClick={() => handleUpdate(tagGroup.id)}
															>
																{tagGroup.name} ({tagGroup.tags?.length || 0})
															</Menu.Item>
														))}
												{!tagGroupsExist && (
													<Menu.Item key="empty">No group exists</Menu.Item>
												)}
											</Menu.Dropdown>
										</Menu>
									</Menu.Item>
									{currentTagGroup && (
										<Menu.Item
											key={tag.tag_group_id}
											onClick={() => handleUpdate('')}
											icon={<Icon name="logout" />}
										>
											Remove from group
										</Menu.Item>
									)}
									<Menu.Item
										icon={<Icon name={tag.visible ? 'eyeOff' : 'eye'} />}
										onClick={handleToggleVisibility}
									>
										{tag.visible ? 'Hide tag' : 'Show tag'}
									</Menu.Item>
								</>
							)}
							{!tagFromIntegration && (
								<Menu.Item
									icon={<Icon name="trash" />}
									onClick={openDeleteModal}
								>
									Delete {propertyLabel}
								</Menu.Item>
							)}
						</Menu.Dropdown>
					</Menu>
				</Group>
			</Group>
			{tag.description && (
				<Text
					size="sm"
					color="text/secondary/default"
					weight="regular"
					className={classes.descriptionText}
				>
					{tag.description}
				</Text>
			)}
		</>
	);
}

export default TagCard;
