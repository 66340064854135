import { useMemo } from 'react';
import { useAuthUser, type IQuestion, type ISecodaEntity } from '../../api';
import {
	useExtendedUserList,
	useGuestUserList,
} from '../../api/hooks/user/useExtendedUserList';
import type { DjangoValueType } from '../../pages/TemplatePage/types';
import type { SidebarEntityKeys } from '../EntityPageLayout/types';
import { getAssigneeAndGroupSelectorOptions } from '../EntityPageLayout/utils';
import type { PropertySelectorProps } from './PropertySelector';
import { PropertySelector } from './PropertySelector';

export interface AssigneePropertySelectorProps
	extends Pick<PropertySelectorProps, 'onChange'> {
	entity: ISecodaEntity;
	viewerOfEntity: boolean;
	handleEntityChange: (
		key: SidebarEntityKeys
	) => (value: DjangoValueType, saveRemotely?: boolean) => void;
}

export function AssigneePropertySelector({
	entity,
	viewerOfEntity,
	handleEntityChange,
	...propertySelectorProps
}: AssigneePropertySelectorProps) {
	const { isGuestUser } = useAuthUser();
	const { usersVisibleToGuests, isLoading: isUsersVisibleToGuestsLoading } =
		useGuestUserList();

	const {
		userGroups: groups,
		activeUsers,
		isLoading: isActiveUsersLoading,
	} = useExtendedUserList();

	const assignees = useMemo(() => {
		const visibleUsers =
			(isGuestUser ? usersVisibleToGuests : activeUsers) ?? [];
		return getAssigneeAndGroupSelectorOptions(
			visibleUsers,
			groups ?? [],
			handleEntityChange
		);
	}, [
		activeUsers,
		groups,
		handleEntityChange,
		isGuestUser,
		usersVisibleToGuests,
	]);

	const selectedAssignee =
		(entity as IQuestion).assigned_to_group ||
		(entity as IQuestion).assigned_to;

	return (
		<PropertySelector
			isLoading={isActiveUsersLoading || isUsersVisibleToGuestsLoading}
			selected={selectedAssignee as string}
			type="single"
			value="assigned_to"
			iconType="avatar"
			isViewerUser={viewerOfEntity}
			readOnly={viewerOfEntity}
			searchable
			options={assignees}
			placeholder={viewerOfEntity ? 'Not assigned' : 'Assign user'}
			{...propertySelectorProps}
		/>
	);
}
