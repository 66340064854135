/**
 * Sanitizes the markdown string generated by RichEditor to remove the escape characters for empty lines.
 * @param str markdown string
 * @returns sanitized string
 */
export function sanitizeMarkdownString(str: string | null | undefined): string {
	return (str ?? '')
		.replaceAll('\\', '')
		.split('\n')
		.map((line) => {
			const regex = /( {0,}#{1,}.*) \{#\}/;
			const match = regex.exec(line);
			return match ? match[1] : line;
		})
		.join('\n');
}

export function escapeStringRegexp(text: string) {
	if (typeof text !== 'string') {
		return text;
	}

	return text.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
}

export function trimMarkdown(text: string) {
	return sanitizeMarkdownString(text.trim().replaceAll('\n', ''));
}
