import { Box } from '@mantine/core';
import MultiSelector from '@repo/common/components/MultiSelector/MultiSelector';
import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import type { ButtonVariants, IconNames } from '@repo/foundations';
import { noop } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useAuthUser } from '../../../api';
import { useExtendedUserList } from '../../../api/hooks/user/useExtendedUserList';
import { getOwnerAndGroupSelectorOptions } from '../../EntityPageLayout/utils';
import IntegrationTooltipSelectorItem from '../../RichTooltip/IntegrationTooltipSelectorItem';

interface OwnerSelectorProps {
	groupUsersBySections?: {
		label: string;
		value: string[];
	}[];
	onChangeUserOwners?: (value: string[]) => void;
	onChangeGroupOwners?: (value: string[]) => void;
	initialValue?: string[];
	placeholder?: string | null;
	placeholderIconName?: IconNames;
	forceVariant?: ButtonVariants;
	readOnly?: boolean;
	hideOnEmpty?: boolean;
	maintainOwners?: boolean;
	entityIntegrationId?: string;
	entityIntegrationName?: string;
	propertyLabel?: string;
	property?: string;
}

export function OwnerSelector({
	onChangeUserOwners,
	onChangeGroupOwners,
	initialValue,
	groupUsersBySections,
	property = 'owner',
	propertyLabel = 'owners',
	placeholder = 'owners',
	placeholderIconName,
	forceVariant,
	readOnly = false,
	hideOnEmpty = false,
	maintainOwners,
	entityIntegrationId,
	entityIntegrationName,
}: OwnerSelectorProps) {
	const { user, isAdminUser } = useAuthUser();

	const [selected, setSelected] = useState<string[]>(initialValue ?? [user.id]);

	const { activeUsers, serviceAccountsActive, userGroups } =
		useExtendedUserList();

	const serviceAccountOptions = useMemo(
		() =>
			serviceAccountsActive?.filter((accountId) =>
				initialValue?.includes(accountId.id)
			),
		[initialValue, serviceAccountsActive]
	);

	const ownerOptions = [
		...(activeUsers ?? []),
		...(serviceAccountOptions ?? []),
	].filter((owner) => (maintainOwners ? selected.includes(owner.id) : true));

	const options = useMemo(
		() =>
			getOwnerAndGroupSelectorOptions(
				ownerOptions,
				!maintainOwners && onChangeGroupOwners ? (userGroups ?? []) : [],
				noop,
				noop
			)
				.map((owner) => ({
					...owner,
					maintainProp: maintainOwners,
					entityIntegrationId: entityIntegrationId,
					itemTypeName: 'owner',
				}))
				.map((owner) => {
					// If the owner is a user, we will attempt to find the group it belongs to.
					if (owner.group === 'users' && typeof owner.value === 'string') {
						return {
							...owner,
							group:
								groupUsersBySections?.find((section) =>
									section.value.includes(owner.value as string)
								)?.label ?? 'users',
						};
					}
					return owner;
				}),
		[
			entityIntegrationId,
			maintainOwners,
			onChangeGroupOwners,
			ownerOptions,
			userGroups,
			groupUsersBySections,
		]
	);

	const handleChange = useCallback(
		(value: (string | boolean)[]) => {
			setSelected(value as string[]);

			const users = options
				// Users are not in the groups section, but they may be in the `groupUsersBySections`	array.
				.filter((owner) => owner.group !== 'groups')
				.filter((owner) => value.includes(owner.value))
				.map((owner) => owner.value);

			const groups = options
				.filter((owner) => owner.group === 'groups')
				.filter((owner) => value.includes(owner.value))
				.map((owner) => owner.value);

			onChangeUserOwners?.(users as string[]);
			onChangeGroupOwners?.(groups as string[]);
		},
		[onChangeUserOwners, onChangeGroupOwners, options]
	);

	const serviceAccountOwners = useMemo(
		() =>
			serviceAccountsActive
				?.filter((owner) => selected.includes(owner.id))
				.map((owner) => owner.id) ?? [],
		[serviceAccountsActive, selected]
	);

	let variant: ButtonVariants = 'default';

	if (forceVariant) {
		variant = forceVariant;
	}

	return (
		<Box maw={256}>
			{maintainOwners && selected.length === 0 ? (
				<RichTooltip
					title={`No ${propertyLabel} were set in ${entityIntegrationName}`}
					body={'To maintain in Secoda, go to integration settings.'}
					linkLabel={
						isAdminUser ? `${entityIntegrationName} settings` : undefined
					}
					linkUrl={
						isAdminUser
							? `/integrations/${entityIntegrationId}/preferences`
							: undefined
					}
				>
					<MultiSelector
						hideOnEmpty={hideOnEmpty}
						iconType="avatar"
						initialSelected={selected}
						isMenuItemBadge={false}
						isViewerUser={false}
						onChange={handleChange}
						inheritedValues={maintainOwners ? serviceAccountOwners : undefined}
						property={property}
						propertyLabel={propertyLabel}
						options={options}
						permittedId={user.id}
						placeholder={placeholder}
						placeholderIconName={placeholderIconName}
						readOnly={readOnly || maintainOwners}
						selectorItem={IntegrationTooltipSelectorItem}
						variant={variant}
					/>
				</RichTooltip>
			) : (
				<MultiSelector
					hideOnEmpty={hideOnEmpty}
					iconType="avatar"
					initialSelected={selected}
					isMenuItemBadge={false}
					isViewerUser={false}
					onChange={handleChange}
					inheritedValues={maintainOwners ? serviceAccountOwners : undefined}
					property={property}
					propertyLabel={propertyLabel}
					options={options}
					permittedId={user.id}
					placeholder={placeholder}
					placeholderIconName={placeholderIconName}
					readOnly={readOnly}
					selectorItem={IntegrationTooltipSelectorItem}
					variant={variant}
				/>
			)}
		</Box>
	);
}
