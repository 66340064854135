import { createStyles } from '@mantine/core';
import type { MantineTheme, Variants } from '@mantine/styles';

interface ISearchBoxStyleProps {
	variant: Variants<'unstyled' | 'default' | 'filled'>;
}

const subtle = (theme: MantineTheme) => ({
	width: '100%',
	backgroundColor: theme.other.getColor('surface/secondary/default'),
	borderWidth: 1,
	borderColor: 'transparent',
	// To make the border visible, we need to add a margin to the input.
	marginTop: theme.spacing['4xs'],
	'&:hover': {
		backgroundColor: theme.other.getColor('surface/secondary/hover'),
	},
	'&:focus': {
		borderStyle: 'solid',
		borderColor: 'transparent',
		boxShadow: `0px 0px 0px 2px ${theme.other.getColor('border/emphasis/default')}`,
	},
	'&:active': {
		borderStyle: 'solid',
		borderColor: 'transparent',
		boxShadow: `0px 0px 0px 2px ${theme.other.getColor('border/emphasis/default')}`,
	},
});

export const useStyles = createStyles(
	(theme, { variant }: ISearchBoxStyleProps) => ({
		input: {
			borderRadius: theme.radius.md,
			...((variant === 'tertiary' ? subtle(theme) : {}) || {}),
			height: variant === 'tertiary' ? theme.other.space[7] : undefined,
		},
		wrapper: {},
		textInput: {
			padding: 0,
			width: '100%',
			input: {
				borderRadius: '0.5rem',
			},
		},
		searchIcon: {
			padding: 0,
		},
	})
);
