import {
	ActionIcon,
	Box,
	createStyles,
	getStylesRef,
	Loader,
	Tooltip,
} from '@mantine/core';
import type { PolicyFramework, PolicyOut } from '@repo/api-codegen';
import { Icon, Text } from '@repo/foundations';
import { useCallback } from 'react';
import { useUpdatePolicy } from '../../../PoliciesListPage/hooks';

const useStyles = createStyles((theme) => ({
	item: {
		background: theme.other.getColor('fill/transparent-secondary/default'),
		borderRadius: theme.radius.sm,
		padding: `0 ${theme.spacing['2xs']}`,
		position: 'relative',
		maxWidth: '260px',

		[`&:hover .${getStylesRef('deleteButton')}, &:focus .${getStylesRef('deleteButton')}, &:active .${getStylesRef('deleteButton')}`]:
			{
				opacity: 1,
			},
	},
	text: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	deleteButton: {
		ref: getStylesRef('deleteButton'),
		opacity: 0,
		position: 'absolute',
		right: 0,
		top: 0,
		width: 20,
		height: 20,
		padding: theme.spacing['4xs'],
		backgroundColor: theme.other.getColor('fill/secondary/default'),
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,

		[`&:hover, &:focus`]: {
			opacity: 1,
			backgroundColor: theme.other.getColor('fill/secondary/hover'),
		},
	},
}));

interface InlineFrameworkPillProps {
	policy: PolicyOut;
	framework: PolicyFramework;
}

export function InlineFrameworkPill({
	policy,
	framework,
}: InlineFrameworkPillProps) {
	const { classes } = useStyles();

	const { mutateAsync: updatePolicy, isLoading } = useUpdatePolicy({});

	const handleRemove = useCallback(async () => {
		await updatePolicy({
			pathParams: { policyId: policy.id },
			body: {
				frameworks: policy.frameworks?.filter((f) => f !== framework),
			},
		});
	}, [framework, policy.id, policy.frameworks, updatePolicy]);

	return (
		<Box className={classes.item} tabIndex={0}>
			<Text size="sm" className={classes.text}>
				{framework}
			</Text>
			<Tooltip label="Remove framework">
				<ActionIcon
					className={classes.deleteButton}
					size="xs"
					onClick={handleRemove}
				>
					{isLoading ? (
						<Loader size="xs" />
					) : (
						<Icon name="smallX" color="icon/primary/default" />
					)}
				</ActionIcon>
			</Tooltip>
		</Box>
	);
}
