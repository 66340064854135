import type { MetricExecutionOut, MetricOut } from '@repo/api-codegen';
import {
	useApiSuperuserExecuteMetric,
	useApiSuperuserGetMetricExecution,
} from '@repo/api-codegen';
import { createMockableHook } from '@repo/common/utils/createMockableHook';
import { useCallback, useEffect, useState } from 'react';
import { api } from '../../../../network';
import type { ExecutionStatus } from '../../../components/Sql/types';
import { fetchExecutionStatusWithRetry } from '../../../components/Sql/utils';
import { trackEvent } from '../../../utils/analytics';
import queryClient from '../../queryClient';
import type { IMetric } from '../../types';
import { useAuthUser } from '../authUser';
import { metricsQueryKeyFactory } from '../metricTerm';
import { useCreateMetricExecution } from '../metricTerm/useCreateMetricExecution';
import { useMetricRefresh } from '../metricTerm/useMetricRefresh';

function useMetricExecutionInternal(
	metric: IMetric,
	eventPrefix: string = 'metric/embedded',
	onExecutionEnd?: () => void
) {
	const [isExecuting, setIsExecuting] = useState(false);
	const [executionStatus, setExecutionStatus] =
		useState<ExecutionStatus | null>(null);
	const { user, workspace } = useAuthUser();
	const { mutateAsync: createExecution } = useCreateMetricExecution();

	const watchExecution = useCallback(
		async (executionId: string) => {
			setIsExecuting(true);
			setExecutionStatus(null);

			const url = `${api()}metric/executions/${executionId}/`;
			const newExecutionStatus = await fetchExecutionStatusWithRetry(url, 1);

			setExecutionStatus(newExecutionStatus);
			setIsExecuting(false);

			// refetch metric data
			queryClient.refetchQueries({
				queryKey: metricsQueryKeyFactory.byId(metric.id),
			});
			queryClient.refetchQueries({
				queryKey: [...metricsQueryKeyFactory.all(), 'get_or_create', metric.id],
			});

			onExecutionEnd?.();
		},
		[metric.id, onExecutionEnd]
	);

	const execute = useCallback(async () => {
		if (isExecuting) {
			return;
		}

		trackEvent(
			`${eventPrefix}/run`,
			{
				id: metric.id,
			},
			user,
			workspace
		);

		const { id: newExecutionId } = await createExecution({
			metricId: metric.id,
		});

		await watchExecution(newExecutionId);
	}, [
		createExecution,
		eventPrefix,
		isExecuting,
		metric.id,
		user,
		watchExecution,
		workspace,
	]);

	const refreshMetric = useMetricRefresh(metric.id);

	useEffect(() => {
		async function refresh() {
			if (!metric?.scheduled_delta) {
				return;
			}

			// we let backend decide if the metric needs to be refreshed or not
			// this should be ok to be called multiple times
			const refreshExecutionData = await refreshMetric();
			if (
				!!refreshExecutionData?.id &&
				!['completed', 'failed'].includes(refreshExecutionData.status)
			) {
				// if execution is not completed or failed, we watch it
				await watchExecution(refreshExecutionData.id);
			}
		}

		refresh();
	}, [metric, refreshMetric, watchExecution]);

	return {
		isExecuting,
		executionStatus,
		execute,
	};
}

export const [useMetricExecution, MockUseMetricExecutionInternalProvider] =
	createMockableHook(useMetricExecutionInternal);

export function useSuperuserMetricExecution(metric?: MetricOut) {
	const [isExecuting, setIsExecuting] = useState(false);
	const [executionStatus, setExecutionStatus] =
		useState<MetricExecutionOut | null>(null);
	const [executionId, setExecutionId] = useState<string | null>(null);

	const { mutateAsync: createExecution } = useApiSuperuserExecuteMetric();

	// Refetch execution status loop
	const { data: metricExecution } = useApiSuperuserGetMetricExecution(
		{
			pathParams: { executionId: executionId || '' },
		},
		{
			enabled: !!executionId,
			refetchInterval(data, query) {
				if (data) {
					setExecutionStatus(data);
				}
				if (data?.status === 'completed' || data?.status === 'failed') {
					setIsExecuting(false);
					return false;
				}

				return 1000;
			},
		}
	);

	const execute = useCallback(async () => {
		if (isExecuting) {
			return;
		}

		setIsExecuting(true);
		setExecutionStatus(null);
		setExecutionId(null);

		const { id } = await createExecution({
			pathParams: { id: metric?.id || '' },
		});
		setExecutionId(id);
	}, [createExecution, isExecuting, metric?.id]);

	if (!metric) {
		return {
			isExecuting,
			executionStatus,
			execute: () => {},
		};
	}

	return {
		isExecuting,
		executionStatus,
		execute,
	};
}
