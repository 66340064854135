import { useFeatureFlags } from '../../../utils/featureFlags';
import { useFeatureAccess } from './useFeatureAccess';

/**
 * Hook to check if policies feature is accessible.
 * Combines both feature access (from plan/subscription) and feature flag (for rollout control).
 *
 * @returns {boolean} Whether policies feature is accessible
 */
export function usePolicyAccess(): boolean {
	const { policiesAccess } = useFeatureAccess();
	const { policies } = useFeatureFlags();

	// Both the feature access (subscription) and feature flag must be enabled
	return !!policiesAccess && !!policies;
}
