import { Box, createStyles, Input } from '@mantine/core';
import { SettingsBlock } from '../SettingsBlock';

interface SettingsImageUploadProps {
	title: string;
	description?: string;
	onChange: (file: File | null) => void;
	disabled?: boolean;
	placeholder: React.ReactNode;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		position: 'relative',
		cursor: 'pointer',
		'&:hover': {
			opacity: 0.8,
		},
	},
	input: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		opacity: 0,
		cursor: 'pointer',
		'&:disabled': {
			cursor: 'not-allowed',
		},
	},
}));

export function SettingsImageUpload({
	title,
	description,
	onChange,
	disabled = false,
	placeholder,
}: SettingsImageUploadProps) {
	const { classes } = useStyles();

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0] || null;
		onChange(file);
	};

	return (
		<SettingsBlock
			title={title}
			description={description}
			rightComponent={
				<Box className={classes.wrapper}>
					{placeholder}
					<Input
						type="file"
						accept="image/*"
						onChange={handleFileChange}
						disabled={disabled}
						className={classes.input}
					/>
				</Box>
			}
		/>
	);
}
