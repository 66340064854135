import type { IconNames } from '@repo/foundations';

export const ACTIVITY_TYPE_TO_ICON: Record<string, IconNames> = {
	DELETE_ENTITY: 'trash',
	CREATE_ENTITY: 'fileAnalytics',
	PROPAGATE_ENTITY: 'arrowsSplit',
	UPDATE_ENTITY_DESCRIPTION: 'pencil',
	ADD_ENTITY_OWNER: 'user',
	REMOVE_ENTITY_OWNER: 'user',
	ADD_ENTITY_COLLECTION: 'folders',
	REMOVE_ENTITY_COLLECTION: 'folders',
	SET_ENTITY_AS_PUBLISHED: 'pencil',
	SET_ENTITY_AS_UNPUBLISHED: 'pencil',
	SET_ENTITY_AS_PII: 'shieldCheckFilled',
	SET_ENTITY_AS_NOT_PII: 'shield',
	SET_ENTITY_AS_VERIFIED: 'discountCheckFilled',
	SET_ENTITY_AS_UNVERIFIED: 'rosette',
	UPDATE_ENTITY_DEFINITION: 'pencil',
	ADD_ENTITY_TAG: 'tag',
	REMOVE_ENTITY_TAG: 'tag',
	ARCHIVE_ENTITY: 'archive',
	RESTORE_ARCHIVED_ENTITY: 'archive',
	ADD_ENTITY_SUBSCRIBER: 'user',
	REMOVE_ENTITY_SUBSCRIBER: 'user',
	ADD_ENTITY_TEAM: 'usersGroup',
	REMOVE_ENTITY_TEAM: 'usersGroup',
	UPDATE_ENTITY_TITLE: 'pencil',
};

export const PENDING_VERSION_HISTORY = 'PENDING_VERSION_HISTORY';
