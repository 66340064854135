import { Avatar, Box, createStyles, Input } from '@mantine/core';
import React from 'react';
import { SettingsBlock } from '../SettingsBlock';

interface SettingsAvatarProps {
	title: string;
	description?: string;
	imageUrl?: string;
	onChange: (file: File | null) => void;
	disabled?: boolean;
}

const useStyles = createStyles((theme) => ({
	avatarWrapper: {
		position: 'relative',
		cursor: 'pointer',
		'&:hover': {
			opacity: 0.8,
		},
	},
	input: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		opacity: 0,
		cursor: 'pointer',
		'&:disabled': {
			cursor: 'not-allowed',
		},
	},
}));

export function SettingsAvatar({
	title,
	description,
	imageUrl,
	onChange,
	disabled = false,
}: SettingsAvatarProps) {
	const { classes } = useStyles();

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0] || null;
		onChange(file);
	};

	return (
		<SettingsBlock
			title={title}
			description={description}
			rightComponent={
				<Box className={classes.avatarWrapper}>
					<Avatar src={imageUrl} size="lg" radius="xl" />
					<Input
						type="file"
						accept="image/*"
						onChange={handleFileChange}
						disabled={disabled}
						className={classes.input}
					/>
				</Box>
			}
		/>
	);
}
