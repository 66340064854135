import {
	Anchor,
	Box,
	Divider,
	Group,
	ScrollArea,
	Stack,
	Table,
	useMantineTheme,
} from '@mantine/core';

import { useDisclosure } from '@mantine/hooks';
import { Text } from '@repo/foundations';
import type { FC } from 'react';
import { useMetricLatestFromWidget } from '../../../../api/hooks/metric/useMetric';
import { ScrollableModal } from '../../../../components/ScrollableModal/ScrollableModal';
import type { ISampleOrActualMetricWidget } from '../../constants';
import { METRIC_WIDGET_CONTENT_HEIGHT } from '../../constants';
import { formatMetricWidgetTitle } from '../../utils/utils';
import { MetricLoadingOrEmpty } from '../MetricLoadingOrEmpty';
import { ValueBadge } from '../ValueBadge';

export type TableComponentProps = {
	values: Array<Record<string, string | number>>;
	infoColumns: Array<{ fieldName: string; label: string }>;
};

type IMetricWidgetTableProps = {
	TableComponent: FC<TableComponentProps>;
	infoColumns: Array<{ fieldName: string; label: string }>;
} & ISampleOrActualMetricWidget;

export function MetricWidgetTable({
	source,
	metricWidget,
	TableComponent,
	infoColumns,
}: IMetricWidgetTableProps) {
	const theme = useMantineTheme();
	const [
		seeAllModalOpened,
		{ open: openSeeAllModal, close: closeSeeAllModal },
	] = useDisclosure(false);

	const { data: metric, isLoading } = useMetricLatestFromWidget(metricWidget);
	const value = metric?.value;

	const validTableMetric = (
		tableMetric: number | Array<Record<string, string | number>> | undefined
	): tableMetric is Array<Record<string, string | number>> =>
		tableMetric !== undefined && typeof tableMetric === 'object';

	if (!validTableMetric(value) || isLoading) {
		return (
			<MetricLoadingOrEmpty
				isLoading={isLoading}
				isEmpty={!validTableMetric(value)}
				source={source}
			/>
		);
	}

	if (source === 'preview') {
		const firstCols = infoColumns.slice(0, 2);
		return (
			<Table horizontalSpacing={2} verticalSpacing={2} fontSize="xs">
				<thead>
					<tr>
						{firstCols.map((col) => (
							<th key={col.fieldName}>
								<Text size="xxs">{col.label}</Text>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					<tr>
						{firstCols.map((col) => (
							<td key={col.fieldName}>
								<Text size="xxs">...</Text>
							</td>
						))}
					</tr>
					<tr>
						{firstCols.map((col) => (
							<td key={col.fieldName}>
								<Text size="xxs">...</Text>
							</td>
						))}
					</tr>
				</tbody>
			</Table>
		);
	}

	const seeAllButtonHeight = 20;
	const scrollAreaHeight =
		METRIC_WIDGET_CONTENT_HEIGHT[source] -
		(source === 'widget' ? seeAllButtonHeight : 0);

	return (
		<Stack spacing={0} w="100%">
			<ScrollableModal
				title={formatMetricWidgetTitle(metricWidget)}
				onClose={closeSeeAllModal}
				opened={seeAllModalOpened}
				size={600}
			>
				<Box px="md">
					<TableComponent values={value} infoColumns={infoColumns} />
				</Box>
			</ScrollableModal>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/* @ts-ignore poorly typed component by Mantine */}
			<ScrollArea.Autosize
				h={scrollAreaHeight}
				sx={{ overflowX: 'auto', width: '100%' }}
			>
				<TableComponent values={value} infoColumns={infoColumns} />
			</ScrollArea.Autosize>

			{source === 'widget' && (
				<>
					<Divider />
					<Group position="apart" mt="md">
						<Anchor
							// eslint-disable-next-line no-theme-colors/no-theme-colors
							color={theme.colors.primary[6]}
							fw={500}
							fz="sm"
							onClick={openSeeAllModal}
						>
							See all
						</Anchor>
						<ValueBadge metricWidget={metricWidget} />
					</Group>
				</>
			)}
		</Stack>
	);
}
