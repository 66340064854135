import { Card, Group, JsonInput, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import type { BackgroundJobType } from '@repo/api-codegen';
import { apiQueryKey, useApiCreateBackgroundJob } from '@repo/api-codegen';
import { Button, ListBox, Text, TextInput, Title } from '@repo/foundations';
import { useState } from 'react';
import { queryClient } from '../../../api';
import { useStyles } from '../IntegrationsStatusPage/IntegrationsStatusPage.styles';

function BackgroundJobCreate() {
	const { classes } = useStyles();
	const { mutateAsync: createBackgroundJob } = useApiCreateBackgroundJob();

	// Type
	const [title, setTitle] = useState<string>('');
	const [type, setType] = useState<BackgroundJobType>('indexing');
	const [opened, setOpened] = useState<boolean>(false);

	// Config
	const [config, setConfig] = useState('');

	// Create
	const handleCreateBackgroundJob = () => {
		let parsedConfig;
		try {
			if (config !== '') {
				parsedConfig = JSON.parse(config);
			}
		} catch {
			showNotification({
				title: 'Invalid JSON',
				message: 'Please provide a valid JSON',
				color: 'red',
			});
			return;
		}
		createBackgroundJob(
			{
				body: {
					title: title,
					type: type,
					config: parsedConfig,
				},
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries({
						queryKey: apiQueryKey('admin/background_job'),
					});
					showNotification({
						title: 'Job created',
						message: 'Yay',
						color: 'green',
					});
				},
				onError: () => {
					showNotification({
						title: 'Failed to create job',
						message:
							"I didn't spend too much time here, look at network tab to debug",
						color: 'red',
					});
				},
			}
		);
	};

	return (
		<Card className={classes.card}>
			<Stack spacing="sm">
				<Title order={2}>Create new background job</Title>
				<TextInput
					label="Job title"
					value={title}
					onChange={(e) => setTitle(e.currentTarget.value)}
				/>
				<Group>
					<Text size="sm" weight="semibold">
						Job type
					</Text>
					<ListBox
						opened={opened}
						onOpenChange={(newOpened) => setOpened(newOpened)}
						closeOnEscape
					>
						<ListBox.Target>
							<Button onClick={() => setOpened(!opened)}>{type}</Button>
						</ListBox.Target>
						<ListBox.ItemsDropdown
							items={['indexing', 'bg_admin'] as BackgroundJobType[]}
							renderItem={(item, getProps, idx) => (
								<ListBox.Item
									onClick={() => {
										setType(item);
										setOpened(false);
									}}
								>
									{item}
								</ListBox.Item>
							)}
						/>
					</ListBox>
				</Group>
				<Stack w="100%">
					<JsonInput
						label="Config"
						value={config}
						onChange={(value) => {
							setConfig(value);
						}}
						placeholder={
							'// JSON config object here. Check BackgroundJobConfig for validation syntax \n{\n"workspace_ids": ["id_1"]\n}'
						}
						validationError="Invalid JSON"
						formatOnBlur
						minRows={4}
					/>
				</Stack>
				<Button variant="primary" onClick={handleCreateBackgroundJob} size="sm">
					Create new
				</Button>
			</Stack>
		</Card>
	);
}

export default BackgroundJobCreate;
