import {
	Flex,
	Group,
	Table,
	createStyles,
	useMantineTheme,
} from '@mantine/core';
import { useInterval } from '@mantine/hooks';
import { Icon, Text } from '@repo/foundations';
import { capitalize } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import type { IDatabuilderJobStage } from '../../../api';
import {
	EXTRACTION_STATUS,
	getExtractionBadgeStyles,
	getExtractionStageDescription,
	getExtractionStageStatus,
} from './utils';

const useStyles = createStyles((theme) => ({
	row: {
		backgroundColor: theme.other.getColor('fill/transparent/default'),
	},
}));

interface ExtractionRowProps {
	stage: IDatabuilderJobStage;
	isJobTerminated: boolean;
}

const ExtractionRow = observer(
	({ stage, isJobTerminated }: ExtractionRowProps) => {
		const { classes } = useStyles();

		const status = getExtractionStageStatus(stage, isJobTerminated);
		const [desc, setDesc] = useState(
			getExtractionStageDescription(stage, isJobTerminated)
		);

		const { start, stop } = useInterval(() => {
			setDesc(getExtractionStageDescription(stage, isJobTerminated));
		}, 500);

		useEffect(() => {
			if (status !== EXTRACTION_STATUS.running) {
				setDesc(getExtractionStageDescription(stage, isJobTerminated));
				stop();
			} else {
				start();
			}
		}, [isJobTerminated, stage, start, status, stop]);

		const extractionStyles = getExtractionBadgeStyles(status);

		return (
			<tr className={classes.row} role="row">
				<td>
					<Flex direction="column">
						<Text size="xs">{stage.stage}</Text>
					</Flex>
				</td>
				<td>
					<Flex direction="row">
						<Flex direction="column">
							<Text size="xs">{desc}</Text>
						</Flex>
					</Flex>
				</td>
				<td>
					<Flex direction="row">
						<Group spacing="3xs" noWrap>
							<Icon
								size="md"
								name={extractionStyles.iconName}
								fillColor={extractionStyles.iconFillColor}
								color={extractionStyles.iconColor}
							/>
							<Text size="xs">{capitalize(status)}</Text>
						</Group>
					</Flex>
				</td>
			</tr>
		);
	}
);

interface ExtractionStagesTableProps {
	isJobTerminated: boolean;
	stages: IDatabuilderJobStage[];
}

export function ExtractionStagesTable({
	isJobTerminated,
	stages,
}: ExtractionStagesTableProps) {
	const theme = useMantineTheme();

	return (
		<Table>
			<thead
				style={{
					backgroundColor: theme.other.getColor('fill/secondary/default'),
				}}
			>
				<tr>
					<th>
						<Text size="sm" p={theme.spacing.xs}>
							Stage
						</Text>
					</th>
					<th>
						<Text size="sm" p={theme.spacing.xs}>
							Timing
						</Text>
					</th>
					<th style={{ borderTopLeftRadius: '4px' }}>
						<Text size="sm" p={theme.spacing.xs}>
							Status
						</Text>
					</th>
				</tr>
			</thead>
			<tbody style={{ maxHeight: 500 }}>
				{stages.map((stage) => (
					<ExtractionRow
						key={stage.id}
						stage={stage}
						isJobTerminated={isJobTerminated}
					/>
				))}
			</tbody>
		</Table>
	);
}
