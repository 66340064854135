import { useMantineTheme } from '@mantine/core';
import {
	apiQueryKey,
	useApiBulkCreateMemberships,
	useApiListMemberships,
	useApiUpdateTeam,
} from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { openConfirmModal } from '../../../components/ModalManager';
import { queryClient } from '../../index';

export function useMembershipsAndBulkCreateMemberships(teamId: string) {
	const { data: memberships } = useApiListMemberships({
		pathParams: {
			teamId,
		},
	});

	const {
		isLoading: isBulkCreatingMemberships,
		mutateAsync: bulkCreateTeamMembership,
	} = useApiBulkCreateMemberships({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: apiQueryKey('auth/teams/{team_id}/memberships', {
					team_id: teamId,
				}),
			});
		},
	});

	return {
		memberships,
		isBulkCreatingMemberships,
		bulkCreateTeamMembership,
	};
}

export function useTeamManagement(teamId: string) {
	const theme = useMantineTheme();
	const { mutateAsync: updateTeam, isLoading: isUpdating } = useApiUpdateTeam({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: apiQueryKey('auth/teams'),
			});
		},
	});

	const archiveTeam = async () => {
		openConfirmModal({
			sx: {
				paddingTop: `${theme.spacing.md} !important`,
				paddingBottom: `${theme.spacing.md} !important`,
			},
			title: (
				<Text size="md" weight="bold">
					Are you sure you want to archive this team?
				</Text>
			),
			children: (
				<Text size="sm">
					Archiving this team will remove access and hide it in the sidebar for
					all team members.{' '}
				</Text>
			),
			labels: { confirm: 'Archive team', cancel: 'Cancel' },
			cancelProps: {
				size: 'md',
			},
			confirmProps: {
				size: 'md',
				variant: 'primary',
				tone: 'critical',
			},

			onConfirm: async () => {
				await updateTeam({
					pathParams: {
						teamId,
					},
					body: {
						archived: true,
					},
				});
			},
		});
	};

	const restoreTeam = async () => {
		await updateTeam({
			pathParams: {
				teamId,
			},
			body: {
				archived: false,
			},
		});
	};

	return {
		isUpdating,
		updateTeam,
		archiveTeam,
		restoreTeam,
	};
}
