import { Box, Group, Stack } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { map } from 'lodash-es';
import { useStyles } from '../styles';

interface IColumnsProps {
	columns: string[];
	isNew?: boolean;
}

function Columns({ columns = [], isNew }: IColumnsProps) {
	const { classes } = useStyles();

	const AddedOrRemovedIcon = isNew
		? () => <Icon name="plus" color="icon/success/default" />
		: () => <Icon name="minus" />;

	return (
		<Group className={classes.rightCol} spacing="xs" noWrap>
			<Stack spacing="xs" w="100%">
				{map(columns, (column) => (
					<Group
						key={column}
						className={classes.colorGroup}
						spacing={6}
						bg={isNew ? 'surface/success/default' : 'surface/secondary/default'}
						noWrap
						data-testid={`inbox-view-schema-change-${
							isNew ? 'added' : 'removed'
						}-column-8a5dca7ad`}
					>
						<Box w={18}>
							<AddedOrRemovedIcon />
						</Box>
						<Text className={classes.soleLabel}>{column}</Text>
					</Group>
				))}
			</Stack>
		</Group>
	);
}

export default Columns;
