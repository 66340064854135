import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { SettingsSwitch } from '@repo/common/components';
import { useMutation } from '@tanstack/react-query';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useAuthUser } from '../../api';
import { useUpdateWorkspace } from '../../api/hooks/workspace/useUpdateWorkspace';

function AppearanceSettings() {
	const { workspace, isAdminUser } = useAuthUser();
	const baseUpdateWorkspace = useUpdateWorkspace(workspace.id);

	const { mutateAsync: updateWorkspace } = useMutation({
		mutationFn: baseUpdateWorkspace.mutateAsync,
		onSuccess: () => {
			showNotification({
				title: 'Settings saved',
				message: 'Appearance settings updated',
				color: 'green',
			});
		},
		onError: () => {
			showNotification({
				title: 'Error saving settings',
				message: 'Failed to update appearance settings.',
				color: 'red',
			});
		},
	});

	const disabled = !isAdminUser;

	const handleFullWidthChange =
		(key: 'default_full_width_pages') => async (checked: boolean) => {
			await updateWorkspace({
				data: { id: workspace.id, [key]: checked },
			});
			runInAction(() => {
				workspace![key] = checked;
			});
		};

	const handleFeedbackChange = async (checked: boolean) => {
		await updateWorkspace({
			data: {
				id: workspace.id,
				hide_feedback_for_viewers: !checked,
			},
		});
	};

	return (
		<Box>
			<SettingsSwitch
				onChange={handleFullWidthChange('default_full_width_pages')}
				checked={Boolean(workspace?.default_full_width_pages)}
				title="Full page width"
				description="Set resource pages to full width by default"
			/>
			<SettingsSwitch
				disabled={disabled}
				checked={!workspace?.hide_feedback_for_viewers}
				onChange={handleFeedbackChange}
				title="Show help button"
				description="The help button will be shown for viewers and guests"
			/>
		</Box>
	);
}

const AppearanceSettingsComponent = observer(AppearanceSettings);
export default AppearanceSettingsComponent;
