import { Group, Stack, useMantineTheme } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import type { TeamOut } from '@repo/api-codegen';
import { Button, Icon, Text } from '@repo/foundations';
import { useTeamManagement } from '../../api/hooks/team/teamsManagement';
import type { TeamType } from '../../interfaces';
import { TeamForm } from '../SecodaAppShell/SideBar/TeamForm';
import type { ITeamForm } from '../SecodaAppShell/SideBar/TeamForm.constants';
import { teamFormSchema } from '../SecodaAppShell/SideBar/TeamForm.constants';

export function TeamGeneralTab({ team }: { team: TeamOut }) {
	const theme = useMantineTheme();

	const form = useForm<ITeamForm>({
		initialValues: {
			icon: team.icon || '',
			name: team.name,
			type: team.type as TeamType,
		},
		validate: yupResolver(teamFormSchema),
		validateInputOnBlur: true,
	});

	const { updateTeam, archiveTeam, restoreTeam, isUpdating } =
		useTeamManagement(team.id);

	const sameValue =
		form.values.type === team.type &&
		form.values.icon === team.icon &&
		form.values.name === team.name;

	const onSubmit = async (values: ITeamForm) => {
		await updateTeam({
			pathParams: {
				teamId: team.id,
			},
			body: {
				...values,
				type: values.type as TeamType,
			},
		});
	};

	return (
		<Stack p={24}>
			<form onSubmit={form.onSubmit(onSubmit)} autoComplete="off">
				<Stack>
					<TeamForm form={form} />
					<Group spacing={12} position="right">
						<Button
							type="submit"
							variant="primary"
							disabled={isUpdating || sameValue}
							loading={isUpdating}
						>
							Update
						</Button>
					</Group>
				</Stack>
			</form>
			{!team.is_default_team && (
				<Stack spacing={4}>
					<Text fw={500} size="sm">
						Danger zone
					</Text>
					{team.archived ? (
						<Group
							align="center"
							py={8}
							px={12}
							spacing={8}
							noWrap
							role="button"
							onClick={restoreTeam}
							sx={{
								border: `1px solid ${theme.other.getColor('border/inverse/active')}`,
								borderRadius: 6,
							}}
						>
							<Icon name="archive" color="icon/primary/default" />
							<Stack spacing={0}>
								<Text size="sm" color="text/primary/default">
									Restore team
								</Text>
								<Text size="xs" color="text/secondary/default">
									Show this team to the sidebar for all members
								</Text>
							</Stack>
						</Group>
					) : (
						<Group
							align="center"
							py={8}
							px={12}
							spacing={8}
							noWrap
							role="button"
							onClick={archiveTeam}
							sx={{
								border: `1px solid ${theme.other.getColor('border/inverse/active')}`,
								borderRadius: 6,
								'&:hover': {
									backgroundColor: theme.other.getColor('fill/primary/hover'),
								},
							}}
						>
							<Icon name="archive" color="text/critical/default" />
							<Stack spacing={0}>
								<Text size="sm" color="text/critical/default">
									Archive team
								</Text>
								<Text size="xs" color="text/secondary/default">
									Remove this team from the sidebar for all members
								</Text>
							</Stack>
						</Group>
					)}
				</Stack>
			)}
		</Stack>
	);
}
