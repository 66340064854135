import type { CustomPropertyOut } from '@repo/api-codegen';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { EntityType } from '@repo/common/enums/entityType';
import { useCallback, useMemo } from 'react';
import { useMention, type ISecodaEntity, type SearchResult } from '../../api';
import { buildResourceUrl } from '../../utils/navigationUtils';
import { SecodaEntityIcon } from '../SecodaEntity';
import { PropertySelector } from './PropertySelector';
import { parsePropertyField } from './utils';

interface ResourceCustomPropertySelectorProps {
	entity: ISecodaEntity;
	customProperty: CustomPropertyOut;
	field: string;
	onChange: (entityId: string) => (value: string[]) => void;
}

export function ResourceCustomPropertySelector({
	entity,
	customProperty,
	field,
	onChange,
}: ResourceCustomPropertySelectorProps) {
	const selected = useMemo(
		() => parsePropertyField(entity, field),
		[entity, field]
	);

	let entityTypes = customProperty.entity_types;
	if (entityTypes[0] === 'all') {
		entityTypes = Object.values(EntityType).map((e) => e.toLowerCase());
	}

	const { data } = useMention({
		searchTerm: '',
		filters: {
			operator: 'and',
			operands: [
				{
					field: 'entity_type',
					operator: 'in',
					value: entityTypes,
					operands: [],
				},
			],
		},
	});

	const options: SelectablePropertyItem[] = useMemo(() => {
		if (!Array.isArray(data?.results) || data.results.length === 0) return [];

		return data.results.map(
			(resource: SearchResult): SelectablePropertyItem => ({
				label: resource.title,
				value: resource.id,
				icon: <SecodaEntityIcon entity={resource} size={20} />,
				navigateTo: buildResourceUrl(resource),
			})
		);
	}, [data]);

	const handleChange = useCallback(
		(value) => {
			onChange(entity.id)(
				Array.isArray(value) ? (value as string[]) : [value as string]
			);
		},
		[onChange, entity.id]
	);

	return (
		<PropertySelector
			selected={selected}
			type="multi"
			value="resource"
			iconType="react-node"
			options={options}
			permittedId=""
			isMenuItemBadge={false}
			isViewerUser={false}
			onChange={handleChange}
			placeholder={`${selected.length || 'No'} resource${selected.length !== 1 ? 's' : ''}`}
		/>
	);
}
