import type { Filter } from '@repo/api-codegen';
import { IconEmoji } from '@repo/common/components/IconEmoji/IconEmoji';
import { EntityType } from '@repo/common/enums/entityType';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { pluralize } from '@repo/common/utils';
import type { MouseEvent } from 'react';
import { useCatalogInfiniteList } from '../../../api/hooks/resourceCatalog';
import { useEntityBreadcrumbTeam } from '../../../hooks/useEntityBreadcrumbs';
import { buildResourceUrl } from '../../../utils/navigationUtils';
import { snakeCaseToTitleCase } from '../../../utils/shared.utils';
import { DataTreeLoader } from '../components/DataTreeLoader';
import { LoadMore } from '../components/LoadMore';
import { TreeNode } from '../components/TreeNode';
import { getEntityIconByType } from '../utils';
import type { NewCollectionTreeNode } from './utils';
import { getCollectionNodeChildren } from './utils';

interface CollectionsTreeProps {
	teamId: string;
}

const getEntityIcon = (data: NewCollectionTreeNode) => {
	if (data.type === 'collection' || data.type === 'child_entity') {
		if (data.entity.icon) {
			return <IconEmoji value={data.entity.icon} />;
		}
		return getEntityIconByType(data.entity.entity_type);
	} else {
		return getEntityIconByType(data.entityType as EntityType);
	}
};

const getLabel = (node: NewCollectionTreeNode) => {
	if (node.type === 'collection' || node.type === 'child_entity') {
		return node.entity.title;
	}

	return `${pluralize(snakeCaseToTitleCase(node.entityType))}`;
};

function CollectionsTree({ teamId }: CollectionsTreeProps) {
	const {
		data: result,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
	} = useCatalogInfiniteList({
		filters: {
			teams: teamId,
			filter: {
				operator: 'and',
				operands: [
					{
						operator: 'not',
						field: 'parent_id',
					},
					{
						operator: 'not',
						field: 'collections',
					},
					{
						field: 'entity_type',
						operator: 'exact',
						value: EntityType.collection,
					},
				],
			} as Filter,
			sort: {
				field: 'title',
				order: 'asc',
			},
		},
	});

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	const navigateHandler = useNavigateHandler();
	const nodes: NewCollectionTreeNode[] | undefined = result?.pages.map(
		(entity) => ({
			type: 'collection',
			id: entity.id,
			entity: entity,
			hasChildren: true,
		})
	);

	const handleNavigate = (event: MouseEvent, node: NewCollectionTreeNode) => {
		setTeamBreadcrumb(teamId);
		if ('entity' in node) {
			const url = buildResourceUrl({
				id: node.entity.id,
				entity_type: node.entity.entity_type,
			});
			if (url) {
				navigateHandler(event)(url);
			}
		} else {
			const url = `/collections/${node.parentCollectionId}/${node.entityType}`;
			if (url) {
				navigateHandler(event)(url);
			}
		}
	};

	if (!nodes) {
		return <DataTreeLoader />;
	}

	return (
		<>
			{nodes.map((node) => (
				<TreeNode
					key={node.id}
					node={node}
					onClick={handleNavigate}
					getIcon={getEntityIcon}
					level={0}
					getLabel={getLabel}
					onLoadChildren={getCollectionNodeChildren}
				/>
			))}
			{hasNextPage && (
				<LoadMore
					level={0}
					onClick={fetchNextPage}
					isLoading={isFetchingNextPage}
				/>
			)}
		</>
	);
}

export default CollectionsTree;
