/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import {
	Navigate,
	Outlet,
	redirect,
	Route,
	useLocation,
	useParams,
} from 'react-router-dom';
import { queryClient } from '../api';
import { thisUserQuery } from '../api/hooks/user/useThisUser';
import AddMonitorPage from '../components/AddMonitorV2/AddMonitorPage/AddMonitorPage';
import { AuthCallback } from '../components/Auth/AuthCallback';
import { AuthImpersonate } from '../components/Auth/AuthImpersonate';
import { AuthSignout } from '../components/Auth/AuthSignout';
import { AuthStepConfirm } from '../components/Auth/AuthStepConfirm';
import { AuthStepLogin } from '../components/Auth/AuthStepLogin';
import { AuthStepProfile } from '../components/Auth/AuthStepProfile';
import { AuthStepSamlSso } from '../components/Auth/AuthStepSamlSso';
import { AuthStepSignup } from '../components/Auth/AuthStepSignup';
import { AuthStepStartContextProvider } from '../components/Auth/AuthStepStartContext';
import { AuthStepVerify } from '../components/Auth/AuthStepVerify';
import { AuthStepWorkspace } from '../components/Auth/AuthStepWorkspace';
import EntityPageLayoutSkeleton from '../components/EntityPageLayout/EntityPageLayoutSkeleton.tsx';
import { EntityListPageSkeleton } from '../components/EntityPageSkeleton/EntityListPageSkeleton.tsx';
import { ErrorBoundary } from '../components/ErrorBoundary';
import IntegrationPage from '../components/Integration/IntegrationPage';
import IntegrationSelectionPage from '../components/Integration/IntegrationSelectionPage';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { ModalManagerProvider } from '../components/ModalManager';
import { PrivateRoute } from '../components/PrivateRoute';
import SecodaAppShell from '../components/SecodaAppShell';
import { BillingCallback } from '../components/Settings/BillingCallback';
import { CreateRolePage } from '../components/Settings/MemberSettings/IAMRole/CreateRolePage.tsx';
import { UpdateRolePage } from '../components/Settings/MemberSettings/IAMRole/UpdateRolePage';
import SettingsPage from '../components/Settings/SettingsPage';
import { TeamPageRoute } from '../components/TeamPageRoute';
import EmbeddedPromptPage from '../pages/AIAssistantPage';
import AnalyticsListPage from '../pages/AnalyticsListPage';
import AnalyticsPage from '../pages/AnalyticsPage';
import AutomationListPage from '../pages/AutomationListPage/AutomationListPage';
import AutomationPage from '../pages/AutomationPage/AutomationPageWrapper';
import ChartPage from '../pages/ChartPage';
import ChromeExtensionPage from '../pages/ChromeExtensionPage/ChromeExtensionPage';
import CollectionPage from '../pages/CollectionPage';
import ColumnEntityPage from '../pages/ColumnEntityPage';
import DashboardPage from '../pages/DashboardPage';
import DatabasePage from '../pages/DatabasePage';
import DocumentPage from '../pages/DocumentPage';
import { DocumentPageSkeleton } from '../pages/DocumentPage/DocumentPageSkeleton.tsx';
import DownloadPage from '../pages/DownloadPage';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import EventPage from '../pages/EventPage';
import GlossaryTermPage from '../pages/GlossaryTermPage';
import { GlossaryTermPageSkeleton } from '../pages/GlossaryTermPage/GlossaryTermPageSkeleton.tsx';
import HomePage from '../pages/HomePage/HomePage';
import TeamHomePage from '../pages/HomePage/TeamHomePage';
import InboxPage from '../pages/InboxPage';
import IncidentPage from '../pages/IncidentPage';
import IntegrationsBrowsePage from '../pages/IntegrationsBrowsePage/IntegrationsBrowsePage';
import IntegrationsListPage from '../pages/IntegrationsPage';
import InternalMarketplaceListPage from '../pages/InternalMarketplaceListPage/InternalMarketplaceListPage';
import InternalMarketplaceReviewPage from '../pages/InternalMarketplaceReviewPage/InternalMarketplaceReviewPage';
import InternalPage from '../pages/InternalPage';
import BackgroundJobPage from '../pages/InternalPage/BackgroundJobPage/BackgroundJobPage';
import { InspectAIChatsPage } from '../pages/InternalPage/InspectAIChatsPage/InspectAIChatsPage.tsx';
import InspectIntegrationPage from '../pages/InternalPage/InspectIntegrationPage/InspectIntegrationPage.tsx';
import InspectSQLLineagePage from '../pages/InternalPage/InspectSQLLineagePage/InspectSQLLineagePage.tsx';
import IntegrationsStatusPage from '../pages/InternalPage/IntegrationsStatusPage';
import QueryActionsPage from '../pages/InternalPage/QueryActionsPage';
import JobPage from '../pages/JobPage';
import LegacyNonTeamPage from '../pages/LegacyNonTeamPage';
import { LEGACY_NON_TEAM_PATHS } from '../pages/LegacyNonTeamPage/constants';
import MarketplaceIntegrationSpecDetailPage from '../pages/MarketplaceIntegrationSpecDetailPage/MarketplaceIntegrationSpecDetailPage';
import MarketplaceIntegrationSpecVersionDetailPage from '../pages/MarketplaceIntegrationSpecVersionDetailPage/MarketplaceIntegrationSpecVersionDetailPage';
import MonitorListPage from '../pages/MonitorListPage/MonitorListPage';
import MonitorPage from '../pages/MonitorPage';
import NewMarketplaceIntegrationSpecVersionPage from '../pages/NewMarketplaceIntegrationSpecVersionPage/NewMarketplaceIntegrationSpecVersionPage';
import OnboardingViewerWelcomePage from '../pages/Onboarding/OnboardingViewerWelcomePage';
import PlansPage from '../pages/PlansPage/PlansPage';
import CreatePolicyModal from '../pages/PoliciesCreatePage/CreatePolicyModal';
import PoliciesListPage from '../pages/PoliciesListPage/PoliciesListPage.tsx';
import EditPolicyPage from '../pages/PolicyPage/EditPolicyPage';
import PolicyPage from '../pages/PolicyPage/PolicyPage.tsx';
import ProfilePage from '../pages/ProfilePage';
import QueryPage from '../pages/QueryPage/QueryPage';
import QuestionPage from '../pages/QuestionPage';
import RequestPage from '../pages/RequestPage/RequestPage.tsx';
import { RequestPageSkeleton } from '../pages/RequestPage/RequestPageSkeleton.tsx';
import RequestsPage from '../pages/RequestsPage/RequestsPage.tsx';
import { RootAuthRedirect } from '../pages/RootPage/RootAuthRedirect';
import { RootHomeRedirect } from '../pages/RootPage/RootHomeRedirect';
import SchemaPage from '../pages/SchemaPage';
import SearchPage from '../pages/SearchPage';
import TableEntityPage from '../pages/TableEntityPage';
import TeamCatalogPage from '../pages/TeamCatalogPage/TeamCatalogPage';
import TeamCollectionsPage from '../pages/TeamCollectionsPage';
import TeamDocumentsPage from '../pages/TeamDocumentsPage';
import TeamGlossaryTermsPage from '../pages/TeamGlossaryTermsPage/TeamGlossaryTermsPage';
import TeamQuestionsPage from '../pages/TeamQuestionsPage';
import TeamsPage from '../pages/TeamsPage/TeamsPage';
import TemplatePage from '../pages/TemplatePage';
import TrashPage from '../pages/TrashPage/TrashPage';
import TunnelPage from '../pages/TunnelPage/TunnelPage.tsx';
import TunnelsListPage from '../pages/TunnelsListPage/index.tsx';
import UserGroupProfilePage from '../pages/UserGroupProfilePage';
import WorkspaceQueriesPage from '../pages/WorkspaceQueriesPage/WorkspaceQueriesPage';

export function withSuspenseWrapper<T>(
	WrappedComponent: React.ComponentType<T>,
	pageTitle?: string,
	SkeletonComponent?: React.ComponentType
) {
	// DisplayName for React Dev Tools.
	const displayName =
		WrappedComponent.displayName || WrappedComponent.name || 'Component';

	function ComponentWithSuspense(props: T) {
		const location = useLocation();
		const params = useParams();

		let { pathname } = location;
		if (params.tab) {
			// we remove the tab param from the ErrorBoundary key to avoid forcing a re-render of the entire page on tab changes
			pathname = pathname.replace(`/${params.tab}`, '');
		}

		// If an error is thrown inside a page, the ErrorBoundary will not be reset until the page is unmounted and remounted.
		// Changing routes between documents, for example, won't unmount the page; so the ErrorBoundary will not be reset unless we change its key.
		return (
			<ErrorBoundary key={pathname}>
				<Suspense
					fallback={
						SkeletonComponent ? <SkeletonComponent /> : <LoadingSpinner />
					}
				>
					{pageTitle && (
						<Helmet>
							<title>{pageTitle}</title>
						</Helmet>
					)}
					<WrappedComponent {...(props as any)} />
				</Suspense>
			</ErrorBoundary>
		);
	}

	ComponentWithSuspense.displayName = `withSuspenseWrapper(${displayName})`;

	return ComponentWithSuspense;
}

const UserGroupProfilePageWithSuspense =
	withSuspenseWrapper(UserGroupProfilePage);
const DashboardPageWithSuspense = withSuspenseWrapper(
	DashboardPage,
	'Dashboard',
	EntityPageLayoutSkeleton
);
const ChartPageWithSuspense = withSuspenseWrapper(
	ChartPage,
	'Chart',
	EntityPageLayoutSkeleton
);
const JobPageWithSuspense = withSuspenseWrapper(
	JobPage,
	'Job',
	EntityPageLayoutSkeleton
);
const EventPageWithSuspense = withSuspenseWrapper(
	EventPage,
	'Event',
	EntityPageLayoutSkeleton
);
const SearchPageWithSuspense = withSuspenseWrapper(SearchPage, 'Search');
const InboxPageWithSuspense = withSuspenseWrapper(InboxPage, 'Inbox');
const TableEntityPageWithSuspense = withSuspenseWrapper(
	TableEntityPage,
	'Table Entity',
	EntityPageLayoutSkeleton
);
const ColumnEntityPageWithSuspense = withSuspenseWrapper(
	ColumnEntityPage,
	'Column Entity',
	EntityPageLayoutSkeleton
);
const DatabasePageWithSuspense = withSuspenseWrapper(
	DatabasePage,
	'Database',
	EntityPageLayoutSkeleton
);
const SchemaPageWithSuspense = withSuspenseWrapper(
	SchemaPage,
	'Schema',
	EntityPageLayoutSkeleton
);
const ProfilePageWithSuspense = withSuspenseWrapper(ProfilePage);
const TunnelPageWithSuspense = withSuspenseWrapper(TunnelPage, 'Tunnel');
const TunnelsListPageWithSuspense = withSuspenseWrapper(
	TunnelsListPage,
	'Tunnels'
);
const SettingsPageWithSuspense = withSuspenseWrapper(SettingsPage);
const IntegrationPageWithSuspense = withSuspenseWrapper(
	IntegrationPage,
	'Integrations'
);
const IntegrationsListPageWithSuspense = withSuspenseWrapper(
	IntegrationsListPage,
	'Integrations',
	EntityListPageSkeleton
);
const IntegrationSelectionPageWithSuspense = withSuspenseWrapper(
	IntegrationSelectionPage,
	'Integrations'
);
const CollectionPageWithSuspense = withSuspenseWrapper(
	CollectionPage,
	'Collection',
	EntityPageLayoutSkeleton
);
const GlossaryTermPageWithSuspense = withSuspenseWrapper(
	GlossaryTermPage,
	'Glossary Term',
	GlossaryTermPageSkeleton
);
const QuestionPageWithSuspense = withSuspenseWrapper(QuestionPage, 'Question');
const DocumentPageWithSuspense = withSuspenseWrapper(
	DocumentPage,
	'Document',
	DocumentPageSkeleton
);
const PlansPageWithSuspense = withSuspenseWrapper(PlansPage, 'Plans');
const HomePageWithSuspense = withSuspenseWrapper(HomePage);
const TeamHomePageWithSuspense = withSuspenseWrapper(TeamHomePage, 'Team Home');
const TemplatePageWithSuspense = withSuspenseWrapper(TemplatePage, 'Template');
const EmbeddedPromptPageWithSuspense = withSuspenseWrapper(
	EmbeddedPromptPage,
	'Secoda AI'
);
const AnalyticsListPageWithSuspense = withSuspenseWrapper(
	AnalyticsListPage,
	'Analytics'
);
const RequestsPageWithSuspense = withSuspenseWrapper(
	RequestsPage,
	'Requests',
	EntityListPageSkeleton
);
const RequestPageWithSuspense = withSuspenseWrapper(
	RequestPage,
	'Request',
	RequestPageSkeleton
);
const AnalyticsPageWithSuspense = withSuspenseWrapper(
	AnalyticsPage,
	'Analytics'
);
const TeamCatalogPageWithSuspense = withSuspenseWrapper(
	TeamCatalogPage,
	'Catalog',
	EntityListPageSkeleton
);
const TeamCollectionsPageWithSuspense = withSuspenseWrapper(
	TeamCollectionsPage,
	'Collections',
	EntityListPageSkeleton
);
const TeamDocumentsPageWithSuspense = withSuspenseWrapper(
	TeamDocumentsPage,
	'Documents',
	EntityListPageSkeleton
);
const TeamGlossaryTermsPageWithSuspense = withSuspenseWrapper(
	TeamGlossaryTermsPage,
	'Glossary',
	EntityListPageSkeleton
);
const TeamQuestionsPageWithSuspense = withSuspenseWrapper(
	TeamQuestionsPage,
	'Questions',
	EntityListPageSkeleton
);
const TeamsPageWithSuspense = withSuspenseWrapper(TeamsPage, 'Teams');
const MonitorPageWithSuspense = withSuspenseWrapper(MonitorPage, 'Monitor');
const MonitorListPageWithSuspense = withSuspenseWrapper(
	MonitorListPage,
	'Monitors',
	EntityListPageSkeleton
);
const AddMonitorPageWithSuspense = withSuspenseWrapper(
	AddMonitorPage,
	'Monitor'
);
const PoliciesListPageWithSuspense = withSuspenseWrapper(
	PoliciesListPage,
	'Policies',
	EntityListPageSkeleton
);
const PolicyPageWithSuspense = withSuspenseWrapper(
	PolicyPage,
	'Policy',
	GlossaryTermPageSkeleton
);
const PoliciesCreatePageWithSuspense = withSuspenseWrapper(
	CreatePolicyModal,
	'Policy'
);

const EditPolicyPageWithSuspense = withSuspenseWrapper(
	EditPolicyPage,
	'Policy'
);

const InternalPageWithSuspense = withSuspenseWrapper(InternalPage);
const IncidentPageWithSuspense = withSuspenseWrapper(IncidentPage, 'Incident');
const TrashPageWithSuspense = withSuspenseWrapper(TrashPage, 'Archived');
const AutomationPageWithSuspense = withSuspenseWrapper(
	AutomationPage,
	'Automation'
);
const AutomationListPageWithSuspense = withSuspenseWrapper(
	AutomationListPage,
	'Automations',
	EntityListPageSkeleton
);
const NewMarketplaceIntegrationSpecVersionPageWithSuspense =
	withSuspenseWrapper(NewMarketplaceIntegrationSpecVersionPage, 'Integrations');

const MarketplaceIntegrationSpecDetailPageWithSuspense = withSuspenseWrapper(
	MarketplaceIntegrationSpecDetailPage,
	'Integrations'
);

const MarketplaceIntegrationSpecVersionDetailPageWithSuspense =
	withSuspenseWrapper(
		MarketplaceIntegrationSpecVersionDetailPage,
		'Integrations'
	);

const IntegrationsBrowsePageWithSuspense = withSuspenseWrapper(
	IntegrationsBrowsePage,
	'Integrations'
);

const InternalMarketplaceListPageWithSuspense = withSuspenseWrapper(
	InternalMarketplaceListPage,
	'Integrations',
	EntityListPageSkeleton
);

const InternalMarketplaceReviewPageWithSuspense = withSuspenseWrapper(
	InternalMarketplaceReviewPage,
	'Integrations'
);
const QueryPageWithSuspense = withSuspenseWrapper(QueryPage, 'Query');
const WorkspaceQueriesPageWithSuspense = withSuspenseWrapper(
	WorkspaceQueriesPage,
	'Queries',
	EntityListPageSkeleton
);
const CreateRolePageWithSuspense = withSuspenseWrapper(
	CreateRolePage,
	'Create Role'
);
const UpdateRolePageWithSuspense = withSuspenseWrapper(
	UpdateRolePage,
	'Edit Role'
);

export const routes = (
	<Route
		element={
			// ModalsProvider is used to render modals in the root of the app.
			// This is unique in that it needs to be within the react-router but outside all routes.
			<ErrorBoundary>
				<ModalManagerProvider modalProps={{ size: 'lg' }}>
					<Outlet />
				</ModalManagerProvider>
			</ErrorBoundary>
		}
	>
		<Route
			loader={() => queryClient.fetchQuery(thisUserQuery())}
			errorElement={
				<ErrorBoundary>
					<RootAuthRedirect />
				</ErrorBoundary>
			}
			path="/"
			element={
				<PrivateRoute>
					<RootHomeRedirect />
				</PrivateRoute>
			}
		/>
		<Route
			path="/auth"
			element={
				<AuthStepStartContextProvider>
					<AuthStepLogin />
				</AuthStepStartContextProvider>
			}
		/>
		<Route path="/auth/login" element={<RootAuthRedirect />} />
		<Route
			path="/auth/login-sso"
			element={
				<AuthStepStartContextProvider>
					<AuthStepSamlSso />
				</AuthStepStartContextProvider>
			}
		/>
		<Route
			path="/auth/signup"
			element={
				<AuthStepStartContextProvider>
					<AuthStepSignup />
				</AuthStepStartContextProvider>
			}
		/>
		<Route path="/auth/verify" element={<AuthStepVerify />} />
		<Route path="/auth/callback" element={<AuthCallback />} />
		<Route path="/auth/signout" element={<AuthSignout />} />
		<Route path="/auth/utilities/account" element={<AuthImpersonate />} />
		<Route
			errorElement={
				<ErrorBoundary>
					<RootAuthRedirect />
				</ErrorBoundary>
			}
			loader={async () => queryClient.fetchQuery(thisUserQuery())}
		>
			<Route path="/internal" element={<InternalPageWithSuspense />}>
				<Route index element={<Navigate replace to="integrations" />} />
				<Route path="integrations" element={<IntegrationsStatusPage />} />
				<Route path="query-actions" element={<QueryActionsPage />} />
				<Route
					path="inspect-integration"
					element={<InspectIntegrationPage />}
				/>
				<Route path="background-job" element={<BackgroundJobPage />} />
				<Route path="inspect-sql-lineage" element={<InspectSQLLineagePage />} />
				<Route path="inspect-ai-chats" element={<InspectAIChatsPage />} />
			</Route>
			<Route
				path="/internal/marketplace"
				element={<InternalMarketplaceListPageWithSuspense />}
			/>
			<Route
				path="/internal/marketplace/review/:specId/version/:versionId"
				element={<InternalMarketplaceReviewPageWithSuspense />}
			/>
			<Route path="/download" element={<DownloadPage />} />
			<Route path="/onboarding/profile" element={<AuthStepProfile />} />
			<Route path="/onboarding/workspace" element={<AuthStepWorkspace />} />
			<Route path="/onboarding/confirm" element={<AuthStepConfirm />} />
			<Route
				path="/onboarding/welcome"
				element={
					<PrivateRoute hideNavBar hideSideBar>
						<OnboardingViewerWelcomePage />
					</PrivateRoute>
				}
			/>
			<Route path="/404" element={<ErrorPage code={404} />} />
			<Route path="/500" element={<ErrorPage code={500} />} />
			<Route path="/403" element={<ErrorPage code={403} />} />
			<Route
				path="/plans"
				element={
					<PrivateRoute hideSideBar v2PermissionRequired="Workspace.Manage">
						<PlansPageWithSuspense />
					</PrivateRoute>
				}
			/>
			<Route
				path="/roles/new"
				element={
					<PrivateRoute hideSideBar v2PermissionRequired="Roles.Create">
						<CreateRolePageWithSuspense />
					</PrivateRoute>
				}
			/>
			<Route
				path="/roles/edit/:roleId"
				element={
					<PrivateRoute hideSideBar v2PermissionRequired="Roles.Update">
						<UpdateRolePageWithSuspense />
					</PrivateRoute>
				}
			/>
			<Route element={<SecodaAppShell />}>
				<Route
					path="/chrome-extension"
					element={
						<PrivateRoute>
							<ChromeExtensionPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/group/:id"
					element={
						<PrivateRoute v2PermissionRequired="Users.Read">
							<UserGroupProfilePageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/dashboard/:id/:tab?"
					element={
						<PrivateRoute hideNavBar v2PermissionRequired="Resources.Read">
							<DashboardPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/chart/:id/:tab?"
					element={
						<PrivateRoute hideNavBar v2PermissionRequired="Resources.Read">
							<ChartPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/job/:id/:tab?"
					element={
						<PrivateRoute hideNavBar v2PermissionRequired="Resources.Read">
							<JobPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/event/:id/:tab?"
					element={
						<PrivateRoute hideNavBar v2PermissionRequired="Resources.Read">
							<EventPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/search"
					element={
						<PrivateRoute>
							<SearchPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/inbox"
					element={
						<PrivateRoute>
							<InboxPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/analytics"
					element={
						<PrivateRoute
							guestRestricted
							editorRequired
							v2PermissionRequired="Analytics.Read"
						>
							<AnalyticsListPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/requests"
					element={
						<PrivateRoute>
							<RequestsPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/requests/:id"
					element={
						<PrivateRoute>
							<RequestPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/analytics/:id"
					element={
						<PrivateRoute
							guestRestricted
							editorRequired
							v2PermissionRequired="Analytics.Read"
						>
							<AnalyticsPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/table/:id/:tab?"
					element={
						<PrivateRoute hideNavBar v2PermissionRequired="Resources.Read">
							<TableEntityPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/column/:id/:tab?"
					element={
						<PrivateRoute hideNavBar v2PermissionRequired="Resources.Read">
							<ColumnEntityPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/database/:id/:tab?"
					element={
						<PrivateRoute hideNavBar v2PermissionRequired="Resources.Read">
							<DatabasePageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/schema/:id/:tab?"
					element={
						<PrivateRoute hideNavBar v2PermissionRequired="Resources.Read">
							<SchemaPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/monitor/:id"
					element={
						<PrivateRoute
							hideNavBar
							v2PermissionRequired="Monitors.Read"
							editorRequired
						>
							<MonitorPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/automation/:id"
					element={
						<PrivateRoute
							editorRequired
							hideNavBar
							v2PermissionRequired="Automations.Read"
						>
							<AutomationPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/incident/:id"
					element={
						<PrivateRoute
							hideNavBar
							v2PermissionRequired="Monitors.Read"
							editorRequired
						>
							<IncidentPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/user/:id"
					element={
						<PrivateRoute v2PermissionRequired="Users.Read">
							<ProfilePageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/tunnels/new"
					element={
						<PrivateRoute v2PermissionRequired="Integrations.Manage">
							<TunnelPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/tunnels/:id"
					element={
						<PrivateRoute v2PermissionRequired="Integrations.Manage">
							<TunnelPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/tunnels"
					element={
						<PrivateRoute v2PermissionRequired="Integrations.Manage">
							<TunnelsListPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route path="/billing/callback" element={<BillingCallback />} />
				<Route
					path="/settings/:tab?/:subtab?"
					element={
						<PrivateRoute>
							<SettingsPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/integrations"
					element={
						<PrivateRoute
							adminRequired
							v2PermissionRequired="Integrations.Manage"
						>
							<IntegrationsListPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/integrations/new"
					element={
						<PrivateRoute
							adminRequired
							hideNavBar
							hideSideBar
							v2PermissionRequired="Integrations.Manage"
						>
							<IntegrationSelectionPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/integrations/browse"
					element={
						<PrivateRoute
							adminRequired
							hideNavBar
							v2PermissionRequired="Integrations.Manage"
						>
							<IntegrationsBrowsePageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/integrations/:id"
					element={
						<PrivateRoute
							adminRequired
							v2PermissionRequired="Integrations.Manage"
						>
							<IntegrationPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/integrations/:id/:tab"
					element={
						<PrivateRoute
							adminRequired
							v2PermissionRequired="Integrations.Manage"
						>
							<IntegrationPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/extensions/:id"
					element={
						<PrivateRoute
							adminRequired
							v2PermissionRequired="Integrations.Manage"
						>
							<IntegrationPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/extensions/:id/:tab"
					element={
						<PrivateRoute
							adminRequired
							v2PermissionRequired="Integrations.Manage"
						>
							<IntegrationPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/metrics/:id"
					loader={({ params }) => redirect(`/glossary/${params.id}`)}
				/>
				<Route
					path="/dictionary/:id"
					loader={({ params }) => redirect(`/glossary/${params.id}`)}
				/>
				<Route
					path="/collections/:id"
					element={
						<PrivateRoute hideNavBar>
							<CollectionPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/collections/:id/:tab"
					element={
						<PrivateRoute hideNavBar>
							<CollectionPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/glossary/:id"
					element={
						<PrivateRoute hideNavBar>
							<GlossaryTermPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/questions/:id"
					element={
						<PrivateRoute hideNavBar>
							<QuestionPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/docs/:id"
					element={
						<PrivateRoute hideNavBar>
							<DocumentPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/templates/:id/"
					element={
						<PrivateRoute hideNavBar>
							<TemplatePageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/ai/:id/"
					element={
						<PrivateRoute hideNavBar v2PermissionRequired="SecodaAI.Read">
							<EmbeddedPromptPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/home"
					element={
						<PrivateRoute>
							<HomePageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:teamId/"
					element={
						<PrivateRoute>
							<TeamPageRoute pageKey="home">
								<TeamHomePageWithSuspense />
							</TeamPageRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:teamId/catalog"
					element={
						<PrivateRoute v2PermissionRequired="Resources.Read">
							<TeamPageRoute pageKey="catalog">
								<TeamCatalogPageWithSuspense />
							</TeamPageRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:teamId/collections"
					element={
						<PrivateRoute v2PermissionRequired="Resources.Read">
							<TeamPageRoute pageKey="collections">
								<TeamCollectionsPageWithSuspense />
							</TeamPageRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:teamId/dictionary"
					loader={({ params }) => redirect(`/teams/${params.teamId}/glossary`)}
				/>
				<Route
					path="/teams/:teamId/metrics"
					loader={({ params }) => redirect(`/teams/${params.teamId}/glossary`)}
				/>
				<Route
					path="/teams/:teamId/docs"
					element={
						<PrivateRoute v2PermissionRequired="Resources.Read">
							<TeamPageRoute pageKey="documents">
								<TeamDocumentsPageWithSuspense />
							</TeamPageRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:teamId/glossary"
					element={
						<PrivateRoute v2PermissionRequired="Resources.Read">
							<TeamPageRoute pageKey="glossary">
								<TeamGlossaryTermsPageWithSuspense />
							</TeamPageRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:teamId/questions"
					element={
						<PrivateRoute v2PermissionRequired="Questions.Read">
							<TeamPageRoute pageKey="questions">
								<TeamQuestionsPageWithSuspense />
							</TeamPageRoute>
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams"
					element={
						<PrivateRoute guestRestricted v2PermissionRequired="Teams.Read">
							<TeamsPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/monitors/:tab?"
					element={
						<PrivateRoute v2PermissionRequired="Monitors.Read" editorRequired>
							<MonitorListPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/monitors/new/"
					element={
						<PrivateRoute
							editorRequired
							hideNavBar
							hideSideBar
							v2PermissionRequired="Monitors.Create"
						>
							<AddMonitorPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/policies/:tab?"
					element={
						<PrivateRoute v2PermissionRequired="Policies.Read">
							<PoliciesListPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="policies/new"
					element={
						<PrivateRoute
							hideSideBar
							hideNavBar
							editorRequired
							v2PermissionRequired="Policies.Create"
						>
							<PoliciesCreatePageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="policy/:id/:tab?"
					element={
						<PrivateRoute v2PermissionRequired="Policies.Read">
							<PolicyPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="policy/:id/edit"
					element={
						<PrivateRoute
							hideSideBar
							hideNavBar
							v2PermissionRequired="Policies.Create"
						>
							<EditPolicyPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/automations"
					element={
						<PrivateRoute
							editorRequired
							v2PermissionRequired="Automations.Read"
						>
							<AutomationListPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/archived"
					element={
						<PrivateRoute adminRequired v2PermissionRequired="Resources.Manage">
							<TrashPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/queries"
					element={
						<PrivateRoute hideNavBar v2PermissionRequired="Queries.Read">
							<WorkspaceQueriesPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/marketplace-integration/new"
					element={
						<PrivateRoute
							hideNavBar
							adminRequired
							v2PermissionRequired="Integrations.Manage"
						>
							<NewMarketplaceIntegrationSpecVersionPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/marketplace-integration/:specId"
					element={
						<PrivateRoute
							hideNavBar
							adminRequired
							v2PermissionRequired="Integrations.Manage"
						>
							<MarketplaceIntegrationSpecDetailPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/marketplace-integration/:specId/new-version"
					element={
						<PrivateRoute
							hideNavBar
							adminRequired
							v2PermissionRequired="Integrations.Manage"
						>
							<NewMarketplaceIntegrationSpecVersionPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/marketplace-integration/:specId/version/:versionId"
					element={
						<PrivateRoute
							hideNavBar
							adminRequired
							v2PermissionRequired="Integrations.Manage"
						>
							<MarketplaceIntegrationSpecVersionDetailPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/marketplace-integration/:specId/version/:versionId"
					element={
						<PrivateRoute
							hideNavBar
							adminRequired
							v2PermissionRequired="Integrations.Manage"
						>
							<MarketplaceIntegrationSpecVersionDetailPageWithSuspense />
						</PrivateRoute>
					}
				/>
				<Route
					path="/query/:id"
					element={
						<PrivateRoute hideNavBar v2PermissionRequired="Queries.Read">
							<QueryPageWithSuspense />
						</PrivateRoute>
					}
				/>

				<>
					{LEGACY_NON_TEAM_PATHS.map((path) => (
						<Route
							key={path}
							path={path}
							element={
								<PrivateRoute>
									<LegacyNonTeamPage oldPath={path} />
								</PrivateRoute>
							}
						/>
					))}
				</>
			</Route>
		</Route>
		<Route path="*" element={<ErrorPage code={404} />} />
	</Route>
);
