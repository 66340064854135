import * as React from "react";
import type { QueryClient } from "@tanstack/react-query";
import type { NormalizerConfig } from "./core";

import { createQueryNormalizer } from "./create-query-normalizer";

const QueryNormalizerContext = React.createContext<
	undefined | ReturnType<typeof createQueryNormalizer>
>(undefined);

export function QueryNormalizerProvider({
	queryClient,
	normalizerConfig,
	children,
}: {
	queryClient: QueryClient;
	children: React.ReactNode;
	normalizerConfig?: NormalizerConfig & { normalize?: boolean };
}) {
	const [queryNormalizer] = React.useState(() =>
		createQueryNormalizer(queryClient, normalizerConfig),
	);

	React.useEffect(() => {
		queryNormalizer.subscribe();

		return () => {
			queryNormalizer.unsubscribe();
			queryNormalizer.clear();
		};
	}, [queryNormalizer]);

	return (
		<QueryNormalizerContext.Provider value={queryNormalizer}>
			{children}
		</QueryNormalizerContext.Provider>
	);
}

export const useQueryNormalizer = () => {
	const queryNormalizer = React.useContext(QueryNormalizerContext);

	if (!queryNormalizer) {
		throw new Error(
			"No QueryNormalizer set, use QueryNormalizerProvider to set one",
		);
	}

	return queryNormalizer;
};
