import { Center, createStyles, Divider, Group, Stack } from '@mantine/core';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Icon, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { capitalize, isEmpty, map, omitBy } from 'lodash-es';
import type React from 'react';
import { useNotificationList } from '../../../../../../../api/hooks/notification';
import type { INotification } from '../../../../../../../api/types/models/notification';
import InboxListItemIcon from '../../../../../../InboxPage/InboxList/InboxListHeader/InboxListItemIcon';
import { NotificationTitle } from '../../../../../../InboxPage/NotificationTitle';
import { WIDGET_CONTENT_HEIGHT } from '../../../constants';

const useStyles = createStyles((theme) => ({
	emptyState: {
		height: WIDGET_CONTENT_HEIGHT,
		flexDirection: 'column',
		gap: theme.spacing.md,
	},
	inbox: {
		cursor: 'pointer',
		borderRadius: theme.radius.xs,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/transparent/hover'),
		},
	},
	markdown: {
		color: theme.other.getColor('text/secondary/default'),
		fontSize: theme.fontSizes.sm,
	},
}));

function Notification({ notification }: { notification: INotification }) {
	return (
		<>
			<Group align="self-start" noWrap>
				<InboxListItemIcon item={notification} />
				<Stack spacing={4}>
					<Text
						size="sm"
						lineClamp={1}
						data-testid="inbox-widget-notification-title-40a2e05a0"
					>
						<NotificationTitle notification={notification} />
					</Text>
					<Text size="xs" color="text/secondary/default" lineClamp={1}>
						<Group spacing={4} noWrap>
							<span>{dayjs(notification.created_at).fromNow()}</span>
							<span>•</span>
							<span>{capitalize(notification.resource_type)}</span>
						</Group>
					</Text>
				</Stack>
			</Group>
			<Divider />
		</>
	);
}

function InboxWidget() {
	const { classes } = useStyles();
	const navigateHandler = useNavigateHandler();

	const { isFetched, data: notifications } = useNotificationList({
		filters: {
			distinct: 'dispatch_id',
		},
		options: {
			select: ({ results }) => omitBy(results, { event: 'announcement' }),
		},
	});

	if (isFetched && isEmpty(notifications)) {
		return (
			<Center className={classes.emptyState}>
				<Icon name="speakerphone" size="lg" color="icon/primary/default" />

				<Stack spacing="none">
					<Text
						size="sm"
						weight="bold"
						align="center"
						color="text/secondary/default"
					>
						No new announcements
					</Text>
					<Text size="sm" align="center" color="text/secondary/default">
						Check back later for updates
					</Text>
				</Stack>
			</Center>
		);
	}

	const handleNotificationClick =
		(notification: INotification) => (event: React.MouseEvent) => {
			navigateHandler(event)(`/inbox?id=${notification.id}`);
		};

	return (
		<Stack spacing={0}>
			{map(notifications, (notification) => (
				<Stack
					key={notification.id}
					pt="sm"
					px="sm"
					spacing="sm"
					className={classes.inbox}
					onClick={handleNotificationClick(notification)}
				>
					<Notification key={notification.id} notification={notification} />
				</Stack>
			))}
		</Stack>
	);
}

export default InboxWidget;
