import {
	ActionIcon,
	createStyles,
	Group,
	Loader,
	TextInput,
} from '@mantine/core';
import { rem } from '@mantine/styles';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon } from '@repo/foundations';
import { isEmpty, isNil } from 'lodash-es';
import { useAiEnabled } from '../../../hooks/useAIEnabled';
import AskAIModal from '../../AskAIModal/AskAIModal';

interface IPaletteInputProps {
	type: 'command' | 'search';
	value: string;
	placeholder: string;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	loading?: boolean;
	onEscape?: VoidFunction;
	loader?: React.ReactNode;
	icon?: React.ReactNode;
	onFocus?: VoidFunction;
	withAI?: boolean;
}

const useStyles = createStyles((theme) => ({
	input: {
		padding: theme.spacing.lg,
		paddingLeft: `calc(${theme.other.space[10]}px + 4px) !important`,
		borderRadius: 0,
		border: 'none',
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		borderStartStartRadius: theme.spacing.sm,
		borderStartEndRadius: theme.spacing.sm,
		'&:focus, &:focus-within': {
			borderColor: theme.other.getColor('border/secondary/default'),
			boxShadow: 'none',
		},
	},
	rightSection: {
		// 2 * width icon + padding
		width: `calc(2 * ${rem(theme.other.space[8])} + ${rem(
			theme.other.space[4]
		)})`,
	},
}));

function PaletteInput({
	type,
	value,
	loading,
	onChange,
	onEscape,
	placeholder,
	loader,
	icon,
	onFocus,
	withAI = true,
}: IPaletteInputProps) {
	const { classes, theme } = useStyles();

	const enableAi = useAiEnabled();

	const loadingIcon = loader || <Loader size={theme.other.iconSize.md} />;
	const inputIcon = icon || <Icon name="search" color="icon/brand/default" />;

	const leftIcon = loading ? loadingIcon : inputIcon;

	const navigate = useNavigate();

	const handleNavigateToSearch = () => {
		let path = '/search';
		if (!isEmpty(value)) {
			path = `/search?term=${value}`;
		}
		navigate(path);
		if (!isNil(onEscape)) {
			onEscape();
		}
	};

	const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		// Prevent emacs keybindings to behave like macOS Spotlight
		if (event.ctrlKey && (event.key === 'n' || event.key === 'p')) {
			event.preventDefault();
		}

		switch (event.key) {
			case 'ArrowDown': {
				event.preventDefault();

				break;
			}

			case 'ArrowUp': {
				event.preventDefault();

				break;
			}

			case 'Enter': {
				event.preventDefault();
				break;
			}

			case 'Tab': {
				event.preventDefault();
				break;
			}

			case 'Escape': {
				if (onEscape === undefined) break;

				event.preventDefault();
				onEscape();
				break;
			}

			default:
				if (event.ctrlKey) break;
				if (onFocus) {
					onFocus();
				}
				break;
		}
	};

	return (
		<TextInput
			value={value}
			data-autofocus
			icon={leftIcon}
			classNames={{
				input: classes.input,
				rightSection: classes.rightSection,
			}}
			size="lg"
			placeholder={placeholder}
			onChange={onChange}
			onKeyDown={handleInputKeyDown}
			rightSection={
				<Group spacing={0} noWrap>
					{withAI && enableAi && <AskAIModal initialSearchTerm={value} />}
					{type === 'search' && (
						<ActionIcon variant="subtle" onClick={handleNavigateToSearch}>
							<Icon name="arrowsDiagonal" />
						</ActionIcon>
					)}
				</Group>
			}
		/>
	);
}

export default PaletteInput;
