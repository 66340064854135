import { showNotification } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';

import { deleteParamsFromUrl } from '../../../utils/url';
import { apiClient, authHeaders, getEndpoints } from '../../common';
import { ACCESS_REQUESTED_NAMESPACE } from './constants';
import { invalidateNotificationListQuery } from './utils';

interface UseRequestAccessArgs {
	entityId?: string;
}

export function useRequestAccess({ entityId }: UseRequestAccessArgs) {
	const mutationFn = async (): Promise<null> => {
		if (!entityId) {
			return null;
		}

		const url = `${getEndpoints(ACCESS_REQUESTED_NAMESPACE).root()}`;
		const headers = authHeaders();
		const param = { entity: entityId };

		const { data } = await apiClient.post(url, param, headers);
		return data;
	};

	return useMutation({
		mutationFn,
		onSuccess: () => {
			deleteParamsFromUrl('id');
			invalidateNotificationListQuery();
			showNotification({
				title: 'Access request sent',
				message:
					'You will receive a notification when an admin reviews the request',
				color: 'green',
			});
		},
		onError: () => {
			showNotification({
				title: 'Access request failed to send',
				message: 'Please contact customer support if the issue continues',
				color: 'red',
			});
		},
	});
}
