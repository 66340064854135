import { useLocalStorage, useResizeObserver, useTimeout } from '@mantine/hooks';
import type { Key } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { DataTableColumn, DataTableRowExpansionProps } from './types';
import { getRecordId } from './utils';

export function useLastSelectionChangeIndex(recordIds: unknown[] | undefined) {
	const [lastSelectionChangeIndex, setLastSelectionChangeIndex] = useState<
		number | null
	>(null);
	const recordIdsString = recordIds?.join(':') || '';
	useEffect(() => {
		setLastSelectionChangeIndex(null);
	}, [recordIdsString]);

	return { lastSelectionChangeIndex, setLastSelectionChangeIndex };
}

export function useRowContextMenu<T>(fetching?: boolean) {
	const [rowContextMenuInfo, setRowContextMenuInfo] = useState<{
		y: number;
		x: number;
		record: T;
		recordIndex: number;
	} | null>(null);
	useEffect(() => {
		if (fetching) setRowContextMenuInfo(null);
	}, [fetching]);
	return { rowContextMenuInfo, setRowContextMenuInfo };
}

export function useRowExpansion<T>({
	rowExpansion,
	records,
	idAccessor,
}: {
	rowExpansion?: DataTableRowExpansionProps<T>;
	records: T[] | undefined;
	idAccessor: string | ((record: T) => Key);
}) {
	let initiallyExpandedRecordIds: unknown[] = [];
	if (rowExpansion && records) {
		const { trigger, allowMultiple, initiallyExpanded } = rowExpansion;
		if (records && trigger === 'always') {
			initiallyExpandedRecordIds = records.map((r) =>
				getRecordId(r, idAccessor)
			);
		} else if (initiallyExpanded) {
			initiallyExpandedRecordIds = records
				.filter(initiallyExpanded)
				.map((r) => getRecordId(r, idAccessor));
			if (!allowMultiple) {
				initiallyExpandedRecordIds = [initiallyExpandedRecordIds[0]];
			}
		}
	}

	let expandedRecordIds: unknown[];
	let setExpandedRecordIds:
		| ((expandedRecordIds: unknown[]) => void)
		| undefined;
	const expandedRecordIdsState = useState<unknown[]>(
		initiallyExpandedRecordIds
	);

	if (rowExpansion) {
		const { trigger, allowMultiple, collapseProps, content } = rowExpansion;
		if (rowExpansion.expanded) {
			({
				recordIds: expandedRecordIds,
				onRecordIdsChange: setExpandedRecordIds,
			} = rowExpansion.expanded);
		} else {
			[expandedRecordIds, setExpandedRecordIds] = expandedRecordIdsState;
		}

		const collapseRow = (record: T) =>
			setExpandedRecordIds?.(
				expandedRecordIds.filter((id) => id !== getRecordId(record, idAccessor))
			);

		return {
			expandOnClick: trigger !== 'always' && trigger !== 'never',
			isRowExpanded: (record: T) =>
				trigger === 'always'
					? true
					: expandedRecordIds.includes(getRecordId(record, idAccessor)),
			expandRow: (record: T) => {
				const recordId = getRecordId(record, idAccessor);
				setExpandedRecordIds?.(
					allowMultiple ? [...expandedRecordIds, recordId] : [recordId]
				);
			},
			collapseRow,
			collapseProps,
			content: (record: T, recordIndex: number) => () =>
				content({ record, recordIndex, collapse: () => collapseRow(record) }),
		};
	}
}

export function useRowExpansionStatus(
	open: boolean,
	transitionDuration?: number
) {
	const [expanded, setExpanded] = useState(open);
	const [visible, setVisible] = useState(open);

	const expand = useTimeout(() => setExpanded(true), 0);
	const hide = useTimeout(() => setVisible(false), transitionDuration || 200);

	useEffect(() => {
		if (open) {
			hide.clear();
			setVisible(true);
			expand.start();
		} else {
			expand.clear();
			setExpanded(false);
			hide.start();
		}
	}, [expand, hide, open]);

	return { expanded, visible };
}

export function useElementOuterSize<T extends HTMLElement>() {
	const [ref] = useResizeObserver<T>();
	const { width, height } = ref.current?.getBoundingClientRect() || {
		width: 0,
		height: 0,
	};
	return { ref, width, height };
}

export type DataTableColumnToggle = {
	accessor: string;
	defaultToggle: boolean;
	toggleable: boolean;
	toggled: boolean;
};

type DataTableColumnWidth = Record<string, string | number>;

export const useDataTableColumns = <T>({
	key,
	columns = [],
	DEFAULT_WIDTH = 200,
}: {
	key: string | undefined;
	columns: DataTableColumn<T>[];
	DEFAULT_WIDTH?: string | number;
}) => {
	// create an array of object with key = accessor and value = width
	const defaultColumnsWidth =
		(columns &&
			columns.map((column) => ({
				[column.accessor]: column.width ?? 'initial',
			}))) ||
		[];

	// This is key will change if we add or remove new columns in a table. For
	// example, adding "verified" to dictionary page will change the key, and
	// reset the local ordering, hiding, and width states. Without this key, the
	// new column will not appear.
	const keyPrefix = columns
		.map((c) => c.accessor)
		.sort()
		.join('-');

	// Store the columns width in localStorage
	const [columnsWidth, setColumnsWidth] = useLocalStorage<
		DataTableColumnWidth[]
	>({
		key: `${key}-${keyPrefix}-columns-width`,
		defaultValue: defaultColumnsWidth as DataTableColumnWidth[],
		getInitialValueInEffect: true,
	});

	const resetColumnsWidth = () =>
		setColumnsWidth(defaultColumnsWidth as DataTableColumnWidth[]);

	const refreshColumns = () => {};

	const effectiveColumns = useMemo(() => {
		const result = columns as DataTableColumn<T>[];

		const newWidths = result.map((column, idx) => {
			const width = columnsWidth.find(
				(width) => width[column?.accessor as string]
			)?.[column?.accessor as string];

			let effectiveWidth = width ?? DEFAULT_WIDTH;

			if (
				idx === result.length - 1 &&
				!result.some((c) => c.width === 'auto')
			) {
				// At least 1 column in the table needs to have an unset width so it can adjust to the screen size - https://github.com/secoda/secoda/pull/7000
				effectiveWidth = 'auto';
			}

			return {
				...column,
				width: effectiveWidth,
			};
		});

		return newWidths;
	}, [DEFAULT_WIDTH, columns, columnsWidth]);

	const setColumnWidth = (accessor: string, width: string | number) => {
		const newColumnsWidth = columnsWidth.map((column) => {
			if (!column[accessor]) {
				return column;
			}
			return {
				[accessor]: width,
			};
		});

		setColumnsWidth(newColumnsWidth);
	};

	return {
		effectiveColumns: effectiveColumns as DataTableColumn<T>[],

		refreshColumns,

		// Resize handling
		columnsWidth,
		setColumnsWidth,
		setColumnWidth,
		resetColumnsWidth,
	} as const;
};
