import { Box, createStyles, Input, Slider, Stack } from '@mantine/core';
import { Button, Icon, Text } from '@repo/foundations';
import { isNumber } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import type { Monitor } from '../../../api';
import type { ISelectablePropertyProps } from '../../../components/EntityPageLayout/EntityPropertySidebar';
import {
	SelectableProperty,
	StaticProperty,
} from '../../../components/EntityPageLayout/EntityPropertySidebar';
import { NumericProperty } from '../../../components/EntityPageLayout/EntityPropertySidebar/NumericProperty';
import type { PropertySelectorProps } from '../../../components/Properties/PropertySelector';
import { PropertySelector } from '../../../components/Properties/PropertySelector';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { BOUNDS_OPTIONS, MODEL_OPTIONS, THRESHOLD_OPTIONS } from '../constants';
import { useCodeMonitorTooltip } from '../hooks';
import { formatValue, getMonitorMetricUnit } from '../utils';
import CodeMonitorTooltip from './CodeMonitorTooltip';
import ModelSelectorModal from './ModelSelectorModal';

interface ThresholdSelectorProps
	extends Pick<ISelectablePropertyProps, 'label'>,
		Pick<PropertySelectorProps, 'variant'> {
	value: string;
	onChange: (value: string | number | boolean | string[]) => void;
	threshold: 'automatic' | 'manual';
	monitor?: Monitor;
	data?: Pick<
		Monitor,
		| 'condition_manual_max'
		| 'condition_manual_min'
		| 'condition_auto_sensitivity'
		| 'metric_type'
		| 'bounds'
		| 'anomaly_detection_model'
	>;
	onMinimumValueChange: (value: number | null | undefined) => Promise<void>;
	onMaximumValueChange: (value: number | null | undefined) => Promise<void>;
	onSensitivityChange: (value: number | string) => Promise<void>;
	onBoundsChange: (value: string | number | boolean | string[]) => void;
	onModelChange: (model: string) => Promise<void>;
	readOnly?: boolean;
	showSensitivityControl?: boolean;
}

const useStyles = createStyles((theme) => ({
	slider: {
		width: '100%',
	},
	sliderThumb: {
		color: theme.other.getColor('fill/brand/default'),
	},
	sliderTrack: {
		borderColor: theme.other.getColor('border/primary/default'),
		backgroundColor: theme.other.getColor('fill/brand/default'),
	},
	sliderMarkLabel: {
		color: theme.other.getColor('text/primary/default'),
	},
	modelButton: {
		marginLeft: -8,
	},
	modelButtonText: {
		marginLeft: theme.spacing['3xs'],
	},
}));

function ThresholdSelector({
	data,
	onChange,
	onMinimumValueChange,
	onMaximumValueChange,
	threshold,
	value,
	label,
	onSensitivityChange,
	variant,
	showSensitivityControl = false,
	readOnly = false,
	monitor,
	onBoundsChange,
	onModelChange,
}: ThresholdSelectorProps) {
	const { classes } = useStyles();
	const { monitoringV2, monitorModels } = useFeatureFlags();
	const autoThresholdsMessage =
		"Automatic thresholds use your monitor's historical data to adjust limits, improving accuracy progressively over time.";

	const [sliderValue, setSliderValue] = useState(5);
	const [isModelModalOpen, setIsModelModalOpen] = useState(false);
	const metricType = data?.metric_type ?? 'custom_sql';
	const unit = getMonitorMetricUnit(metricType);
	const previewAvailable = monitorModels && !!monitor?.preview_generated_at;

	// Use the new hook for each wrapper
	const thresholdTypeWrapper = useCodeMonitorTooltip(
		monitor,
		'Threshold type set in code-based monitor'
	);
	const boundsWrapper = useCodeMonitorTooltip(
		monitor,
		'Threshold bounds set in code-based monitor'
	);
	const minWrapper = useCodeMonitorTooltip(
		monitor,
		'Minimum threshold set in code-based monitor'
	);
	const maxWrapper = useCodeMonitorTooltip(
		monitor,
		'Maximum threshold set in code-based monitor'
	);

	useEffect(() => {
		if (data?.condition_auto_sensitivity) {
			setSliderValue(data?.condition_auto_sensitivity);
		}
	}, [data]);

	const handleModelSelect = useCallback(
		(model: string, sensitivity: string) => {
			onModelChange(model);

			// Convert sensitivity string to numeric value for the slider
			let sensitivityValue = 5; // Default medium value
			if (sensitivity === 'low') {
				sensitivityValue = 2;
			} else if (sensitivity === 'high') {
				sensitivityValue = 8;
			}

			onSensitivityChange(sensitivityValue);
			setIsModelModalOpen(false);
		},
		[onModelChange, onSensitivityChange]
	);

	const activeModel =
		MODEL_OPTIONS.find((opt) => opt.id === data?.anomaly_detection_model) ??
		MODEL_OPTIONS[0];

	return (
		<Stack spacing="3xs">
			<SelectableProperty
				label={label}
				dataTestId="threshold"
				labelTooltip={monitoringV2 ? autoThresholdsMessage : undefined}
				wrapValueWith={thresholdTypeWrapper}
			>
				<PropertySelector
					variant={variant}
					selected={value}
					type="single"
					value="threshold"
					iconType="tabler"
					readOnly={readOnly}
					isViewerUser={false}
					options={THRESHOLD_OPTIONS}
					onChange={onChange}
				/>
			</SelectableProperty>
			{threshold === 'automatic' &&
				data?.condition_auto_sensitivity &&
				showSensitivityControl && (
					<Box mb="md">
						<Stack>
							<StaticProperty
								infoTooltip="If adjusted, higher sensitivity catches smaller details, while lower sensitivity focuses on bigger changes."
								label="Sensitivity"
								custom={
									<CodeMonitorTooltip monitor={monitor}>
										<Slider
											classNames={{
												root: classes.slider,
												thumb: classes.sliderThumb,
												track: classes.sliderTrack,
												markLabel: classes.sliderMarkLabel,
											}}
											color="dark"
											label={null}
											showLabelOnHover={false}
											step={1}
											min={1}
											max={10}
											value={sliderValue}
											onChange={setSliderValue}
											onChangeEnd={(val) => onSensitivityChange(val)}
											marks={[
												{ value: 1, label: 'Low' },
												{ value: 5, label: 'Default' },
												{ value: 9, label: 'High' },
											]}
											disabled={readOnly}
										/>
									</CodeMonitorTooltip>
								}
							/>
							<SelectableProperty
								label="Bounds"
								dataTestId="bounds"
								wrapValueWith={boundsWrapper}
							>
								<PropertySelector
									selected={data.bounds ?? 'BOTH'}
									type="single"
									itemSize={48}
									value="bounds"
									variant={variant}
									iconType="tabler"
									isViewerUser={false}
									readOnly={readOnly}
									options={BOUNDS_OPTIONS}
									onChange={onBoundsChange}
								/>
							</SelectableProperty>
							{previewAvailable && (
								<StaticProperty
									label="Model"
									custom={
										<CodeMonitorTooltip monitor={monitor}>
											<Button
												variant="tertiary"
												onClick={() => setIsModelModalOpen(true)}
												className={classes.modelButton}
												size="sm"
												disabled={readOnly}
											>
												<Icon name={activeModel.iconName} />{' '}
												<Text className={classes.modelButtonText} size="sm">
													{activeModel.name}
												</Text>
											</Button>
										</CodeMonitorTooltip>
									}
								/>
							)}
							<ModelSelectorModal
								opened={isModelModalOpen}
								onClose={() => setIsModelModalOpen(false)}
								onSelectModel={handleModelSelect}
								monitor={monitor}
							/>
						</Stack>
					</Box>
				)}
			{threshold === 'manual' && (
				<>
					<NumericProperty
						readOnly={readOnly}
						label="Minimum"
						value={data?.condition_manual_min}
						onValueChange={onMinimumValueChange}
						displayValue={
							isNumber(data?.condition_manual_min)
								? formatValue(metricType, data?.condition_manual_min)
								: undefined
						}
						unit={unit}
						wrapValueWith={minWrapper}
					/>
					<NumericProperty
						readOnly={readOnly}
						label="Maximum"
						value={data?.condition_manual_max}
						displayValue={
							isNumber(data?.condition_manual_max)
								? formatValue(metricType, data?.condition_manual_max)
								: undefined
						}
						onValueChange={onMaximumValueChange}
						unit={unit}
						wrapValueWith={maxWrapper}
					/>

					{data?.condition_manual_max === null &&
						data?.condition_manual_min === null && (
							<Input.Error size="sm" color="critical">
								Please specify either a minimum or maximum threshold. If both
								are left unset, automatic thresholds will be applied.
							</Input.Error>
						)}
					{data?.condition_manual_max !== null &&
						data?.condition_manual_max !== undefined &&
						data?.condition_manual_min !== null &&
						data?.condition_manual_min !== undefined &&
						data?.condition_manual_max < data?.condition_manual_min && (
							<Input.Error size="xs" color="critical" pl="xs" pt="xs">
								The maximum value should be greater than the minimum value.
							</Input.Error>
						)}
				</>
			)}
		</Stack>
	);
}

export default ThresholdSelector;
