import { LoadingOverlay } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useApiGetPolicy } from '@repo/api-codegen';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CreatePolicyModal from '../PoliciesCreatePage/CreatePolicyModal';

function EditPolicyPage() {
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const exitPage = useCallback(() => {
		if (location.state && location.state['prev_url_path']) {
			navigate(location.state['prev_url_path']);
		} else {
			navigate('/policies/');
		}
		showNotification({
			message: `Failed to get policy details`,
			color: 'red',
		});
	}, [location.state, navigate]);

	const {
		data: policy,
		isLoading,
		error,
	} = useApiGetPolicy({
		pathParams: { policyId: id! },
	});

	useEffect(() => {
		if (error) {
			exitPage();
		}
	}, [error, exitPage]);

	if (isLoading) {
		return <LoadingOverlay visible={true} />;
	}

	if (!policy) {
		exitPage();
	}

	return <CreatePolicyModal editMode={true} policy={policy} />;
}

export default EditPolicyPage;
