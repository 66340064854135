/* eslint-disable prefer-destructuring */
import { Box, Divider, Group, Input, Stack, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
	useApiDeleteAllowedDomain,
	useApiListAllowedDomains,
	useVerifyDomain,
	useVerifyDomainCode,
} from '@repo/api-codegen';
import {
	SettingsBlock,
	SettingsButton,
	SettingsSelect,
	SettingsSwitch,
} from '@repo/common/components';
import { UserRole } from '@repo/common/enums/UserRole.ts';
import {
	Button,
	CopyButton,
	IconButton,
	ResourceIcon,
	Text,
	Title,
} from '@repo/foundations';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { useCallback, useMemo, useRef, useState } from 'react';
import { api } from '../../../network';
import type { IWorkspace } from '../../api';
import { useAuthUser } from '../../api';
import { useSaml } from '../../api/hooks/saml';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import { useUpdateWorkspace } from '../../api/hooks/workspace/useUpdateWorkspace';
import { SSO_NAMES } from '../Auth/utils';
import { EmptyState } from '../EmptyState';
import { openModal } from '../ModalManager';
import { closeAllModals, openConfirmModal } from '../ModalManager/events';
import { UpgradeButton } from './UpgradeButton';

const SSO_OPTIONS = [
	{ value: 'none', label: SSO_NAMES.none },
	{ value: 'google', label: SSO_NAMES.google },
	{ value: 'microsoft', label: SSO_NAMES.microsoft },
	{ value: 'saml', label: SSO_NAMES.saml },
];

interface DomainCardProps {
	domain: string;
	onDelete: (domain: string) => void;
}

function DomainCard({ domain, onDelete }: DomainCardProps) {
	const handleDelete = useCallback(() => {
		onDelete(domain);
	}, [domain, onDelete]);

	return (
		<SettingsBlock
			leftComponent={<ResourceIcon iconName="at" />}
			title={
				<Text size="sm" weight="semibold">
					{domain}
				</Text>
			}
			rightComponentHover={
				<IconButton
					iconName="x"
					onClick={handleDelete}
					variant="tertiary"
					tooltip="Remove domain"
				/>
			}
		/>
	);
}

function AddDomainModal({
	onClose,
	onSubmit,
}: {
	onClose: () => void;
	onSubmit: (domain: string) => void;
}) {
	const [step, setStep] = useState<'domain' | 'email' | 'verification'>(
		'domain'
	);
	const [domain, setDomain] = useState('');
	const [email, setEmail] = useState('');
	const [code, setCode] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const { mutateAsync: verifyDomain } = useVerifyDomain();
	const { mutateAsync: verifyDomainCode } = useVerifyDomainCode();

	const handleDomainNext = useCallback(() => {
		if (domain && /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(domain)) {
			setStep('email');
		} else {
			setError('Invalid domain');
		}
	}, [domain]);

	const handleSendVerification = useCallback(async () => {
		if (!email) return;

		// Check if email domain matches the entered domain
		const emailDomain = email.split('@')[1];
		if (emailDomain !== domain) {
			setError(`Email must be from the domain ${domain}`);
			return;
		}

		setIsLoading(true);
		setError(null);

		try {
			await verifyDomain({
				body: {
					domain,
					email,
				},
			});

			setStep('verification');
			showNotification({
				title: 'Verification code sent',
				message: 'Please check your email for the verification code',
				color: 'green',
			});
		} catch (err: unknown) {
			setError(
				(err as { message: string }).message ||
					'Failed to send verification code'
			);
		} finally {
			setIsLoading(false);
		}
	}, [domain, email, verifyDomain]);

	const handleVerifyCode = useCallback(async () => {
		if (!code) return;

		setIsLoading(true);
		setError(null);

		try {
			await verifyDomainCode({
				body: {
					domain,
					email,
					code,
				},
			});

			showNotification({
				title: 'Domain verified',
				message: 'Domain has been verified and added successfully',
				color: 'green',
			});

			onSubmit(domain);
			onClose();
		} catch (err: unknown) {
			setError(
				(err as { message: string }).message || 'Failed to verify domain'
			);
		} finally {
			setIsLoading(false);
		}
	}, [domain, email, code, onSubmit, onClose, verifyDomainCode]);

	const handleBackToDomain = useCallback(() => {
		setStep('domain');
		setError(null);
	}, []);

	const handleBackToEmail = useCallback(() => {
		setStep('email');
		setError(null);
	}, []);

	if (step === 'domain') {
		return (
			<Stack spacing="0">
				<Text size="sm" weight="semibold">
					Email domain
				</Text>
				<Input
					name="domain"
					placeholder="example.com"
					value={domain}
					onChange={(e) => setDomain(e.currentTarget.value)}
					data-autofocus
				/>
				<Text size="sm" color="text/secondary/default" mb="sm">
					Users from this domain can join this workspace
				</Text>
				<Divider mb="md" />
				{error && <Text color="text/critical/default">{error}</Text>}
				<Group position="right">
					<Button onClick={onClose} variant="default">
						Cancel
					</Button>
					<Button
						onClick={handleDomainNext}
						variant="primary"
						disabled={!domain}
					>
						Add domain
					</Button>
				</Group>
			</Stack>
		);
	}

	if (step === 'email') {
		return (
			<Stack spacing="sm">
				<Text size="sm" color="text/secondary/default">
					Enter an email address from the domain "{domain}" to receive a
					verification code.
				</Text>
				<Input
					name="email"
					placeholder={`user@${domain}`}
					value={email}
					onChange={(e) => setEmail(e.currentTarget.value)}
					disabled={isLoading}
				/>
				{error && <Text color="text/critical/default">{error}</Text>}
				<Group position="right">
					<Button
						onClick={handleBackToDomain}
						variant="tertiary"
						disabled={isLoading}
					>
						Back
					</Button>
					<Button
						onClick={handleSendVerification}
						variant="primary"
						disabled={!email || isLoading}
						loading={isLoading}
					>
						Send verification
					</Button>
				</Group>
			</Stack>
		);
	}

	return (
		<Stack spacing="sm">
			<Text size="sm" color="text/secondary/default">
				Enter the verification code sent to {email}
			</Text>
			<Input
				name="code"
				placeholder="Enter verification code"
				value={code}
				onChange={(e) => setCode(e.currentTarget.value)}
				disabled={isLoading}
			/>
			{error && <Text color="text/critical/default">{error}</Text>}
			<Group position="right">
				<Button
					onClick={handleBackToEmail}
					variant="tertiary"
					disabled={isLoading}
				>
					Back
				</Button>
				<Button
					onClick={handleVerifyCode}
					variant="primary"
					disabled={!code || isLoading}
					loading={isLoading}
				>
					Verify domain
				</Button>
			</Group>
			<Text size="xs" color="text/secondary/default" align="right">
				<Button
					onClick={handleSendVerification}
					variant="tertiary"
					size="sm"
					disabled={isLoading}
				>
					Resend code
				</Button>
			</Text>
		</Stack>
	);
}

const samlOptions = [
	{ value: 'generic', label: SSO_NAMES.generic },
	{ value: 'google', label: SSO_NAMES.google },
	{ value: 'microsoft', label: SSO_NAMES.microsoft },
	{ value: 'okta', label: SSO_NAMES.okta },
];

export function SecuritySettings() {
	const { isAdminUser, domain, workspace } = useAuthUser();
	const disabled = !isAdminUser;
	const { mutateAsync } = useUpdateWorkspace(workspace!.id);
	const { data: samlData } = useSaml();
	const { samlAccess } = useFeatureAccess();
	const [samlIdp, setSamlIdp] = useState(samlData?.type ?? 'generic');

	const ref = useRef<HTMLInputElement>(null);

	const { data: allowedDomains, refetch } = useApiListAllowedDomains({});
	const { mutateAsync: deleteDomain } = useApiDeleteAllowedDomain({
		onSuccess: () => {
			refetch();
		},
	});

	const acs = `${window.location.origin}/api/v1/auth/saml/${domain?.replaceAll('.', '+')}/acs/`;
	const entityIdLabel =
		samlIdp === 'microsoft' ? 'Application ID URI' : 'Entity ID';
	const entityIdValue =
		samlData?.ENTITY_ID ??
		(samlIdp === 'microsoft' ? `https://${domain}/secoda` : acs);

	const handleSamlSubmit = useCallback(() => {
		axios.post(`${api()}auth/utilities/saml_request_workflow`, {
			acs_url: acs,
			entity_id: entityIdValue,
			metadata_url: ref.current?.value,
			idp: samlIdp,
		});
		showNotification({
			autoClose: false,
			message:
				'We have received your request. Your settings values will not be updated until the SAML configuration is verified and added. Please allow 1 business day to process this request.',
		});
	}, [acs, entityIdValue, samlIdp]);

	const debouncedUpdate = useMemo(
		() =>
			debounce((values: Partial<IWorkspace>) => {
				mutateAsync({
					data: {
						id: workspace?.id,
						...values,
					},
				}).then(() => {
					showNotification({
						title: 'Workspace updated',
						message: 'Workspace information updated successfully',
						color: 'green',
						autoClose: 1500,
					});
				});
			}, 500),
		[mutateAsync, workspace?.id]
	);

	const handleSCIMRoleChange = useCallback(
		(role: UserRole) => {
			debouncedUpdate({ default_scim_role: role });
		},
		[debouncedUpdate]
	);

	const showAddDomainModal = useCallback(() => {
		const id = 'add-domain-modal';
		openModal({
			id,
			title: 'Add email domain',
			children: (
				<AddDomainModal onClose={closeAllModals} onSubmit={() => refetch()} />
			),
		});
	}, [refetch]);

	return (
		<Box>
			<Title size="md" mb="md">
				General
			</Title>
			<Box mb="4xl">
				<SettingsSwitch
					disabled={disabled}
					onChange={(checked) => {
						debouncedUpdate({ lax_cookie_security: checked });
					}}
					checked={workspace.lax_cookie_security ?? false}
					title="Allow embedding"
					description="Allow your Secoda workspace to be embedded in an iframe. Note: This may increase the risk of CSRF attacks"
				/>
			</Box>

			<Title size="md">Allowed email domains</Title>
			<Text size="sm" color="text/secondary/default" mb="md">
				Anyone with email addresses at these domains can sign up to this
				workspace without an invitation
			</Text>
			<Box mb="4xl">
				<SettingsButton
					iconName="plus"
					variant="tertiary"
					title="Allowed domains"
					description="Anyone with email addresses at these domains can sign up to this workspace without an invitation"
					onClick={showAddDomainModal}
					tooltip="Add domain"
				/>
				{(allowedDomains?.allowed_domains || []).map((domain: string) => (
					<DomainCard
						key={domain}
						domain={domain}
						onDelete={() =>
							deleteDomain({
								body: {
									domain,
								},
							})
						}
					/>
				))}
			</Box>

			<Title size="md" mb="md">
				Authentication
			</Title>
			<Box mb="4xl">
				<SettingsSelect
					title="Enforce SSO"
					description="Require users to sign in with a specific SSO provider"
					value={workspace.enforce_sso ?? 'none'}
					options={SSO_OPTIONS}
					onChange={(value) => {
						if (value === 'none') {
							debouncedUpdate({ enforce_sso: null });
						} else {
							openConfirmModal({
								title: 'Enforce SSO Authentication',
								children: (
									<Text size="sm">
										Warning: Enforcing SSO authentication may lock you out if
										your SSO provider is not configured correctly. Please test
										the SSO login before enforcing it. You can reset this by
										contacting Secoda support.
									</Text>
								),
								confirmLabel: 'Enforce SSO',
								cancelLabel: 'Cancel',
								onConfirm: () => {
									debouncedUpdate({ enforce_sso: value });
								},
							});
						}
					}}
				/>
			</Box>

			<Title size="md" mb="md">
				SAML authentication
			</Title>
			<Box mb="4xl">
				{!samlAccess ? (
					<EmptyState
						title="Upgrade to access SAML & SCIM"
						description="You can use an SAML & SCIM to programatically provision users in Secoda"
						illustrationName="upgrade"
						includeGoBack={false}
						stateHeight="40vh"
						size="lg"
						withActions={<UpgradeButton feature="SAML & SCIM" size="md" />}
					/>
				) : (
					<Box>
						<Box>
							<SettingsSelect
								title="SAML provider (IDP)"
								description="The identity provider (IDP) you would like to use for SAML authentication"
								value={samlIdp ?? samlData?.type ?? 'generic'}
								options={samlOptions}
								onChange={(value) =>
									setSamlIdp(
										value as 'generic' | 'google' | 'microsoft' | 'okta'
									)
								}
							/>
							<SettingsBlock
								title="ACS URL"
								description="The Assertion Consumer Service (ACS) URL to input into your IDP"
							>
								<TextInput
									value={acs}
									readOnly
									variant="filled"
									rightSection={<CopyButton value={acs} />}
								/>
							</SettingsBlock>
							<SettingsBlock
								title={entityIdLabel}
								description="The Entity/Audience/Application ID to input into your IDP"
							>
								<TextInput
									value={entityIdValue}
									readOnly
									variant="filled"
									rightSection={<CopyButton value={entityIdValue} />}
								/>
							</SettingsBlock>
							<SettingsBlock
								title="Metadata URL"
								description="This a public URL provided by your IDP once you have configured your SAML application"
							>
								<Group>
									<TextInput
										style={{ flexGrow: 1 }}
										defaultValue={samlData?.METADATA_AUTO_CONF_URL ?? ''}
										ref={ref}
										required
									/>
									<Button onClick={handleSamlSubmit} variant="primary">
										{samlData?.METADATA_AUTO_CONF_URL
											? 'Request update'
											: 'Request SAML'}
									</Button>
								</Group>
							</SettingsBlock>
						</Box>
						<Box mt="xl">
							<SettingsSelect
								title="SCIM default role"
								description="The default role assigned to users provisioned via SCIM"
								value={
									(workspace.default_scim_role as UserRole) ?? UserRole.VIEWER
								}
								options={[
									{ value: UserRole.VIEWER, label: 'Viewer' },
									{ value: UserRole.EDITOR, label: 'Editor' },
									{ value: UserRole.ADMIN, label: 'Admin' },
								]}
								onChange={(value) => {
									handleSCIMRoleChange(value as UserRole);
								}}
							/>
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	);
}
