import { Group, Stack, Tooltip } from '@mantine/core';
import { IconButton, Text, Title } from '@repo/foundations';
import { size, startCase } from 'lodash-es';
import type { MouseEvent } from 'react';
import { useMemo } from 'react';
import { useAuthUser, useSecodaEntity } from '../../../../api';
import entityDrawerStore from '../../../EntityDrawer/store';
import IntegrationLogo from '../../../IntegrationLogo';
import { NODE_HEADER_INTEGRATION_ICON_SIZE } from '../../constants';
import { impactAnalysisStore } from '../../Modals/ImpactAnalysisModal/store';
import type { EntityNodeData } from '../../types';
import { useStyles } from './EntityNodeHeader.styles';
import MenuButton from './MenuButton';
import TestBadge from './TestBadge';
import { getMetadataText } from './utils';

interface IEntityNodeHeaderProps {
	data: EntityNodeData;
	selected: boolean;
}

function EntityNodeHeader({ data, selected }: IEntityNodeHeaderProps) {
	const {
		id,
		title,
		types,
		published,
		metadata,
		integration,
		creationQuery,
		tests,
		monitors,
		direction,
		isManual,
		isRoot,
		count,
		collapsed,
		fetched,
	} = data;

	const { isViewerOrGuestUser, isEditorOrAdminUser } = useAuthUser();

	const { classes } = useStyles();

	const metadataText = useMemo(
		() => getMetadataText(types, metadata),
		[types, metadata]
	);

	const filteredTests = useMemo(
		() => tests?.filter((test) => test.latest_run && test.latest_run.status),
		[tests]
	);

	const handleEditInSidebar = (event: MouseEvent<HTMLDivElement>) => {
		if (!event.metaKey && !event.ctrlKey) {
			if (isViewerOrGuestUser && !published) {
				entityDrawerStore.openEntityDrawerWithError();
			} else {
				entityDrawerStore.openEntityDrawerById(isEditorOrAdminUser, id).then();
			}
		}
	};

	// If entity type is glue, fetch the entity from the API
	const { data: entityData } = useSecodaEntity({
		id,
		options: {
			enabled: integration?.type === 'glue',
		},
	});

	const handleImpactAnalysis = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();

		impactAnalysisStore.setId(id);
		impactAnalysisStore.setOpened(true);
	};

	return (
		<Group className={classes.wrapper}>
			<Group className={classes.contentWrapper}>
				<IntegrationLogo
					entityType={types.entity}
					integrationId={integration?.id}
					integrationType={integration?.type}
					miw={NODE_HEADER_INTEGRATION_ICON_SIZE}
					maw={NODE_HEADER_INTEGRATION_ICON_SIZE}
					iconOverride={entityData ? entityData.icon : undefined}
				/>
				<Group className={classes.content}>
					<Stack className={classes.textWrapper} onClick={handleEditInSidebar}>
						<Title size="sm" truncate>
							{title}
						</Title>
						<Group className={classes.metadataWrapper}>
							<Text size="xs" color="text/secondary/default">
								{startCase(types.native || types.entity)}
							</Text>
							{size(metadataText) > 0 && (
								<>
									<Text size="xs" color="text/secondary/default">
										·
									</Text>
									<Text size="xs" color="text/secondary/default" truncate>
										{metadataText}
									</Text>
								</>
							)}
						</Group>
					</Stack>
					<Group className={classes.actionIconsWrapper}>
						<MenuButton
							id={id}
							types={types}
							published={published}
							creationQuery={creationQuery}
							tests={tests}
							direction={direction}
							isManual={isManual}
							isRoot={isRoot}
							count={count}
							collapsed={collapsed}
							fetched={fetched}
							selected={selected}
						/>
						<Tooltip label="View impact analysis" withArrow>
							<IconButton
								iconName="reportSearch"
								onClick={handleImpactAnalysis}
								variant="tertiary"
							/>
						</Tooltip>
						{filteredTests && (
							<TestBadge tests={filteredTests} monitors={monitors || []} />
						)}
					</Group>
				</Group>
			</Group>
		</Group>
	);
}

export default EntityNodeHeader;
