import { createStyles, Group, Skeleton } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useUnlinkJiraIssue } from '@repo/api-codegen';
import { IconButton, Text } from '@repo/foundations';
import { queryClient, questionsQueryKeyFactory } from '../../../../api';
import { incidentsQueryKeyFactory } from '../../../../api/hooks/monitoring/constants';
import IntegrationLogo from '../../../../components/IntegrationLogo';

const useStyles = createStyles((theme) => ({
	itemWrapper: {
		width: '100%',
		borderRadius: theme.radius.md,
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
		cursor: 'pointer',
	},
	iconWrapper: {
		paddingLeft: theme.other.space[1],
	},
}));

export function LinkedJiraIssue({
	issueName,
	issueUrl,
	entityId,
	isViewer = false,
}: {
	issueName: string;
	issueUrl: string;
	entityId: string;
	isViewer?: boolean;
}) {
	const { ref, hovered } = useHover<HTMLDivElement>();
	const { classes } = useStyles();
	const { mutate: unlinkIssue, isLoading } = useUnlinkJiraIssue({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: incidentsQueryKeyFactory.all(),
			});
			queryClient.invalidateQueries({
				queryKey: questionsQueryKeyFactory.all(),
			});
		},
	});

	return isLoading ? (
		<Skeleton height="90%" width="90%" />
	) : (
		<Group
			className={classes.itemWrapper}
			noWrap
			position="apart"
			ref={ref}
			onClick={() => {
				window.open(issueUrl, '_blank');
			}}
		>
			<Group spacing="xs" noWrap>
				<IntegrationLogo
					className={classes.iconWrapper}
					size={16}
					integrationType={'jira'}
				/>
				<Text lineClamp={1} size="xs">
					{issueName}
				</Text>
			</Group>
			{!isViewer && (
				<IconButton
					size="sm"
					variant="tertiary"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						unlinkIssue({ pathParams: { entityId: entityId } });
					}}
					iconName="x"
					style={{ visibility: hovered && !isLoading ? 'visible' : 'hidden' }}
				/>
			)}
		</Group>
	);
}
