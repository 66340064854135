import { Box, Divider, Flex, Group, Stack, createStyles } from '@mantine/core';
import type { PolicyOut } from '@repo/api-codegen';
import { Icon, IconButton, Text, Title } from '@repo/foundations';
import { iconSize } from '@repo/theme/primitives';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { openConfirmModal } from '../../components/ModalManager';
import ConditionsStack from './components/ConditionsStack';
import { CreatePolicyStoreProvider } from './components/context';
import CreatePolicyButton from './components/CreatePolicyButton';
import PropertiesStack from './components/PropertiesStack';
import RemediationStack from './components/RemediationStack';
import ResourceSelectionStack from './components/ResourceSelectionStack';

const useStyles = createStyles((theme) => ({
	wrapper: {
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		gap: 0,
		animation: 'fadeIn 0.35s ease-in-out',
	},
	header: {
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		padding: `${theme.spacing.sm} ${theme.spacing['3xl']}`,
	},
	content: {
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		height: 'calc(100vh - 52px)',
	},
	policyWrapper: {
		alignItems: 'flex-start',
		justifyContent: 'center',
		flexGrow: 1,
		overflowY: 'auto',
		padding: `0 ${theme.spacing.xl} ${theme.spacing['5xl']} ${theme.spacing.xl}`,
		borderTopWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.other.getColor('border/secondary/default'),
	},
	exitModalContent: {
		width: 100,
		borderRadius: theme.radius.lg,
	},
	policyIcon: {
		backgroundColor: theme.other.getColor('fill/decorative/sunset'),
		borderRadius: theme.radius.sm,
		padding: theme.spacing['3xs'],
	},
}));

interface CreatePolicyModalProps {
	editMode?: boolean;
	policy?: PolicyOut;
}

function CreatePolicyModal({
	editMode = false,
	policy,
}: CreatePolicyModalProps) {
	const navigate = useNavigate();
	const location = useLocation();
	const { classes, theme } = useStyles();
	const policyTemplate = location.state?.policyTemplate;
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

	const exitPage = useCallback(() => {
		if (location.state && location.state['prev_url_path']) {
			navigate(location.state['prev_url_path']);
		} else {
			navigate('/policies/');
		}
	}, [location.state, navigate]);

	const handleClose = useCallback(() => {
		if (hasUnsavedChanges) {
			openConfirmModal({
				title: <Title size="md">Leave page with unsaved changes?</Title>,
				children: 'All progress will be lost.',
				labels: { confirm: 'Leave page', cancel: 'Cancel' },
				cancelProps: { size: 'md' },
				confirmProps: { variant: 'primary', tone: 'default', size: 'md' },
				groupProps: {
					spacing: 'xs',
				},
				onConfirm: exitPage,
				classNames: {
					content: classes.exitModalContent,
				},
			});
		} else {
			exitPage();
		}
	}, [exitPage, classes.exitModalContent, hasUnsavedChanges]);

	return (
		<CreatePolicyStoreProvider
			policy={policy || policyTemplate}
			onFormChange={setHasUnsavedChanges}
		>
			<Flex className={classes.wrapper}>
				<Flex className={classes.header}>
					<div
						style={{
							width: iconSize.md,
							height: iconSize.md,
							visibility: 'hidden',
						}}
					/>
					<Group>
						<Box className={classes.policyIcon}>
							<Icon name="checklist" color="icon/decorative/sunset" />
						</Box>
						<Text size="sm" weight="bold">
							{editMode ? 'Edit policy' : 'New policy'}
						</Text>
					</Group>
					<IconButton variant="tertiary" iconName="x" onClick={handleClose} />
				</Flex>
				<Flex className={classes.content}>
					<Flex className={classes.policyWrapper}>
						<Stack
							w={theme.other.width.lg}
							pt={theme.spacing['3xl']}
							spacing="2xl"
						>
							<Stack p={0} spacing="sm">
								<Title size="xl">
									{editMode ? 'Edit policy' : 'New policy'}
								</Title>
								<Text size="md" color="text/secondary/default">
									{editMode
										? 'Update your policy settings'
										: 'Set up a policy to manage access and permissions'}
								</Text>
							</Stack>
							<PropertiesStack />
							<Divider />
							<ResourceSelectionStack />
							<Divider />
							<ConditionsStack />
							<Divider />
							<RemediationStack />
							<CreatePolicyButton
								exitPage={exitPage}
								editMode={editMode}
								policyId={policy?.id}
							/>
						</Stack>
					</Flex>
				</Flex>
			</Flex>
		</CreatePolicyStoreProvider>
	);
}

export default CreatePolicyModal;
