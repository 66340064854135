import type {
	Filter,
	PartialPolicyIn,
	PolicyFramework,
	PolicyOut,
	PolicySeverity,
} from '@repo/api-codegen';
import { makeAutoObservable, reaction } from 'mobx';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useAuthUser } from '../../../api';

class CreatePolicyStore {
	// Properties
	name: string = '';
	description: string = '';
	frameworks: string[] = [];
	severity: PolicySeverity = 'NONE';

	nameError: string = '';

	// Remediation
	remediationGuidelines: string = '';
	owners: string[];
	ownersGroups: string[] = [];
	subscribers: string[] = [];
	subscribersGroups: string[] = [];
	createdBy: string | undefined;
	automationEnabled: boolean = false;

	ownersError: string = '';

	// Resource selection
	resourceFilters: { id: string; filter: Filter }[] = [];

	// Conditions
	// Add a unique id with the filter to better handle React updates.
	policyFilters: { id: string; filter: Filter }[] = [];
	policyFiltersError: string = '';

	initialState: PolicyOut | undefined;

	constructor({ userId, policy }: { userId: string; policy?: PolicyOut }) {
		if (!policy) {
			this.createdBy = userId;
			this.owners = [userId];
			this.subscribers = [userId];
		} else {
			this.createdBy = policy.created_by?.id;
			this.owners = policy.owners;
			this.ownersGroups = policy.owners_groups;
			this.subscribers = policy.subscribers;
			this.subscribersGroups = policy.subscribers_groups;
		}

		if (policy) {
			this.initialState = policy;

			this.name = policy.name;
			this.description = policy.description ?? '';
			this.frameworks = policy.frameworks ?? [];
			this.severity = policy.severity ?? 'NONE';
			this.resourceFilters =
				policy.resource_filters?.map((filter) => ({
					id: crypto.randomUUID(),
					filter,
				})) ?? [];
			this.policyFilters =
				policy.policy_filters?.map((filter) => ({
					id: crypto.randomUUID(),
					filter,
				})) ?? [];
			this.remediationGuidelines = policy.remediation ?? '';
			this.owners = policy.owners;
			this.ownersGroups = policy.owners_groups;
			this.subscribers = policy.subscribers;
			this.subscribersGroups = policy.subscribers_groups;
		}

		makeAutoObservable(this);
	}

	get hasUnsavedChanges() {
		const policy = this.initialState;

		if (!policy) {
			return (
				this.name !== '' ||
				this.description !== '' ||
				this.frameworks.length > 0 ||
				this.severity !== 'NONE' ||
				this.remediationGuidelines !== '' ||
				this.resourceFilters.length > 0 ||
				this.policyFilters.length > 0
			);
		}

		return (
			this.name !== policy.name ||
			this.description !== (policy.description ?? '') ||
			JSON.stringify(this.frameworks) !==
				JSON.stringify(policy.frameworks ?? []) ||
			this.severity !== (policy.severity ?? 'NONE') ||
			this.remediationGuidelines !== (policy.remediation ?? '') ||
			JSON.stringify(this.owners) !== JSON.stringify(policy.owners) ||
			JSON.stringify(this.ownersGroups) !==
				JSON.stringify(policy.owners_groups) ||
			JSON.stringify(this.subscribers) !== JSON.stringify(policy.subscribers) ||
			JSON.stringify(this.subscribersGroups) !==
				JSON.stringify(policy.subscribers_groups) ||
			this.resourceFilters.length !== (policy.resource_filters?.length ?? 0) ||
			this.policyFilters.length !== (policy.policy_filters?.length ?? 0)
		);
	}

	// Properties

	setName = (name: string) => {
		this.name = name;
		this.nameError = '';
	};

	setDescription = (description: string) => {
		this.description = description;
	};

	setFrameworks = (frameworks: string[]) => {
		this.frameworks = frameworks;
	};

	setSeverity = (severity: PolicySeverity) => {
		this.severity = severity;
	};

	// Remediation

	setRemediationGuidelines = (guidelines: string) => {
		this.remediationGuidelines = guidelines;
	};

	setOwners = (owners: string[]) => {
		this.owners = owners;
		this.ownersError = '';
	};

	setOwnersGroups = (ownersGroups: string[]) => {
		this.ownersGroups = ownersGroups;
		this.ownersError = '';
	};

	setSubscribers = (subscribers: string[]) => {
		this.subscribers = subscribers;
	};

	setSubscribersGroups = (subscribersGroups: string[]) => {
		this.subscribersGroups = subscribersGroups;
	};

	setAutomationEnabled = (enabled: boolean) => {
		this.automationEnabled = enabled;
	};

	setValue = (field: 'owners' | 'subscribers') => (value: string[]) => {
		if (field === 'owners') {
			this.setOwners(value);
		} else {
			this.setSubscribers(value);
		}
	};

	addResourceFilter = (filter: Filter) => {
		this.resourceFilters.push({ id: crypto.randomUUID(), filter });
	};

	removeResourceFilter = (id: string) => {
		this.resourceFilters = this.resourceFilters.filter(
			(filter) => filter.id !== id
		);
	};

	updateResourceFilter = (id: string, filter: Filter) => {
		this.resourceFilters = this.resourceFilters.map((f) =>
			f.id === id ? { id, filter } : f
		);
	};

	addPolicyFilter = (filter: Filter) => {
		this.policyFilters.push({ id: crypto.randomUUID(), filter });
		this.policyFiltersError = '';
	};

	removePolicyFilter = (id: string) => {
		this.policyFilters = this.policyFilters.filter(
			(filter) => filter.id !== id
		);
	};

	updatePolicyFilter = (id: string, filter: Filter) => {
		this.policyFilters = this.policyFilters.map((f) =>
			f.id === id ? { id, filter } : f
		);
	};

	get isFormValid() {
		this.nameError = '';
		this.ownersError = '';

		if (!this.name) {
			this.nameError = 'Policy name is required';
		}

		if (this.owners.length === 0) {
			this.ownersError = 'At least one owner is required';
		}

		if (this.policyFilters.length === 0) {
			this.policyFiltersError = 'At least one policy filter is required';
		}

		return !(this.nameError || this.ownersError || this.policyFiltersError);
	}

	getData(): PartialPolicyIn {
		return {
			name: this.name,
			description: this.description,
			frameworks: this.frameworks as PolicyFramework[],
			severity: this.severity,
			owners: this.owners,
			owners_groups: this.ownersGroups,
			subscribers: this.subscribers,
			subscribers_groups: this.subscribersGroups,
			resource_filters: this.resourceFilters.map(({ filter }) => filter),
			policy_filters: this.policyFilters.map(({ filter }) => filter),
			remediation: this.remediationGuidelines,
			created_by_id: this.createdBy,
		};
	}
}

const CreatePolicyStoreContext = createContext<CreatePolicyStore | null>(null);

export function CreatePolicyStoreProvider({
	policy,
	children,
	onFormChange,
}: {
	policy?: PolicyOut;
	children: React.ReactNode;
	onFormChange?: (hasChanges: boolean) => void;
}) {
	const { user } = useAuthUser();
	const store = useMemo(
		() => new CreatePolicyStore({ userId: user.id, policy }),
		[user, policy]
	);

	useEffect(() => {
		if (onFormChange) {
			const disposer = reaction(
				() => store.hasUnsavedChanges,
				(hasChanges) => {
					onFormChange(hasChanges);
				}
			);

			return () => disposer();
		}
	}, [store, onFormChange]);

	return (
		<CreatePolicyStoreContext.Provider value={store}>
			{children}
		</CreatePolicyStoreContext.Provider>
	);
}

export function useCreatePolicyStore() {
	const context = useContext(CreatePolicyStoreContext);
	if (!context) {
		throw new Error(
			'useCreatePolicyStore must be used within CreatePolicyStoreProvider'
		);
	}
	return context;
}
