import { createStyles, ScrollArea, Stack, Transition } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { UserRole } from '@repo/common/enums/UserRole';
import { useLocalStorageBooleanState } from '@repo/common/hooks/useLocalStorageBooleanState';
import { Banner } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import { useAIConversation, useAuthUser } from '../../api';
import { useFeatureFlags } from '../../utils/featureFlags';
import { DOCUMENTATION_WIDTH } from '../Documentation/constants';
import { AIChatConversation } from './AIChatConversation';
import AIChatEmpty from './AIChatEmpty';
import { AIPromptInput } from './AIPromptInput';
import { useAIAssistantContext } from './context';
import { AIAssistantMode } from './types';

const useStyles = createStyles(
	(
		theme,
		{
			isSidebar,
			containerWidth,
		}: { isSidebar: boolean; containerWidth: number }
	) => {
		const isSmallScreen = containerWidth < 850;

		return {
			wrapper: {
				width: '100%',
				height: '100%',
				paddingBottom: !isSidebar ? theme.spacing.md : undefined,
				gap: 0,
				display: 'flex',
				flexDirection: 'column',
				overflow: 'hidden',
			},
			scrollAreaRoot: {
				width: '100%',
				flex: '1 1 0',
				minHeight: 0,
				overflow: 'hidden',
			},
			scrollAreaViewport: {
				width: '100%',
				height: '100%',
				'>div': {
					width: '100%',
					height: '100%',
				},
			},
			scrollInnerContent: {
				height: '100%',
			},
			scrollInnerContainer: {
				width:
					isSidebar || isSmallScreen
						? '100%'
						: `calc(${DOCUMENTATION_WIDTH}px + (2 * 1.5rem))`,
				margin: '0 auto',
				paddingLeft: isSidebar ? 0 : theme.spacing.xl,
				paddingRight: isSidebar ? 0 : theme.spacing.xl,
			},
			questionEducationBanner: {
				paddingBottom: theme.spacing.md,
			},
			userInfoBanner: {
				paddingBottom: theme.spacing.md,
				margin: `0 ${theme.other.space[10]}px`,
				display: isSidebar ? 'none' : 'flex',
			},
		};
	}
);

export const AIChat = observer(() => {
	const store = useAIAssistantContext();
	const { aiToQuestionsAnswers } = useFeatureFlags();
	const [showFeedbackBanner, setShowFeedbackBanner] = useState(false);
	const { ref: containerRef, width: containerWidth } = useElementSize();

	const handleNegativeFeedback = useCallback(() => {
		setShowFeedbackBanner(true);
	}, []);

	const { classes, cx } = useStyles({
		isSidebar: store.mode === AIAssistantMode.SIDEBAR,
		containerWidth,
	});

	const { user } = useAuthUser();

	const {
		isLoading,
		submitPrompt,
		submit,
		stop,
		inputPrompt,
		setInputPrompt,
		isWaitingAI,
		messages,
		viewportRef,
		rerenderKey,
		retryPrompt,
		onAttachmentUpload,
		onAttachmentRemove,
	} = useAIConversation({ id: store.id });

	const [canShowEducation, { setTrue: setEducationBannerShown }] =
		useLocalStorageBooleanState('questionEducationBannerShown');

	const currentUserIsOwner =
		isNil(messages[0]?.user?.id) || user?.id === messages[0]?.user?.id;

	const showQuestionEducationBanner = useMemo(() => {
		const hasUnansweredPrompts = messages?.some(
			(message) => message.benchmark_raw?.fail_reason
		);

		return (
			hasUnansweredPrompts &&
			canShowEducation &&
			currentUserIsOwner &&
			user?.role === UserRole.VIEWER
		);
	}, [messages, canShowEducation, currentUserIsOwner, user?.role]);

	const isEmpty = store.id === 'new';
	const showInput =
		isEmpty || (messages && messages.length >= 1 && currentUserIsOwner);

	const handleBannerDismiss = () => {
		if (showQuestionEducationBanner) {
			setEducationBannerShown();
		}
		setShowFeedbackBanner(false);
	};

	return (
		<Stack className={classes.wrapper}>
			{!currentUserIsOwner && !isEmpty && (
				<div className={classes.userInfoBanner}>
					<Banner
						tone="neutral"
						message={`You are viewing a conversation between Secoda AI and ${messages[0]?.user?.display_name ?? 'a team member'}.`}
					/>
				</div>
			)}
			<ScrollArea
				viewportRef={viewportRef}
				classNames={{
					root: classes.scrollAreaRoot,
					viewport: classes.scrollAreaViewport,
				}}
				type="scroll"
				ref={containerRef}
			>
				<div
					className={cx(
						classes.scrollInnerContainer,
						classes.scrollInnerContent
					)}
				>
					{isEmpty ? (
						<AIChatEmpty />
					) : (
						<AIChatConversation
							isLoading={isLoading}
							messages={messages}
							scrollAreaRef={viewportRef}
							onRetry={retryPrompt}
							onNegativeFeedback={handleNegativeFeedback}
						/>
					)}
				</div>
			</ScrollArea>
			<div className={classes.scrollInnerContainer}>
				<Transition
					mounted={
						(showFeedbackBanner || !!showQuestionEducationBanner) &&
						aiToQuestionsAnswers
					}
					transition="slide-up"
					duration={100}
					exitDuration={0}
				>
					{(styles) => (
						<div className={classes.questionEducationBanner} style={styles}>
							<Banner
								tone="neutral"
								message="If Secoda AI isn't providing the answer you need, you can forward your question to your team. Just select the question icon on a message to get started."
								title="Not getting the answer you're looking for?"
								onDismiss={handleBannerDismiss}
							/>
						</div>
					)}
				</Transition>
				{showInput && (
					<AIPromptInput
						key={rerenderKey}
						value={inputPrompt}
						onChange={setInputPrompt}
						onSubmit={submit}
						isLoading={isWaitingAI}
						onStop={stop}
						onAttachmentUpload={onAttachmentUpload}
						onAttachmentRemove={onAttachmentRemove}
					/>
				)}
			</div>
		</Stack>
	);
});
