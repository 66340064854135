import { Stack } from '@mantine/core';
import { useDebounceFn } from 'ahooks';
import { lowerCase } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type React from 'react';
import type { JSX } from 'react';
import { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useAuthUser } from '../../api';
import { trackEvent } from '../../utils/analytics';
import { useCheckEntityUpdatePermission } from '../../utils/authorization/roles';
import { AIAssistantSidebar } from '../AIAssistant/AIAssistantSidebar/AIAssistantSidebar';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../PageLayout';
import {
	RightSidebarMode,
	RightSidebarWrapper,
	rightSidebarStore,
} from '../RightSidebar';
import EntityPageActions from './EntityPageActions';
import EntityPageDescription from './EntityPageDescription';
import EntityPageNavBar from './EntityPageNavBar';
import type { EntityPageSidebarProps } from './EntityPageSidebar';
import EntityPageSidebar from './EntityPageSidebar';
import EntityPageTitle from './EntityPageTitle';
import EntityPageToggles from './EntityPageToggles';

export interface EntityPageLayoutProps extends EntityPageSidebarProps {
	name: string;
	children: React.ReactNode;
	icon?: React.ReactNode;
	classNames?: Partial<Record<'contentWrapper' | 'content', string>>;
	isReadOnlyTitle?: boolean;
	customActionsPosition?: 'left' | 'right';
	withCustomActions?: React.ReactNode;
	withActions?: boolean;
	withSidebarToggles?: boolean;
	withDescription?: boolean;
	withEntityByteSize?: boolean;
	withEntityRowCount?: boolean;
	withMetricSection?: JSX.Element;
	withPinnedToggle?: boolean;
	wrapTitle?: boolean;
	withCustomPublishElement?: JSX.Element;
}

function EntityPageLayout({
	name,
	entity,
	updateEntity,
	children,
	icon,
	classNames,
	isReadOnlyTitle = false,
	customActionsPosition,
	withCustomActions,
	withActions = true,
	withSidebarToggles = true,
	withFrequentUsers,
	withDescription,
	withVerifiedSelector,
	withGovernanceSelector,
	withCollectionSelector,
	withTagSelector,
	withOwnerSelector,
	withRelatedResourceSelector,
	withCustomPropertyEditors,
	withEntityPopularity,
	withEntityRowCount,
	withEntityByteSize,
	withPinnedToggle,
	withCustomPublishElement,
	withTeamSelector,
	withMetricSection,
	withCollectionParentSelector: withParentSelector,
	// Question-specific properties:
	withAssignedToSelector = false,
	withStatusSelector = false,
	withPrioritySelector = false,
	withSource,
	wrapTitle,
}: EntityPageLayoutProps) {
	const { user, workspace } = useAuthUser();
	const canUpdateEntity = useCheckEntityUpdatePermission({ entity });
	const isReadOnlyTitleProp = isReadOnlyTitle || !canUpdateEntity;

	const { run: updateEntityDebounce } = useDebounceFn(
		(key, value, saveRemotely) => {
			updateEntity(key, value, saveRemotely);
		},
		{ wait: 150 }
	);

	const handleEntityChange = useCallback(
		(key: string) =>
			(value: unknown, saveRemotely = true) => {
				updateEntityDebounce(key, value, saveRemotely);
				trackEvent(
					`${entity.entity_type}/${key}/update`,
					{ id: entity.id },
					user,
					workspace
				);
			},
		[entity.entity_type, entity.id, updateEntityDebounce, user, workspace]
	);

	const sidebarProps = useMemo(
		() => ({
			entity,
			updateEntity,
			withFrequentUsers,
			withVerifiedSelector,
			withGovernanceSelector,
			withCollectionSelector,
			withTagSelector,
			withOwnerSelector,
			withRelatedResourceSelector,
			withCustomPropertyEditors,
			withEntityPopularity,
			withEntityByteSize,
			withEntityRowCount,
			withTeamSelector,
			withAssignedToSelector,
			withStatusSelector,
			withPrioritySelector,
			withSource,
			withMetricSection,
			withCustomPublishElement,
			withCollectionParentSelector: withParentSelector,
		}),
		[
			entity,
			updateEntity,
			withFrequentUsers,
			withVerifiedSelector,
			withGovernanceSelector,
			withCollectionSelector,
			withTagSelector,
			withOwnerSelector,
			withRelatedResourceSelector,
			withCustomPropertyEditors,
			withEntityPopularity,
			withEntityByteSize,
			withEntityRowCount,
			withTeamSelector,
			withAssignedToSelector,
			withStatusSelector,
			withPrioritySelector,
			withSource,
			withMetricSection,
			withCustomPublishElement,
			withParentSelector,
		]
	);

	const navbarActions = useMemo(() => {
		if (!withActions) return undefined;

		return (
			<EntityPageActions
				entity={entity}
				withPinnedToggle={withPinnedToggle}
				withCustomActions={withCustomActions}
				customActionsPosition={customActionsPosition}
			/>
		);
	}, [
		entity,
		withPinnedToggle,
		withCustomActions,
		customActionsPosition,
		withActions,
	]);

	const navbarToggles = useMemo(() => {
		if (!withSidebarToggles) return undefined;

		return <EntityPageToggles entity={entity} />;
	}, [entity, withSidebarToggles]);

	const navbar = useMemo(() => {
		if (!entity) return null;

		return (
			<EntityPageNavBar
				entity={entity}
				actions={navbarActions}
				toggles={navbarToggles}
			/>
		);
	}, [entity, navbarActions, navbarToggles]);

	const titleAndDescription = useMemo(
		() => (
			<Stack spacing="xs">
				<EntityPageTitle
					icon={icon}
					placeholder={`Untitled ${lowerCase(name)}`}
					entity={entity}
					isReadOnly={isReadOnlyTitleProp}
					onChange={handleEntityChange('title')}
					wrapTitle={wrapTitle}
				/>
				{withDescription && (
					<EntityPageDescription
						entityId={entity.id}
						description={entity?.description ?? ''}
						readOnly={!canUpdateEntity}
						onChange={handleEntityChange('description')}
						integrationId={entity.integration}
					/>
				)}
			</Stack>
		),
		[
			entity,
			icon,
			name,
			isReadOnlyTitleProp,
			handleEntityChange,
			wrapTitle,
			withDescription,
			canUpdateEntity,
		]
	);

	const rightSidebar = useMemo(() => {
		if (rightSidebarStore.mode === RightSidebarMode.INFO) {
			return <EntityPageSidebar {...sidebarProps} />;
		}

		if (rightSidebarStore.mode === RightSidebarMode.AI) {
			return <AIAssistantSidebar />;
		}

		return null;
	}, [sidebarProps, rightSidebarStore.mode]);

	return (
		<PageLayoutOuterWrapper>
			<Helmet>
				<title>{entity.title_cased ?? entity.title ?? name}</title>
			</Helmet>
			<PageLayoutWrapper name="entity-page" key={entity.id}>
				<PageLayoutContentWrapper name="entity-page">
					{navbar}
					<PageLayoutContent className={classNames?.content}>
						{titleAndDescription}
						{children}
					</PageLayoutContent>
				</PageLayoutContentWrapper>
				<RightSidebarWrapper>{rightSidebar}</RightSidebarWrapper>
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}

export default observer(EntityPageLayout);
