import type { ColorScheme } from '@mantine/core';
import { Box, createStyles, useMantineColorScheme } from '@mantine/core';
import { SettingsSelect } from '@repo/common/components/SettingsSelect';
import { Title } from '@repo/foundations';
import {
	queryClient,
	useAuthUser,
	useUpdateUser,
	usersQueryKeyFactory,
} from '../../api';
import { thisUserQueryKey } from '../../api/hooks/user/useThisUser';

const useStyles = createStyles((theme) => ({
	preferencesTitle: {
		marginBottom: theme.spacing.md,
	},
}));

enum InterfaceTheme {
	SYSTEM_PREFERENCE = 'system',
	LIGHT = 'light',
	DARK = 'dark',
}

interface ThemeOption {
	label: string;
	value: InterfaceTheme;
}

const themeOptions: ThemeOption[] = [
	{ label: 'System preference', value: InterfaceTheme.SYSTEM_PREFERENCE },
	{ label: 'Light', value: InterfaceTheme.LIGHT },
	{ label: 'Dark', value: InterfaceTheme.DARK },
];

export const getPreferredColorScheme = (): ColorScheme => {
	if (typeof window === 'undefined') return 'light';
	return window.matchMedia('(prefers-color-scheme: dark)').matches
		? 'dark'
		: 'light';
};

export function Preferences() {
	const { classes } = useStyles();
	const { toggleColorScheme } = useMantineColorScheme();
	const { user } = useAuthUser();

	const { mutateAsync: updateUser } = useUpdateUser({
		options: {
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: usersQueryKeyFactory.byId(user.id),
				});
				queryClient.invalidateQueries({
					queryKey: thisUserQueryKey,
				});
			},
		},
	});

	const handleThemeChange = (selectedTheme: InterfaceTheme): void => {
		let newColorScheme: ColorScheme;

		switch (selectedTheme) {
			case InterfaceTheme.SYSTEM_PREFERENCE:
				newColorScheme = getPreferredColorScheme();
				toggleColorScheme(newColorScheme);
				break;
			case InterfaceTheme.LIGHT:
				newColorScheme = 'light';
				toggleColorScheme('light');
				break;
			case InterfaceTheme.DARK:
				newColorScheme = 'dark';
				toggleColorScheme('dark');
				break;
			default:
				return;
		}

		if (!user) return;

		updateUser({
			data: {
				id: user.id,
				user_appearance: selectedTheme,
			},
		});
	};

	return (
		<>
			<Title className={classes.preferencesTitle} size="md">
				Appearance
			</Title>
			<Box>
				<SettingsSelect
					title="Interface theme"
					description="Select your interface theme"
					options={themeOptions}
					onChange={(value) => handleThemeChange(value as InterfaceTheme)}
					searchable={false}
					value={user?.user_appearance || InterfaceTheme.SYSTEM_PREFERENCE}
				/>
			</Box>
		</>
	);
}
