import { Group, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useApiDeletePolicy, type PolicyOut } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { Breadcrumbs, Button, Icon, IconButton } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { queryClient, useAuthUser, useWorkspace } from '../../api';
import { policiesQueryKeyFactory } from '../../api/hooks/policy';
import ActivityLogDrawer from '../../components/ActivityLog/ActivityLogDrawer';
import { AIAssistantSidebarToggle } from '../../components/AIAssistant';
import { ActivityLogAction } from '../../components/EntityPageLayout/EntityActions/Actions/ActivityLogAction';
import { CopyLinkAction } from '../../components/EntityPageLayout/EntityActions/Actions/CopyLinkAction';
import { EntityPageSidebarToggle } from '../../components/EntityPageSidebarToggle';
import { openDeleteConfirmModal } from '../../components/ModalManager';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarSidesheetControlsWrapper,
	NavBarWrapper,
} from '../../components/SecodaAppShell/SecodaNavBar';
import { trackEvent } from '../../utils/analytics';
import { useTogglePolicyEnable } from '../PoliciesListPage/hooks';
import { PolicyToggle } from './PolicyToggle';

interface PolicyPageNavBarProps {
	policy: PolicyOut;
}

const PolicyPageNavBar = observer(({ policy }: PolicyPageNavBarProps) => {
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();
	const navigate = useNavigate();

	const crumbs = [
		{
			title: 'Policies',
			href: '/policies',
		},
		{
			title: policy.name,
			href: `/policy/${policy.id}`,
		},
	];

	const { mutateAsync: togglePolicy } = useTogglePolicyEnable({});
	const toggleCallBack = useCallback(
		(record: PolicyOut, enabled: boolean) => {
			togglePolicy({
				body: {
					enabled: enabled,
				},
				pathParams: {
					policyId: record.id,
				},
			});
		},
		[togglePolicy]
	);

	const [logDrawerOpen, { open: openLogDrawer, close: closeLogDrawer }] =
		useDisclosure(false);

	const { mutateAsync: deletePolicy } = useApiDeletePolicy({});
	const handleDeleteClick = useCallback(() => {
		openDeleteConfirmModal({
			title: `Delete ${policy.name}`,
			description: `Are you sure you want to delete ${policy.name}?`,
			onConfirm: async () => {
				const notificationId = `delete-policy-${policy.id}`;
				notifications.show({
					id: notificationId,
					loading: true,
					message: `Deleting ${policy.name}...`,
				});

				try {
					await deletePolicy({ pathParams: { policyId: policy.id } });

					trackEvent(
						`/delete`,
						{
							id: policy.id,
						},
						user,
						workspace
					);

					notifications.update({
						id: notificationId,
						message: `Successfully deleted ${policy.name}`,
					});

					queryClient.invalidateQueries({
						queryKey: policiesQueryKeyFactory.all(),
					});

					navigate('/policies');
				} catch (error) {
					notifications.update({
						id: notificationId,
						message: `Failed to delete ${policy.name}`,
						color: 'red',
					});
				}
			},
		});
	}, [deletePolicy, policy.id, policy.name, user, workspace, navigate]);

	return (
		<>
			<NavBarWrapper>
				<NavBarBreadcrumbWrapper>
					<Breadcrumbs crumbs={crumbs} />
				</NavBarBreadcrumbWrapper>
				<NavBarRightSideWrapper>
					<Group spacing="xs">
						<Group spacing="3xs">
							<PolicyToggle
								enabled={policy.enabled}
								onChange={(enabled) => toggleCallBack(policy, enabled)}
							/>
							<Menu
								position="bottom-end"
								width={150}
								closeOnItemClick
								withinPortal
							>
								<Menu.Target>
									<IconButton
										data-testid="policy-actions-menu"
										iconName="dots"
										variant="tertiary"
										tooltip="More actions"
									/>
								</Menu.Target>
								<Menu.Dropdown>
									<CopyLinkAction />
									<Menu.Divider />
									{/* TODO[tan-policies]: Add snapshots action */}
									{/* <SnapshotsAction entity={policy} /> */}
									<ActivityLogAction
										entityType={EntityType.policy}
										openLogDrawer={openLogDrawer}
									/>
									<Menu.Divider />
									<Menu.Item
										icon={<Icon name="trash" />}
										onClick={handleDeleteClick}
									>
										Delete...
									</Menu.Item>
								</Menu.Dropdown>
							</Menu>
						</Group>
						<Button
							onClick={() =>
								navigate(`/policy/${policy.id}/edit`, {
									state: { prev_url_path: `/policy/${policy.id}` },
								})
							}
						>
							Edit policy
						</Button>
					</Group>
				</NavBarRightSideWrapper>
				<NavBarSidesheetControlsWrapper>
					<AIAssistantSidebarToggle />
					<EntityPageSidebarToggle />
				</NavBarSidesheetControlsWrapper>
			</NavBarWrapper>
			<ActivityLogDrawer
				entity={{
					id: policy.id,
					entity_type: EntityType.policy,
					created_at: policy.created_at,
				}}
				open={logDrawerOpen}
				onClose={closeLogDrawer}
			/>
		</>
	);
});

PolicyPageNavBar.displayName = 'PolicyPageNavBar';

export default PolicyPageNavBar;
