import { Box, createStyles, Image } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import type { ISecodaEntity } from '../../api';
import { useIconSrcFromIntegrationId } from '../../utils/integrationLogo';
import IntegrationLogo from '../IntegrationLogo';

export interface IEntityLogoProps {
	entity: ISecodaEntity;
	size: number;
}

const useStyles = createStyles((theme) => ({
	container: {
		position: 'relative',
	},
	sourceLogo: {
		position: 'absolute',
		backgroundColor: theme.other.getColor('fill/primary/default'),
		borderRadius: '50%',
		padding: 2,
	},
}));

const calculateLogoPosition = (size: number) => {
	const position: {
		bottom: string | undefined;
		right: string | undefined;
	} = {
		right: undefined,
		bottom: undefined,
	};
	position.bottom = `-${size / 4}px`;
	position.right = `calc(50% - ${size / 2}px`;

	return position;
};

function IntegrationSourceLogo({
	integrationId,
	size,
}: {
	integrationId: string;
	size: number;
}) {
	const { classes } = useStyles();
	const src = useIconSrcFromIntegrationId(integrationId);

	if (!src) {
		return null;
	}

	return (
		<Box
			key={integrationId}
			className={classes.sourceLogo}
			style={calculateLogoPosition(size)}
		>
			<Image height={size / 3} width={size / 3} src={src} />
		</Box>
	);
}

function EntityLogo({ entity, size }: IEntityLogoProps) {
	const { classes } = useStyles();

	return (
		<Box className={classes.container}>
			<IntegrationLogo
				height={size}
				width={size}
				entityType={EntityType.integration}
				integrationId={entity.integration}
				iconOverride={entity.icon ?? undefined}
			/>
			{entity.sources.map((sourceId) => (
				<IntegrationSourceLogo
					key={sourceId}
					integrationId={sourceId}
					size={size}
				/>
			))}
		</Box>
	);
}

export default EntityLogo;
