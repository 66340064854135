import { Icon } from '@repo/foundations';
import type { TablerIconsProps } from '@tabler/icons-react';
import {
	IconBook,
	IconChartBar,
	IconChartHistogram,
	IconCircleCaretRight,
	IconClick,
	IconCloud,
	IconColumns,
	IconDatabase,
	IconFileDescription,
	IconFolder,
	IconFolders,
	IconPresentationAnalytics,
	IconQuestionMark,
	IconSchema,
	IconTable,
	IconTemplate,
	IconUserCircle,
} from '@tabler/icons-react';
import { capitalize } from 'lodash-es';
import { EntityType } from '../enums/entityType';

export interface IEntityTypeDisplayInfo {
	icon: (props: TablerIconsProps) => JSX.Element;
	label: string;
}

export const getEntityDisplayType = (
	entityType: EntityType | undefined
): string => capitalize(entityType?.replace('_', ' '));

export const getEntityTypeDisplayInfo = (
	entityType: EntityType | undefined
): IEntityTypeDisplayInfo => {
	switch (entityType) {
		case EntityType.user:
			return {
				icon: IconUserCircle,
				label: 'People',
			};
		case EntityType.dictionary_term:
			return {
				icon: IconBook,
				label: 'Terms',
			};
		case EntityType.glossary:
			return {
				icon: IconBook,
				label: 'Glossary',
			};
		case EntityType.metric:
			return {
				icon: IconChartHistogram,
				label: 'Metrics',
			};
		case EntityType.question:
			return {
				icon: IconQuestionMark,
				label: 'Questions',
			};
		case EntityType.document:
			return {
				icon: IconFileDescription,
				label: 'Documents',
			};
		case EntityType.table:
			return {
				icon: IconTable,
				label: 'Tables',
			};
		case EntityType.dashboard:
			return {
				icon: IconPresentationAnalytics,
				label: 'Dashboards',
			};
		case EntityType.job:
			return {
				icon: IconCircleCaretRight,
				label: 'Jobs',
			};
		case EntityType.collection:
			return {
				icon: IconFolders,
				label: 'Collections',
			};
		case EntityType.column:
			return {
				icon: IconColumns,
				label: 'Columns',
			};
		case EntityType.chart:
			return {
				icon: IconChartBar,
				label: 'Charts',
			};
		case EntityType.event:
			return {
				icon: IconClick,
				label: 'Events',
			};
		case EntityType.database:
			return {
				icon: IconDatabase,
				label: 'Database',
			};
		case EntityType.schema:
			return {
				icon: IconSchema,
				label: 'Schema',
			};
		case EntityType.dashboard_group:
			return {
				icon: IconFolder,
				label: 'Dashboard Group',
			};
		case EntityType.job_group:
			return {
				icon: IconFolder,
				label: 'Job Group',
			};
		case EntityType.template:
			return {
				icon: IconTemplate,
				label: 'Templates',
			};
		default:
			return {
				icon: IconCloud,
				label: 'Resource',
			};
	}
};

export const getEntityTypeIcon = (
	entityType: EntityType | undefined
): JSX.Element => {
	switch (entityType) {
		case EntityType.user:
			return <Icon name="userCircle" color="icon/primary/default" />;
		case EntityType.dictionary_term:
			return <Icon name="book" color="icon/primary/default" />;
		case EntityType.glossary:
			return <Icon name="book" color="icon/primary/default" />;
		case EntityType.metric:
			return <Icon name="chartHistogram" color="icon/primary/default" />;
		case EntityType.question:
			return <Icon name="questionMark" color="icon/primary/default" />;
		case EntityType.document:
			return <Icon name="fileDescription" color="icon/primary/default" />;
		case EntityType.table:
			return <Icon name="table" color="icon/primary/default" />;
		case EntityType.dashboard:
			return <Icon name="presentationAnalytics" color="icon/primary/default" />;
		case EntityType.job:
			return <Icon name="circleCaretRight" color="icon/primary/default" />;
		case EntityType.collection:
			return <Icon name="folders" color="icon/primary/default" />;
		case EntityType.column:
			return <Icon name="columns" color="icon/primary/default" />;
		case EntityType.chart:
			return <Icon name="chartBar" color="icon/primary/default" />;
		case EntityType.event:
			return <Icon name="click" color="icon/primary/default" />;
		case EntityType.database:
			return <Icon name="database" color="icon/primary/default" />;
		case EntityType.schema:
			return <Icon name="schema" color="icon/primary/default" />;
		case EntityType.dashboard_group:
			return <Icon name="folder" color="icon/primary/default" />;
		case EntityType.job_group:
			return <Icon name="folder" color="icon/primary/default" />;
		case EntityType.template:
			return <Icon name="template" color="icon/primary/default" />;
		default:
			return <Icon name="cloud" color="icon/primary/default" />;
	}
};
