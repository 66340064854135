import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';

import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import type {
	EditorDictionary,
	SecodaEditorComponentProps,
} from '@repo/secoda-editor/types';
import { ToastType } from '@repo/secoda-editor/types';
import copy from 'copy-to-clipboard';
import type { Token } from 'markdown-it';
import type { ParseSpec } from 'prosemirror-markdown';
import { NodeSelection } from 'prosemirror-state';
import { memo, useCallback } from 'react';
import { ChartBlockContainer } from '../components/ChartBlock/ChartBlockContainer';
import type { CreateGraphModalResult } from '../components/ChartBlock/CreateGraphModal';
import chartBlocksRule from '../rules/chartblocks';
import ReactNode from './ReactNode';

export default class ChartBlock extends ReactNode {
	get name() {
		return 'chart_block';
	}

	get markdownToken() {
		return 'chart_block';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				id: {
					default: '',
				},
				xAxis: {
					default: '',
				},
				yAxis: {
					default: '',
				},
				dimension: {
					default: '',
				},
				numericFormat: {
					default: '',
				},
			},
			group: 'block',
			defining: true,
			atom: true,
			draggable: false,
			parseDOM: [
				{
					preserveWhitespace: 'full',
					tag: 'div.chartblock',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									id: dom.getAttribute('id'),
									xAxis: dom.getAttribute('data-x-axis'),
									yAxis: dom.getAttribute('data-y-axis'),
									dimension: dom.getAttribute('data-dimension'),
									numericFormat: dom.getAttribute('data-numeric-format'),
								},
				},
			],
			toDOM: (node) => [
				'div',
				{
					...node.attrs,
					class: 'chartblock',
					id: node.attrs.id,
					'data-x-axis': node.attrs.xAxis,
					'data-y-axis': node.attrs.yAxis,
					'data-dimension': node.attrs.dimension,
					'data-numeric-format': node.attrs.numericFormat,
				},
				node.attrs.id,
			],
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		const { id, xAxis, yAxis, dimension, numericFormat } = node.attrs;
		if (id) {
			state.write(
				`\n\n:;:${id}|${xAxis ?? ''}|${yAxis ?? ''}|${dimension ?? ''}|${numericFormat ?? ''}:;:\n\n`
			);
			state.closeBlock(node);
		}
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'chart_block',
			getAttrs: (token: Token) => {
				const parsedAttrs = (token.attrs ?? []).reduce(
					(acc, item: [string, string]) => {
						if (item?.length !== 2) {
							return acc;
						}

						return {
							...acc,
							[item[0]]: item[1],
						};
					},
					{} as Record<string, string>
				);

				return {
					id: parsedAttrs.id,
					xAxis: parsedAttrs.xAxis,
					yAxis: parsedAttrs.yAxis,
					dimension: parsedAttrs.dimension,
					numericFormat: parsedAttrs.numericFormat,
				};
			},
		};
	}

	get rulePlugins() {
		return [chartBlocksRule];
	}

	commands() {
		return () => () => true;
	}

	component = (props: SecodaEditorComponentProps) => (
		<ChartBlockContainerWrapper
			{...props}
			readOnly={this.editorState.readOnly}
			onShowToast={this.options.onShowToast}
			dictionary={this.options.dictionary}
			onTrackEvent={this.editor?.options?.onTrackEvent}
		/>
	);
}

const ChartBlockContainerWrapper = memo(
	({
		node,
		getPos,
		isSelected,
		view,
		readOnly,
		onShowToast,
		dictionary,
		onTrackEvent,
	}: SecodaEditorComponentProps & {
		readOnly: boolean;
		onShowToast: (message: string, type: ToastType) => void;
		dictionary: EditorDictionary;
		onTrackEvent?: (
			eventName: string,
			properties?: Record<string, string>
		) => void;
	}) => {
		const onDelete = useCallback(() => {
			const $pos = view.state.doc.resolve(getPos());
			const tr = view.state.tr.setSelection(new NodeSelection($pos));
			view.dispatch(tr.deleteSelection());
			view.focus();
			onShowToast(dictionary.chartDeleted, ToastType.Info);
		}, [dictionary.chartDeleted, getPos, onShowToast, view]);

		const onDuplicate = useCallback(() => {
			const emptyParagraph = view.state.schema.nodes['paragraph'].create();
			const pos = getPos();

			view.dispatch(
				view.state.tr
					.insert(
						pos + node.nodeSize, // after the current query block
						emptyParagraph
					)
					.insert(
						pos + node.nodeSize + emptyParagraph.nodeSize, // after the empty paragraph created above
						node.type.create(node.attrs)
					)
			);
			view.focus();
			onShowToast(dictionary.chartDuplicated, ToastType.Info);
		}, [dictionary.chartDuplicated, getPos, node, onShowToast, view]);

		const onCopyLink = useCallback(() => {
			const hash = `#metric-chart-${node.attrs.id}`;

			onTrackEvent?.('editor/chart-block-copy-link');

			// The existing url might contain a hash already, lets make sure to remove
			// that rather than appending another one.
			const urlWithoutHash = window.location.href.split('#')[0];
			copy(urlWithoutHash + hash);
			onShowToast(dictionary.linkCopied, ToastType.Info);
		}, [dictionary.linkCopied, node.attrs.id, onShowToast, onTrackEvent]);

		const onSave = useCallback(
			async (result: CreateGraphModalResult) => {
				const { xAxis, yAxis, dimension, numericFormat } = result;
				const pos = getPos();

				const tr = view.state.tr.setNodeMarkup(pos, null, {
					...node.attrs,
					xAxis,
					yAxis,
					dimension,
					numericFormat,
				});
				view.dispatch(tr);
			},
			[getPos, node.attrs, view]
		);

		return (
			<ChartBlockContainer
				id={node.attrs.id}
				readOnly={readOnly}
				onDelete={onDelete}
				isSelected={isSelected}
				onDuplicate={onDuplicate}
				onCopyLink={onCopyLink}
				onSave={onSave}
			/>
		);
	}
);
