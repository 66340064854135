import { Stack } from '@mantine/core';
import { UserRole } from '@repo/common/enums/UserRole';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';
import {
	queryClient,
	reportsQueryKeyFactory,
	useUpdateReport,
} from '../../api';
import type { ButtonDetails } from '../../components/EmptyState';
import { EmptyState } from '../../components/EmptyState';
import EntityPageTitle from '../../components/EntityPageLayout/EntityPageTitle';
import { FeatureGuard } from '../../components/FeatureGuard/FeatureGuard';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { appShellStore } from '../../components/SecodaAppShell/store';
import { usePlan } from '../../hooks/usePlans';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import { useReportManagement } from '../HomePage/hooks/useReportManagement';
import { DynamicReport } from './DynamicReport';
import { DataQualityReport } from './staticReports/DataQualityReport';
export interface IAnalyticsPageProps {
	id?: string;
}

function AnalyticsPage({ id: propsId }: IAnalyticsPageProps) {
	const paramsId = useParamsIdSuffixUuid();
	const id = propsId || paramsId;
	const { plan } = usePlan();
	const navigate = useNavigate();
	const { report } = useReportManagement({
		type: 'analytics',
		id,
	});

	useEffect(() => {
		runInAction(() => {
			appShellStore.navBarUI.analyticsPage.showAddWidgetButton =
				report?.type === 'analytics';
			appShellStore.navBarUI.analyticsPage.downloadDataQualityScore.downloadButtonVisible =
				report?.type === 'data_quality';
			appShellStore.navBarUI.analyticsPage.reportId = report?.id || '';
			appShellStore.navBarUI.analyticsPage.reportTitle = report?.name || '';
		});
	}, [report]);

	const { mutateAsync: updateReport } = useUpdateReport({
		disableOptimisticUpdate: true,
		disableInvalidation: true,
		options: {
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: reportsQueryKeyFactory.byArgs('analytics', id),
				});
				queryClient.invalidateQueries({
					queryKey: reportsQueryKeyFactory.allLists(),
				});
			},
		},
	});

	// Handle empty state
	const emptyStateActions: ButtonDetails[] = [
		{
			name: 'Choose plan',
			action: () => navigate('/plans'),
			isPrimary: true,
			size: 'md',
		},
	];

	if (!plan?.analytics_support) {
		return (
			<EmptyState
				iconName="bolt"
				title="Upgrade to access Analytics"
				stateHeight="80vh"
				description="Workspace analytics is only available on paid plans. Upgrade your plan to get access to this feature."
				buttons={emptyStateActions}
				includeGoBack={false}
				size="sm"
			/>
		);
	}

	if (!report) {
		return <LoadingSpinner />;
	}

	return (
		<FeatureGuard
			featureName="Analytics"
			v1AllowedRoles={[UserRole.ADMIN, UserRole.EDITOR]}
			v2Permission="Analytics.Read"
			isFeatureIncludedInPlan={plan?.analytics_support ?? false}
			isAlwaysIncludedFeature={false}
			description="Understand workspace usage, important KPIs, and more with analytics."
		>
			<Stack px="2xl" spacing="sm" pb="md" h="100%">
				<Helmet>
					<title>{report?.name} - Analytics</title>
				</Helmet>

				<Stack
					w="100%"
					maw={report?.type === 'data_quality' ? 848 : undefined}
					sx={{ margin: '0 auto' }}
				>
					<EntityPageTitle
						icon={null}
						placeholder={'New report'}
						entity={{
							title: report?.name,
							title_cased: report?.name,
						}}
						isReadOnly={false}
						onChange={(value) =>
							updateReport({ data: { id: report.id, name: value } })
						}
						wrapTitle
					/>
				</Stack>
				{report?.type === 'data_quality' ? (
					<DataQualityReport />
				) : (
					<DynamicReport id={id} />
				)}
			</Stack>
		</FeatureGuard>
	);
}

export default observer(AnalyticsPage);
