import { Box, createStyles, Group, Stack } from '@mantine/core';
import { Text } from '@repo/foundations';
import type { ReactNode } from 'react';

interface SettingsBlockProps {
	title: string | ReactNode;
	description?: string | ReactNode;
	children?: React.ReactNode;
	rightComponent?: React.ReactNode;
	rightComponentHover?: React.ReactNode;
	leftComponent?: React.ReactNode;
	testId?: string;
	onClickRow?: () => void;
}

const useStyles = createStyles(
	(theme, { hasOnClickRow }: { hasOnClickRow: boolean }) => ({
		wrapper: {
			gap: 0,
			justifyContent: 'center',
			paddingTop: theme.spacing.sm, // Exact 12px padding top
			paddingBottom: theme.spacing.sm, // Exact 12px padding bottom
			'&:hover .right-component-hover': {
				opacity: 1,
			},
			'&:not(:hover) .right-component-hover': {
				opacity: 0,
			},
			paddingLeft: theme.spacing.md,
			paddingRight: theme.spacing.md,
			backgroundColor: theme.other.getColor('surface/primary/default'),
			borderRadius: theme.radius.md,
			border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
			transition: 'background-color 150ms ease',
			minHeight: theme.other.space[10] * 1.5, // Ensure minimum height of 60px
			boxSizing: 'border-box', // Ensure padding is included in height calculation
			...(hasOnClickRow && {
				'&:hover': {
					backgroundColor: theme.other.getColor('surface/primary/hover'),
				},
			}),
			'&:not(:last-child)': {
				borderBottom: 'none',
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
				position: 'relative',
				'&::after': {
					content: '""',
					position: 'absolute',
					bottom: 0,
					left: theme.spacing.md,
					right: theme.spacing.md,
					height: '1px',
					backgroundColor: theme.other.getColor('border/secondary/default'),
				},
			},
			'&:not(:first-child)': {
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0,
				borderTop: 'none',
			},
		},
		header: {
			display: 'flex',
			justifyContent: 'space-between',
			gap: theme.spacing.sm,
			flexWrap: 'nowrap',
		},
		content: {
			marginTop: theme.spacing.xs,
		},
		title: {
			fontSize: theme.other.typography.text.sm,
			fontWeight: 500,
			wordWrap: 'break-word',
			lineHeight: theme.other.typography.lineHeight.text.sm,
		},
		description: {
			fontSize: theme.other.typography.text.xs, // 12px
			color: theme.other.getColor('text/secondary/default'),
			lineHeight: theme.other.typography.lineHeight.text.xs, // 16px as specified
			wordWrap: 'break-word',
		},
	})
);

export function SettingsBlock({
	title,
	description,
	children,
	rightComponent,
	rightComponentHover,
	leftComponent,
	testId,
	onClickRow,
}: SettingsBlockProps) {
	const { classes } = useStyles({ hasOnClickRow: !!onClickRow });

	return (
		<Stack
			onClick={onClickRow}
			sx={{
				cursor: onClickRow ? 'pointer' : 'default',
			}}
			className={classes.wrapper}
			data-testid={testId ?? `settings-block-${title}`}
		>
			<Group className={classes.header}>
				<Group spacing="sm" noWrap>
					{leftComponent && <Box>{leftComponent}</Box>}
					<Stack spacing={0}>
						<Text weight="semibold" className={classes.title}>
							{title}
						</Text>
						{description && (
							<Text className={classes.description}>{description}</Text>
						)}
					</Stack>
				</Group>
				{rightComponentHover && (
					<Box className={'right-component-hover'}>{rightComponentHover}</Box>
				)}
				{rightComponent && <Box>{rightComponent}</Box>}
			</Group>
			{children && <Box className={classes.content}>{children}</Box>}
		</Stack>
	);
}
