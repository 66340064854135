import { createStyles, Group, UnstyledButton } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { useState } from 'react';
import type { Monitor } from '../../../api';
import { MONITOR_ICON_MAPPING } from '../constants';
import { useIncidentDrawer } from '../v2/IncidentDrawerContext';
import MonitorErrorDrawer from './MonitorErrorDrawer';

interface IMonitorStatusBadgeProps {
	monitor?: Monitor;
	withAction?: boolean;
}

const useStyles = createStyles(() => ({
	badgeClickable: {
		cursor: 'pointer',
	},
}));

function MonitorStatusBadge({
	monitor,
	withAction = true,
}: IMonitorStatusBadgeProps) {
	const [errorDrawerOpen, setErrorDrawerOpen] = useState<boolean>(false);
	const { classes } = useStyles();

	const { openIncident } = useIncidentDrawer();

	if (!monitor) {
		return null;
	}

	const toggleErrorDrawer = () => {
		setErrorDrawerOpen((prevState) => !prevState);
	};

	const handleStatusBadgeClick = () => {
		if (monitor.status === 'error') {
			toggleErrorDrawer();
		} else if (monitor.status === 'incident' && monitor.last_incident) {
			openIncident(monitor.last_incident);
		}
	};

	const badge = (
		<Group spacing="3xs" noWrap>
			<Icon
				size="md"
				name={MONITOR_ICON_MAPPING[monitor.status].iconName}
				fillColor={MONITOR_ICON_MAPPING[monitor.status].iconFillColor}
				color={MONITOR_ICON_MAPPING[monitor.status].iconColor}
			/>
			<Text size="xs">{MONITOR_ICON_MAPPING[monitor.status].name}</Text>
		</Group>
	);

	if (!withAction) {
		return badge;
	}

	return (
		<>
			<UnstyledButton
				onClick={handleStatusBadgeClick}
				className={classes.badgeClickable}
			>
				{badge}
			</UnstyledButton>
			<MonitorErrorDrawer
				monitor={monitor}
				open={errorDrawerOpen}
				onClose={toggleErrorDrawer}
			/>
		</>
	);
}

export default MonitorStatusBadge;
