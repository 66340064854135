import type { SecodaEntity } from '../../lib/models';

export const docEntity = {
	save: () => {}, // No-op save
	id: '4ab998ca-9409-4d52-afe4-420da948eb5e',
	updated_at: '2024-01-04T04:02:52.329497Z',
	created_at: '2023-08-28T17:32:02.453201Z',
	entity_type: 'document',
	native_type: null,
	icon: null,
	title: 'Humanity',
	title_full: null,
	title_cased: null,
	description: null,
	definition:
		'\\\nte{[{sting {[{1}]}(4ab998ca-9409-4d52-afe4-420da948eb5e)}]}(4ab998ca-9409-4d52-afe4-420da948eb5f) 2 {[{3}]}(4ab998ca-9409-4d52-afe4-420da948eb5f)',
	definition_version: 1132,
	multiplayers: [],
	multiplayer_last_modified_by: null,
	multiplayer_last_modified: null,
	owners: ['9e3a416f-3308-4ed8-ac4b-921fac4d227e'],
	owners_groups: [],
	collections: [],
	tags: [],
	workspace_id: 'c520250d-2da3-4772-9a17-01609b34d185',
	published: false,
	archived: false,
	archived_at: null,
	pii: false,
	verified: false,
	url: null,
	parent: null,
	children_count: 0,
	properties: {},
	bookmarked_by: [],
	integration: null,
	external_updated_at: null,
	internal_usage: 626,
	internal_users: ['9e3a416f-3308-4ed8-ac4b-921fac4d227e'],
	external_users: [],
	external_usage: 0,
	databuilder_id: null,
	search_metadata: {},
	sources: [],
	subscribers: ['9e3a416f-3308-4ed8-ac4b-921fac4d227e'],
	teams: ['9c0166d2-e4c4-4a68-9db8-b474fd68d536'],
	stale: false,
	stale_at: null,
	forced_unstale_by_user: false,
	current_user_permissions: {
		read: true,
		write: true,
		execute: true,
	},
	row_count: null,
	byte_size: null,
	parent_id: null,
	base_entity_id: '4ab998ca-9409-4d52-afe4-420da948eb5e',
} as unknown as SecodaEntity;

export const currentUser = {
	id: '9e3a416f-3308-4ed8-ac4b-921fac4d227e',
	first_name: 'no',
	last_name: 'bruh',
	email: 'tan@secoda.co',
	display_name: 'no bruh',
	use_reason: '',
	role_name: '',
	role: 'admin',
	workspace_id: 'c520250d-2da3-4772-9a17-01609b34d185',
	secoda_is_superuser: false,
	profile_picture: null,
	pending: false,
	user_groups: [],
	bookmarks: [],
	workspace: {
		id: 'c520250d-2da3-4772-9a17-01609b34d185',
		created_at: '2023-07-31T14:12:56.309936Z',
		updated_at: '2023-07-31T14:12:56.309963Z',
		name: 'test',
		enforce_sso: null,
		lax_cookie_security: false,
		customer_id: null,
		customer_plan_id: 'test',
		demo_data_added: false,
		disable_analytics: false,
		hide_feedback_for_viewers: false,
		hide_sensitive_integration_params: false,
		hide_dictionary_term_query_section: false,
		onboarding_completed: false,
		publicly_available: false,
		logo: '',
		background_image: null,
		free_trial_started_at: '2023-07-31T14:12:56.310020Z',
		notification_preferences: {
			notify_mentions: true,
			notify_joined_team: true,
			notify_announcement: true,
			notify_question_new: true,
			notify_access_request: true,
			notify_change_request: true,
			notify_question_reply: true,
			notify_question_reply_edited: true,
			notify_question_reply_removed: true,
			notify_question_answer: true,
			notification_preferences: true,
			notify_resource_archived: true,
			notify_resource_published: true,
			notify_resource_tag_added: true,
			notify_resource_tag_removed: true,
			notify_resource_unpublished: true,
			notify_table_schema_changed: true,
			notify_extraction_job_failed: true,
			notify_queryblock_job_failed: true,
			notify_applied_access_request: true,
			notify_applied_change_request: true,
			notify_extraction_column_added: true,
			notify_question_owner_assigned: true,
			notify_resource_owner_assigned: true,
			notify_extraction_job_completed: true,
			notify_metadata_push_job_failed: true,
			notify_queryblock_job_completed: true,
			notify_extraction_column_removed: true,
			notify_resource_collection_added: true,
			notify_metadata_push_job_completed: true,
			notify_resource_collection_removed: true,
			notify_resource_definition_updated: true,
			notify_resource_description_updated: true,
		},
		inherit_parent_document_properties: false,
		default_dictionary_term_form: null,
		default_document_form: null,
		default_question_form: null,
		members_count: 1,
		ai_search: false,
		integration_analytics_enabled: true,
		default_full_width_pages: false,
		send_notifications_to_service_account: false,
		anonymous_user_id: null,
		has_connected_integration: true,
		default_publishing_enabled: false,
	},
	is_service_account: false,
	service_account_count_popularity: true,
	last_login: '2023-12-27T20:17:03.773416Z',
	new_feature_acknowledged: true,
	user_appearance: 'system',
	disabled: false,
};

export const comments = [
	{
		id: '41b0215a-2c1e-43b5-a673-a5addc284745',
		created_at: '2024-01-17T04:51:41.628633Z',
		updated_at: '2024-01-17T04:51:41.631967Z',
		workspace: 'c520250d-2da3-4772-9a17-01609b34d185',
		owner: '9e3a416f-3308-4ed8-ac4b-921fac4d227e',
		entity: '4ab998ca-9409-4d52-afe4-420da948eb5e',
		resolved: false,
		definition: 'test',
		reactions: [],
		order: 0,
		root: '41b0215a-2c1e-43b5-a673-a5addc284745',
		parent: null,
	},
	{
		id: 'f991a496-deb1-492a-8a00-297d6b993b89',
		created_at: '2024-01-17T07:06:28.064780Z',
		updated_at: '2024-01-17T07:06:28.069596Z',
		workspace: 'c520250d-2da3-4772-9a17-01609b34d185',
		owner: '9e3a416f-3308-4ed8-ac4b-921fac4d227e',
		entity: '4ab998ca-9409-4d52-afe4-420da948eb5e',
		resolved: false,
		definition: 'more',
		reactions: [],
		order: 1,
		root: '41b0215a-2c1e-43b5-a673-a5addc284745',
		parent: '41b0215a-2c1e-43b5-a673-a5addc284745',
	},
];
