import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
	root: {
		display: 'inline-flex',
		alignItems: 'center',
		gap: theme.spacing['2xs'],
	},
	dot: {
		width: 4,
		height: 4,
		borderRadius: '50%',
		backgroundColor: theme.other.getColor('text/secondary/default'),
		animation: 'bounce 1.4s infinite ease-in-out both',
		'&:nth-child(1)': {
			animationDelay: '-0.32s',
		},
		'&:nth-child(2)': {
			animationDelay: '-0.16s',
		},
		'@keyframes bounce': {
			'0%, 80%, 100%': {
				transform: 'scale(0)',
			},
			'40%': {
				transform: 'scale(1)',
			},
		},
	},
}));

export function LoadingDots() {
	const { classes } = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.dot} />
			<div className={classes.dot} />
			<div className={classes.dot} />
		</div>
	);
}
