import { Stack } from '@mantine/core';
import { Button, Title } from '@repo/foundations';

import type { ButtonDetails } from '@repo/common/components';
import { EmptyState } from '@repo/common/components';
import { integrationList } from '@repo/common/constants/integration/integrations';
import { width } from '@repo/theme/primitives';
import { isEmpty } from 'lodash-es';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import type { IIntegration } from '../../../api';
import { useIntegrationList } from '../../../api';
import NewIntegrationModal from '../../Integration/AddIntegrationModal';
import { useStyles as useNewIntegrationModalStyles } from '../../Integration/AddIntegrationModal/AddIntegrationModal.styles';
import { NEW_INTEGRATION_MODAL_ID } from '../../Integration/AddIntegrationModal/constants';
import FullWidthLoadingSpinner from '../../LoadingSpinner';
import { openModal } from '../../ModalManager';
import { TableV2 } from '../../TableV2';
import type { ExtendedDataTableColumn } from '../../TableV2/types';
import { useActions, useColumns } from './ExtensionSettings.hooks';

const QUICK_ACTIONS = ['actions::delete'] as const;

const PAGINATION_LIST_OPTIONS = {
	suspense: true,
};

const SETTINGS_PAGE_ONLY_TYPES = integrationList
	.filter((i) => i.isExtension)
	.map((i) => i.type);

export function ExtensionSettings() {
	const { data: integrations, isLoading } = useIntegrationList({
		page: 1,
		filters: {
			type__in: SETTINGS_PAGE_ONLY_TYPES.join(','),
		},
	});

	const { classes: addIntegrationModalClasses } =
		useNewIntegrationModalStyles();

	const columns = useColumns();
	const actions = useActions();

	const navigate = useNavigate();

	const handleClick = useCallback(
		(id: string) => {
			navigate(`/integrations/${id}`);
		},
		[navigate]
	);

	const handleConnectIntegration = useCallback(() => {
		openModal({
			modalId: NEW_INTEGRATION_MODAL_ID,
			title: <Title size="md">Connect Extension</Title>,
			children: (
				<NewIntegrationModal integrationTypes={SETTINGS_PAGE_ONLY_TYPES} />
			),
			size: width.xxl,
			radius: 'lg',
			classNames: {
				header: addIntegrationModalClasses.header,
				body: addIntegrationModalClasses.body,
			},
		});
	}, [addIntegrationModalClasses.body, addIntegrationModalClasses.header]);

	const onCellClick = useCallback(
		(cell) => {
			if (
				(cell.column as ExtendedDataTableColumn<unknown>).navigate !== false
			) {
				handleClick(cell.record.id);
			}
		},
		[handleClick]
	);

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (!isLoading && isEmpty(integrations)) {
		const buttons: ButtonDetails[] = [
			{
				name: 'Learn more',
				action: () => {
					window.open('https://docs.secoda.co/integrations', '_blank');
				},
				isPrimary: false,
				size: 'md',
				leftIconName: 'externalLink',
			},
			{
				name: 'Connect extension',
				action: handleConnectIntegration,
				isPrimary: true,
				size: 'md',
			},
		];

		return (
			<EmptyState
				title="Connect your first extension"
				description="Set up two or more extensions to seamlessly interact, govern and view lineage with your data."
				illustrationName="integrations"
				includeGoBack={false}
				size="lg"
				buttons={buttons}
				stateHeight="80vh"
			/>
		);
	}

	return (
		<Stack>
			<TableV2<IIntegration>
				pluralTypeString="extensions"
				columns={columns}
				usePaginationList={useIntegrationList}
				defaultRequiredSearchParams={{
					type__in: SETTINGS_PAGE_ONLY_TYPES.join(','),
				}}
				usePaginationListOptions={PAGINATION_LIST_OPTIONS}
				withActions={actions}
				withCheckbox={true}
				withQuickActions={QUICK_ACTIONS}
				withAdditionalButtons={
					<Button onClick={handleConnectIntegration} variant="primary">
						Connect extension
					</Button>
				}
				onCellClick={onCellClick}
			/>
		</Stack>
	);
}

export default ExtensionSettings;
