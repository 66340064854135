import type { DataTreeNode, Filter } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { useMemo, useState } from 'react';
import { useCatalogInfiniteList } from '../../../api/hooks/resourceCatalog';
import { useParamsIdSuffixUuid } from '../../../utils/hook/utils';
import { CATALOG_TREE_INTEGRATION_NODE_CHILD_ENTITY_TYPES } from '../CatalogTree/constants';
import type { CommonEntityTreeNode } from '../utils';
import { EntityTreeNode, TreeNodeDisplay } from './EntityTreeNode';
import { LoadMore } from './LoadMore';

export interface PrefetchedEntityTreeNodeProps {
	node: CommonEntityTreeNode;
	children?: DataTreeNode[];
	level: number;
	getLabel: (node: CommonEntityTreeNode) => React.ReactNode;
	getIcon?: (node: CommonEntityTreeNode) => React.ReactNode;
	onClick: (e: React.MouseEvent, node: CommonEntityTreeNode) => void;
	onOpen?: (open: boolean) => void;
}

export function BottomUpTreeEntityNode({
	node,
	children,
	level,
	getLabel,
	getIcon,
	onClick,
	onOpen,
}: PrefetchedEntityTreeNodeProps) {
	const paramsId = useParamsIdSuffixUuid();
	const [open, setOpen] = useState(false);

	const childrenMapping = useMemo(
		() =>
			children?.reduce(
				(acc, child) => {
					acc[child.id] = {
						isLeaf: child.is_leaf,
						children: child.children ?? [],
					};
					return acc;
				},
				{} as Record<string, { isLeaf: boolean; children: DataTreeNode[] }>
			) ?? {},
		[children]
	);

	const childIds: string[] = useMemo(
		() => children?.map((child) => child.id) ?? [],
		[children]
	);

	const filterOperands = useMemo(() => {
		const operands = [
			{
				operator: 'in',
				field: 'id',
				value: childIds,
			},
		];

		if (node.entity.entity_type === EntityType.integration) {
			// If the node is an integration, we only want to show the children that are
			// databases, schemas, dashboard groups, categories, jobs, or job groups.
			operands.push({
				operator: 'in',
				field: 'entity_type',
				value: CATALOG_TREE_INTEGRATION_NODE_CHILD_ENTITY_TYPES,
			});
		}

		return operands;
	}, [childIds, node.entity.entity_type]);

	const {
		data: childrenData,
		isFetching,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
	} = useCatalogInfiniteList({
		filters: {
			filter: {
				operator: 'and',
				operands: filterOperands,
			} as Filter,
			sort: {
				field: 'title',
				order: 'asc',
			},
			page_size: 50,
		},
		options: {
			enabled: open && !!children,
		},
	});

	const isSelected = useMemo(() => {
		if ('entity' in node) {
			return paramsId === node.id;
		}
		return false;
	}, [node, paramsId]);

	const toggle = (e: React.MouseEvent) => {
		e.stopPropagation();
		onOpen?.(!open);
		setOpen(!open);
	};

	const showLoadMore = open && (hasNextPage || isFetchingNextPage);

	return (
		<>
			<TreeNodeDisplay
				node={node}
				level={level}
				getLabel={getLabel}
				getIcon={getIcon}
				onClick={onClick}
				open={open}
				isFetchingChildren={isFetching || (open && !children)}
				toggle={toggle}
				isSelected={isSelected}
			/>
			{open && (
				<div className="flex flex-col gap-2">
					{childrenData?.pages
						.flatMap((page) => page)
						.map((child) =>
							childrenMapping[child.id].isLeaf ? (
								<EntityTreeNode
									key={child.id}
									node={{
										id: child.id,
										hasChildren: child.children_count > 0,
										entity: child,
									}}
									level={level + 1}
									getLabel={getLabel}
									getIcon={getIcon}
									onClick={onClick}
									childFilterOperands={({ id }) => [
										{
											operator: 'exact',
											field: 'parent_id',
											value: id,
											operands: [],
										},
									]}
								/>
							) : (
								<BottomUpTreeEntityNode
									key={child.id}
									node={{
										id: child.id,
										hasChildren: childrenMapping[child.id].children.length > 0,
										entity: child,
									}}
									children={childrenMapping[child.id].children}
									level={level + 1}
									getLabel={getLabel}
									getIcon={getIcon}
									onClick={onClick}
								/>
							)
						)}
				</div>
			)}
			{showLoadMore && (
				<LoadMore
					level={level + 1}
					isLoading={isFetchingNextPage}
					onClick={fetchNextPage}
				/>
			)}
		</>
	);
}
