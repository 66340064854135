import { Group, Stack } from '@mantine/core';

import { Button, Text, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { GovernanceFilterCard } from './GovernanceFilterCard';
import { GovernanceFilterStoreContext } from './store';
import type { UseGovernanceFiltersProps } from './useGovernanceFilters';
import { useGovernanceFilters } from './useGovernanceFilters';

interface GovernanceFiltersProps extends UseGovernanceFiltersProps {
	title?: string;
	description?: string;
	emptyState?: React.ReactNode;
	compact?: boolean;
	disabled?: boolean;
}

export const GovernanceFilters = observer(
	({
		emptyState,
		title,
		description,
		includedFilters,
		excludedFilters,
		onIncludedFiltersChange,
		onExcludedFiltersChange,
		onFiltersChange,
		filterOptions,
		compact = false,
		disabled = false,
	}: GovernanceFiltersProps) => {
		const store = useGovernanceFilters({
			includedFilters,
			excludedFilters,
			onIncludedFiltersChange,
			onExcludedFiltersChange,
			onFiltersChange,
			filterOptions,
		});

		return (
			<GovernanceFilterStoreContext.Provider value={store}>
				<Stack mt={`-${8}px`} spacing="lg">
					{!compact && !disabled && (
						<Group position="apart" noWrap>
							<Stack spacing="4xs">
								<Title size="sm">{title ?? 'Resource filter rules'}</Title>
								{description && (
									<Text size="xs" color="text/secondary/default">
										{description}
									</Text>
								)}
							</Stack>
							<Button onClick={store.onAddFilter}>Create rule</Button>
						</Group>
					)}

					{store.values.length === 0 && emptyState}
					{store.values.length > 0 && (
						<Stack spacing="md">
							{store.values.map((filter, idx) => (
								<GovernanceFilterCard
									// eslint-disable-next-line react/no-array-index-key
									key={`filter-card-${idx}`}
									filter={filter}
									filterIndex={idx}
									compact={compact}
									disabled={disabled}
								/>
							))}
						</Stack>
					)}

					{compact && !disabled ? (
						<Group position="left">
							<Button leftIconName="plus" onClick={store.onAddFilter}>
								Add rule
							</Button>
						</Group>
					) : null}
				</Stack>
			</GovernanceFilterStoreContext.Provider>
		);
	}
);
