import { Group, Select, Stack } from '@mantine/core';
import { Button } from '@repo/foundations';
import { isNil } from 'lodash-es';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useIntegration, useUpdateIntegration } from '../../../api';

interface IIntegrationAlertConfigProps {
	integrationId: string;
}

function IntegrationAlertConfig({
	integrationId,
}: IIntegrationAlertConfigProps): JSX.Element {
	// Fetch the integration data by its id
	const { data: integration } = useIntegration({
		id: integrationId,
		options: {
			enabled: !!integrationId,
		},
	});

	const alertSeverityOptions = [
		{ label: 'Critical', value: 'critical' },
		{ label: 'Error', value: 'error' },
		{ label: 'Warning', value: 'warning' },
		{ label: 'Info', value: 'info' },
	];

	// Initialize the mutation function for updating the integration
	const { mutate, isLoading } = useUpdateIntegration({
		disableOptimisticUpdate: true,
		disableInvalidation: true,
	});

	/*
	 * Integration Configuration
	 * - severity
	 */
	const [severity, setSeverity] = useState<string>('');
	const updateIntegrationConfig = useCallback(() => {
		if (isNil(integration)) {
			return;
		}

		setSeverity((integration.credentials?.severity ?? 'warning') as string);
	}, [integration]);

	useEffect(() => {
		updateIntegrationConfig();
	}, [updateIntegrationConfig]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		mutate({
			data: { id: integrationId, credentials: { ...integration?.credentials } },
		});
	};

	const handleSeverityChange = (value: string) => {
		setSeverity(value);
		if (integration) {
			integration.credentials.severity = value;
		}
	};

	// Render the component
	return (
		<Stack>
			<form onSubmit={handleSubmit}>
				<Select
					mb={24}
					data={alertSeverityOptions}
					label="Severity"
					value={severity}
					onChange={handleSeverityChange}
				/>
				<Group spacing="xs">
					<Button
						loading={isLoading}
						mt={24}
						size="md"
						type="submit"
						variant="primary"
						disabled={isLoading}
					>
						Save Changes
					</Button>
				</Group>
			</form>
		</Stack>
	);
}

export default IntegrationAlertConfig;
