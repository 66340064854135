import type { ButtonVariants, IconNames } from '@repo/foundations';
import { Button, IconButton } from '@repo/foundations';
import type { IconButtonVariants } from '@repo/foundations/components/Buttons/IconButton';
import type { ReactNode } from 'react';
import { SettingsBlock } from '../SettingsBlock';

interface SettingsButtonProps {
	title: string | ReactNode;
	description?: string | ReactNode;
	buttonText?: string;
	onClick?: () => void;
	variant?: ButtonVariants;
	disabled?: boolean;
	children?: React.ReactNode;
	iconName?: IconNames;
	leftComponent?: React.ReactNode;
	tooltip?: string;
	onClickRow?: () => void;
}

export function SettingsButton({
	title,
	description,
	buttonText,
	onClick,
	onClickRow,
	variant = 'default',
	disabled = false,
	children,
	iconName,
	leftComponent,
	tooltip,
}: SettingsButtonProps) {
	const renderRightComponent = () => {
		if (children) {
			return children;
		}

		if (iconName) {
			return (
				<IconButton
					tooltip={tooltip}
					iconName={iconName}
					onClick={onClick}
					variant={variant as IconButtonVariants}
					disabled={disabled}
					data-testid={`settings-icon-button-${title}`}
				/>
			);
		}

		if (buttonText) {
			return (
				<Button
					onClick={onClick}
					variant={variant}
					disabled={disabled}
					size="md"
					data-testid={`settings-button-${title}`}
				>
					{buttonText}
				</Button>
			);
		}

		return null;
	};

	return (
		<SettingsBlock
			onClickRow={onClickRow}
			title={title}
			description={description}
			rightComponent={renderRightComponent()}
			leftComponent={leftComponent}
		/>
	);
}
