import { Image, useMantineTheme } from '@mantine/core';
import { SettingsImageUpload } from '@repo/common/components';
import { isEmpty } from 'lodash-es';

interface SettingsWorkspaceImageUploadProps {
	title: string;
	onChange: (file: File | null) => void;
	size?: number;
	imageUrl?: string;
}

export function SettingsWorkspaceImageUpload({
	title,
	onChange,
	imageUrl,
	size = 28,
}: SettingsWorkspaceImageUploadProps) {
	const theme = useMantineTheme();

	return (
		<SettingsImageUpload
			title={title}
			onChange={onChange}
			placeholder={
				<Image
					src={isEmpty(imageUrl) ? '/images/logos/cfsecoda.webp' : imageUrl}
					mih={size}
					miw={size}
					height={size}
					width={size}
					radius={theme.other.borderRadius.sm}
					fit="contain"
				/>
			}
		/>
	);
}
