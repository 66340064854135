import { Checkbox, Divider, Radio, Stack } from '@mantine/core';
import type { IIntegration } from '@repo/common/models/integration';
import { Banner, Switch } from '@repo/foundations';
import { upperFirst } from 'lodash-es';
import { useUpdateIntegration } from '../../../../../api';
import { getPermissionRequirementsForIntegrationType } from './permissionRequirements';
import { PreferenceSection } from './PreferenceSection';

interface AccessRequestPreferencesProps {
	integration: IIntegration;
}

// Access request approval types
type AccessRequestType = IIntegration['access_request_preference'];

export function AccessRequestPreferences({
	integration,
}: AccessRequestPreferencesProps) {
	const { mutateAsync: updateIntegration } = useUpdateIntegration({});
	const accessRequestType =
		integration.access_request_preference || 'temporary_user';
	const allowedPrivileges = integration.access_request_allowed_privileges || [
		'SELECT',
		'INSERT',
		'UPDATE',
		'DELETE',
	];

	const permissionRequirements = getPermissionRequirementsForIntegrationType(
		integration.type
	);

	return (
		<>
			<PreferenceSection
				header="Access Request"
				subheader={`Allow workspace members to request access to ${upperFirst(integration.type)} resources.`}
			>
				<Stack spacing="md">
					<Switch
						label="Enable access request"
						checked={!!integration.access_request_enabled}
						onChange={(e) => {
							updateIntegration({
								data: {
									id: integration.id,
									access_request_enabled: e.target.checked,
								},
							});
						}}
					/>

					{integration.access_request_enabled && (
						<>
							{permissionRequirements && (
								<Banner
									tone="info"
									title="Required Database Permissions"
									message={
										<span
											dangerouslySetInnerHTML={{
												__html: permissionRequirements,
											}}
											aria-label="Database permissions required for data access requests"
										/>
									}
									inCard
								/>
							)}

							<Radio.Group
								label="Access request approval type"
								value={accessRequestType}
								onChange={async (value: string) => {
									await updateIntegration({
										data: {
											id: integration.id,
											access_request_preference: value as AccessRequestType,
										},
									});
								}}
							>
								<Radio
									value="create_temporary_user_and_role"
									label="Create temporary user"
								/>
								<Radio
									value="create_and_grant_temporary_role"
									label="Select existing user"
								/>
								<Radio
									value="grant_privileges_to_role"
									label="Select existing role"
								/>
							</Radio.Group>

							<Checkbox.Group
								label="Allowed privileges"
								value={allowedPrivileges}
								onChange={async (value: string[]) => {
									await updateIntegration({
										data: {
											id: integration.id,
											access_request_allowed_privileges:
												value as IIntegration['access_request_allowed_privileges'],
										},
									});
								}}
							>
								<Stack spacing="2xs">
									<Checkbox value="SELECT" label="SELECT" />
									<Checkbox value="INSERT" label="INSERT" />
									<Checkbox value="UPDATE" label="UPDATE" />
									<Checkbox value="DELETE" label="DELETE" />
								</Stack>
							</Checkbox.Group>
						</>
					)}
				</Stack>
			</PreferenceSection>
			<Divider />
		</>
	);
}
