import { Box, Container, createStyles, Group, Stack } from '@mantine/core';
import type { TeamOut } from '@repo/api-codegen';
import { Title } from '@repo/foundations';
import EmojiIconSelector from '../../../components/EmojiIconSelector';
import TeamMembersListButton from '../../../components/TeamMembersListButton';
import { HOMEPAGE_MAX_WIDTH, HOMEPAGE_PADDING } from '../constants';
import { getBackgroundImageStyles } from '../utils';

interface IStyleParams {
	backgroundImage?: string;
}

interface ITeamsHomepageHeaderProps {
	team: TeamOut;
	image?: string;
	icon: string;
	onIconChange: (icon: string) => void;
}

const useStyles = createStyles((theme, { backgroundImage }: IStyleParams) => ({
	backgroundImage: getBackgroundImageStyles(theme, backgroundImage),
	emojiSelector: {
		paddingLeft: '0.5rem',
		marginTop: '-1rem',
	},
	title: {
		paddingTop: '1rem',
		flexGrow: 1,
	},
}));

function TeamsHomepageHeader({
	team,
	image,
	icon,
	onIconChange,
}: ITeamsHomepageHeaderProps) {
	const { classes } = useStyles({
		backgroundImage: image,
	});

	const title = `${team.name} Home`;

	return (
		<Stack spacing={0}>
			<Group
				position="right"
				align="start"
				className={classes.backgroundImage}
				miw={HOMEPAGE_MAX_WIDTH + 2 * HOMEPAGE_PADDING}
			/>
			<Container
				px={HOMEPAGE_PADDING}
				size={HOMEPAGE_MAX_WIDTH + 2 * HOMEPAGE_PADDING}
				miw={HOMEPAGE_MAX_WIDTH + 2 * HOMEPAGE_PADDING}
			>
				<Box className={classes.emojiSelector}>
					<EmojiIconSelector
						key={icon}
						icon={icon}
						iconProps={{ size: 64 }}
						title="Home page icon"
						onChange={onIconChange}
						emojiSize={64}
					/>
				</Box>
				<Group noWrap>
					<Title order={1} className={classes.title} lineClamp={1}>
						{title}
					</Title>
					<Box pt="1rem">
						<TeamMembersListButton size="md" spacing="md" team={team} />
					</Box>
				</Group>
			</Container>
		</Stack>
	);
}

export default TeamsHomepageHeader;
