import { createStyles, Loader, Tabs } from '@mantine/core';
import type { TabItem } from '@repo/common/components/TabsList';
import TabsList from '@repo/common/components/TabsList';
import type { ReactNode } from 'react';
import { Suspense, useMemo } from 'react';
import type { ISecodaEntity } from '../../api';
import { ErrorBoundary } from '../ErrorBoundary';

const useStyles = createStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
}));

export interface OverviewPageTab extends TabItem {
	component: ReactNode;
	condition?: boolean;
	loader?: ReactNode;
}

export interface OverviewPageTabsProps {
	entity: ISecodaEntity;
	currentTab: string;
	tabs: OverviewPageTab[];
	onTabChange: (tab: string) => void;
}

function OverviewPageTabs({
	entity,
	currentTab,
	tabs,
	onTabChange,
}: OverviewPageTabsProps) {
	const { classes } = useStyles();

	const classNames = {
		root: classes.root,
		panel: classes.panel,
	};

	const displayTabs = useMemo(
		() => tabs.filter((tab) => tab.condition !== false),
		[tabs]
	);

	return (
		<Tabs
			color="dark"
			classNames={classNames}
			value={currentTab}
			onTabChange={onTabChange}
			keepMounted={false}
		>
			<TabsList tabs={displayTabs} />
			{displayTabs.map(({ value, component, loader }) => (
				<Tabs.Panel key={value} value={value} pt="sm">
					<ErrorBoundary>
						<Suspense fallback={loader || <Loader />}>{component}</Suspense>
					</ErrorBoundary>
				</Tabs.Panel>
			))}
		</Tabs>
	);
}

export default OverviewPageTabs;
