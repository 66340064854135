import type { MantineThemeOverride } from '@mantine/core';
import { iconSize } from '../../primitives/sizing';

export const DateTimePicker: MantineThemeOverride['components'] = {
	DateTimePicker: {
		styles: (theme) => ({
			day: {
				color: theme.other.getColor('text/primary/default'),

				'&:hover': {
					backgroundColor: theme.other.getColor('fill/brand/hover'),
					color: theme.other.getColor('text/brand-on-fill/default'),
				},
				'&[data-today]': {
					backgroundColor: theme.other.getColor('fill/brand/hover'),
					color: theme.other.getColor('text/brand-on-fill/default'),
				},
				'&[data-outside]': {
					color: theme.other.getColor('text/primary/disabled'),
					'&:hover': {
						backgroundColor: theme.other.getColor('fill/brand/hover'),
						color: theme.other.getColor('text/brand-on-fill/default'),
					},
				},
				'&[data-in-range]': {
					backgroundColor: theme.other.getColor('surface/primary/selected'),
					color: theme.other.getColor('text/primary/default'),
					'&:hover': {
						backgroundColor: theme.other.getColor('fill/brand/hover'),
						color: theme.other.getColor('text/brand-on-fill/default'),
					},
				},
				'&[data-selected]': {
					backgroundColor: theme.other.getColor('fill/brand/selected'),
					color: theme.other.getColor('text/brand-on-fill/default'),

					'&:hover': {
						backgroundColor: theme.other.getColor('fill/brand/hover'),
						color: theme.other.getColor('text/brand-on-fill/default'),
					},
				},
				'&[data-weekend]': {
					color: theme.other.getColor('text/primary/default'),

					'&[data-selected], &:hover': {
						color: theme.other.getColor('text/brand-on-fill/default'),
					},
				},
			},
			input: {
				height: theme.other.space[8],
				backgroundColor: theme.other.getColor('surface/input/default'),
				borderRadius: theme.radius.sm,
				borderWidth: 0.5,
				borderStyle: 'solid',
				borderColor: theme.other.getColor('border/input/default'),
				'&:hover': {
					backgroundColor: theme.other.getColor('surface/input/default'),
					borderWidth: 0.5,
					borderColor: theme.other.getColor('border/input/hover'),
				},
				'&:focus, &:active': {
					backgroundColor: theme.other.getColor('surface/input/default'),
					borderWidth: 0.5,
					borderColor: theme.other.getColor('border/input/active'),
					boxShadow: `0px 0px 0px 1px white, 0px 0px 0px 3px ${theme.other.getColor('border/emphasis/default')}`,
				},
				'&:disabled': {
					backgroundColor: theme.other.getColor('surface/primary/disabled'),
					borderWidth: 0,
				},
				'&[data-with-icon]': {
					// have to calculate the padding of the label manually to be consistent with item icon spacing
					// 8px left pad + 20px icon size + 4px right pad
					paddingLeft:
						theme.other.space[1] + iconSize['md'] + theme.other.space[2],
				},
				'.mantine-DateTimePicker-placeholder': {
					color: theme.other.getColor('text/secondary/default'),
				},
			},
			placeholder: {
				color: theme.other.getColor('text/secondary/default'),
				marginLeft: theme.spacing['3xs'],
			},
		}),
	},
};
