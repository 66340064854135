import { Group } from '@mantine/core';
import { useGetAccessRequest } from '@repo/api-codegen';
import { Breadcrumbs, Title } from '@repo/foundations';
import { useParamsIdSuffixUuid } from '../../../utils/hook/utils';
import { AddRequestButton } from '../../DataAccessRequest/AddRequestButton';
import { DataAccessRequestControls } from '../../DataAccessRequest/DataAccessRequestControls/DataAccessRequestControls';
import { NavBarBreadcrumbWrapper } from './NavBarBreadcrumbWrapper';
import { NavBarRightSideWrapper } from './NavBarRightSideWrapper';
import { NavBarWrapper } from './NavBarWrapper';

export function RequestsPageNavBar() {
	const id = useParamsIdSuffixUuid();

	const isRequestPage = !!id;

	const { data: request } = useGetAccessRequest(
		{
			pathParams: {
				requestId: id,
			},
		},
		{
			enabled: isRequestPage,
		}
	);

	if (isRequestPage) {
		return (
			<NavBarWrapper>
				<NavBarBreadcrumbWrapper>
					<Breadcrumbs
						crumbs={[
							{
								title: 'Requests',
								href: '/requests',
							},
							{
								title: `${request?.integration_name} request`,
								href: `/requests/${id}`,
							},
						]}
					/>
				</NavBarBreadcrumbWrapper>
				<NavBarRightSideWrapper>
					<DataAccessRequestControls requestId={id} />
				</NavBarRightSideWrapper>
			</NavBarWrapper>
		);
	}

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Group>
					<Title order={1} size="sm" data-testid="requests-page-nav-bar-title">
						Requests
					</Title>
				</Group>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<AddRequestButton />
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}
