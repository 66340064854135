import type { Filter } from '@repo/api-codegen';
import axios from 'axios';
import { api } from '../../../../network';
import { convertObjectToUrlSearchParams } from '../../../lib/models';
import type {
	IApiListResponse,
	ISecodaEntity,
	IUseListQueryHookArgs,
	SearchResult,
} from '../../types';
import { useBaseModelList } from '../base';
import { MENTION_NAMESPACE, mentionQueryKeyFactory } from './constants';

export interface IMentionItem {
	name: string;
	title: string;
	description?: string;
	entity?: ISecodaEntity;
	attrs?: {
		markup: string;
		'data-name': string;
		href: string;
		title: string;

		// to_entity is null for users.
		to_entity?: string;
	};
}

interface IUseMentionArgs<T>
	extends Omit<IUseListQueryHookArgs<SearchResult, T>, 'filters'> {
	searchTerm?: string;
	filters?: Filter;
}

// useMention optimizes mentions by always showing fetched users first before doing a full search
// Fetched users are usually cached through reactQuery from other parts of the app so it's usually instant.
export function useMention<TData = IApiListResponse<SearchResult>>({
	searchTerm = '',
	filters,
	options,
}: IUseMentionArgs<TData>) {
	const filterParams = {
		search_term: searchTerm,
		filters: filters,
	};
	const queryKey = mentionQueryKeyFactory.list(1, filterParams);

	const queryFn = async (): Promise<IApiListResponse<SearchResult>> => {
		const { data } = await axios.get(
			`${api()}mention/?${convertObjectToUrlSearchParams(filterParams).toString()}`
		);
		return data;
	};

	return useBaseModelList<SearchResult, TData, Error>({
		queryKey,
		queryFn,
		namespace: MENTION_NAMESPACE,
		page: 1,
		options,
	});
}
