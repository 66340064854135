import { Image } from '@mantine/core';
import { space } from '@repo/theme/primitives';
import { forwardRef } from 'react';
import { AIAnimatedIllustration } from './AIAnimatedIllustration';
import type { IllustrationNames } from './Illustration.constants';

const IllustrationImageMap: Record<IllustrationNames, string> = {
	ai: '/images/illustrations/ai.webp', // filler, but we are using the animated version in AIAnimatedIllustration
	catalog: '/images/illustrations/catalog.webp',
	collections: '/images/illustrations/collections.webp',
	metrics: '/images/illustrations/metrics.webp',
	dictionary: '/images/illustrations/dictionary.webp',
	glossary: '/images/illustrations/dictionary.webp',
	documents: '/images/illustrations/documents.webp',
	questions: '/images/illustrations/questions.webp',
	integrations: '/images/illustrations/integrations.webp',
	monitors: '/images/illustrations/monitors.webp',
	automations: '/images/illustrations/automations.webp',
	forms: '/images/illustrations/forms.webp',
	analytics: '/images/illustrations/analytics.webp',
	reports: '/images/illustrations/reports.webp',
	upgrade: '/images/illustrations/upgrade.webp',
	policies: '/images/illustrations/policies.webp',
};

export interface IllustrationProps {
	/** Unique name of the icon. */
	name: IllustrationNames;
}

export const Illustration = forwardRef<HTMLDivElement, IllustrationProps>(
	({ name }, ref) => (
		<div ref={ref}>
			{name === 'ai' ? (
				<AIAnimatedIllustration />
			) : (
				<Image src={IllustrationImageMap[name]} width={space[40]} />
			)}
		</div>
	)
);

Illustration.displayName = 'Icon';
