import { useMantineTheme } from '@mantine/core';
import { useApiGetEntityCounts } from '@repo/api-codegen';
import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { EntityType } from '@repo/common/enums/entityType';
import { Icon, Text } from '@repo/foundations';
import { useMount } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import type { IQuery, ISecodaEntity } from '../../api';
import {
	useAuthUser,
	usePolicyAccess,
	useSecodaEntity,
	useUpdateSecodaEntity,
} from '../../api';
import useSuspenseSecodaEntity from '../../api/hooks/secodaEntity/useSuspenseSecodaEntity';
import Documentation from '../../components/Documentation';
import EntityDiscussions from '../../components/EntityDiscussions';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';
import EntityPageLayoutSkeleton from '../../components/EntityPageLayout/EntityPageLayoutSkeleton';
import { StaticProperty } from '../../components/EntityPageLayout/EntityPropertySidebar';
import EntityPolicyList from '../../components/EntityPolicyList/EntityPolicyList';
import { LineageGraphWrapper } from '../../components/LineageGraph/LineageGraph';
import OverviewPageTabs from '../../components/Overview/OverviewPageTabs';
import { QueryList } from '../../components/QueryListV2/QueryList';
import { trackEvent } from '../../utils/analytics';
import { useSilentUrlUpdate } from '../../utils/hook/useSilentUrlUpdate';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import Tests from '../TablePage/Tests';
import type { DjangoValueType } from '../TemplatePage/types';

function ReadOnlyPublishField({
	columnParent,
}: {
	columnParent: ISecodaEntity | undefined;
}) {
	const theme = useMantineTheme();
	const isPublished = columnParent?.published || false;

	const statusOption: SelectablePropertyItem = {
		label: isPublished ? 'Published' : 'Draft',
		value: isPublished,
		icon: <Icon name={isPublished ? 'circleCheckFilled' : 'circle'} />,
		iconColor: theme.other.getColor('icon/success/default'),
		fill: 'surface/primary/disabled',
	};

	return (
		<RichTooltip
			title="Read-only"
			body={
				<Text size="sm">
					Column status is automatically set based on the parent table&apos;s
					status.
				</Text>
			}
			position="left"
		>
			<StaticProperty type="badge" label="Status" value={statusOption} />
		</RichTooltip>
	);
}

const InnerColumnEntityPage = observer(({ id }: { id: string }) => {
	const { user, workspace } = useAuthUser();
	const hasPolicyAccess = usePolicyAccess();
	const { currentTab, setCurrentTab } = useSilentUrlUpdate('lineage');

	const { data: columnEntity } = useSuspenseSecodaEntity({
		id,
	});

	useMount(() => {
		trackEvent('column_entity/open', {}, user, workspace);
	});

	const { mutateAsync } = useUpdateSecodaEntity({});

	const updateColumnEntity = useCallback(
		async (key: string, value: DjangoValueType, saveRemotely = true) => {
			if (saveRemotely) {
				await mutateAsync({
					data: {
						id: columnEntity!.id,
						[key]: value,
					},
				});
				trackEvent('column/properties/update', {}, user, workspace!);
			}
		},
		[columnEntity, mutateAsync, user, workspace]
	);

	const { data: columnParent } = useSecodaEntity({
		id: columnEntity?.parent as string,
		options: {
			enabled: !!columnEntity && !!columnEntity.parent,
		},
	});

	const { data: entityCounts, isLoading } = useApiGetEntityCounts(
		{
			queryParams: {
				entity_id: columnEntity?.id ?? '',
			},
		},
		{
			enabled: !!columnEntity?.id,
		}
	);

	if (!columnEntity) {
		return <EntityPageLayoutSkeleton />;
	}

	const handleTabChange = useCallback(
		(updatedTab: string) => {
			if (updatedTab === 'lineage') {
				trackEvent('column/lineage/open', {}, user, workspace);
			}
			setCurrentTab(updatedTab);
		},
		[setCurrentTab, user, workspace]
	);

	const tabs = useMemo(
		() => [
			{
				value: 'lineage',
				label: 'Lineage',
				component: (
					<LineageGraphWrapper
						id={columnEntity.id}
						entityType={columnEntity.entity_type}
						nativeType={columnEntity.native_type ?? ''}
						published={columnEntity.published}
					/>
				),
			},
			{
				value: 'queries',
				label: 'Queries',
				condition: !isLoading && Boolean(entityCounts?.query_count),
				component: (
					<QueryList
						entity={
							columnEntity as unknown as ISecodaEntity & {
								creation_query?: IQuery;
							}
						}
					/>
				),
			},
			{
				value: 'tests',
				label: 'Tests',
				disabled: isLoading,
				condition: !isLoading && Boolean(entityCounts?.test_count),
				component: <Tests entity={columnEntity} />,
			},
			{
				value: 'discussions',
				label: 'Questions',
				component: <EntityDiscussions entity={columnEntity} />,
			},
			{
				value: 'documentation',
				label: 'Documentation',
				component: <Documentation entity={columnEntity} />,
			},
		],
		[
			columnEntity,
			isLoading,
			entityCounts?.query_count,
			entityCounts?.test_count,
		]
	);

	if (hasPolicyAccess) {
		tabs.push({
			value: 'policies',
			label: 'Policies',
			component: <EntityPolicyList entity={columnEntity} />,
		});
	}

	return (
		<EntityPageLayout
			key={columnEntity.id}
			name="Column"
			isReadOnlyTitle
			entity={columnEntity}
			icon={<EntityLogo entity={columnEntity} size={32} />}
			updateEntity={updateColumnEntity}
			withDescription
			withEntityByteSize
			withEntityRowCount
			withCustomPropertyEditors
			withCustomPublishElement={
				<ReadOnlyPublishField columnParent={columnParent} />
			}
			{...getEntityPageSidebarProps(EntityType.column)}
		>
			<OverviewPageTabs
				key={columnEntity.id}
				entity={columnEntity}
				currentTab={currentTab || 'lineage'}
				tabs={tabs}
				onTabChange={handleTabChange}
			/>
		</EntityPageLayout>
	);
});

// by memoizing the inner component, we can avoid re-rendering the entire page on tab changes and only re-render the tab panel
function ColumnEntityPage() {
	const id = useParamsIdSuffixUuid();

	return <InnerColumnEntityPage id={id} />;
}

export default ColumnEntityPage;
