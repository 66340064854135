import {
	useApiGetEntityCounts,
	useApiHasPreviewAccess,
} from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { useMount } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import {
	useAuthUser,
	useIntegration,
	useIntegrationSpec,
	useUpdateSecodaEntity,
} from '../../api';
import useSuspenseSecodaEntity from '../../api/hooks/secodaEntity/useSuspenseSecodaEntity';
import Documentation from '../../components/Documentation';
import EntityDiscussions from '../../components/EntityDiscussions';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';
import EntityPageLayoutSkeleton from '../../components/EntityPageLayout/EntityPageLayoutSkeleton';
import { LineageGraphWrapper } from '../../components/LineageGraph/LineageGraph';
import type { OverviewPageTab } from '../../components/Overview/OverviewPageTabs';
import OverviewPageTabs from '../../components/Overview/OverviewPageTabs';
import UrlPreview from '../../components/Preview/UrlPreview';
import { QueryList } from '../../components/QueryListV2/QueryList';
import { trackEvent } from '../../utils/analytics';
import { useSilentUrlUpdate } from '../../utils/hook/useSilentUrlUpdate';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import ChartsTab from './DashboardEntityTabs/ChartsTab';

const InnerDashboardPage = observer(({ id }: { id: string }) => {
	const { user, workspace } = useAuthUser();

	const { data: dashboard } = useSuspenseSecodaEntity({
		id,
	});

	useMount(() => {
		trackEvent('dashboard_entity/open', {}, user, workspace);
	});

	const { mutateAsync } = useUpdateSecodaEntity({});

	const updateDashboard = useCallback(
		async (key: string, value: DjangoValueType, saveRemotely = true) => {
			if (saveRemotely) {
				mutateAsync({
					data: {
						id: dashboard!.id,
						[key]: value,
					},
				});
				trackEvent('dashboard/properties/update', {}, user, workspace!);
			}
		},
		[mutateAsync, dashboard, user, workspace]
	);

	const { data: integration } = useIntegration({
		id: dashboard?.integration || '',
		options: {
			enabled: !!dashboard?.integration,
		},
	});

	const integrationSpec = useIntegrationSpec(dashboard?.integration ?? '');

	const { data: previewPermissionGranted } = useApiHasPreviewAccess(
		{
			pathParams: {
				integrationId: dashboard?.integration ?? '',
			},
		},
		{
			enabled: !!dashboard?.integration,
		}
	);

	// Hex dashboards are self-contained projects that don't have separate chart entities.
	// Snowflake charts are disabled for streamlit apps.
	// DBT charts are disabled for exposures.
	const showChartsTab =
		integration?.type !== 'hex' &&
		integration?.type !== 'snowflake' &&
		integration?.type !== 'dbt_core' &&
		integration?.type !== 'dbt';

	const showPreviewTab =
		integrationSpec &&
		integrationSpec.type === 'builtin' &&
		integrationSpec.value.previewSupport &&
		!!previewPermissionGranted;

	const documentationFirst = (dashboard?.definition?.length || 0) > 2;

	const getDefaultTab = useCallback(() => {
		if (showPreviewTab) {
			return 'preview';
		}
		if (documentationFirst) {
			return 'documentation';
		}
		return 'lineage';
	}, [showPreviewTab, documentationFirst]);

	const { currentTab, setCurrentTab } = useSilentUrlUpdate(getDefaultTab());

	const handleTabChange = useCallback(
		(updatedTab: string) => {
			if (updatedTab === 'lineage') {
				trackEvent('dashboard/lineage/open', {}, user, workspace);
			}
			setCurrentTab(updatedTab);
		},
		[setCurrentTab, user, workspace]
	);

	const { data: entityCounts, isLoading } = useApiGetEntityCounts(
		{
			queryParams: {
				entity_id: id || '',
			},
		},
		{
			enabled: !!id,
		}
	);

	if (!dashboard) {
		return <EntityPageLayoutSkeleton />;
	}

	const tabs: OverviewPageTab[] = useMemo(
		() => [
			...(showPreviewTab
				? [
						{
							value: 'preview',
							label: 'Preview',
							component: (
								<UrlPreview
									integration={integration?.type as string}
									entity={dashboard}
								/>
							),
						},
					]
				: []),
			{
				value: 'lineage',
				label: 'Lineage',
				component: (
					<LineageGraphWrapper
						id={dashboard.id}
						entityType={dashboard.entity_type}
						nativeType={dashboard.native_type ?? ''}
						published={dashboard.published}
					/>
				),
			},
			...(showChartsTab
				? [
						{
							value: 'charts',
							label: 'Charts',
							component: <ChartsTab id={dashboard.id} />,
						},
					]
				: []),
			{
				value: 'queries',
				label: 'Queries',
				condition: !isLoading && Boolean(entityCounts?.query_count),
				component: <QueryList entity={dashboard} />,
			},
			{
				value: 'discussions',
				label: 'Questions',
				component: <EntityDiscussions entity={dashboard} />,
			},
			{
				value: 'documentation',
				label: 'Documentation',
				component: <Documentation entity={dashboard} />,
			},
		],
		[
			showPreviewTab,
			integration?.type,
			dashboard,
			showChartsTab,
			isLoading,
			entityCounts?.query_count,
		]
	);

	// Move documentation tab to the front if it should be first
	if (documentationFirst) {
		const docTabIndex = tabs.findIndex((t) => t.value === 'documentation');
		if (docTabIndex !== -1) {
			const [docTab] = tabs.splice(docTabIndex, 1);
			tabs.unshift(docTab);
		}
	}

	return (
		<EntityPageLayout
			key={dashboard.id}
			name="Dashboard"
			isReadOnlyTitle
			entity={dashboard}
			icon={<EntityLogo entity={dashboard} size={32} />}
			updateEntity={updateDashboard}
			withDescription
			{...getEntityPageSidebarProps(EntityType.dashboard)}
		>
			<OverviewPageTabs
				key={dashboard.id}
				entity={dashboard}
				currentTab={currentTab || getDefaultTab()}
				tabs={tabs}
				onTabChange={handleTabChange}
			/>
		</EntityPageLayout>
	);
});

// By memoizing the inner component, we can avoid re-rendering the entire page on tab changes and only re-render the tab panel
export default function DashboardPage() {
	const id = useParamsIdSuffixUuid();

	return <InnerDashboardPage id={id} />;
}
