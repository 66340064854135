import type {
	Dispatch,
	EditorDictionary,
	SecodaEditorComponentProps,
} from '@repo/secoda-editor';
import { ToastType } from '@repo/secoda-editor';
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import copy from 'copy-to-clipboard';
import type { ParseSpec } from 'prosemirror-markdown';
import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';
import { NodeSelection, type EditorState } from 'prosemirror-state';
import { memo, useCallback } from 'react';
import { v4 } from '../../../../../utils/uuid/v4';
import { BlockSkeleton } from '../components/BlockSkeleton';
import type { CreateGraphModalResult } from '../components/ChartBlock/CreateGraphModal';
import { QueryBlockContainer } from '../components/MetricContainer/QueryBlockContainer';
import queryBlocksRule from '../rules/queryblocks';
import type { NodeOptions } from './Node';
import ReactNode from './ReactNode';

export default class QueryBlock extends ReactNode {
	get name() {
		return 'query_block';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				id: {
					default: '', // This value is shared across all new nodes, so the `uuid()` call cannot be here.
				},
				integrationId: {
					default: '',
				},
			},
			group: 'block',
			defining: true,
			atom: true,
			parseDOM: [
				{
					preserveWhitespace: 'full',
					tag: 'div.queryblock',
					getAttrs: (dom: HTMLElement | string) => ({
						id:
							typeof dom === 'string' ? v4() : (dom.getAttribute('id') ?? v4()),
					}),
				},
			],
			toDOM: (node) => [
				'div',
				{
					...node.attrs,
					class: 'queryblock',
					id: node.attrs.id,
				},
				node.attrs.id,
			],
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		const { id } = node.attrs;
		if (id) {
			state.write(`\n\n;;;${id};;;\n\n`);
			state.closeBlock(node);
		}
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'query_block',
			getAttrs: (tok) => ({ id: tok.attrs?.[0][1].split(';;;')[1] }),
		};
	}

	get rulePlugins() {
		return [queryBlocksRule];
	}

	commands({ type }: NodeOptions) {
		return (attrs?: Record<string, unknown>) =>
			(state: EditorState, dispatch: Dispatch) => {
				let id = attrs?.id;
				if (!id) {
					id = v4();
				}

				dispatch(
					state.tr.insert(
						state.selection.from,
						type.create({ ...(attrs ?? {}), id })
					)
				);

				return true;
			};
	}

	component = (props: SecodaEditorComponentProps) => (
		<QueryBlockContainerWrapper
			{...props}
			readOnly={this.editorState.readOnly}
			onShowToast={this.options.onShowToast}
			dictionary={this.options.dictionary}
			onTrackEvent={this.editor?.options?.onTrackEvent}
		/>
	);
}

const QueryBlockContainerWrapper = memo(
	({
		node,
		getPos,
		isSelected,
		view,
		readOnly,
		onShowToast,
		dictionary,
		onTrackEvent,
	}: SecodaEditorComponentProps & {
		readOnly: boolean;
		onShowToast: (message: string, type: ToastType) => void;
		dictionary: EditorDictionary;
		onTrackEvent?: (
			eventName: string,
			properties?: Record<string, string>
		) => void;
	}) => {
		const deleteNode = useCallback(() => {
			const $pos = view.state.doc.resolve(getPos());
			const tr = view.state.tr.setSelection(new NodeSelection($pos));
			view.dispatch(tr.deleteSelection());
			view.focus();
		}, [getPos, view]);

		const onDuplicate = useCallback(
			(newMetricId: string) => {
				const emptyParagraph = view.state.schema.nodes['paragraph'].create();
				const pos = getPos();

				view.dispatch(
					view.state.tr
						.insert(
							pos + node.nodeSize, // after the current query block
							emptyParagraph
						)
						.insert(
							pos + node.nodeSize + emptyParagraph.nodeSize, // after the empty paragraph created above
							node.type.create({ id: newMetricId })
						)
				);
				view.focus();
				onShowToast(dictionary.queryDuplicated, ToastType.Info);
			},
			[getPos, node.nodeSize, node.type, view, onShowToast, dictionary]
		);

		const copyLink = useCallback(() => {
			const hash = `#metric-${node.attrs.id}`;

			onTrackEvent?.('editor/query-block-copy-link');

			// The existing url might contain a hash already, lets make sure to remove
			// that rather than appending another one.
			const urlWithoutHash = window.location.href.split('#')[0];
			copy(urlWithoutHash + hash);
			onShowToast(dictionary.linkCopied, ToastType.Info);
		}, [node.attrs.id, onShowToast, dictionary, onTrackEvent]);

		const onAddGraph = useCallback(
			(result: CreateGraphModalResult) => {
				const { xAxis, yAxis, dimension, numericFormat } = result;

				const emptyParagraph = view.state.schema.nodes['paragraph'].create();
				const chartBlock = view.state.schema.nodes['chart_block'].create({
					id: node.attrs.id,
					xAxis,
					yAxis,
					dimension,
					numericFormat,
				});
				const pos = getPos();

				view.dispatch(
					view.state.tr
						.insert(
							pos + node.nodeSize, // after the current query block
							emptyParagraph
						)
						.insert(
							pos + node.nodeSize + emptyParagraph.nodeSize, // after the empty paragraph created above
							chartBlock
						)
				);
				view.focus();
			},
			[view, node.attrs.id, node.nodeSize, getPos]
		);

		if (!node) {
			return <BlockSkeleton isSelected={isSelected} height={100} />;
		}

		return (
			<QueryBlockContainer
				id={node.attrs.id}
				integrationId={node.attrs.integrationId ?? undefined}
				readOnly={readOnly}
				onDelete={deleteNode}
				isSelected={isSelected}
				onDuplicate={onDuplicate}
				onCopyLink={copyLink}
				onAddGraph={onAddGraph}
			/>
		);
	}
);
