import type { INotification } from '../../../../../api/types/models/notification';
import { DataAccessRequestControls } from '../../../../../components/DataAccessRequest/DataAccessRequestControls/DataAccessRequestControls';

interface DataAccessRequestProps {
	notification: INotification;
}

export function DataAccessRequest({ notification }: DataAccessRequestProps) {
	const canShowButtons = ['data_access_request_created'].includes(
		notification.event
	);

	if (!Boolean(notification.resource_id) || !canShowButtons) {
		return null;
	}

	return (
		<DataAccessRequestControls requestId={notification.resource_id as string} />
	);
}
