import { Group, UnstyledButton } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { forwardRef } from 'react';

export const AccessSummaryButton = forwardRef<
	HTMLButtonElement,
	{ faceValue: string; disabled?: boolean }
>(({ faceValue, disabled = false, ...props }, ref) => (
	<UnstyledButton
		ref={ref}
		{...props}
		sx={(theme) => ({
			padding: '4px 8px',
			borderRadius: theme.radius.sm,
			border: `1px solid ${theme.other.getColor('border/primary/default')}`,
			backgroundColor: disabled
				? theme.other.getColor('fill/primary/disabled')
				: theme.other.getColor('surface/input/default'),
		})}
	>
		<Group position="apart" noWrap spacing={0}>
			<Text
				size="sm"
				color="text/primary/default"
				sx={{ whiteSpace: 'nowrap' }}
			>
				{faceValue}
			</Text>
			<Icon size="md" name="selector" />
		</Group>
	</UnstyledButton>
));
