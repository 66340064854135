import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useApiGetCreationQuery } from '@repo/api-codegen';
import { IconButton } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import type { ISecodaEntity } from '../../api';
import { useFmtSql } from '../../api/hooks/query/useFormatterQuery';
import { QueryCode } from '../QueryCodeBlock/QueryCode';

export interface IEntityCreationQueryProps {
	entity: ISecodaEntity;
}

function EntityCreationQuery({ entity }: IEntityCreationQueryProps) {
	const { data } = useApiGetCreationQuery(
		{
			pathParams: {
				entityId: entity.id,
			},
		},
		{
			enabled: !!entity.id,
		}
	);

	const sql = useFmtSql(data?.sql ?? '');
	const [opened, { close, toggle }] = useDisclosure(false);

	if (!data?.sql) {
		return null;
	}

	return (
		<>
			<IconButton
				tooltip="Creation query"
				onClick={toggle}
				iconName="code"
				variant="tertiary"
			/>
			<Modal
				size="xl"
				opened={opened}
				onClose={close}
				title={`Creation query - ${entity.title}`}
			>
				<QueryCode minHeight={724} query={sql ?? ''} />
			</Modal>
		</>
	);
}

export default observer(EntityCreationQuery);
