import type {
	ClassNames,
	TitleProps as MantineTitleProps,
	TitleOrder,
} from '@mantine/core';
// eslint-disable-next-line no-restricted-imports
import { Title as MantineTitle, createStyles } from '@mantine/core';
import { typography } from '@repo/theme/primitives';
import type { ColorNames } from '@repo/theme/utils';
import { TITLE_ORDER_TO_SIZE_MAP } from './constants';

type TitleStylesNames = 'root';

interface TitleStylesParams {
	size?: keyof typeof typography.title;
	weight: keyof typeof typography.weight;
	color: ColorNames;
	order?: TitleOrder;
}

interface SecodaTitleProps {
	children?: React.ReactNode;
	size?: keyof typeof typography.title;
	weight?: keyof typeof typography.weight;
	color?: ColorNames;
	classNames?: ClassNames<TitleStylesNames>;
}

// Define sizes that are larger than 'lg' for application of InterDisplay
const LARGE_SIZES: Array<string> = [
	'xl',
	'xxl',
	'3xl',
	'4xl',
	'7xl',
	'1.25rem',
	'1.5rem',
	'1.75rem',
	'2rem',
	'2.5rem',
	'3rem',
	'4rem',
	'5rem',
];

export type TitleProps = SecodaTitleProps &
	Omit<MantineTitleProps, keyof SecodaTitleProps | 'lh' | 'weight'>;

const useStyles = createStyles(
	(theme, { size, order, weight, color }: TitleStylesParams) => {
		const effectiveSize = size
			? typography.title[size]
			: typography.title[TITLE_ORDER_TO_SIZE_MAP[order as any]];
		const isLargeSize = LARGE_SIZES.includes(effectiveSize);
		const baseFontStack =
			"Inter,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif";
		const fontFamily = isLargeSize
			? `InterDisplay,InterVariable,${baseFontStack}`
			: `InterVariable,${baseFontStack}`;

		return {
			root: {
				fontFamily,
				fontSize: size
					? typography.title[size]
					: typography.title[TITLE_ORDER_TO_SIZE_MAP[order as any]],
				lineHeight: size
					? typography.lineHeight.title[size]
					: typography.lineHeight.title[TITLE_ORDER_TO_SIZE_MAP[order as any]],
				fontWeight: typography.weight[weight],
				color: color
					? theme.other.getColor(color)
					: theme.other.getColor('text/primary/default'),
			},
		};
	}
);

function Title({
	children,
	order,
	size,
	weight = 'bold',
	color = 'text/primary/default',
	className,
	classNames: classNamesProp,
	...others
}: TitleProps) {
	const { classes, cx } = useStyles({
		size,
		weight,
		color,
	});

	return (
		<MantineTitle
			order={order}
			size={size}
			className={cx(classes.root, classNamesProp?.root, className)}
			{...others}
		>
			{children}
		</MantineTitle>
	);
}

export { Title };
