import { Box, createStyles, Stack } from '@mantine/core';
import { Text } from '@repo/foundations';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import type { ILineage, Incident } from '../../../api';
import { useLineageList } from '../../../api/hooks/lineage/useLineage';
import { TableV2 } from '../../../components/TableV2';
import type { ExtendedDataTableColumn } from '../../../components/TableV2/types';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { useActions, useColumns } from './DownstreamResourcesTable.hooks';

export interface IDownstreamResourcesTableProps {
	incident: Incident;
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		height: '100%',
		flexGrow: 1,
	},
	title: {
		fontWeight: theme.other.typography.weight.bold,
	},
}));

const QUICK_ACTIONS = ['actions::notify'] as const;

function DownstreamResourcesTable({
	incident,
}: IDownstreamResourcesTableProps) {
	const { monitoringV2 } = useFeatureFlags();
	const { classes } = useStyles();
	const navigate = useNavigate();

	const columns = useColumns();
	const actions = useActions(incident);

	const handleClick = useCallback(
		(id: string) => {
			navigate(`/resource/${id}`);
		},
		[navigate]
	);

	const onCellClick = useCallback(
		(cell: {
			column: ExtendedDataTableColumn<ILineage>;
			record: { id: string };
		}) => {
			if (cell.column.navigate !== false) {
				handleClick(cell.record.id);
			}
		},
		[handleClick]
	);

	return (
		<Stack className={classes.wrapper}>
			{!monitoringV2 && (
				<Text className={classes.title}>Downstream Resources</Text>
			)}
			<Box>
				<TableV2<ILineage>
					pluralTypeString="resources"
					withCheckbox={true}
					withInteractiveHeader
					withSearch
					defaultSort={null}
					withCsvExport
					columns={columns}
					usePaginationList={useLineageList}
					defaultRequiredSearchParams={{
						id: incident.target,
						depth: 3,
						direction: 'DOWNSTREAM',
					}}
					onCellClick={onCellClick}
					withActions={actions}
					withQuickActions={QUICK_ACTIONS}
				/>
			</Box>
		</Stack>
	);
}
export default memo(DownstreamResourcesTable);
