import type {
	ClassNames,
	TextareaProps as MantineTextAreaProps,
} from '@mantine/core';
import {
	createStyles,
	Textarea as MantineTextArea,
	Stack,
} from '@mantine/core';
import { useId } from '@mantine/hooks';
import type { ColorNames } from '@repo/theme/utils';
import { isEmpty } from 'lodash-es';
import { forwardRef, useCallback, useMemo } from 'react';
import { TextInputError } from '../TextInput/TextInputError';
import { TextInputHelp } from '../TextInput/TextInputHelp';
import { TextInputLabel } from '../TextInput/TextInputLabel';

interface TextAreaStylesParams {
	error?: string;
}

const useStyles = createStyles((theme, { error }: TextAreaStylesParams) => {
	let backgroundColor: ColorNames = 'surface/input/default';
	let hoverBackgroundColor: ColorNames = 'surface/input/default';
	let focusBackgroundColor: ColorNames = 'surface/input/default';

	let borderColor: ColorNames = 'border/input/default';
	let hoverBorderColor: ColorNames = 'border/input/hover';
	let focusBorderColor: ColorNames = 'border/input/active';

	const boxShadow = `0px 0px 0px 1px white, 0px 0px 0px 3px ${theme.other.getColor('border/emphasis/default')}`;

	if (!isEmpty(error)) {
		backgroundColor = 'surface/critical/default';
		hoverBackgroundColor = 'surface/critical/default';
		focusBackgroundColor = 'surface/critical/default';
		borderColor = 'border/critical-secondary/default';
		hoverBorderColor = 'border/critical-secondary/default';
		focusBorderColor = 'border/critical-secondary/default';
	}

	return {
		root: {
			width: '100%',
		},
		input: {
			backgroundColor: theme.other.getColor(backgroundColor),
			borderRadius: theme.radius.sm,
			borderWidth: 0.5,
			borderStyle: 'solid',
			borderColor: theme.other.getColor(borderColor),
			'&:hover': {
				backgroundColor: theme.other.getColor(hoverBackgroundColor),
				borderWidth: 0.5,
				borderColor: theme.other.getColor(hoverBorderColor),
			},
			'&:focus, &:active': {
				backgroundColor: theme.other.getColor(focusBackgroundColor),
				borderWidth: 0.5,
				borderColor: theme.other.getColor(focusBorderColor),
				boxShadow,
			},
			'&:disabled': {
				backgroundColor: theme.other.getColor('surface/primary/disabled'),
				borderWidth: 0,
			},
		},
		optional: { color: theme.other.getColor('text/secondary/default') },
		required: { color: theme.other.getColor('text/critical/default') },
	};
});

type TextAreaStylesNames = 'root' | 'input' | 'optional' | 'required';

type TextAreaProps = {
	id?: string;
	label?: string;
	help?: string;
	error?: string;
	optional?: boolean;
	classNames?: ClassNames<TextAreaStylesNames>;
} & Omit<MantineTextAreaProps, 'classNames'>;

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
	(
		{
			id,
			label,
			name,
			help,
			error,
			optional = false,
			disabled = false,
			onChange,
			classNames: classNamesProp,
			...others
		},
		ref
	) => {
		const uuid = useId(id);
		const { classes, cx, theme } = useStyles({
			error,
		});

		const classNames = useMemo(
			() => ({
				root: cx(classes.root, classNamesProp?.root),
				input: cx(classes.input, classNamesProp?.input),
				optional: cx(classes.optional, classNamesProp?.optional),
				required: cx(classes.required, classNamesProp?.required),
			}),
			[classes, cx, classNamesProp]
		);

		const handleOnChange = useCallback(
			(event: React.ChangeEvent<HTMLTextAreaElement>) => {
				if (disabled) {
					return;
				}

				onChange?.(event);
			},
			[disabled, onChange]
		);

		return (
			<Stack spacing={theme.spacing['3xs']}>
				<TextInputLabel label={label} optional={optional} inputId={uuid} />
				<MantineTextArea
					id={uuid}
					ref={ref}
					classNames={classNames}
					name={name}
					onChange={handleOnChange}
					disabled={disabled}
					{...others}
				/>
				<TextInputHelp help={help} error={error} />
				<TextInputError error={error} />
			</Stack>
		);
	}
);

TextArea.displayName = 'TextArea';

export { TextArea };
