import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { flatMapDeep, isNil } from 'lodash-es';
import { apiClient, getEndpoints } from '../../common';
import type { IApiListResponse, InfiniteQueryFnParams } from '../../types';
import type { IActivity } from '../../types/models/notification';
import {
	resourceActivityQueryKeyFactory,
	userActivityQueryKeyFactory,
	workspaceActivityQueryKeyFactory,
} from './constants';

type IResourceActivityAPIResponse = IApiListResponse<IActivity>;

export function useResourceActivity(resourceId: string | undefined) {
	const queryKey = resourceActivityQueryKeyFactory.byArgs(resourceId ?? '');
	const queryFn = async () => {
		const url = `${getEndpoints(
			resourceActivityQueryKeyFactory.namespace
		).root()}?resource_id=${resourceId}`;
		const { data: activity } =
			await apiClient.get<IResourceActivityAPIResponse>(url);
		return activity.results;
	};

	return useQuery({
		queryKey,
		queryFn,
		enabled: !!resourceId,
	});
}

export function useUserActivity(userId: string | undefined) {
	const queryKey = userActivityQueryKeyFactory.byArgs(userId ?? '');
	const queryFn = async () => {
		const url = `${getEndpoints(
			userActivityQueryKeyFactory.namespace
		).root()}?user_id=${userId}&distinct=dispatch_id`;
		const { data: activity } =
			await apiClient.get<IResourceActivityAPIResponse>(url);
		return activity.results;
	};

	return useQuery({
		queryKey,
		queryFn,
		enabled: !!userId,
	});
}

export function useWorkspaceActivity() {
	const queryKey = workspaceActivityQueryKeyFactory.allLists();
	const queryFn = async ({ pageParam }: InfiniteQueryFnParams) => {
		const url = getEndpoints(workspaceActivityQueryKeyFactory.namespace).root();
		const { data: activity } =
			await apiClient.get<IResourceActivityAPIResponse>(url, {
				params: pageParam,
			});
		return activity;
	};

	return useInfiniteQuery(queryKey, queryFn, {
		keepPreviousData: true,
		getPreviousPageParam: (firstPage) =>
			isNil(firstPage.meta.previous_page)
				? null
				: { page: firstPage.meta.previous_page },
		getNextPageParam: (lastPage) =>
			isNil(lastPage.meta.next_page) ? null : { page: lastPage.meta.next_page },
		select: ({ pages, pageParams }) => ({
			pages: flatMapDeep(pages, (page) => page.results),
			pageParams,
		}),
	});
}
