export const typography = {
	weight: {
		regular: 400,
		semibold: 500,
		bold: 600,
	},
	title: {
		'7xl': '5rem', // 80px
		'4xl': '2.5rem', // 40px
		'3xl': '2rem', // 32px
		xxl: '1.75rem', // 28px
		xl: '1.5rem', // 24px
		lg: '1.25rem', // 20px
		md: '0.9375rem', // 15px
		sm: '0.8125rem', // 13px
		xs: '0.75rem', // 12px
		xxs: '0.5rem', // 8px
	},
	text: {
		'7xl': '5rem', // 80px
		'4xl': '2.5rem', // 40px
		'3xl': '2rem', // 32px
		xxl: '1.75rem', // 28px
		xl: '1.5rem', // 24px
		lg: '1.25rem', // 20px
		md: '0.9375rem', // 15px
		sm: '0.8125rem', // 13px
		xs: '0.75rem', // 12px
		xxs: '0.5rem', // 8px
	},
	lineHeight: {
		title: {
			'7xl': '5.25rem', // 84px
			'4xl': '2.75rem', // 44px
			'3xl': '2.25rem', // 36px
			xxl: '2rem', // 32px
			xl: '1.75rem', // 28px
			lg: '1.5rem', // 24px
			md: '1.5rem', // 24px
			sm: '1.25rem', // 20px
			xs: '1rem', // 16px
			xxs: '0.8rem', // 12.8px
		},
		text: {
			'7xl': '5.25rem', // 84px
			'4xl': '2.75rem', // 44px
			'3xl': '2.25rem', // 36px
			xxl: '2rem', // 32px
			xl: '1.75rem', // 28px
			lg: '1.5rem', // 24px
			md: '1.5rem', // 24px
			sm: '1.25rem', // 20px
			xs: '1rem', // 16px
			xxs: '0.8rem', // 12.8px
		},
	},
} as const;
