import type { FilterItem, FilterValue, FilterValueType } from './types';
import {
	FilterDropdownType,
	FilterOperator,
	FilterOptionType,
	SortValue,
} from './types';

export interface FilterOperatorConfig {
	getOperators: (value: FilterValue) => FilterOperator[];
	getLabel: (
		operator: FilterOperator,
		value: FilterValueType | FilterValueType[] | null
	) => string;
}

export const FILTER_OPTIONS_DIVIDER = 'divider' as const;

export const IS_NOT_SET_FILTER_ITEM: FilterItem = {
	label: 'Is not set',
	value: 'not_set',
};

export const IS_NOT_SET_FILTER_LABEL: FilterItem = {
	label: '(not set)',
	value: 'not_set',
};

export const IS_SET_FILTER_ITEM: FilterItem = {
	label: 'Is set',
	value: 'is_set',
};

export const IS_SET_FILTER_LABEL: FilterItem = {
	label: '(is set)',
	value: 'is_set',
};

export const DEFAULT_FILTER_OPTIONS: (
	| FilterOptionType
	| typeof FILTER_OPTIONS_DIVIDER
)[] = [
	FilterOptionType.NATIVE_TYPE,
	FilterOptionType.INTEGRATION,
	FilterOptionType.RELATED,
	FILTER_OPTIONS_DIVIDER,
	FilterOptionType.TITLE,
	FilterOptionType.DESCRIPTION,
	FilterOptionType.TAGS,
	FilterOptionType.QUESTION_STATUS,
	FilterOptionType.QUESTION_PRIORITY,
	FilterOptionType.PUBLISHED,
	FilterOptionType.COLLECTIONS,
	FilterOptionType.OWNERS,
	FilterOptionType.PII,
	FilterOptionType.VERIFICATION,
	FilterOptionType.TEAMS,
	FILTER_OPTIONS_DIVIDER,
	FilterOptionType.CREATED_TIME,
	FilterOptionType.UPDATED_TIME,
	FilterOptionType.EXTERNALLY_UPDATED_TIME,
	// not currently supported - no plan to add
	// FilterOptionType.FREQUENT_USERS,
	// FilterOptionType.SLACK_CHANNELS,
	FILTER_OPTIONS_DIVIDER,
	FilterOptionType.SOURCES,
	FilterOptionType.PARENT_ID,
	FilterOptionType.SCHEMA,
	FilterOptionType.DATABASE,
	FilterOptionType.TABLE,
	FILTER_OPTIONS_DIVIDER,
	FilterOptionType.IS_TESTED,
	FilterOptionType.TESTS_PASSING,
	FilterOptionType.IS_MONITORED,
	FilterOptionType.MONITORS_PASSING,
	FILTER_OPTIONS_DIVIDER,
	FilterOptionType.LINEAGE,
];

export const DEFAULT_FILTER_OPTIONS_WITH_DQS: (
	| FilterOptionType
	| typeof FILTER_OPTIONS_DIVIDER
)[] = [
	FilterOptionType.NATIVE_TYPE,
	FilterOptionType.INTEGRATION,
	FilterOptionType.RELATED,
	FILTER_OPTIONS_DIVIDER,
	FilterOptionType.TITLE,
	FilterOptionType.DESCRIPTION,
	FilterOptionType.TAGS,
	FilterOptionType.QUESTION_STATUS,
	FilterOptionType.QUESTION_PRIORITY,
	FilterOptionType.PUBLISHED,
	FilterOptionType.COLLECTIONS,
	FilterOptionType.OWNERS,
	FilterOptionType.PII,
	FilterOptionType.VERIFICATION,
	FilterOptionType.TEAMS,
	FilterOptionType.DATA_QUALITY,
	FILTER_OPTIONS_DIVIDER,
	FilterOptionType.CREATED_TIME,
	FilterOptionType.UPDATED_TIME,
	FilterOptionType.EXTERNALLY_UPDATED_TIME,
	// not currently supported - no plan to add
	// FilterOptionType.FREQUENT_USERS,
	// FilterOptionType.SLACK_CHANNELS,
	FILTER_OPTIONS_DIVIDER,
	FilterOptionType.SOURCES,
	FilterOptionType.PARENT_ID,
	FilterOptionType.SCHEMA,
	FilterOptionType.DATABASE,
	FilterOptionType.TABLE,
	FILTER_OPTIONS_DIVIDER,
	FilterOptionType.IS_TESTED,
	FilterOptionType.TESTS_PASSING,
	FilterOptionType.IS_MONITORED,
	FilterOptionType.MONITORS_PASSING,
	FILTER_OPTIONS_DIVIDER,
	FilterOptionType.LINEAGE,
];

export type SortOption = {
	label: string;
	value: SortValue;
};

export const SORT_OPTIONS: Record<SortValue, SortOption> = {
	[SortValue.RELEVANCE]: {
		label: 'Relevance',
		value: SortValue.RELEVANCE,
	},
	[SortValue.POPULARITY]: {
		label: 'Popularity',
		value: SortValue.POPULARITY,
	},
	[SortValue.SECODA_VIEWS]: {
		label: 'Secoda Views',
		value: SortValue.SECODA_VIEWS,
	},
	[SortValue.UPDATED_AT]: {
		label: 'Last modified',
		value: SortValue.UPDATED_AT,
	},
	[SortValue.CREATED_AT]: {
		label: 'Date created',
		value: SortValue.CREATED_AT,
	},
};

export const FILTER_OPERATOR_TO_NEGATED_OPERATOR: Partial<
	Record<FilterOperator, FilterOperator>
> = {
	[FilterOperator.Is]: FilterOperator.IsNot,
	[FilterOperator.Contains]: FilterOperator.DoesNotContain,
	[FilterOperator.isSet]: FilterOperator.isNotSet,
};

export const OPERATORS_CONFIG: Partial<
	Record<FilterDropdownType, FilterOperatorConfig>
> = {
	[FilterDropdownType.List]: {
		getOperators: () => [FilterOperator.Is, FilterOperator.IsNot],
		getLabel: (operator, value) => {
			if (operator === FilterOperator.Is) {
				return Array.isArray(value) && value.length > 1 ? 'is any of' : 'is';
			}

			return operator.toString();
		},
	},
	[FilterDropdownType.Date]: {
		getOperators: (value: FilterValue) =>
			Array.isArray(value.value)
				? [FilterOperator.IsBetween]
				: [FilterOperator.IsOnOrBefore, FilterOperator.IsOnOrAfter],
		getLabel: (operator) => operator.toString(),
	},
	[FilterDropdownType.String]: {
		getOperators: () => [
			FilterOperator.Is,
			FilterOperator.IsNot,
			FilterOperator.Contains,
			FilterOperator.DoesNotContain,
		],
		getLabel: (operator) => operator.toString(),
	},
};
