import { Box, Group, Stack } from '@mantine/core';
import { SettingsButton } from '@repo/common/components';
import { SettingsBlock } from '@repo/common/components/SettingsBlock';
import { ResourceIcon, Text } from '@repo/foundations';
import { useWorkspace } from '../../api';
import { useLimits } from '../../api/hooks/workspace/useLimits';
import { FREE_PLAN } from '../../data/plans';
import { usePlan } from '../../hooks/usePlans';

// Helper function to format seat count
const formatSeatCount = (seats: number) =>
	seats >= 10000 ? 'Unlimited' : seats;

export function BillingSettings() {
	const { workspace } = useWorkspace();
	const { daysLeftInTrial, trial, plan } = usePlan();
	const {
		editorOrAdminSeats,
		viewerSeats,
		integrationSeats,
		usedEditorOrAdminSeats,
		usedViewerSeats,
		usedIntegrationSeats,
	} = useLimits();
	const showPlanSelector = trial || plan?.id === FREE_PLAN.id;
	const showStripePortal =
		workspace.customer_id && workspace.customer_id.startsWith('cus_');

	let buttonText = undefined;
	if (showStripePortal) {
		buttonText = 'Manage plan';
	} else if (showPlanSelector) {
		buttonText = 'Choose plan';
	}

	return (
		<Stack spacing="md">
			<Box>
				<SettingsButton
					title={trial ? `Enterprise trial` : `${plan?.title} plan`}
					description={
						trial ? `${daysLeftInTrial} days remaining` : `Current plan`
					}
					leftComponent={<ResourceIcon iconName={plan?.icon ?? 'briefcase'} />}
					buttonText={buttonText}
					variant={showStripePortal ? 'default' : 'primary'}
					onClick={() => {
						if (showPlanSelector) {
							window.open('/plans', '_blank');
						} else if (showStripePortal) {
							window.open(
								`https://app.secoda.co/billing/telemetry/?customer_id=${workspace.customer_id}`,
								'_blank',
								'noopener,noreferrer'
							);
						}
					}}
				/>
			</Box>
			<Box>
				<SettingsBlock
					title="Editor seats"
					rightComponent={
						<Group spacing="xs">
							<Text size="sm" color="text/primary/default">
								{usedEditorOrAdminSeats}
							</Text>
							<Text size="sm" color="text/secondary/default">
								/
							</Text>
							<Text size="sm" color="text/primary/default">
								{formatSeatCount(editorOrAdminSeats)}
							</Text>
						</Group>
					}
				/>
				<SettingsBlock
					title="Viewer seats"
					rightComponent={
						<Group spacing="xs">
							<Text size="sm" color="text/primary/default">
								{usedViewerSeats}
							</Text>
							<Text size="sm" color="text/secondary/default">
								/
							</Text>
							<Text size="sm" color="text/primary/default">
								{formatSeatCount(viewerSeats)}
							</Text>
						</Group>
					}
				/>
				<SettingsBlock
					title="Integrations"
					rightComponent={
						<Group spacing="xs">
							<Text size="sm" color="text/primary/default">
								{usedIntegrationSeats}
							</Text>
							<Text size="sm" color="text/secondary/default">
								/
							</Text>
							<Text size="sm" color="text/primary/default">
								{formatSeatCount(integrationSeats)}
							</Text>
						</Group>
					}
				/>
			</Box>
		</Stack>
	);
}
