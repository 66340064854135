import { Menu, Tooltip } from '@mantine/core';
import { IconButton } from '@repo/foundations';
import type { DataTableColumn } from '@repo/mantine-datatable';
import { useCallback, useMemo } from 'react';
import { queryClient, useAuthUser, type Catalog } from '../../../api';
import { CUSTOM_PROPERTY_COLUMN_PREFIX } from '../../../constants';
import { trackEvent } from '../../../utils/analytics';
import type { ColumnName } from '../helpers';
import type { UseColumnDefsArgs } from '../hooks/useColumnDefs';

import { catalogQueryKeyFactory } from '../../../api/hooks/catalog/constants';
import { STICKY_COLUMNS } from '../../TableV2/constants';
import type { ExtendedDataTableColumn } from '../../TableV2/types';
import { useColumnDefs } from '../hooks/useColumnDefs';
import MenuList from './MenuList';

interface ICustomizeColumnsPanelProps<T>
	extends Pick<UseColumnDefsArgs<T>, 'catalogType' | 'catalogServerType'> {
	defaultColumns: DataTableColumn<T>[];
	referenceId?: null | string;
	onChangeVisibility?: (columnName: string, isVisible: boolean) => void;
	onChangeOrder?: (catalog: Catalog) => void;
}

function CustomizeColumnsPanel<T>({
	defaultColumns,
	catalogType,
	catalogServerType,
	referenceId = null,
	onChangeVisibility,
	onChangeOrder,
}: ICustomizeColumnsPanelProps<T>) {
	const { catalog, onColumnReorder, onColumnVisibilityChange } = useColumnDefs({
		defaultColumns,
		catalogServerType,
		catalogType,
		entityId: referenceId,
	});

	const { user, workspace } = useAuthUser();

	const handleColumnOrderChange = useCallback(
		async (columnName: string, toIndex: number) => {
			const reorderedCatalog = await onColumnReorder(columnName, toIndex + 1);

			if (reorderedCatalog) {
				onChangeOrder?.(reorderedCatalog);
				queryClient.invalidateQueries({
					queryKey: catalogQueryKeyFactory.all(),
				});
			}

			trackEvent('catalog/customize/reorder', {}, user, workspace);
		},
		[onChangeOrder, onColumnReorder, user, workspace]
	);

	const handleVisibilityChange = useCallback(
		async (columnName: ColumnName, isVisible: boolean) => {
			await onColumnVisibilityChange(columnName, isVisible);
			onChangeVisibility?.(columnName, isVisible);
			queryClient.invalidateQueries({
				queryKey: catalogQueryKeyFactory.all(),
			});

			trackEvent(
				'catalog/customize/toggle_column',
				{
					column: columnName,
				},
				user,
				workspace
			);
		},
		[onChangeVisibility, onColumnVisibilityChange, user, workspace]
	);

	const propertiesToDisplay = useMemo(
		() =>
			catalog?.properties
				.filter((p) => !STICKY_COLUMNS.includes(p.value))
				// No longer show (v1) (legacy) custom properties.
				.filter((p) => !p.value.startsWith(CUSTOM_PROPERTY_COLUMN_PREFIX))
				.filter((p) =>
					defaultColumns.find(
						(c) =>
							c.accessor === p.value ||
							(c as ExtendedDataTableColumn<T>).esAccessor === p.value
					)
				) ?? [],
		[catalog?.properties, defaultColumns]
	);

	return (
		<Menu width={250} position="bottom-end" withinPortal>
			<Menu.Target>
				<Tooltip label="Rearrange columns">
					<IconButton
						data-testid="customize-columns-button"
						iconName="adjustmentsHorizontal"
						variant="tertiary"
					/>
				</Tooltip>
			</Menu.Target>
			<Menu.Dropdown>
				<MenuList
					rowHeight={32}
					disableDragging={false}
					catalogProperties={propertiesToDisplay}
					catalogType={catalogType}
					onColumnOrderChange={handleColumnOrderChange}
					onVisibilityChange={handleVisibilityChange}
				/>
			</Menu.Dropdown>
		</Menu>
	);
}

export default CustomizeColumnsPanel;
