import { createStyles, Group } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { Text } from '@repo/foundations';
import { IconKey, IconReportSearch } from '@tabler/icons-react';
import type { MouseEvent } from 'react';
import { Handle, Position } from 'reactflow';
import { NODE_GAP, NODE_PADDING, NODE_ROW_HEIGHT } from '../../constants';
import { impactAnalysisStore } from '../../Modals/ImpactAnalysisModal/store';
import { lineageStore } from '../../store';
import type { EntityNodeChildren } from '../../types';
import { EdgeHandle } from '../../types';

interface IEntityChildProps {
	child: EntityNodeChildren;
	order: number;
}

interface IEntityChildStylesProps {
	isHighlighted: boolean;
	isFocused: boolean;
	order: number;
}

const useStyles = createStyles(
	(theme, { isHighlighted, isFocused, order }: IEntityChildStylesProps) => {
		let hoverBackgroundColor = theme.other.getColor('surface/primary/hover');

		if (isHighlighted) {
			hoverBackgroundColor = theme.other.getColor('surface/emphasis/default');
		}

		return {
			wrapper: {
				cursor: 'pointer',
				justifyContent: 'space-between',
				minHeight: NODE_ROW_HEIGHT,
				paddingLeft: theme.spacing.sm,
				paddingRight: theme.spacing.sm,
				borderRadius: theme.radius.sm,
				gap: theme.spacing.xs,
				backgroundColor: isHighlighted
					? theme.other.getColor('surface/emphasis/default')
					: theme.other.getColor('surface/primary/default'),
				borderColor: isFocused ? '#005BD3' : 'unset',
				borderStyle: isFocused ? 'solid' : 'unset',
				borderWidth: isFocused ? 2 : 'unset',
				'&:hover': {
					backgroundColor: hoverBackgroundColor,
				},
				'&:active': {
					backgroundColor: theme.other.getColor('surface/primary/active'),
				},
			},
			handle: {
				width: '0px !important',
				height: '0px !important',
				backgroundColor: 'transparent !important',
				borderColor: 'transparent !important',
				top: `${
					2 * NODE_GAP +
					NODE_PADDING +
					2 * NODE_ROW_HEIGHT +
					NODE_ROW_HEIGHT * (order - 0.5)
				}px !important`,
			},
			lineageIndicatorIcon: {
				stroke: theme.other.getColor('icon/secondary/default'),
				'&:hover': {
					stroke: theme.other.getColor('border/input/default'),
				},
				'&:active': {
					stroke: theme.other.getColor('border/input/active'),
				},
			},
		};
	}
);

export function EntityChild({ child, order }: IEntityChildProps) {
	const { hovered, ref } = useHover();

	const { id, node, title, metadata, isHighlighted, isFocused } = child;

	const showLineageIndicator = hovered;
	const showPrimaryKeyIndicator = metadata?.is_pk;

	const { classes, theme } = useStyles({
		isHighlighted,
		isFocused,
		order,
	});

	const handleFocusChild = () => {
		lineageStore.selectChild(id, node);
	};

	const handleImpactAnalysis = (event: MouseEvent<SVGSVGElement>) => {
		event.stopPropagation();

		impactAnalysisStore.setId(id);
		impactAnalysisStore.setOpened(true);
	};

	return (
		<Group ref={ref} className={classes.wrapper} onClick={handleFocusChild}>
			<Handle
				className={classes.handle}
				id={`${EdgeHandle.CHILD_TARGET}--${id}`}
				type="target"
				position={Position.Left}
				isConnectable={false}
			/>
			<Handle
				className={classes.handle}
				id={`${EdgeHandle.CHILD_SOURCE}--${id}`}
				type="source"
				position={Position.Right}
				isConnectable={false}
			/>
			<Text size="sm">{title}</Text>
			<Group spacing="3xs" noWrap>
				{showLineageIndicator && (
					<IconReportSearch
						className={classes.lineageIndicatorIcon}
						size={theme.other.iconSize.sm}
						onClick={handleImpactAnalysis}
					/>
				)}
				{showPrimaryKeyIndicator && (
					<IconKey
						size={theme.other.iconSize.sm}
						color={theme.other.getColor('icon/caution/default')}
					/>
				)}
			</Group>
		</Group>
	);
}
