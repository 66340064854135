import { Box, Flex, useMantineTheme } from '@mantine/core';

import {
	Badge,
	Breadcrumbs,
	Button,
	Select,
	TextInput,
} from '@repo/foundations';
import ed25519 from 'ed25519-keygen/ssh';
import { randomBytes } from 'ed25519-keygen/utils';
import type { FieldProps, FormikProps } from 'formik';
import { Field, Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import type { ITunnel, ITunnelConnectionStatus } from '../../api';
import {
	queryClient,
	tunnelsQueryKeyFactory,
	useCreateTunnel,
	useTunnel,
	useUpdateTunnel,
} from '../../api';
import {
	ConnectionError,
	Whitelist,
} from '../../components/Integration/IntegrationDisclaimers';
import type { WithOnlyIdRequired } from '../../lib/typescript';
import { CopyKey } from './CopyKey';
import { generateKeyPair } from './utils';
import {
	validateHostname,
	validateNumber,
	validateUsername,
} from './utils/validate';

interface TunnelFormValues extends Partial<ITunnel> {
	type?: 'RSA' | 'ed25519';
	private_key?: string;
}

interface TunnelFormProps {
	initialValues: Partial<TunnelFormValues>;
}

function TunnelForm({ initialValues }: TunnelFormProps) {
	const theme = useMantineTheme();
	const [publicKey, setPublicKey] = useState<string>();
	const [tunnelStatus, setTunnelStatus] =
		useState<ITunnelConnectionStatus | null>(null);

	const { mutate: updateTunnel } = useUpdateTunnel({});
	const { mutate: createTunnel } = useCreateTunnel({});

	const handleUpdateTunnel = useCallback(
		async (values: TunnelFormValues) => {
			if (values.id) {
				updateTunnel({
					data: values as WithOnlyIdRequired<ITunnel>,
				});
			}
			queryClient.invalidateQueries({
				queryKey: tunnelsQueryKeyFactory.allLists(),
			});
		},
		[updateTunnel]
	);

	const handleCreateTunnel = useCallback(
		async (values: TunnelFormValues) => {
			let input = { ...values } as TunnelFormValues;
			let copyText = null;

			if (values.type === 'RSA') {
				const rsaKeys = await generateKeyPair({
					alg: 'RSASSA-PKCS1-v1_5',
					size: 4096,
					hash: 'SHA-512',
					name: 'ssh@secoda.co',
				});
				input.private_key = btoa(rsaKeys.privateKey);
				copyText = rsaKeys.publicKey;
			} else {
				const ed25519Keys = await ed25519(randomBytes(32), 'ssh@secoda.co');
				input.private_key = btoa(ed25519Keys.privateKey);
				copyText = ed25519Keys.publicKey;
			}

			await createTunnel({
				data: input as WithOnlyIdRequired<ITunnel>,
			});
			setPublicKey(copyText);
			queryClient.invalidateQueries({
				queryKey: tunnelsQueryKeyFactory.allLists(),
			});
		},
		[createTunnel, setPublicKey]
	);

	if (publicKey) {
		return (
			<Flex my="100px" w="100%" justify="center">
				<Flex style={{ maxWidth: 750 }}>
					<CopyKey value={publicKey} />
				</Flex>
			</Flex>
		);
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={async (values) => {
				if (values.id) {
					await handleUpdateTunnel(values);
				} else {
					await handleCreateTunnel(values);
				}
			}}
		>
			{(formProps: FormikProps<TunnelFormValues>) => (
				<Form>
					<Flex w="100%" justify="center">
						<Flex
							direction="column"
							justify="flex-start"
							gap={theme.other.space[5]}
							w="100%"
							my={theme.other.space[6]}
							style={{ maxWidth: 750 }}
						>
							<Flex justify="space-between">
								{tunnelStatus && tunnelStatus.is_open && (
									<Badge variant="success">Connected</Badge>
								)}
							</Flex>
							<Field name="username" validate={validateUsername}>
								{({ field, form }: FieldProps<string, TunnelFormValues>) => (
									<TextInput
										label="SSH Username"
										description="Ensure that this SSH user exists on the bastion."
										error={form.errors.username}
										{...field}
										id="username"
										placeholder="secoda"
									/>
								)}
							</Field>

							<Field name="host" validate={validateHostname}>
								{({ field, form }: FieldProps<string, TunnelFormValues>) => (
									<TextInput
										label="SSH Hostname"
										description="The IP or public hostname of the bastion."
										error={form.errors.host}
										{...field}
										id="host"
										placeholder=""
									/>
								)}
							</Field>

							<Field name="port" validate={validateNumber}>
								{({ field, form }: FieldProps<string, TunnelFormValues>) => (
									<TextInput
										label="SSH Port"
										description="The port the bastion is listening on. The default SSH port is 22."
										error={form.errors.port}
										{...field}
										id="port"
										placeholder="22"
									/>
								)}
							</Field>

							{!initialValues.id && (
								<Field name="type">
									{({
										field,
										form,
									}: FieldProps<'RSA' | 'ed25519', TunnelFormValues>) => (
										<Select
											label="Pick the SSH algorithm"
											defaultValue="ed25519"
											description="ed25519 is more secure and compatible with modern systems. RSA is more compatible with older systems."
											data={[
												{ value: 'ed25519', label: 'ed25519' },
												{ value: 'RSA', label: 'RSA' },
											]}
											error={form.errors.type}
											{...field}
											onChange={(e) => {
												form.setFieldValue('type', e);
											}}
											id="type"
										/>
									)}
								</Field>
							)}
							<Whitelist />
							{tunnelStatus && !tunnelStatus.is_open && (
								<ConnectionError
									error={`${
										tunnelStatus.error || ''
									}. If the issue persists, contact customer support.`}
								/>
							)}
							<Box>
								<Button loading={formProps.isSubmitting} type="submit">
									Submit
								</Button>
							</Box>
						</Flex>
					</Flex>
				</Form>
			)}
		</Formik>
	);
}

function TunnelPage() {
	const theme = useMantineTheme();
	const { id } = useParams();

	const { data: tunnel } = useTunnel({ id: id ?? '' });

	return (
		<main>
			<Box px={theme.other.space[8]} py={theme.other.space[1]}>
				<Breadcrumbs
					crumbs={[
						{ title: 'Tunnels', href: '/tunnels' },
						{ title: 'New standard tunnel', href: '/tunnels/new' },
					]}
				/>
				<TunnelForm initialValues={tunnel ?? {}} />
			</Box>
		</main>
	);
}

export default TunnelPage;
