import type { ISecodaEntity } from '../../api';
import type { PropertySelectorProps } from './PropertySelector';
import { PropertySelector } from './PropertySelector';

export interface VerificationPropertySelectorProps
	extends Pick<PropertySelectorProps, 'onChange'> {
	entity: ISecodaEntity;
	viewerOfEntity: boolean;
}

export function VerificationPropertySelector({
	entity,
	viewerOfEntity,
	...propertySelectorProps
}: VerificationPropertySelectorProps) {
	return (
		<PropertySelector
			selected={!!entity.verified}
			type="single"
			value="verified"
			iconType="tabler"
			isViewerUser={viewerOfEntity}
			readOnly={viewerOfEntity}
			{...propertySelectorProps}
		/>
	);
}
