import { Title } from '@repo/foundations';
import React from 'react';

interface SettingsTitleProps {
	children: React.ReactNode;
}

function SettingsTitle({ children }: SettingsTitleProps) {
	return (
		<Title order={2} size="xl">
			{children}
		</Title>
	);
}

export { SettingsTitle };
