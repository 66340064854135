import { Box, Stack } from '@mantine/core';
import type { Message } from '@repo/api-codegen';
import { useMemo } from 'react';
import { AnimatedContent } from './AnimatedContent';
import { ThinkingSection } from './components/ThinkingSection';
import { useMessageContentStyles } from './styles/MessageContent.styles';

interface MessageContentV3Props {
	messages: Message[];
	isRunning: boolean;
	viewportRef?: React.RefObject<HTMLDivElement>;
}

export function MessageContentV3({
	messages,
	isRunning,
	viewportRef,
}: MessageContentV3Props) {
	const { classes } = useMessageContentStyles();

	const messageSections = useMemo(() => {
		// Special case: when not running, all messages except final one should be thinking
		if (!isRunning && messages.length > 1) {
			const thinkingMessages = messages
				.slice(0, -1)
				.map((msg) => ({ ...msg, thinking: true }));
			return [
				thinkingMessages, // All messages except last as thinking section
				[messages[messages.length - 1]], // Final message as normal section
			];
		}

		// Find first and last thinking message indices
		const firstThinkingIndex = messages.findIndex((msg) => msg.thinking);
		const lastThinkingIndex =
			messages.length -
			1 -
			[...messages].reverse().findIndex((msg) => msg.thinking);

		// If no thinking messages, return all messages as one normal section
		if (firstThinkingIndex === -1) {
			return [messages];
		}

		const sections: Message[][] = [];

		// Add messages before first thinking as normal section
		if (firstThinkingIndex > 0) {
			sections.push(messages.slice(0, firstThinkingIndex));
		}

		// Add all messages between first and last thinking (inclusive) as thinking section
		sections.push(messages.slice(firstThinkingIndex, lastThinkingIndex + 1));

		// Add remaining messages as normal section
		if (lastThinkingIndex < messages.length - 1) {
			sections.push(messages.slice(lastThinkingIndex + 1));
		}

		return sections;
	}, [messages, isRunning]);

	return (
		<Stack spacing="sm">
			{messageSections.map((sectionMessages, index) => {
				const isThinking = sectionMessages[0]?.thinking ?? false;

				if (isThinking) {
					return (
						<ThinkingSection
							key={index}
							messages={sectionMessages}
							promptRunning={isRunning}
							uniqueId={`thinking-${index}`}
						/>
					);
				}

				return sectionMessages.map((msg, msgIdx) => {
					const isLastMessage = msgIdx === sectionMessages.length - 1;
					const isTyping = isLastMessage && isRunning;

					return (
						<Box
							key={msg.content + msg.sender}
							className={classes.messageBlock}
						>
							<AnimatedContent
								cacheKey={`message-${index}-${msgIdx}`}
								content={msg.content}
								isTyping={isTyping}
								viewportRef={viewportRef}
							/>
						</Box>
					);
				});
			})}
		</Stack>
	);
}
