import type { MantineTheme } from '@mantine/core';
import { Center, createStyles, Image } from '@mantine/core';
import { IconEmoji } from '@repo/common/components/IconEmoji/IconEmoji';
import { DEFAULT_ICONS } from '@repo/common/constants';
import { EntityType } from '@repo/common/enums/entityType';
import { isNil } from 'lodash-es';
import { useAuthUser, useUser, type ISecodaEntity } from '../../../api';
import type { SecodaEntity } from '../../../lib/models';
import IntegrationLogo from '../../IntegrationLogo';
import { SecodaMinimalLogo } from '../../SecodaMinimalLogo';
import { UserAvatar } from '../../UserAvatar';

export type ISecodaEntityIcon = Pick<
	SecodaEntity | ISecodaEntity,
	'entity_type' | 'icon' | 'integration' | 'id' | 'title'
>;

interface ISecodaEntityIconProps {
	entity?: ISecodaEntityIcon;
	size?: number;
	inline?: boolean;
}

const useStyles = createStyles(
	(theme: MantineTheme, { size }: { size: number }) => ({
		center: {
			minWidth: size,
			minHeight: size,
			borderRadius: 1000,
		},
	})
);

export function SecodaEntityIcon({
	entity,
	inline = false,
	size = 36,
}: ISecodaEntityIconProps) {
	const { classes } = useStyles({
		size,
	});

	const { workspace } = useAuthUser();

	const { data: user } = useUser({
		id: entity?.id ?? '',
		options: {
			enabled: !!entity?.id && entity?.entity_type === EntityType.user,
		},
	});

	if (isNil(entity)) {
		return workspace.logo ? (
			<Image width={size} src={workspace.logo} data-testid="workspace-logo" />
		) : (
			<SecodaMinimalLogo
				width={size}
				height={size}
				data-testid="workspace-default-logo"
			/>
		);
	}

	if (entity.entity_type === EntityType.user) {
		return (
			<UserAvatar
				size={size}
				user={{
					id: user?.id || '',
					disabled: user?.disabled || false,
					email: user?.email || '',
					display_name: entity?.title || 'Untitled',
					profile_picture: user?.profile_picture || '',
					first_name: user?.first_name || '',
					last_name: user?.last_name || '',
				}}
				data-testid="user-avatar"
			/>
		);
	}

	if (
		entity.entity_type === EntityType.metric ||
		entity.entity_type === EntityType.collection ||
		entity.entity_type === EntityType.document ||
		entity.entity_type === EntityType.question ||
		entity.entity_type === EntityType.dictionary_term ||
		entity.entity_type === EntityType.glossary
	) {
		return (
			<Center
				className={classes.center}
				inline={inline}
				data-testid={`${entity.entity_type}-icon`}
			>
				<IconEmoji
					value={entity.icon || (DEFAULT_ICONS[entity.entity_type] as string)}
					size={size}
				/>
			</Center>
		);
	}

	return (
		<Center
			className={classes.center}
			inline={inline}
			data-testid={`integration-${entity.integration}-icon`}
		>
			<IntegrationLogo
				size={size - 4}
				entityType={entity.entity_type}
				integrationId={entity.integration}
			/>
		</Center>
	);
}
