import type { PolicyOut } from '@repo/api-codegen';
import { useApiListPolicies } from '@repo/api-codegen';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { TableV2 } from '../../components/TableV2';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { FilterOptionType } from '@repo/common/components/Filter/types';
import { FILTER_OPTIONS_CONFIG } from '../../components/Filter/constants';
import { useActions, useColumns } from './PoliciesList.hooks';

const COLUMN_VISIBILITY = {
	catalogServerType: 'policies',
	catalogType: 'policies',
} as const;

const PoliciesList = observer(
	({
		withAdditionalButtons,
		defaultRequiredSearchParams,
	}: {
		withAdditionalButtons?: React.ReactNode;
		defaultRequiredSearchParams?: Record<string, any>;
	}) => {
		const QUICK_ACTIONS = ['actions::delete', 'actions::duplicate'] as const;

		const columns = useColumns();
		const actions = useActions();

		const navigate = useNavigate();

		const handleClick = useCallback(
			(id: string) => {
				navigate(`/policy/${id}`);
			},
			[navigate]
		);

		const onCellClick = useCallback(
			(cell: {
				column: ExtendedDataTableColumn<PolicyOut>;
				record: { id: string };
			}) => {
				if (cell.column.navigate !== false) {
					handleClick(cell.record.id);
				}
			},
			[handleClick]
		);

		return (
			<TableV2<PolicyOut>
				pluralTypeString="policies"
				tableCacheKey="policies"
				columns={columns}
				columnVisibility={COLUMN_VISIBILITY}
				// @ts-ignore
				usePaginationList={useApiListPolicies}
				useCodegenListInterface
				withActions={actions}
				withQuickActions={QUICK_ACTIONS}
				withFilterOptions={[
					FILTER_OPTIONS_CONFIG[FilterOptionType.CREATED_BY],
					FILTER_OPTIONS_CONFIG[FilterOptionType.SEVERITY],
					FILTER_OPTIONS_CONFIG[FilterOptionType.FRAMEWORK],
					FILTER_OPTIONS_CONFIG[FilterOptionType.ENABLED],
				]}
				withCheckbox
				withCsvExport
				withInteractiveHeader
				withSearch
				defaultSort={null}
				withAdditionalButtons={withAdditionalButtons}
				defaultRequiredSearchParams={defaultRequiredSearchParams}
				onCellClick={onCellClick}
			/>
		);
	}
);

PoliciesList.displayName = 'PoliciesList';
export default PoliciesList;
