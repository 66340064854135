import { createStyles, ThemeIcon, UnstyledButton } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { noop } from 'lodash-es';
import type React from 'react';
import { Link } from 'react-router-dom';
import type {
	IHomepageOnboardingSteps,
	ITeamHomepageOnboardingSteps,
} from '../types';

interface IOnboardingStepProps {
	step: IHomepageOnboardingSteps | ITeamHomepageOnboardingSteps;
	isComplete: boolean;
	onClick?: VoidFunction;
	link?: string;
	externalLink?: string;
}

const useStyles = createStyles((theme) => ({
	step: {
		display: 'flex',
		gap: theme.spacing.md,
		alignItems: 'center',
		border: 'thin solid',
		borderColor: theme.other.getColor('border/secondary/default'),
		padding: `${theme.spacing.xs} ${theme.spacing.md}`,
		borderRadius: 1000,
	},
	stepDone: {
		backgroundColor: theme.other.getColor('fill/primary/hover'),
		'&:hover': {
			cursor: 'default',
		},
	},
	checkIcon: {
		color: theme.other.getColor('fill/secondary/default'),
		backgroundColor: theme.other.getColor('fill/secondary/active'),
	},
	checkIconComplete: {
		color: theme.other.getColor('fill/secondary/default'),
		backgroundColor: theme.other.getColor('fill/brand/default'),
	},
}));

function OnboardingStep({
	step,
	isComplete,
	link,
	externalLink,
	onClick = noop,
}: IOnboardingStepProps) {
	const { cx, classes } = useStyles();

	const handleClick = (event: React.MouseEvent) => {
		if (isComplete) {
			event.preventDefault();
			return;
		}

		if (!link) {
			onClick();
		}

		if (externalLink) {
			window.open(externalLink, '_blank');
		}
	};

	return (
		<UnstyledButton
			className={cx(classes.step, { [classes.stepDone]: isComplete })}
			key={step}
			component={Link}
			to={link ?? '#'}
			onClick={handleClick}
		>
			<ThemeIcon
				size="sm"
				radius={24}
				className={isComplete ? classes.checkIconComplete : classes.checkIcon}
			>
				<Icon name="check" size="md" />
			</ThemeIcon>
			<Text size="sm">{step}</Text>
		</UnstyledButton>
	);
}

export default OnboardingStep;
