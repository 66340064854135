import { Tabs } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { CollectionDocumentation } from './CollectionDocumentation';
import { CollectionsCatalogTable } from './CollectionsCatalogTable';

interface CollectionsTabsPanelProps {
	activeTab: string;
	collectionId: string;
}

function CollectionsTabsPanel({
	activeTab,
	collectionId,
}: CollectionsTabsPanelProps) {
	return (
		<>
			{activeTab !== 'documentation' && (
				<CollectionsCatalogTable
					collectionId={collectionId}
					activeTab={activeTab}
				/>
			)}

			<Tabs.Panel value="documentation" pt="sm">
				<CollectionDocumentation collectionId={collectionId} />
			</Tabs.Panel>
		</>
	);
}

export default observer(CollectionsTabsPanel);
