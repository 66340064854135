import { useListCustomProperties } from '@repo/api-codegen';
import {
	DEFAULT_FILTER_OPTIONS,
	DEFAULT_FILTER_OPTIONS_WITH_DQS,
	FILTER_OPTIONS_DIVIDER,
} from '@repo/common/components/Filter/constants';
import type { FilterOption } from '@repo/common/components/Filter/types';
import { FilterOptionType } from '@repo/common/components/Filter/types';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useDataQualityAccess } from '../../api';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import { useFeatureFlags } from '../../utils/featureFlags';
import { FILTER_OPTIONS_CONFIG } from '../Filter/constants';
import { getCustomPropertyAsFilter } from '../Filter/customPropertyUtils';
import { GlobalSearchStore } from './store';
import { GlobalSearchStoreContext } from './useGlobalSearch';

export function GlobalSearchProvider({ children }: { children: ReactNode }) {
	const dqsEnabled = useDataQualityAccess();
	const enableAi = useAiEnabled();

	const { customPropertiesFilter } = useFeatureFlags();

	const { data: customProperties } = useListCustomProperties(
		{},
		{
			enabled: customPropertiesFilter,
		}
	);

	const defaultGlobalSearchStore = useMemo(() => {
		let options = dqsEnabled
			? DEFAULT_FILTER_OPTIONS_WITH_DQS
			: DEFAULT_FILTER_OPTIONS;

		if (enableAi) {
			options = [FilterOptionType.AI, FILTER_OPTIONS_DIVIDER, ...options];
		}

		const filterOptions = options.map((option) =>
			option === FILTER_OPTIONS_DIVIDER
				? FILTER_OPTIONS_DIVIDER
				: FILTER_OPTIONS_CONFIG[option]
		);

		if (customPropertiesFilter && !!customProperties?.length) {
			filterOptions.push(FILTER_OPTIONS_DIVIDER);
			filterOptions.push(
				...(customProperties
					.map(getCustomPropertyAsFilter)
					.filter(Boolean) as FilterOption[])
			);
		}

		return new GlobalSearchStore({
			filterOptions,
		});
	}, [enableAi, dqsEnabled, customPropertiesFilter, customProperties]);

	return (
		<GlobalSearchStoreContext.Provider value={defaultGlobalSearchStore}>
			{children}
		</GlobalSearchStoreContext.Provider>
	);
}
