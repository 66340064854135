import { Stack } from '@mantine/core';
import type { Monitor } from '../../api';
import type { ScheduleConfig } from '../../api/types/models/schedule';
import { ScheduleCadence } from '../../api/types/models/schedule';
import { useCodeMonitorTooltip } from '../../pages/MonitorPage/hooks';
import { isValidEnumValue } from '../../utils/enumUtils';
import { SelectableProperty } from '../EntityPageLayout/EntityPropertySidebar';
import { PropertySelector } from '../Properties/PropertySelector';
import { DAY_OPTIONS, HOUR_OPTIONS } from './constants';
import { SCHEDULE_CADENCE_OPTIONS } from './schedule';

interface ScheduleSelectorProps {
	schedule: ScheduleConfig;
	onChange: (value: ScheduleConfig) => void;
	disableWeekly?: boolean;
	readOnly?: boolean;
	monitor?: Monitor;
}

type DropdownValue = string | number | boolean | string[];

function ScheduleSelector({
	schedule,
	onChange,
	readOnly = false,
	disableWeekly = false,
	monitor,
}: ScheduleSelectorProps) {
	const { cadence, hour, day, frequency } = schedule;

	const shouldDisableWeekly =
		disableWeekly && cadence !== ScheduleCadence.WEEKLY;

	const frequencyWrapper = useCodeMonitorTooltip(
		monitor,
		'Schedule frequency set in code-based monitor'
	);
	const dayWrapper = useCodeMonitorTooltip(
		monitor,
		'Schedule day set in code-based monitor'
	);
	const timeWrapper = useCodeMonitorTooltip(
		monitor,
		'Schedule time set in code-based monitor'
	);

	const handleCadenceChange = (value: DropdownValue) => {
		if (typeof value !== 'string') {
			return;
		}

		if (value.includes(':')) {
			const [newCadence, newFrequency] = value.split(':');
			if (isValidEnumValue(ScheduleCadence, newCadence)) {
				const freq = Number(newFrequency);
				if (freq >= 1 && freq < 24) {
					onChange({
						...schedule,
						cadence: newCadence as ScheduleCadence,
						frequency: freq,
					});
				}
			}
		} else if (isValidEnumValue(ScheduleCadence, value)) {
			if (shouldDisableWeekly && value === ScheduleCadence.WEEKLY) {
				return;
			}

			onChange({
				...schedule,
				cadence: value,
			});
		}
	};

	const handleDayChange = (value: DropdownValue) => {
		if (typeof value !== 'string') {
			return;
		}
		onChange({
			...schedule,
			day: Number(value),
		});
	};

	const handleHourChange = (value: DropdownValue) => {
		if (typeof value !== 'string') {
			return;
		}
		onChange({
			...schedule,
			hour: Number(value),
		});
	};

	const cadenceOptions = SCHEDULE_CADENCE_OPTIONS.filter(
		(option) =>
			!(shouldDisableWeekly && option.value === ScheduleCadence.WEEKLY)
	);

	const selectedCadenceValue =
		cadence === ScheduleCadence.HOURLY ? `${cadence}:${frequency}` : cadence;

	return (
		<Stack spacing="xs">
			<SelectableProperty
				label="Repeats"
				dataTestId="repeats"
				wrapValueWith={frequencyWrapper}
			>
				<PropertySelector
					selected={selectedCadenceValue ?? ''}
					type="single"
					value="repeats"
					iconType="tabler"
					isViewerUser={false}
					options={cadenceOptions}
					onChange={handleCadenceChange}
					readOnly={readOnly}
				/>
			</SelectableProperty>
			{cadence === ScheduleCadence.WEEKLY && (
				<SelectableProperty
					label="Day"
					dataTestId="Day"
					wrapValueWith={dayWrapper}
				>
					<PropertySelector
						selected={day?.toString() ?? ''}
						type="single"
						value="Day"
						iconType="tabler"
						isViewerUser={false}
						options={DAY_OPTIONS}
						onChange={handleDayChange}
						readOnly={readOnly}
					/>
				</SelectableProperty>
			)}
			{[ScheduleCadence.DAILY, ScheduleCadence.WEEKLY].includes(cadence) && (
				<SelectableProperty
					label="Time"
					dataTestId="Time"
					wrapValueWith={timeWrapper}
				>
					<PropertySelector
						selected={hour?.toString() ?? ''}
						type="single"
						value="Time"
						iconType="tabler"
						isViewerUser={false}
						options={HOUR_OPTIONS}
						onChange={handleHourChange}
						readOnly={readOnly}
					/>
				</SelectableProperty>
			)}
		</Stack>
	);
}

export default ScheduleSelector;
