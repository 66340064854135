import { Group, Table, ThemeIcon } from '@mantine/core';
import {
	FilterOperator,
	FilterOptionType,
} from '@repo/common/components/Filter/types';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Text } from '@repo/foundations';
import { IconCircle } from '@tabler/icons-react';
import { useGlobalSearch } from '../../../../components/GlobalSearch/useGlobalSearch';
import type { TableComponentProps } from './MetricWidgetTable';

export function MetricTagTable({ infoColumns, values }: TableComponentProps) {
	const navigate = useNavigate();
	const searchStore = useGlobalSearch();

	const handleTagClick = (tag: string | number) => {
		searchStore.setValues([
			{
				filterType: FilterOptionType.TAGS,
				value: tag,
				operator: FilterOperator.Is,
			},
		]);
		navigate(`/search`);
	};

	const rows = values.map((tag) => (
		<tr
			key={tag.id}
			style={{
				cursor: 'pointer',
			}}
			onClick={() => handleTagClick(tag.id)}
		>
			<td>
				<Group h={52} noWrap>
					<ThemeIcon
						children={<IconCircle size={16} />}
						size={16}
						radius="sm"
						style={{
							backgroundColor: tag.color.toString(),
						}}
					/>
					<Text color="text/primary/default" size="sm" fw={500} lineClamp={1}>
						{tag.name}
					</Text>
				</Group>
			</td>
			{infoColumns.map((col) => (
				<td key={col.fieldName}>
					<Text color="text/primary/default" size="sm">
						{tag[col.fieldName]}
					</Text>
				</td>
			))}
		</tr>
	));

	return (
		<Table highlightOnHover w="100%">
			<thead>
				<tr>
					<th>
						<Text color="text/secondary/default" fz="xs" fw={500}>
							Tag
						</Text>
					</th>
					{infoColumns.map((col) => (
						<th key={col.fieldName}>
							<Text color="text/secondary/default" fz="xs" fw={500}>
								{col.label}
							</Text>
						</th>
					))}
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</Table>
	);
}
