import { createStyles } from '@mantine/core';
import * as Input from '@observablehq/inputs';
import { typography } from '@repo/theme/primitives';
import dayjs from 'dayjs';
import { arrayToObjects } from '../../utils/metrics';
import { Wrapper } from './components/Wrapper';

const useStyles = createStyles(
	(theme, { width, height }: { width?: string; height?: string }) => ({
		wrapper: {
			form: {
				width,
				maxHeight: height ? `${height} !important` : undefined,
				display: 'block',
				overflowY: 'auto',
				color: theme.other.getColor('text/primary/default'),
			},
			'form table': {
				width: '100%',
				borderCollapse: 'collapse',
			},
			'form table thead tr th:first-child': {
				display: 'none',
			},
			'form table thead tr th': {
				fontWeight: typography.weight.bold,
				textAlign: 'left',
				fontSize: 14,
				borderBottom: `solid 1px ${theme.other.getColor('border/secondary/default')}`,
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				padding: '3px 6.5px 3px 0',
				textWrap: 'nowrap',
			},
			'form table tr td:first-child': {
				display: 'none',
			},
			'form table tr td': {
				fontWeight: typography.weight.regular,
				fontSize: 14,
				borderBottom: `solid 1px ${theme.other.getColor('border/secondary/default')}`,
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				padding: '3px 6.5px 3px 0',
			},
		},
	})
);

const isTimestamp = (value: string): boolean => {
	if (!value) return false;

	// format: 2022-06-10 21:23:37.946000+00:00
	const timestampRegex =
		/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:\d{2})?$/;

	if (timestampRegex.test(value)) {
		try {
			const date = new Date(value);
			return !isNaN(date.getTime());
		} catch {
			return false;
		}
	}

	return false;
};

const formatTimestamp = (value: string): string => {
	try {
		return dayjs(value).format('MMMM D, YYYY h:mm A Z');
	} catch (e) {
		return value;
	}
};

const processValue = (value: any): any => {
	if (isTimestamp(value)) {
		return formatTimestamp(value);
	}
	return value;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const svgTable = (results: any[][]) => {
	const data = results;

	const processedData = data.map((row) =>
		row.map((cell) => processValue(cell))
	);

	const formattedData = arrayToObjects(processedData);

	return Input.table(formattedData, {
		multiple: false,
		fixedWidth: true,
	});
};

interface DataDisplayTableProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	results: any[][];
	height?: string;
	width?: string;
}

export function DataDisplayTable({
	height,
	width = '100%',
	results,
}: DataDisplayTableProps) {
	const { classes } = useStyles({ width, height });

	return <Wrapper svgElement={svgTable(results)} className={classes.wrapper} />;
}
