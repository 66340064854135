import type { SelectItem } from '@mantine/core';
import { useApiListTeams } from '@repo/api-codegen';
import type {
	FilterOption,
	FilterValue,
	FilterView,
} from '@repo/common/components/Filter/types';
import { useState } from 'react';

import { filterValueToLegacyFilter } from '@repo/common/components/Filter';
import type { FILTER_OPTIONS_DIVIDER } from '@repo/common/components/Filter/constants';
import type { DataTableSortStatus } from '@repo/mantine-datatable';
import {
	invalidateSearchViews,
	useAuthUser,
	useCreateSearchView,
	useUpdateSearchView,
} from '../../../api';
import { trackEvent } from '../../../utils/analytics';
import { convertISearchViewToFilterView } from './utils';

interface UseFilterViewModalProps {
	onClose: (view?: FilterView | null) => void;
	view?: FilterView | null;
	selectedFilters: FilterValue[];
	selectedSort?: DataTableSortStatus;
	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];
}

export function useFilterViewModal({
	onClose,
	view,
	selectedFilters,
	selectedSort,
	filterOptions,
}: UseFilterViewModalProps) {
	const { user, workspace } = useAuthUser();
	const [name, setName] = useState<string>(view?.label || '');
	const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>(
		view?.teams || []
	);

	const { data: teams } = useApiListTeams(
		{},
		{
			select: (res) =>
				(res.results ?? []).map(
					(team) =>
						({
							label: team.name,
							value: team.id,
							icon: team.icon,
						}) as SelectItem
				),
		}
	);

	const handleTeamChange = (value: Array<string>) => {
		setSelectedTeamIds(value);
	};

	const handleNameChange = (value: string) => {
		setName(value);
	};

	const { mutateAsync: createSearchView } = useCreateSearchView({
		options: {
			onSuccess: () => {
				invalidateSearchViews(1);
				trackEvent('search/views/create', {}, user, workspace);
			},
		},
	});

	async function handleOnSave() {
		const createdView = await createSearchView({
			data: {
				name,
				teams: selectedTeamIds,
				selected_filters: await filterValueToLegacyFilter(
					filterOptions,
					selectedFilters
				),
				selected_sort_by: selectedSort?.columnAccessor ?? '',
				selected_sort_direction: selectedSort?.direction,
			},
		});
		onClose(convertISearchViewToFilterView(createdView, user));
	}

	const { mutateAsync: updateSearchView } = useUpdateSearchView({
		options: {
			onSuccess: () => {
				invalidateSearchViews(1);
				trackEvent('search/views/update', {}, user, workspace);
			},
		},
		disableOptimisticUpdate: true,
		disableInvalidation: true,
	});

	async function handleOnUpdate() {
		if (!view) {
			return;
		}

		const updatedView = await updateSearchView({
			data: {
				id: view.value,
				name,
				teams: selectedTeamIds,
				selected_filters: await filterValueToLegacyFilter(
					filterOptions,
					selectedFilters
				),
				selected_sort_by: selectedSort?.columnAccessor ?? '',
				selected_sort_direction: selectedSort?.direction,
			},
		});
		onClose(convertISearchViewToFilterView(updatedView, user));
	}

	return {
		teams,
		selectedTeamIds,
		name,
		handleTeamChange,
		handleNameChange,
		handleOnSave,
		handleOnUpdate,
	};
}
