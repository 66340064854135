import { Box, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
	SettingsAvatar,
	SettingsButton,
	SettingsTextInput,
} from '@repo/common/components';
import { Button } from '@repo/foundations';
import { useDebounceFn } from 'ahooks';
import axios from 'axios';
import { size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import {
	queryClient,
	useAuthUser,
	usersQueryKeyFactory,
	useUpdateUser,
} from '../../api';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList';
import { thisUserQueryKey } from '../../api/hooks/user/useThisUser';
import { useUserProfileImage } from '../../api/hooks/user/useUserProfileImage';
import { openModal } from '../ModalManager';

export const ProfileSettings = observer(() => {
	const { activeUsers } = useExtendedUserList();
	const theme = useMantineTheme();
	const { user } = useAuthUser();

	// Add state for first and last name
	const [firstName, setFirstName] = useState(user?.first_name || '');
	const [lastName, setLastName] = useState(user?.last_name || '');

	const { mutateAsync: updateProfileImage } = useUserProfileImage();
	const { mutateAsync: updateUser } = useUpdateUser({
		options: {
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: usersQueryKeyFactory.byId(user.id),
				});
				queryClient.invalidateQueries({
					queryKey: thisUserQueryKey,
				});
			},
		},
	});

	const handleNotification = useCallback((updatedVal: string) => {
		showNotification({
			title: `${updatedVal} updated`,
			message: 'Your profile has been updated.',
			color: 'green',
			autoClose: 1500,
		});
	}, []);

	// Create debounced update functions for first and last name
	const { run: updateFirstName } = useDebounceFn(
		async (value: string) => {
			if (value !== user.first_name) {
				await updateUser({
					data: {
						id: user.id,
						first_name: value,
						last_name: lastName,
					},
				});
				handleNotification('First name');
			}
		},
		{ wait: 1000 }
	);

	const { run: updateLastName } = useDebounceFn(
		async (value: string) => {
			if (value !== user.last_name) {
				await updateUser({
					data: {
						id: user.id,
						first_name: firstName,
						last_name: value,
					},
				});
				handleNotification('Last name');
			}
		},
		{ wait: 1000 }
	);

	const handleDeleteAccountModal = useCallback(() => {
		const handleLeave = async () => {
			await axios.post(`/auth/leave_workspace/`, {});
			window.location.reload();
		};

		openModal({
			title: 'Are you sure you want to leave this workspace?',
			children: (
				<Button
					data-testid="leave-workspace-modal"
					variant="primary"
					tone="critical"
					onClick={handleLeave}
				>
					Leave workspace
				</Button>
			),
		});
	}, []);

	const onUpload = useCallback(
		async (file: File | null) => {
			if (file) {
				await updateProfileImage({
					file,
				});
			}
		},
		[updateProfileImage]
	);

	return (
		<Box>
			<Box>
				<SettingsAvatar
					title="Profile picture"
					imageUrl={user?.profile_picture ?? ''}
					onChange={onUpload}
				/>
				<SettingsTextInput
					title="First name"
					value={firstName}
					onChange={(value) => {
						setFirstName(value);
						updateFirstName(value);
					}}
				/>
				<SettingsTextInput
					title="Last name"
					value={lastName}
					onChange={(value) => {
						setLastName(value);
						updateLastName(value);
					}}
				/>
			</Box>
			<Box my={theme.spacing.xl}>
				{size(activeUsers) > 0 && (
					<SettingsButton
						title="Leave workspace"
						buttonText="Leave workspace"
						description="Remove your account from the workspace"
						onClick={handleDeleteAccountModal}
					/>
				)}
			</Box>
		</Box>
	);
});
