import { Group, Image, Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import type { IIntegration } from '../../../../api';
import { useIntegrationList } from '../../../../api';
import { useDatabuilderJob } from '../../../../api/hooks/databuilderJob';
import type { INotification } from '../../../../api/types/models/notification';
import { iconSrcFromIntegration } from '../../../../utils/integrationLogo';
import { formatIntegrationType } from '../../../../utils/stringUtils';
import { useStyles } from './styles';

interface IResourceIntegrationJobContentProps {
	selected: INotification;
}

function ResourceIntegrationJobContent({
	selected,
}: IResourceIntegrationJobContentProps) {
	const { classes, cx } = useStyles();
	const navigate = useNavigate();
	const integrationId =
		selected.metadata?.integration_id || selected.resource_id;

	const { data: integrations } = useIntegrationList({});

	const integration: IIntegration | null | undefined = useMemo(
		() => integrations?.results?.find((i) => i.id === integrationId),
		[integrationId, integrations?.results]
	);

	const { data: databuilderJob } = useDatabuilderJob({
		id: selected.metadata.databuilder_job_id as string,
		options: {
			retry: false,
			enabled: !isNil(selected.metadata.databuilder_job_id),
		},
	});

	const timeLabel = selected.event.startsWith('extraction')
		? 'Extraction time'
		: 'Push time';

	return (
		<Stack spacing="lg">
			{integration && (
				<Group spacing="xs" align="flex-start">
					<Group className={classes.leftCol} spacing="xs">
						<Icon name="plugConnected" />
						Integration
					</Group>
					<Group
						className={cx(classes.rightCol, classes.cursorPointer)}
						spacing="xs"
						onClick={() => navigate(`/integrations/${integration.id}`)}
					>
						<Image
							width={36}
							height={36}
							src={iconSrcFromIntegration(integration)}
						/>
						<Stack spacing={0}>
							<Text className={classes.label}>{integration.name}</Text>
							<Text
								className={classes.subLabel}
								data-testid="inbox-resource-integration-job-content-sublabel-e31e5dd8c"
							>
								{formatIntegrationType(integration.type)}
							</Text>
						</Stack>
					</Group>
				</Group>
			)}
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="clock" />
					{timeLabel}
				</Group>
				<Group className={classes.rightCol} spacing="xs" noWrap>
					<Stack spacing={0}>
						<Text className={classes.soleLabel}>
							{dayjs(databuilderJob?.created_at || selected.created_at).format(
								'MMMM D, YYYY h:mm A'
							)}
						</Text>
					</Stack>
				</Group>
			</Group>
			{integration?.type === 'github' &&
				Array.isArray(selected.metadata?.resources) && (
					<Group>
						<Group className={classes.leftCol} spacing="xs">
							<Icon name="listDetails" />
							Resources
						</Group>
						<Group className={classes.rightCol} spacing="xs">
							<Stack spacing={0}>
								<Text className={classes.soleLabel}>
									{selected.metadata?.resources.join(', ')}
								</Text>
							</Stack>
						</Group>
					</Group>
				)}
		</Stack>
	);
}

export default ResourceIntegrationJobContent;
