import {
	Alert,
	Box,
	CloseButton,
	Container,
	Divider,
	Group,
	Skeleton,
	Stack,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import type {
	ApiGetPermissionsTreeResponse,
	IAMRoleIn,
	IAMRoleOut,
} from '@repo/api-codegen';
import {
	apiQueryKey,
	useApiGetIamRole,
	useApiGetPermissionsTree,
	useApiUpdateIamRole,
} from '@repo/api-codegen';
import { UserRole } from '@repo/common/enums/UserRole';
import { Button, Text } from '@repo/foundations';
import { useNavigate, useParams } from 'react-router-dom';
import { queryClient } from '../../../../api';
import { useCheckIAMPermission } from '../../../../utils/authorization/roles';
import { openConfirmModal } from '../../../ModalManager';
import { IamRoleForm } from './IamRoleForm';
import { useIamRoleForm } from './useIamRoleForm';

function Content({
	role,
	permissionsTree,
	canEditRole,
}: {
	role: IAMRoleOut;
	permissionsTree: ApiGetPermissionsTreeResponse;
	canEditRole: boolean;
}) {
	const form = useIamRoleForm(role);
	const navigate = useNavigate();
	const {
		mutateAsync: apiUpdateIamRole,
		isLoading,
		error,
	} = useApiUpdateIamRole({
		onSuccess: async () => {
			notifications.show({
				title: 'Role updated',
				message: 'Successfully updated role',
				color: 'green',
			});
			queryClient.invalidateQueries({
				queryKey: apiQueryKey('iam/iam_roles/iam_role'),
			});
			navigate(-1);
		},
	});

	const onSubmit = async (values: IAMRoleIn) => {
		await apiUpdateIamRole({
			pathParams: { iamRoleId: role.id },
			body: values,
		});
	};

	return (
		<form onSubmit={form.onSubmit(onSubmit)}>
			<Stack spacing="xl">
				<IamRoleForm
					disabled={role.is_legacy || !canEditRole}
					form={form}
					permissionsTree={permissionsTree}
				/>
				{error && (
					<Alert title="Error" color="red">
						{error.detail}
					</Alert>
				)}
				{!role.is_legacy && canEditRole && (
					<Group position="right">
						<Button variant="default" onClick={() => navigate(-1)}>
							Cancel
						</Button>
						<Button variant="primary" type="submit" loading={isLoading}>
							Save changes
						</Button>
					</Group>
				)}
			</Stack>
		</form>
	);
}

export function UpdateRolePage() {
	const navigate = useNavigate();
	const { roleId } = useParams();

	const { data: role } = useApiGetIamRole({
		pathParams: { iamRoleId: roleId! },
	});
	const { data: permissionsTree } = useApiGetPermissionsTree({});
	const { hasPermission: canEditRole } = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Roles.Update',
		defaultResult: false,
	});

	const readOnly = role?.is_legacy || !canEditRole;

	const handleClose = () => {
		if (readOnly) {
			navigate(-1);
			return;
		}
		openConfirmModal({
			title: 'Unsaved changes',
			children: (
				<Text>
					You have unsaved changes. Are you sure you want to leave this page?
					Your changes will be lost.
				</Text>
			),
			labels: { confirm: 'Leave page', cancel: 'Stay' },
			confirmProps: { color: 'red' },
			onConfirm: () => navigate(-1),
		});
	};

	return (
		<Stack spacing={0}>
			<Stack spacing={0}>
				<Group position="apart" px="xl" py="xs">
					<Box sx={{ width: 40 }} /> {/* Spacer for centering */}
					<Text weight="bold">{role?.name}</Text>
					<CloseButton onClick={handleClose} />
				</Group>
				<Divider />
			</Stack>

			<Container size={640}>
				<Stack pb="xl" spacing="xl" w={640} py="xl">
					{role && permissionsTree ? (
						<Content
							role={role}
							permissionsTree={permissionsTree}
							canEditRole={canEditRole}
						/>
					) : (
						<Stack spacing="xl">
							<Skeleton height={40} radius="sm" />
							<Skeleton height={120} radius="sm" />
							<Skeleton height={200} radius="sm" />
						</Stack>
					)}
				</Stack>
			</Container>
		</Stack>
	);
}
