import { useFeatureFlags } from '../../utils/featureFlags/useFeatureFlags';
import UserActivityLogTimeline from '../ActivityLog/UserActivityLogTimeline';
import { UserLogs } from './UserLogs';

export function UserLogWrapper({ userId }: { userId: string }) {
	const { feActivityLog } = useFeatureFlags();

	if (feActivityLog) {
		return <UserLogs userId={userId} />;
	}

	return <UserActivityLogTimeline userId={userId} />;
}
