import { type CustomPropertyOut } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import type { DataTableColumn } from '@repo/mantine-datatable';
import { useDataTableColumns } from '@repo/mantine-datatable';
import { useDebounceFn } from 'ahooks';
import { useMemo } from 'react';
import { type IBaseModel, type ISecodaEntity } from '../../api';
import { useColumnDefs } from '../CatalogView/hooks/useColumnDefs';
import { CustomPropertyRendererV2 } from '../CustomPropertyRenderV2';
import type { SearchFilterV2Store } from '../Filter/store';
import { STICKY_COLUMNS } from './constants';
import type { ITableV2Props } from './TableV2';
import { TableV2Header } from './TableV2Header';
import type { ExtendedDataTableColumn } from './types';

const DEFAULT_CUSTOM_PROPERTY_COLUMN_WIDTH = 350;

export function useTableV2Columns<T extends IBaseModel>({
	columns,
	columnVisibility,
	storeColumnsKey,
	withInteractiveHeader = true,
	customProperties,
	searchFilterV2Store,
}: ITableV2Props<T> & {
	storeColumnsKey: string;
	customProperties?: CustomPropertyOut[];
	searchFilterV2Store: SearchFilterV2Store;
}) {
	const { catalog: catalogProperties } = useColumnDefs<T>({
		defaultColumns: columns,
		catalogType: columnVisibility?.catalogType ?? EntityType.table,
		catalogServerType: columnVisibility?.catalogServerType ?? EntityType.table,
		// If there is a view, use the `id` of that view.
		entityId: searchFilterV2Store.view?.id ?? columnVisibility?.catalogEntityId,
	});

	const { run: onResizeColumn } = useDebounceFn(
		(columnName: string, newWidth: number) => {
			setColumnWidth(columnName, newWidth);
		},
		{ wait: 5 }
	);

	const allColumns = useMemo(() => {
		let computedColumns: ExtendedDataTableColumn<T>[] = [...columns];

		if (customProperties) {
			computedColumns = computedColumns.concat(
				customProperties.map((cp) => ({
					customProperty: true,
					accessor: cp.name,
					title: cp.name,
					sortable: false,
					filtering: true,
					explicit: false,
					navigate: false,
					width: DEFAULT_CUSTOM_PROPERTY_COLUMN_WIDTH,
					filterOptionType: `custom_property_${cp.id}`,
					render: (record) => (
						<CustomPropertyRendererV2
							customProperty={cp}
							entity={record as unknown as ISecodaEntity}
						/>
					),
				}))
			);
		}

		return computedColumns
			.sort((a, b) => {
				if (STICKY_COLUMNS.includes(a.accessor)) {
					return -1;
				} else if (STICKY_COLUMNS.includes(b.accessor)) {
					return 1;
				} else {
					const aOrder = catalogProperties?.properties?.find(
						(prop) => prop.value === a.accessor || prop.value === a.esAccessor
					)?.order;
					const bOrder = catalogProperties?.properties?.find(
						(prop) => prop.value === b.accessor || prop.value === b.esAccessor
					)?.order;

					if (a.customProperty && aOrder === undefined) {
						return 1;
					}
					if (b.customProperty && bOrder === undefined) {
						return -1;
					}

					return (aOrder ?? 0) - (bOrder ?? 0);
				}
			})
			.map((column, idx) => ({
				...column,
				defaultToggle: true,
				title: (
					<TableV2Header
						column={column}
						withSort={withInteractiveHeader && column.sortable !== false}
						withFilters={withInteractiveHeader && column.filtering !== false}
						// We don't want to allow resizing the first column - https://github.com/secoda/secoda/pull/7000
						onResizeColumn={
							idx === computedColumns.length - 1 ? undefined : onResizeColumn
						}
					/>
				),
			}));
	}, [
		catalogProperties,
		columns,
		customProperties,
		onResizeColumn,
		withInteractiveHeader,
	]);

	const visibleColumns = useMemo(
		() =>
			allColumns.filter((column) => {
				const columnCatalogSetting = catalogProperties?.properties?.find(
					(prop) =>
						prop.value === column.accessor ||
						prop.value === (column as ExtendedDataTableColumn<T>).esAccessor
				);

				if (
					(column as DataTableColumn<T> & { explicit: boolean }).explicit &&
					!columnCatalogSetting
				) {
					// Filter out all columns that require the backend to explicitly
					// define them, to render. This is used mainly on the columns table
					// where distributions and custom properties may be added.
					return false;
				}

				return (
					!columnCatalogSetting?.hidden ||
					STICKY_COLUMNS.includes(column.accessor)
				);
			}),
		[catalogProperties?.properties, allColumns]
	);

	const { effectiveColumns, setColumnWidth } = useDataTableColumns({
		key: storeColumnsKey,
		columns: visibleColumns,
	});

	return {
		allColumns,
		effectiveColumns,
	};
}
