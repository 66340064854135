import { createStyles, Menu, ScrollArea } from '@mantine/core';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { Text } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import type { TablerIconsProps } from '@tabler/icons-react';
import { IconCheck, IconChevronDown } from '@tabler/icons-react';
import { FilterMenuTarget } from '../FilterMenu';

type FilterProps = {
	value: string | null;
	options: Array<{
		value: string | null;
		label: string;
		icon?: ((props: TablerIconsProps) => JSX.Element) | string;
	}>;
	onChange(value: string | null): void;
	icon?: ((props: TablerIconsProps) => JSX.Element) | string;
};

type OptionType = {
	value: string | null;
	label: string;
	icon?: string | ((props: TablerIconsProps) => JSX.Element) | undefined;
};

const useStyles = createStyles({
	menuDropdown: {
		minHeight: 'fit-content',
	},
	menuText: {
		maxWidth: '280px',
		paddingRight: space[3],
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
});

export function ScrollableDropdownFilter({
	icon: Icon,
	onChange,
	options,
	value,
}: FilterProps) {
	const { classes, theme } = useStyles();

	const variant = value === null ? 'secondary' : 'primary';
	const renderOptionIcon = (option: OptionType) => {
		if (option.icon) {
			if (typeof option.icon === 'string') {
				return <IconWrapper>{option.icon}</IconWrapper>;
			}
			return (
				<option.icon
					size={14}
					color={theme.other.getColor('icon/primary/disabled')}
				/>
			);
		}
		return null;
	};
	const renderOptionRightSection = (option: OptionType) =>
		option.value === value ? (
			<IconCheck size={14} color={theme.other.getColor('icon/brand/default')} />
		) : null;

	return (
		<Menu
			classNames={{
				dropdown: classes.menuDropdown,
			}}
			width={300}
			position="bottom-start"
			withinPortal
		>
			<Menu.Target>
				<FilterMenuTarget
					position="align"
					variant={variant}
					leftIcon={Icon && <Icon size={14} />}
					rightIcon={<IconChevronDown size={14} />}
				>
					<Text size="sm" lineClamp={1}>
						{options.find((o) => o.value === value)?.label}
					</Text>
				</FilterMenuTarget>
			</Menu.Target>
			<Menu.Dropdown>
				{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
				{/* @ts-ignore poorly typed component by Mantine */}
				<ScrollArea.Autosize mah={400}>
					{options.map((option) => (
						<Menu.Item
							key={option.value}
							onClick={() => onChange(option.value)}
							icon={renderOptionIcon(option)}
							rightSection={renderOptionRightSection(option)}
							maw={280}
						>
							<Text size="sm" className={classes.menuText}>
								{option.label}
							</Text>
						</Menu.Item>
					))}
				</ScrollArea.Autosize>
			</Menu.Dropdown>
		</Menu>
	);
}
