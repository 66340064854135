import {
	Center,
	createStyles,
	List,
	SimpleGrid,
	Stack,
	ThemeIcon,
} from '@mantine/core';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Button, Icon, Text } from '@repo/foundations';
import { chunk, isEmpty, map } from 'lodash-es';
import { Link } from 'react-router-dom';
import { WIDGET_CONTENT_HEIGHT } from '../../../constants';
import type { IWidgetItemProps } from '../../../types';

const useStyles = createStyles((theme) => ({
	itemWrapper: {
		borderRadius: theme.spacing.sm,
		padding: theme.spacing.xs,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/transparent/hover'),
		},
	},
	emptyState: {
		height: WIDGET_CONTENT_HEIGHT,
		flexDirection: 'column',
		gap: theme.spacing.sm,
	},
}));

function SearchWidget({ widget }: IWidgetItemProps) {
	const { classes } = useStyles();
	const navigate = useNavigateHandler();

	if (isEmpty(widget.data?.results)) {
		return (
			<Center className={classes.emptyState}>
				<Icon name="search" size="lg" />

				<Stack spacing="none">
					<Text px="xl" size="sm" weight="bold" align="center">
						No recent searches
					</Text>
					<Text size="sm" color="text/secondary/default" align="center">
						Your company data searches will appear here
					</Text>
				</Stack>
				<Button component={Link} to="/search">
					Start searching
				</Button>
			</Center>
		);
	}

	const shouldChunkData = widget.size === 'full';
	const chunkCount = shouldChunkData ? 5 : 10;
	const cols = shouldChunkData ? 2 : 1;

	const searchResults: string[][] = chunk(widget.data?.results, chunkCount);

	const handleOnLinkClick =
		(searchTerm: string) => (event: React.MouseEvent) => {
			event.preventDefault();
			navigate(event)(`/search/?term=${searchTerm}&resource=all&index=0`);
		};

	return (
		<SimpleGrid px="sm" cols={cols}>
			{map(searchResults, (searches, index) => (
				<List
					key={index}
					pt="sm"
					size="sm"
					center
					classNames={{
						item: classes.itemWrapper,
					}}
					icon={
						<ThemeIcon color="fill/secondary/default" size={32} radius={24}>
							<Icon name="search" color="icon/primary/default" />
						</ThemeIcon>
					}
				>
					{map(searches, (searchTerm, searchIndex) => (
						<List.Item
							onClick={handleOnLinkClick(searchTerm)}
							key={`${searchTerm}-${searchIndex}`}
						>
							{searchTerm}
						</List.Item>
					))}
				</List>
			))}
		</SimpleGrid>
	);
}

export default SearchWidget;
