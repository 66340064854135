import { Stack, Table } from '@mantine/core';
import {
	useGetEntityAccessControlRole,
	type AccessControlRecordOut,
} from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { sortBy } from 'lodash-es';
import type { ISecodaEntity } from '../../api';
import { formatEntityDateTime } from '../../utils/time';

interface AccessControlRoleDetailsModalProps {
	entity: ISecodaEntity;
	role: AccessControlRecordOut;
}

export function AccessControlRoleDetailsModal({
	entity,
	role,
}: AccessControlRoleDetailsModalProps) {
	const { data: roles } = useGetEntityAccessControlRole({
		pathParams: {
			entityId: entity.id,
			roleId: role.id,
		},
	});

	if (!roles) {
		return null;
	}

	return (
		<Stack spacing="md">
			<Stack spacing="sm">
				<Text fw={600}>Roles and users granted {role.name}</Text>
				<Table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Type</th>
							<th>Valid until</th>
						</tr>
					</thead>
					<tbody>
						{sortBy(roles.results, 'relationship_type').map((user) => (
							<tr key={user.id}>
								<td>{user.source.name}</td>
								<td>
									{user.relationship_type === 'USER_ROLE' ? 'User' : 'Role'}
								</td>
								<td>
									{user.source.valid_until
										? formatEntityDateTime(user.source.valid_until)
										: 'Indefinite'}
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Stack>
		</Stack>
	);
}
