import { Box, createStyles, Divider, Group, Stack } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import type { DataAccessRequestOut } from '@repo/api-codegen';
import { Banner, Button, IconButton, TextInput } from '@repo/foundations';

const useStyles = createStyles((theme) => ({
	divider: {
		margin: `0 -${theme.spacing.md}`,
	},
}));

export interface DataAccessRequestCredentialsModalProps {
	request: DataAccessRequestOut;
}

export function DataAccessRequestCredentialsModal({
	request,
}: DataAccessRequestCredentialsModalProps) {
	const { classes } = useStyles();
	const clipboard = useClipboard();

	const credentials = [
		{ label: 'Username', value: request.temporary_user_name ?? '' },
		{ label: 'Password', value: request.temporary_user_password ?? '' },
	];

	return (
		<Stack spacing="md">
			<Banner
				message="These credentials will not be displayed again. Please securely share with the requester."
				tone="warning"
				inCard
			/>
			{credentials.map(({ label, value }) => (
				<Box key={label}>
					<TextInput
						value={value}
						readOnly
						label={label}
						variant="subtle"
						rightSection={
							<IconButton
								iconName="clipboard"
								onClick={() => clipboard.copy(value)}
								variant="tertiary"
								size="sm"
							/>
						}
					/>
				</Box>
			))}
			<Divider className={classes.divider} />
			<Group position="right">
				<Button
					leftIconName="download"
					onClick={() => {
						const content = credentials
							.map(({ label, value }) => `${label}: ${value}`)
							.join('\n');
						const blob = new Blob([content], { type: 'text/plain' });
						const url = URL.createObjectURL(blob);
						const a = document.createElement('a');
						a.href = url;
						a.download = 'credentials.txt';
						a.click();
						URL.revokeObjectURL(url);
					}}
				>
					Download Credentials
				</Button>
			</Group>
		</Stack>
	);
}
