import { Container, Group, Stack, Timeline, Tooltip } from '@mantine/core';
import type { ActivityLogOut, VersionHistoryOut } from '@repo/api-codegen';
import { fetchApiListResourceLogs } from '@repo/api-codegen';
import { IconButton, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useResourceLogStyles } from './ResourceLog.styles';
import { ResourceLogItem } from './ResourceLogItem';
import { ResourceLogItemIcon } from './ResourceLogItemIcon';
import { PENDING_VERSION_HISTORY } from './constants';
import { useInfinitePagination } from './hooks';

export interface ResourceLogsProps {
	id: string;
	onClickVersionHistory: (versionHistoryId: string) => void;
}

export function ResourceLogs({ id, onClickVersionHistory }: ResourceLogsProps) {
	const { classes, theme } = useResourceLogStyles();

	const {
		page,
		setPage,
		fetchedPages,
		setFetchedPages,
		hasNextPage,
		setHasNextPage,
		loading,
		setLoading,
	} = useInfinitePagination();

	const [nonVersionedLogs, setNonVersionedLogs] = useState<ActivityLogOut[]>(
		[]
	);
	const [versionHistories, setVersionHistories] = useState<VersionHistoryOut[]>(
		[]
	);

	const loadMore = useCallback(async () => {
		if (loading || fetchedPages.has(page) || !hasNextPage) return;
		setLoading(true);

		const response = await fetchApiListResourceLogs({
			queryParams: {
				resource_id: id,
				page: page,
			},
		});

		setNonVersionedLogs((prev) => [...prev, ...response.non_versioned_logs]);
		setVersionHistories((prev) => [...prev, ...response.version_histories]);

		setPage(page + 1);
		setFetchedPages((prev) => new Set(prev).add(page));
		setHasNextPage(!isNil(response.meta.next_page));
		setLoading(false);
	}, [
		loading,
		fetchedPages,
		page,
		hasNextPage,
		setLoading,
		id,
		setPage,
		setFetchedPages,
		setHasNextPage,
	]);

	useEffect(() => {
		if (page === 1) {
			loadMore();
		}
	}, []);

	const data = useMemo(
		() => [
			...(nonVersionedLogs.length > 0
				? [
						{
							id: PENDING_VERSION_HISTORY,
							created_at: nonVersionedLogs[0].logged_at,
							updated_at: nonVersionedLogs[0].logged_at,
							latest_activity_logged_at: nonVersionedLogs[0].logged_at,
							resource_id: id,
							activity_logs: nonVersionedLogs,
							metadata: {
								prev: {},
								current: {},
								definition_diff: {},
								description_diff: {},
							},
							is_legacy: false,
						},
					]
				: []),
			...versionHistories.filter((v) => v.activity_logs.length > 0),
		],
		[nonVersionedLogs, versionHistories, id]
	);

	const itemContent = useCallback(
		(index: number, item: VersionHistoryOut) => (
			<Stack key={item.id} spacing="sm" mt={index > 0 ? 'xl' : 0}>
				{item.id !== PENDING_VERSION_HISTORY &&
					item.activity_logs.length > 0 && (
						<Group spacing="2xs">
							<Text size="sm" weight="bold">
								{dayjs(item.latest_activity_logged_at).format(
									'MMM D, YYYY [at] h:mm A'
								)}
							</Text>
							<Tooltip label="View version history" position="top">
								<IconButton
									iconName="clockPlay"
									variant="tertiary"
									onClick={() => {
										if (item.id) {
											onClickVersionHistory(item.id);
										}
									}}
								/>
							</Tooltip>
						</Group>
					)}
				<Timeline classNames={classes} lineWidth={1}>
					{item.activity_logs.map((log) => (
						<Timeline.Item
							key={log.id}
							bullet={<ResourceLogItemIcon activityType={log.activity_type} />}
						>
							<ResourceLogItem log={log} />
						</Timeline.Item>
					))}
				</Timeline>
			</Stack>
		),
		[classes, onClickVersionHistory]
	);

	return (
		<Container fluid h="100%" mt="xs">
			<Virtuoso
				height={`calc(100vh - ${theme.spacing.sm}px - ${theme.spacing.sm}px - ${theme.spacing.sm}px)`}
				data={data}
				endReached={loadMore}
				itemContent={itemContent}
			/>
		</Container>
	);
}
