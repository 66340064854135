import {
	ActionIcon,
	Group,
	Tooltip,
	UnstyledButton,
	createStyles,
} from '@mantine/core';

import type { UnstyledButtonProps } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { px } from '@mantine/styles';
import { Badge, Text, type TextProps } from '@repo/foundations';
import { getFormattedTooltipLabel } from '@repo/foundations/components/Tooltip';
import { IconCirclePlus, IconInfoCircle } from '@tabler/icons-react';

export interface ICardWrapperProps {
	name: string;
	tooltipLabel?: string;
	onClick: VoidFunction;
	children: React.ReactNode;
	containerProps?: UnstyledButtonProps;
	textProps?: TextProps;
	recommended?: boolean;
}

const useStyles = createStyles((theme) => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing.sm,
		width: '100%',
		boxShadow: `0px 0px 0px 2px ${theme.other.getColor(
			'surface/primary/active'
		)} inset`,
		borderRadius: 12,

		marginTop: 15,

		'&:focus': {
			boxShadow: `0px 8px 16px 0px ${theme.other.getColor('border/inverse/active')}, 0px 0px 0px 1px ${theme.other.getColor(
				'surface/primary/active'
			)} inset !important`,
		},

		'&:hover': {
			boxShadow: `0px 8px 16px 0px ${theme.other.getColor('border/inverse/active')}, 0px 0px 0px 1px ${theme.other.getColor(
				'surface/primary/active'
			)} inset`,
		},
	},
	header: {
		width: '100%',
	},
	addIcon: {
		position: 'absolute',
		top: -px(theme.spacing.xs),
		left: -px(theme.spacing.xs),
	},
}));

function CardWrapper({
	name,
	tooltipLabel,
	onClick,
	children,
	containerProps = {},
	textProps,
	recommended = false,
}: ICardWrapperProps) {
	const { classes, theme } = useStyles();

	const { ref, hovered } = useHover<HTMLButtonElement>();
	return (
		<UnstyledButton
			className={classes.root}
			onClick={onClick}
			p="sm"
			ref={ref}
			{...containerProps}
		>
			{hovered && (
				<IconCirclePlus
					className={classes.addIcon}
					color={theme.other.getColor('fill/brand/default')}
					strokeWidth={1}
				/>
			)}
			<Group className={classes.header} position="apart">
				<Group spacing="none">
					<Text {...textProps} size="sm" weight="bold">
						{name}
					</Text>
					{tooltipLabel && (
						<Tooltip label={getFormattedTooltipLabel(tooltipLabel)}>
							<ActionIcon variant="transparent">
								<IconInfoCircle size={theme.other.iconSize.sm} />
							</ActionIcon>
						</Tooltip>
					)}
				</Group>
				{recommended && <Badge variant="info">Recommended</Badge>}
			</Group>
			{children}
		</UnstyledButton>
	);
}

export default CardWrapper;
