import {
	Box,
	Center,
	Divider,
	Group,
	Menu,
	Modal,
	Select,
	Stack,
	Table,
	Tooltip,
	useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
	Badge,
	Button,
	IconButton,
	Text,
	Title,
	TruncateText,
} from '@repo/foundations';
import { capitalize } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { IUser } from '../../../api';

import { useApiImpersonateUser } from '@repo/api-codegen';
import { SettingsButton } from '@repo/common/components';
import { UserRole } from '@repo/common/enums/UserRole';
import Papa from 'papaparse';
import {
	queryClient,
	useAuthUser,
	useInviteUsers,
	useUpdateUser,
	usersQueryKeyFactory,
} from '../../../api';
import { useExtendedUserList } from '../../../api/hooks/user/useExtendedUserList';
import { useCheckIAMPermission } from '../../../utils/authorization/roles';
import {
	clearCache,
	clearTokens,
	isImpersonating,
} from '../../../utils/cache/utils';
import { Guard } from '../../Guard/Guard';
import { closeModal, openModal } from '../../ModalManager';
import { InviteMembersModal } from '../../PriceIncreaseModals';
import SearchBox from '../../SearchBox/SearchBox';
import { UserAvatar } from '../../UserAvatar';
import { SettingsTableEmpty } from '../Empty';
import { IamRoleSelector } from '../Selectors/IamRoleSelector';
import RoleSelect from '../Selectors/RoleSelector';
import { useMembersTabSelectRole } from './MembersTab.helpers';

type MembersListFilterType =
	| 'Members'
	| 'INVITED'
	| 'REMOVED'
	| keyof typeof UserRole;

interface RemoveMemberModalProps {
	user: IUser;
	onClose: VoidFunction;
	onConfirm: (user: IUser) => Promise<void>;
}

const sortByRole = (a: IUser, b: IUser) => {
	// This is the order of roles, that is always used.
	const roleOrder = ['ADMIN', 'EDITOR', 'VIEWER'];

	const roleA = a.role?.toUpperCase();
	const roleB = b.role?.toUpperCase();

	// Determine the role index for each user.
	const roleIndexA =
		roleOrder.indexOf(roleA) !== -1
			? roleOrder.indexOf(roleA)
			: roleOrder.length;

	const roleIndexB =
		roleOrder.indexOf(roleB) !== -1
			? roleOrder.indexOf(roleB)
			: roleOrder.length;

	// Compare roles based on role index
	if (roleIndexA < roleIndexB) return -1;
	if (roleIndexA > roleIndexB) return 1;

	// If roles are the same, compare display names.
	return a.display_name.localeCompare(b.display_name);
};

export function Members() {
	const theme = useMantineTheme();
	const [queryParams, setQueryParams] = useSearchParams();
	const { isAdminUser, user: currentUser, workspace } = useAuthUser();
	const { activeUsers, disabledUsers, pendingUsers } = useExtendedUserList();
	const { hasPermission: canEditUsers } = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Users.Update',
		defaultResult: false,
	});

	const { hasPermission: canRemoveUsers } = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Users.Delete',
		defaultResult: false,
	});

	const navigate = useNavigate();

	const [searchTerm, setSearch] = useState('');
	const [selectedFilter, setSelectedFilter] = useState<MembersListFilterType>(
		(queryParams.get('member_role') as MembersListFilterType) ?? 'Members'
	);

	const [opened, { open, close }] = useDisclosure(false);

	const { selectRole } = useMembersTabSelectRole();
	const { mutateAsync: inviteUsers } = useInviteUsers(
		usersQueryKeyFactory.list()
	);
	const { mutateAsync: updateUser } = useUpdateUser({
		options: {
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: usersQueryKeyFactory.allLists(),
				});
			},
		},
	});

	const [userToRemove, setUserToRemove] = useState<IUser | null>(null);

	const {
		mutateAsync: impersonateUser,
		isLoading: isSendingImpersonatingRequest,
	} = useApiImpersonateUser();

	const handleToggleActivation = useCallback(
		async (toggleUser: IUser) => {
			await updateUser({
				data: {
					id: toggleUser.id,
					disabled: !toggleUser.disabled,
				},
			});
			showNotification({
				message: !toggleUser.disabled
					? `${toggleUser.display_name} has been removed from your workspace`
					: 'User activated',
			});
			setUserToRemove(null);
		},
		[updateUser]
	);

	const handleInviteMembers = async ({
		emails,
		role,
		iam_role_id,
		groups,
		teams,
	}: {
		emails: string[];
		role: UserRole;
		iam_role_id?: string | null;
		groups: string[];
		teams: string[];
	}) => {
		await inviteUsers({
			emails,
			role,
			groups,
			teams,
			iam_role_id: iam_role_id,
		});
	};

	const handleSelectedFilterChange = useCallback(
		(value: MembersListFilterType) => {
			setQueryParams((prev) => ({ ...prev, member_role: value }));
			setSelectedFilter(value);
		},
		[setQueryParams]
	);

	const users = useMemo(() => {
		const initialUsers = [
			...(activeUsers ?? []),
			...(disabledUsers ?? []),
			...(pendingUsers ?? []),
		];

		let filtered = [];

		if (selectedFilter === 'Members') {
			filtered = initialUsers.filter((user) => !user.pending && !user.disabled);
		} else if (selectedFilter === 'INVITED') {
			filtered = initialUsers.filter((user) => user.pending && !user.disabled);
		} else if (selectedFilter === 'REMOVED') {
			filtered = initialUsers.filter((user) => user.disabled);
		} else {
			filtered = initialUsers.filter(
				(user) =>
					user.role &&
					user.role.toLowerCase() === selectedFilter?.toLowerCase() &&
					!user.disabled &&
					!user.pending
			);
		}

		return filtered
			.filter(
				(user) =>
					user.display_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
					user.email.toLowerCase().includes(searchTerm.toLowerCase())
			)
			.sort(sortByRole);
	}, [activeUsers, disabledUsers, pendingUsers, searchTerm, selectedFilter]);

	const handleOpenUser = useCallback(
		(userId: string) => {
			navigate(`/user/${userId}`);
		},
		[navigate]
	);

	const handleCsvExport = () => {
		const data = users.map((user) => ({
			'Display Name': user.display_name,
			Email: user.email,
			Role: user.role,
		}));

		const csvContent = Papa.unparse(data, {
			escapeFormulae: true,
		});

		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.setAttribute('download', `Secoda ${workspace.name} members.csv`);
		link.click();
	};

	const handleRemoveClick = useCallback((user: IUser) => {
		setUserToRemove(user);
	}, []);

	const handleRemoveClose = useCallback(() => {
		setUserToRemove(null);
	}, []);

	const handleImpersonate = useCallback(
		async (user: IUser) => {
			try {
				await impersonateUser({
					body: {
						user_id: user.id,
					},
				});
				clearTokens();
				await clearCache();
				window.location.replace('/');
			} catch (error) {
				showNotification({
					message: 'Failed to impersonate user',
					color: 'red',
				});
			}
		},
		[impersonateUser]
	);

	const handleImpersonateClick = useCallback(
		(user: IUser) => {
			openModal({
				id: 'impersonate-modal',
				title: `Impersonate ${user.display_name}?`,
				children: (
					<Stack>
						<Text size="sm">
							By impersonating {user.display_name}, you will be able to view and
							make changes as if you were the user.
						</Text>
						<Divider />
						<Group position="right">
							<Button
								variant="default"
								tone="default"
								onClick={() => closeModal('impersonate-modal')}
							>
								Cancel
							</Button>
							<Button
								variant="primary"
								loading={isSendingImpersonatingRequest}
								onClick={async () => {
									await handleImpersonate(user);
									closeModal('impersonate-modal');
								}}
							>
								Impersonate
							</Button>
						</Group>
					</Stack>
				),
			});
		},
		[handleImpersonate, isSendingImpersonatingRequest]
	);

	const roleFilterOptions = [
		'Members',
		'ADMIN',
		'EDITOR',
		'VIEWER',
		...(workspace.migrated_permission_v2 ? ['CUSTOM'] : []),
		'GUEST',
		'INVITED',
		'REMOVED',
	];

	return (
		<Stack spacing={'xl'}>
			<Guard v1AllowedRoles={[UserRole.ADMIN]} v2Permission="Users.Create">
				<Box mt={'xl'}>
					<SettingsButton
						title="Add members"
						description="Members can view and edit your workspace depending on their assigned roles"
						onClick={open}
						buttonText="Invite members"
					/>
				</Box>
			</Guard>
			<Title order={3} weight="semibold" size="md">
				<Group noWrap>
					<SearchBox placeholder="Search by name" onSearch={setSearch} />
					<Select
						data={roleFilterOptions.map((value) => ({
							label: capitalize(value),
							value,
						}))}
						value={selectedFilter}
						onChange={handleSelectedFilterChange}
					/>
					<Tooltip label="Export CSV file">
						<IconButton iconName="download" onClick={handleCsvExport} />
					</Tooltip>
				</Group>
			</Title>

			{users?.length === 0 && (
				<SettingsTableEmpty
					title="No users found."
					description="Add a user to get started."
				/>
			)}

			{users?.length > 0 && (
				<Table style={{ width: '100%', cursor: 'pointer' }} highlightOnHover>
					<tbody>
						{users.map((user) => (
							<tr
								key={user.id}
								data-testid={`membership-row-${user.id}-2y1sVvJp`}
							>
								<td
									aria-label="User"
									onClick={() => handleOpenUser(user.id)}
									width={theme.other.space[100]}
								>
									<Group>
										<UserAvatar user={user} size="sm" />
										<Stack spacing={0}>
											<TruncateText
												text={user.display_name}
												length={36}
												size="sm"
												weight="bold"
											/>
											<TruncateText text={user.email} length={34} size="xs" />
										</Stack>
									</Group>
								</td>
								{!user.disabled && (
									<td width={theme.other.space[40]}>
										{workspace.migrated_permission_v2 ? (
											<IamRoleSelector
												disabled={!canEditUsers || currentUser?.id === user.id}
												user={user}
											/>
										) : (
											<RoleSelect
												disabled={!canEditUsers || currentUser?.id === user.id}
												role={user.role as UserRole}
												selectRole={(role) => selectRole(user, role)}
											/>
										)}
									</td>
								)}
								{(user.disabled || user.pending) &&
									(user.pending && !user.disabled ? (
										<td width={theme.other.space[20]}>
											<Tooltip
												label={`${user.email} has a pending invitation `}
											>
												<Center>
													<Badge w="100%">Invited</Badge>
												</Center>
											</Tooltip>
										</td>
									) : (
										<td width={theme.other.space[26]}>
											<Tooltip
												label={`${user.display_name} has been removed from this workspace`}
											>
												<Center>
													<Badge w="100%">Removed</Badge>
												</Center>
											</Tooltip>
										</td>
									))}
								<Guard
									v1AllowedRoles={[UserRole.ADMIN]}
									v2Permission="Users.Delete"
								>
									<td aria-label="Actions" align="right">
										<Menu position="bottom-end">
											<Menu.Target>
												<IconButton iconName="dots" variant="tertiary" />
											</Menu.Target>
											<Menu.Dropdown>
												<Menu.Item
													onClick={() =>
														user.disabled
															? handleToggleActivation(user)
															: handleRemoveClick(user)
													}
												>
													{user.disabled ? 'Activate' : 'Remove'}
												</Menu.Item>
												{currentUser?.id !== user.id && !isImpersonating() && (
													<Menu.Item
														onClick={() => handleImpersonateClick(user)}
													>
														Impersonate
													</Menu.Item>
												)}
											</Menu.Dropdown>
										</Menu>
									</td>
								</Guard>
							</tr>
						))}
					</tbody>
				</Table>
			)}

			{userToRemove && (
				<RemoveMemberModal
					user={userToRemove}
					onClose={handleRemoveClose}
					onConfirm={handleToggleActivation}
				/>
			)}

			<InviteMembersModal
				opened={opened}
				onClose={close}
				onConfirm={handleInviteMembers}
			/>
		</Stack>
	);
}

function RemoveMemberModal({
	user,
	onClose,
	onConfirm,
}: RemoveMemberModalProps) {
	return (
		<Modal
			opened
			size="md"
			title={`Remove ${user.display_name}?`}
			onClose={onClose}
		>
			<Stack>
				<Text size="sm">
					Removing this user will revoke all their access to the workspace.
				</Text>
				<Divider />
				<Group position="right">
					<Button variant="default" tone="default" onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant="primary"
						tone="critical"
						onClick={() => onConfirm(user)}
					>
						Remove user
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
