import type { EventType } from './types';

export const APP_HEADER_HEIGHT = 58;
export const INBOX_TAB_HEIGHT = 42;

export const NOTIFICATION_EVENT_TYPES: EventType[] = [
	{
		label: 'Account Management',
		value: 'account_management',
		values: ['free_trial_ending'],
		iconName: 'adjustmentsCog',
	},
	{
		label: 'Announcement',
		value: 'announcement',
		values: ['announcement'],
		iconName: 'speakerphone',
	},
	{
		label: 'Metadata Push',
		value: 'metadata_push',
		values: ['metadata_push_job_completed', 'metadata_push_job_failed'],
		iconName: 'arrowBigUpLines',
	},
	{
		label: 'Monitoring Incidents',
		value: 'monitoring_incidents',
		values: ['monitor_incident_created', 'monitor_incident_resolved'],
		iconName: 'alertTriangle',
		isTask: true,
	},
	{
		label: 'Extraction',
		value: 'extraction',
		values: [
			'extraction_job_completed',
			'extraction_job_failed',
			'github_change_request',
		],
		iconName: 'arrowBigDownLines',
	},
	{
		label: 'Mentions',
		value: 'mentions',
		values: ['mentions'],
		iconName: 'at',
	},
	{
		label: 'Query Block',
		value: 'queryblock',
		values: ['queryblock_job_completed', 'queryblock_job_failed'],
		iconName: 'code',
	},
	{
		label: 'Question',
		value: 'question',
		values: [
			'question_owner_assigned',
			'question_answer',
			'question_new',
			'question_reply',
			'question_reply_edited',
			'question_reply_removed',
		],
		iconName: 'messageCircleQuestion',
	},
	{
		label: 'Request',
		value: 'request',
		values: [
			'applied_access_request',
			'applied_change_request',
			'access_request',
			'change_request',
		],
		iconName: 'eye',
	},
	{
		label: 'Resource',
		value: 'resource',
		values: [
			'resource_archived',
			'resource_collection_added',
			'resource_collection_removed',
			'resource_definition_updated',
			'resource_description_updated',
			'resource_owner_assigned',
			'resource_published',
			'resource_tag_added',
			'resource_tag_removed',
			'resource_unpublished',
		],
		iconName: 'file',
	},
	{
		label: 'Table',
		value: 'table',
		values: ['tables_dropped'],
		iconName: 'table',
	},
	{
		label: 'Schema',
		value: 'schema',
		values: ['table_schema_changed'],
		iconName: 'table',
	},

	{
		label: 'Team',
		value: 'team',
		values: ['joined_team'],
		iconName: 'users',
	},
	{
		label: 'Comment',
		value: 'comment',
		values: [
			'comment_new_thread',
			'comment_reply',
			'comment_mention',
			'comment_reaction',
			'comment_resolved',
		],
		iconName: 'message',
	},
	{
		label: 'Access Request',
		value: 'data_access_request',
		values: [
			'data_access_request_created',
			'data_access_request_approved',
			'data_access_request_approved_admin',
			'data_access_request_rejected',
			'data_access_request_cancelled',
		],
		iconName: 'lock',
		featureFlag: 'dataAccessRequest',
	},
];
