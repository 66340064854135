import type {
	ClassNames,
	CollapseProps,
	MantineStyleSystemProps,
} from '@mantine/core';
import {
	Box,
	Collapse,
	createStyles,
	Divider,
	Group,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { Icon, Text, Title } from '@repo/foundations';
import type React from 'react';
import { useMemo } from 'react';
import { useLocalStorageBooleanState } from '../../hooks/useLocalStorageBooleanState';

const useStyles = createStyles((theme) => ({
	root: {},
	collapsableWrapper: {
		padding: `0 ${theme.spacing.xl}`,
	},
	button: {
		padding: `${theme.spacing['3xs']} ${theme.spacing.xs}`,
		marginLeft: `-${theme.spacing.xs}`,
		borderRadius: theme.radius.md,
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
	},
	actions: {
		flexShrink: 0,
	},
	collapsedTextWrapper: {
		background: theme.other.getColor('surface/secondary/default'),
		paddingLeft: theme.other.space[1],
		paddingRight: theme.other.space[1],
		marginLeft: `${theme.spacing.sm}`,
		borderRadius: theme.radius.xs,
	},
	title: {
		fontSize: theme.other.typography.title.sm,
	},
}));

type CollapsableStackStylesNames =
	| 'root'
	| 'collapsableWrapper'
	| 'button'
	| 'actions'
	| 'collapsedTextWrapper'
	| 'title';

export interface ICollapsableStackProps extends MantineStyleSystemProps {
	groupName: string;
	defaultOpened?: boolean;
	children: React.ReactNode;
	collapseProps?: Omit<CollapseProps, 'in' | 'children'>;
	withDivider?: boolean;
	actions?: React.ReactNode;
	collapsedText?: string;
	alwaysShowCollapsedText?: boolean;
	className?: string;
	classNames?: ClassNames<CollapsableStackStylesNames>;
}

function CollapsableStack({
	groupName,
	children,
	defaultOpened = true,
	classNames: classNamesProp,
	className,
	collapseProps = {},
	withDivider = true,
	actions,
	collapsedText = '',
	alwaysShowCollapsedText = false,
	...others
}: ICollapsableStackProps) {
	const { classes, cx } = useStyles();

	const [open, { toggle }] = useLocalStorageBooleanState(
		groupName,
		defaultOpened
	);

	const ToggleIcon = open
		? () => <Icon name="chevronDown" color="icon/primary/default" />
		: () => <Icon name="chevronRight" color="icon/primary/default" />;

	const collapsedTextVisible =
		collapsedText && (!open || alwaysShowCollapsedText);

	const classNames = useMemo(
		() => ({
			root: cx(classes.root, classNamesProp?.root),
			collapsableWrapper: cx(
				classes.collapsableWrapper,
				classNamesProp?.collapsableWrapper
			),
			button: cx(classes.button, classNamesProp?.button),
			actions: cx(classes.actions, classNamesProp?.actions),
			collapsedTextWrapper: cx(
				classes.collapsedTextWrapper,
				classNamesProp?.collapsedTextWrapper
			),
			title: cx(classes.title, classNamesProp?.title),
		}),
		[classes, cx, classNamesProp]
	);

	return (
		<Stack className={cx(classNames.root, className)} {...others}>
			<Stack className={cx(classNames.collapsableWrapper)}>
				<Group position="apart" noWrap>
					<UnstyledButton className={classNames.button} onClick={toggle}>
						<Group spacing="4xs" noWrap>
							<Title weight="bold" className={cx(classNames.title)}>
								{groupName}
							</Title>
							{collapsedTextVisible && (
								<Box className={classNames.collapsedTextWrapper}>
									<Text className={cx(classNames.title)}>
										{collapsedText || ''}
									</Text>
								</Box>
							)}
							<ToggleIcon />
						</Group>
					</UnstyledButton>
					{actions && <Box className={classNames.actions}>{actions}</Box>}
				</Group>
				<Collapse in={Boolean(open)} {...collapseProps}>
					{children}
				</Collapse>
			</Stack>
			{withDivider && <Divider />}
		</Stack>
	);
}

export default CollapsableStack;
