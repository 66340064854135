import { Button } from '@repo/foundations';
import { closeAllModals, openModal } from '../ModalManager';
import { DataAccessRequestCreateModal } from './DataAccessRequestCreateModal/DataAccessRequestCreateModal';

export function AddRequestButton() {
	const handleOpenModal = () => {
		openModal({
			title: 'Request access',
			children: <DataAccessRequestCreateModal onClose={closeAllModals} />,
		});
	};

	return (
		<Button onClick={handleOpenModal} variant="primary">
			Create request
		</Button>
	);
}
