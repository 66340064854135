/* eslint-disable no-theme-colors/no-theme-colors */
import {
	Center,
	Checkbox,
	createStyles,
	Group,
	rem,
	UnstyledButton,
} from '@mantine/core';
import type { SpotlightActionProps } from '@mantine/spotlight';
import { Text } from '@repo/foundations';

import { SpotlightProvider } from '@mantine/spotlight';
import { observer } from 'mobx-react-lite';
import type { ReactNode } from 'react';
import { paletteStore } from './TableV2Spotlight.store';

const useStyles = createStyles((theme) => ({
	action: {
		position: 'relative',
		display: 'block',
		width: '100%',
		padding: `${rem(10)} ${rem(12)}`,
		borderRadius: theme.radius.sm,
		...theme.fn.hover({
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[4]
					: theme.colors.gray[1],
		}),

		'&[data-hovered]': {
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[4]
					: theme.colors.gray[1],
		},
	},
}));

export const Action = observer(
	({
		action,
		styles,
		classNames,
		hovered,
		onTrigger,
		...others
	}: SpotlightActionProps) => {
		const { classes } = useStyles(null as unknown as void, {
			styles,
			classNames,
			name: 'Spotlight',
		});

		const checked = paletteStore.selected.includes(action.id as string);

		const indeterminate =
			!checked &&
			paletteStore.selected.includes(`${action.id}::partial` as string);

		return (
			<UnstyledButton
				className={classes.action}
				data-hovered={hovered || undefined}
				tabIndex={-1}
				onClick={(event) => {
					event.preventDefault();
					event.stopPropagation();
					onTrigger();
				}}
				{...others}
			>
				<Group noWrap>
					<Checkbox
						indeterminate={indeterminate}
						checked={checked}
						onClick={(event) => {
							event.preventDefault();
							event.stopPropagation();
							onTrigger();
						}}
					/>

					{action.icon && <Center>{action.icon}</Center>}

					<div style={{ flex: 1 }}>
						<Text>{action.title}</Text>
					</div>
				</Group>
			</UnstyledButton>
		);
	}
);

export const TableV2Spotlight = observer(
	({ children }: { children: ReactNode }) => (
		<SpotlightProvider
			closeOnClickOutside
			withOverlay
			overlayProps={{
				opacity: 0.5,
				blur: 0,
			}}
			actions={paletteStore.actions ?? []}
			actionComponent={Action}
			searchPlaceholder="Search..."
			shortcut={null}
			closeOnActionTrigger={false}
		>
			{children}
		</SpotlightProvider>
	)
);
