import { Box, createStyles, Divider, Flex, Group, Stack } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { apiQueryKey, type Filter, type PolicyOut } from '@repo/api-codegen';
import { IconButton, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useCatalogList } from '../../../../api/hooks/resourceCatalog';
import entityDrawerStore from '../../../../components/EntityDrawer/store';

import { queryClient } from '../../../../api';
import { resourceCatalogQueryKeyFactory } from '../../../../api/hooks/resourceCatalog/constants';
import SearchBox from '../../../../components/SearchBox/SearchBox';
import FilterGroup from '../../../PoliciesCreatePage/components/FilterGroup';
import { ResourceDisplay } from '../../../PoliciesListPage/render';
import { getCountDisplay } from '../../../PoliciesListPage/utils';
import PolicySection from '../../PolicySection';
import InlineFramework from './InlineFramework';
import InlineOwners from './InlineOwners';
import InlineSeverity from './InlineServerity';
import InlineStatus from './InlineStatus';

const useStyles = createStyles((theme) => ({
	row: {
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		padding: theme.spacing.xs,
		paddingLeft: theme.spacing.sm,
	},
}));

interface PolicyOverviewProps {
	policy: PolicyOut;
}

const PolicyOverview = observer(({ policy }: PolicyOverviewProps) => {
	const { classes } = useStyles();

	const [search, setSearch] = useState('');
	const [debouncedSearch] = useDebouncedValue(search, 100);

	const [page, setPage] = useState(1);

	const { data, isLoading } = useCatalogList({
		filters: {
			filter: {
				operator: 'and',
				operands: policy.resource_filters,
			} as Filter,
			search_term: debouncedSearch,
		},
		page,
	});

	const handleResourceClick = useCallback(
		(id: string) => {
			entityDrawerStore.openEntityDrawerById(true, id, undefined, () => {
				queryClient.invalidateQueries({
					queryKey: resourceCatalogQueryKeyFactory.allLists(),
				});
				queryClient.invalidateQueries({
					queryKey: apiQueryKey('policy/{policy_id}/issues', {
						policy_id: policy.id,
					}),
				});
			});
		},
		[policy.id]
	);

	return (
		<Stack pt="lg">
			<Stack>
				<InlineFramework policy={policy} />
				<InlineStatus policy={policy} />
				<InlineSeverity policy={policy} />
				<InlineOwners policy={policy} />
			</Stack>
			<Divider />
			<PolicySection
				title="Conditions"
				content={
					<Stack p="sm" w="100%" spacing="xs">
						{policy.policy_filters?.map((condition, idx) => (
							<FilterGroup
								key="idx"
								condition={condition}
								onConditionChange={() => {}}
								onConditionDelete={() => {}}
								readOnly
								groupText="Require that "
							/>
						))}
					</Stack>
				}
			/>
			<Divider />
			<PolicySection
				title="Governed resources"
				content={
					<>
						<Stack p="sm" w="100%">
							<Group spacing="2xs">
								{policy.resource_filters?.map((condition) => (
									<FilterGroup
										key="idx"
										condition={condition}
										onConditionChange={() => {}}
										onConditionDelete={() => {}}
										readOnly
										addFilterLabelText="Add condition"
										isResource
									/>
								))}
							</Group>
							<SearchBox
								variant="tertiary"
								placeholder="Search resources"
								onSearch={setSearch}
							/>
						</Stack>
						<Divider />
						<Flex h="50vh" direction="column" sx={{ overflowY: 'auto' }}>
							{data?.results.map((result) => (
								<Box
									key={result.id}
									className={classes.row}
									onClick={() => handleResourceClick(result.id)}
								>
									<ResourceDisplay record={result} />
								</Box>
							))}
						</Flex>
						<Divider />
					</>
				}
				footer={
					<Group position="apart" p="xs" noWrap>
						<Text color="text/secondary/default" size="xs">
							{isLoading
								? 'Loading...'
								: `${(page - 1) * 50 + 1}-${Math.min(page * 50, data?.count ?? 0)} of ${getCountDisplay(data?.count)} results`}
						</Text>
						<Group spacing="xs">
							<IconButton
								disabled={!data?.meta.previous_page}
								variant="tertiary"
								iconName="chevronLeft"
								onClick={() => setPage(page - 1)}
							/>
							<IconButton
								disabled={!data?.meta.next_page}
								variant="tertiary"
								iconName="chevronRight"
								onClick={() => setPage(page + 1)}
							/>
						</Group>
					</Group>
				}
			/>
		</Stack>
	);
});

PolicyOverview.displayName = 'PolicyOverview';
export default PolicyOverview;
