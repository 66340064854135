import { Code, Group } from '@mantine/core';
import type { AccessControlEntityOut } from '@repo/api-codegen';
import { useMemo } from 'react';
import type { IBaseModel } from '../../api';
import type { ExtendedDataTableColumn } from '../TableV2/types';

export const useColumns = (): ExtendedDataTableColumn<
	AccessControlEntityOut & IBaseModel
>[] => {
	const columns: ExtendedDataTableColumn<
		AccessControlEntityOut & IBaseModel
	>[] = useMemo(
		() => [
			{
				accessor: 'grantee.name',
				title: 'Role',
			},
			{
				accessor: 'privileges',
				title: 'Privileges',
				render: (record) => (
					<Group spacing="xs">
						{(record.privileges ?? []).map((privilege) => (
							<Code key={privilege}>{privilege}</Code>
						))}
					</Group>
				),
			},
			{
				accessor: 'num_grantees',
				title: 'Grantees',
			},
		],
		[]
	);

	return columns;
};
