import { Group, Loader } from '@mantine/core';
import {
	queryKeyFn,
	useGetExcludedFromAiGovernance,
	useGetIncludedInAiGovernance,
	useSetExcludedFromAiGovernance,
	useSetIncludedInAiGovernance,
} from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { queryClient } from '../../../api';
import { GovernanceFilters } from './GovernanceFilters';

export function AIGovernanceFilters() {
	const { data: includedFilters, isLoading: isLoadingIncluded } =
		useGetIncludedInAiGovernance({});
	const { mutateAsync: updateIncludedFilters } = useSetIncludedInAiGovernance({
		onSuccess: (_, variables) => {
			// optimistic update
			queryClient.setQueryData(
				queryKeyFn({
					path: '/ai/preferences/governance/included/',
					operationId: 'getIncludedInAiGovernance',
					variables: {},
				}),
				variables.body
			);
		},
	});
	const { data: excludedFilters, isLoading: isLoadingExcluded } =
		useGetExcludedFromAiGovernance({});
	const { mutateAsync: updateExcludedFilters } = useSetExcludedFromAiGovernance(
		{
			onSuccess: (_, variables) => {
				// optimistic update
				queryClient.setQueryData(
					queryKeyFn({
						path: '/ai/preferences/governance/excluded/',
						operationId: 'getExcludedFromAiGovernance',
						variables: {},
					}),
					variables.body
				);
			},
		}
	);

	if (isLoadingIncluded || isLoadingExcluded) {
		return (
			<Group position="center">
				<Loader />
			</Group>
		);
	}

	return (
		<GovernanceFilters
			title="Resource access"
			description="Create rules to exclude resources from Secoda AI. These settings are applied to all users"
			emptyState={
				<Text size="xs" color="text/secondary/default">
					Currently, the AI assistant can access all resources in your workspace
					(subject to permissions of the user asking the question). To limit the
					resources, add Resource Access filters.
				</Text>
			}
			includedFilters={includedFilters}
			excludedFilters={excludedFilters}
			onIncludedFiltersChange={(filters) =>
				updateIncludedFilters({ body: filters })
			}
			onExcludedFiltersChange={(filters) =>
				updateExcludedFilters({ body: filters })
			}
		/>
	);
}
