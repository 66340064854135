import { Group, Menu } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
	apiQueryKey,
	useCancelAccessRequest,
	useDeleteAccessRequest,
	useGetAccessRequest,
	useRejectAccessRequest,
} from '@repo/api-codegen';
import { UserRole } from '@repo/common/enums/UserRole';
import type { ButtonProps } from '@repo/foundations';
import { Button, IconButton } from '@repo/foundations';
import { useCallback } from 'react';
import { queryClient, useAuthUser } from '../../../api';
import { useCheckIAMPermission } from '../../../utils/authorization/roles';
import { closeAllModals, openModal } from '../../ModalManager/events';
import { DataAccessRequestApproveModal } from '../DataAccessRequestApproveModal/DataAccessRequestApproveModal';
import { DataAccessRequestCreateModal } from '../DataAccessRequestCreateModal/DataAccessRequestCreateModal';
import { DataAccessRequestControlsSkeleton } from './DataAccessRequestControlsSkeleton';

export interface DataAccessRequestControlsProps {
	requestId: string;
}

export function DataAccessRequestControls({
	requestId,
}: DataAccessRequestControlsProps) {
	const { user: currentUser } = useAuthUser();
	const { data: dataAccessRequest, isLoading } = useGetAccessRequest({
		pathParams: {
			requestId,
		},
	});

	const { mutate: rejectAccessRequest } = useRejectAccessRequest({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: apiQueryKey('integration/data-access-requests'),
			});
			showNotification({
				title: 'Access request rejected',
				message: 'The request has been rejected',
				color: 'red',
			});
		},
	});

	const { mutate: cancelAccessRequest } = useCancelAccessRequest({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: apiQueryKey('integration/data-access-requests'),
			});
			showNotification({
				title: 'Access request revoked',
				message: 'The request has been revoked',
				color: 'red',
			});
		},
	});

	const { mutate: deleteAccessRequest } = useDeleteAccessRequest({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: apiQueryKey('integration/data-access-requests'),
			});
			queryClient.invalidateQueries({
				queryKey: apiQueryKey('integration/data-access-requests/{request_id}', {
					request_id: requestId,
				}),
			});
		},
	});

	const handleDenyAccess = useCallback(() => {
		rejectAccessRequest({
			pathParams: {
				requestId,
			},
			body: {},
		});
	}, [rejectAccessRequest, requestId]);

	const handleApproveAccess = useCallback(() => {
		if (!dataAccessRequest) {
			return;
		}

		openModal({
			title: 'Approve access request',
			size: 480,
			children: (
				<DataAccessRequestApproveModal
					request={dataAccessRequest}
					onClose={closeAllModals}
				/>
			),
		});
	}, [dataAccessRequest]);

	const handleDeleteRequest = useCallback(() => {
		deleteAccessRequest({
			pathParams: {
				requestId,
			},
		});
	}, [deleteAccessRequest, requestId]);

	const handleCancelAccess = useCallback(() => {
		cancelAccessRequest({
			pathParams: {
				requestId,
			},
			body: {},
		});
	}, [cancelAccessRequest, requestId]);

	const handleEditRequest = useCallback(() => {
		openModal({
			title: 'Edit access request',
			size: 480,
			children: (
				<DataAccessRequestCreateModal
					request={{
						id: dataAccessRequest?.id,
						integration_id: dataAccessRequest?.integration_id ?? undefined,
						requested_resources: dataAccessRequest?.requested_resources,
						requested_expires_at: dataAccessRequest?.requested_expires_at,
						requested_text: dataAccessRequest?.requested_text,
					}}
					onClose={closeAllModals}
				/>
			),
		});
	}, [dataAccessRequest]);

	const { hasPermission: isAccessRequestsAdmin } = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Workspace.Manage',
	});

	if (isLoading || !dataAccessRequest) {
		return <DataAccessRequestControlsSkeleton />;
	}

	const controls: Array<{
		label: string;
		show: boolean;
		canCollapse: boolean;
		onClick: () => void;
		variant: ButtonProps['variant'];
	}> = [
		{
			label: 'Edit',
			show:
				currentUser?.id === dataAccessRequest?.created_by?.id &&
				dataAccessRequest?.status === 'pending',
			canCollapse: true,
			onClick: handleEditRequest,
			variant: 'default',
		},
		{
			label: 'Delete',
			show:
				currentUser?.id === dataAccessRequest?.created_by?.id &&
				dataAccessRequest?.status === 'pending',
			canCollapse: true,
			onClick: handleDeleteRequest,
			variant: 'default',
		},
		{
			label: 'Deny access',
			show: isAccessRequestsAdmin && dataAccessRequest?.status === 'pending',
			canCollapse: false,
			onClick: handleDenyAccess,
			variant: 'default',
		},
		{
			label: 'Approve access',
			show: isAccessRequestsAdmin && dataAccessRequest?.status === 'pending',
			canCollapse: false,
			onClick: handleApproveAccess,
			variant: 'primary',
		},
		{
			label: 'Revoke access',
			show: dataAccessRequest?.status === 'approved',
			canCollapse: false,
			onClick: handleCancelAccess,
			variant: 'primary',
		},
	];

	const controlsToShow = controls.filter((control) => control.show);
	const shouldCollapse =
		controlsToShow.length > 2 &&
		controlsToShow.filter((control) => control.canCollapse).length > 1;

	return (
		<Group spacing="xs">
			{shouldCollapse && (
				<Menu>
					<Menu.Target>
						<IconButton iconName="dots" variant="tertiary" />
					</Menu.Target>
					<Menu.Dropdown>
						{controlsToShow
							.filter((control) => control.canCollapse)
							.map((control) => (
								<Menu.Item key={control.label} onClick={control.onClick}>
									{control.label}
								</Menu.Item>
							))}
					</Menu.Dropdown>
				</Menu>
			)}
			{controlsToShow
				.filter((control) => !shouldCollapse || !control.canCollapse)
				.map((control) => (
					<Button
						key={control.label}
						variant={control.variant}
						onClick={control.onClick}
					>
						{control.label}
					</Button>
				))}
		</Group>
	);
}
