import { useMutation } from '@tanstack/react-query';
import { BackgroundJob } from '../../../lib/models';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import type { Automation } from '../../types';
import { automationQueryKeyFactory } from './constants';

interface RequestData {
	data: {
		automationId: string;
	};
	signal?: AbortSignal;
}

export function useAutomationRun() {
	return useMutation({
		mutationFn: async ({ data, signal }: RequestData) => {
			const url = getEndpoints([
				...automationQueryKeyFactory.namespace,
				'run',
			]).root();

			const { data: responseData } = await apiClient.post<Automation>(
				url,
				{
					automations: [data.automationId],
				},
				{
					signal,
				}
			);

			const job = new BackgroundJob({ id: responseData.id });
			await job.sync();

			return job;
		},
		onSettled: async (_, __, variables) => {
			const { automationId } = variables.data;
			await queryClient.invalidateQueries({
				queryKey: automationQueryKeyFactory.byId(automationId),
			});
			await queryClient.invalidateQueries({
				queryKey: automationQueryKeyFactory.allLists(),
			});
		},
	});
}
