import type { IApiListResponse, IBaseModel } from '../types';

export async function toArray(
	asyncIterator: AsyncGenerator<IApiListResponse<IBaseModel>, void, unknown>
) {
	const arr: IBaseModel[] = [];

	while (true) {
		// eslint-disable-next-line no-await-in-loop
		const { done, value } = await asyncIterator.next();
		if (done) break;
		arr.push(...value.results);
	}

	return arr;
}
