import { Box, createStyles, Flex, Skeleton, Stack, Tabs } from '@mantine/core';
import type { PrimitivePermissionType } from '@repo/api-codegen';
import { useApiGetMyPermissions } from '@repo/api-codegen';
import { SettingsTitle } from '@repo/common/components';
import { UserRole } from '@repo/common/enums/UserRole';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Button, Icon, Text } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import { useMemo, type CSSProperties } from 'react';
import { useParams } from 'react-router-dom';
import type { IWorkspace } from '../../api';
import { useAuthUser } from '../../api';
import { CreateTab } from '../../pages/IntegrationsBrowsePage/CreateTab';
import type { FeatureFlags } from '../../utils/featureFlags';
import { useFeatureFlags } from '../../utils/featureFlags';
import { AuditLogWrapper } from '../AuditLog';
import { AIToolsSettings } from './AIToolsSettings';
import { AIViewerSettings } from './AIViewerSettings';
import { APISettings } from './APISettings/APISettings';
import AppearanceSettings from './AppearanceSettings';
import { BillingSettings } from './BillingSettings';
import CatalogDefaultsSettings from './CatalogDefaultsSettings';
import { ChromeExtensionSettings } from './ChromeExtensionSettings/ChromeExtensionSettings';
import { DataSettings } from './DataSettings';
import { ExtensionSettings } from './ExtensionSettings/ExtensionSettings';
import { ImportSettings } from './ImportSettings';
import { MemberSettings } from './MemberSettings/MemberSettings';
import { NotificationSettings } from './NotificationSettings';
import { Preferences } from './Preferences';
import { ProfileSettings } from './ProfileSettings';
import { PublishSettings } from './PublishSettings';
import { QualityScoreSettings } from './QualityScoreSettings';
import { SecuritySettings } from './SecuritySettings';
import { TagSettings } from './TagSettings/TagSettings';
import { WorkspaceSettings } from './WorkspaceSettings';

interface TabType {
	name: string;
	id: string;
	title?: string;
	sidebarTitle?: string;
	description?: string;
	documentationLink?: string;
	allowedRoles?: UserRole[];
	v2AllowedPermissions?: PrimitivePermissionType[];
	v2DeniedPermissions?: PrimitivePermissionType[];
	featureFlag?: keyof FeatureFlags;
	component: React.JSXElementConstructor<unknown>;
}

const getNotificationTabName = (subtab?: string) => {
	if (subtab === 'app') return 'App';
	if (subtab === 'email') return 'Email';
	if (subtab === 'slack') return 'Slack';
	return 'Notifications';
};

const profileTabs = (subtab?: string): Array<TabType> => [
	{
		name: 'Preferences',
		id: 'preferences',
		description: 'Configure your preferences and settings',
		component: Preferences,
		featureFlag: 'darkMode',
	},
	{
		name: 'Profile',
		id: 'profile',
		description: 'Configure your personal account settings and preferences',
		component: ProfileSettings,
	},
	{
		name: getNotificationTabName(subtab),
		sidebarTitle: 'Notifications',
		id: 'notifications',
		description: 'Configure your notification preferences and alert settings',
		component: NotificationSettings,
	},
	{
		name: 'Google Chrome extension',
		id: 'chrome-extension',
		description:
			'Access your data directly from Chrome with the browser extension',
		component: ChromeExtensionSettings,
	},
];

const workspaceTabs = (workspace: IWorkspace): Array<TabType> => [
	{
		name: 'Workspace',
		id: 'workspace',
		sidebarTitle: 'General',
		title: 'Workspace settings',
		description:
			'Configure core workspace settings and organization preferences',
		allowedRoles: [UserRole.ADMIN],
		v2AllowedPermissions: ['Workspace.Write'],
		component: WorkspaceSettings,
	},
	{
		name: 'Properties',
		id: 'properties',
		description:
			'Define and manage catalog properties and metadata configuration',
		allowedRoles: [UserRole.ADMIN],
		v2AllowedPermissions: ['Properties.Update', 'Properties.Create'],
		component: CatalogDefaultsSettings,
	},
	{
		name: 'Security',
		id: 'security',
		title: 'Security',
		sidebarTitle: 'Security',
		v2AllowedPermissions: ['Workspace.Write'],
		description:
			'Security settings for your workspace. SAML is only available on the Enterprise plan',
		documentationLink: 'https://docs.secoda.co/saml',
		allowedRoles: [UserRole.ADMIN],
		component: SecuritySettings,
	},
	{
		name: 'Appearance',
		id: 'appearance',
		description: 'Customize the appearance of your workspace',
		allowedRoles: [UserRole.ADMIN],
		v2AllowedPermissions: ['Workspace.Write'],
		component: AppearanceSettings,
	},
	{
		name: workspace.migrated_permission_v2
			? 'Members and permissions'
			: 'Members',
		id: 'members',
		description:
			'Manage workspace members, configure user groups, and customize role-based permissions',
		allowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		v2AllowedPermissions: [
			'Groups.Create',
			'Groups.Delete',
			'Groups.Update',
			'Users.Create',
			'Users.Delete',
			'Users.Update',
			'Roles.Create',
			'Roles.Delete',
			'Roles.Update',
		],
		component: MemberSettings,
	},
	{
		name: 'Data',
		id: 'data',
		description: 'Configure data management settings and storage preferences',
		allowedRoles: [UserRole.ADMIN],
		v2AllowedPermissions: ['Workspace.Write'],
		component: DataSettings,
	},
	{
		name: 'AI',
		id: 'ai',
		title: 'AI',
		description:
			'Configure AI capabilities and customize machine learning preferences',
		documentationLink: 'https://docs.secoda.co/features/ai-assistant',
		allowedRoles: [UserRole.ADMIN],
		v2AllowedPermissions: ['SecodaAI.Write'],
		component: AIToolsSettings,
	},
	{
		name: 'AI',
		id: 'ai-memory',
		title: 'AI',
		description: 'Manage your Secoda AI preferences',
		allowedRoles: [UserRole.VIEWER, UserRole.EDITOR],
		v2AllowedPermissions: ['SecodaAI.Read'],
		v2DeniedPermissions: ['SecodaAI.Write'],
		component: AIViewerSettings,
		featureFlag: 'aiMemory',
	},
	{
		name: 'Data quality score',
		id: 'quality',
		title: 'Data quality score',
		description: 'Understand and improve your data quality',
		documentationLink: 'https://docs.secoda.co/features/data-quality-score',
		allowedRoles: [UserRole.ADMIN],
		v2AllowedPermissions: ['DQS.Write'],
		component: QualityScoreSettings,
	},
	{
		name: 'Import',
		id: 'import',
		title: 'Import and export',
		sidebarTitle: 'Import and export',
		description: 'Manage data import/export operations and bulk data transfers',
		v2AllowedPermissions: ['ImportExports.Write', 'ImportExports.Read'],
		documentationLink:
			'https://docs.secoda.co/features/data-management/import-and-export-data',
		allowedRoles: [UserRole.ADMIN],
		component: ImportSettings,
	},
	{
		name: 'Publishing',
		id: 'publish',
		description: 'Publish resources from your workspace',
		v2AllowedPermissions: ['Workspace.Write'],
		allowedRoles: [UserRole.ADMIN],
		component: PublishSettings,
	},
	{
		name: 'Billing',
		id: 'billing',
		v2AllowedPermissions: ['Billing.Write', 'Billing.Read'],
		description:
			'Workspace billing history and additional details will show up here',
		allowedRoles: [UserRole.ADMIN],
		component: BillingSettings,
	},
	{
		name: 'API',
		id: 'api',
		title: 'API access',
		description:
			'Manage API credentials and access tokens for programmatic integration',
		v2AllowedPermissions: ['APIKeys.Read', 'APIKeys.Write'],
		documentationLink: 'https://docs.secoda.co/secoda-api',
		allowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		component: APISettings,
	},
	{
		name: 'Tags',
		id: 'tags',
		description:
			'Create and manage resource tags for enhanced organization and searchability',
		v2AllowedPermissions: ['Tags.Create', 'Tags.Delete', 'Tags.Update'],
		allowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		component: TagSettings,
	},
	{
		name: 'Audit log',
		id: 'audit-log',
		description: 'Review detailed workspace activity and security audit trails',
		allowedRoles: [UserRole.ADMIN],
		v2AllowedPermissions: ['Workspace.Write'],
		component: AuditLogWrapper,
	},
	{
		name: 'Marketplace',
		id: 'marketplace-integrations',
		v2AllowedPermissions: ['Integrations.Create'],
		description: 'Develop and publish custom integrations using the Secoda SDK',
		documentationLink:
			'https://docs.secoda.co/integrations/custom-integrations-and-marketplace/secoda-sdk-custom-integration',
		allowedRoles: [UserRole.ADMIN],
		component: CreateTab,
	},
	{
		name: 'Extensions',
		id: 'extensions',
		v2AllowedPermissions: ['Integrations.Create'],
		description:
			'Manage your productivity, communication, and collaboration extensions',
		documentationLink: 'https://docs.secoda.co/extensions',
		component: ExtensionSettings,
		allowedRoles: [UserRole.ADMIN],
	},
];

const useStyles = createStyles(
	(theme, { isFullWidth }: { isFullWidth: boolean }) => ({
		settingsPanel: {
			maxWidth: isFullWidth ? '100%' : theme.other.space[160],
			margin: '0 auto',
		},
	})
);

function TabGroupTitle(props: {
	title: string;
	icon: JSX.Element;
	style?: CSSProperties;
}) {
	const { title, style, icon } = props;
	return (
		<Flex style={style ?? {}} mb="10px" align="center" gap="5px">
			{icon}
			<Text size="sm" weight="semibold">
				{title}
			</Text>
		</Flex>
	);
}

export default function SettingsPage() {
	const navigate = useNavigate();
	const { tab: browserPath, subtab } = useParams();
	const { workspace } = useAuthUser();
	const { user } = useAuthUser();
	const featureFlags = useFeatureFlags();
	const { data: userPermissions } = useApiGetMyPermissions({});

	const filteredTabs = useMemo(() => {
		function filterTabs(tabs: TabType[]) {
			return tabs.filter((tab) => {
				if (tab.featureFlag && !featureFlags[tab.featureFlag]) {
					return false;
				}

				if (workspace.migrated_permission_v2) {
					if (tab.v2AllowedPermissions) {
						if (
							tab.v2DeniedPermissions &&
							tab.v2DeniedPermissions.some((permission) =>
								userPermissions?.includes(permission)
							)
						) {
							return false;
						}

						return tab.v2AllowedPermissions.some((permission) =>
							userPermissions?.includes(permission)
						);
					}
				} else {
					if (tab.allowedRoles) {
						return user ? tab.allowedRoles.includes(user.role) : false;
					}
				}

				return true;
			});
		}
		return {
			profileTabs: filterTabs(profileTabs(subtab)),
			workspaceTabs: filterTabs(workspaceTabs(workspace)),
		};
	}, [featureFlags, user, userPermissions, workspace, subtab]);

	const tabs = [...filteredTabs.profileTabs, ...filteredTabs.workspaceTabs];
	const currentTab = browserPath
		? tabs.find((tab) => tab.id === browserPath)
		: filteredTabs.profileTabs[0];

	const isFullWidth = useMemo(
		() => currentTab?.id === 'audit-log' || currentTab?.id === 'members',
		[currentTab]
	);

	const { classes } = useStyles({ isFullWidth });

	if (!userPermissions) {
		return (
			<Box p="lg" style={{ display: 'flex' }}>
				<Box mr={space[4]} w={space[60]}>
					{[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
						<Skeleton key={i} height={36} mb={8} />
					))}
				</Box>

				<Box sx={{ flex: 1 }}>
					<Skeleton height={40} width={200} mb={15} />
					<Skeleton height={20} width="80%" mb={35} />
					<Stack>
						{[1, 2, 3].map((i) => (
							<Skeleton key={i} height={50} />
						))}
					</Stack>
				</Box>
			</Box>
		);
	}

	if (!currentTab) {
		navigate('/403');
		return null;
	}

	return (
		<Box p="lg">
			<Tabs
				value={currentTab.id}
				onTabChange={(tabId) => navigate(`/settings/${tabId}`)}
				orientation="vertical"
				variant="pills"
			>
				<Tabs.List mr={space[4]} px={space[2]} w={space[60]}>
					<TabGroupTitle
						icon={<Icon name="userCircle" color="icon/primary/default" />}
						title="Account"
					/>
					{filteredTabs.profileTabs.map((tab) => (
						<Tabs.Tab key={tab.id} value={tab.id}>
							<Text size="sm" weight="semibold">
								{tab.sidebarTitle ?? tab.name}
							</Text>
						</Tabs.Tab>
					))}
					{filteredTabs.workspaceTabs.length > 0 && (
						<>
							<TabGroupTitle
								icon={<Icon name="building" color="icon/primary/default" />}
								style={{ marginTop: '30px' }}
								title="Workspace"
							/>
							{filteredTabs.workspaceTabs.map((tab) => (
								<Tabs.Tab key={tab.id} id={tab.id} value={tab.id}>
									<Text size="sm" weight="semibold">
										{tab.sidebarTitle ?? tab.name}
									</Text>
								</Tabs.Tab>
							))}
						</>
					)}
				</Tabs.List>

				{tabs.map((tab) => {
					const title = tab.title ?? tab.name;
					const TabElement = tab.component;

					return (
						<Tabs.Panel
							key={tab.id}
							id={`panel-${tab.name}`}
							value={tab.id}
							className={classes.settingsPanel}
						>
							<Box>
								<Flex justify="space-between" align="center" mb="xl">
									<SettingsTitle>{title}</SettingsTitle>
									{tab.documentationLink && (
										<Button
											component="a"
											href={tab.documentationLink}
											target="_blank"
											variant="tertiary"
											size="sm"
											rightIconName="externalLink"
										>
											Documentation
										</Button>
									)}
								</Flex>
							</Box>
							<TabElement />
						</Tabs.Panel>
					);
				})}
			</Tabs>
		</Box>
	);
}
