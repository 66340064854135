import { useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import {
	NOTIFICATION_UNREAD_COUNT_NAMESPACE,
	notificationUnreadCountQueryKeyFactory,
} from './constants';

export function useUnreadNotificationCount() {
	const queryKey = notificationUnreadCountQueryKeyFactory.all();
	const queryFn = async () => {
		const url = getEndpoints(NOTIFICATION_UNREAD_COUNT_NAMESPACE).root();
		const { data } = await apiClient.get(url, {});
		return data;
	};

	return useQuery({
		queryKey,
		queryFn,
		select: (response: { count: number }) => response.count,
		refetchIntervalInBackground: false,
		refetchInterval: 1000 * 60 * 10, // 10 minutes.
	});
}
