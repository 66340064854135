import { Menu, Stack, createStyles } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Button, Title } from '@repo/foundations';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '../../components/SecodaAppShell/SecodaNavBar';
import { TableV2 } from '../../components/TableV2';
import { useActions, useColumns, useWorkspaceQueryList } from './hooks';

const PAGINATION_LIST_OPTIONS = {
	suspense: true,
};

const useStyles = createStyles((theme) => ({
	content: {
		marginLeft: theme.spacing['2xl'],
		marginRight: theme.spacing['2xl'],
		width: `calc(100% - (2 * ${theme.spacing['2xl']}))`,
		flexGrow: 1,
	},
}));

function WorkspaceQueriesPage() {
	const navigate = useNavigate();

	const columns = useColumns();
	const actions = useActions();

	const [lookBackDays, setLookBackDays] = React.useState(30);
	const { classes } = useStyles();

	const workspaceQueryList = useWorkspaceQueryList(lookBackDays);

	const handleSetLookbackDays = (days: number) => () => {
		setLookBackDays(days);
	};

	const onCellClick = useCallback(
		(cell) => {
			if (cell.column.navigate !== false) {
				navigate(`/query/${cell.record.id}`);
			}
		},
		[navigate]
	);

	return (
		<Stack spacing={0} style={{ flexGrow: 1 }}>
			<Helmet>
				<title>Workspace Queries</title>
			</Helmet>
			<NavBarWrapper>
				<NavBarBreadcrumbWrapper>
					<Title
						order={1}
						size="sm"
						data-testid="workspace-queries-nav-bar-title"
					>
						Queries
					</Title>
				</NavBarBreadcrumbWrapper>
				<NavBarRightSideWrapper>
					<Menu>
						<Menu.Target>
							<Button leftIconName="calendar" variant="primary">
								Last {lookBackDays} days
							</Button>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item onClick={handleSetLookbackDays(1)}>Today</Menu.Item>
							<Menu.Item onClick={handleSetLookbackDays(7)}>7 days</Menu.Item>
							<Menu.Item onClick={handleSetLookbackDays(30)}>30 days</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</NavBarRightSideWrapper>
			</NavBarWrapper>
			<Stack className={classes.content}>
				<TableV2
					pluralTypeString="queries"
					// @ts-ignore
					columns={columns}
					// @ts-ignore
					usePaginationList={workspaceQueryList}
					withActions={actions}
					withCheckbox={false}
					withStickyColumn={false}
					withInteractiveHeader
					withSearch
					withSort={false}
					defaultSort={null}
					onCellClick={onCellClick}
					usePaginationListOptions={PAGINATION_LIST_OPTIONS}
				/>
			</Stack>
		</Stack>
	);
}

export default WorkspaceQueriesPage;
