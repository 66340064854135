import { Checkbox, Dialog, Group, createStyles } from '@mantine/core';
import { spotlight } from '@mantine/spotlight';
import type { IBaseModel } from '@repo/common/models/baseModel';
import { Button, Text } from '@repo/foundations';
import { useKeyPress } from 'ahooks';
import { filter, isBoolean, noop } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useAuthUser } from '../../api/hooks/authUser';
import { openSpotlight } from '../Spotlight';
import type { ICommandListItem } from '../Spotlight/components/CommandPalette/constants';
import { closeSpotlight } from '../Spotlight/events';

const useStyles = createStyles((theme) => ({
	root: {
		padding: 0,
		position: 'fixed',
		bottom: '1.5rem',
		left: '50%',
		minHeight: 'auto',
		borderRadius: theme.radius.md,
		transform: 'translateX(-50%) scale(1) !important',
		minWidth: 'max-content',
		width: 'unset',
	},
}));

function ActionButton<T extends IBaseModel>({
	action,
	selectedRecordsState,
	handleCloseDialog,
	setSelectedRecordsState,
}: {
	action: ICommandListItem<T>;
	selectedRecordsState: {
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	};
	handleCloseDialog: () => void;
	setSelectedRecordsState: (state: {
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	}) => void;
}) {
	const [isDisabled, setIsDisabled] = useState(false);

	const handleClick = useCallback(async () => {
		setIsDisabled(true);
		await action.onClick?.(selectedRecordsState.selectedRecords, () => {
			handleCloseDialog();
			setSelectedRecordsState({
				selectedRecords: [],
				lastSelectedIndex: null,
			});
		});
		setIsDisabled(false);
	}, [
		action,
		selectedRecordsState.selectedRecords,
		handleCloseDialog,
		setSelectedRecordsState,
	]);

	return (
		<Button
			disabled={isDisabled}
			key={action.id}
			leftIconName={action.iconName ?? undefined}
			onClick={handleClick}
		>
			{action.title}
		</Button>
	);
}

interface TableV2DialogProps<T extends IBaseModel> {
	count: number;
	totalCount: number;
	withQuickActions?: readonly string[];
	actions: ICommandListItem<T>[];
	selectedRecordsState: {
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	};
	results: T[];
	setSelectedRecordsState: (state: {
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	}) => void;
}

export function TableV2Dialog<T extends IBaseModel>({
	count,
	totalCount,
	withQuickActions,
	actions,
	selectedRecordsState,
	results,
	setSelectedRecordsState,
}: TableV2DialogProps<T>) {
	const { classes, theme } = useStyles();
	const { isViewerOrGuestUser } = useAuthUser();

	const actionsToShow = useMemo(() => {
		const selected = selectedRecordsState.selectedRecords.filter((record) =>
			results?.some((result) => result.id === record.id)
		) as unknown as IBaseModel[];

		const finalActions = actions.map((action) => ({
			...action,
			selected,
			show: isBoolean(action.show) ? action.show : action.show(selected as T[]),
			onClick: async () => {
				await action?.onClick?.(selected as T[], () =>
					setSelectedRecordsState({
						selectedRecords: [],
						lastSelectedIndex: null,
					})
				);
			},
		}));

		return filter(finalActions, (action) => action.show);
	}, [selectedRecordsState, actions, results, setSelectedRecordsState]);

	const activeActions = useMemo(
		() =>
			actions.filter(
				(action) =>
					withQuickActions?.includes(action.id) &&
					(typeof action.show === 'function'
						? action.show(selectedRecordsState.selectedRecords)
						: action.show)
			),
		[selectedRecordsState.selectedRecords, actions, withQuickActions]
	);

	const handleOpenActions = useCallback(() => {
		openSpotlight({
			type: 'bulkActions',
			props: {
				actions: actionsToShow,
			},
		});
	}, [actionsToShow]);

	const handleCloseDialog = useCallback(() => {
		closeSpotlight('bulkActions');
		setSelectedRecordsState({ selectedRecords: [], lastSelectedIndex: null });
		spotlight.close();
	}, [setSelectedRecordsState]);

	useKeyPress(['Escape'], () => {
		spotlight.close();
	});

	useKeyPress(['meta.k'], () => {
		if (isViewerOrGuestUser) {
			return;
		}
		if (selectedRecordsState.selectedRecords.length > 0) {
			handleOpenActions();
		}
	});

	const showMoreActionsButton =
		actionsToShow.length > 0 &&
		actionsToShow.length !== withQuickActions?.length;

	return (
		<Dialog
			p={theme.spacing.xs}
			px={theme.spacing.sm}
			className={classes.root}
			opened={count > 0}
			onClose={noop}
		>
			<Group
				sx={{
					flexWrap: 'nowrap',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<Group spacing={0}>
					<Checkbox
						defaultChecked={count <= totalCount}
						indeterminate={count < totalCount}
						onClick={handleCloseDialog}
					/>
				</Group>
				<Text size="sm" weight="semibold">
					{count} selected
				</Text>
				<Group spacing="xs">
					{activeActions.map((action) => (
						<ActionButton<T>
							key={action.id}
							action={action}
							selectedRecordsState={selectedRecordsState}
							handleCloseDialog={handleCloseDialog}
							setSelectedRecordsState={setSelectedRecordsState}
						/>
					))}
					{showMoreActionsButton && (
						<Button onClick={handleOpenActions}>More actions</Button>
					)}
				</Group>
			</Group>
		</Dialog>
	);
}
