import { Stack } from '@mantine/core';
import { useApiListAuditLogs, type ActivityLogOut } from '@repo/api-codegen';
import { noop } from 'lodash-es';
import type { ApiCatalogSort } from '../../api';
import { TableV2 } from '../TableV2';
import { useColumns } from './hooks';
const DEFAULT_SORT: ApiCatalogSort = {
	field: 'updated_at',
	order: 'desc',
};

export function AuditLogs() {
	const columns = useColumns();

	return (
		<Stack spacing="md" py="md">
			<TableV2<ActivityLogOut>
				columns={columns}
				onRowClick={noop}
				pluralTypeString="logs"
				// @ts-ignore
				usePaginationList={useApiListAuditLogs}
				useCodegenListInterface
				withInteractiveHeader={false}
				withStickyColumnBorder={false}
				defaultSort={DEFAULT_SORT}
			/>
		</Stack>
	);
}
