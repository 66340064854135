import type { MantineThemeOverride } from '@mantine/core';

export const Anchor: MantineThemeOverride['components'] = {
	Anchor: {
		styles: (theme) => ({
			root: {
				color: theme.other.getColor('text/emphasis/default'),
				'&:hover': {
					color: theme.other.getColor('text/emphasis/hover'),
				},
			},
		}),
	},
};
