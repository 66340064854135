import { Box, createStyles, Divider, Group, Modal, Stack } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { Button, TextInput, Title } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useCallback } from 'react';
import { authenticateChromeExtension } from '../../../utils/extension';
import { CHROME_EXTENSION_ID_LOCAL_STORAGE_KEY } from './constants';

interface ChromeExtensionLocalDevelopmentModalProps {
	opened: boolean;
	onClose: () => void;
	onAuthenticatedChange: (authenticated: boolean) => void;
}

const useStyles = createStyles((theme) => ({
	header: {
		background: theme.other.getColor('surface/secondary/default'),
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		padding: `${theme.spacing.sm} ${theme.spacing.md}`,
	},
	content: {
		borderRadius: theme.radius.lg,
	},
	body: {
		padding: 0,
	},
}));

export function ChromeExtensionLocalDevelopmentModal({
	opened,
	onClose,
	onAuthenticatedChange,
}: ChromeExtensionLocalDevelopmentModalProps) {
	const [chromeExtensionID, setChromeExtensionID] = useInputState('');

	const handleConnect = useCallback(async () => {
		localStorage.setItem(
			CHROME_EXTENSION_ID_LOCAL_STORAGE_KEY,
			chromeExtensionID
		);
		const response = await authenticateChromeExtension();
		onAuthenticatedChange(response.success);
		if (response.success) {
			showNotification({
				message: 'Successfully connected',
				color: 'green',
			});
		} else {
			showNotification({
				message: `Failed to connect. ${
					'message' in response ? response.message : 'Unknown error'
				}`,
				color: 'red',
			});
		}
		setChromeExtensionID('');
		onClose();
	}, [chromeExtensionID, onAuthenticatedChange, onClose, setChromeExtensionID]);

	const { classes } = useStyles();
	return (
		<Modal
			classNames={{
				header: classes.header,
				content: classes.content,
				body: classes.body,
			}}
			opened={opened}
			onClose={onClose}
			title={<Title size="md">Connect Google Chrome extension</Title>}
			size={480}
		>
			<Stack spacing={0}>
				<Box p="md">
					<TextInput
						label="Google Chrome extension ID"
						value={chromeExtensionID}
						onChange={setChromeExtensionID}
						help="To locate, navigate to chrome://extensions, then look for the ID in the Secoda extension card."
					/>
				</Box>
				<Divider />
				<Group p="md" spacing="xs" position="right">
					<Button variant="default" onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant="primary"
						disabled={isEmpty(chromeExtensionID)}
						onClick={handleConnect}
					>
						Connect
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
