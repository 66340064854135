import { Box, createStyles, Stack } from '@mantine/core';
import { type PolicyOut } from '@repo/api-codegen';
import CollapsableStack from '@repo/common/components/CollapsableStack';
import { SELECTABLE_PROPERTY_OPTIONS } from '@repo/common/components/SelectableProperty/constants';
import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import { Text } from '@repo/foundations';
import { useMemo } from 'react';
import { useAuthUser } from '../../api';
import {
	useExtendedUserList,
	useGuestUserList,
} from '../../api/hooks/user/useExtendedUserList';
import { OwnerSelector } from '../../components/EntityModal/Metadata/OwnerSelector';
import { SelectableProperty } from '../../components/EntityPageLayout/EntityPropertySidebar';
import { getOwnerAndGroupSelectorOptions } from '../../components/EntityPageLayout/utils';
import { PropertySelector } from '../../components/Properties/PropertySelector';
import type { DjangoValueType } from '../../pages/TemplatePage/types';
import { formatEntityDateTime } from '../../utils/time';
import { FrameworkSelector } from '../PoliciesListPage/components/FrameworkSelector';
import {
	CreatedByRender,
	PolicyStatusRender,
} from '../PoliciesListPage/render';

const useStyles = createStyles((theme) => ({
	status: {
		paddingLeft: theme.spacing['3xs'],
	},
}));

export interface PolicyPageSidesheetProps {
	policy: PolicyOut;
	handlePolicyChange: (key: string) => (value: DjangoValueType) => void;
}

function PolicyPageSidebar({
	policy,
	handlePolicyChange,
}: PolicyPageSidesheetProps) {
	const { user, isGuestUser } = useAuthUser();
	const { classes } = useStyles();

	const { usersVisibleToGuests } = useGuestUserList();
	const { activeUsers, userGroups: groups } = useExtendedUserList();

	const subscriberOptions = useMemo(() => {
		const options = getOwnerAndGroupSelectorOptions(
			(isGuestUser ? usersVisibleToGuests : activeUsers) ?? [],
			groups ?? [],
			handlePolicyChange('subscribers'),
			handlePolicyChange('subscribers_groups')
		).map((owner) => ({
			...owner,
			itemTypeName: 'subscribers',
		}));
		return options;
	}, [
		isGuestUser,
		usersVisibleToGuests,
		activeUsers,
		groups,
		handlePolicyChange,
	]);

	const subscribers = useMemo(
		() => [...(policy.subscribers ?? []), ...(policy.subscribers_groups ?? [])],
		[policy]
	);

	return (
		<Stack>
			<CollapsableStack groupName="Overview">
				<Stack spacing="xs">
					<SelectableProperty label="Status" dataTestId="status">
						<Box className={classes.status}>
							<PolicyStatusRender record={policy} />
						</Box>
					</SelectableProperty>
					<SelectableProperty label="Severity" dataTestId="severity">
						<SingleSelector
							variant="tertiary"
							searchable
							readOnly={false}
							property="severity"
							placeholder={`No severity`}
							options={SELECTABLE_PROPERTY_OPTIONS['severity']}
							onChange={handlePolicyChange('severity')}
							isViewerUser={false}
							initialSelected={policy.severity ?? 'NONE'}
							iconType={'tabler'}
							hideOnEmpty
						/>
					</SelectableProperty>
					<SelectableProperty label="Owner" dataTestId="owner">
						<OwnerSelector
							property="owners"
							propertyLabel="Owners"
							placeholder="Select owners"
							forceVariant="tertiary"
							initialValue={[...policy.owners, ...policy.owners_groups]}
							onChangeGroupOwners={handlePolicyChange('owners_groups')}
							onChangeUserOwners={handlePolicyChange('owners')}
						/>
					</SelectableProperty>
					<SelectableProperty label="Frameworks" dataTestId="frameworks">
						<FrameworkSelector
							hideOnEmpty={false}
							policy={policy}
							placeholder="Add framework"
							placeholderIconName="plus"
						/>
					</SelectableProperty>
				</Stack>
			</CollapsableStack>
			<CollapsableStack groupName="Metadata">
				<Stack spacing="xs">
					<SelectableProperty label="Created by" dataTestId="created_by">
						<CreatedByRender record={policy} />
					</SelectableProperty>
					<SelectableProperty label="Created at" dataTestId="created_at">
						<Text size="sm">{formatEntityDateTime(policy.created_at)}</Text>
					</SelectableProperty>
					<SelectableProperty label="Updated at" dataTestId="updated_at">
						<Text size="sm">{formatEntityDateTime(policy.updated_at)}</Text>
					</SelectableProperty>
				</Stack>
			</CollapsableStack>
			<CollapsableStack groupName="Subscribers">
				<Stack spacing="xs">
					<SelectableProperty label="Subscribers" dataTestId="subscribers">
						<PropertySelector
							selected={subscribers}
							type="multi"
							value="subscribers"
							iconType="avatar"
							options={subscriberOptions}
							permittedId={user.id}
							isViewerUser={false}
							readOnly={false}
						/>
					</SelectableProperty>
				</Stack>
			</CollapsableStack>
		</Stack>
	);
}

export default PolicyPageSidebar;
