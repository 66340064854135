export const FEATURE_FLAGS_DEFAULT_VALUES = {
	policies: false,
	'monitoring-v2': false,
	'skip-default-billing-plan': false,
	'table-v2': true,
	'editor-ai-summary-block': false,
	'queries-v3': false,
	'region-selector': false,
	'data-quality-score-sla': false,
	'automation-lineage-metadata-propagation': false,
	'automation-history': false,
	'slack-v3': false,
	'ai-personas': false,
	'monitor-creation-flow': false,
	'lineage-filtering': false,
	'chrome-extension-onboarding': false,
	'attachments-in-ai-assistant': false,
	'monitor-annotations': false,
	'monitor-models': false,
	'native-snowflake': false,
	glossary: false,
	'workspace-ai-feedback': false,
	'query-chart-blocks': false,
	'custom-team-schema-mapping': false,
	'create-workspace': false,
	'ai-to-questions-answers': false,
	'query-block-scheduling': false,
	'activity-log-snapshot-merge': false,
	'car-gurus-activity-log-restore': false,
	'lineage-flow-update': false,
	'data-access-request': false,
	'allow-xai-llm': false,
	'ai-memory': false,
	'catalog-tree-v2': false,
	'lineage-monitors': false,
	'fe-activity-log': false,
	'entity-privileges-tab': false,
	'dark-mode': false,
	'custom-properties-filter': false,
	'ai-v3': false,
} as const;
