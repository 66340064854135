import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import { automationQueryKeyFactory } from './constants';

interface FetchAutomationFilterCountResponse {
	results: number;
}

export const fetchAutomationFilterCount = ({
	automationId,
}: {
	automationId: string;
}): Promise<FetchAutomationFilterCountResponse> =>
	queryClient.fetchQuery({
		queryKey: automationQueryKeyFactory.byArgs(automationId),
		queryFn: async () => {
			const url = getEndpoints(automationQueryKeyFactory.namespace).byPath([
				automationId,
				'count',
			]);

			const { data } = await apiClient.get(url);

			return data;
		},
		staleTime: 0,
	});
