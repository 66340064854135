import { Box, createStyles, type Sx } from '@mantine/core';
import type { CSSProperties, MouseEventHandler, ReactNode } from 'react';
import { memo } from 'react';
import type {
	DataTableCellClickHandler,
	DataTableColumn,
	DataTableDefaultColumnProps,
} from './types';
import { getValueAtPath, useMediaQueryStringOrFunction } from './utils';

const useStyles = createStyles({
	withPointerCursor: {
		cursor: 'pointer',
	},
	noWrap: {
		whiteSpace: 'nowrap',
	},
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
});

type DataTableRowCellProps<T> = {
	className?: string;
	sx?: Sx;
	style?: CSSProperties;
	record: T;
	recordIndex: number;
	defaultRender:
		| ((record: T, index: number, accessor: string) => ReactNode)
		| undefined;
	onClick?: MouseEventHandler<HTMLTableCellElement>;
} & Pick<
	DataTableColumn<T>,
	| 'accessor'
	| 'visibleMediaQuery'
	| 'textAlignment'
	| 'width'
	| 'noWrap'
	| 'ellipsis'
	| 'render'
	| 'customCellAttributes'
>;

export default function DataTableRowCell<T>({
	className,
	sx,
	style,
	visibleMediaQuery,
	record,
	recordIndex,
	onClick,
	noWrap,
	ellipsis,
	textAlignment,
	width,
	accessor,
	render,
	defaultRender,
	customCellAttributes,
}: DataTableRowCellProps<T>) {
	const { cx, classes } = useStyles();
	if (!useMediaQueryStringOrFunction(visibleMediaQuery)) return null;
	return (
		<Box
			component="td"
			className={cx(
				{
					[classes.noWrap]: noWrap || ellipsis,
					[classes.ellipsis]: ellipsis,
					[classes.withPointerCursor]: onClick,
				},
				className
			)}
			sx={[
				{
					width,
					minWidth: width,
					maxWidth: ['initial', 'auto'].includes(width as string) ? '50vw' : width,
					textAlign: textAlignment,
				},
				sx,
			]}
			style={style}
			onClick={onClick}
			{...customCellAttributes?.(record, recordIndex)}
		>
			{render
				? render(record, recordIndex)
				: defaultRender
					? defaultRender(record, recordIndex, accessor)
					: (getValueAtPath(record, accessor) as ReactNode)}
		</Box>
	);
}

function DataTableRowCellWrapperComponent<T>({
	columnProps,
	record,
	recordIndex,
	defaultColumnProps,
	defaultColumnRender,
	onCellClick,
	columnIndex,
}: {
	columnProps: DataTableColumn<T>;
	record: T;
	recordIndex: number;
	defaultColumnProps: DataTableDefaultColumnProps<T> | undefined;
	defaultColumnRender:
		| ((record: T, index: number, accessor: string) => ReactNode)
		| undefined;
	onCellClick: DataTableCellClickHandler<T> | undefined;
	columnIndex: number;
}) {
	if (columnProps.hidden) return null;

	const {
		accessor,
		visibleMediaQuery,
		textAlignment,
		noWrap,
		ellipsis,
		width,
		render,
		cellsClassName,
		cellsStyle,
		cellsSx,
		customCellAttributes,
	} = { ...defaultColumnProps, ...columnProps };

	let handleCellClick: MouseEventHandler<HTMLTableCellElement> | undefined;
	if (onCellClick) {
		handleCellClick = (event) =>
			onCellClick({
				event,
				record,
				recordIndex,
				column: columnProps,
				columnIndex,
			});
	}

	return (
		<DataTableRowCell<T>
			key={accessor}
			className={
				typeof cellsClassName === 'function'
					? cellsClassName(record, recordIndex)
					: cellsClassName
			}
			style={
				typeof cellsStyle === 'function'
					? cellsStyle(record, recordIndex)
					: cellsStyle
			}
			sx={cellsSx}
			visibleMediaQuery={visibleMediaQuery}
			record={record}
			recordIndex={recordIndex}
			onClick={handleCellClick}
			accessor={accessor}
			textAlignment={textAlignment}
			noWrap={noWrap}
			ellipsis={ellipsis}
			width={width}
			render={render}
			defaultRender={defaultColumnRender}
			customCellAttributes={customCellAttributes}
		/>
	);
}

export const DataTableRowCellWrapper = memo(
	DataTableRowCellWrapperComponent
) as typeof DataTableRowCellWrapperComponent;
