import { Center, createStyles, Stack } from '@mantine/core';
import { Button, Text, Title } from '@repo/foundations';
import type { IIntegration } from '../../../../../api';
import type { IntegrationSpec } from '../../../../../interfaces/IntegrationSpec.ts';
import { SelectIntegrationGroupsPanel } from '../../../components/SelectIntegrationGroupsPanel/SelectIntegrationGroupsPanel.tsx';

const useStyles = createStyles((theme) => ({
	treeWrapper: {
		position: 'relative',
		padding: `${theme.spacing.xs} ${theme.spacing.md}`,
		overflowY: 'hidden',
		maxHeight: '50vh',
	},
}));

interface SelectStepProps {
	integration: IIntegration;
	integrationSpec: IntegrationSpec;
	nextStep: () => void;
}

export function SelectStep({
	integration,
	integrationSpec,
	nextStep,
}: SelectStepProps) {
	const { classes } = useStyles();

	return (
		<Stack spacing={0}>
			<Stack spacing="xs">
				<Title size="xl">Import</Title>
				{integrationSpec.type === 'builtin' && (
					<Text size="sm">
						Confirm the{' '}
						{integrationSpec.value.schemaSupport ? 'schemas' : 'folders'}{' '}
						you&apos;d like to include in the sync
					</Text>
				)}
			</Stack>
			<Stack spacing="sm" h="100%" className={classes.treeWrapper}>
				<SelectIntegrationGroupsPanel
					integration={integration}
					spec={integrationSpec}
					refreshOnLoad
				/>
			</Stack>
			<Center>
				<Button variant="primary" size="md" onClick={nextStep}>
					Continue to sync
				</Button>
			</Center>
		</Stack>
	);
}
