import { createStyles } from '@mantine/core';
import { BULLET_SIZE } from './constants';

export const useStyles = createStyles((theme) => ({
	root: {},
	itemBody: {},
	itemContent: {},
	itemBullet: {
		'&[data-with-child]': {
			backgroundColor: theme.other.getColor('fill/primary/default'),
			border: 'none',
		},
	},
	item: {
		'::before': {
			top: `calc(${BULLET_SIZE}px + ${theme.spacing['3xs']})`,
			bottom: `calc((${theme.spacing.xl} - ${theme.spacing['3xs']}) * -1)`,
			borderColor: theme.other.getColor('border/primary/default'),
			borderWidth: 1,
		},
	},
	itemTitle: {},
}));
