import { Box, Stack } from '@mantine/core';
import { integrationList } from '@repo/common/constants/integration/integrations';
import { UserRole } from '@repo/common/enums/UserRole';
import { Banner, Title } from '@repo/foundations';
import { width } from '@repo/theme/primitives';
import { isEmpty } from 'lodash-es';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useIntegrationList } from '../../api';
import { useEducationModalDismiss } from '../../components/EducationModal/useEducationModalDismiss';
import { EmptyState, type ButtonDetails } from '../../components/EmptyState';
import { FeatureGuard } from '../../components/FeatureGuard/FeatureGuard';
import NewIntegrationModal from '../../components/Integration/AddIntegrationModal';
import { useStyles as useNewIntegrationModalStyles } from '../../components/Integration/AddIntegrationModal/AddIntegrationModal.styles';
import { NEW_INTEGRATION_MODAL_ID } from '../../components/Integration/AddIntegrationModal/constants';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { openModal } from '../../components/ModalManager';
import { TableV2 } from '../../components/TableV2';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { useFeatureFlags } from '../../utils/featureFlags/useFeatureFlags';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { useActions, useColumns } from './IntegrationsListPage.hooks';

const COLUMN_VISIBILITY = {
	catalogServerType: 'integrations',
	catalogType: 'integration',
} as const;

const QUICK_ACTIONS = ['actions::run', 'actions::delete'] as const;

const PAGINATION_LIST_OPTIONS = {
	suspense: true,
};

const SETTINGS_PAGE_ONLY_TYPES = integrationList
	.filter((i) => i.isExtension)
	.map((i) => i.type)
	.join(',');

function IntegrationsListPage() {
	const { data: integrations, isLoading } = useIntegrationList({
		page: 1,
		filters: {
			type__not_in: SETTINGS_PAGE_ONLY_TYPES,
		},
	});

	const { classes } = useStyles();
	const { classes: addIntegrationModalClasses } =
		useNewIntegrationModalStyles();
	const { nativeSnowflake } = useFeatureFlags();

	const columns = useColumns();
	const actions = useActions();

	const navigate = useNavigate();
	const { setAsSeen, dismissedModals } = useEducationModalDismiss();

	const handleClick = useCallback(
		(id: string) => {
			navigate(`/integrations/${id}`);
		},
		[navigate]
	);

	const handleConnectIntegration = useCallback(() => {
		const availableIntegrations = integrationList
			.filter((i) => !i.isExtension)
			.filter((i) => {
				if (i.type === 'native_snowflake') {
					return nativeSnowflake;
				}
				return true;
			})
			.map((i) => i.type);

		openModal({
			modalId: NEW_INTEGRATION_MODAL_ID,
			title: <Title size="md">Connect integration</Title>,
			children: (
				<NewIntegrationModal integrationTypes={availableIntegrations} />
			),
			size: width.xxl,
			radius: 'lg',
			classNames: {
				header: addIntegrationModalClasses.header,
				body: addIntegrationModalClasses.body,
			},
		});
	}, [
		addIntegrationModalClasses.body,
		addIntegrationModalClasses.header,
		nativeSnowflake,
	]);

	const onCellClick = useCallback(
		(cell) => {
			if (
				(cell.column as ExtendedDataTableColumn<unknown>).navigate !== false
			) {
				handleClick(cell.record.id);
			}
		},
		[handleClick]
	);

	const handleBannerDismiss = useCallback(() => {
		setAsSeen('productivity-integrations-moved');
	}, [setAsSeen]);

	const showProductivityBanner =
		!dismissedModals?.['productivity-integrations-moved'];

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (!isLoading && isEmpty(integrations)) {
		const buttons: ButtonDetails[] = [
			{
				name: 'Learn more',
				action: () => {
					window.open('https://docs.secoda.co/integrations', '_blank');
				},
				isPrimary: false,
				size: 'md',
				leftIconName: 'externalLink',
			},
			{
				name: 'Connect integration',
				action: handleConnectIntegration,
				isPrimary: true,
				size: 'md',
			},
		];

		return (
			<EmptyState
				title="Connect your first integration"
				description="Set up two or more integrations to seamlessly interact, govern and view lineage with your data."
				illustrationName="integrations"
				includeGoBack={false}
				size="lg"
				buttons={buttons}
				stateHeight="80vh"
			/>
		);
	}

	return (
		<FeatureGuard
			featureName="Integrations"
			description="Get access to integrations to seamlessly interact, govern and view lineage with your data."
			illustrationName="integrations"
			v1AllowedRoles={[UserRole.ADMIN, UserRole.EDITOR]}
			v2Permission="Integrations.Read"
			isFeatureIncludedInPlan={true}
			isAlwaysIncludedFeature={false}
		>
			<Stack className={classes.wrapper}>
				{showProductivityBanner && (
					<Box h={100} my="sm">
						<Banner
							title="Productivity integrations have moved"
							message="Slack, Confluence, and other productivity integrations have been moved to Settings and been renamed to Extensions"
							tone="neutral"
							onDismiss={handleBannerDismiss}
							actions={[
								{
									children: 'Go to Extensions',
									onClick: () => {
										navigate('/settings/extensions');
									},
								},
							]}
						/>
					</Box>
				)}
				<TableV2
					columnVisibility={COLUMN_VISIBILITY}
					pluralTypeString="integrations"
					columns={columns}
					usePaginationList={useIntegrationList}
					defaultRequiredSearchParams={{
						type__not_in: SETTINGS_PAGE_ONLY_TYPES,
					}}
					usePaginationListOptions={PAGINATION_LIST_OPTIONS}
					withActions={actions}
					withCheckbox={true}
					withQuickActions={QUICK_ACTIONS}
					withCsvExport
					withInteractiveHeader
					withSearch
					defaultSort={null}
					onCellClick={onCellClick}
				/>
			</Stack>
		</FeatureGuard>
	);
}

export default IntegrationsListPage;
