import { map, noop } from 'lodash-es';
import { useMemo } from 'react';
import { useCollectionListAll, type ISecodaEntity } from '../../api';
import type { PropertySelectorProps } from './PropertySelector';
import { PropertySelector } from './PropertySelector';

export interface CollectionParentPropertySelectorProps
	extends Pick<PropertySelectorProps, 'onChange'> {
	entity: ISecodaEntity;
	viewerOfEntity: boolean;
}

export function CollectionParentPropertySelector({
	entity,
	viewerOfEntity,
	onChange,
}: CollectionParentPropertySelectorProps) {
	const { data: collections } = useCollectionListAll();

	const collectionOptions = useMemo(
		() =>
			map(collections?.results, (collection) => ({
				label: collection.title || 'Untitled',
				value: collection.id,
				icon: collection.icon,
				navigateTo: `/collections/${collection.id}`,
				onClick: noop,
			})),
		[collections]
	);

	return (
		<PropertySelector
			selected={entity.collections?.length > 0 ? entity.collections[0] : []}
			type="single"
			value="collections"
			iconType="emoji"
			isViewerUser={viewerOfEntity}
			readOnly={viewerOfEntity}
			onChange={(value) => {
				const collectionId = value as string;
				onChange?.(collectionId ? [collectionId] : []);
			}}
			options={collectionOptions}
			searchable
		/>
	);
}
