import { useCallback, useEffect, useState } from 'react';
import { matchPath, useParams } from 'react-router-dom';

interface PathInfo {
	pattern: string;
	params: Record<string, string>;
}

interface TabHookResult {
	currentTab: string | undefined;
	setCurrentTab: (tab: string) => void;
}

export function useSilentUrlUpdate(defaultTab?: string): TabHookResult {
	const params = useParams<{ tab?: string }>();
	const [currentTab, setCurrentTab] = useState<string | undefined>(
		params.tab || defaultTab
	);

	const getCurrentPathPattern = (): PathInfo => {
		const currentPath = window.location.pathname;
		const basePattern = '/:pagePrefix/:id';
		const tabPattern = `${basePattern}/:tab`;

		const withTabMatch = matchPath(tabPattern, currentPath);
		const baseMatch = matchPath(basePattern, currentPath);

		return {
			pattern: withTabMatch ? tabPattern : basePattern,
			params: Object.fromEntries(
				Object.entries((withTabMatch || baseMatch)?.params || {})
					.filter(([_, value]) => value !== undefined)
					.map(([key, value]) => [key, value as string])
			),
		};
	};

	const createNewPath = (tab: string): string => {
		const { pattern } = getCurrentPathPattern();

		if (pattern.includes(':tab')) {
			const segments = window.location.pathname.split('/');
			segments[segments.length - 1] = tab;
			return segments.join('/');
		}

		return `${window.location.pathname}/${tab}`;
	};

	useEffect(() => {
		const handlePopState = (): void => {
			const { params: currentParams } = getCurrentPathPattern();
			if (currentParams.tab) {
				setCurrentTab(currentParams.tab);
			}
		};

		window.addEventListener('popstate', handlePopState);
		return () => window.removeEventListener('popstate', handlePopState);
	}, []);

	const setTab = useCallback((tab: string): void => {
		setCurrentTab(tab);
		const newPath = createNewPath(tab);
		window.history.pushState({ tab }, '', newPath);
	}, []);

	return { currentTab, setCurrentTab: setTab };
}
