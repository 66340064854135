export const getCountDisplay = (count: number | undefined) => {
	if (!count) {
		return '0';
	}
	if (count === 10000) {
		// Limit
		return '10,000+';
	}
	return `${count > 1000 ? `${(count / 1000).toFixed(0)},${count % 1000}` : count}`;
};
