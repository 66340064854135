import { useObserver } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { getParamsFromUrl } from '../../utils/url';
import { inboxPageStore } from './store';
import { useInboxEventTypes } from './useInboxEventTypes';
import { getFilters, getInboxFiltersFromUrl } from './utils';

export const useInboxPage = () => {
	const params = getParamsFromUrl();
	const inboxEventTypes = useInboxEventTypes();

	const {
		searchTerm: searchTermParam,
		page: pageParam,
		events: eventsParam,
		isInclusive: inclusiveParam,
		selectedId: selectedIdParam,
	} = getInboxFiltersFromUrl(params);

	const [searchTerm, setSearchTerm] = useState(searchTermParam);
	const [page, setPage] = useState(pageParam);
	const [events, setEvents] = useState(eventsParam);
	const [isInclusive, setIsInclusive] = useState(inclusiveParam);
	const [selectedId, setSelectedId] = useState(selectedIdParam);

	useEffect(() => {
		setSearchTerm(searchTermParam);
		setPage(pageParam);
		setEvents(eventsParam);
		setIsInclusive(inclusiveParam);
		setSelectedId(selectedIdParam);
	}, [
		eventsParam,
		searchTermParam,
		pageParam,
		inclusiveParam,
		selectedIdParam,
	]);

	const filters = useMemo(
		() =>
			getFilters(
				inboxEventTypes,
				events,
				searchTerm,
				isInclusive,
				inboxPageStore.showRead
			),
		[inboxEventTypes, events, searchTerm, isInclusive]
	);

	return useObserver(() => ({
		searchTerm,
		setSearchTerm,
		page,
		setPage,
		events,
		setEvents,
		isInclusive,
		setIsInclusive,
		selectedId,
		setSelectedId,
		filters,
	}));
};
