import { Box, Group, Table } from '@mantine/core';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Text } from '@repo/foundations';
import { useSecodaEntityList } from '../../../../api';
import { SecodaEntityIcon } from '../../../../components/SecodaEntity';
import type { TableComponentProps } from './MetricWidgetTable';

export function MetricQueriesTable({
	// can use MetricResourceTable instead
	infoColumns,
	values,
}: TableComponentProps) {
	const navigateHandler = useNavigateHandler();

	const { data } = useSecodaEntityList({
		filters: {
			id__in: values.map((resource) => resource.id).join(','),
		},
	});

	function getSecodaEntityById(id: string) {
		return data?.results.find((e) => e.id === id);
	}

	const rows = values.map((table) => {
		const entity = getSecodaEntityById(table.id as string);
		return (
			<tr
				key={table.title}
				style={{
					cursor: 'pointer',
				}}
				onClick={(e) => navigateHandler(e)(`/catalog/${table.title}`)}
			>
				<td>
					<Group h={52} noWrap>
						<Box w={32} h={32}>
							<SecodaEntityIcon entity={entity} size={32} />
						</Box>
						<Text color="text/primary/default" size="sm" fw={500} lineClamp={1}>
							{table.title}
						</Text>
					</Group>
				</td>
				{infoColumns.map((col) => (
					<td key={col.fieldName}>
						<Text color="text/primary/default" size="sm">
							{table[col.fieldName]}
						</Text>
					</td>
				))}
			</tr>
		);
	});

	return (
		<Table highlightOnHover w="100%">
			<thead>
				<tr>
					<th>
						<Text color="text/secondary/default" fz="xs" fw={500}>
							Table
						</Text>
					</th>
					{infoColumns.map((col) => (
						<th key={col.fieldName}>
							<Text color="text/secondary/default" fz="xs" fw={500}>
								{col.label}
							</Text>
						</th>
					))}
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</Table>
	);
}
