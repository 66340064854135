import { Group } from '@mantine/core';
import type {
	ActivityLogOut,
	ActivityLogPerformerMetadata,
	LiteUserOut,
} from '@repo/api-codegen';
import { UserAvatar } from '@repo/common/components/UserAvatar';
import { EntityType } from '@repo/common/enums/entityType';
import { Icon, Text } from '@repo/foundations';
import { useMemo } from 'react';
import IntegrationLogo from '../../IntegrationLogo';

const USER_PERFORMER_TYPES: ActivityLogOut['performer_type'][] = [
	'USER',
	'API',
];

export interface IPerformer {
	id: string;
	type: ActivityLogOut['performer_type'];
	metadata: ActivityLogPerformerMetadata;
}

export interface VersionHistoryButtonItemProps {
	performer: IPerformer;
}

export function VersionHistoryButtonItem({
	performer,
}: VersionHistoryButtonItemProps) {
	const info:
		| { avatar?: React.ReactNode; name: string | null | undefined }
		| undefined = useMemo(() => {
		if (USER_PERFORMER_TYPES.includes(performer.type)) {
			return {
				avatar: (
					<UserAvatar size="xs" user={performer.metadata as LiteUserOut} />
				),
				name: (performer.metadata as LiteUserOut).display_name,
			};
		}

		if (performer.type === 'AUTOMATION') {
			return {
				avatar: <Icon name="bolt" />,
				name: performer.metadata.name,
			};
		}

		if (performer.type === 'EXTRACTION' || performer.type === 'INTEGRATION') {
			return {
				avatar: (
					<IntegrationLogo
						entityType={EntityType.integration}
						integrationId={performer.metadata.id}
						integrationType={performer.metadata.integration_type ?? undefined}
						size={16}
					/>
				),
				name: performer.metadata.name,
			};
		}

		if (performer.type === 'UNKNOWN') {
			return {
				avatar: <Icon name="user" />,
				name: 'Unknown',
			};
		}

		return { name: performer.metadata.name };
	}, [performer]);

	return (
		<Group spacing="2xs" noWrap>
			{info.avatar}
			<Text size="sm" color="text/primary/default">
				{info.name}
			</Text>
		</Group>
	);
}
