export const DESCRIPTION_UPDATED_EVENTS = new Set([
	'resource_definition_updated',
	'resource_description_updated',
]);

export const OWNER_ASSIGNED_EVENTS = new Set(['resource_owner_assigned']);

export const COLLECTION_ADDED_REMOVED_EVENTS = new Set([
	'resource_collection_added',
	'resource_collection_removed',
]);

export const RESOURCE_TAG_ADDED_REMOVED_EVENTS = new Set([
	'resource_tag_added',
	'resource_tag_removed',
]);

// Extraction or metadata push job
export const INTEGRATION_JOB_EVENTS = new Set([
	'extraction_job_completed',
	'extraction_job_failed',
	'metadata_push_job_completed',
	'metadata_push_job_failed',
	'github_change_request',
]);

export const BASIC_RESOURCE_EVENTS = new Set([
	'access_request',
	'change_request',
	'applied_access_request',
	'applied_change_request',
	'resource_published',
	'resource_unpublished',
	'resource_archived',
	'mentions',
	'table_schema_changed',
	'tables_dropped',
]);

export const QUESTION_EVENTS = new Set([
	'question_new',
	'question_answer',
	'question_reply',
	'question_reply_edited',
	'question_reply_removed',
	'question_owner_assigned',
]);

export const MONITOR_INCIDENT_EVENTS = new Set([
	'monitor_incident_created',
	'monitor_incident_resolved',
]);

export const COMMENT_EVENTS = new Set([
	'comment_new_thread',
	'comment_reply',
	'comment_mention',
	'comment_reaction',
	'comment_resolved',
]);

export const ANNOUNCEMENT_EVENTS = new Set(['announcement']);

export const DATA_ACCESS_REQUEST_EVENTS = new Set([
	'data_access_request_created',
	'data_access_request_approved',
	'data_access_request_approved_admin',
	'data_access_request_rejected',
	'data_access_request_cancelled',
]);
