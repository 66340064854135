import {
	Box,
	createStyles,
	Group,
	Select,
	Stack,
	TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Button } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useAuthUser, useUpdateUser } from '../../api';
import { useUserProfileImage } from '../../api/hooks/user/useUserProfileImage';
import { trackEventAnonymous } from '../../utils/analytics';
import { AvatarUpload } from '../ImageUpload/AvatarUpload';
import { AuthLayoutBase } from './AuthLayouts/AuthLayoutBase';
import {
	anonymousId,
	EMAIL_VERIFICATION_LOCAL_STORAGE_KEY,
	referrer,
} from './AuthStepEmailComponent.constants';
import { ROLE_OPTIONS, USE_SECODA_FOR_OPTIONS } from './consts';

const useStyles = createStyles((theme) => ({
	page: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		height: '100vh',
		alignItems: 'center',
	},
	card: {
		width: 500,
		marginTop: 50,
	},
}));

const NAME_REGEX =
	/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

interface UserProfileType {
	firstName?: string;
	lastName?: string;
	roleName?: string;
	useReason?: string;
	profilePicture?: File | null;
}

export function AuthStepProfile() {
	const { classes, theme } = useStyles();
	const navigate = useNavigate();
	const { user } = useAuthUser();
	const { mutateAsync } = useUpdateUser({});
	const { mutateAsync: uploadImage } = useUserProfileImage();

	const form = useForm<UserProfileType>({
		initialValues: {
			firstName: user?.first_name,
			lastName: user?.last_name,
			roleName: user?.role_name,
			useReason: user?.use_reason,
		},

		validate: {
			firstName: (value) =>
				!isEmpty(value) && value?.match(NAME_REGEX) ? null : 'Invalid name',
			lastName: (value) =>
				!isEmpty(value) && value?.match(NAME_REGEX) ? null : 'Invalid name',
			roleName: (value) =>
				ROLE_OPTIONS.some((opt) => opt.value === value)
					? null
					: 'Please select a role',
			useReason: (value) =>
				USE_SECODA_FOR_OPTIONS.some((opt) => opt.value === value)
					? null
					: 'Please select a use case',
			// Always return null for profilePicture, as it's optional.
			profilePicture: () => null,
		},
	});

	const handleSubmit = async (values: UserProfileType) => {
		trackEventAnonymous(
			'auth/verify',
			{
				path: window.location.pathname,
				anonymous_id: anonymousId(),
				referrer: referrer(),
			},
			localStorage.getItem(EMAIL_VERIFICATION_LOCAL_STORAGE_KEY) ?? ''
		);

		if (user) {
			const data = {
				first_name: values.firstName,
				last_name: values.lastName,
				use_reason: values.useReason,
				role_name: values.roleName,
				id: user.id,
			};

			await mutateAsync({
				data,
			});

			if (values.profilePicture) {
				await uploadImage({
					file: values.profilePicture,
				});
			}

			if (user.workspace_id) {
				// Skip workspace creation, if user already has one.
				navigate('/onboarding/confirm');
				return;
			}

			navigate('/onboarding/workspace');
		}
	};

	const handleFile = useCallback(
		(file: File) => {
			form.setFieldValue('profilePicture', file);
		},
		[form]
	);

	if (!user) {
		return null;
	}

	return (
		<Box className={classes.page}>
			<AuthLayoutBase
				title="Tell us about yourself"
				subTitle="These details will help us give you a better experience."
			>
				<Stack spacing="2xl">
					<form
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: theme.spacing.md,
							marginTop: theme.spacing['2xl'],
						}}
						onSubmit={form.onSubmit(handleSubmit)}
					>
						<Group position="left">
							<AvatarUpload
								callback={handleFile}
								label="Profile image"
								placeholder="/images/auth-image/profile-placeholder.webp"
								user={user}
							/>
						</Group>
						<TextInput
							size="sm"
							label="First name"
							styles={{ input: { borderRadius: theme.other.space[2] } }}
							{...form.getInputProps('firstName')}
						/>
						<TextInput
							size="sm"
							label="Last name"
							styles={{ input: { borderRadius: theme.other.space[2] } }}
							{...form.getInputProps('lastName')}
						/>
						<Select
							size="sm"
							data={ROLE_OPTIONS}
							label="Role"
							name="customerRole"
							placeholder="Select"
							{...form.getInputProps('roleName')}
						/>
						<Select
							size="sm"
							data={USE_SECODA_FOR_OPTIONS}
							label="What are you hoping to use Secoda for?"
							name="desiredUse"
							placeholder="Select"
							styles={{
								item: {
									whiteSpace: 'normal',
									wordBreak: 'break-word',
								},
							}}
							{...form.getInputProps('useReason')}
						/>
						<Group mt={theme.spacing.md} position="center">
							<Button disabled={!form.isValid} type="submit" variant="primary">
								Continue
							</Button>
						</Group>
					</form>
				</Stack>
			</AuthLayoutBase>
		</Box>
	);
}
