import {
	Box,
	Center,
	createStyles,
	Flex,
	Group,
	Skeleton,
	Stack,
} from '@mantine/core';
import type { PolicyIssueOut, PolicyOut } from '@repo/api-codegen';
import { apiQueryKey, useApiListPolicyIssues } from '@repo/api-codegen';
import { Icon, IconButton, Text, Title } from '@repo/foundations';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import type { ISecodaEntity } from '../../../../api';
import { queryClient } from '../../../../api';
import { resourceCatalogQueryKeyFactory } from '../../../../api/hooks/resourceCatalog/constants';
import entityDrawerStore from '../../../../components/EntityDrawer/store';
import { RichEditor } from '../../../../components/RichEditor';
import { ResourceDisplay } from '../../../PoliciesListPage/render';
import { getCountDisplay } from '../../../PoliciesListPage/utils';
import PolicySection from '../../PolicySection';

interface PolicyIssuesProps {
	policy: PolicyOut;
}

const useStyles = createStyles((theme) => ({
	editorWrapper: {
		padding: theme.spacing.sm,
		'& .ProseMirror': {
			fontSize: theme.fontSizes.sm,
		},
	},
	rowContainer: {
		display: 'flex',
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		padding: theme.spacing.xs,
		paddingLeft: theme.spacing.sm,
		cursor: 'pointer',
	},
	resourceColumn: {
		color: theme.other.getColor('text/secondary/default'),
		fontSize: theme.fontSizes.xs,
		flex: '0 0 80%',
		minWidth: 0, // Prevents flex items from overflowing
		display: 'flex',
		justifyContent: 'space-between',
		paddingRight: theme.spacing.lg,
	},
	resolveText: {
		color: theme.other.getColor('text/secondary/default'),
		fontSize: theme.fontSizes.xs,
		alignSelf: 'center',
		paddingRight: theme.spacing.lg,
	},
	dateColumn: {
		color: theme.other.getColor('text/secondary/default'),
		fontSize: theme.fontSizes.xs,
		flex: '0 0 20%',
		minWidth: 0, // Prevents flex items from overflowing
		alignItems: 'center', // Vertically center content
	},
}));

type PolicyIssueWithEphemeralResolved = PolicyIssueOut & {
	resolved: boolean;
};

export default function PolicyIssues({ policy }: PolicyIssuesProps) {
	const { classes } = useStyles();

	const [page, setPage] = useState(1);

	const { data, isLoading } = useApiListPolicyIssues({
		pathParams: {
			policyId: policy.id,
		},
		queryParams: {
			page,
		},
	});

	const [previousFetched, setPreviousFetched] = useState<PolicyIssueOut[]>([]);

	const issues: PolicyIssueWithEphemeralResolved[] = useMemo(() => {
		const newIssues =
			data?.results.map((issue) => ({
				...issue,
				resolved: false,
			})) ?? [];

		for (const issue of previousFetched) {
			if (!newIssues.find((i) => i.id === issue.id)) {
				newIssues.push({
					...issue,
					resolved: true,
				});
			}
		}
		setPreviousFetched(data?.results ?? []);
		return newIssues.sort((a, b) =>
			dayjs(b.occured_at).diff(dayjs(a.occured_at))
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.results]);

	const handleResourceClick = useCallback(
		(id: string) => {
			entityDrawerStore.openEntityDrawerById(true, id, undefined, () => {
				queryClient.invalidateQueries({
					queryKey: resourceCatalogQueryKeyFactory.allLists(),
				});
				queryClient.invalidateQueries({
					queryKey: apiQueryKey('policy/{policy_id}/issues', {
						policy_id: policy.id,
					}),
				});
			});
		},
		[policy.id]
	);

	return (
		<Stack pt="lg">
			<Stack>
				<PolicySection
					title="Remediation"
					content={
						<Box className={classes.editorWrapper}>
							<RichEditor
								placeholder="No remediation guidelines provided."
								readOnly
								initialValue={
									policy.remediation || 'No remediation guidelines provided.'
								}
								disableTopGap
								limited
							/>
						</Box>
					}
				/>
				<PolicySection
					title={
						<Flex>
							<Text className={classes.resourceColumn}>Resources at risk</Text>
							<Text className={classes.dateColumn}>Issue occurred</Text>
						</Flex>
					}
					content={
						<>
							{isLoading && <Skeleton height="50vh" />}
							{!isLoading && issues.length === 0 && (
								<Center p="2xl">
									<Stack align="center" p="xl">
										<Icon
											name="circleCheck"
											size="lg"
											color="icon/secondary/default"
										/>
										<Title size="sm">No issues to resolve</Title>
									</Stack>
								</Center>
							)}
							{!isLoading && issues.length > 0 && (
								<Flex h="50vh" direction="column" sx={{ overflowY: 'auto' }}>
									{issues.map((issue) => (
										<Box
											key={issue.id}
											className={classes.rowContainer}
											onClick={() => handleResourceClick(issue.resource.id)}
										>
											<Flex className={classes.resourceColumn}>
												<ResourceDisplay
													record={
														issue.resource as Pick<
															ISecodaEntity,
															| 'title'
															| 'integration'
															| 'search_metadata'
															| 'entity_type'
															| 'id'
														>
													}
												/>
												{issue.resolved && (
													<Group
														className={classes.resolveText}
														spacing="xs"
														noWrap
													>
														<Icon
															name="circleCheckFilled"
															fillColor="icon/success/default"
														/>
														<Text size="sm" color="text/secondary/default">
															Resolved
														</Text>
													</Group>
												)}
											</Flex>
											<Flex className={classes.dateColumn}>
												<Text size="sm">
													{dayjs(issue.occured_at).format(
														'MMM D, YYYY [at] h:mm A'
													)}
												</Text>
											</Flex>
										</Box>
									))}
								</Flex>
							)}
						</>
					}
					footer={
						<Group position="apart" p="xs" noWrap>
							<Text color="text/secondary/default" size="xs">
								{isLoading
									? 'Loading...'
									: `${(page - 1) * 50 + 1}-${Math.min(page * 50, data?.count ?? 0)} of ${getCountDisplay(data?.count)} results`}
							</Text>
							<Group spacing="xs">
								<IconButton
									disabled={!data?.meta.previous_page}
									variant="tertiary"
									iconName="chevronLeft"
									onClick={() => setPage(page - 1)}
								/>
								<IconButton
									disabled={!data?.meta.next_page}
									variant="tertiary"
									iconName="chevronRight"
									onClick={() => setPage(page + 1)}
								/>
							</Group>
						</Group>
					}
				/>
			</Stack>
		</Stack>
	);
}
