import {
	apiQueryKey,
	useApiGetEntityCounts,
	useApiHasPreviewAccess,
	useRetrieveQuality,
} from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { useMount } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import {
	queryClient,
	useAuthUser,
	useDataQualityAccess,
	useFeatureAccess,
	useIntegration,
	useIntegrationSupport,
	usePolicyAccess,
	useUpdateSecodaEntity,
} from '../../api';
import useSuspenseSecodaEntity from '../../api/hooks/secodaEntity/useSuspenseSecodaEntity';
import { EntityAccessControlList } from '../../components/AccessControl/EntityAccessControlList';
import Documentation from '../../components/Documentation';
import EntityDiscussions from '../../components/EntityDiscussions';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';
import EntityPageLayoutSkeleton from '../../components/EntityPageLayout/EntityPageLayoutSkeleton';
import EntityPolicyList from '../../components/EntityPolicyList/EntityPolicyList';
import { LineageGraphWrapper } from '../../components/LineageGraph/LineageGraph';
import OverviewPageTabs from '../../components/Overview/OverviewPageTabs';
import TableDataPreview from '../../components/Preview/TableDataPreview';
import { QueryList } from '../../components/QueryListV2/QueryList';
import { trackEvent } from '../../utils/analytics';
import { useFeatureFlags } from '../../utils/featureFlags';
import { useSilentUrlUpdate } from '../../utils/hook/useSilentUrlUpdate';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import Tests from '../TablePage/Tests';
import type { DjangoValueType } from '../TemplatePage/types';
import ColumnsTab from './TableEntityTabs/ColumnsTab';
import { DataQualityTab } from './TableEntityTabs/DataQualityTab';
import IncidentsTab from './TableEntityTabs/IncidentsTab';
import MonitorsTab from './TableEntityTabs/MonitorsTab';

const InnerTableEntityPage = observer(({ id }: { id: string }) => {
	const { user, workspace } = useAuthUser();
	const hasPolicyAccess = usePolicyAccess();
	const dqsEnabled = useDataQualityAccess();

	const { currentTab, setCurrentTab } = useSilentUrlUpdate('columns');

	const { data: tableEntity } = useSuspenseSecodaEntity({
		id,
	});

	useMount(() => {
		trackEvent('table_entity/open', {}, user, workspace);
	});

	const { mutateAsync } = useUpdateSecodaEntity({
		options: {
			onSuccess: () =>
				queryClient.invalidateQueries({
					queryKey: apiQueryKey('quality'),
				}),
		},
	});

	const updateTableEntity = useCallback(
		async (key: string, value: DjangoValueType, saveRemotely = true) => {
			if (saveRemotely) {
				await mutateAsync({
					data: {
						id: tableEntity!.id,
						[key]: value,
					},
				});
				trackEvent('table/properties/update', {}, user, workspace!);
			}
		},
		[mutateAsync, tableEntity, user, workspace]
	);

	const canShowDataQualityScore =
		dqsEnabled && tableEntity?.entity_type?.toLowerCase() === 'table';
	const { data: dataQuality } = useRetrieveQuality(
		{
			pathParams: {
				entityId: tableEntity?.id || '',
			},
		},
		{
			enabled: !!tableEntity && canShowDataQualityScore,
			refetchOnMount: 'always',
		}
	);

	const { data: integration } = useIntegration({
		id: tableEntity?.integration || '',
		options: {
			enabled: !!tableEntity?.integration,
		},
	});
	const { data: previewPermissionGranted } = useApiHasPreviewAccess(
		{
			pathParams: {
				integrationId: tableEntity?.integration ?? '',
			},
		},
		{
			enabled: !!tableEntity?.integration,
		}
	);

	const { data: entityCounts, isLoading } = useApiGetEntityCounts(
		{
			queryParams: {
				entity_id: tableEntity?.id || '',
			},
		},
		{ enabled: !!tableEntity?.id }
	);

	const { data: integrationSupport } = useIntegrationSupport();
	const integrationKeys = integrationSupport
		? Object.keys(integrationSupport)
		: [];
	const { monitoringAccess } = useFeatureAccess();
	const isMonitoringEnabled =
		Boolean(monitoringAccess) &&
		integrationKeys.includes(integration?.type ?? '');

	const { entityPrivilegesTab } = useFeatureFlags();

	const handleTabChange = useCallback(
		(updatedTab: string) => {
			if (updatedTab === 'lineage') {
				trackEvent('table/lineage/open', {}, user, workspace);
			}
			if (updatedTab === 'quality') {
				trackEvent('dqs/resource_tab/open', {}, user, workspace);
			}
			setCurrentTab(updatedTab);
		},
		[setCurrentTab, user, workspace]
	);

	if (!tableEntity) {
		return <EntityPageLayoutSkeleton />;
	}

	const showPreviewTab =
		!['looker', 'powerbi', 'tableau', 'mode', 's3'].includes(
			integration?.type ?? ''
		) && !!previewPermissionGranted;

	const tabs = useMemo(
		() => [
			{
				value: 'columns',
				label: 'Columns',
				component: <ColumnsTab table={tableEntity} />,
			},
			{
				value: 'lineage',
				label: 'Lineage',
				component: (
					<LineageGraphWrapper
						id={tableEntity.id}
						entityType={tableEntity.entity_type}
						nativeType={tableEntity.native_type ?? ''}
						published={tableEntity.published}
					/>
				),
			},
			{
				value: 'monitors',
				label: 'Monitors',
				condition: isMonitoringEnabled,
				component: (
					<MonitorsTab
						integrationId={tableEntity.integration as string}
						tableId={tableEntity.id}
					/>
				),
			},
			{
				value: 'incidents',
				label: 'Incidents',
				condition: isMonitoringEnabled && Boolean(entityCounts?.incident_count),
				component: <IncidentsTab tableId={tableEntity.id} />,
			},
			{
				value: 'preview',
				label: 'Preview',
				condition: showPreviewTab,
				component: <TableDataPreview tableKey={tableEntity.id} />,
			},
			{
				value: 'tests',
				label: 'Tests',
				disabled: isLoading,
				condition: isLoading || Boolean(entityCounts?.test_count),
				component: <Tests entity={tableEntity} />,
			},
			{
				value: 'queries',
				label: 'Queries',
				condition: isLoading || Boolean(entityCounts?.query_count),
				component: <QueryList entity={tableEntity} />,
			},
			{
				value: 'discussions',
				label: 'Questions',
				component: <EntityDiscussions entity={tableEntity} />,
			},
			{
				value: 'documentation',
				label: 'Documentation',
				component: <Documentation entity={tableEntity} />,
			},
			{
				value: 'policies',
				label: 'Policies',
				condition: hasPolicyAccess,
				component: <EntityPolicyList entity={tableEntity} />,
			},
			{
				value: 'quality',
				label: 'Quality',
				condition: dataQuality !== null,
				component: <DataQualityTab entity={tableEntity} />,
			},
			{
				value: 'privileges',
				label: 'Privileges',
				condition: entityPrivilegesTab,
				component: <EntityAccessControlList entity={tableEntity} />,
			},
		],
		[
			tableEntity,
			isMonitoringEnabled,
			entityCounts,
			isLoading,
			showPreviewTab,
			hasPolicyAccess,
			dataQuality,
			entityPrivilegesTab,
		]
	);

	return (
		<EntityPageLayout
			key={tableEntity.id}
			name="Table"
			isReadOnlyTitle
			entity={tableEntity}
			icon={<EntityLogo entity={tableEntity} size={32} />}
			updateEntity={updateTableEntity}
			withDescription
			withEntityByteSize
			withEntityRowCount
			{...getEntityPageSidebarProps(EntityType.table)}
		>
			<OverviewPageTabs
				key={tableEntity.id}
				entity={tableEntity}
				currentTab={currentTab || 'columns'}
				tabs={tabs}
				onTabChange={handleTabChange}
			/>
		</EntityPageLayout>
	);
});

// by memoizing the inner component, we can avoid re-rendering the entire page on tab changes and only re-render the tab panel
export default function TableEntityPage() {
	const id = useParamsIdSuffixUuid();

	return <InnerTableEntityPage id={id} />;
}
