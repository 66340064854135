import IconEmojiSelector from '@repo/common/components/IconEmojiSelector';
import { EntityType } from '@repo/common/enums/entityType';
import { trimMarkdown } from '@repo/common/utils/sanitizeMarkdownString.ts';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import {
	useAddToCollection,
	useAuthUser,
	useCollectionTabs,
	useUpdateCollection,
} from '../../api';
import { useSuspenseSecodaEntity } from '../../api/hooks/secodaEntity/useSuspenseSecodaEntity';
import TabsPanel from '../../components/CollectionsEntityTabs/CollectionsTabsPanel.tsx';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityPageLayout from '../../components/EntityPageLayout';
import EntityPageLayoutSkeleton from '../../components/EntityPageLayout/EntityPageLayoutSkeleton.tsx';
import type { OverviewPageTab } from '../../components/Overview/OverviewPageTabs';
import OverviewPageTabs from '../../components/Overview/OverviewPageTabs';
import ResourceSelectorPopover from '../../components/ResourceSelectorPopover/ResourceSelectorPopover.tsx';
import type { SecodaEntity } from '../../lib/models/index.ts';
import { trackEvent } from '../../utils/analytics';
import { useCheckEntityUpdatePermission } from '../../utils/authorization/roles.ts';
import { useSilentUrlUpdate } from '../../utils/hook/useSilentUrlUpdate.ts';
import { useTabNavigate } from '../../utils/hook/useTabNavigate.ts';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
const InnerCollectionPage = observer(({ id }: { id: string }) => {
	const { tab: urlTabParam } = useParams();
	const { user, workspace } = useAuthUser();
	const navigateToTab = useTabNavigate();
	const { currentTab, setCurrentTab } = useSilentUrlUpdate();

	const { data: collection } = useSuspenseSecodaEntity({
		id,
	});

	useMount(() => {
		trackEvent('collection_entity/open', {}, user, workspace);
	});

	const { data: collectionTabs, isLoading: isTabsLoading } =
		useCollectionTabs(id);

	useEffect(() => {
		if (collection && collectionTabs) {
			if (urlTabParam) {
				setCurrentTab(urlTabParam);
			} else {
				const hasDocumentation =
					trimMarkdown(collection.definition ?? '').length > 2;
				if (hasDocumentation && isEmpty(collectionTabs)) {
					setCurrentTab('documentation');
				} else {
					setCurrentTab(collectionTabs[0]?.value ?? 'documentation');
				}
			}
		}
	}, [collection, collectionTabs, urlTabParam]);

	useEffect(() => {
		if (urlTabParam) {
			setCurrentTab(urlTabParam);
		}
	}, [urlTabParam]);

	const canUpdateEntity = useCheckEntityUpdatePermission({
		entity: collection,
	});
	const readOnly = !canUpdateEntity;

	const { mutateAsync } = useUpdateCollection({
		disableInvalidation: true,
		options: {
			onSuccess: () => {
				trackEvent('collection/properties/update', {}, user, workspace!);
			},
		},
	});

	const { addToCollection } = useAddToCollection();

	const handleAddToCollection = useCallback(
		async (entity: SecodaEntity) => {
			await addToCollection(collection?.id ?? '', entity);
			navigateToTab(entity.entity_type);
		},
		[addToCollection, collection?.id, navigateToTab]
	);

	const updateCollectionEntity = useCallback(
		async (key: string, value: DjangoValueType) => {
			await mutateAsync({
				data: {
					id,
					[key]: value,
				},
			});
		},
		[id, mutateAsync]
	);

	const handleIconChange = useCallback(
		(value: string) => {
			updateCollectionEntity('icon', value);
		},
		[updateCollectionEntity]
	);

	const customActions = useMemo(
		() => (
			<ResourceSelectorPopover
				key="add-to-collection"
				targetButtonIconName="plus"
				targetButtonLabel="Add resource"
				collectionId={collection?.id ?? ''}
				types={[
					'table',
					'dashboard',
					'job',
					'metric',
					'dictionary_term',
					'question',
					'document',
					'collection',
				]}
				updateEntity={handleAddToCollection}
			/>
		),
		[collection?.id, handleAddToCollection]
	);

	const hasDocumentation = useMemo(
		() => trimMarkdown(collection?.definition ?? '').length > 2,
		[collection?.definition]
	);

	const handleTabChange = useCallback(
		(tab: string) => {
			setCurrentTab(tab);
		},
		[setCurrentTab]
	);

	const tabs = useMemo(() => {
		const tabsArray: OverviewPageTab[] = [];

		if (hasDocumentation) {
			tabsArray.push({
				value: 'documentation',
				label: 'Documentation',
				component: <TabsPanel activeTab="documentation" collectionId={id} />,
			});
		}

		if (collectionTabs && collectionTabs.length > 0) {
			collectionTabs.forEach((tab) => {
				if (tab.value !== 'documentation' || !hasDocumentation) {
					tabsArray.push({
						value: tab.value,
						label: tab.label,
						component: <TabsPanel activeTab={tab.value} collectionId={id} />,
					});
				}
			});
		}

		return tabsArray;
	}, [hasDocumentation, collectionTabs, id]);

	if (isTabsLoading || !currentTab || !collection) {
		return <EntityPageLayoutSkeleton withTabs withTable />;
	}

	return (
		<EntityPageLayout
			key={collection.id}
			name="Collection"
			entity={collection as never}
			icon={
				<IconEmojiSelector
					value={collection.icon ?? 'iconName:folder iconColor:#4a4a4a'}
					onChange={handleIconChange}
					entityType={collection.entity_type}
					readOnly={readOnly}
				/>
			}
			updateEntity={updateCollectionEntity}
			withDescription
			withPinnedToggle
			withCustomActions={customActions}
			customActionsPosition={'right'}
			{...getEntityPageSidebarProps(EntityType.collection)}
		>
			<OverviewPageTabs
				entity={collection}
				currentTab={currentTab}
				tabs={tabs}
				onTabChange={handleTabChange}
			/>
		</EntityPageLayout>
	);
});

// By memoizing the inner component, we can avoid re-rendering the entire page on tab changes and only re-render the tab panel
export default function CollectionPage() {
	const id = useParamsIdSuffixUuid();

	return <InnerCollectionPage id={id} />;
}
