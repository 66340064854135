import {
	Box,
	createStyles,
	getStylesRef,
	Group,
	Stack,
	Tooltip,
} from '@mantine/core';
import { useDisclosure, useScrollIntoView } from '@mantine/hooks';
import type { Message } from '@repo/api-codegen';
import { useShare } from '@repo/api-codegen';
import { AIAvatar } from '@repo/common/components/AI/Avatar';
import { EntityType } from '@repo/common/enums/entityType';
import { AvatarSkeleton, IconButton } from '@repo/foundations';
import { isEqual } from 'lodash-es';
import type { RefObject } from 'react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import CopyButton from '../../../../../../packages/foundations/components/Buttons/CopyButton';
import { useAuthUser, type IEmbeddedPrompt } from '../../../api';
import { useAIFeedback } from '../../../api/hooks/ai/useAIFeedback';
import { useTemplateList } from '../../../api/hooks/templates';
import { SentimentType } from '../../../components/AIAssistant/AIFeedbackModal/constants';
import { useStreamingContent } from '../../../hooks/useStreamingContent';
import { trackEvent } from '../../../utils/analytics';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { AttachmentContainer } from '../../Editor/outline/src/components/AttachmentContainer';
import { entityModalStore } from '../../EntityModal/store';
import { RichEditor } from '../../RichEditor';
import { UserAvatar } from '../../UserAvatar';
import {
	getAttachmentUrlsInPromptMessage,
	getFileNameFromUrl,
	sanitizePromptMessage,
} from '../utils';
import { AISteps } from './AISteps';
import { MessageAIError } from './MessageAIError';
import { MessageAILoading } from './MessageAILoading';
import { MessageBody } from './MessageBody';
import { MessageContent } from './MessageContent';

const useStyles = createStyles((theme) => ({
	markdown: {
		color: theme.other.getColor('text/primary/default'),
		fontSize: theme.fontSizes.md,
		fontWeight: theme.other.typography.weight.regular,
		lineHeight: theme.other.typography.lineHeight.text.md,
	},
	richEditorContainer: {
		'.markdown-body': {
			color: theme.other.getColor('text/primary/default'),
			fontSize: theme.fontSizes.md,
			fontWeight: theme.other.typography.weight.bold,
			lineHeight: theme.other.typography.lineHeight.text.md,
		},
	},
	toolbar: {
		ref: getStylesRef('toolbar'),
		marginLeft: `-${theme.spacing['3xs']}`,
	},
	messageContainer: {
		[`.${getStylesRef('toolbar')}`]: {
			opacity: 0,
		},
		[`&:hover .${getStylesRef('toolbar')}`]: {
			opacity: 1,
		},
	},
	attachment: {
		paddingBottom: theme.spacing.sm,
	},
	negativeFeedbackDisabled: {
		color: theme.other.getColor('text/primary/default'),
		'&:hover': { backgroundColor: 'unset', cursor: 'default' },
	},
}));

type MessageGroupProps = {
	message: IEmbeddedPrompt;
	parentPrompt?: IEmbeddedPrompt;
	scrollAreaRef?: RefObject<HTMLDivElement>;
	onRetry?: () => void;
	onNegativeFeedback?: () => void;
};

function MessageGroupInternal({
	message,
	parentPrompt,
	scrollAreaRef,
	onRetry,
	onNegativeFeedback,
}: MessageGroupProps) {
	const { user: authUser, workspace } = useAuthUser();
	const { user } = message;

	const { classes } = useStyles();
	const { aiToQuestionsAnswers, aiV3 } = useFeatureFlags();
	const { data: defaultQuestionTemplates } = useTemplateList({
		filters: {
			is_default: true,
		},
	});
	const { mutateAsync: sharePrompt } = useShare({});

	const showQuestionModal = useCallback(
		({ title, description }: { title?: string; description?: string } = {}) => {
			entityModalStore.open({
				type: EntityType.question,
				template: defaultQuestionTemplates?.results?.[0],
				title: title || '',
				description: description || '',
				metadata: {
					owners: [user?.id].filter(Boolean),
					ai_prompt: parentPrompt,
					share_prompt: async (promptId: string) => {
						await sharePrompt({
							pathParams: { promptId },
							body: { shared_globally: true },
						});
					},
				},
			});
		},
		[defaultQuestionTemplates?.results, user?.id, parentPrompt, sharePrompt]
	);

	const parsePromptForModal = (prompt: string | undefined) => {
		if (!prompt) return;

		const sanitizedPrompt = sanitizePromptMessage(prompt)
			.replace(/(<([^>]+)>)/gi, '')
			.replace(/\n+/g, ' ');
		const markdownLinkRegex = /!?\[.*?\]\(.*?\)/g;
		const title = sanitizedPrompt.replace(markdownLinkRegex, '').trim();
		const attachments = sanitizedPrompt.match(markdownLinkRegex) || [];

		showQuestionModal({ title, description: attachments.join('\n') });
	};

	const { showPositiveFeedbackModal, showNegativeFeedbackModal } =
		useAIFeedback({
			id: message.id,
			onNegativeFeedbackSubmitted: onNegativeFeedback,
		});
	const { targetRef, scrollableRef, scrollIntoView } = useScrollIntoView<
		HTMLDivElement,
		HTMLDivElement
	>({ duration: 200 });

	const handleNegativeFeedback = useCallback(() => {
		showNegativeFeedbackModal();
	}, [showNegativeFeedbackModal]);

	useEffect(() => {
		if (scrollAreaRef?.current) {
			scrollableRef.current = scrollAreaRef.current;
		}
	}, [scrollAreaRef, scrollableRef]);

	const [showSteps, { toggle: toggleSteps }] = useDisclosure(false);

	useEffect(() => {
		if (showSteps) {
			scrollIntoView({ alignment: 'end' });
		}
	}, [showSteps, scrollIntoView]);

	const messageContent = useStreamingContent(
		message.status === 'running',
		message.response?.content ?? ''
	);

	const messages = useMemo(
		() =>
			message.structured_response?.length
				? message.structured_response
				: [
						{
							content: messageContent,
							sender: 'assistant',
							use_tools: [],
							tool_response: null,
						},
					],
		[message.structured_response, messageContent]
	);

	const shouldDisplayLoading =
		message.status === 'pending' ||
		(message.status === 'running' &&
			messageContent.length === 0 &&
			message.structured_response?.length === 0);
	const shouldDisplayFailure = message.status === 'failed';
	const shouldDisplayAnswerToolbar = message.status === 'completed';
	const hasSteps = (message.response?.steps?.length ?? 0) > 0;

	return (
		<Stack spacing="sm" pb="sm">
			<Box className={classes.messageContainer}>
				<MessageBody
					avatar={
						user ? (
							<UserAvatar user={user} size="md" />
						) : (
							<AvatarSkeleton size="md" />
						)
					}
				>
					{getAttachmentUrlsInPromptMessage(message.prompt).map((url) => (
						<div key={url} className={classes.attachment}>
							<AttachmentContainer
								title={getFileNameFromUrl(url)}
								href={url}
								isSelected={false}
								canDownload={true}
							/>
						</div>
					))}
					<RichEditor readOnly value={sanitizePromptMessage(message.prompt)} />
					{aiToQuestionsAnswers && (
						<Group spacing={0} className={`${classes.toolbar}`}>
							<Group spacing={0} noWrap>
								<Tooltip label="Ask team">
									<IconButton
										iconName="messageCircleQuestion"
										variant="tertiary"
										onClick={() => {
											trackEvent(
												'question/create_from_ai_clicked',
												{ source: 'prompt' },
												authUser,
												workspace
											);
											parsePromptForModal(message.prompt);
										}}
									/>
								</Tooltip>
							</Group>
						</Group>
					)}
				</MessageBody>
			</Box>
			<MessageBody
				avatar={
					<AIAvatar size="md" speed={shouldDisplayLoading ? 'slow' : 'none'} />
				}
			>
				{shouldDisplayLoading && (
					<MessageAILoading steps={message.response?.steps ?? []} />
				)}
				{shouldDisplayFailure && <MessageAIError onRetry={onRetry} />}
				{!shouldDisplayLoading && !shouldDisplayFailure && (
					<Box className={classes.messageContainer}>
						{(message.status === 'running' ||
							message.status === 'completed') && (
							<MessageContent
								messages={(messages || []) as unknown as Message[]}
								isRunning={message.status === 'running'}
								viewportRef={scrollAreaRef ?? { current: null }}
							/>
						)}
						{shouldDisplayAnswerToolbar && (
							<Group spacing={0} className={`${classes.toolbar}`}>
								<CopyButton value={message.response?.content || ''} />
								<Group spacing={0} noWrap>
									{message.user_feedback_raw?.sentiment ===
									SentimentType.Positive ? (
										<IconButton
											iconName="thumbUpFilled"
											variant="tertiary"
											tooltip="Positive feedback for this prompt already submitted"
										/>
									) : (
										<IconButton
											iconName="thumbUp"
											variant="tertiary"
											onClick={showPositiveFeedbackModal}
											tooltip="Good response"
										/>
									)}
									{message.user_feedback_raw?.sentiment ===
									SentimentType.Negative ? (
										<IconButton
											iconName="thumbDownFilled"
											variant="tertiary"
											tooltip="Negative feedback for this prompt already submitted"
										/>
									) : (
										<IconButton
											iconName="thumbDown"
											variant="tertiary"
											onClick={handleNegativeFeedback}
											tooltip="Bad response"
										/>
									)}
								</Group>
								{hasSteps && !aiV3 && (
									<IconButton
										iconName="listSearch"
										variant="tertiary"
										onClick={toggleSteps}
										tooltip="Show steps"
									/>
								)}
								{aiToQuestionsAnswers && (
									<IconButton
										iconName="messageCircleQuestion"
										variant="tertiary"
										onClick={() => {
											trackEvent(
												'question/create_from_ai_clicked',
												{ source: 'response' },
												authUser,
												workspace
											);
											parsePromptForModal(message.prompt);
										}}
									/>
								)}
							</Group>
						)}
					</Box>
				)}
				{showSteps && <AISteps ref={targetRef} prompt={message} />}
			</MessageBody>
		</Stack>
	);
}

export const MessageGroup = memo(MessageGroupInternal, (prevProps, nextProps) =>
	isEqual(prevProps.message, nextProps.message)
);
