import type { ISecodaEntity } from '../../api';
import type { PropertySelectorProps } from './PropertySelector';
import { PropertySelector } from './PropertySelector';

export interface StatusPropertySelectorProps
	extends Pick<PropertySelectorProps, 'onChange'> {
	entity: ISecodaEntity;
	viewerOfEntity: boolean;
}

export function StatusPropertySelector({
	entity,
	viewerOfEntity,
	...propertySelectorProps
}: StatusPropertySelectorProps) {
	return (
		<PropertySelector
			selected={entity.published}
			type="single"
			value="published"
			iconType="tabler"
			isViewerUser={viewerOfEntity}
			readOnly={viewerOfEntity}
			{...propertySelectorProps}
		/>
	);
}
