import { apiQueryKey } from '@repo/api-codegen';
import { useCallback } from 'react';
import type { ISecodaEntity } from '../../../api';
import { queryClient, useUpdateSecodaEntity } from '../../../api';
import { OwnerSelector } from '../../EntityModal/Metadata/OwnerSelector';

interface AddOwnerActionProps {
	entity: ISecodaEntity;
}

export function AddOwnerAction({ entity }: AddOwnerActionProps) {
	const { mutateAsync: updateEntity } = useUpdateSecodaEntity({});

	const handleChangeUserOwners = useCallback(
		(owners: string[]) =>
			updateEntity(
				{
					data: {
						id: entity.id,
						owners,
					},
				},
				{
					onSuccess: () => {
						queryClient.invalidateQueries({
							queryKey: apiQueryKey('quality/{entity_id}', {
								entity_id: entity.id,
							}),
						});
					},
				}
			),
		[entity.id, updateEntity]
	);

	const handleChangeGroupOwners = useCallback(
		(owners: string[]) =>
			updateEntity(
				{
					data: {
						id: entity.id,
						owners_groups: owners,
					},
				},
				{
					onSuccess: () => {
						queryClient.invalidateQueries({
							queryKey: apiQueryKey('quality/{entity_id}', {
								entity_id: entity.id,
							}),
						});
					},
				}
			),
		[entity.id, updateEntity]
	);

	return (
		<OwnerSelector
			initialValue={entity.owners ?? []}
			onChangeUserOwners={handleChangeUserOwners}
			onChangeGroupOwners={handleChangeGroupOwners}
			forceVariant="primary"
			placeholder="Add owner"
		/>
	);
}
