import moment from 'moment';
import { useEffect, useState } from 'react';
import { useWorkspace } from '../api';
import {
	ENTERPRISE_PLAN,
	FREE_PLAN,
	workspacePlans as plans,
	TRIAL_PLAN,
} from '../data/plans';
import type { Plan } from '../interfaces/Plan';
import { useFeatureFlags } from '../utils/featureFlags';

// Environment-specific default billing plan configuration
const DEFAULT_BILLING_PLAN = import.meta.env.VITE_DEFAULT_BILLING_PLAN;
const TRIAL_LENGTH = 14; // Duration of trial period in days

export const evaluateSubscriptionStatus = (
	currentPlanId: string | null,
	trialActivationDate: moment.MomentInput,
	systemDefaultPlanId: string | undefined,
	bypassDefaultPlan?: boolean
) => {
	let activePlan: Plan | undefined;
	let remainingTrialDays = 0;
	let isPaywallActive = false;
	let isTrialActive = false;

	if (
		!bypassDefaultPlan &&
		systemDefaultPlanId?.toLowerCase() === ENTERPRISE_PLAN.id?.toLowerCase()
	) {
		// The system default plan (DEFAULT_BILLING_PLAN) takes precedence over the workspace plan
		activePlan = ENTERPRISE_PLAN;
	} else if (
		currentPlanId &&
		// This check should not be required, but for now, it is. This is
		// because retool always adds 'trial' as plan. This is not necessary,
		// and should be implied from the trialActivationDate.
		currentPlanId?.toLowerCase() !== TRIAL_PLAN.id?.toLowerCase()
	) {
		activePlan =
			plans.find((availablePlan) =>
				availablePlan.all_plans.includes(currentPlanId)
			) ||
			plans.find(
				(availablePlan) =>
					availablePlan.id?.toLowerCase() === currentPlanId?.toLowerCase()
			) ||
			FREE_PLAN;
	} else {
		// If no plan has been set, evaluate remaining trial period
		const daysSinceTrialStart = Math.abs(
			moment(trialActivationDate).diff(moment(), 'days')
		);
		remainingTrialDays = TRIAL_LENGTH - daysSinceTrialStart;
		if (remainingTrialDays <= 0) {
			activePlan = undefined;
			isPaywallActive = true;
			isTrialActive = false;
		} else {
			activePlan = TRIAL_PLAN;
			isPaywallActive = false;
			isTrialActive = true;
		}
	}

	return {
		plan: activePlan,
		daysLeftInTrial: remainingTrialDays,
		paywall: isPaywallActive,
		trial: isTrialActive,
		isPayingCustomer:
			!isTrialActive &&
			!isPaywallActive &&
			!!activePlan?.id &&
			activePlan?.id !== FREE_PLAN.id &&
			activePlan?.id !== TRIAL_PLAN.id,
	};
};

export const usePlan = () => {
	const { workspace } = useWorkspace();
	const trialStartDate = workspace?.free_trial_started_at;
	const workspaceActivePlanId = workspace.customer_plan_id;

	// Feature flag to override default billing plan behavior in CI environment
	const { skipDefaultBillingPlan: bypassDefaultPlan } = useFeatureFlags();

	// Initialize with enterprise plan to prevent UI flicker during load
	const [subscriptionStatus, setSubscriptionStatus] = useState({
		plan: ENTERPRISE_PLAN as Plan | undefined,
		daysLeftInTrial: 0,
		paywall: false,
		trial: false,
		isPayingCustomer: true,
	});

	useEffect(() => {
		const planDetails = evaluateSubscriptionStatus(
			workspaceActivePlanId,
			trialStartDate,
			DEFAULT_BILLING_PLAN,
			bypassDefaultPlan
		);
		setSubscriptionStatus(planDetails);
	}, [bypassDefaultPlan, trialStartDate, workspaceActivePlanId]);

	return subscriptionStatus;
};
