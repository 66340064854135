/*
We can never repeat these keys or else customers won't be shown the modal with new content.
Please add keys to the used list below to make sure we never repeat them in the future:
Previously used keys:
- education-modal-of-table-v2
- education-modal-of-data-quality-score
- education-modal-of-glossary-terms
- education-modal-of-dark-mode
*/

import type { MantineNumberSize } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface EducationModalCopy {
	title: string;
	children: ReactNode;
	imageUrl: string;
	primaryAction: (handleClose: VoidFunction) => ReactNode | string;
	learnMoreLink?: string;
	width?: MantineNumberSize;
	expiresAt?: string;
}

function DarkModePrimaryButton() {
	const navigate = useNavigate();

	return (
		<Button
			data-testid="education-continue-button"
			variant="primary"
			onClick={() => navigate('/settings/preferences')}
		>
			Go to preferences
		</Button>
	);
}

export const EDUCATION_MODALS = {
	'education-modal-of-dark-mode': {
		title: 'Dark mode is here',
		imageUrl:
			'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/1a5048f0-1808-40cf-b97a-043458797600/backgrounds',
		children: (
			<Text color="text/secondary/default">
				{`Secoda now supports dark mode, providing a more comfortable viewing experience in low-light environments. Choose Light, Dark, or System in preferences to personalize your experience.`}
			</Text>
		),
		primaryAction: () => <DarkModePrimaryButton />,
		width: 538,
		expiresAt: '2025-03-30',
	} as EducationModalCopy,
} as const;
