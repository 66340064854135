import { Stack } from '@mantine/core';
import type { EntityPolicyOut } from '@repo/api-codegen';
import { useApiListEntityPolicies } from '@repo/api-codegen';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import type { ISecodaEntity } from '../../api';
import { TableV2 } from '../TableV2';
import { useColumns } from './columns';

export interface IPolicyListProps {
	entity: ISecodaEntity;
}

const COLUMN_VISIBILITY = {
	catalogType: 'policies',
	catalogServerType: 'policies',
} as const;

const EntityPolicyList = observer(({ entity }: IPolicyListProps) => {
	const navigate = useNavigate();
	const columns = useColumns();

	const handleRowClick = useCallback(
		(row: EntityPolicyOut, index, event) => {
			navigate(`/policy/${row.id}`);
		},
		[navigate]
	);

	const defaultSearchParams = useMemo(
		() => ({
			pathParams: {
				entityId: entity.id,
			},
		}),
		[entity.id]
	);

	return (
		<Stack>
			<TableV2<EntityPolicyOut>
				pluralTypeString="policies"
				tableCacheKey="entity-policies"
				columns={columns}
				columnVisibility={COLUMN_VISIBILITY}
				// @ts-ignore
				usePaginationList={useApiListEntityPolicies}
				useCodegenListInterface
				withSearch
				defaultSort={null}
				usePaginationListOptions={defaultSearchParams}
				onRowClick={handleRowClick}
				noRecordsText={`No policies on this entity`}
			/>
		</Stack>
	);
});

EntityPolicyList.displayName = 'EntityPolicyList';
export default EntityPolicyList;
