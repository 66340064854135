import { useApiListTeams } from '@repo/api-codegen';
import { map, noop } from 'lodash-es';
import { useMemo } from 'react';
import { useAuthUser, type IIntegration, type ISecodaEntity } from '../../api';
import type { PropertySelectorProps } from './PropertySelector';
import { PropertySelector } from './PropertySelector';

export interface TeamsPropertySelectorProps
	extends Pick<PropertySelectorProps, 'onChange'> {
	entity: ISecodaEntity;
	viewerOfEntity: boolean;
	integration?: IIntegration;
}

export function TeamsPropertySelector({
	entity,
	viewerOfEntity,
	integration,
	...propertySelectorProps
}: TeamsPropertySelectorProps) {
	const { isGuestUser } = useAuthUser();

	const { data: teams } = useApiListTeams(
		{
			queryParams: {
				only_joined: isGuestUser,
			},
		},
		{
			select: (res) => res.results,
		}
	);

	const teamOptions = useMemo(
		() =>
			map(teams, (team) => ({
				label: team.name,
				value: team.id,
				icon: team.icon,
				navigateTo: '/teams',
				onClick: noop,
			})),
		[teams]
	);

	return (
		<PropertySelector
			inheritedValues={integration?.teams}
			inheritedValuesTooltip={`From ${integration?.name}`}
			selected={entity?.teams ?? []}
			type="multi"
			value="teams"
			iconType="emoji"
			options={teamOptions}
			isViewerUser={viewerOfEntity}
			readOnly={viewerOfEntity}
			{...propertySelectorProps}
		/>
	);
}
