import { Box, createStyles, Menu } from '@mantine/core';
import type { ButtonVariants, IconNames } from '@repo/foundations';
import { useDebounceEffect } from 'ahooks';
import {
	find,
	forEach,
	isEmpty,
	isNil,
	map,
	size,
	startCase,
	uniq,
} from 'lodash-es';
import type React from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { pluralize } from '../../utils';
import { fuzzySearchFilter } from '../../utils/fuse';
import { EmptyState, type ButtonDetails } from '../EmptyState';
import type { ItemIconType } from '../ItemIcon';
import SelectorSearch from '../MultiSelector/SelectorSearch';
import type { ISingleSelectorItemProps } from './SingleSelectorItem';
import SingleSelectorItem from './SingleSelectorItem';
import SingleSelectorTarget from './SingleSelectorTarget';
import type { SelectablePropertyItem } from './types';

export interface ISingleSelectorProps {
	width?: number;
	placeholder?: string;
	hideOnEmpty?: boolean;
	placeholderIconName?: IconNames;
	variant?: ButtonVariants;
	initialSelected?: string | boolean;
	property: string;
	iconType: ItemIconType;
	options: SelectablePropertyItem[];
	isViewerUser: boolean;
	searchable: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange?: (value: any) => void;
	readOnly?: boolean;
	selectorItem?: React.FunctionComponent<ISingleSelectorItemProps>;
	itemSize?: number;
	itemsToRender?: number;
	onSearchTermChange?: (value: string) => void;
	target?: React.ReactElement;
	defaultOpened?: boolean;
	displayIcon?: boolean;
	supportSelected?: boolean;
	onClose?: () => void;
	emptyState?: React.ReactNode;
	/**
	 * Determines if the component is within a portal.
	 * Needs to be set to false for chrome extension to have
	 * proper styling
	 */
	withinPortal?: boolean;

	opened?: boolean;
	onTargetClick?: () => void;
}

const useStyles = createStyles({
	menuDropdown: {
		minHeight: 'fit-content',
	},
});

function SingleSelector({
	defaultOpened = false,
	displayIcon = true,
	supportSelected = true,
	hideOnEmpty = false,
	iconType,
	initialSelected,
	isViewerUser,
	itemSize = 32,
	itemsToRender = 8,
	onChange,
	opened,
	onTargetClick,
	onClose,
	onSearchTermChange,
	options,
	placeholder,
	placeholderIconName,
	property,
	readOnly = false,
	searchable,
	selectorItem: SelectorItem = SingleSelectorItem,
	target,
	variant,
	width = 300,
	emptyState,
	withinPortal = true,
}: ISingleSelectorProps) {
	const [selected, setSelected] = useState(initialSelected);
	const [searchTerm, setSearchTerm] = useState('');
	const { classes } = useStyles();

	useDebounceEffect(
		() => {
			onSearchTermChange?.(searchTerm);
		},
		[searchTerm],
		{
			wait: 500,
		}
	);

	useEffect(() => {
		setSelected(initialSelected);
	}, [initialSelected, setSelected]);

	const hasGroups = useMemo(
		() => !isEmpty(options) && 'group' in options[0],
		[options]
	);

	const filteredOptions = useMemo(() => {
		if (isEmpty(searchTerm)) {
			return options;
		}
		return fuzzySearchFilter(searchTerm, options, ['label']).filter(
			(f) => !f.hidden
		);
	}, [searchTerm, options]);

	// Memoized filtered not selected items grouped by groups based on the search term, inherited values, and user permissions
	const filteredGroups: { [key: string]: SelectablePropertyItem[] } =
		useMemo(() => {
			if (!hasGroups) {
				return {};
			}

			const newGroups: { [key: string]: SelectablePropertyItem[] } = {};

			forEach(filteredOptions, (option) => {
				if (option.group) {
					if (!newGroups[option.group]) {
						newGroups[option.group] = [option];
					} else {
						newGroups[option.group].push(option);
					}
				}
			});

			return newGroups;
		}, [filteredOptions, hasGroups]);

	const groups = useMemo(
		() => uniq(map(filteredOptions, (option) => option.group ?? '')),
		[filteredOptions]
	);

	const item = useMemo(
		() =>
			find(
				options,
				(option) => selected === option.value
			) as SelectablePropertyItem,
		[options, selected]
	) as SelectablePropertyItem;

	const handleClearSearch = () => {
		setSearchTerm('');
	};

	const handleOnChange = useCallback(
		(i: SelectablePropertyItem) => {
			onChange?.(i.value);
			setSelected(i.value);
		},
		[onChange]
	);

	const itemContent = useCallback(
		(_: number, element: SelectablePropertyItem) => (
			<SelectorItem
				isSelected={supportSelected && selected === element.value}
				iconType={iconType}
				item={element}
				isViewerUser={isViewerUser}
				onClick={handleOnChange}
				displayIcon={displayIcon}
			/>
		),
		[handleOnChange, iconType, isViewerUser, supportSelected, selected]
	);

	const menuClassNames = useMemo(
		() => ({
			dropdown: classes.menuDropdown,
		}),
		[classes.menuDropdown]
	);

	const buttons: ButtonDetails[] = useMemo(
		() => [
			{
				name: 'Clear search',
				action: handleClearSearch,
				isPrimary: false,
				size: 'sm',
			},
		],
		[handleClearSearch]
	);

	if (readOnly || isViewerUser) {
		return (
			target || (
				<SingleSelectorTarget
					hideOnEmpty={hideOnEmpty}
					iconType={iconType}
					isViewerUser={isViewerUser}
					readOnly={readOnly || isNil(onChange)}
					selected={supportSelected ? item : undefined}
				/>
			)
		);
	}

	const height =
		size(filteredOptions) > itemsToRender
			? 256
			: size(filteredOptions) * itemSize;

	return (
		<Menu
			withinPortal={withinPortal}
			width={width}
			position="bottom-start"
			defaultOpened={defaultOpened}
			onClose={onClose}
			opened={opened}
			classNames={menuClassNames}
		>
			<Menu.Target>
				{target || (
					<SingleSelectorTarget
						hideOnEmpty={hideOnEmpty}
						readOnly={readOnly || isNil(onChange)}
						placeholder={placeholder}
						placeholderIconName={placeholderIconName}
						variant={variant}
						selected={supportSelected ? item : undefined}
						iconType={iconType}
						isViewerUser={isViewerUser}
						onTargetClick={onTargetClick}
					/>
				)}
			</Menu.Target>
			<Menu.Dropdown>
				{searchable && (
					<SelectorSearch
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
					/>
				)}
				{searchable &&
					filteredOptions.length === 0 &&
					(emptyState || (
						<EmptyState
							iconName="search"
							title={`No ${pluralize(property)} found`}
							description="No resources or invalid search"
							buttons={buttons}
							includeGoBack={false}
							size="sm"
						/>
					))}
				{hasGroups &&
					!isViewerUser &&
					map(groups, (group) => (
						<Box key={group}>
							<Menu.Label>{startCase(group)}</Menu.Label>
							<Virtuoso
								data={filteredGroups[group]}
								totalCount={size(filteredGroups)}
								itemContent={itemContent}
								style={{
									height:
										size(filteredGroups[group]) > itemsToRender
											? 256
											: size(filteredGroups[group]) * itemSize,
								}}
							/>
						</Box>
					))}
				{!hasGroups && (
					<Virtuoso
						data={filteredOptions}
						totalCount={size(filteredOptions)}
						itemContent={itemContent}
						style={{
							height,
						}}
					/>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}

export default memo(SingleSelector);
