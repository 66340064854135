import { Divider, Group } from '@mantine/core';
import { AddFilter, Filter } from '@repo/common/components/Filter';
import {
	FILTER_OPTIONS_DIVIDER,
	OPERATORS_CONFIG,
	SORT_OPTIONS,
} from '@repo/common/components/Filter/constants';
import { TopLevelOperatorToggle } from '@repo/common/components/Filter/TopLevelOperatorToggle';
import type {
	FilterOption,
	FilterView,
} from '@repo/common/components/Filter/types';
import { SortMenu } from '@repo/common/components/SortMenu/SortMenu';
import { Button } from '@repo/foundations';
import { size } from 'lodash-es';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { FilterViewModal } from '../Filter/Views/FilterViewModal';
import { FilterViews } from '../Filter/Views/FilterViews';
import { closeAllModals, openModal } from '../ModalManager';
import { useGlobalSearch } from './useGlobalSearch';

export interface FilterBarProps {
	showFilterViews?: boolean;
	onOpenFilterViewModal?: (view?: FilterView | null) => void;
	showTopLevelOperator?: boolean;
}

export const FilterBar = observer(
	({
		showFilterViews,
		showTopLevelOperator,
		onOpenFilterViewModal,
	}: FilterBarProps) => {
		const store = useGlobalSearch();

		useEffect(() => {
			function loadFilterOptions() {
				store.prefetchPromises();
			}

			document.addEventListener('load', loadFilterOptions);

			return () => {
				document.removeEventListener('load', loadFilterOptions);
			};
		}, [store]);
		const handleOpenFilterViewModal = useCallback(
			(view: FilterView | null) => {
				openModal({
					title: view ? 'Edit view' : 'Save view',
					children: (
						<FilterViewModal
							filterOptions={store.filterOptions}
							onClose={(newView?: FilterView | null) => {
								if (newView) {
									store.setFilterView(newView);
								}
								closeAllModals();
							}}
							view={view ?? store.view}
							selectedFilters={toJS(store.values)}
						/>
					),
				});
			},
			[store]
		);

		return (
			<Group position="apart" spacing="md" noWrap align="baseline">
				<Group spacing="2xs" py="md">
					<SortMenu
						onChange={store.setSort}
						options={Object.values(SORT_OPTIONS)}
						value={store.sort}
					/>
					{showFilterViews && (
						<>
							<FilterViews
								handleEdit={handleOpenFilterViewModal}
								onChange={store.setFilterView}
								value={store.view}
							/>
							{onOpenFilterViewModal && store.valuesDiffersFromViewValues && (
								<Button
									onClick={() => onOpenFilterViewModal(store.view)}
									size="sm"
								>
									Save view
								</Button>
							)}
							<Divider mx="2xs" orientation="vertical" />
						</>
					)}
					{store.values.map((value, idx) => {
						const filterOption = store.filterOptions.find(
							(option) =>
								option !== FILTER_OPTIONS_DIVIDER &&
								option.type === value.filterType
						) as FilterOption;

						if (!filterOption) {
							return null;
						}

						return (
							<Filter
								// eslint-disable-next-line react/no-array-index-key
								key={`filter-${idx}}`}
								value={toJS(value)}
								filterOption={filterOption}
								onChange={store.onChangeValue(idx)}
								onClear={store.onClearValue(idx)}
								showDetailedLabel
								operatorConfig={
									OPERATORS_CONFIG[
										filterOption.filterDropdownConfig.dropdownType
									]
								}
							/>
						);
					})}
					<AddFilter
						options={toJS(store.filterOptions)}
						onAddFilter={store.onAddValue}
					/>
				</Group>
				{showTopLevelOperator && size(store.values) >= 2 && (
					<TopLevelOperatorToggle
						value={store.topLevelOperator}
						onChange={store.setTopLevelOperator}
					/>
				)}
			</Group>
		);
	}
);
