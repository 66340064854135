import { Center, createStyles, Group, UnstyledButton } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { split } from 'lodash-es';
import type { IAction } from '../types';

export interface IActionItemProps {
	action: IAction;
	selected?: boolean;
	onHover: VoidFunction;
}

interface DefaultActionItemStyleProps {
	selected?: boolean;
}

const useStyles = createStyles(
	(theme, { selected }: DefaultActionItemStyleProps) => ({
		clearFiltersButton: {
			padding: theme.spacing.xs,
			borderRadius: theme.radius.sm,
			backgroundColor: selected
				? theme.other.getColor('fill/transparent/hover')
				: theme.other.getColor('surface/primary/default'),
			'&:hover': {
				backgroundColor: theme.other.getColor('fill/transparent/hover'),
			},
		},
		icon: {
			color: theme.other.getColor('icon/primary/default'),
		},
		rightIcon: {},
		suggestion: {
			color: theme.other.getColor('text/secondary/default'),
		},
		item: {
			color: theme.other.getColor('text/primary/default'),
		},
	})
);

function DefaultActionItem({ action, selected, onHover }: IActionItemProps) {
	const { classes } = useStyles({ selected });

	const { iconName, onClick, title, hidden = false } = action;

	if (hidden) {
		return null;
	}

	const [name, suggestion] = split(title, ' - ');

	return (
		<UnstyledButton
			className={classes.clearFiltersButton}
			onMouseOver={onHover}
			onClick={onClick}
		>
			<Group position="apart">
				<Group spacing="xs">
					{iconName && (
						<Center w={20}>
							<Icon name={iconName} />
						</Center>
					)}
					<Text size="sm" className={classes.item} span>
						{name}
						{suggestion && (
							<Text size="sm" className={classes.suggestion} span>
								&nbsp;&mdash;&nbsp;{suggestion}
							</Text>
						)}
					</Text>
				</Group>
				{selected && <Icon name="arrowBack" color="icon/secondary/default" />}
			</Group>
		</UnstyledButton>
	);
}

export default DefaultActionItem;
