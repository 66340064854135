import { Box, Stack } from '@mantine/core';
import type { CheckStateResponse } from '@repo/common/interfaces';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { IS_LOCAL_OR_DEVELOPMENT } from '../../../utils/envs';
import { checkChromeExtensionState } from '../../../utils/extension';
import { ChromeExtensionRow } from './ChromeExtensionRow';

type ChromeExtensionState = {
	main?: CheckStateResponse;
	beta?: CheckStateResponse;
};

export const ChromeExtensionSettings = observer(() => {
	const [chromeExtensionState, setChromeExtensionState] =
		useState<ChromeExtensionState>({
			main: undefined,
			beta: undefined,
		});

	const getChromeExtensionState = useCallback(async () => {
		const { main, beta } = await checkChromeExtensionState();
		setChromeExtensionState({ main, beta });
	}, []);

	useEffect(() => {
		getChromeExtensionState();
	}, [getChromeExtensionState]);

	const updateAuthenticatedState = useCallback(() => {
		getChromeExtensionState();
	}, [getChromeExtensionState]);

	const showBeta = !IS_LOCAL_OR_DEVELOPMENT && chromeExtensionState.beta;

	return (
		<Stack>
			<Box>
				<ChromeExtensionRow
					installed={chromeExtensionState.main?.installed ?? false}
					authenticated={chromeExtensionState.main?.authenticated ?? false}
					version={chromeExtensionState.main?.version}
					onAuthenticatedChange={updateAuthenticatedState}
					isBeta={false}
				/>
			</Box>
			{showBeta && (
				<Box>
					<ChromeExtensionRow
						installed={chromeExtensionState.beta?.installed ?? false}
						authenticated={chromeExtensionState.beta?.authenticated ?? false}
						version={chromeExtensionState.beta?.version}
						onAuthenticatedChange={updateAuthenticatedState}
						isBeta
					/>
				</Box>
			)}
		</Stack>
	);
});
