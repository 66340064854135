import { Stack, UnstyledButton, createStyles } from '@mantine/core';
import type { VersionHistoryOut } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash-es';
import { useMemo } from 'react';
import { VersionHistoryButtonItem } from './VersionHistoryButtonItem';

const useStyles = createStyles((theme) => ({
	button: {
		width: '100%',
		backgroundColor: theme.other.getColor('surface/primary/default'),
		padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
		textAlign: 'left',
		borderRadius: theme.radius.sm,

		'&:hover, &:focus': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
			textDecoration: 'none',
			color: theme.other.getColor('text/primary/default'),
		},

		'&:active, &[data-active="true"]': {
			backgroundColor: theme.other.getColor('surface/primary/active'),
		},
	},
}));

export interface VersionHistoryButtonProps {
	versionHistory: VersionHistoryOut;
	selected?: boolean;
	onClick: () => void;
}

export function VersionHistoryButton({
	versionHistory,
	selected,
	onClick,
}: VersionHistoryButtonProps) {
	const { classes } = useStyles();

	const performers = useMemo(
		() =>
			uniqBy(
				versionHistory.activity_logs.filter(
					(log) => !!log.performer_metadata || log.performer_type === 'UNKNOWN'
				),
				(log) => log.performer_metadata?.id ?? 'unknown'
			).map((log) => ({
				id: log.performer_metadata?.id ?? 'unknown',
				type: log.performer_type,
				metadata: log.performer_metadata!,
			})),
		[versionHistory.activity_logs]
	);

	return (
		<UnstyledButton
			key={versionHistory.id}
			onClick={onClick}
			className={classes.button}
			data-active={selected ? 'true' : undefined}
		>
			<Text size="sm" weight="bold">
				{dayjs(versionHistory.created_at).format('MMM D, YYYY [at] h:mm A')}
			</Text>
			<Stack spacing="xs" py="3xs">
				{performers.map((performer) => (
					<VersionHistoryButtonItem key={performer.id} performer={performer} />
				))}
			</Stack>
		</UnstyledButton>
	);
}
