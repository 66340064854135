import { Group, Stack } from '@mantine/core';
import {
	AvatarSkeleton,
	ParagraphSkeleton,
	TextSkeleton,
	TitleSkeleton,
} from '@repo/foundations';
import { SnapshotModalBody } from './SnapshotModalBody';

export function SnapshotModalContentSkeleton() {
	return (
		<SnapshotModalBody
			leftColumn={
				<Stack spacing="2xl">
					<Group pt="xl" pb="xs" spacing="xs">
						<TitleSkeleton text="Entity title here" />
					</Group>
					<Stack spacing="sm">
						<ParagraphSkeleton />
					</Stack>
				</Stack>
			}
			rightColumn={Array.from({ length: 3 }).map((_, index) => (
				<Stack key={index} spacing="3xs" py="xs" px="sm">
					<TextSkeleton size="sm" />
					<Group spacing="2xs" noWrap>
						<AvatarSkeleton size="xs" />
						<TextSkeleton size="sm" />
					</Group>
				</Stack>
			))}
			actions={[
				<TextSkeleton key="cancel" text="Cancel" />,
				<TextSkeleton key="restore" text="Restore version" />,
			]}
		/>
	);
}
