import { createStyles, Drawer } from '@mantine/core';
import { Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';
import type { SecodaEntity } from '../../lib/models';
import { ErrorBoundary } from '../ErrorBoundary';
import { SmallLoadingSpinner } from '../LoadingSpinner';
import ActivityLog from './ActivityLog';

interface IActivityLogDrawerProps {
	entity?: Pick<SecodaEntity, 'id' | 'entity_type' | 'created_at'>;
	onClose: () => void;
	open: boolean;
}

const useStyles = createStyles({
	content: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	body: {
		height: 'calc(100% - 72px)',
		overflowY: 'auto',
	},
});

const ActivityLogDrawer = observer(
	({ entity, open, onClose }: IActivityLogDrawerProps) => {
		const { classes } = useStyles();

		return (
			<Drawer
				classNames={classes}
				opened={open}
				onClose={onClose}
				title={
					<Text size="md" weight="bold">
						Activity
					</Text>
				}
				size="xl"
				padding="xl"
				position="right"
			>
				{open && (
					<ErrorBoundary>
						<Suspense fallback={<SmallLoadingSpinner />}>
							<ActivityLog entity={entity} />
						</Suspense>
					</ErrorBoundary>
				)}
			</Drawer>
		);
	}
);

export default ActivityLogDrawer;
