import type { MantineTheme } from '@mantine/styles';
import { rem } from '@mantine/styles';
import { has } from 'lodash-es';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import window from 'global';
import { darkShades, lightShades } from './primitives/colors';
import { space } from './primitives/space';

export function calculateSpace(value: number, unit: 'px' | 'rem' = 'px') {
	const spaceInPx = value * space[1];

	if (unit === 'px') {
		return `${spaceInPx}px`;
	}

	return rem(spaceInPx);
}

export type ColorNames = keyof typeof lightShades;

export function modifyOpacityOfRgbaColor(
	color: string,
	opacity: number
): string {
	if (color.startsWith('rgba')) {
		return color.replace(
			/rgba\(([^,]+),([^,]+),([^,]+),[^)]+\)/,
			`rgba($1,$2,$3,${opacity})`
		);
	}

	return color;
}

export function getColor(name: ColorNames) {
	const colorScheme =
		window?.localStorage?.getItem('mantine-color-scheme') || 'light';

	if (!has(lightShades, name)) {
		throw new Error(`Invalid color name: ${name}`);
	}

	// ColorScheme is sometimes set as '"light"' with quotes by Mantine
	// So, we have to check whether the text includes instead of ===
	if (colorScheme.includes('light')) {
		return lightShades[name];
	}

	return darkShades[name];
}

const isShade = (value: string): value is keyof typeof lightShades =>
	value in lightShades;

export function themeColor(theme: MantineTheme, color: string, shade?: number) {
	if (shade) {
		return theme.fn.themeColor(color, shade);
	}

	if (isShade(color)) {
		return getColor(color);
	}

	return color;
}
