import { Box } from '@mantine/core';
import { Switch } from '@repo/foundations';
import type { ReactNode } from 'react';
import { SettingsBlock } from '../SettingsBlock';

interface SettingsSwitchProps {
	title: string;
	description?: string | ReactNode;
	checked?: boolean;
	defaultChecked?: boolean;
	onChange: (checked: boolean) => void;
	disabled?: boolean;
	leftComponent?: ReactNode;
}

export function SettingsSwitch({
	title,
	description,
	checked,
	defaultChecked,
	onChange,
	disabled = false,
	leftComponent,
}: SettingsSwitchProps) {
	return (
		<SettingsBlock
			title={title}
			description={description}
			leftComponent={leftComponent}
			rightComponent={
				<Box onClick={(e) => e.stopPropagation()}>
					<Switch
						defaultChecked={defaultChecked}
						checked={checked}
						onChange={(event) => onChange(event.currentTarget.checked)}
						disabled={disabled}
					/>
				</Box>
			}
		/>
	);
}
