import { Button, Title } from '@repo/foundations';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import { appShellStore } from '../store';

function HomePageNavBar() {
	const { open } = appShellStore.homePageDrawerHandler;

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Title order={1} size="sm" data-testid="home-page-nav-bar-title">
					Home
				</Title>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<Button
					variant="primary"
					data-testid="home-page-customize-button"
					onClick={open}
				>
					Customize
				</Button>
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default HomePageNavBar;
