import type { MutationFunction } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { forEach } from 'lodash-es';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import type {
	CreateRequestParams,
	IBaseModel,
	IBaseModelCreateArgs,
	Namespace,
} from '../../types';

export function getDefaultCreateFn<
	TApiResponseData extends IBaseModel,
	TRequestData extends
		CreateRequestParams<TApiResponseData> = CreateRequestParams<TApiResponseData>,
>(namespace: Namespace): MutationFunction<TApiResponseData, TRequestData> {
	const mutationFn = async ({ data, signal }: TRequestData) => {
		const url = getEndpoints(namespace).root();

		const { data: responseData } = await apiClient.post<TApiResponseData>(
			url,
			data,
			{ signal }
		);
		return responseData;
	};

	return mutationFn;
}

/**
 * Hook for creating a base model.
 *
 * @param params Params for react-query.
 * Caches of queries from invalidationKeys are invalidated after the mutation is successful.
 * @returns React Query hook for creating a base model
 */
function useCreateBaseModel<
	TApiResponseData extends IBaseModel,
	TCreateRequestData extends
		CreateRequestParams<TApiResponseData> = CreateRequestParams<TApiResponseData>,
	TContext = unknown,
	TError = Error,
>({
	namespace,
	mutationFn: customMutationFn,
	options,
	invalidationKeys = [],
}: IBaseModelCreateArgs<
	TApiResponseData,
	TCreateRequestData,
	TContext,
	TError
>) {
	const mutationFn =
		customMutationFn ||
		getDefaultCreateFn<TApiResponseData, TCreateRequestData>(namespace);

	return useMutation({
		mutationFn,
		...options,
		onSettled: (...args) => {
			const [data] = args;
			// If the mutation failed, don't invalidate any queries
			if (data === undefined) {
				if (options?.onSettled) {
					options.onSettled(...args);
				}
				return;
			}

			forEach(invalidationKeys, (key) => {
				queryClient.invalidateQueries({
					queryKey: key,
				});
			});

			if (options?.onSettled) {
				options.onSettled(...args);
			}
		},
	});
}

export default useCreateBaseModel;
