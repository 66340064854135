import { Box, Group, Skeleton, Stack, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type {
	IAMResourcePolicyOut,
	LiteUserOut,
	RoleSummary,
} from '@repo/api-codegen';
import {
	apiQueryKey,
	useApiBulkAddResourcePolicies,
	useApiGetResourcePermissionsRoleSummary,
	useApiGetResourcePolicies,
	useApiQuickAlterResourcePolicies,
} from '@repo/api-codegen';
import { Button, IconButton, MultiSelect, Text } from '@repo/foundations';
import { useCallback, useState } from 'react';
import type { ISecodaEntity } from '../../api';
import { queryClient, useAuthUser, useWorkspace } from '../../api';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList';
import type { SecodaEntity } from '../../lib/models';
import CopyLinkButton from '../../pages/TablePage/CopyLinkButton';
import { trackEvent } from '../../utils/analytics';
import { getDisplayName } from '../../utils/userUtils';
import { CustomSelector } from '../CustomSelector';
import { ScrollableModal } from '../ScrollableModal/ScrollableModal';
import { UserAvatar } from '../UserAvatar';

function InviteUserForm({
	roleSummary,
	policies,
	entity,
}: {
	roleSummary: RoleSummary[];
	policies: IAMResourcePolicyOut[];
	entity: ISecodaEntity | SecodaEntity;
}) {
	const { activeUsers: usersData } = useExtendedUserList();
	const [searchValue, setSearchValue] = useState('');
	const [value, setValue] = useState<string[]>([]);
	const { mutateAsync: bulkAddPolicies, isLoading: isBulkAddingPolicies } =
		useApiBulkAddResourcePolicies({
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: apiQueryKey(
						'iam/iam_resource_policies/resources/{entity_id}/policies',
						{
							entity_id: entity.id,
						}
					),
				});
			},
		});

	const data = usersData
		.filter((user) => {
			const appearInPolicies = policies.some(
				(policy) => policy.user_id === user.id
			);
			const appearInRoles = roleSummary.some((role) =>
				role.members.some((member) => member.id === user.id)
			);

			return !appearInPolicies && !appearInRoles;
		})
		.map((user) => ({
			label: getDisplayName(user),
			value: user.id,
			user: user,
		}));

	const filteredValue = value.filter((v) =>
		data.find((item) => item.value === v)
	);

	const bulkInvite = async () => {
		await bulkAddPolicies({
			body: {
				user_ids: filteredValue,
			},
			pathParams: {
				entityId: entity.id,
			},
		});
		setValue([]);
	};

	return (
		<Group noWrap>
			<Box
				sx={{
					flexGrow: 1,
					flexShrink: 1,
					paddingTop: 3,
				}}
			>
				<MultiSelect
					searchValue={searchValue}
					onSearchChange={setSearchValue}
					data={data}
					sx={{
						paddingTop: 4,
					}}
					placeholder="Add new users"
					value={filteredValue}
					setValue={setValue}
					renderIcon={(item) => <UserAvatar user={item.user} size="sm" />}
					renderLabel={(item) => getDisplayName(item.user)}
				/>
			</Box>
			<Button
				size="md"
				disabled={filteredValue.length === 0}
				onClick={bulkInvite}
				loading={isBulkAddingPolicies}
			>
				Add
			</Button>
		</Group>
	);
}

function PolicyMemberRow({
	policy,
	entity,
}: {
	policy: IAMResourcePolicyOut;
	entity: SecodaEntity | ISecodaEntity;
}) {
	const theme = useMantineTheme();

	const { mutateAsync: quickAlterResourcePolicies, isLoading: isAltering } =
		useApiQuickAlterResourcePolicies({
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: apiQueryKey(
						'iam/iam_resource_policies/resources/{entity_id}/policies',
						{
							entity_id: entity.id,
						}
					),
				});
			},
		});

	const handleChangePermission = (value: string) => {
		if (value === 'remove') {
			quickAlterResourcePolicies({
				body: {
					type: 'remove',
					user_id: policy.user_id,
				},
				pathParams: {
					entityId: entity.id,
				},
			});
			return;
		}

		quickAlterResourcePolicies({
			body: {
				type: value === 'write' ? 'set_write' : 'set_read',
				user_id: policy.user_id,
			},
			pathParams: {
				entityId: entity.id,
			},
		});
	};

	const currentValue = policy.permissions.includes('Resources.Update')
		? 'write'
		: 'read';

	const color = {
		read: 'text/primary/default',
		write: 'text/primary/default',
		remove: 'text/critical/default',
	} as const;

	const text = {
		read: 'Can read',
		write: 'Can edit',
		remove: 'Remove',
	};

	return (
		<Group noWrap spacing="xs" position="apart">
			<Group>
				<UserAvatar user={policy.user} enableLink size="md" />
				<Stack spacing={0}>
					<Text
						size="sm"
						fw={theme.other.typography.weight.semibold}
						lineClamp={1}
					>
						{getDisplayName(policy.user)}
					</Text>
					<Text size="xs" color="text/secondary/default" lineClamp={1}>
						{policy.user.email}
					</Text>
				</Stack>
			</Group>
			<Group>
				<CustomSelector
					items={[{ value: 'read' }, { value: 'write' }, { value: 'remove' }]}
					value={currentValue}
					menuProps={{
						width: 'target',
						position: 'bottom-start',
						withinPortal: true,
					}}
					onChange={handleChangePermission}
					disabled={isAltering}
					renderItem={(item: { value: 'read' | 'write' | 'remove' }) => (
						<Box px="xs" py={6}>
							<Text size="sm" color={color[item.value]}>
								{text[item.value]}
							</Text>
						</Box>
					)}
				/>
			</Group>
		</Group>
	);
}

function RoleMemberRow({
	member,
	policies,
	entity,
	permissionLevel,
}: {
	member: LiteUserOut;
	policies: IAMResourcePolicyOut[];
	entity: SecodaEntity | ISecodaEntity;
	permissionLevel: 'read' | 'write';
}) {
	const { user } = useAuthUser();
	const theme = useMantineTheme();

	const { mutateAsync: quickAlterResourcePolicies, isLoading: isAltering } =
		useApiQuickAlterResourcePolicies({
			onSuccess: () => {
				queryClient.invalidateQueries({
					queryKey: apiQueryKey(
						'iam/iam_resource_policies/resources/{entity_id}/policies',
						{
							entity_id: entity.id,
						}
					),
				});
			},
		});

	const accessRemoved = policies.some(
		(policy) =>
			policy.user_id === member.id && policy.type === 'REMOVE_ACCESS_FROM_ROLE'
	);

	const color = {
		normal: 'text/primary/default' as const,
		removed: 'text/critical/default' as const,
	};

	const text = {
		normal: permissionLevel === 'write' ? 'Can edit' : 'Can read',
		removed: 'No access',
	};

	const handleToggleAccess = (value: string) => {
		quickAlterResourcePolicies({
			body: {
				type: value === 'removed' ? 'revoke_from_role' : 'restore_to_role',
				user_id: member.id,
			},
			pathParams: {
				entityId: entity.id,
			},
		});
	};

	return (
		<Group noWrap spacing="xs" position="apart">
			<Group>
				<UserAvatar user={member} enableLink size="md" />
				<Stack spacing={0}>
					<Text
						size="sm"
						fw={theme.other.typography.weight.semibold}
						lineClamp={1}
					>
						{getDisplayName(member)}
					</Text>
					<Text size="xs" color="text/secondary/default" lineClamp={1}>
						{member.email}
					</Text>
				</Stack>
			</Group>
			<Group>
				<CustomSelector
					items={[{ value: 'normal' }, { value: 'removed' }]}
					value={accessRemoved ? 'removed' : 'normal'}
					menuProps={{
						width: 'target',
						position: 'bottom-start',
						withinPortal: true,
					}}
					onChange={handleToggleAccess}
					disabled={isAltering || user.id === member.id}
					renderItem={(item: { value: 'normal' | 'removed' }) => (
						<Box px="xs" py={6}>
							<Text size="sm" color={color[item.value]}>
								{text[item.value]}
							</Text>
						</Box>
					)}
				/>
			</Group>
		</Group>
	);
}

function RoleSection({
	entity,
	name,
	permissionLevel,
	members,
	policies,
}: {
	entity: SecodaEntity | ISecodaEntity;
	name: string;
	permissionLevel: 'read' | 'write';
	members: LiteUserOut[];
	policies: IAMResourcePolicyOut[];
}) {
	const theme = useMantineTheme();

	return (
		<Stack spacing="xs">
			<Group position="apart">
				<Text fw="semibold">{name}</Text>
			</Group>
			<Stack spacing="xs">
				{members.map((member) => (
					<RoleMemberRow
						permissionLevel={permissionLevel}
						key={member.id}
						member={member}
						policies={policies}
						entity={entity}
					/>
				))}
			</Stack>
		</Stack>
	);
}

function ContentV2({ entity }: { entity: SecodaEntity | ISecodaEntity }) {
	const { data, isLoading } = useApiGetResourcePermissionsRoleSummary({
		pathParams: {
			entityId: entity.id,
		},
	});
	const { data: policies = [], isLoading: isPoliciesLoading } =
		useApiGetResourcePolicies({
			pathParams: {
				entityId: entity.id,
			},
		});

	if (isLoading || isPoliciesLoading) {
		return (
			<Stack px="lg" spacing="sm">
				{[1, 2, 3, 4, 5].map((i) => (
					<Skeleton key={i} height={40} />
				))}
			</Stack>
		);
	}

	if (!data?.length) {
		return (
			<Stack px="lg" spacing="sm" align="center" py="xl">
				<Text color="text/secondary/default">No roles with access</Text>
			</Stack>
		);
	}

	const customPermissionsPolicies = policies.filter((policy) => {
		const appearInRoles = data.some((role) =>
			role.members.some((member) => member.id === policy.user_id)
		);
		return policy.type === 'ADD_PERMISSIONS' && !appearInRoles;
	});

	return (
		<Stack px="lg" spacing="xl" pb="lg">
			<InviteUserForm roleSummary={data} policies={policies} entity={entity} />
			{['write', 'read'].map((level) => {
				const roles = data.filter((role) => role.permission_level === level);
				if (roles.length === 0) return null;

				return (
					<Stack key={level} spacing="xs">
						<Text size="sm" color="text/secondary/default">
							Roles with {level === 'write' ? 'write' : 'read'} access
						</Text>
						{roles
							.filter((role) => role.members.length > 0)
							.map((role) => (
								<RoleSection
									entity={entity}
									policies={policies}
									key={role.role_id}
									name={role.role_name}
									permissionLevel={level as 'read' | 'write'}
									members={role.members}
								/>
							))}
					</Stack>
				);
			})}
			{customPermissionsPolicies.length > 0 && (
				<Stack spacing="xs">
					<Text size="sm" color="text/secondary/default">
						Other users
					</Text>
					{customPermissionsPolicies.map((policy) => (
						<PolicyMemberRow key={policy.id} policy={policy} entity={entity} />
					))}
				</Stack>
			)}
		</Stack>
	);
}

export function EntityPermissionsV2({
	model,
}: {
	model: SecodaEntity | ISecodaEntity;
}) {
	const { user } = useAuthUser();

	const [opened, { close, toggle }] = useDisclosure(false);
	const { workspace } = useWorkspace();

	const trackClick = useCallback(() => {
		trackEvent(
			`${model.entity_type}/share_settings/click/v2`,
			{
				resource_id: model.id,
			},
			user,
			workspace
		);
	}, [model.entity_type, model.id, user, workspace]);

	const handleClick = () => {
		trackClick();
		toggle();
	};

	return (
		<>
			<IconButton
				iconName="share"
				onClick={handleClick}
				variant="tertiary"
				tooltip="Share and set permissions"
			/>
			<ScrollableModal
				headerProps={{ pl: 'lg' }}
				opened={opened}
				title={`Permissions of ${model.title}`}
				onClose={close}
				withHeaderDivider={false}
				size={480}
				footer={
					<Group position="right" px="lg" py="md">
						<CopyLinkButton />
					</Group>
				}
				withDoneFooter={false}
				modalProps={{
					top: 150,
				}}
			>
				<ContentV2 entity={model} />
			</ScrollableModal>
		</>
	);
}
