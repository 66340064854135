import {
	Anchor,
	Center,
	createStyles,
	Group,
	HoverCard,
	Stack,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { getSupportedTabs } from '@repo/common/constants/integration/integrations.preferences';
import { Button, IconButton, Text, TextInput, Title } from '@repo/foundations';
import { useFormik } from 'formik';
import { isEmpty, isNil } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import type { IIntegration } from '../../../../../api';
import { useAuthUser, useUpdateIntegration } from '../../../../../api';
import { useAiEnabled } from '../../../../../hooks/useAIEnabled';
import { usePlan } from '../../../../../hooks/usePlans';
import type { IntegrationSpec } from '../../../../../interfaces/IntegrationSpec';
import { trackEvent } from '../../../../../utils/analytics';
import { useFeatureFlags } from '../../../../../utils/featureFlags';
import { IntegrationPreferences } from '../../../IntegrationPreferences';
import {
	cronValidationMessage,
	isValidCron,
} from '../../../IntegrationSchedule/utils';
import useIntegrationExtraction from '../../useIntegrationExtraction';
import { AccordionCard } from './AccordionCard';

interface SyncStepProps {
	integrationSpec: IntegrationSpec;
	integration: IIntegration;
}

const useStyles = createStyles((theme) => ({
	dropdown: {},
	anchor: {
		fontSize: theme.other.typography.text.sm,
		weight: theme.other.typography.weight.semibold,
	},
}));

export function SyncStep({ integrationSpec, integration }: SyncStepProps) {
	const { classes, theme } = useStyles();

	const { user, workspace } = useAuthUser();
	const { plan } = usePlan();
	const { slackV3 } = useFeatureFlags();
	const enableAi = useAiEnabled();

	const { mutateAsync: updateIntegration } = useUpdateIntegration({});

	const navigate = useNavigate();

	const preferences = useMemo(() => {
		const supportedTabs = getSupportedTabs(integrationSpec, {
			isSlackV3: slackV3,
			isAIEnabledWorkspace: enableAi,
		});
		return supportedTabs.find((tab) => tab.name === 'preferences');
	}, [integrationSpec]);

	const { extractionJobs, polling, handleRunExtraction } =
		useIntegrationExtraction({
			id: integration.id,
		});

	useEffect(() => {
		// start sync when component loads
		if (!extractionJobs?.length && !polling) {
			handleRunExtraction();
		}
	}, [extractionJobs, polling, handleRunExtraction]);

	const formik = useFormik({
		initialValues: {
			scheduled_extractions_cron: '0 0 * * 6',
		},
		validationSchema: Yup.object({
			scheduled_extractions_cron: Yup.string().test(
				'is-valid-cron',
				(value) => cronValidationMessage(value.value || '', workspace.id),
				(value) => isNil(value) || isValidCron(value, workspace.id)
			),
		}),
		onSubmit: async (values) => {
			try {
				if (values.scheduled_extractions_cron) {
					await updateIntegration({
						data: {
							id: integration.id,
							scheduled_extractions_cron: values.scheduled_extractions_cron,
						},
					});
					showNotification({
						message: 'Integration schedule updated sucessfully',
						color: 'green',
					});
					trackEvent(
						'integration/schedule/update',
						{
							label: integration.name,
							type: integration.type,
						},
						user!,
						workspace!
					);
				}
				navigate(`/integrations/${integration.id}`);
			} catch (error) {
				showNotification({
					message: 'Failed to update integration schedule',
					color: 'red',
				});
			}
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} autoComplete="off">
			<Stack spacing="2xl" pb={theme.spacing.xl}>
				<Stack spacing="xs">
					<Title size="xl">Your initial sync has started</Title>
					<Text size="md">
						You’ll be notified when the sync is complete. In the meantime, here
						are some things you can do. You can always update these details
						later in your integration settings.
					</Text>
				</Stack>
				<Stack spacing="xs">
					{plan?.integration_scheduler_support && (
						<AccordionCard title="Create a schedule">
							<Stack spacing="3xs">
								<Group spacing="3xs" align="center" noWrap>
									<Text size="sm" weight="semibold">
										Cron expression<span aria-hidden>{'*'}</span>
									</Text>
									<HoverCard
										width={theme.other.width.xs}
										position={'bottom-start'}
									>
										<HoverCard.Target>
											<IconButton
												variant="tertiary"
												iconName="infoCircle"
												size="sm"
											/>
										</HoverCard.Target>
										<HoverCard.Dropdown>
											<Stack spacing="xs">
												<Stack spacing="4xs">
													<Text size="sm" weight="semibold">
														Please enter a cron expression to schedule the
														extractions.
													</Text>
													<Text size="sm" color="text/secondary/default">
														For example, 0 0 * * * runs the extraction daily at
														midnight UTC. Ensure your cron expression is in UTC
														and not more frequent than once a day.
													</Text>
												</Stack>
												<Anchor
													className={classes.anchor}
													href="https://crontab.guru/"
													target="_blank"
												>
													Learn more
												</Anchor>
											</Stack>
										</HoverCard.Dropdown>
									</HoverCard>
								</Group>
								<TextInput
									name="scheduled_extractions_cron"
									value={formik.values.scheduled_extractions_cron}
									error={formik.errors.scheduled_extractions_cron}
									onChange={formik.handleChange}
									optional
								/>
							</Stack>
						</AccordionCard>
					)}
					{preferences && (
						<AccordionCard title="Additional preferences">
							<IntegrationPreferences
								id={integration.id}
								preferenceOptions={preferences?.options || []}
							/>
						</AccordionCard>
					)}
				</Stack>
				<Center>
					<Button
						type="submit"
						variant="primary"
						w="fit-content"
						disabled={!isEmpty(formik.errors.scheduled_extractions_cron)}
					>
						Done
					</Button>
				</Center>
			</Stack>
		</form>
	);
}
