import type { Dispatch } from '@repo/secoda-editor';
import { joinBackward } from 'prosemirror-commands';
import type { EditorState } from 'prosemirror-state';
import { Selection } from 'prosemirror-state';
import getParentListItem from '../queries/getParentListItem';

/**
 * 1) Checks if we're in an empty paragraph at the start of a list_item.
 * 2) Tries normal joinBackward first.
 * 3) If joinBackward fails, force-remove the empty list_item and move cursor up.
 */
export function joinOrRemoveEmptyListItem() {
	return (state: EditorState, dispatch?: Dispatch) => {
		const { $from, empty } = state.selection;
		if (!empty) return false; // Must be a cursor, not a range
		if ($from.parentOffset !== 0) return false; // Must be at the start of the paragraph
		if ($from.parent.content.size !== 0) return false; // Must be an actually empty paragraph

		// Attempt the standard joinBackward
		if (joinBackward(state, dispatch)) {
			// If ProseMirror found a joinable block above, peace out!
			return true;
		}

		// If joinBackward returned false, we might still be in a list item
		const result = getParentListItem(state);
		if (!result) return false; // Not in a list item at all
		const [listItemNode, listItemPos] = result;

		// Force-remove the empty list item
		if (dispatch) {
			let tr = state.tr.delete(
				listItemPos,
				listItemPos + listItemNode.nodeSize
			);

			// Move the cursor to the end of the previous node (if it exists)
			const $newPos = tr.doc.resolve(Math.max(0, listItemPos - 1));
			const node = $newPos.node($newPos.depth);
			if (node) {
				tr = tr.setSelection(Selection.atEnd(node));
				dispatch(tr.scrollIntoView());
			}
		}
		return true;
	};
}
