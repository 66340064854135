import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useHash } from '@mantine/hooks';
import { Text } from '@repo/foundations';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	handleGoogleCallback,
	handleMicrosoftCallback,
} from './AuthCallback.helpers';

export function AuthCallback() {
	const [error, setError] = useState<Error | null>(null);

	const failureCallback = useCallback((errorCallbackValue: Error) => {
		setError(errorCallbackValue);
	}, []);

	const navigate = useNavigate();

	// Microsoft
	const { accounts, inProgress } = useMsal();

	// Microsoft
	useEffect(() => {
		// The provider handles the access token here.
		// We just need to ensure the provider got us a code
		// with the `accounts` check.
		if (accounts && accounts.length > 0) {
			handleMicrosoftCallback(navigate, failureCallback);
		}
	}, [accounts, failureCallback, navigate, inProgress]);

	// Google
	const [hash] = useHash();
	const params = new URLSearchParams(hash.replace('#', ''));
	const accessToken = params.get('access_token');
	const csrfState = params.get('state');

	// Google
	useEffect(() => {
		if (accessToken && csrfState) {
			handleGoogleCallback(navigate, accessToken, csrfState, failureCallback);
		}
	}, [accessToken, csrfState, failureCallback, navigate, inProgress]);

	const googleInProgress = accessToken;
	const microsoftInProgress =
		inProgress &&
		(inProgress === InteractionStatus.AcquireToken ||
			inProgress === InteractionStatus.Login ||
			inProgress === InteractionStatus.HandleRedirect);

	return (
		<Text>
			<Text>
				{!googleInProgress &&
					!microsoftInProgress &&
					'Processing other callback...'}
			</Text>
			<Text>{error?.message}</Text>
			<Text>{googleInProgress && 'Processing Google callback...'}</Text>
			<Text>
				{microsoftInProgress &&
					`Processing Microsoft callback... ${inProgress.match(/[A-Z][a-z]+/g)?.join(' ')}`}
			</Text>
		</Text>
	);
}

export default AuthCallback;
