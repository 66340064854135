import { Group, Stack, type SelectItem } from '@mantine/core';
import type { ResourcesIn } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { getSummaryAndBreadCrumbs } from '@repo/common/utils/breadcrumb';
import type { MultiSelectProps } from '@repo/foundations';
import { Banner, MultiSelect, Text } from '@repo/foundations';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useSecodaEntityList, type SearchResult } from '../../../api';
import { useMention } from '../../../api/hooks/search/useMention';
import { SecodaEntityIcon } from '../../SecodaEntity';

type ResourceSelectItem = SelectItem & {
	entity: SearchResult;
};

type ResourceSelectorItemProps = React.ComponentPropsWithoutRef<'div'> &
	ResourceSelectItem;

const ResourceSelectorItem = forwardRef<
	HTMLDivElement,
	ResourceSelectorItemProps
>(({ entity, ...others }: ResourceSelectorItemProps, ref) => {
	const breadcrumbs = getSummaryAndBreadCrumbs(
		entity.native_type || entity.entity_type,
		entity.search_metadata
	);

	return (
		<div ref={ref} {...others}>
			<Group spacing="sm" noWrap>
				<SecodaEntityIcon entity={entity} size={20} />
				<Stack spacing={0}>
					<Text size="sm" lineClamp={1} data-testid="search-result-item">
						{entity.title_cased ?? entity.title ?? 'Untitled'}
					</Text>
					<Text size="xs" color="text/secondary/default" lineClamp={1}>
						{breadcrumbs}
					</Text>
				</Stack>
			</Group>
		</div>
	);
});
ResourceSelectorItem.displayName = 'ResourceSelectorItem';

export interface ResourceSelectorProps
	extends Omit<
		MultiSelectProps<ResourceSelectItem>,
		'data' | 'value' | 'setValue' | 'onChange'
	> {
	integrationId?: string;
	initialSelectedValues?: ResourcesIn[];
	onChange: (resources: ResourcesIn[]) => void;
}

export function ResourceSelector({
	integrationId,
	initialSelectedValues = [],
	onChange,
	...multiSelectProps
}: ResourceSelectorProps) {
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedValues, setSelectedValues] = useState<ResourcesIn[]>(
		initialSelectedValues
	);

	// Reset selected values when integration changes
	useEffect(() => {
		setSelectedValues(initialSelectedValues);
	}, [integrationId, initialSelectedValues]);

	const selectedIds = useMemo(
		() =>
			(selectedValues?.map((item) => item.id).filter(Boolean) as string[]) ??
			[],
		[selectedValues]
	);

	const { data: selectedEntities } = useSecodaEntityList({
		filters: {
			id__in: selectedIds,
		},
		options: {
			select: (response) =>
				response.results.map(
					(item) =>
						({
							value: item.id,
							label: item.title,
							icon: <SecodaEntityIcon entity={item} size={16} />,
							entity: item,
						}) as ResourceSelectItem
				),
		},
	});

	const { data: searchResults } = useMention({
		searchTerm: searchTerm,
		filters: {
			operator: 'and',
			operands: [
				{
					field: 'integration_id',
					operator: 'exact',
					value: integrationId,
					operands: [],
				},
				{
					field: 'entity_type',
					operator: 'in',
					value: [EntityType.table, EntityType.schema],
					operands: [],
				},
			],
		},
		options: {
			enabled: !!integrationId,
			select: (response) =>
				response.results.map(
					(item) =>
						({
							value: item.id,
							label: item.title,
							icon: <SecodaEntityIcon entity={item} size={16} />,
							entity: item,
						}) as ResourceSelectItem
				),
		},
	});

	const data = useMemo(
		() => [
			...(selectedEntities ?? []),
			...(searchResults ?? []).filter(
				(item) => !selectedIds.includes(item.value)
			),
		],
		[searchResults, selectedEntities, selectedIds]
	);

	const handleSetValue = useCallback(
		(values: string[]) => {
			const selectedResources = values
				.map((value) => {
					const found = data.find((item) => item.value === value);
					if (!found) return null;
					return {
						id: found.entity.id,
						database_name: found.entity.search_metadata?.database,
						schema_name: found.entity.search_metadata?.schema,
						table_name: ['table', 'view'].includes(found.entity.entity_type)
							? found.entity.title
							: undefined,
					};
				})
				.filter(Boolean) as ResourcesIn[];
			setSelectedValues(selectedResources);
			onChange(selectedResources);
		},
		[onChange, data]
	);

	const hasSelectedSchema = useMemo(
		() =>
			selectedValues.some((resource) =>
				data.some(
					(item) =>
						item.value === resource.id &&
						item.entity.entity_type === EntityType.schema
				)
			),
		[selectedValues, data]
	);

	return (
		<Stack>
			<MultiSelect
				{...multiSelectProps}
				data={data}
				value={selectedIds}
				setValue={handleSetValue}
				searchable
				searchValue={searchTerm}
				onSearchChange={setSearchTerm}
				nothingFound="No resources found"
				itemComponent={ResourceSelectorItem}
			/>
			{hasSelectedSchema && (
				<Banner
					tone="info"
					message="Selecting a schema will grant access to all existing tables within it with the selected privileges. Tables added to this schema in the future will require separate permissions."
					inCard
				/>
			)}
		</Stack>
	);
}
