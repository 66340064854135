import { createStyles, type CSSObject } from '@mantine/core';
import { type CSSProperties } from 'react';
import DataTableHeaderCell from './DataTableHeaderCell';
import DataTableHeaderSelectorCell from './DataTableHeaderSelectorCell';
import type {
	DataTableColumn,
	DataTableColumnGroup,
	DataTableSortProps,
} from './types';

const useStyles = createStyles((theme) => ({
	root: {
		background: theme.other.getColor('surface/app/default'),
	},
	textSelectionDisabled: {
		userSelect: 'none',
	},
}));

type DataTableHeaderProps<T> = {
	className?: string;
	style?: CSSObject;
	sortStatus: DataTableSortProps['sortStatus'];
	sortIcons: DataTableSortProps['sortIcons'];
	onSortStatusChange: DataTableSortProps['onSortStatusChange'];
	columns: DataTableColumn<T>[];
	defaultColumnProps: Omit<DataTableColumn<T>, 'accessor'> | undefined;
	groups: readonly DataTableColumnGroup<T>[] | undefined;
	selectionVisible: boolean;
	selectionChecked: boolean;
	selectionIndeterminate: boolean;
	onSelectionChange: (() => void) | undefined;
	selectionCheckboxProps: Record<string, unknown>;
	leftShadowVisible: boolean;
};

export default (function DataTableHeader<T>({
	className,
	style,
	sortStatus,
	sortIcons,
	onSortStatusChange,
	columns,
	defaultColumnProps,
	groups,
	selectionVisible,
	selectionChecked,
	selectionIndeterminate,
	onSelectionChange,
	selectionCheckboxProps,
	leftShadowVisible,
}: DataTableHeaderProps<T>) {
	const { classes, cx } = useStyles();

	const allRecordsSelectorCell = selectionVisible ? (
		<DataTableHeaderSelectorCell
			shadowVisible={leftShadowVisible}
			checked={selectionChecked}
			indeterminate={selectionIndeterminate}
			checkboxProps={selectionCheckboxProps}
			onChange={onSelectionChange}
			rowSpan={groups ? 2 : undefined}
		/>
	) : null;

	return (
		<tr className={cx(classes.root, className)} style={style as CSSProperties}>
			{allRecordsSelectorCell}
			{columns.map(({ hidden, ...columnProps }, index) => {
				if (hidden) return null;

				const {
					accessor,
					visibleMediaQuery,
					textAlignment,
					width,
					title,
					sortable,
					draggable,
					toggleable,
					resizable,
					titleClassName,
					titleStyle,
					titleSx,
					filter,
					filtering,
				} = { ...defaultColumnProps, ...columnProps };

				return (
					<DataTableHeaderCell<T>
						key={accessor}
						accessor={accessor}
						className={titleClassName}
						style={titleStyle}
						sx={titleSx}
						visibleMediaQuery={visibleMediaQuery}
						textAlignment={textAlignment}
						width={width}
						title={title}
						sortable={sortable}
						draggable={draggable}
						// we won't display the resize handle for the last column
						// to avoid overflow render issues
						resizable={resizable && index < columns.length - 1}
						toggleable={toggleable}
						sortStatus={sortStatus}
						sortIcons={sortIcons}
						onSortStatusChange={onSortStatusChange}
						filter={filter}
						filtering={filtering}
						allColumns={columns}
					/>
				);
			})}
		</tr>
	);
} as <T>(props: DataTableHeaderProps<T>) => JSX.Element);
