import {
	ActionIcon,
	createStyles,
	Group,
	Skeleton,
	Tooltip,
	UnstyledButton,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { Button, Icon, IconButton, ListBox, Text } from '@repo/foundations';
import { getFormattedTooltipLabel } from '@repo/foundations/components/Tooltip';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useMonitor, useMonitorList, type IMetricWidget } from '../../../api';
import { WidgetType } from '../../../interfaces';
import type { WidgetUpdateFn } from '../../HomePage/types';
import {
	formatMetricWidgetTitle,
	getMetricNameTooltip,
	hasLookback,
	joinFilters,
	LOOKBACK_KEY,
	lookbackLabel,
} from '../utils/utils';
import EditableTitle from './EditableTitle';
import { EditMenu } from './EditMenu';
import { FilterMenu } from './FilterMenu/FilterMenu';
import { MetricWidgetContent } from './MetricWidgetContent';
import { WidgetWrapper } from './WidgetWrapper';

const useStyles = createStyles((theme) => ({
	entityTitleSelector: {
		minWidth: 0,
		maxWidth: '100%',
		flex: '1 1 auto',
		marginLeft: `-${theme.spacing.sm}`,
	},
}));

interface MetricWidgetProps {
	metricWidget: IMetricWidget;
	updateWidget: WidgetUpdateFn;
	deleteWidget: VoidFunction;
}

export function MetricWidget({
	metricWidget,
	updateWidget,
	deleteWidget,
}: MetricWidgetProps) {
	const { classes } = useStyles();

	const navigate = useNavigate();

	const [editingTitle, setEditingTitle] = useState<boolean>(false);
	const [title, setTitle] = useState<string>(
		formatMetricWidgetTitle(metricWidget)
	);

	const [opened, setOpened] = useState<boolean>(false);
	const handleOpenChange = (newOpened: boolean) => {
		setOpened(newOpened);
	};

	const { data: monitor, isFetching: isFetchingMonitor } = useMonitor({
		id: String(metricWidget.metric_metadata.monitor_id),
		options: {
			enabled:
				metricWidget.type === WidgetType.MONITOR &&
				Boolean(metricWidget.metric_metadata.monitor_id),
		},
	});

	const [searchTerm, setSearchTerm] = useState<string>('');
	const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
	const { data: monitors } = useMonitorList({
		filters: {
			search_term: debouncedSearchTerm,
			archived: false,
		},
		options: {
			enabled: metricWidget.type === WidgetType.MONITOR,
			select: (data) => data.results,
		},
	});

	// ========================================================
	// Render
	// ========================================================

	let left = null;
	let right = null;

	if (metricWidget.type === WidgetType.MONITOR) {
		const handleUpdateMonitorId = (id: string) => {
			updateWidget(
				metricWidget.id,
				'metric_metadata'
			)({
				...metricWidget.metric_metadata,
				monitor_id: id,
			});
		};
		left = (
			// have to wrap in a div to prevent interaction with drag and drop context
			<div onPointerDown={(e) => e.stopPropagation()}>
				<ListBox opened={opened} onOpenChange={handleOpenChange}>
					<ListBox.Target>
						<Button
							rightIconName="selector"
							iconColor="icon/secondary/default"
							variant="tertiary"
							w="fit-content"
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}
							className={classes.entityTitleSelector}
						>
							{isFetchingMonitor ? (
								<Skeleton w={100} h={50} />
							) : (
								<Text weight="bold" truncate size="sm">
									{monitor?.name || 'Sample monitor'}
								</Text>
							)}
						</Button>
					</ListBox.Target>
					<ListBox.ItemsDropdown
						usePortal
						search={{
							placeholder: 'Search monitors',
							value: searchTerm,
							onChange: setSearchTerm,
						}}
						items={monitors ?? []}
						renderItem={(m) => (
							<ListBox.Item key={m.id} value={m.id}>
								<UnstyledButton
									w="100%"
									onClick={(e) => {
										handleUpdateMonitorId(m.id);
										setTitle(m.name);
										setOpened(false);
									}}
								>
									<Text size="sm">{m.name}</Text>
								</UnstyledButton>
							</ListBox.Item>
						)}
					/>
				</ListBox>
			</div>
		);
		right = monitor && (
			<>
				<FilterMenu
					metricWidget={metricWidget}
					updateWidgetMetricMetadata={updateWidget(
						metricWidget.id,
						'metric_metadata'
					)}
				/>
				<EditMenu
					metricWidget={metricWidget}
					deleteWidget={deleteWidget}
					updateWidgetSize={updateWidget(metricWidget.id, 'size')}
				/>
				<IconButton
					tooltip="Navigate to monitor"
					iconName="chevronRight"
					variant="tertiary"
					onClick={() => {
						navigate(`/monitor/${metricWidget.metric_metadata.monitor_id}`);
					}}
				/>
			</>
		);
	} else {
		const handleSaveTitle = (value: string) => {
			updateWidget(metricWidget.id, 'title')(value);
			setTitle(value);
		};
		const handleExitEditing = () => setEditingTitle(false);

		const titleElement = editingTitle ? (
			<EditableTitle
				initialValue={formatMetricWidgetTitle(metricWidget)}
				onSave={handleSaveTitle}
				onExitEditing={handleExitEditing}
			/>
		) : (
			<Text size="sm" weight="bold" lineClamp={1}>
				{title}
			</Text>
		);

		const tooltipLabel = getMetricNameTooltip(
			metricWidget.metric_metadata.metric_name
		);
		const tooltip = tooltipLabel && !editingTitle && (
			<Tooltip label={getFormattedTooltipLabel(tooltipLabel)}>
				<ActionIcon variant="transparent">
					<Icon name="infoCircle" />
				</ActionIcon>
			</Tooltip>
		);

		left = (
			<>
				<Group spacing="xs" p={0} w="100%">
					{titleElement}
					{tooltip}
				</Group>
				{hasLookback(metricWidget) && (
					<Text size="xs" weight="semibold" color="text/secondary/default">
						{lookbackLabel(joinFilters(metricWidget)[LOOKBACK_KEY])}
					</Text>
				)}
			</>
		);
		right = (
			<>
				<FilterMenu
					metricWidget={metricWidget}
					updateWidgetMetricMetadata={updateWidget(
						metricWidget.id,
						'metric_metadata'
					)}
				/>
				<EditMenu
					metricWidget={metricWidget}
					deleteWidget={deleteWidget}
					updateWidgetSize={updateWidget(metricWidget.id, 'size')}
					onEditingTitle={() => setEditingTitle(true)}
				/>
			</>
		);
	}

	return (
		<WidgetWrapper
			metricId={metricWidget.id}
			size={metricWidget.size}
			left={left}
			right={right}
			content={
				<MetricWidgetContent source="widget" metricWidget={metricWidget} />
			}
		/>
	);
}
