import { createStyles, SimpleGrid, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { apiQueryKey, useApiUpdateTeam } from '@repo/api-codegen';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { queryClient, useReport } from '../../../../api';
import { useHomepageTeam } from '../../hooks/useHomepageTeam';
import { getWidgetCardShadow } from '../../utils';
import OnboardingHeader from './components/OnboardingHeader';
import OnboardingStep from './components/OnboardingStep';
import { TEAM_HOMEPAGE_ONBOARDING_STEPS } from './constants';
import {
	getHasHomepageCoverImageChanged,
	getHasHomepageWidgetReordered,
	getHasTeamMembersAdded,
	getIsAddNewWidgetComplete,
} from './utils';

interface TeamHomepageEditorOnboardingProps {
	onEditHomeScreen: VoidFunction;
	onOpenTeamSettings: VoidFunction;
}

const useStyles = createStyles((theme) => ({
	root: {
		borderRadius: theme.spacing.sm,
		boxShadow: getWidgetCardShadow(theme),
	},
}));

function TeamHomepageEditorOnboarding({
	onEditHomeScreen,
	onOpenTeamSettings,
}: TeamHomepageEditorOnboardingProps) {
	const { teamId } = useParams();

	if (!teamId) {
		throw new Error(
			'Cannot render TeamHomepageEditorOnboarding outside a team page'
		);
	}

	const { classes } = useStyles();

	const { data: homepage } = useReport({ type: 'teams', teamId });
	const { team, teamMembers } = useHomepageTeam();
	const { mutateAsync: updateTeam } = useApiUpdateTeam({});

	const isAddNewWidgetComplete = getIsAddNewWidgetComplete(homepage);
	const hasHomepageWidgetReordered = getHasHomepageWidgetReordered(homepage);
	const hasHomepageCoverImageChanged =
		getHasHomepageCoverImageChanged(homepage);
	const hasTeamMembersAdded = getHasTeamMembersAdded(teamMembers);

	const stepsCompleted = useMemo(
		() =>
			[
				isAddNewWidgetComplete,
				hasHomepageWidgetReordered,
				hasHomepageCoverImageChanged,
				hasTeamMembersAdded,
			].reduce((total, n) => total + Number(n), 0),
		[
			hasHomepageCoverImageChanged,
			hasHomepageWidgetReordered,
			hasTeamMembersAdded,
			isAddNewWidgetComplete,
		]
	);

	const completeOnboarding = useCallback(async () => {
		await updateTeam({
			pathParams: {
				teamId,
			},
			body: {
				onboarding_completed: true,
			},
		});
		await queryClient.invalidateQueries({
			queryKey: apiQueryKey('auth/teams/{team_id}', { team_id: teamId }),
		});
	}, [teamId, updateTeam]);

	useEffect(() => {
		if (TEAM_HOMEPAGE_ONBOARDING_STEPS.length === stepsCompleted) {
			completeOnboarding().catch(() => {
				showNotification({
					title: 'Failed to dismiss onboarding',
					message: 'An error occurred while dismissing onboarding',
					color: 'red',
				});
			});
		}
	}, [stepsCompleted, completeOnboarding]);

	if (team === null || team.onboarding_completed) {
		return null;
	}

	return (
		<Stack className={classes.root} p="lg">
			<OnboardingHeader
				totalSteps={TEAM_HOMEPAGE_ONBOARDING_STEPS.length}
				stepsCompleted={stepsCompleted}
				onDismissOnboarding={completeOnboarding}
				description={`Complete these steps to get the ${team.name} home set up.`}
			/>
			<SimpleGrid cols={2}>
				<OnboardingStep
					step="Add new widgets to this page"
					isComplete={isAddNewWidgetComplete}
					onClick={onEditHomeScreen}
				/>
				<OnboardingStep
					step="Click and drag widgets to reorder them"
					isComplete={hasHomepageWidgetReordered}
				/>
				<OnboardingStep
					step="Add a cover image"
					isComplete={hasHomepageCoverImageChanged}
					onClick={onEditHomeScreen}
				/>
				<OnboardingStep
					step="Invite members to this team"
					isComplete={hasTeamMembersAdded}
					onClick={onOpenTeamSettings}
				/>
			</SimpleGrid>
		</Stack>
	);
}

export default TeamHomepageEditorOnboarding;
