import { Stack } from '@mantine/core';
import { useApiListUserLogs, type ActivityLogOut } from '@repo/api-codegen';
import { noop } from 'lodash-es';
import type { ApiCatalogSort } from '../../api';
import { TableV2 } from '../TableV2';
import { useColumns } from './hooks';

const DEFAULT_SORT: ApiCatalogSort = {
	field: 'updated_at',
	order: 'desc',
};

export function UserLogs({ userId }: { userId: string }) {
	const columns = useColumns();

	return (
		<Stack spacing="md" py="md">
			<TableV2<ActivityLogOut>
				columns={columns}
				onRowClick={noop}
				pluralTypeString="logs"
				// @ts-ignore
				usePaginationList={useApiListUserLogs}
				defaultRequiredSearchParams={{
					user_id: userId,
				}}
				defaultSort={DEFAULT_SORT}
				useCodegenListInterface
				withInteractiveHeader={false}
				withStickyColumnBorder={false}
			/>
		</Stack>
	);
}
