import {
	ActionIcon,
	Box,
	Group,
	Skeleton,
	Stack,
	useMantineTheme,
} from '@mantine/core';
import {
	useIsJiraIntegrated,
	useListCustomProperties,
	useUpdateEntityCustomProperty,
} from '@repo/api-codegen';
import CollapsableStack from '@repo/common/components/CollapsableStack';
import { EntityType } from '@repo/common/enums/entityType';
import { Icon, IconButton } from '@repo/foundations';
import { useCallback, useMemo } from 'react';
import type { IGlossaryTerm, IQuestion, ISecodaEntity } from '../../api';
import { useAuthUser, useIntegrationPrefetched } from '../../api';
import {
	useExtendedUserList,
	useGuestUserList,
} from '../../api/hooks/user/useExtendedUserList';
import type { DjangoValueType } from '../../pages/TemplatePage/types';
import { trackEvent } from '../../utils/analytics';
import { useCheckEntityUpdatePermission } from '../../utils/authorization/roles';
import { useFeatureFlags } from '../../utils/featureFlags';
import { SelectableProperty, StaticProperty } from './EntityPropertySidebar';
import type { SidebarEntityKeys } from './types';
import { getOwnerAndGroupSelectorOptions } from './utils';

import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import { Link } from 'react-router-dom';
import { CustomPropertyRendererV2 } from '../CustomPropertyRenderV2';
import { AssigneePropertySelector } from '../Properties/AssigneePropertySelector';
import { CollectionParentPropertySelector } from '../Properties/CollectionParentPropertySelector';
import { CollectionsPropertySelector } from '../Properties/CollectionsPropertySelector';
import { GovernancePropertySelector } from '../Properties/GovernancePropertySelector';
import { OwnersPropertySelector } from '../Properties/OwnersPropertySelector';
import { PriorityPropertySelector } from '../Properties/PriorityPropertySelector';
import { PropertySelector } from '../Properties/PropertySelector';
import { QuestionStatusPropertySelector } from '../Properties/QuestionStatusPropertySelector';
import { StatusPropertySelector } from '../Properties/StatusPropertySelector';
import TagPropertySelector from '../Properties/TagPropertySelector';
import { TeamsPropertySelector } from '../Properties/TeamsPropertySelector';
import { VerificationPropertySelector } from '../Properties/VerificationPropertySelector';
import { FreshnessStack } from './SidesheetStacks/Freshness/FreshnessStack';
import { JiraIssueStack } from './SidesheetStacks/Jira/JiraIssueStack';
import MetadataStack from './SidesheetStacks/Metadata/MetadataStack';
import { RelatedResourceStack } from './SidesheetStacks/RelatedResource/RelatedResourceStack';
import { SynonymsStack } from './SidesheetStacks/Synonyms/SynonymsStack';

export interface EntitySidebarContentProps {
	entity: ISecodaEntity | IQuestion;
	updateEntity: (
		key: SidebarEntityKeys,
		value: DjangoValueType,
		saveRemotely?: boolean
	) => void;
	withFrequentUsers?: boolean;
	withVerifiedSelector?: boolean;
	withGovernanceSelector?: boolean;
	withCollectionSelector?: boolean;
	withTagSelector?: boolean;
	withOwnerSelector?: boolean;
	withRelatedResourceSelector?: boolean;
	withSynonymsSelector?: boolean;
	withCustomPropertyEditors?: boolean;
	withEntityPopularity?: boolean;
	withEntityRowCount?: boolean;
	withEntityByteSize?: boolean;
	withTeamSelector?: boolean;
	// Question-specific properties:
	withAssignedToSelector?: boolean;
	withPrioritySelector?: boolean;
	withStatusSelector?: boolean;
	withSource?: string;
	withMetricSection?: JSX.Element;
	withCustomPublishElement?: JSX.Element;
	withAIInclusionStatus?: boolean;
	withCollectionParentSelector?: boolean;
}

function EntitySidebarContent({
	entity,
	updateEntity,
	withFrequentUsers = false,
	withVerifiedSelector = false,
	withGovernanceSelector = false,
	withCollectionSelector = false,
	withTagSelector = false,
	withOwnerSelector = false,
	withCustomPublishElement,
	withRelatedResourceSelector = false,
	withSynonymsSelector = false,
	withCustomPropertyEditors = false,
	withEntityPopularity = false,
	withEntityRowCount = false,
	withEntityByteSize = false,
	withTeamSelector = true,
	// Question-specific properties:
	withAssignedToSelector = false,
	withPrioritySelector = false,
	withStatusSelector = false,
	withSource = undefined,
	withMetricSection,
	withAIInclusionStatus = true,
	withCollectionParentSelector = false,
}: EntitySidebarContentProps) {
	const { user, workspace, isGuestUser, isAdminUser } = useAuthUser();
	const editorOfEntity = useCheckEntityUpdatePermission({ entity });
	const viewerOfEntity = !editorOfEntity;

	const { dataQualityScoreSla } = useFeatureFlags();

	const { data: integration } = useIntegrationPrefetched({
		id: entity.integration,
		options: {
			enabled:
				Boolean(entity.integration) && entity.entity_type !== EntityType.metric,
		},
	});

	const theme = useMantineTheme();

	const maintainOwners = !!integration?.credentials?.import_owners_preference;
	const noOwners = maintainOwners && entity.owners.length === 0;

	const canShowSLA =
		dataQualityScoreSla && entity?.entity_type?.toLowerCase() === 'table';

	const canLinkJira = entity?.entity_type === EntityType.question;
	const { data: isJiraIntegrated } = useIsJiraIntegrated({});

	const { usersVisibleToGuests } = useGuestUserList();
	const { activeUsers, userGroups: groups } = useExtendedUserList();

	const handleEntityChange = useCallback(
		(key: SidebarEntityKeys) =>
			(value: DjangoValueType, saveRemotely = true) => {
				updateEntity(key, value, saveRemotely);
				trackEvent(
					`${entity.entity_type}/${key}/update`,
					{ id: entity.id },
					user,
					workspace
				);
			},
		[entity.entity_type, entity.id, updateEntity, user, workspace]
	);

	const { mutateAsync: updateEntityCustomProperty } =
		useUpdateEntityCustomProperty({});

	const subscriberOptions = useMemo(
		() =>
			getOwnerAndGroupSelectorOptions(
				(isGuestUser ? usersVisibleToGuests : activeUsers) ?? [],
				groups ?? [],
				handleEntityChange('subscribers'),
				handleEntityChange('subscribers_groups')
			).map((owner) => ({
				...owner,
				itemTypeName: 'subscribers',
			})),
		[isGuestUser, usersVisibleToGuests, activeUsers, groups, handleEntityChange]
	);

	const withAddCustomProperty = withCustomPropertyEditors && editorOfEntity;

	const { data: _customProperties, isLoading: isLoadingCustomProperties } =
		useListCustomProperties(
			{},
			{
				enabled: !!withCustomPropertyEditors,
			}
		);

	const customPropertiesV2 = useMemo(
		() =>
			_customProperties?.filter(
				(cp) =>
					cp.entity_types.includes(entity.entity_type) ||
					cp.entity_types.includes(EntityType.all)
			),
		[_customProperties, entity.entity_type]
	);

	const subscribers = useMemo(
		() => [...(entity.subscribers ?? []), ...(entity.subscribers_groups ?? [])],
		[entity]
	);

	return (
		<Stack>
			{withMetricSection && (
				<CollapsableStack groupName="Configuration">
					{withMetricSection}
				</CollapsableStack>
			)}
			<CollapsableStack
				groupName="Properties"
				actions={
					withAddCustomProperty && (
						<Link to={'/settings/properties'}>
							<IconButton
								data-testid="add-custom-property-032487fh"
								iconName="plus"
								variant="tertiary"
								tooltip="Add property"
							/>
						</Link>
					)
				}
			>
				<Stack spacing="xs">
					{withAssignedToSelector && (
						<SelectableProperty label="Assigned to" dataTestId="assigned_to">
							<AssigneePropertySelector
								entity={entity}
								viewerOfEntity={viewerOfEntity}
								onChange={handleEntityChange('assigned_to')}
								handleEntityChange={handleEntityChange}
							/>
						</SelectableProperty>
					)}
					{withPrioritySelector && (
						<SelectableProperty label="Priority" dataTestId="priority">
							<PriorityPropertySelector
								entity={entity}
								viewerOfEntity={viewerOfEntity}
								onChange={handleEntityChange('priority')}
							/>
						</SelectableProperty>
					)}
					{withStatusSelector && (
						<SelectableProperty label="Status">
							<QuestionStatusPropertySelector
								entity={entity}
								viewerOfEntity={viewerOfEntity}
								onChange={handleEntityChange('status')}
							/>
						</SelectableProperty>
					)}
					{editorOfEntity &&
						!withCustomPublishElement &&
						!withStatusSelector && (
							<SelectableProperty label="Status" dataTestId="published">
								<StatusPropertySelector
									entity={entity}
									viewerOfEntity={viewerOfEntity}
									onChange={handleEntityChange('published')}
								/>
							</SelectableProperty>
						)}
					{!!withCustomPublishElement && withCustomPublishElement}
					{withTagSelector && (
						<SelectableProperty label="Tags" dataTestId="tags">
							<TagPropertySelector
								initialValue={entity.tags}
								readOnly={viewerOfEntity}
								placeholder={
									viewerOfEntity || entity.tags.length === 0
										? 'No tags'
										: 'Add tag'
								}
								forceVariant="tertiary"
								tagsManagedByIntegration={
									!!integration?.credentials?.import_tags_preference
								}
								entityIntegrationId={integration?.id}
								onChange={handleEntityChange('tags')}
							/>
						</SelectableProperty>
					)}
					{withCollectionSelector && (
						<SelectableProperty label="Collections" dataTestId="collections">
							<CollectionsPropertySelector
								entity={entity}
								viewerOfEntity={viewerOfEntity}
								onChange={handleEntityChange('collections')}
							/>
						</SelectableProperty>
					)}
					{noOwners ? (
						<RichTooltip
							title={`No owners were set in ${integration.name}`}
							body={'To maintain in Secoda, go to integration settings.'}
							linkLabel={
								isAdminUser ? `${integration?.name} settings` : undefined
							}
							linkUrl={
								isAdminUser
									? `/integrations/${integration?.id}/preferences`
									: undefined
							}
						>
							{withOwnerSelector && (
								<SelectableProperty label="Owners" dataTestId="owners">
									<OwnersPropertySelector
										entity={entity}
										viewerOfEntity={viewerOfEntity}
										integration={integration}
										handleEntityChange={handleEntityChange}
									/>
								</SelectableProperty>
							)}
						</RichTooltip>
					) : (
						withOwnerSelector && (
							<SelectableProperty label="Owners" dataTestId="owners">
								<OwnersPropertySelector
									entity={entity}
									viewerOfEntity={viewerOfEntity}
									integration={integration}
									handleEntityChange={handleEntityChange}
								/>
							</SelectableProperty>
						)
					)}
					{withGovernanceSelector && (
						<SelectableProperty label="Governance" dataTestId="governance">
							<GovernancePropertySelector
								entity={entity}
								viewerOfEntity={viewerOfEntity}
								onChange={handleEntityChange('pii')}
							/>
						</SelectableProperty>
					)}
					{withVerifiedSelector && (
						<SelectableProperty label="Verification" dataTestId="verification">
							<VerificationPropertySelector
								entity={entity}
								viewerOfEntity={viewerOfEntity}
								onChange={handleEntityChange('verified')}
							/>
						</SelectableProperty>
					)}
					{withTeamSelector && !isGuestUser && (
						<SelectableProperty label="Teams" dataTestId="teams">
							<TeamsPropertySelector
								entity={entity}
								viewerOfEntity={viewerOfEntity}
								integration={integration}
								onChange={handleEntityChange('teams')}
							/>
						</SelectableProperty>
					)}
					{withCollectionParentSelector && (
						<Group>
							<SelectableProperty label="Parent" dataTestId="collections">
								<CollectionParentPropertySelector
									entity={entity}
									viewerOfEntity={viewerOfEntity}
									onChange={handleEntityChange('collections')}
								/>
							</SelectableProperty>
							{entity.collections?.length > 0 && (
								<ActionIcon
									size="xs"
									onClick={() => handleEntityChange('collections')([])}
								>
									<Icon name="x" />
								</ActionIcon>
							)}
						</Group>
					)}
					{isLoadingCustomProperties && (
						<Stack spacing="xs">
							{[1, 2, 3].map((i) => (
								<Group key={i} noWrap align="center" spacing={0}>
									<Box miw={theme.other.space[32] + theme.other.space[1]}>
										<Skeleton height={20} width={100} />
									</Box>
									<Skeleton height={20} sx={{ flexGrow: 1 }} />
								</Group>
							))}
						</Stack>
					)}
					{customPropertiesV2?.map((cp) => (
						<Group key={cp.id} grow>
							<SelectableProperty
								label={cp.name}
								dataTestId={`custom-property-${cp.name}`}
							>
								<Box w="100%" ml={theme.spacing.xs}>
									<CustomPropertyRendererV2
										customProperty={cp}
										entity={entity}
										hideOnEmpty={false}
									/>
								</Box>
							</SelectableProperty>
						</Group>
					))}
				</Stack>
			</CollapsableStack>
			{canLinkJira && isJiraIntegrated && (
				<JiraIssueStack
					question={entity as IQuestion}
					isViewer={viewerOfEntity}
				/>
			)}
			{canShowSLA && <FreshnessStack entity={entity} />}
			{withSynonymsSelector && (
				<SynonymsStack entity={entity as IGlossaryTerm} />
			)}
			<RelatedResourceStack
				entity={entity}
				isVisible={withRelatedResourceSelector}
			/>
			<MetadataStack
				integration={integration}
				entity={entity}
				visibleUsers={(isGuestUser ? usersVisibleToGuests : activeUsers) ?? []}
				withFrequentUsers={withFrequentUsers}
				withEntityPopularity={withEntityPopularity}
				withEntityRowCount={withEntityRowCount}
				withEntityByteSize={withEntityByteSize}
				withAIInclusionStatus={withAIInclusionStatus}
			/>
			<CollapsableStack groupName="Subscribers">
				<Stack spacing="xs">
					<SelectableProperty label="Subscribers" dataTestId="subscribers">
						<PropertySelector
							selected={subscribers}
							type="multi"
							value="subscribers"
							iconType="avatar"
							options={subscriberOptions}
							permittedId={user.id}
							isViewerUser={false}
							readOnly={false}
						/>
					</SelectableProperty>
					{withSource && (
						<StaticProperty type="source" label="Source" value={withSource} />
					)}
				</Stack>
			</CollapsableStack>
		</Stack>
	);
}
export default EntitySidebarContent;
