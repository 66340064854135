import { Box, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
	stackContainer: {
		borderRadius: theme.radius.md,
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		fontSize: theme.fontSizes.xs,
		color: theme.other.getColor('text/secondary/default'),
	},
	stackTop: {
		borderTopLeftRadius: theme.radius.md,
		borderTopRightRadius: theme.radius.md,
		backgroundColor: theme.other.getColor('fill/primary/hover'),
	},
	stackFooter: {
		borderBottomLeftRadius: theme.radius.md,
		borderBottomRightRadius: theme.radius.md,
		backgroundColor: theme.other.getColor('fill/primary/hover'),
	},
}));

interface PolicySectionProps {
	title: string | React.ReactNode;
	content: React.ReactNode;
	footer?: React.ReactNode;
}

export default function PolicySection({
	title,
	content,
	footer,
}: PolicySectionProps) {
	const { classes } = useStyles();

	return (
		<Box className={classes.stackContainer}>
			<Box className={classes.stackTop} p="xs">
				{title}
			</Box>
			{content}
			{footer && <Box className={classes.stackFooter}>{footer}</Box>}
		</Box>
	);
}
