import { createStyles } from '@mantine/core';

export const useResourceLogStyles = createStyles((theme) => ({
	item: {
		'::before': {
			top: `calc(20px + ${theme.spacing['3xs']})`,
			bottom: `calc((${theme.spacing.xl} - ${theme.spacing['3xs']}) * -1)`,
			borderColor: theme.other.getColor('border/primary/default'),
			borderWidth: 1,
		},
	},
	itemBullet: {
		'&[data-with-child]': {
			backgroundColor: theme.other.getColor('fill/primary/default'),
			border: 'none',
		},
	},
}));
