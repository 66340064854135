import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { metricsQueryKeyFactory } from '.';
import type { ExecutionStatus } from '../../../components/Sql/types';
import { authHeaders, getEndpoints } from '../../common';

export function useCreateMetricExecution() {
	return useMutation({
		mutationFn: async ({ metricId }: { metricId: string }) => {
			const apiUrl = getEndpoints(metricsQueryKeyFactory.namespace).byPath([
				metricId,
				'execute',
			]);
			const response = await axios.post<ExecutionStatus>(
				apiUrl,
				{},
				authHeaders()
			);
			return response.data;
		},
	});
}
