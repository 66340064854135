import { useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import { SECODA_ENTITIES_NAMESPACE } from './constants';

export function useEntityMyPermission(entityId: string) {
	const queryKey = [...SECODA_ENTITIES_NAMESPACE, entityId, 'my_permission'];

	const queryFn = async () => {
		const url = getEndpoints(SECODA_ENTITIES_NAMESPACE).byPath([
			entityId,
			'my_permission',
		]);
		const { data } = await apiClient.get<{ read: boolean; write: boolean }>(
			url
		);
		return data;
	};

	return useQuery({
		queryKey,
		queryFn,
	});
}
