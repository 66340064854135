import { Box, Checkbox, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, Text } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import { useFormik } from 'formik';
import { isNil } from 'lodash-es';
import { memo } from 'react';
import { useUpdateUser, useUserList } from '../../api';
import { getHash } from '../../utils/shared.utils';
import { LoadingSpinner } from '../LoadingSpinner';

export const IntegrationPopularity = memo(() => {
	const theme = useMantineTheme();
	const { data: users } = useUserList({
		filters: { is_service_account: true },
	});
	const { mutate: updateUser } = useUpdateUser({});

	const formik = useFormik({
		initialValues: {},
		onSubmit: async (values) => {
			Object.keys(values).forEach((key) => {
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				const value = values[key];
				if (!isNil(value)) {
					const user = users?.results?.find(
						(u) => getHash(u.email).toString() === key
					);
					if (user) {
						updateUser({
							data: {
								id: user.id,
								service_account_count_popularity: value,
							},
						});
					}
				}
			});
			showNotification({
				message: 'User preferences updated sucessfully',
				color: 'green',
				autoClose: 5000,
			});
		},
	});

	if (!users) {
		return <LoadingSpinner />;
	}

	if (!users || users?.results?.length === 0) {
		return <Text>No service accounts found for this resource.</Text>;
	}

	return (
		<Box miw={space[120]}>
			<form onSubmit={formik.handleSubmit} autoComplete="off">
				{users?.results?.map((g) => {
					// We use a hashKey because '.' will break javascript objects.
					const hashKey = getHash(g.email).toString();
					return (
						<Box mt={theme.spacing.sm} key={g.email}>
							<Checkbox
								name={hashKey}
								// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
								isInvalid={!!formik.errors[hashKey]}
								checked={
									// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
									formik.values[hashKey] !== undefined
										? // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
											formik.values[hashKey]
										: g.service_account_count_popularity
								}
								label={g.email}
								onChange={(e) => {
									formik.setFieldValue(hashKey, e.target.checked);
								}}
								onBlur={formik.handleBlur}
							/>
						</Box>
					);
				})}

				<Button
					type="submit"
					disabled={formik.isSubmitting}
					mt={theme.spacing.md}
				>
					Submit
				</Button>
			</form>
		</Box>
	);
});

IntegrationPopularity.displayName = 'IntegrationPopularity';
