import { useOs } from '@mantine/hooks';
import { useRef } from 'react';
import { BOTTOM_PADDING, HEADER_HEIGHT, ROW_HEIGHT } from './TableV2.styles';

export function useTableV2Height({
	totalCountWithNesting,
	paginationSize,
	height,
}: {
	totalCountWithNesting: number;
	paginationSize: number;
	height: number | string | undefined;
}) {
	const tableRef = useRef<HTMLTableSectionElement>(null);

	const distanceFromTop = tableRef?.current?.getBoundingClientRect()?.top ?? 0;

	let tableMaxHeight =
		totalCountWithNesting > 0
			? HEADER_HEIGHT +
				2 +
				Math.min(paginationSize, totalCountWithNesting) * ROW_HEIGHT +
				// Account for the border widths of each row.
				Math.min(paginationSize, totalCountWithNesting) +
				1
			: undefined;

	const os = useOs();
	if (!!tableMaxHeight && os === 'windows') {
		// We need to add a 20px gutter to the max-height on Windows to account for
		// the scrollbars taking over the element's space in this issue we see that
		// the horizontal scrollbar is forcing the vertical scrollbar to appear,
		// which is causing the scrollbar to hide part of the elements on Mac this
		// doesn't happen because Mac uses overlay scrollbars, while Windows uses
		// classic scrollbars
		// Reference:
		// https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-gutter Note:
		// scrollbar-gutter doesn't work either because all of these elements have
		// fixed height. We need to revisit this whole structure to fix this
		// properly. This will cause some unwanted extra space at the bottom for
		// some windows users if the browser decides to use overlay scrollbars. This
		// is less of a problem than the current issue.
		tableMaxHeight += 20;
	}

	let tableHeight: number | string | undefined = height;
	if (!tableHeight) {
		// If the table is on the bottom-half of the screen, set height to auto.
		if (distanceFromTop > window.innerHeight / 2) {
			tableHeight = tableMaxHeight;
		} else {
			tableHeight = `calc(100vh - ${distanceFromTop + BOTTOM_PADDING}px)`;
		}
	}

	return {
		tableRef,
		tableHeight,
		tableMaxHeight,
	};
}
