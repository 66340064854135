import { Divider, Group, Stack } from '@mantine/core';
import type { DataAccessRequestOut } from '@repo/api-codegen';
import { UserAvatar } from '@repo/common/components';
import { Icon, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { useAuthUser, type ISecodaEntity } from '../../../api';
import { RelatedResourcePill } from '../../InlineRelatedResources/RelatedResourcePill';
import { DataAccessRequestActivityLog } from '../DataAccessRequestActivityLog/DataAccessRequestActivityLog';
import { DataAccessRequestStatusBadge } from '../DataAccessRequestStatusBadge/DataAccessRequestStatusBadge';
import { ViewCredentialsButton } from '../ViewCredentialsButton';

export interface DataAccessRequestContentProps {
	request: DataAccessRequestOut;
}

export function DataAccessRequestContent({
	request,
}: DataAccessRequestContentProps) {
	const { user } = useAuthUser();

	const resources =
		request.status === 'approved'
			? request.approved_resources
			: request.requested_resources;

	const reviewer = {
		deleted: null,
		approved: request.approved_by,
		rejected: request.rejected_by,
		pending: null,
		expired: request.approved_by,
		cancelled: request.cancelled_by,
	}[request.status];

	const durationLabel =
		{
			deleted: 'Deleted',
			pending: 'Access until',
			approved: 'Expires',
			rejected: 'Access end date',
			expired: 'Expired',
			cancelled: 'Revoked',
		}[request.status] ?? 'Access end date';

	return (
		<Stack spacing="xl">
			<Stack spacing="xs">
				<Group spacing="xs" noWrap align="baseline">
					<Text w={128} size="sm" py="3xs" color="text/secondary/default">
						Status
					</Text>
					<DataAccessRequestStatusBadge status={request.status} />
				</Group>
				<Group spacing="xs" noWrap align="baseline">
					<Text w={128} size="sm" py="3xs" color="text/secondary/default">
						Resources
					</Text>
					<Group spacing="xs" align="center" style={{ flex: 1 }}>
						{(resources ?? []).map((resource) => (
							<RelatedResourcePill
								key={resource.id}
								entity={
									{
										...resource,
										integration: request.integration_id,
									} as unknown as ISecodaEntity
								}
							/>
						))}
					</Group>
				</Group>
				<Group spacing="xs" noWrap align="center">
					<Text w={128} size="sm" py="3xs" color="text/secondary/default">
						Requested by
					</Text>
					<Group spacing="2xs" noWrap>
						<UserAvatar user={request.created_by} size="xs" />
						<Text size="sm">{request.created_by.display_name}</Text>
					</Group>
				</Group>
				<Group spacing="xs" noWrap align="center">
					<Text w={128} size="sm" py="3xs" color="text/secondary/default">
						{durationLabel}
					</Text>
					<Group spacing="2xs" noWrap>
						<Icon name="calendarEvent" />
						<Text size="sm">
							{(request.approved_expires_at ?? request.requested_expires_at)
								? dayjs(
										request.approved_expires_at ?? request.requested_expires_at
									).format('MMMM D, YYYY h:mm A')
								: 'Indefinite'}
						</Text>
					</Group>
				</Group>
				{reviewer && (
					<Group spacing="xs" noWrap align="center">
						<Text w={128} size="sm" py="3xs" color="text/secondary/default">
							Reviewer
						</Text>
						<Group spacing="2xs" noWrap>
							<UserAvatar user={reviewer} size="xs" />
							<Text size="sm">{reviewer.display_name}</Text>
						</Group>
					</Group>
				)}
				{request.temporary_user_name && (
					<Group spacing="xs" noWrap align="baseline">
						<Text w={128} size="sm" py="3xs">
							Temporary User
						</Text>
						<Text size="sm">{request.temporary_user_name}</Text>
					</Group>
				)}
				{request.status === 'approved' && request.approved_grant_role && (
					<Group spacing="xs" noWrap align="baseline">
						<Text w={128} size="sm" py="3xs">
							Role
						</Text>
						<Text size="sm">{request.approved_grant_role}</Text>
					</Group>
				)}
				{request.status === 'approved' &&
					request.approved_grant_privileges_to_role && (
						<Group spacing="xs" noWrap align="baseline">
							<Text w={128} size="sm" py="3xs">
								Privileges
							</Text>
							<Text size="sm">
								{request.approved_grant_privileges_to_role.join(', ')}
							</Text>
						</Group>
					)}
				{request.status === 'approved' &&
					request.created_by.id === user.id &&
					!request.has_seen_credentials &&
					request.temporary_user_name && (
						<Group spacing="xs" noWrap align="baseline">
							<Text w={128} size="sm" py="3xs">
								Credentials
							</Text>
							<ViewCredentialsButton request={request} />
						</Group>
					)}
			</Stack>
			<Divider />
			<DataAccessRequestActivityLog request={request} />
		</Stack>
	);
}
