import type { SelectItem } from '@mantine/core';
import { createStyles, Group, Skeleton } from '@mantine/core';
import { Text } from '@repo/foundations';
import { useCallback } from 'react';
import { Selector, SelectorTarget, SelectType } from '../Selectors';
import { SettingsBlock } from '../SettingsBlock';

interface SettingsSelectProps {
	title: string;
	description?: string;
	value?: string;
	options: SelectItem[];
	onChange: (value: string | null) => void;
	disabled?: boolean;
	searchable?: boolean;
	loading?: boolean;
}

const useStyles = createStyles((theme) => ({
	container: {
		paddingTop: theme.spacing['3xs'],
		paddingBottom: theme.spacing['3xs'],
	},
	inner: {
		paddingLeft: theme.spacing['xs'],
		paddingRight: theme.spacing['3xs'],
	},
}));

export function SettingsSelect({
	title,
	description,
	value,
	options,
	onChange,
	disabled = false,
	searchable = false,
	loading = false,
}: SettingsSelectProps) {
	const { classes, theme } = useStyles();
	const getItems = useCallback(
		(searchTerm: string) =>
			Promise.resolve(
				options
					.filter((item) => !searchTerm || item.label?.includes(searchTerm))
					.map((item) => ({
						label: item.label ?? '',
						value: item.value ?? '',
					}))
			),
		[options]
	);

	const handleChange = useCallback(
		(values: string[]) => {
			onChange(values[0] ?? '');
		},
		[onChange]
	);

	return (
		<SettingsBlock
			title={title}
			description={description}
			rightComponent={
				loading ? (
					<Skeleton height={theme.other.space[7]} width={100} />
				) : (
					<Selector
						initialValue={value ? [value] : []}
						getItems={getItems}
						onChange={handleChange}
						selectType={SelectType.Single}
						disableSearch={!searchable}
						disabled={disabled}
					>
						<SelectorTarget
							variant="tertiary"
							classNames={{
								container: classes.container,
								inner: classes.inner,
							}}
						>
							<Group spacing="2xs">
								<Text size="sm">
									{options.find((item) => item.value === value)?.label}
								</Text>
							</Group>
						</SelectorTarget>
					</Selector>
				)
			}
		/>
	);
}
