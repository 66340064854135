import { integrationList } from '@repo/common/constants/integration/integrations';
import { createMockableHook } from '@repo/common/utils/createMockableHook';
import { useMutation } from '@tanstack/react-query';
import type { IntegrationSpec } from '../../../interfaces/IntegrationSpec';
import { apiClient, getEndpoints } from '../../common';
import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type {
	IApiListResponse,
	IIntegration,
	IUseQueryHookArgs,
} from '../../types';
import { useMarketplaceIntegrationSpec } from '../marketplace';

export const INTEGRATIONS_NAMESPACE = ['integration', 'integrations'];

export const integrationsQueryKeyFactory = createQueryKeys(
	INTEGRATIONS_NAMESPACE
);

const { prefetchIntegration, prefetchIntegrationList } =
	prefetchFunctionsFactory('integration', integrationsQueryKeyFactory);

const {
	useIntegration: useIntegrationInternal,
	useIntegrationInfiniteList,
	useIntegrationList,
	useSuspenseIntegrationList,
	useCreateIntegration,
	useDeleteIntegration,
	useUpdateIntegration,
	fetchIntegrationList,
	updateIntegration,
} = baseQueryHooksFactory<IIntegration, 'integration'>(
	'integration',
	integrationsQueryKeyFactory
);

export const [useIntegration, MockUseIntegration] = createMockableHook(
	useIntegrationInternal
);

export {
	fetchIntegrationList,
	prefetchIntegration,
	prefetchIntegrationList,
	updateIntegration,
	useCreateIntegration,
	useDeleteIntegration,
	useIntegrationInfiniteList,
	useIntegrationList,
	useSuspenseIntegrationList,
	useUpdateIntegration,
};

// This is a wrapper for the `useIntegrationList`. This let's us access
// integration properties without making separate API calls for each
// integration. A use case is to fetch the integration icons on the catalog page
// for a given integration id. We will assume there is only one page (50
// integrations max.).
export const useIntegrationPrefetched = (
	props: IUseQueryHookArgs<IApiListResponse<IIntegration>>
) => {
	const { data: integrations } = useIntegrationList({
		options: { staleTime: Infinity, ...props.options },
	});
	return {
		data: integrations?.results?.find((i: IIntegration) => i.id === props.id),
	};
};

export function useIntegrationSpec(
	integrationId: string
): IntegrationSpec | null {
	const { data: integration, isLoading } = useIntegration({
		id: integrationId,
		options: {
			enabled: !!integrationId,
		},
	});

	const { data: spec } = useMarketplaceIntegrationSpec({
		id: integration?.marketplace_integration_spec_version?.spec_id || '',
		options: {
			enabled: Boolean(integration?.marketplace_integration_spec_version),
		},
	});

	if (isLoading || !integration) {
		return null;
	}

	if (integration.marketplace_integration_spec_version) {
		if (!spec) return null;

		return {
			type: 'marketplace',
			value: spec,
		};
	}

	return {
		type: 'builtin',
		value: integrationList.find((i) => i.type === integration.type)!,
	};
}

export function useImportMetadataIntegration(integrationId: string) {
	const mutationFn = async (file: File) => {
		const url = getEndpoints(integrationsQueryKeyFactory.namespace).byPath([
			integrationId,
			'import_metadata',
		]);

		const form = new FormData();
		form.append('file', file);

		await apiClient.post(url, form);
		return true;
	};

	return useMutation({
		mutationFn,
	});
}

export function useImportJSONLMetadataIntegration(integrationId: string) {
	const mutationFn = async ({
		resourcesFile,
		lineagesFile,
	}: {
		resourcesFile: File;
		lineagesFile: File | null;
	}) => {
		const url = getEndpoints(INTEGRATIONS_NAMESPACE).byPath([
			integrationId,
			'import_jsonl_metadata',
		]);

		const form = new FormData();
		form.append('resources_file', resourcesFile);

		if (lineagesFile) {
			form.append('lineages_file', lineagesFile);
		}

		await apiClient.post(url, form);
		return true;
	};

	return useMutation({
		mutationFn,
	});
}
