import type { Faro } from '@grafana/faro-react';
import {
	createSession,
	getWebInstrumentations,
	initializeFaro,
	LogLevel,
	ReactIntegration,
	ReactRouterVersion,
} from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { matchRoutes } from 'react-router-dom';
import { IS_LOCAL_OR_DEVELOPMENT } from './utils/envs';

let faro: Faro | null = null;

// can't use IS_PRODUCTION here because development.secoda.co is also considered production
// Grafana is not enabled in development
if (!IS_LOCAL_OR_DEVELOPMENT) {
	faro = initializeFaro({
		url: 'https://faro-collector-prod-us-east-0.grafana.net/collect/0f41a950a23577388c335ae380159853',
		app: {
			name: 'app.secoda.co',
			version: import.meta.env.VITE_FRONTEND_VERSION ?? '1.0.0',
			environment: 'production',
		},

		sessionTracking: {
			enabled: true,
			persistent: true,
			samplingRate: 0.5,
		},

		instrumentations: [
			...getWebInstrumentations(),
			new TracingInstrumentation(),
			new ReactIntegration({
				router: {
					version: ReactRouterVersion.V6_data_router,
					dependencies: {
						matchRoutes,
					},
				},
			}),
		],
	});
}

export function captureError(error: unknown) {
	if (faro) {
		faro.api.pushError(error as Error);
	} else {
		// eslint-disable-next-line no-console
		console.error(error);
	}
}

export function captureLog(
	messages: string[],
	level: LogLevel = LogLevel.INFO
) {
	if (faro) {
		faro.api.pushLog(messages, { level });
	} else {
		// eslint-disable-next-line no-console
		console.log(...messages);
	}
}
export function setGrafanaSessionMetadata(props: Record<string, string>) {
	if (faro) {
		faro.api.setSession(createSession(props));
	} else {
		// eslint-disable-next-line no-console
		console.info('Setting Grafana session metadata', props);
	}
}

export function resetGrafanaSessionMetadata() {
	if (faro) {
		faro.api.resetSession();
	} else {
		// eslint-disable-next-line no-console
		console.info('Resetting Grafana session metadata');
	}
}
