import {
	Center,
	createStyles,
	Flex,
	Grid,
	Group,
	Menu,
	Stack,
	Tooltip,
} from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import MarkdownRenderer from '@repo/common/components/MarkdownRenderer';
import { Icon, IconButton, Text } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import dayjs from 'dayjs';
import { some, sortBy, uniqBy } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useAuthUser, type IComment } from '../../../api';
import base from '../../../components/Editor/outline/src/styles/theme';
import { InlineEmoji } from '../../../components/InlineEmojiPicker/InlineEmoji';
import { InlineEmojiPicker } from '../../../components/InlineEmojiPicker/InlineEmojiPicker';
import { updateReactionArray } from '../../../components/InlineEmojiPicker/utils';
import { RichEditor } from '../../../components/RichEditor';
import { UserAvatar } from '../../../components/UserAvatar';
import type { WithOnlyIdRequired } from '../../../lib/typescript';
import { formatShortTimeDifference } from '../../../utils/time';
import { useCommentStoreContext } from '../context';
import CommentForm from './CommentForm';
import { useCommentStyles } from './styles';

const useStyles = createStyles(
	(
		theme,
		{
			editActionOnly,
			actionGroupVisible,
		}: { editActionOnly: boolean; actionGroupVisible: boolean }
	) => ({
		comment: {
			position: 'relative',
			// Actions are visible on comment hovered
			'&:focus, &:hover': {
				'#actionSection': {
					display: 'inline-flex',
				},
			},
		},
		line: {
			paddingTop: theme.spacing.sm,
			width: theme.other.space[0.25],
			height: `calc(100% - ${theme.other.space[5]}px)`,
			minHeight: theme.other.space[3],
			backgroundColor: theme.other.getColor('border/secondary/default'),
		},
		commentHighlight: {
			backgroundColor: base.textComment,
			fontSize: theme.fontSizes.md,
		},
		actionSection: {
			display: actionGroupVisible ? 'inline-flex' : 'none',
			position: 'absolute',
			borderRadius: theme.radius.sm,
			border: `1px solid ${theme.other.getColor('border/primary/default')}`,
			top: 2,
			right: 0,
			zIndex: 1,
			backgroundColor: theme.other.getColor('fill/primary/default'),
			padding: editActionOnly ? 0 : '4px',
		},
		inlineEmojiSection: {
			display: 'flex',
			gap: theme.spacing.xs,
			flexWrap: 'wrap',
			paddingBottom: theme.spacing.md,
		},
		readOnlyEditor: {
			overflowY: 'auto',
			'& .ProseMirror': {
				wordBreak: 'break-word',
				fontSize: theme.fontSizes.md,
			},
		},
	})
);

export type ICommentProps = {
	comment: IComment;
	showQuotedText?: boolean;
	onUpdateComment: (data: WithOnlyIdRequired<IComment>) => Promise<void>;
	onDeleteComment: (id: string) => Promise<void>;
	showResolveAction?: boolean;
	showLine?: boolean;
	// TODO[tan]: This is a hacky way to get the title to truncate properly. Refactor this.
	titleMaxWidth?: number;
};

function Comment({
	comment,
	showQuotedText = false,
	onUpdateComment,
	onDeleteComment,
	showResolveAction = false,
	showLine = false,
	titleMaxWidth = space[28],
}: ICommentProps) {
	const [editing, setEditing] = useState<boolean>(false);
	const { handleProseMirrorRemoveComment } = useCommentStoreContext();

	const ref = useClickOutside(() => setEditing(false));
	const { user: currentUser } = useAuthUser();
	const isCurrentUserOwner = comment.owner.id === currentUser?.id;
	const isCurrentCommentRoot = comment.root === comment.id;
	const reactionVisible =
		!editing && some(comment.reactions ?? [], (r) => r.users.length > 0);

	const isEdited =
		dayjs(comment.edited_at).diff(dayjs(comment.created_at), 's') > 0;
	const formattedTime = `${formatShortTimeDifference(comment.edited_at)}${
		isEdited ? ' (edited)' : ''
	}`;

	// === Emoji ===
	const handleSelectEmoji = (emoji: { native: string }) => {
		const newReactions = updateReactionArray(
			comment.reactions,
			emoji.native,
			currentUser?.id
		);
		onUpdateComment({ id: comment.id, reactions: newReactions });
	};

	const [emojiMenuOpen, setEmojiMenuOpen] = useState(false);
	const handleMenuOpenChange = (open: boolean) => {
		setEmojiMenuOpen(open);
	};

	const { theme, classes } = useStyles({
		editActionOnly:
			reactionVisible && (!isCurrentCommentRoot || comment.resolved),
		actionGroupVisible: emojiMenuOpen,
	});
	const { classes: commentClasses } = useCommentStyles();

	// ===============
	const toggleEdit = () => {
		setEditing(!editing);
	};
	const handleDelete = () => onDeleteComment(comment.id);
	const handleCancel = () => {
		setEditing(false);
	};
	const handleCommentUpdate = async (commentDefinition: string) => {
		await onUpdateComment({ id: comment.id, definition: commentDefinition });
		setEditing(false);
	};
	const handleCommentResolve = async (
		event: React.MouseEvent<HTMLElement, MouseEvent>
	) => {
		event.stopPropagation();
		event.preventDefault();
		await onUpdateComment({ id: comment.id, resolved: true });
		handleProseMirrorRemoveComment(comment.id);
	};

	return (
		<Grid ref={ref} className={classes.comment}>
			<Grid.Col span={2} className={commentClasses.commentAvatar}>
				<Flex direction="column" align="center" gap="3xs">
					<Center p={2}>
						<UserAvatar user={comment.owner} size="xxs" />
					</Center>
					{showLine && <div className={classes.line} />}
				</Flex>
			</Grid.Col>
			<Grid.Col span="auto" className={commentClasses.commentContent}>
				<Stack spacing={theme.other.space[1]}>
					<Group spacing="md" position="apart">
						<Group spacing={theme.other.space[2]} w="100%" noWrap>
							<Group style={{ flex: 1 }}>
								<Text
									size="md"
									color="text/primary/default"
									weight="bold"
									truncate
									maw={comment.resolved ? titleMaxWidth - 25 : titleMaxWidth}
								>
									{comment.owner.display_name || comment.owner.email}
								</Text>
								<Tooltip label={dayjs(comment.updated_at).format('LLL')}>
									<Text size="xs" color="text/secondary/default">
										{formattedTime}
									</Text>
								</Tooltip>
							</Group>
							<Group position="right">
								{comment.resolved && (
									<Icon name="check" color="icon/secondary/default" />
								)}
							</Group>
						</Group>
						{!editing && (
							<Group
								spacing={0}
								className={classes.actionSection}
								id="actionSection"
							>
								{!reactionVisible && (
									<InlineEmojiPicker
										onClick={handleSelectEmoji}
										onOpenChange={handleMenuOpenChange}
									/>
								)}
								{showResolveAction &&
									isCurrentCommentRoot &&
									!comment.resolved && (
										<IconButton
											iconName="check"
											variant="tertiary"
											tooltip="Resolve"
											onClick={handleCommentResolve}
										/>
									)}
								{isCurrentUserOwner && (
									<Menu shadow="md" position="left-start">
										<Menu.Target>
											<IconButton
												iconName="dots"
												variant="tertiary"
												tooltip="More actions"
											/>
										</Menu.Target>
										<Menu.Dropdown>
											<Menu.Item
												icon={<Icon name="pencil" />}
												onClick={toggleEdit}
											>
												Edit
											</Menu.Item>
											<Menu.Item
												icon={<Icon name="trash" />}
												onClick={handleDelete}
											>
												Delete
											</Menu.Item>
										</Menu.Dropdown>
									</Menu>
								)}
							</Group>
						)}
					</Group>
					<Stack spacing={0}>
						{showQuotedText && comment.quoted_text && (
							<MarkdownRenderer className={classes.commentHighlight} inline>
								{comment.quoted_text}
							</MarkdownRenderer>
						)}
						{editing ? (
							<CommentForm
								autoFocus
								isEditExisting
								definition={comment.definition}
								onSubmit={handleCommentUpdate}
								onCancel={handleCancel}
							/>
						) : (
							<>
								<RichEditor
									readOnly
									initialValue={comment?.definition}
									className={classes.readOnlyEditor}
								/>
								{reactionVisible && (
									<Flex className={classes.inlineEmojiSection}>
										{sortBy(uniqBy(comment.reactions ?? [], 'emoji'), 'emoji')
											.filter((f) => f.users.length > 0)
											.map(({ emoji, users: emojiUsers }) => (
												<InlineEmoji
													key={emoji}
													emoji={emoji}
													users={emojiUsers}
													onToggle={handleSelectEmoji}
												/>
											))}
										<InlineEmojiPicker onClick={handleSelectEmoji} />
									</Flex>
								)}
							</>
						)}
					</Stack>
				</Stack>
			</Grid.Col>
		</Grid>
	);
}

export default observer(Comment);
