import { Box } from '@mantine/core';
import { SettingsBlock, SettingsSwitch } from '@repo/common/components';
import { useAuthUser } from '../../api';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import { useUpdateWorkspace } from '../../api/hooks/workspace/useUpdateWorkspace';
import { EmptyState } from '../EmptyState';
import { QualityGovernanceFilters } from './GovernanceFilters/QualityGovernanceFilters';
import { UpgradeButton } from './UpgradeButton';

export function QualityScoreSettings() {
	const { workspace } = useAuthUser();
	const { mutateAsync: updateWorkspace } = useUpdateWorkspace(workspace.id);

	const { dqsAccess } = useFeatureAccess();

	if (!dqsAccess) {
		return (
			<EmptyState
				title="Upgrade to access Quality Score"
				description="You can use the Data Quality Score to understand and improve your data quality."
				illustrationName="upgrade"
				includeGoBack={false}
				stateHeight="50vh"
				size="lg"
				withActions={<UpgradeButton feature="DQS" size="md" />}
			/>
		);
	}

	return (
		<Box>
			<SettingsSwitch
				onChange={(checked) =>
					updateWorkspace({
						data: { id: workspace.id, quality_enabled: checked },
					})
				}
				checked={workspace?.quality_enabled ?? false}
				title="Data Quality Score"
				description="Turn on Quality Score for all tables"
			/>
			{workspace?.quality_enabled && (
				<SettingsBlock title="" description="">
					<QualityGovernanceFilters />
				</SettingsBlock>
			)}
		</Box>
	);
}
