import { Group, Stack, Tabs } from '@mantine/core';
import TabsList from '@repo/common/components/TabsList';
import { UserRole } from '@repo/common/enums/UserRole';
import { Button } from '@repo/foundations';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useAuthUser, useMonitorList } from '../../api';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import AddMonitorButton from '../../components/AddMonitor/AddMonitorButton';
import { EmptyState } from '../../components/EmptyState';
import { FeatureGuard } from '../../components/FeatureGuard/FeatureGuard';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { trackEvent } from '../../utils/analytics';
import { useListPageTabNavigate } from '../../utils/hook/useTabNavigate';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import IncidentList from './IncidentList';
import MonitorList from './MonitorList';

type MonitorListPageTab = 'monitors' | 'incidents';

function MonitorListPage() {
	const { tab } = useParams();
	const { user, workspace } = useAuthUser();
	const { data: monitors, isLoading } = useMonitorList({});
	const { monitoringAccess, isLoading: isFeatureAccessLoading } =
		useFeatureAccess();

	const { classes, theme } = useStyles();
	const [activeTab, setActiveTab] = useState<MonitorListPageTab>(
		tab === 'incidents' ? 'incidents' : 'monitors'
	);
	const navigateToTab = useListPageTabNavigate();

	const handleTabChange = (value: string): void => {
		if (value === 'monitors' || value === 'incidents') {
			setActiveTab(value);
			navigateToTab(value);
		}
		trackEvent(
			'monitors/tabs/click',
			{
				tab: value,
			},
			user,
			workspace!
		);
	};

	const learnMoreAction = useMemo(
		() => (
			<Group>
				<Button
					component="a"
					href="https://docs.secoda.co/features/monitoring#how-to-create-monitors"
					target="_blank"
					rel="noreferrer noopener"
					size="md"
					leftIconName="externalLink"
				>
					Learn more
				</Button>
				<AddMonitorButton />
			</Group>
		),
		[]
	);

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (monitors && monitors.results.length === 0) {
		if (isFeatureAccessLoading) {
			return <FullWidthLoadingSpinner />;
		}

		return (
			<EmptyState
				className={classes.emptySpace}
				illustrationName="monitors"
				title="Keep a pulse on your data"
				description="Add monitors to your data, set thresholds, and be alerted as soon as incidents are detected."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={learnMoreAction}
			/>
		);
	}

	return (
		<FeatureGuard
			v1AllowedRoles={[UserRole.ADMIN, UserRole.EDITOR, UserRole.VIEWER]}
			v2Permission="Monitors.Read"
			isFeatureIncludedInPlan={true}
			isAlwaysIncludedFeature={true}
			featureName="Monitors"
			description="Add monitors to your data, set thresholds, and be alerted as soon as incidents are detected."
		>
			<Stack
				className={classes.wrapper}
				spacing={0}
				mt={`-${theme.other.space[2]}px`}
			>
				<Tabs
					color="fill/brand/default"
					value={activeTab}
					onTabChange={handleTabChange}
				>
					<TabsList
						tabs={[
							{ value: 'monitors', label: 'Monitors' },
							{ value: 'incidents', label: 'Incidents' },
						]}
					/>
					<Tabs.Panel value="monitors" pt="sm">
						<MonitorList />
					</Tabs.Panel>
					<Tabs.Panel value="incidents" pt="sm">
						<IncidentList />
					</Tabs.Panel>
				</Tabs>
			</Stack>
		</FeatureGuard>
	);
}

export default MonitorListPage;
