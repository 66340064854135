import { Container, createStyles, Stack, Tabs } from '@mantine/core';
import IconEmojiSelector from '@repo/common/components/IconEmojiSelector/IconEmojiSelector';
import type { TabItem } from '@repo/common/components/TabsList';
import TabsList from '@repo/common/components/TabsList';
import { UserRole } from '@repo/common/enums/UserRole';
import { space } from '@repo/theme/primitives';
import { useDebounceFn } from 'ahooks';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router';
import type { Automation } from '../../api';
import { AutomationTriggerType, useAuthUser } from '../../api';
import { useAutomation, useUpdateAutomation } from '../../api/hooks/automation';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import AddAutomationActionButton from '../../components/Automation/AddAutomationActionButton';
import AutomationCard from '../../components/Automation/AutomationCard/AutomationCard';
import { AutomationTriggerCardStoreProvider } from '../../components/Automation/AutomationCard/AutomationTriggerCard/context';
import { AutomationActionCardType } from '../../components/Automation/constants';
import { EntityPageDescription } from '../../components/EntityPageLayout';
import EntityPageTitle from '../../components/EntityPageLayout/EntityPageTitle';
import { FeatureGuard } from '../../components/FeatureGuard/FeatureGuard';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import { isValidEnumValue } from '../../utils/enumUtils';
import { sanitizeMarkdownString } from '../../utils/shared.utils';
import AutomationRunHistoryTab from './AutomationRunHistoryTab';
import AutomationConfigurationSidebar from './containers/AutomationConfigurationSidebar';
import AutomationPageNavBar from './containers/AutomationPageNavbar';
import { useAutomationStore } from './context';
export interface AutomationPageProps {
	id?: string;
}

const useStyles = createStyles((theme) => ({
	stackContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	stack: {
		maxWidth: space[160], // 640px
		width: '100%',
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		alignItems: 'center',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		padding: `${theme.other.space[4]}px ${theme.other.space[6]}px`,
	},
	backButtonGroup: {
		gap: 0,
	},
	backButtonIcon: {
		borderRadius: theme.radius.sm,
		background: theme.other.getColor('fill/transparent/default'),
	},
	verticalLine: {
		position: 'relative',
		borderLeft: `2px dotted ${theme.other.getColor(
			'border/secondary/default'
		)}`,
		height: theme.spacing.md,
		padding: 0,
	},
}));

function AutomationPage({ id: propsId }: AutomationPageProps) {
	const { id: paramsId } = useParams();
	const id = propsId || paramsId;

	if (!id) {
		throw new Error('Unable to find automation ID');
	}

	const store = useAutomationStore();
	const { classes } = useStyles();
	const [activeTab, setActiveTab] = useState<string | null>('automation');
	const { data: automation } = useAutomation({
		id,
	});

	useEffect(() => {
		if (automation) {
			store.automation = automation;
			store.setupCards();
		}
	}, [automation, store]);

	const automationTitle = useMemo(
		() => ({
			title: store.automation?.title ?? '',
			title_cased: store.automation?.title ?? '',
		}),
		[store.automation]
	);

	const { mutateAsync: updateAutomation } = useUpdateAutomation({});
	const { workspace } = useAuthUser();
	// Debounce updating title to avoid spamming API
	const { run: handleUpdateTitle } = useDebounceFn(
		async (value) => {
			if (!store.automation?.id) {
				return;
			}

			if (value) {
				await updateAutomation({
					data: {
						id: store.automation.id,
						title: value,
					},
				});
			}
		},
		{ wait: 450 }
	);

	const handleAutomationUpdate = useCallback(
		async (data: Partial<Automation>): Promise<void> => {
			if (!store.automation?.id) {
				return;
			}

			const updatedAutomation = await updateAutomation({
				data: {
					id: store.automation?.id,
					...data,
				},
			});

			store.automation = updatedAutomation;
		},
		[store, updateAutomation]
	);

	// No need to debounce description updates because it is handled
	// by the EntityPageDescription component
	const handleUpdateDescription = useCallback(
		async (value: string) => {
			if (!store.automation?.id) {
				return;
			}

			let updatedValue = value;

			// Need to handle the specific markdown string from RichEditor
			if (value.charCodeAt(0) === 92) {
				updatedValue = sanitizeMarkdownString(value);
			}

			await handleAutomationUpdate({
				description: updatedValue || '',
			});
		},
		[handleAutomationUpdate, store]
	);

	const { automationsAccess } = useFeatureAccess();

	if (!workspace.automation_enabled) {
		return <Navigate to="/" />;
	}

	const tabsList: TabItem[] = [
		{ value: 'automation', label: 'Automation' },
		{ value: 'run_history', label: 'Run history' },
	];

	const handleIconChange = async (value: string) => {
		if (store.automation?.id) {
			await handleAutomationUpdate({
				icon: value,
			});
		}
	};

	const icon = (
		<IconEmojiSelector
			value={store.automation?.icon ?? 'iconName:bolt iconColor:#4a4a4a'}
			onChange={handleIconChange}
			entityType={'automation'}
		/>
	);

	return (
		<FeatureGuard
			featureName="Automations"
			description="Automatically set tags, owners, property values, and so much more with automations."
			v1AllowedRoles={[UserRole.ADMIN, UserRole.EDITOR]}
			v2Permission="Automations.Read"
			isFeatureIncludedInPlan={automationsAccess ?? false}
			isAlwaysIncludedFeature={false}
		>
			<PageLayoutOuterWrapper>
				<PageLayoutWrapper name="automation">
					<PageLayoutContentWrapper name="automation">
						{automation && <AutomationPageNavBar automation={automation} />}
						<PageLayoutContent className={classes.stackContainer}>
							<Stack spacing="xs" py="md" className={classes.stack}>
								{automation && store.automation && (
									<>
										<EntityPageTitle
											entity={automationTitle}
											onChange={handleUpdateTitle}
											placeholder="Untitled"
											isReadOnly={false}
											icon={icon}
											wrapTitle
										/>
										<EntityPageDescription
											onChange={handleUpdateDescription}
											description={store.automation.description ?? ''}
										/>
										<Tabs
											color="dark"
											classNames={{
												root: classes.root,
												panel: classes.panel,
											}}
											defaultValue="automation"
											value={activeTab}
											onTabChange={setActiveTab}
										>
											<TabsList tabs={tabsList} />
											<Tabs.Panel value="automation" pt="sm">
												<AutomationTriggerCardStoreProvider
													automation={store.automation}
												>
													{store.cards.map((card) => {
														// Card by itself is a mobx proxy object
														// so we need to convert it to a plain object
														const jsCard = toJS(card);
														// Do not include filtercards if trigger TABLE_DROPPED or SCHEMA_CHANGE
														if (
															store.automation &&
															(store.automation.trigger_type ===
																AutomationTriggerType.TABLE_DROP ||
																store.automation.trigger_type ===
																	AutomationTriggerType.SCHEMA_CHANGE) &&
															!jsCard.isNthFilterResourceCard
														) {
															const filterAndEditCardTypes = [
																AutomationActionCardType.FILTER_RESOURCES,
																AutomationActionCardType.FILTER_COUNT,
															];

															if (
																isValidEnumValue(
																	AutomationActionCardType,
																	jsCard.type
																) &&
																filterAndEditCardTypes.includes(jsCard.type)
															) {
																return null;
															}
														}

														return (
															<>
																<AutomationCard
																	key={jsCard.id}
																	cardId={jsCard.id}
																	type={jsCard.type}
																	titleIcon={jsCard.titleIcon}
																	searchKey={jsCard.searchKey}
																	isNthFilterResourceCard={
																		jsCard.isNthFilterResourceCard
																	}
																	isSecondFilterResourceCard={
																		jsCard.isSecondFilterResourceCard
																	}
																	handleAutomationUpdate={
																		handleAutomationUpdate
																	}
																/>
																<Container
																	key={`${jsCard.id}${card.type}`}
																	className={classes.verticalLine}
																/>
															</>
														);
													})}
													<AddAutomationActionButton />
												</AutomationTriggerCardStoreProvider>
											</Tabs.Panel>
											<Tabs.Panel value="run_history" pt="sm">
												<AutomationRunHistoryTab
													automationId={store.automation.id}
												/>
											</Tabs.Panel>
										</Tabs>
									</>
								)}
							</Stack>
						</PageLayoutContent>
					</PageLayoutContentWrapper>
					{automation && store.automation && (
						<AutomationConfigurationSidebar automation={store.automation} />
					)}
				</PageLayoutWrapper>
			</PageLayoutOuterWrapper>
		</FeatureGuard>
	);
}

export default observer(AutomationPage);
