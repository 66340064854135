import {
	createStyles,
	Divider,
	Group,
	SimpleGrid,
	Skeleton,
	Space,
	Stack,
} from '@mantine/core';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Text, Title } from '@repo/foundations';
import { chunk, isEmpty, map, size, slice } from 'lodash-es';
import type { MouseEvent } from 'react';
import type { ISecodaEntity } from '../../../../../../../api';
import { useAuthUser, useSecodaEntityList } from '../../../../../../../api';
import { SecodaEntityIcon } from '../../../../../../../components/SecodaEntity';
import { useEntityBreadcrumbs } from '../../../../../../../hooks/useEntityBreadcrumbs';
import { WidgetType } from '../../../../../../../interfaces';
import { buildResourceUrl } from '../../../../../../../utils/navigationUtils';
import { snakeCaseToTitleCase } from '../../../../../../../utils/shared.utils';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';
import type { IWidgetItemProps, ResourceWidgetTypes } from '../../../types';
import EmptyResourcesWidget from './EmptyResourcesWidget';

export type IResourcesWidgetProps = IWidgetItemProps & {
	onWidgetTypeChange?: (widgetType: WidgetType) => void;
};

const useStyles = createStyles((theme) => ({
	resource: {
		cursor: 'pointer',
		borderRadius: theme.radius.xs,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/transparent/hover'),
		},
	},
	markdown: {
		color: theme.other.getColor('text/secondary/default'),
		fontSize: theme.fontSizes.sm,
	},
	subtitle: {
		color: theme.other.getColor('text/secondary/default'),
	},
}));

export function ResourceWidgetItem({ entity }: { entity: ISecodaEntity }) {
	const { classes } = useStyles();

	const { isLoading, breadcrumbs } = useEntityBreadcrumbs(entity);

	if (isLoading === null) {
		return null;
	}

	return (
		<Group align="self-start" noWrap>
			<SecodaEntityIcon size={32} entity={entity} />
			<Stack spacing={2}>
				<Title order={6} lineClamp={1}>
					{entity.title_cased || entity.title || 'Untitled'}
				</Title>
				<Text className={classes.subtitle} size="xs" lineClamp={2}>
					{entity.entity_type === 'table' || entity.entity_type === 'dashboard'
						? map(breadcrumbs, 'title').join(' / ')
						: snakeCaseToTitleCase(entity.entity_type)}
				</Text>
			</Stack>
		</Group>
	);
}

function ResourcesWidget({
	widget,
	onWidgetTypeChange,
}: IResourcesWidgetProps) {
	const { user } = useAuthUser();
	const { isTeamsPage, team } = useHomepageTeam();

	const { classes, theme } = useStyles();
	const navigateHandler = useNavigateHandler();

	const widgetType = widget.type as ResourceWidgetTypes;

	const filters: Record<string, string> = { bookmarked_by__id: user.id };

	if (isTeamsPage) {
		filters.teams = team.id;
	}

	const { data: entities, isFetching } = useSecodaEntityList({
		filters,
		options: {
			select: ({ results }) => results,
			enabled: widgetType === WidgetType.BOOKMARKED_RESOURCES,
		},
	});

	const resourcesToDisplay =
		widgetType === WidgetType.BOOKMARKED_RESOURCES
			? entities
			: (widget.data?.results ?? []);

	const shouldChunkData =
		size(resourcesToDisplay) > 5 && widget.size === 'full';

	const chunkCount = shouldChunkData ? 5 : 10;
	const cols = shouldChunkData ? 2 : 1;

	const resources: ISecodaEntity[][] = chunk(
		slice(resourcesToDisplay, 0, 10),
		chunkCount
	);

	if (isFetching) {
		return <Skeleton my={theme.spacing.xs} h={280} />;
	}

	if (isEmpty(resources)) {
		return (
			<EmptyResourcesWidget
				widgetType={widgetType}
				onWidgetTypeChange={onWidgetTypeChange}
			/>
		);
	}

	const handleNavigateToResource =
		(resource: ISecodaEntity) => (event: MouseEvent) => {
			const url = buildResourceUrl(resource);
			navigateHandler(event)(url);
		};

	return (
		<SimpleGrid cols={cols} spacing={0}>
			{map(resources, (resourceChunk, chunkIndex) => (
				<Stack key={chunkIndex} spacing={0}>
					{map(resourceChunk, (resource, resourceIndex) => (
						<Stack
							key={resource.id}
							pt="sm"
							px="sm"
							spacing="sm"
							className={classes.resource}
							onClick={handleNavigateToResource(resource)}
						>
							<ResourceWidgetItem entity={resource} />
							{resourceIndex !== chunkCount - 1 ? <Divider /> : <Space />}
						</Stack>
					))}
				</Stack>
			))}
		</SimpleGrid>
	);
}

export default ResourcesWidget;
