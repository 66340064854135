import { showNotification } from '@mantine/notifications';
import { UserRole } from '@repo/common/enums/UserRole';
import { createMockableHook } from '@repo/common/utils/createMockableHook';
import type { QueryKey } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { size } from 'lodash-es';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import { useAuthUser } from '../authUser';
import { WORKSPACE_NAMESPACE } from './constants';
import { useLimits } from './useLimits';

export function useWorkspaceInternal() {
	const { workspace } = useAuthUser();

	return {
		workspace,
	};
}

export const [useWorkspace, MockUseWorkspace] =
	createMockableHook(useWorkspaceInternal);

interface RequestData {
	emails: string[];
	role: UserRole;
	iam_role_id?: string | null;
	groups: string[];
	teams: string[];
}

function validateSeatLimits(
	role: UserRole,
	emailCount: number,
	{
		editorOrAdminSeats,
		viewerSeats,
		usedEditorOrAdminSeats,
		usedViewerSeats,
	}: ReturnType<typeof useLimits>
): boolean {
	if (
		(role === UserRole.EDITOR || role === UserRole.ADMIN) &&
		usedEditorOrAdminSeats + emailCount > editorOrAdminSeats
	) {
		showNotification({
			title: 'Error sending invitations',
			message: `Your workspace has used ${usedEditorOrAdminSeats} of ${editorOrAdminSeats} editor or equivalent roles`,
			color: 'red',
		});
		return false;
	}
	if (
		(role === UserRole.VIEWER || role === UserRole.GUEST) &&
		usedViewerSeats + emailCount > viewerSeats
	) {
		showNotification({
			title: 'Error sending invitations',
			message: `Your workspace has used ${usedViewerSeats} of ${viewerSeats} viewer seats`,
			color: 'red',
		});
		return false;
	}
	return true;
}

export function useInviteUsers(invalidationKey: QueryKey) {
	const { workspace } = useWorkspace();
	const limits = useLimits();

	const mutationFn = async ({
		emails,
		role,
		groups,
		teams,
		iam_role_id,
	}: RequestData) => {
		if (!validateSeatLimits(role, size(emails), limits)) {
			return false;
		}

		const url = getEndpoints(WORKSPACE_NAMESPACE).byPath([
			workspace.id,
			'invite',
		]);

		try {
			await apiClient.post<string>(url, {
				emails,
				role,
				groups,
				teams,
				iam_role_id,
			});
			showNotification({
				title: 'Invitations sent successfully',
				message: `Email invitations were sent to the specified users`,
			});
			return true;
		} catch (error: unknown) {
			if (
				error instanceof AxiosError &&
				error.status === 422 &&
				error.response?.data?.message
			) {
				showNotification({
					title: 'Error sending invitations',
					message: error.response.data.message,
					color: 'red',
				});
				return false;
			}
			showNotification({
				title: 'Error sending invitations',
				message: 'Failed to send invitations. Please try again.',
				color: 'red',
			});
			return false;
		}
	};

	return useMutation<boolean, unknown, RequestData, unknown>({
		mutationFn,
		onSuccess() {
			queryClient.invalidateQueries({
				queryKey: invalidationKey,
			});
		},
	});
}
