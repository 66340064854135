import { showNotification } from '@mantine/notifications';
import {
	apiQueryKey,
	useGetAccessRequestCredentials,
	type DataAccessRequestOut,
} from '@repo/api-codegen';
import { Button } from '@repo/foundations';
import { useCallback } from 'react';
import { queryClient } from '../../api';
import { openModal } from '../ModalManager/events';
import { DataAccessRequestCredentialsModal } from './DataAccessRequestCredentialsModal/DataAccessRequestCredentialsModal';

export interface ViewCredentialsButtonProps {
	request: DataAccessRequestOut;
}

export function ViewCredentialsButton({ request }: ViewCredentialsButtonProps) {
	const { mutateAsync: getCredentials } = useGetAccessRequestCredentials({});

	const handleShowCredentials = useCallback(async () => {
		try {
			const credentials = await getCredentials({
				pathParams: {
					requestId: request.id,
				},
			});

			openModal({
				title: 'View credentials',
				children: <DataAccessRequestCredentialsModal request={credentials} />,
				size: 'lg',
			});
		} catch (error) {
			showNotification({
				title: 'Failed to view credentials',
				message: error ?? 'Please try again later',
				color: 'red',
			});
			queryClient.invalidateQueries({
				queryKey: apiQueryKey('integration/data-access-requests/{request_id}', {
					request_id: request.id,
				}),
			});
		}
	}, [getCredentials, request]);

	return (
		<Button leftIconName="eye" onClick={handleShowCredentials}>
			View credentials
		</Button>
	);
}
