import type { IJob } from '../../../api';
import { useJobRunsInfiniteList } from '../../../api';
import { TableV2 } from '../../../components/TableV2';
import { useColumns } from './RunTable.hooks';

interface IRunTableProps {
	job: IJob;
}

function RunTable({ job }: IRunTableProps) {
	const columns = useColumns();

	return (
		<TableV2
			pluralTypeString="runs"
			columns={columns}
			withInfiniteScroll
			usePaginationList={useJobRunsInfiniteList}
			defaultRequiredSearchParams={{
				parent_id: job.id,
				sort_by: '-run_finished_at',
			}}
			withCheckbox={false}
			withInteractiveHeader={false}
		/>
	);
}

export default RunTable;
