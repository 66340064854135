import { Stack } from '@mantine/core';
import type { IMarketplaceIntegrationSpecVersion } from '@repo/common/models/marketplace';
import { useNavigate } from 'react-router';
import { useInternalMarketplaceIntegrationSpecVersionList } from '../../api/hooks/marketplace';

import { useCallback } from 'react';
import type { FetchModelListHook } from '../../api/factories/types';
import { TableV2 } from '../../components/TableV2';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { useColumns } from './InternalMarketplaceListPage.hooks';

function InternalMarketplaceListPage() {
	const navigate = useNavigate();
	const columns = useColumns();

	const onCellClick = useCallback(
		(cell: {
			column: ExtendedDataTableColumn<IMarketplaceIntegrationSpecVersion>;
			record: IMarketplaceIntegrationSpecVersion;
		}) => {
			if (cell.column.navigate !== false) {
				navigate(
					`/internal/marketplace/review/${cell.record.spec_id}/version/${cell.record.id}`
				);
			}
		},
		[navigate]
	);

	return (
		<Stack>
			<TableV2<IMarketplaceIntegrationSpecVersion>
				onCellClick={onCellClick}
				columns={columns}
				usePaginationList={
					useInternalMarketplaceIntegrationSpecVersionList as unknown as FetchModelListHook<IMarketplaceIntegrationSpecVersion>
				}
				withInteractiveHeader={false}
			/>
		</Stack>
	);
}

export default InternalMarketplaceListPage;
