import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	spacer: {
		flexGrow: 1,
	},
	progressBar: {
		backgroundColor: theme.other.getColor('fill/brand/default'),
	},
}));
