import {
	Box,
	Group,
	Modal,
	Skeleton,
	Stack,
	Tooltip,
	useMantineTheme,
} from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useApiListIamRoles } from '@repo/api-codegen';
import { UserRole } from '@repo/common/enums/UserRole';
import { Button, Text } from '@repo/foundations';
import { useFormik } from 'formik';
import { isNil, size } from 'lodash-es';
import { useCallback } from 'react';
import * as Yup from 'yup';
import { useWorkspace } from '../../api';
import { useDefaultTeam } from '../../api/hooks/team/myMemberships';
import { useLimits } from '../../api/hooks/workspace/useLimits';
import { MultiTeamsSelector } from '../MultiTeamsSelector/MultiTeamsSelector';
import { IamRoleSelectorCore } from '../Settings/Selectors/IamRoleSelector';
import { MultiGroupSelector } from '../Settings/Selectors/MultiGroupSelector';
import RoleSelect from '../Settings/Selectors/RoleSelector';
import { MultiTextInput } from './MultiTextInput';
import { PricingDetails } from './PricingDetails';

interface IInviteMembersModalProps {
	title?: string;
	opened: boolean;
	onClose: () => void;
	onConfirm: (data: {
		emails: string[];
		role: UserRole;
		iam_role_id?: string | null;
		groups: string[];
		teams: string[];
	}) => void;
	role?: UserRole;
	hideGroupSelector?: boolean;
	hideTeamSelector?: boolean;
}

export function InviteMembersModal({
	title = 'Invite members',
	opened,
	onClose,
	onConfirm,
	role,
	hideGroupSelector = false,
	hideTeamSelector = false,
}: IInviteMembersModalProps) {
	const theme = useMantineTheme();
	const clipboard = useClipboard();

	const { usedEditorOrAdminSeats } = useLimits();
	const { workspace } = useWorkspace();
	const { defaultTeam } = useDefaultTeam();
	const teams = defaultTeam ? [defaultTeam.id] : [];

	const { data: iamRoles, isLoading: iamRolesLoading } = useApiListIamRoles({});

	const formik = useFormik({
		initialValues: {
			emails: [],
			role: role ?? UserRole.EDITOR,
			iam_role_id: iamRoles?.results?.find((r) =>
				role
					? r.name.toLowerCase() === role?.toLowerCase()
					: r.name.toLowerCase() === 'viewer'
			)?.id,
			groups: [],
			teams,
		},
		validationSchema: Yup.object({
			emails: Yup.array()
				.of(
					Yup.string().email((email) => {
						if (!email.regex.test(email.value)) {
							return email.value;
						}
						return '';
					})
				)
				.required('Required')
				.min(1, 'No emails provided'),
			role: Yup.string().when('iam_role_id', {
				is: null,
				then: (schema) => schema.required('Role is required'),
				otherwise: (schema) => schema,
			}),
			groups: Yup.array().of(Yup.string()),
			teams: Yup.array()
				.of(Yup.string())
				.required('Required')
				.min(1, 'At least one team is required'),
		}),
		validateOnMount: false,
		validateOnChange: false,
		enableReinitialize: true,
		onSubmit: async (values, formikHelpers) => {
			const iam_role_id = workspace.migrated_permission_v2
				? values.iam_role_id
				: iamRoles?.results?.find(
						(r) => r.name.toLowerCase() === values.role.toLowerCase()
					)?.id;

			onConfirm({
				emails: values.emails,
				role: values.role,
				iam_role_id,
				groups: values.groups,
				teams: values.teams,
			});
			formikHelpers.resetForm();
			onClose();
		},
		onReset: (values, formikHelpers) => {
			formikHelpers.setFieldValue('role', values.role);
			formikHelpers.setFieldValue('iam_role_id', values.iam_role_id);
		},
	});

	const isPricingDetailsHidden =
		size(formik.values.emails) === 0 || !isNil(formik.errors.emails);

	let pricingDetailsType: 'viewers' | 'editors' | 'integrations' | undefined;

	switch (formik.values.role) {
		case UserRole.VIEWER:
			pricingDetailsType = 'viewers';
			break;
		case UserRole.EDITOR:
			pricingDetailsType = 'editors';
			break;
		case UserRole.ADMIN:
			pricingDetailsType = 'editors';
			break;
		default:
			break;
	}

	const handleSetEmails = useCallback(
		(emails: string[]) => {
			formik.setFieldValue('emails', emails);
		},
		[formik]
	);

	const handleOnClose = useCallback(() => {
		formik.resetForm();
		onClose();
	}, [formik, onClose]);

	if (workspace.migrated_permission_v2 && iamRolesLoading) {
		return (
			<Modal opened={opened} onClose={handleOnClose} title={title}>
				<Stack spacing="xl">
					<Skeleton height={32} />
				</Stack>
			</Modal>
		);
	}

	return (
		<Modal opened={opened} onClose={handleOnClose} title={title}>
			<Stack spacing="xl">
				<MultiTextInput
					type="email"
					values={formik.values.emails}
					setValues={handleSetEmails}
					label="Emails"
					placeholder="Enter teammates email addresses..."
					error={formik.errors.emails}
				/>
				<Box>
					<Text size="sm" weight="semibold" mb={theme.spacing['3xs']}>
						Role
					</Text>
					{workspace.migrated_permission_v2 ? (
						<IamRoleSelectorCore
							roles={iamRoles?.results ?? []}
							value={formik.values.iam_role_id ?? ''}
							onChange={(roleId) => {
								formik.setFieldValue('iam_role_id', roleId);
							}}
							withDescriptions
						/>
					) : (
						<RoleSelect
							disabled={!!role}
							role={formik.values.role}
							selectRole={(selectedRole) => {
								formik.setFieldValue('role', selectedRole);
							}}
							withDescriptions
						/>
					)}
				</Box>
				{!hideGroupSelector && (
					<MultiGroupSelector
						label="Groups"
						value={formik.values.groups}
						setValue={(value) => formik.setFieldValue('groups', value)}
					/>
				)}
				{!hideTeamSelector && (
					<MultiTeamsSelector
						label="Teams"
						error={formik.errors.teams as string}
						value={formik.values.teams}
						setValue={(value) => formik.setFieldValue('teams', value)}
					/>
				)}
				{!isNil(pricingDetailsType) && (
					<PricingDetails
						type={pricingDetailsType}
						nominalCurrentCount={usedEditorOrAdminSeats}
						nominalChange={size(formik.values.emails)}
						hidden={isPricingDetailsHidden}
					/>
				)}
				<Group position="apart">
					<Tooltip label="Members will join as a Viewer">
						<Button
							size="md"
							variant="tertiary"
							leftIconName="link"
							onClick={() => {
								clipboard.copy(
									`${window.location.origin}?workspace_invite=${
										workspace?.id ?? ''
									}`
								);
								showNotification({
									message: 'Invitation link copied to clipboard.',
								});
							}}
						>
							Copy invite link
						</Button>
					</Tooltip>
					<Group position="right" spacing="xs">
						<Button onClick={handleOnClose}>Cancel</Button>
						<Button variant="primary" onClick={() => formik.handleSubmit()}>
							Send invites
						</Button>
					</Group>
				</Group>
			</Stack>
		</Modal>
	);
}
