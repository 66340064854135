import type {
	EntityPermissionOrMembershipOut,
	LiteUserOut,
	TeamMembershipOut,
} from '@repo/api-codegen';
import type { IEntityPermission, IIntegration, IUser } from '../api';
import { isNullOrWhiteSpace } from './stringUtils';

export function getDisplayName(user: IUser | LiteUserOut) {
	return isNullOrWhiteSpace(user.first_name as string)
		? user.email
		: `${user.first_name} ${user.last_name}`;
}

export function getTeamMembershipOrEntityPermisisonDisplayName(
	r: TeamMembershipOut | IEntityPermission | EntityPermissionOrMembershipOut
) {
	if (r.user) {
		return getDisplayName(r.user);
	}
	if ('group' in r && r.group) {
		return r.group.name;
	}
	if ('user_group' in r && r.user_group) {
		return r.user_group.name;
	}

	return '';
}

export function checkPreviewPermission(
	user: IUser,
	integration: IIntegration | undefined
) {
	if (integration?.preview_access_groups) {
		return user.user_groups.some((group) =>
			integration.preview_access_groups?.includes(group)
		);
	}
	if (integration?.preview_access_roles) {
		return integration.preview_access_roles?.includes(user.role);
	}
	return false;
}

export function getUserInitialsFromName(name?: string) {
	const names = name?.split(' ');
	let initials = names?.[0].substring(0, 1).toUpperCase();

	if (names && (names?.length ?? 0) > 1) {
		initials += names[names.length - 1].substring(0, 1).toUpperCase();
	}
	return initials;
}
