import { useMutation, useQuery } from '@tanstack/react-query';
import { convertObjectToUrlSearchParams } from '../../../lib/models';
import type { ISearchSource } from '../../../utils/analytics';
import { trackEvent } from '../../../utils/analytics';
import { apiClient, getEndpoints } from '../../common';
import { useAuthUser } from '../authUser';
import { searchQueryKeyFactory } from './constants';

interface ITrackSearchLogRequestParams {
	term: string;
	source: ISearchSource;
	resultIndex: number;
	entityID: string;
	topResults: string[];
	queryParams?: Record<string, string | number | boolean>;
	signal?: AbortSignal;
}

const SEARCH_META_NAMESPACE = ['search', 'v2', 'meta'];

function useSearchMeta() {
	const queryKey = [...SEARCH_META_NAMESPACE];

	const queryFn = async () => {
		const url = getEndpoints(SEARCH_META_NAMESPACE).byPath([]);
		const { data } = await apiClient.get(url, {});

		return data;
	};

	return useQuery({
		queryKey,
		queryFn,
	});
}

export function useTrackSearchLog() {
	const { user, workspace } = useAuthUser();
	const { data: meta } = useSearchMeta();

	const mutationFn = async ({
		term,
		source,
		resultIndex,
		entityID,
		topResults = [],
		queryParams = {},
		signal,
	}: ITrackSearchLogRequestParams) => {
		const url = getEndpoints(searchQueryKeyFactory.namespace).byPath([
			'track_search_log',
		]);

		trackEvent(
			'search/click',
			{
				search_term: term,
				search_source: source,
				search_result_index: String(resultIndex),
				search_result_entity_id: entityID,
				engine: meta?.engine,
			},
			user,
			workspace
		);

		const { data } = await apiClient.post(
			url,
			{
				term,
				entity_id: entityID,
				result_index: resultIndex,
				top_results: topResults.join(','),
				query_params: convertObjectToUrlSearchParams(queryParams).toString(),
			},
			{ signal }
		);

		return data;
	};
	return useMutation({
		mutationFn,
	});
}
