import { Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import type { GithubPullRequestOut } from '@repo/api-codegen';
import { useApiGithubPullRequests } from '@repo/api-codegen';
import { Icon } from '@repo/foundations';
import { useState } from 'react';
import type { IIntegration } from '../../../api';
import { TableV2 } from '../../TableV2';
import { useColumns } from './IntegrationPullRequests.hooks';
import { IntegrationPullRequestsDrawer } from './IntegrationPullRequestsDrawer';

interface IntegrationPullRequestsProps {
	integration: IIntegration;
}

export function IntegrationPullRequests({
	integration,
}: IntegrationPullRequestsProps) {
	const [opened, { open, close }] = useDisclosure(false);
	const [selectedPullRequestId, setSelectedPullRequestId] = useState<
		string | undefined
	>(undefined);

	const columns = useColumns();

	const handleRowClick = (row: GithubPullRequestOut) => {
		if (row.downstream_entities_count > 0) {
			setSelectedPullRequestId(row.id);
			open();
		} else {
			showNotification({
				title: 'No downstream entities found',
				message: 'This pull request has no downstream entities',
				color: 'red',
				icon: <Icon name="alertCircle" />,
			});
		}
	};

	return (
		<Stack>
			<TableV2<GithubPullRequestOut>
				columns={columns}
				onRowClick={handleRowClick}
				// @ts-ignore
				usePaginationList={useApiGithubPullRequests}
				usePaginationListOptions={{
					pathParams: {
						integrationId: integration.id,
					},
				}}
				useCodegenListInterface
				withFilters={false}
				withSearch={false}
				withInteractiveHeader={false}
			/>
			<IntegrationPullRequestsDrawer
				integrationId={integration.id}
				pullRequestId={selectedPullRequestId ?? ''}
				opened={opened && !!selectedPullRequestId}
				close={close}
			/>
		</Stack>
	);
}
