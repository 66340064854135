import { createStyles, Stack } from '@mantine/core';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useAiEnabled } from '../../../hooks/useAIEnabled';
import { AIChat } from '../AIChat';
import { ChatHistory } from '../ChatHistory';
import { AIAssistantProvider, useAIAssistantContext } from '../context';
import { AIAssistantMode } from '../types';
import { AIAssistantSidebarHeader } from './AIAssistantSidebarHeader';
import { aiSidebarSessionStore } from './sessionStore';

const useStyles = createStyles((theme) => ({
	container: {
		height: '100%',
		gap: 0,
	},
	content: {
		height: `calc(100% - ${theme.other.space[20]}px)`,
		padding: `0 ${theme.spacing.xl} ${theme.spacing.xl} ${theme.spacing.xl}`,
		gap: theme.spacing.xl,
	},
}));

const AIAssistantSidebarInternal = observer(() => {
	const { classes } = useStyles();
	const store = useAIAssistantContext();

	// sync the conversation id to the session storage to persist the
	// conversation in the sidebar whenever the user refreshes the page or
	// navigates away
	useEffect(
		() =>
			reaction(
				() => store.id,
				(sessionId) => {
					if (!!sessionId && sessionId !== 'new') {
						aiSidebarSessionStore.storeSessionId(sessionId);
					}
				}
			),
		[store]
	);

	return (
		<Stack className={classes.container}>
			<AIAssistantSidebarHeader />
			{store.isHistoryOpen ? (
				<ChatHistory />
			) : (
				<Stack className={classes.content}>
					<AIChat />
				</Stack>
			)}
		</Stack>
	);
});

// need to wrap the top component for the sidebar in the context provider + check if AI is enabled
export function AIAssistantSidebar() {
	const enableAi = useAiEnabled();

	if (!enableAi) {
		return null;
	}

	// load existing conversation id from session storage if it exists
	const sessionId = aiSidebarSessionStore.getSessionId();

	return (
		<AIAssistantProvider mode={AIAssistantMode.SIDEBAR} id={sessionId ?? 'new'}>
			<AIAssistantSidebarInternal />
		</AIAssistantProvider>
	);
}
