import type {
	SelectProps as MantineSelectProps,
	SelectItem,
} from '@mantine/core';
import {
	createStyles,
	Select as MantineSelect,
	Skeleton,
	Stack,
} from '@mantine/core';
import { useId } from '@mantine/hooks';
import { iconSize } from '@repo/theme/primitives';
import type { ColorNames } from '@repo/theme/utils';
import type { ReactNode } from 'react';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import ItemComponent from '../MultiSelect/ItemComponent';
import { TextInputHelp, TextInputLabel } from '../TextInput';
import { TextInputError } from '../TextInput/TextInputError';

type SelectSizes = 'sm' | 'md';

export interface SelectStylesParams {
	size: SelectSizes;
	error?: string;
}

const useStyles = createStyles((theme, { size, error }: SelectStylesParams) => {
	let height: number = theme.other.space[8];

	let backgroundColor: ColorNames = 'surface/input/default';
	let hoverBackgroundColor: ColorNames = 'surface/input/default';
	let focusBackgroundColor: ColorNames = 'surface/input/default';
	const disabledBackgroundColor: ColorNames = 'surface/primary/disabled';

	let borderWidth: number = 0.5;
	let hoverBorderWidth: number = 0.5;
	let focusBorderWidth: number = 0.5;
	const disabledBorderWidth: number = 0;

	let borderColor: ColorNames = 'border/input/default';
	let hoverBorderColor: ColorNames = 'border/input/hover';
	let focusBorderColor: ColorNames = 'border/input/active';

	const boxShadow = `0px 0px 0px 1px white, 0px 0px 0px 3px ${theme.other.getColor('border/emphasis/default')}`;

	if (size === 'sm') {
		height = theme.other.space[7];
	}

	if (error) {
		borderColor = 'border/critical-secondary/default';
		focusBorderColor = 'border/critical-secondary/default';
		hoverBorderColor = 'border/critical-secondary/default';

		backgroundColor = 'surface/critical/default';
		focusBackgroundColor = 'surface/critical/default';
		hoverBackgroundColor = 'surface/critical/default';
	}

	return {
		input: {
			height,
			minHeight: height,
			backgroundColor: theme.other.getColor(backgroundColor),
			borderRadius: theme.radius.sm,
			borderWidth,
			borderStyle: 'solid',
			borderColor: theme.other.getColor(borderColor),
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: theme.other.getColor(hoverBackgroundColor),
				borderWidth: hoverBorderWidth,
				borderColor: theme.other.getColor(hoverBorderColor),
			},
			'&:focus, &:active': {
				backgroundColor: theme.other.getColor(focusBackgroundColor),
				borderWidth: focusBorderWidth,
				borderColor: theme.other.getColor(focusBorderColor),
				boxShadow,
			},
			'&:disabled': {
				backgroundColor: theme.other.getColor(disabledBackgroundColor),
				borderWidth: disabledBorderWidth,
			},
			'&[data-with-icon]': {
				// have to calculate the padding of the label manually to be consistent with item icon spacing
				// 8px left pad + 20px icon size + 4px right pad
				paddingLeft:
					theme.other.space[1] + iconSize['md'] + theme.other.space[2],
			},
		},
		icon: {
			color: 'inherit',
			cursor: 'pointer',
		},
	};
});

type SelectProps<T extends SelectItem> = {
	id?: string;
	size?: SelectSizes;
	label?: string;
	help?: string | React.ReactNode;
	error?: string;
	optional?: boolean;

	// Data
	isLoading?: boolean;
	data: T[];

	// Rendering
	renderIcon?: (item: T) => ReactNode;
	renderLabel?: (item: T) => ReactNode;
	className?: string;
} & Omit<MantineSelectProps, 'size' | 'required'>;

function Select<T extends SelectItem = SelectItem>(
	{
		id,
		size = 'md',
		label,
		name,
		help,
		error,
		optional = false,
		disabled = false,

		// Data
		isLoading = false,
		data,
		value,
		defaultValue,
		onChange,

		// Rendering
		renderIcon,
		renderLabel = (item: T) => item.label,
		...others
	}: SelectProps<T>,
	ref: React.ForwardedRef<HTMLInputElement>
) {
	const uuid = useId(id);
	const { classes, theme } = useStyles({ size, error });
	const [selectedItem, setSelectedItem] = useState<T | undefined>(
		data.find((item) => item.value === defaultValue || item.value === value)
	);

	// Avoid inconsistent state when the options is not loaded yet but the value is already set
	useEffect(() => {
		setSelectedItem(data.find((item) => item.value === value));
	}, [data]);

	const handleOnChange = useCallback(
		(value: string) => {
			if (disabled) {
				return;
			}

			onChange?.(value);
			setSelectedItem(data.find((item) => item.value === value));
		},
		[data, disabled, onChange]
	);

	return (
		<Stack w="100%" spacing={theme.spacing['3xs']}>
			<TextInputLabel label={label} optional={optional} inputId={uuid} />
			{isLoading ? (
				<Skeleton height={theme.other.space[size === 'sm' ? 7 : 8]} />
			) : (
				<MantineSelect
					id={uuid}
					ref={ref}
					classNames={classes}
					name={name}
					data={data}
					value={value}
					onChange={handleOnChange}
					itemComponent={forwardRef<HTMLDivElement, T>((props, ref) => (
						<ItemComponent
							ref={ref}
							label={renderLabel(props as T)}
							icon={renderIcon?.(props as T)}
							{...props}
						/>
					))}
					icon={
						renderIcon && selectedItem ? renderIcon(selectedItem) : undefined
					}
					{...others}
				/>
			)}
			<TextInputHelp help={help} error={error} />
			<TextInputError error={error} />
		</Stack>
	);
}

Select.displayName = 'Select';

export { Select };
