import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import type { ISlackChannel } from '../../../api';
import { authHeaders, getEndpoints } from '../../common';
import { createQueryKeys } from '../../factories';
import { INTEGRATION_SLACK_NAMESPACE } from '../slack';

interface IUseSlackArgs {
	id?: string;
	options?: UseQueryOptions<ISlackChannel[]>;
	refresh?: boolean;
}

const SLACK_NAMESPACE = ['this', 'slack'];
export const slackQueryKeyFactory = createQueryKeys(SLACK_NAMESPACE);

export const slackChannelsQuery = (refresh: boolean | undefined) => ({
	queryKey: slackQueryKeyFactory.list(1, { refresh }),
	queryFn: async () => {
		const res = await axios.get<{ results: ISlackChannel[] }>(
			getEndpoints(INTEGRATION_SLACK_NAMESPACE).byPath(['conversations']),
			{
				...authHeaders(),
				params: {
					refresh,
				},
			}
		);
		return res.data.results;
	},
});

export function useSlackChannels({ options, refresh }: IUseSlackArgs = {}) {
	const query = slackChannelsQuery(refresh);
	return useQuery<ISlackChannel[]>({
		...options,
		...query,
	});
}
