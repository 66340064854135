import { Button } from '@repo/foundations';
import { useCallback } from 'react';
import type { IIntegration } from '../../api';
import { useWorkspace } from '../../api';
import type { SlackIntegrationSpec } from '../../interfaces/IntegrationSpec';
import { v4 } from '../../utils/uuid/v4';

function encodeUtf8ToHex(str: string): string {
	const encoder = new TextEncoder();
	const uint8Array = encoder.encode(str);
	return Array.from(uint8Array, (byte) =>
		byte.toString(16).padStart(2, '0')
	).join('');
}

export function SlackOAuthButton(props: {
	spec: SlackIntegrationSpec;
	integration?: IIntegration;
}) {
	const { spec, integration } = props;
	const { workspace } = useWorkspace();

	const handleConnect = useCallback(() => {
		const { oauth } = spec;

		// We pass in the callback encoded in the state parameter, so the central
		// server can redirect back to the correct page. We can't pass this in the
		// `redirect_uri` parameter, because we want to reuse the same slack app for
		// different domains, and the `redirect_uri` parameter must be whitelisted
		// in the slack app.
		const state = encodeUtf8ToHex(
			JSON.stringify({
				nonce: v4(),
				workspace_id: workspace.id,
				origin: window.location.origin,
				redirect_uri: oauth.redirect_uri,
			})
		);

		const url = new URL(oauth.base_url);
		url.searchParams.append('state', state);
		url.searchParams.append('scope', oauth.scope ?? '');
		url.searchParams.append('client_id', oauth.client_id ?? '');
		url.searchParams.append('redirect_uri', oauth.redirect_uri ?? '');
		url.searchParams.append('response_type', oauth.response_type ?? '');
		if (oauth.access_type) {
			url.searchParams.append('access_type', oauth.access_type);
		}
		window.open(url.toString(), '_self');
	}, [spec, workspace.id]);

	return (
		<Button type="button" size="md" onClick={handleConnect}>
			{integration?.credentials
				? `Re-authorize ${integration?.name ?? 'slack'}`
				: 'Connect'}
		</Button>
	);
}
