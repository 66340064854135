import { Stack } from '@mantine/core';
import { UserRole } from '@repo/common/enums/UserRole';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import type { IReport } from '../../api';
import { useReportList } from '../../api';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import { FeatureGuard } from '../../components/FeatureGuard/FeatureGuard';
import { TableV2 } from '../../components/TableV2';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { useActions, useColumns } from './AnalyticsListPage.hooks';

const COLUMN_VISIBILITY = {
	catalogServerType: 'reports',
	catalogType: 'report',
} as const;

const DEFAULT_SEARCH_PARAMS = {
	type: 'analytics',
} as const;

const QUICK_ACTIONS = ['actions::delete'] as const;

function AnalyticsListPage() {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const columns = useColumns();
	const actions = useActions();
	const { analyticsAccess } = useFeatureAccess();

	const handleClick = useCallback(
		(id: string) => {
			navigate(`/analytics/${id}`);
		},
		[navigate]
	);

	const onCellClick = useCallback(
		(cell: {
			column: ExtendedDataTableColumn<IReport>;
			record: { id: string };
		}) => {
			if (cell.column.navigate !== false) {
				handleClick(cell.record.id);
			}
		},
		[handleClick]
	);

	return (
		<FeatureGuard
			featureName="Analytics"
			description="Understand workspace usage, important KPIs, and more with analytics."
			v1AllowedRoles={[UserRole.ADMIN, UserRole.EDITOR]}
			v2Permission="Analytics.Read"
			isFeatureIncludedInPlan={analyticsAccess ?? false}
			isAlwaysIncludedFeature={false}
		>
			<Stack className={classes.wrapper} data-testid="analytics-list">
				<TableV2<IReport>
					columnVisibility={COLUMN_VISIBILITY}
					pluralTypeString="reports"
					withCheckbox={true}
					withInteractiveHeader
					withSearch
					defaultSort={null}
					withCsvExport
					columns={columns}
					withActions={actions}
					withQuickActions={QUICK_ACTIONS}
					usePaginationList={useReportList}
					defaultRequiredSearchParams={DEFAULT_SEARCH_PARAMS}
					onCellClick={onCellClick}
				/>
			</Stack>
		</FeatureGuard>
	);
}

export default AnalyticsListPage;
