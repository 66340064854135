import { Group } from '@mantine/core';
import { UserRole } from '@repo/common/enums/UserRole';
import { Button, Title } from '@repo/foundations';
import { width } from '@repo/theme/primitives';
import { observer } from 'mobx-react-lite';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import { usePolicyAccess } from '../../../api';
import AddPolicyButton from '../../../pages/PoliciesListPage/AddPolicyButton';
import PolicyTemplatesModal from '../../../pages/PoliciesListPage/PolicyTemplatesModal';
import { Guard } from '../../Guard/Guard';
import { openModal } from '../../ModalManager';

const PoliciesListPageNavBar = observer(() => {
	const hasPolicyAccess = usePolicyAccess();
	if (!hasPolicyAccess) {
		return null;
	}

	const handleOpenTemplates = () => {
		openModal({
			id: 'browse-policy-templates',
			title: 'Policy Templates',
			children: <PolicyTemplatesModal />,
			size: width.xxl,
		});
	};

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Group>
					<Title
						order={1}
						size="sm"
						data-testid="policies-page-nav-bar-title"
						pl="sm"
					>
						Policies
					</Title>
				</Group>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<Guard
					v1AllowedRoles={[UserRole.ADMIN, UserRole.EDITOR]}
					v2Permission="Policies.Create"
				>
					<Group>
						<Button onClick={handleOpenTemplates}>Browse templates</Button>
						<AddPolicyButton />
					</Group>
				</Guard>
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
});

PoliciesListPageNavBar.displayName = 'PoliciesListPageNavBar';
export default PoliciesListPageNavBar;
