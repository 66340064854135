import { Group, Loader } from '@mantine/core';
import {
	queryKeyFn,
	useGetExcludedFilters,
	useGetIncludedFilters,
	useSetExcludedFilters,
	useSetIncludedFilters,
} from '@repo/api-codegen';
import { queryClient } from '../../../api';
import { GovernanceFilters } from '../GovernanceFilters/GovernanceFilters';

interface IAIPersonasFiltersProps {
	personaId: string;
}

export function AIPersonasFilters({ personaId }: IAIPersonasFiltersProps) {
	const { data: includedFilters, isLoading: isLoadingIncluded } =
		useGetIncludedFilters({
			pathParams: {
				personaId,
			},
		});
	const { mutateAsync: updateIncludedFilters } = useSetIncludedFilters({
		onSuccess: (_, variables) => {
			// optimistic update
			queryClient.setQueryData(
				queryKeyFn({
					path: '/ai/personas/{personaId}/included_filters/',
					operationId: 'getIncludedFilters',
					variables: {
						pathParams: {
							personaId,
						},
					},
				}),
				variables.body
			);
		},
	});
	const { data: excludedFilters, isLoading: isLoadingExcluded } =
		useGetExcludedFilters({
			pathParams: {
				personaId,
			},
		});
	const { mutateAsync: updateExcludedFilters } = useSetExcludedFilters({
		onSuccess: (_, variables) => {
			// optimistic update
			queryClient.setQueryData(
				queryKeyFn({
					path: '/ai/personas/{personaId}/excluded_filters/',
					operationId: 'getExcludedFilters',
					variables: {
						pathParams: {
							personaId,
						},
					},
				}),
				variables.body
			);
		},
	});

	if (isLoadingIncluded || isLoadingExcluded) {
		return (
			<Group position="center">
				<Loader />
			</Group>
		);
	}

	return (
		<GovernanceFilters
			description="Create rules to exclude resources from the Agent. By default the Agent can access all resources in the workspace"
			includedFilters={includedFilters}
			excludedFilters={excludedFilters}
			onIncludedFiltersChange={(filters) =>
				updateIncludedFilters({ body: filters, pathParams: { personaId } })
			}
			onExcludedFiltersChange={(filters) =>
				updateExcludedFilters({ body: filters, pathParams: { personaId } })
			}
		/>
	);
}
