import { Group, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useApiMonitorNotifyDownstream } from '@repo/api-codegen';
import { Button, Text } from '@repo/foundations';
import type { Incident } from '../../../api';
import { closeAllModals } from '../../../components/ModalManager';

interface NotifyDownstreamModalProps {
	incident: Incident;
	entityIds: string[];
}

function NotifyDownstreamModal({
	incident,
	entityIds,
}: NotifyDownstreamModalProps) {
	const { mutateAsync: notifyDownstream, isLoading: notifyLoading } =
		useApiMonitorNotifyDownstream();

	const handleSendNotification = async () => {
		try {
			await notifyDownstream({
				pathParams: { monitorId: incident.monitor },
				body: { entities: entityIds, incident_id: incident.id },
			});
			showNotification({ message: 'Notification Sent' });
			closeAllModals();
		} catch (e) {
			showNotification({
				message: 'Failed to send notification',
				color: 'red',
			});
		}
	};

	return (
		<Stack spacing="md">
			<Text size="md">
				This will notify all owners and subscribers of {entityIds.length}{' '}
				downstream resources.
			</Text>

			<Group
				position="right"
				mt="md"
				sx={(theme) => ({
					borderTop: `1px solid ${theme.other.getColor('border/secondary/default')}`,
					paddingTop: theme.spacing.md,
				})}
			>
				<Button variant="default" onClick={() => closeAllModals()}>
					Cancel
				</Button>
				<Button
					type="button"
					variant="primary"
					loading={notifyLoading}
					onClick={handleSendNotification}
				>
					Send Notification
				</Button>
			</Group>
		</Stack>
	);
}

export default NotifyDownstreamModal;
