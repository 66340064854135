// Import web tracing configuration
import './web-tracing';

// Import MSAL (Microsoft Authentication Library) components
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom';

// Import styles
import './index.css';
import './styles/markdownrenderer.scss';

// Import and configure dayjs with necessary plugins
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

// Import main App component and authentication utilities
import { App } from './app';
import {
	initializeMsal,
	msalInstance,
} from './utils/authentication/azure/authConfig';

// Configure dayjs with all required plugins
// These enable various date formatting and manipulation features
dayjs.extend(customParseFormat); // Allows parsing custom date formats
dayjs.extend(duration); // Adds duration calculations
dayjs.extend(isToday); // Adds date comparison helpers
dayjs.extend(isYesterday); // Adds date comparison helpers
dayjs.extend(LocalizedFormat); // Adds localized date formatting
dayjs.extend(relativeTime); // Adds relative time formatting (e.g., "2 hours ago")
dayjs.extend(timezone); // Adds timezone support
dayjs.extend(updateLocale); // Allows updating locale settings
dayjs.extend(utc); // Adds UTC support

// Application entry point
const start = async () => {
	// Initialize Microsoft Authentication
	await initializeMsal();

	// Render the React application
	ReactDOM.render(
		<React.StrictMode>
			<MsalProvider instance={msalInstance}>
				<App />
			</MsalProvider>
		</React.StrictMode>,
		document.getElementById('root')
	);
};

// Start the application
start();
