import { createStyles, Stack, TextInput } from '@mantine/core';
import { useDebounceFn } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { RichEditor } from '../../RichEditor';
import { DISABLE_EXTENSIONS_FOR_CREATION_MODAL_EDITOR } from '../../RichEditor/RichEditor.constants';
import { ENTITY_TYPES_MODAL_CONFIG } from '../constants';
import { entityModalStore } from '../store';

const useStyles = createStyles((theme) => ({
	center: {
		backgroundColor: theme.other.getColor('fill/primary/active'),
	},
	title: {
		height: theme.spacing.sm,
		padding: 0,
	},
	input: {
		color: theme.other.getColor('text/primary/default'),
		fontSize: theme.fontSizes.xl,
		fontWeight: theme.other.typography.weight.semibold,
		lineHeight: theme.spacing.md,
		padding: `0 ${theme.spacing.xs}`,
		'&:focus': {
			outline: 'none',
			borderColor: 'transparent',
			boxShadow: 'none',
		},
	},
	content: {
		padding: `${theme.spacing.md} ${theme.spacing.xs}`,
	},
}));

function EntityModalHeader() {
	const { classes, theme } = useStyles();

	const findRelatedQuestions = useDebounceFn(
		entityModalStore.findRelatedQuestions,
		{ wait: 300 }
	);

	const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		entityModalStore.setTitle(event.currentTarget.value);
		findRelatedQuestions.run();
	};

	const handleDescriptionChange = (description?: string) => {
		entityModalStore.setDescription(description ?? '');
	};

	const config =
		entityModalStore.type && ENTITY_TYPES_MODAL_CONFIG[entityModalStore.type];

	const descriptionDefaultValue =
		entityModalStore.template?.[
			config?.richTextTemplateField ?? 'definition'
		] ??
		entityModalStore.description ??
		'';

	return (
		// We put the `key` prop here to force re-rendering of the component on
		// template change.
		<Stack key={entityModalStore.template?.id} spacing="sm">
			<TextInput
				className={classes.title}
				classNames={{
					input: classes.input,
				}}
				placeholder="Untitled"
				size="xl"
				variant="unstyled"
				// Do not use `value={entityModalStore.title}` directly here
				// because it's an observed value and will cause re-renders.
				defaultValue={
					entityModalStore.template?.title ?? entityModalStore.title ?? ''
				}
				onChange={handleTitleChange}
				data-autofocus
			/>
			<RichEditor
				className={classes.content}
				readOnly={false}
				disableExtensions={DISABLE_EXTENSIONS_FOR_CREATION_MODAL_EDITOR}
				// Do not use `value={entityModalStore.description}` directly here
				// because it's an observed value and will cause re-renders.
				initialValue={descriptionDefaultValue}
				placeholder={config?.richEditorPlaceholder}
				onChangeCallback={handleDescriptionChange}
				{...(config?.richEditorProps ?? {})}
			/>
		</Stack>
	);
}

export default observer(EntityModalHeader);
