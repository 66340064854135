import type { CustomPropertyOut } from '@repo/api-codegen';
import type {
	FilterDropdownConfigDate,
	FilterDropdownConfigList,
	FilterDropdownConfigString,
	FilterOption,
} from '@repo/common/components/Filter';
import {
	FilterDropdownType,
	FilterOperator,
	FilterOptionType,
	FilterType,
} from '@repo/common/components/Filter';
import { UserAvatar } from '@repo/common/components/UserAvatar';
import { pluralize } from '@repo/common/utils';
import { Icon } from '@repo/foundations/components/Icon';
import { startCase, toLower } from 'lodash-es';
import {
	fetchTagList,
	TAGS_NAMESPACE,
	tagsQueryKeyFactory,
} from '../../api/hooks/tag';
import { fetchUserList } from '../../api/hooks/user';
import type { ITag, IUser } from '../../api/types';
import { FILTER_OPTIONS_CONFIG } from './constants';
import {
	buildGetItemsById,
	buildGetItemsByIdFromSearch,
	fetchQuery,
} from './filterConfigUtils';

function getCustomPropertyDropdownConfig(
	customProperty: CustomPropertyOut
):
	| FilterDropdownConfigList
	| FilterDropdownConfigString
	| FilterDropdownConfigDate
	| null {
	switch (customProperty.value_type) {
		case 'select':
			return {
				searchPlaceholder: 'Search',
				multipleCountsSuffix: pluralize(toLower(customProperty.name)),
				dropdownType: FilterDropdownType.List,
				defaultOperator: FilterOperator.Is,
				hasIsNotSetOption: true,
				hasIsSetOption: true,
				getItems: async (page: number = 1, searchTerm: string = '') => {
					const getQueryKey = (filterPage: number, filterSearchTerm: string) =>
						tagsQueryKeyFactory.list(filterPage, {
							name__icontains: filterSearchTerm,
							visible: true,
							custom_property_id: customProperty.id,
						});
					const getQueryFn = (filterPage: number, filterSearchTerm: string) =>
						fetchTagList({
							filters: {
								name__icontains: filterSearchTerm,
								visible: true,
								custom_property_id: customProperty.id,
							},
						});
					const filterFn = (filterSearchTerm: string, result: ITag[]) =>
						result.filter(
							(tag) =>
								!searchTerm ||
								tag.name.toLowerCase().includes(filterSearchTerm.toLowerCase())
						);

					const results = await fetchQuery<ITag>(
						getQueryKey,
						getQueryFn,
						page,
						searchTerm,
						filterFn
					);

					return (
						results?.map((tag: ITag) => ({
							label: startCase(tag.name),
							value: tag.id,
							icon: (
								<Icon
									name="circle"
									style={{ fill: tag.color, color: tag.color, width: 12 }}
								/>
							),
						})) ?? []
					);
				},
				getItemsById: buildGetItemsById(TAGS_NAMESPACE),
			} as FilterDropdownConfigList;
		case 'tag':
			return FILTER_OPTIONS_CONFIG[FilterOptionType.TAGS]
				.filterDropdownConfig as FilterDropdownConfigList;
		case 'user':
			return {
				searchPlaceholder: 'Search users',
				multipleCountsSuffix: 'users',
				dropdownType: FilterDropdownType.List,
				defaultOperator: FilterOperator.Is,
				hasIsNotSetOption: true,
				hasIsSetOption: true,
				getItems: async (page: number = 1, searchTerm: string = '') => {
					const { results: usersResults } = await fetchUserList({
						filters: {
							disabled: false,
							is_service_account: false,
							pending: false,
						},
					});

					return (
						usersResults
							?.filter(
								(user: IUser) =>
									!searchTerm ||
									user.display_name
										?.toLowerCase()
										?.includes(searchTerm.toLowerCase()) ||
									user.email?.toLowerCase()?.includes(searchTerm.toLowerCase())
							)
							.map((user: IUser) => ({
								label: user.display_name || 'Untitled',
								value: user.id,
								metadata: 'user',
								icon: <UserAvatar key={user.id} user={user} size="xs" />,
							})) ?? []
					);
				},
				getItemsById: buildGetItemsByIdFromSearch,
			};
		case 'resource':
			return FILTER_OPTIONS_CONFIG[FilterOptionType.RELATED]
				.filterDropdownConfig as FilterDropdownConfigList;
		case 'date':
			return null;
		case 'number':
			return {
				dropdownType: FilterDropdownType.String,
				defaultOperator: FilterOperator.Is,
				hasIsNotSetOption: true,
				hasIsSetOption: true,
			} as FilterDropdownConfigString;
		case 'boolean':
			return {
				dropdownType: FilterDropdownType.List,
				defaultOperator: FilterOperator.Is,
				filterType: FilterType.Single,
				getItems: [
					{
						label: 'Checked',
						value: true,
					},
					{
						label: 'Unchecked',
						value: false,
					},
				],
			} as FilterDropdownConfigList;
		default:
			return {
				dropdownType: FilterDropdownType.String,
				defaultOperator: FilterOperator.Contains,
				inputPlaceholder: 'Enter a description',
				hasIsNotSetOption: true,
			} as FilterDropdownConfigString;
	}
}

export function getCustomPropertyAsFilter(
	customProperty: CustomPropertyOut
): FilterOption | null {
	const config = getCustomPropertyDropdownConfig(customProperty);

	if (!config) {
		return null;
	}

	return {
		field: `custom_properties.${customProperty.id}`,
		label: customProperty.name,
		type: `custom_property_${customProperty.id}` as FilterOptionType,
		filterDropdownConfig: config,
	};
}
