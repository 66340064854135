import { useQuery } from '@tanstack/react-query';
import {
	baseQueryHooksFactory,
	createQueryKeys,
	fetchAllPages,
	prefetchFunctionsFactory,
} from '../../factories';
import queryClient from '../../queryClient';
import type { ITag, ITagGroup } from '../../types';
import { filterFromListCacheByIds } from '../../utils';
import { toArray } from '../../utils/iterator';

export const TAGS_NAMESPACE = ['tag'];
export const TAG_GROUPS_NAMESPACE = ['tag_group'];

export const tagsQueryKeyFactory = createQueryKeys(TAGS_NAMESPACE);
export const tagGroupsQueryKeyFactory = createQueryKeys(TAG_GROUPS_NAMESPACE);

const { prefetchTag, prefetchTagList } = prefetchFunctionsFactory(
	'tag',
	tagsQueryKeyFactory
);

const {
	useTag,
	useTagInfiniteList,
	useTagList,
	useCreateTag,
	useDeleteTag,
	useUpdateTag,
	fetchTagList,
} = baseQueryHooksFactory<ITag, 'tag'>('tag', tagsQueryKeyFactory);

const {
	useTagGroup,
	useTagGroupList,
	useCreateTagGroup,
	useUpdateTagGroup,
	useDeleteTagGroup,
} = baseQueryHooksFactory<ITagGroup, 'tagGroup'>(
	'tagGroup',
	tagGroupsQueryKeyFactory
);

export const invalidateTagList = () => {
	queryClient.invalidateQueries({
		queryKey: tagsQueryKeyFactory.list(),
	});
	queryClient.invalidateQueries({
		queryKey: tagGroupsQueryKeyFactory.list(),
	});
};

export const filterTagsByIds = (ids: string[]) =>
	filterFromListCacheByIds<ITag>(tagsQueryKeyFactory, ids);

export {
	fetchTagList,
	prefetchTag,
	prefetchTagList,
	useCreateTag,
	useCreateTagGroup,
	useDeleteTag,
	useDeleteTagGroup,
	useTag,
	useTagGroup,
	useTagGroupList,
	useTagInfiniteList,
	useTagList,
	useUpdateTag,
	useUpdateTagGroup,
};

export const useTagListAll = () =>
	useQuery({
		queryKey: tagsQueryKeyFactory.allLists(),
		queryFn: async () => ({
			results: (await toArray(
				fetchAllPages(tagsQueryKeyFactory, {})
			)) as ITag[],
		}),
	});
