import { Container, Stack } from '@mantine/core';
import { UserRole } from '@repo/common/enums/UserRole';
import { isEmpty } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import type { IWidget, IWidgetSample } from '../../api';
import { useAuthUser, useDataQualityAccess, useWorkspace } from '../../api';
import { EducationModal } from '../../components/EducationModal/EducationModal';
import { appShellStore } from '../../components/SecodaAppShell/store';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import { useUserOnboarding } from '../../hooks/useUserOnboarding';
import { WidgetType } from '../../interfaces';
import { useCheckIAMPermission } from '../../utils/authorization/roles';
import { useFeatureFlags } from '../../utils/featureFlags';
import EditHomeScreenDrawer from './components/EditHomeScreenDrawer';
import HomepageHeader from './components/HomepageHeader';
import {
	HomepageEditorOnboarding,
	ViewerOnboarding,
} from './components/Onboarding';
import WidgetContainer, {
	EmptyWidgetContainer,
} from './components/WidgetContainer';
import {
	HOMEPAGE_ALLOWED_WIDGETS_TO_ADD,
	HOMEPAGE_MAX_WIDTH,
	HOMEPAGE_PADDING,
} from './constants';
import { useReportManagement } from './hooks/useReportManagement';
import type {
	UpdatableWidgetKeys,
	UpdatableWidgetValue,
	WidgetRemoveFn,
	WidgetUpdateFn,
} from './types';

function HomePage() {
	const { workspace } = useWorkspace();
	const enableAi = useAiEnabled();
	const { isViewerUser } = useAuthUser();
	const canShowDataQualityScore = useDataQualityAccess();
	const { darkMode } = useFeatureFlags();

	const { hasPermission: canManageHomePage } = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Analytics.Manage',
	});

	const {
		report,
		widgets,
		addWidget,
		reorderWidgets,
		updateWidget,
		removeWidget,
		updateBackgroundImage,
		isFetching,
	} = useReportManagement({ type: 'homepage' });
	const { dismissViewerOnboardingStepHomescreen } = useUserOnboarding();

	const handleAddWidget = useCallback(
		(widgetSample: IWidgetSample) => {
			addWidget(widgetSample);
			dismissViewerOnboardingStepHomescreen();
		},
		[addWidget, dismissViewerOnboardingStepHomescreen]
	);

	const handleRemoveWidget: WidgetRemoveFn = useCallback(
		(id: IWidget['id']) => () => {
			dismissViewerOnboardingStepHomescreen();
			removeWidget(id)();
		},
		[removeWidget, dismissViewerOnboardingStepHomescreen]
	);

	const handleReorderWidgets = useCallback(
		({ from, to }: { from: number; to: number }) => {
			reorderWidgets({ from, to });
			dismissViewerOnboardingStepHomescreen();
		},
		[reorderWidgets, dismissViewerOnboardingStepHomescreen]
	);

	const handleUpdateWidget: WidgetUpdateFn = useCallback(
		(id: IWidget['id'], key: UpdatableWidgetKeys) =>
			(
				value: UpdatableWidgetValue | UpdatableWidgetValue[],
				disableInvalidateReport?: boolean
			) => {
				dismissViewerOnboardingStepHomescreen();
				updateWidget(id, key)(value, disableInvalidateReport);
			},
		[updateWidget, dismissViewerOnboardingStepHomescreen]
	);

	const {
		navBarUI: {
			homePage: { drawerOpen },
		},
		homePageDrawerHandler: { open, close },
	} = appShellStore;

	const widgetSamples = [...HOMEPAGE_ALLOWED_WIDGETS_TO_ADD];
	if (canShowDataQualityScore) {
		widgetSamples.push({ type: WidgetType.DATA_QUALITY_SCORE });
	}

	return (
		<Stack>
			<HomepageHeader
				image={report?.background_image}
				fontColor={report?.header_font_color}
			/>
			<Container
				px={HOMEPAGE_PADDING}
				size={HOMEPAGE_MAX_WIDTH + 2 * HOMEPAGE_PADDING}
				miw={HOMEPAGE_MAX_WIDTH + 2 * HOMEPAGE_PADDING}
			>
				<Stack>
					{!workspace.onboarding_completed && canManageHomePage && (
						<HomepageEditorOnboarding />
					)}
					{isViewerUser && <ViewerOnboarding showAI={enableAi} />}
					{isEmpty(widgets) && !isFetching ? (
						<EmptyWidgetContainer onCustomizeButtonClick={open} />
					) : (
						<WidgetContainer
							widgets={widgets}
							onReorderWidgets={handleReorderWidgets}
							onUpdateWidget={handleUpdateWidget}
							onRemoveWidget={handleRemoveWidget}
						/>
					)}
				</Stack>
			</Container>
			<EditHomeScreenDrawer
				widgetSamples={widgetSamples}
				opened={drawerOpen}
				onClose={close}
				backgroundImage={report?.background_image}
				onAddWidget={handleAddWidget}
				onBackgroundImageChange={updateBackgroundImage}
			/>
			{darkMode && <EducationModal modalKey="education-modal-of-dark-mode" />}
		</Stack>
	);
}

export default observer(HomePage);
