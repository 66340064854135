import { useMantineTheme } from '@mantine/core';
import type {
	EntityPolicyOut,
	PolicyFramework,
	PolicyOut,
} from '@repo/api-codegen';
import MultiSelector from '@repo/common/components/MultiSelector/MultiSelector';
import { SELECTABLE_PROPERTY_OPTIONS } from '@repo/common/components/SelectableProperty/constants';
import type { IconNames } from '@repo/foundations/components/Icon/Icon';
import type { SecodaThemeShades } from '@repo/theme/types';
import { useCallback, useEffect, useState } from 'react';
import { useAuthUser } from '../../../api';
import { useUpdatePolicy } from '../hooks';

interface FrameworkSelectorProps {
	policy: PolicyOut | EntityPolicyOut;
	readOnly?: boolean;
	hideOnEmpty?: boolean;
	placeholder?: string;
	placeholderIconName?: IconNames;
}

export function FrameworkSelector({
	policy,
	readOnly = false,
	hideOnEmpty = true,
	placeholder,
	placeholderIconName,
}: FrameworkSelectorProps) {
	const { user, isViewerUser } = useAuthUser();
	const theme = useMantineTheme();
	const [selected, setSelected] = useState<PolicyFramework[]>(
		policy.frameworks ?? []
	);

	useEffect(() => {
		setSelected(policy.frameworks ?? []);
	}, [policy.frameworks]);

	const frameworks = SELECTABLE_PROPERTY_OPTIONS.complianceFramework.map(
		(item) => ({
			value: item.value,
			label: item.label,
			color: theme.other.getColor(item.color) as SecodaThemeShades, // tag type multiselectors expect tag properties which are saved with colors rather than names
		})
	);

	const { mutateAsync: updatePolicy } = useUpdatePolicy({});
	const handleChange = useCallback(
		(newFrameworks: string[]) => {
			updatePolicy({
				pathParams: {
					policyId: policy.id,
				},
				body: {
					frameworks: newFrameworks as PolicyFramework[],
				},
			});
			setSelected(newFrameworks as PolicyFramework[]);
		},
		[policy.id, updatePolicy]
	);

	return (
		<MultiSelector
			hideOnEmpty={hideOnEmpty}
			readOnly={readOnly}
			initialSelected={selected}
			placeholder={placeholder ? placeholder : 'Frameworks'}
			placeholderIconName={placeholderIconName}
			property="frameworks"
			iconType="tag"
			options={frameworks}
			permittedId={user.id}
			isViewerUser={isViewerUser}
			isMenuItemBadge={false}
			onChange={handleChange as (newValue: (string | boolean)[]) => void}
		/>
	);
}
