import type { MantineTheme } from '@mantine/core';
import type { CSSObject } from '@mantine/styles/lib/tss/types';
import { isColor, isUrl } from '../../utils/stringUtils';

export const getGreeting = () => {
	const today = new Date();
	const curHr = today.getHours();

	if (curHr < 12) {
		return 'Good morning';
	}

	if (curHr < 18) {
		return 'Good afternoon';
	}

	return 'Good evening';
};

export function getBackgroundImageStyles(
	theme: MantineTheme,
	backgroundImage?: string,
	isHomepage = false
): CSSObject {
	const backgroundImageStyles: CSSObject = {
		height: isHomepage ? 'fit-content' : '25vh',
		paddingTop: theme.spacing.sm,
		paddingRight: theme.spacing.lg,
	};

	if (!backgroundImage) {
		return {
			...backgroundImageStyles,
			height: isHomepage ? 'fit-content' : '12.5vh',
			backgroundColor: theme.other.getColor('surface/secondary/default'),
		};
	}

	if (isColor(backgroundImage)) {
		return {
			...backgroundImageStyles,
			backgroundColor: backgroundImage,
		};
	}

	if (isUrl(backgroundImage)) {
		return {
			...backgroundImageStyles,
			backgroundImage:
				theme.colorScheme === 'dark'
					? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`
					: `url(${backgroundImage})`,
			backgroundSize: 'cover',
			backgroundPosition: 'center',
		};
	}

	return backgroundImageStyles;
}

export const getWidgetCardShadow = (theme: MantineTheme) => {
	const from = theme.fn.rgba(theme.black, 0.1);
	const to = theme.fn.rgba(theme.other.getColor('fill/brand/default'), 0.2);
	return `0px 8px 16px 0px ${from} , 0px 0px 0px 1px ${to} inset`;
};
