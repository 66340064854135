import { createStyles } from '@mantine/core';

export const useMessageContentStyles = createStyles((theme) => ({
	messageBlock: {
		width: '100%',
		wordBreak: 'break-word',
		'&:not(:last-child)': {
			marginBottom: theme.spacing.md,
		},
	},
	richEditorContainer: {
		'.markdown-body': {
			color: theme.other.getColor('text/primary/default'),
			fontSize: theme.fontSizes.sm,
			fontWeight: theme.other.typography.weight.regular,
			lineHeight: 1.5,
		},
	},
	expandHeader: {
		cursor: 'pointer',
		color: theme.other.getColor('text/secondary/default'),
		padding: theme.spacing.md,
		borderRadius: theme.radius.md,
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		transition: 'all 200ms ease',
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},
	},
	expandContent: {
		opacity: 0,
		maxHeight: 0,
		overflow: 'hidden',
		transition: 'opacity 100ms ease-out, max-height 75ms ease-out',
		'&[data-expanded="true"]': {
			opacity: 1,
			maxHeight: '10000px',
			transition: 'opacity 100ms ease-in, max-height 75ms ease-in',
		},
	},
	chevron: {
		transition: 'transform 200ms ease',
		transform: 'rotate(0deg)',
		'&[data-expanded="true"]': {
			transform: 'rotate(180deg)',
		},
	},
	timelineLine: {
		width: 2,
		backgroundColor: theme.other.getColor('border/secondary/default'),
		transition: 'background-color 200ms ease',
		height: '100%',
		flex: 1,
		minHeight: theme.spacing.xs,
		marginTop: theme.spacing['3xs'],
		marginBottom: theme.spacing['3xs'],
		'&[data-active="true"]': {
			backgroundColor: theme.other.getColor('fill/brand/default'),
		},
	},
	timelineItem: {
		marginTop: theme.spacing.xs,
		'&:first-of-type': {
			marginTop: theme.spacing.md,
		},
		'&:last-of-type': {
			marginBottom: theme.spacing.md,
		},
	},
}));
