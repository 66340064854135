import { Drawer } from '@mantine/core';
import {
	useApiGithubPullRequest,
	type GithubPullRequestDownstreamEntitiesOut,
} from '@repo/api-codegen';
import { useMemo } from 'react';
import { useCatalogFilter } from '../../Filter/useCatalogFilter';
import { TableV2 } from '../../TableV2';
import { useColumns } from './IntegrationPullRequestsDrawer.hooks';

interface IntegrationPullRequestsDrawerProps {
	integrationId: string;
	pullRequestId: string;
	opened: boolean;
	close: () => void;
}

export function IntegrationPullRequestsDrawer({
	integrationId,
	pullRequestId,
	opened,
	close,
}: IntegrationPullRequestsDrawerProps) {
	const columns = useColumns();
	const filterOptions = useCatalogFilter();

	const additionalFilters = useMemo(
		() => [
			'schema',
			'database',
			'sources',
			'schema',
			'related',
			'slack_channels__contains',
		],
		[]
	);

	return (
		<Drawer
			opened={opened}
			onClose={close}
			title="Pull Request Impacted Downstream Entities"
			position="right"
			size="xl"
		>
			<TableV2<GithubPullRequestDownstreamEntitiesOut>
				pluralTypeString="resources"
				columns={columns}
				// @ts-ignore
				usePaginationList={useApiGithubPullRequest}
				usePaginationListOptions={{
					pathParams: {
						integrationId,
						pullRequestId,
					},
				}}
				additionalFilters={additionalFilters}
				useCodegenListInterface
				withFilterOptions={filterOptions}
				withCheckbox={false}
				withSearch
			/>
		</Drawer>
	);
}
