import type { ListQueryParams } from '@repo/common/interfaces/params';
import type { QueryFunctionContext, QueryKey } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { isNil, omitBy } from 'lodash-es';
import { apiClient, getEndpoints } from '../../common';
import type {
	IApiListResponse,
	IBaseModel,
	IBaseModelListArgs,
	Namespace,
} from '../../types';

export function getDefaultListQueryFn<TApiResponseData extends IBaseModel>(
	{ page = 1, filters = {}, page_size = null }: ListQueryParams,
	namespace: Namespace
) {
	const defaultQueryFn = async ({ signal }: QueryFunctionContext<QueryKey>) => {
		const url = getEndpoints(namespace).root();

		const params = omitBy({ page, page_size, ...filters }, isNil);

		const { data } = await apiClient.get<IApiListResponse<TApiResponseData>>(
			url,
			{ params, signal }
		);

		return data;
	};

	return defaultQueryFn;
}

/**
 * Hook for fetching a list of base models.
 *
 * **Usecase**: Fetching & caching a paginated list of models
 *
 * @param params Params for react-query. Page and filters are passed to the
 * default list queryFn if not provided.
 * - page => Page number for API pagination
 * - filters => Filters to apply to the API request (must match Django's model queries)
 * @returns React Query hook for pagination
 */
function useBaseModelList<
	TApiResponseData extends IBaseModel,
	TData = IApiListResponse<TApiResponseData>,
	TError = Error,
>({
	queryKey,
	namespace,
	queryFn: customQueryFn,
	page,
	filters,
	options = {},
}: IBaseModelListArgs<IApiListResponse<TApiResponseData>, TData, TError>) {
	const queryFn =
		customQueryFn ||
		getDefaultListQueryFn<TApiResponseData>({ page, filters }, namespace);

	return useQuery<IApiListResponse<TApiResponseData>, TError, TData>({
		queryKey,
		queryFn,
		keepPreviousData: true,
		...options,
	});
}

export default useBaseModelList;
