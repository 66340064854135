import { hasProperty } from 'lib0/object';
import { get } from 'lodash-es';
import type { ISecodaEntity } from '../../api';
import { isJson } from '../../utils/utils';

export function parsePropertyField(
	entity: ISecodaEntity,
	field: string
): string[] {
	const value = get(entity, field);
	if (!value) return [];

	const parsedValue = isJson(value) ? JSON.parse(value) : value;

	const arrayValue = Array.isArray(parsedValue) ? parsedValue : [parsedValue];

	return arrayValue.map((e: ISecodaEntity | string) =>
		hasProperty(e, 'id') ? (e as ISecodaEntity).id : (e as string)
	);
}
