import { SELECTABLE_PROPERTY_OPTIONS } from '@repo/common/components/SelectableProperty/constants';
import { type IQuestion, type ISecodaEntity } from '../../api';
import type { PropertySelectorProps } from './PropertySelector';
import { PropertySelector } from './PropertySelector';

export interface PriorityPropertySelectorProps
	extends Pick<PropertySelectorProps, 'onChange'> {
	entity: ISecodaEntity;
	viewerOfEntity: boolean;
}

export function PriorityPropertySelector({
	entity,
	viewerOfEntity,
	...propertySelectorProps
}: PriorityPropertySelectorProps) {
	return (
		<PropertySelector
			selected={(entity as IQuestion).priority}
			type="single"
			value="priority"
			iconType="tabler"
			isViewerUser={viewerOfEntity}
			readOnly={viewerOfEntity}
			options={SELECTABLE_PROPERTY_OPTIONS.priority}
			placeholder={viewerOfEntity ? 'No priority' : 'Add priority'}
			{...propertySelectorProps}
		/>
	);
}
