import { Box, createStyles, Group, UnstyledButton } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import type { IBaseModel } from '../../../../../api';
import type { ICommandListItem } from '../constants';
import HotKeys from './Hotkeys';

export interface ICommandPaletteItemProps {
	icon: JSX.Element | null;
	onClick?: VoidFunction;
	entity: ICommandListItem<IBaseModel>;
	showHotkey?: boolean;
	showTabSearchKey: boolean;
	selected: boolean;
}

const useStyles = createStyles(
	(theme, { selected }: Pick<ICommandPaletteItemProps, 'selected'>) => ({
		key: {
			display: 'flex',
			height: '20px',
			padding: `${theme.spacing['4xs']} ${theme.spacing['3xs']}`,
			flexDirection: 'column',
			justifyContent: 'center',
			gap: theme.spacing.xs,
			borderRadius: theme.radius.xs,
			backgroundColor: theme.other.getColor('surface/secondary/default'),
			color: theme.other.getColor('text/secondary/default'),
			fontSize: theme.fontSizes.xs,
		},

		prompt: {
			fontSize: theme.fontSizes.xs,
			color: theme.other.getColor('text/secondary/default'),
		},
		primaryIcon: {
			color: theme.other.getColor('icon/primary/default'),
		},
		text: {
			color: theme.other.getColor('text/primary/default'),
		},
		menuItem: {
			borderRadius: theme.radius.sm,
			padding: theme.spacing.xs,
			backgroundColor: selected
				? theme.other.getColor('surface/primary/selected')
				: theme.other.getColor('surface/primary/default'),

			'&:hover': {
				backgroundColor: selected
					? theme.other.getColor('surface/primary/selected')
					: theme.other.getColor('surface/primary/hover'),
			},
			'&:active': {
				backgroundColor: theme.other.getColor('surface/primary/active'),
			},
		},
		groupTitle: {
			paddingLeft: theme.spacing.xs,
			fontSize: theme.fontSizes.xs,
			color: theme.other.getColor('text/secondary/default'),
		},
	})
);

function CommandPaletteItem({
	icon,
	onClick,
	entity,
	showHotkey,
	showTabSearchKey,
	selected,
}: ICommandPaletteItemProps) {
	const { classes } = useStyles({ selected });
	const isGroupTitle = entity.type === 'group';

	if (isGroupTitle) {
		return <Text className={classes.groupTitle}>{entity.title}</Text>;
	}

	return (
		<UnstyledButton
			onClick={onClick}
			className={classes.menuItem}
			disabled={isGroupTitle}
		>
			<Group spacing="xs" noWrap position="apart">
				<Group spacing="xs" noWrap>
					{icon || <Box>{icon}</Box>}
					<Text className={classes.text} lh="20px" size="sm" lineClamp={1}>
						{entity.name}
					</Text>
				</Group>

				{showHotkey && entity.hotkey && <HotKeys hotkey={entity.hotkey} />}
				{showTabSearchKey && (
					<Group spacing="3xs" className={classes.prompt}>
						<Text className={classes.key}>Enter</Text>to jump
						<Text className={classes.key}>Tab</Text>to search
					</Group>
				)}
				{!showTabSearchKey && selected && (
					<Icon name="arrowBack" color="icon/secondary/default" />
				)}
			</Group>
		</UnstyledButton>
	);
}

export default CommandPaletteItem;
