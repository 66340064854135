import { useMount } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import {
	useAuthUser,
	usePolicyAccess,
	useSuspenseSchema,
	useUpdateSchema,
} from '../../api';
import { EntityAccessControlList } from '../../components/AccessControl/EntityAccessControlList';
import Documentation from '../../components/Documentation';
import EntityDiscussions from '../../components/EntityDiscussions';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';
import EntityPageLayoutSkeleton from '../../components/EntityPageLayout/EntityPageLayoutSkeleton';
import EntityPolicyList from '../../components/EntityPolicyList/EntityPolicyList';
import { LineageGraphWrapper } from '../../components/LineageGraph/LineageGraph';
import type { OverviewPageTab } from '../../components/Overview/OverviewPageTabs';
import OverviewPageTabs from '../../components/Overview/OverviewPageTabs';
import { trackEvent } from '../../utils/analytics';
import { useFeatureFlags } from '../../utils/featureFlags';
import { useTabNavigate } from '../../utils/hook/useTabNavigate';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import TablesTab from './components/TablesTab';

const InnerSchemaPage = observer(({ id }: { id: string }) => {
	const { tab } = useParams();
	const { user, workspace } = useAuthUser();
	const hasPolicyAccess = usePolicyAccess();
	const { entityPrivilegesTab } = useFeatureFlags();
	const navigateToTab = useTabNavigate();

	const { data: schema } = useSuspenseSchema({
		id,
	});

	useMount(() => {
		trackEvent('schema/open', {}, user, workspace);
	});

	const { mutateAsync } = useUpdateSchema({});

	// Debouncing this is not a good idea, because it overwrites changes, does not
	// queue them.
	const updateSchema = useCallback(
		async (key: string, value: DjangoValueType, saveRemotely = true) => {
			if (saveRemotely) {
				mutateAsync({
					data: {
						id: schema!.id,
						[key]: value,
					},
				});
				trackEvent('schema/properties/update', {}, user, workspace!);
			}
		},
		[mutateAsync, schema, user, workspace]
	);

	if (!schema) {
		return <EntityPageLayoutSkeleton />;
	}

	const documentationFirst = (schema.definition?.length || 0) > 2;
	const defaultTab = documentationFirst ? 'documentation' : 'tables';
	const currentTab = tab || defaultTab;

	const tabs: OverviewPageTab[] = useMemo(
		() => [
			{
				value: 'tables',
				label: 'Tables',
				component: <TablesTab schemaId={schema.id} />,
			},
			{
				value: 'lineage',
				label: 'Lineage',
				component: (
					<LineageGraphWrapper
						id={schema.id}
						entityType={schema.entity_type}
						nativeType={schema.native_type ?? ''}
						published={schema.published}
					/>
				),
			},
			{
				value: 'discussions',
				label: 'Questions',
				component: <EntityDiscussions entity={schema} />,
			},
			{
				value: 'documentation',
				label: 'Documentation',
				component: <Documentation entity={schema} />,
			},
			{
				value: 'privileges',
				label: 'Privileges',
				component: <EntityAccessControlList entity={schema} />,
				condition: entityPrivilegesTab,
			},
			{
				value: 'policies',
				label: 'Policies',
				component: <EntityPolicyList entity={schema} />,
				condition: hasPolicyAccess,
			},
		],
		[schema, hasPolicyAccess, documentationFirst]
	);

	if (documentationFirst) {
		const docTabIndex = tabs.findIndex((t) => t.value === 'documentation');
		if (docTabIndex !== -1) {
			const [docTab] = tabs.splice(docTabIndex, 1);
			tabs.unshift(docTab);
		}
	}

	return (
		<EntityPageLayout
			key={schema.id}
			name="Schema"
			isReadOnlyTitle
			entity={schema}
			icon={<EntityLogo size={32} entity={schema} />}
			updateEntity={updateSchema}
			withDescription
			withFrequentUsers
			withOwnerSelector
			withCollectionSelector
			withCustomPropertyEditors
			withTagSelector
			withEntityPopularity
			withRelatedResourceSelector
		>
			<OverviewPageTabs
				key={schema.id}
				entity={schema}
				currentTab={currentTab}
				tabs={tabs}
				onTabChange={navigateToTab}
			/>
		</EntityPageLayout>
	);
});

// by memoizing the inner component, we can avoid re-rendering the entire page on tab changes and only re-render the tab panel
export default function SchemaPage() {
	const id = useParamsIdSuffixUuid();

	return <InnerSchemaPage id={id} />;
}
