/* eslint-disable react/jsx-no-constructed-context-values */

import { Anchor } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type React from 'react';
import {
	createContext,
	useCallback,
	useEffect,
	useLayoutEffect,
	useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { api } from '../../../network';
import { trackEventAnonymous } from '../../utils/analytics';
import {
	EMAIL_VERIFICATION_LOCAL_STORAGE_KEY,
	anonymousId,
	referrer,
} from './AuthStepEmailComponent.constants';
import type { SSOTypes } from './utils';

interface IAuthStepStartContext {
	email: string;
	emailAuthLoading: boolean;
	forceSSO: SSOTypes;
	supportedMethods: (SSOTypes | 'email')[];
	resetSSO: () => void;
	setEmail: (email: string) => void;
	startEmailAuth: () => void;
	startSAMLAuth: (domain: string) => void;
	initiateAuth: () => void;

	error: string | null;
	setError: (error: string | null) => void;
}

interface IStartEmailAuthResponse {
	method: SSOTypes | 'email';
}

export const AuthStepStartContext = createContext<
	IAuthStepStartContext | undefined
>(undefined);

export interface AuthStepStartContextProviderProps {
	children: React.ReactNode;
	initialForceSSO?: SSOTypes;
}

export function AuthStepStartContextProvider({
	children,
	initialForceSSO,
}: AuthStepStartContextProviderProps) {
	const [searchParams] = useSearchParams();
	const [forceSSO, setForceSSO] = useState<SSOTypes>(initialForceSSO || null);
	const [email, setEmail] = useState<string>('');
	const [error, setError] = useState<string | null>(searchParams.get('error'));
	const [showTrialPrompt, setShowTrialPrompt] = useState(false);

	const navigate = useNavigate();

	const { data: authMethods = ['google', 'microsoft', 'saml', 'email'] } =
		useQuery({
			queryKey: ['auth-methods'],
			queryFn: async () => {
				try {
					const response = await axios.get('/auth/u/get_allowed_auth_methods/');
					return response.data.methods.split(',').filter(Boolean);
				} catch {
					// Return default methods on error
					return ['google', 'microsoft', 'saml', 'email'];
				}
			},
		});

	const resetSSO = useCallback(() => {
		setForceSSO(null);
	}, []);

	const initiateAuth = useCallback(async () => {
		// This request serves two purposes:
		// 1. It will set the CSRF cookie for the user.
		// 2. It will set the URL for the instance, used for SAML.
		await axios.post(`${api()}auth/u/url/`, {
			value: window.location.href,
		});
	}, []);

	useLayoutEffect(() => {
		initiateAuth();
	}, [initiateAuth]);

	const handleTrialPrompt = useCallback(() => {
		setShowTrialPrompt(true);
		showNotification({
			title: 'Speak to the Secoda team',
			autoClose: false,
			message: (
				<Anchor href="https://secoda.co/start-your-trial">
					Start a free trial
				</Anchor>
			),
		});
		setTimeout(() => {
			window.open('https://secoda.co/start-your-trial', '_blank');
		}, 2500);
	}, []);

	useEffect(() => {
		const searchError = searchParams.get('error');
		// Search error is a technical error and does not contain trial upgrade
		// link, so do not show upgrade prompt.
		if (searchError) {
			showNotification({
				message: searchError ?? '',
			});
		} else if (
			error?.includes('https://secoda.co/start-your-trial') &&
			!showTrialPrompt
		) {
			handleTrialPrompt();
		}
	}, [searchParams, showTrialPrompt, error, handleTrialPrompt]);

	const { isLoading, mutate: startEmailAuth } = useMutation({
		mutationFn: async () =>
			axios.post(
				`${api()}auth/u/start/`,
				{
					email,
				},
				{}
			),
		onError: (axiosError: AxiosError<{ message: string }>) => {
			if (
				axios.isAxiosError(axiosError) &&
				axiosError.response?.data?.message
			) {
				setError(axiosError.response?.data?.message);
			}
		},
		onSuccess: (response) => {
			const { method } = response.data as IStartEmailAuthResponse;
			if (method === 'email') {
				localStorage.setItem(EMAIL_VERIFICATION_LOCAL_STORAGE_KEY, email);
				trackEventAnonymous(
					'auth/email',
					{
						path: window.location.pathname,
						anonymous_id: anonymousId(),
						referrer: referrer(),
					},
					localStorage.getItem(EMAIL_VERIFICATION_LOCAL_STORAGE_KEY) ?? ''
				);

				navigate('/auth/verify');
			} else if (method) {
				setForceSSO(method);
			}
		},
	});

	const startSAMLAuth = async (domain: string) => {
		const escapeDomain = encodeURIComponent(domain).replaceAll(/\./g, '+');
		window.open(`${api()}auth/saml/${escapeDomain}/sp-initiate/`, '_self');
	};

	return (
		<AuthStepStartContext.Provider
			value={{
				forceSSO,
				supportedMethods: authMethods,
				resetSSO,
				startEmailAuth,
				emailAuthLoading: isLoading,
				email,
				setEmail,
				startSAMLAuth,
				error,
				setError,
				initiateAuth,
			}}
		>
			{children}
		</AuthStepStartContext.Provider>
	);
}
