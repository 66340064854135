import { useCollection } from '../../api';
import Documentation from '../Documentation';

export function CollectionDocumentation({
	collectionId,
}: {
	collectionId: string;
}) {
	const { data: collection } = useCollection({
		id: collectionId,
	});

	if (!collection) {
		return null;
	}

	return <Documentation entity={collection} />;
}
