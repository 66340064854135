import { useMemo } from 'react';
import type { IJobRun } from '../../../api';
import {
	DurationRender,
	LastUpdatedRender,
	ReadOnlyBadgeRender,
} from '../../../components/TableV2/render';
import type { ExtendedDataTableColumn } from '../../../components/TableV2/types';

export const useColumns = (): ExtendedDataTableColumn<IJobRun>[] => {
	const columns: ExtendedDataTableColumn<IJobRun>[] = useMemo(
		() => [
			{
				title: 'Started at',
				accessor: 'run_created_at',
				render: (record) => (
					<LastUpdatedRender<IJobRun>
						record={record}
						field={'run_created_at'}
					/>
				),
			},
			{
				title: 'Finished at',
				accessor: 'run_finished_at',
				render: (record) => (
					<LastUpdatedRender<IJobRun>
						record={record}
						field={'run_finished_at'}
					/>
				),
			},
			{
				title: 'Status',
				accessor: 'status',
				render: (record) => (
					<ReadOnlyBadgeRender
						record={record}
						field={'status'}
						options={[
							{
								option: 'error',
								color: 'text/critical/default',
								label: 'Error',
							},
							{
								option: 'success',
								color: 'text/success/default',
								label: 'Success',
							},
							{
								option: 'completed',
								color: 'text/success/default',
								label: 'Completed',
							},
							{
								option: 'succeeded',
								color: 'text/success/default',
								label: 'Success',
							}, // Glue saves success status as succeeded
							{
								option: 'running',
								color: 'text/info/default',
								label: 'Running',
							},
							{
								option: 'canceled',
								color: 'text/secondary/default',
								label: 'Cancelled',
							},
							{
								option: 'failed',
								color: 'text/critical/default',
								label: 'Error',
							},
							{
								option: 'initializing',
								color: 'text/info/default',
								label: 'Initializing',
							},
							{
								option: 'rendering',
								color: 'text/info/default',
								label: 'Rendering',
							},
							{
								option: 'unknown',
								color: 'text/secondary/default',
								label: 'Unknown',
							},
							{
								option: 'waitingToRun',
								color: 'text/secondary/default',
								label: 'Waiting to Run',
							},
						]}
						nilOption={{
							option: 'Unknown error',
							color: 'text/critical/default',
							label: 'Unknown error',
						}}
					/>
				),
			},
			{
				title: 'Duration',
				accessor: 'duration',
				render: (record) => (
					<DurationRender<IJobRun>
						record={record}
						field1={'run_created_at'}
						field2={'run_finished_at'}
					/>
				),
			},
		],
		[]
	);

	return columns;
};
