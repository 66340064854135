import { Group, Menu } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { integrationList } from '@repo/common/constants/integration/integrations.ts';
import { Breadcrumbs, Button, Icon, Title } from '@repo/foundations';
import { width } from '@repo/theme/primitives';
import { filter } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import {
	queryClient,
	useAuthUser,
	useCreateDatabuilderJob,
	useIntegration,
	useIntegrationSpec,
	useUpdateDatabuilderJob,
	useUpdateIntegration,
} from '../../../api';
import {
	forceFetchJobs,
	usePollDatabuilderJobList,
} from '../../../api/hooks/databuilderJob/usePollDatabuilderJob';
import {
	incidentsQueryKeyFactory,
	monitorsQueryKeyFactory,
} from '../../../api/hooks/monitoring/constants.ts';
import { DatabuilderJobType } from '../../../interfaces';
import { trackEvent } from '../../../utils/analytics';
import { useFeatureFlags } from '../../../utils/featureFlags';
import NewIntegrationModal from '../../Integration/AddIntegrationModal';
import { useStyles as useNewIntegrationModalStyles } from '../../Integration/AddIntegrationModal/AddIntegrationModal.styles';
import { NEW_INTEGRATION_MODAL_ID } from '../../Integration/AddIntegrationModal/constants';
import { IntegrationToggle } from '../../Integration/IntegrationToggle.tsx';
import { openModal } from '../../ModalManager';

interface SyncButtonProps {
	isPolling: boolean;
	integrationSpec: {
		value: {
			disablePullMetadata?: boolean;
			supportPushMetadata?: boolean;
		};
	} | null;
	onCancelSync: () => void;
	onPullSync: () => void;
	onPushSync: () => void;
}

function SyncButton({
	isPolling,
	integrationSpec,
	onCancelSync,
	onPullSync,
	onPushSync,
}: SyncButtonProps) {
	// Check if only one sync option is available (no menu needed)
	const showPullMetadata = !integrationSpec?.value.disablePullMetadata;
	const showPushMetadata = integrationSpec?.value.supportPushMetadata;
	const onlyPullAvailable = showPullMetadata && !showPushMetadata;
	const shouldUseDirectButton = !isPolling && onlyPullAvailable;

	if (shouldUseDirectButton) {
		return (
			<Button variant="primary" leftIconName="arrowDown" onClick={onPullSync}>
				Pull metadata
			</Button>
		);
	}

	// Otherwise use the menu
	return (
		<Menu>
			<Menu.Target>
				<Button
					variant="primary"
					leftIconName={isPolling ? 'refresh' : undefined}
					leftIconAnimation="spinCounterClockwise"
					rightIconName="chevronDown"
				>
					{isPolling ? 'Syncing' : 'Run sync'}
				</Button>
			</Menu.Target>
			<Menu.Dropdown>
				{isPolling && (
					<Menu.Item icon={<Icon name="playerStop" />} onClick={onCancelSync}>
						Cancel sync
					</Menu.Item>
				)}
				{!isPolling && showPullMetadata && (
					<Menu.Item icon={<Icon name="arrowDown" />} onClick={onPullSync}>
						Pull metadata
					</Menu.Item>
				)}
				{!isPolling && showPushMetadata && (
					<Menu.Item icon={<Icon name="arrowUp" />} onClick={onPushSync}>
						Push metadata
					</Menu.Item>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}

function IntegrationPageNavBar() {
	const { classes: newIntegrationModalClasses } =
		useNewIntegrationModalStyles();
	const { nativeSnowflake } = useFeatureFlags();

	const location = useLocation();

	const { isEditorOrAdminUser, user, workspace } = useAuthUser();
	const { id } = useParams();

	const { data: integration } = useIntegration({
		id: id!,
		options: {
			enabled: !!id,
		},
	});
	const { mutateAsync: updateIntegration } = useUpdateIntegration({});
	const integrationSpec = useIntegrationSpec(id || '');

	const { mutate: runSync } = useCreateDatabuilderJob({
		options: {
			onSuccess: forceFetchJobs,
			onError: () => {
				showNotification({
					title: 'Error running sync',
					message: 'Please try again',
					color: 'red',
				});
			},
		},
	});
	const { mutate: updateJob } = useUpdateDatabuilderJob({
		options: {
			onSuccess: forceFetchJobs,
		},
	});
	const { polling: pullPolling, databuilderJobs: pullJobs } =
		usePollDatabuilderJobList({
			integrationId: id || '',
			type: 'PULL',
			enabled: Boolean(id),
		});

	const { polling: pushPolling, databuilderJobs: pushJobs } =
		usePollDatabuilderJobList({
			integrationId: id || '',
			type: 'PUSH',
			enabled: Boolean(id),
		});

	const isIntegrationListPage = location.pathname === '/integrations';

	const handleNavigate = useCallback(() => {
		openModal({
			modalId: NEW_INTEGRATION_MODAL_ID,
			title: <Title size="md">Connect Integration</Title>,
			children: (
				<NewIntegrationModal
					integrationTypes={integrationList
						.filter((i) => !i.isExtension)
						.filter((i) => {
							if (i.type === 'native_snowflake') {
								return nativeSnowflake;
							}
							return true;
						})
						.map((i) => i.type)}
				/>
			),
			size: width.xxl,
			radius: 'lg',
			classNames: {
				header: newIntegrationModalClasses.header,
				body: newIntegrationModalClasses.body,
			},
		});
	}, [newIntegrationModalClasses, nativeSnowflake]);

	const toggleEnabled = useCallback(
		(enabled: boolean) => {
			updateIntegration({
				data: {
					id: integration!.id,
					enabled,
				},
			});
		},
		[integration, updateIntegration]
	);

	const handlePullSync = useCallback(async () => {
		if (integration && !pullPolling) {
			await runSync({
				data: {
					integration_id: id,
					type: DatabuilderJobType.METADATA,
				},
			});
			trackEvent('integrations/run_extraction/click', {}, user, workspace!);
			// ENG-10149 Invalidate the queries for monitors and incidents
			await queryClient.invalidateQueries({
				queryKey: monitorsQueryKeyFactory.allLists(),
			});
			await queryClient.invalidateQueries({
				queryKey: incidentsQueryKeyFactory.allLists(),
			});
		}
	}, [integration, id, pullPolling, runSync, user, workspace]);

	const handlePushSync = useCallback(async () => {
		if (integration && !pushPolling) {
			await runSync({
				data: {
					integration_id: id,
					type: DatabuilderJobType.PUSH,
				},
			});
			trackEvent(
				'integration/sync/push',
				{
					integration_type: integration.type,
				},
				user,
				workspace!
			);
		}
	}, [integration, id, pushPolling, runSync, user, workspace]);

	const handleCancelSync = useCallback(() => {
		const idsToCancel = filter(
			[...(pullJobs ?? []), ...(pushJobs ?? [])],
			(job) =>
				!job.failed && !job.completed && !job.terminated && !job.timed_out
		).map((job) => job.id);

		idsToCancel.forEach((id) => {
			updateJob({
				data: {
					id: id,
					terminated: true,
				},
			});
		});
	}, [pullJobs, pushJobs, updateJob]);

	const isPolling = pullPolling || pushPolling;

	const breadcrumbs = useMemo(() => {
		if (!integration) {
			return [{ title: 'Integrations', href: '/integrations' }];
		}

		if (integrationList.find((i) => i.type === integration.type)?.isExtension) {
			return [
				{ title: 'Extensions', href: '/settings/extensions' },
				{ title: integration.name, href: `/extensions/${integration.id}` },
			];
		}

		return [
			{ title: 'Integrations', href: '/integrations' },
			{ title: integration.name, href: `/integrations/${integration.id}` },
		];
	}, [integration]);

	if (isIntegrationListPage) {
		return (
			<NavBarWrapper>
				<NavBarBreadcrumbWrapper>
					<Title
						order={1}
						size="sm"
						data-testid="integrations-page-nav-bar-title"
					>
						{integrationList.find((i) => i.type === integration?.type)
							?.isExtension
							? 'Extensions'
							: 'Integrations'}
					</Title>
				</NavBarBreadcrumbWrapper>
				<NavBarRightSideWrapper>
					{isEditorOrAdminUser && (
						<Button
							variant="primary"
							onClick={handleNavigate}
							data-testid="new-integrations-button"
						>
							Connect integration
						</Button>
					)}
				</NavBarRightSideWrapper>
			</NavBarWrapper>
		);
	}

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Breadcrumbs crumbs={breadcrumbs} />
			</NavBarBreadcrumbWrapper>
			{integration &&
				!['slack', 'ms_teams', 'pagerduty'].includes(integration.type) && (
					<NavBarRightSideWrapper>
						<Group spacing="sm">
							{integration && (
								<IntegrationToggle
									enabled={integration.enabled}
									onChange={toggleEnabled}
								/>
							)}
							<SyncButton
								isPolling={isPolling}
								integrationSpec={integrationSpec}
								onCancelSync={handleCancelSync}
								onPullSync={handlePullSync}
								onPushSync={handlePushSync}
							/>
						</Group>
					</NavBarRightSideWrapper>
				)}
		</NavBarWrapper>
	);
}

export default IntegrationPageNavBar;
