import { Text } from '@repo/foundations';
import { useIncident } from '../../../api';
import { IncidentStatusBadge } from '../../MonitorPage/components/IncidentStatusBadge';

function IncidentStatusRender({
	incidentId,
}: {
	incidentId: string | null;
}): JSX.Element {
	const { data: incident } = useIncident({
		id: incidentId ?? '',
		options: {
			enabled: Boolean(incidentId),
		},
	});

	return incident?.status ? (
		<IncidentStatusBadge incidentStatus={incident.status} />
	) : (
		<Text size="sm" color="text/primary/default" truncate>
			{' '}
		</Text>
	);
}

export default IncidentStatusRender;
