import { Checkbox } from '@mantine/core';
import type { DateValue } from '@mantine/dates';
import {
	useUpdateEntityCustomProperty,
	type CustomPropertyOut,
} from '@repo/api-codegen';
import { useCallback } from 'react';
import type { ISecodaEntity } from '../api';
import { ResourceCustomPropertySelector } from './Properties/ResourceCustomPropertySelector';
import {
	DatePickerRender,
	DescriptionRender,
	NumberRender,
	OwnerRender,
	TagRender,
} from './TableV2/render';

interface CustomPropertyRendererV2Props {
	customProperty: CustomPropertyOut;
	entity: ISecodaEntity;
	hideOnEmpty?: boolean;
}

export function CustomPropertyRendererV2({
	customProperty,
	entity,
	hideOnEmpty = true,
}: CustomPropertyRendererV2Props) {
	const { mutateAsync: updateEntityCustomProperty } =
		useUpdateEntityCustomProperty({});

	const onChange = useCallback(
		(entityId: string) =>
			(value: string | string[] | boolean | undefined | number | DateValue) => {
				updateEntityCustomProperty({
					pathParams: {
						customPropertyId: customProperty.id,
						entityId: entityId,
					},
					body: {
						value: typeof value === 'string' ? value : JSON.stringify(value),
					},
				});
			},
		[updateEntityCustomProperty, customProperty.id]
	);

	switch (customProperty.value_type) {
		case 'select':
			return (
				<TagRender
					key={customProperty.id}
					label={customProperty.name}
					record={entity}
					field={`properties2.${customProperty.id}`}
					onChange={onChange}
					hideOnEmpty={hideOnEmpty}
					customPropertyIdFilter={customProperty.id}
				/>
			);
		case 'tag':
			return (
				<TagRender
					key={customProperty.id}
					label={customProperty.name}
					record={entity}
					field={`properties2.${customProperty.id}`}
					onChange={onChange}
					hideOnEmpty={hideOnEmpty}
				/>
			);
		case 'user':
			return (
				<OwnerRender
					key={customProperty.id}
					record={entity}
					onChangeUserOwners={onChange}
					label={customProperty.name}
					field={`properties2.${customProperty.id}`}
					hideOnEmpty={hideOnEmpty}
				/>
			);
		case 'resource':
			return (
				<ResourceCustomPropertySelector
					entity={entity}
					customProperty={customProperty}
					field={`properties2.${customProperty.id}`}
					onChange={onChange}
				/>
			);
		case 'date':
			return (
				<DatePickerRender
					label={customProperty.name}
					record={entity}
					field={`properties2.${customProperty.id}`}
					onChange={onChange}
				/>
			);

		case 'number':
			return (
				<NumberRender
					label={customProperty.name}
					record={entity}
					field={`properties2.${customProperty.id}`}
					onChange={onChange}
				/>
			);

		case 'boolean':
			return (
				<Checkbox
					key={customProperty.id}
					defaultChecked={entity?.properties2?.[customProperty.id] === 'true'}
					onChange={(event) => {
						onChange(entity.id)(Boolean(event.target.checked));
					}}
				/>
			);
		default:
			return (
				<DescriptionRender
					key={customProperty.id}
					record={entity}
					label={customProperty.name}
					field={`properties2.${customProperty.id}`}
					onChange={onChange}
				/>
			);
	}
}
