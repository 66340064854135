import { Box, Group, Stack, Tooltip, createStyles } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { KeyboardShortcut } from '@repo/common/components/KeyboardShortcut/KeyboardShortcut';
import { trimMarkdown } from '@repo/common/utils';
import { IconButton } from '@repo/foundations';
import { useQueryClient } from '@tanstack/react-query';
import { useKeyPress } from 'ahooks';
import { useCallback, useState } from 'react';
import {
	questionReplyQueryKeyFactory,
	useAuthUser,
	useCreateQuestionReply,
	useWorkspace,
} from '../../../api';
import { RichEditor } from '../../../components/RichEditor';
import { UserAvatar } from '../../../components/UserAvatar';
import { trackEvent } from '../../../utils/analytics';
import { useForceReRender } from '../../../utils/hook/useForceReRender';
import { getModKey } from '../../../utils/keyboard';
import {
	getTTLCacheItem,
	invalidateTTLCacheItem,
	setTTLCacheItem,
} from '../utils/utils';
import { QUESTION_REPLY_LOCAL_STORAGE_KEY } from './constants';
import { useStyles } from './styles';

const useLocalStyles = createStyles((theme) => ({
	actionIcon: {
		backgroundColor: theme.other.getColor('fill/brand/default'),
		color: theme.other.getColor('text/brand-on-fill/default'),
		position: 'absolute',
		right: `calc(${theme.spacing.xs} * 1.5)`,
		bottom: theme.spacing.xs,
		'&[data-disabled]': {
			backgroundColor: theme.other.getColor('fill/brand/disabled'),
			color: theme.other.getColor('text/brand-on-fill/disabled'),
		},

		'&:hover, &:focus': {
			backgroundColor: theme.other.getColor('fill/brand/hover'),
			color: theme.other.getColor('text/brand-on-fill/hover'),
		},

		'&:active': {
			backgroundColor: theme.other.getColor('fill/brand/active'),
			color: theme.other.getColor('text/brand-on-fill/active'),
		},
	},
}));

export interface IQuestionNewReplyProps {
	questionId: string;
}

export function QuestionNewReply({ questionId }: IQuestionNewReplyProps) {
	const queryClient = useQueryClient();
	const { classes: localClasses, cx } = useLocalStyles();
	const { classes } = useStyles({ accepted: false, shouldSetFullWidth: true });
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();

	const modKey = getModKey();
	const cacheKey = `${QUESTION_REPLY_LOCAL_STORAGE_KEY}-${questionId}`;
	const initialValue = getTTLCacheItem(cacheKey) || '';

	const [definition, setDefinition] = useState(initialValue);

	const [loading, { open: startLoading, close: stopLoading }] =
		useDisclosure(false);

	const { key, forceUpdate } = useForceReRender();
	const { mutateAsync: createQuestionReply } = useCreateQuestionReply({
		options: {
			onMutate: () => {
				startLoading();
			},
			onSuccess: async () => {
				setDefinition('');
				invalidateTTLCacheItem(cacheKey);
				queryClient.invalidateQueries({
					queryKey: questionReplyQueryKeyFactory.list(1, {
						question_id: questionId,
					}),
				});

				// Due to the nested nature of the question replies,
				// there isn't a great way to determine when the parent reply
				// has been updated. Instead, we just wait for a short period
				// of time before stopping the loading state.
				setTimeout(() => {
					stopLoading();
					forceUpdate();
				}, 150);

				trackEvent(
					'question/reply/create',
					{
						id: questionId,
					},
					user,
					workspace
				);
			},
			onError: () => {
				showNotification({
					title: 'Error',
					message: 'Failed to create reply',
					color: 'red',
				});
			},
		},
	});

	const onSubmit = useCallback(async () => {
		if (!definition) return;
		if (definition && trimMarkdown(definition).length > 2) {
			createQuestionReply({
				data: {
					parent: questionId,
					definition,
					owners: [user.id],
				},
			});
		}
	}, [createQuestionReply, definition, questionId, user.id]);

	useKeyPress(['meta.Enter'], onSubmit);

	const handleChangeCallback = useCallback(
		(value: string | undefined) => {
			setDefinition(value ?? '');
			if (value) {
				// Cache the value in local storage so that it can be restored if the
				// user navigates away from the page.
				setTTLCacheItem(cacheKey, value, 1000 * 60 * 60 * 24 * 7);
			}
		},
		[cacheKey]
	);

	const valid = definition && trimMarkdown(definition).length > 2;

	return (
		<Group pos="relative" align="flex-start" noWrap>
			<Box miw={32} mt="xs">
				<UserAvatar user={user} size="md" />
			</Box>
			<Group className={cx(classes.editorWrapper)}>
				<Stack
					className={cx(classes.editorWhileEditing, classes.editorMaxWidth)}
				>
					<RichEditor
						dataTestId="new-reply-editor"
						key={key}
						placeholder="Add a reply..."
						readOnly={loading}
						onChangeCallback={handleChangeCallback}
						initialValue={initialValue}
						disableTopGap
					/>
					<Tooltip
						label={
							<KeyboardShortcut keys={[modKey, 'Enter']} variant="dark">
								Send reply
							</KeyboardShortcut>
						}
					>
						<IconButton
							iconName="arrowUp"
							data-testid="new-reply-submit"
							disabled={!valid || loading}
							loading={loading}
							size="sm"
							onClick={onSubmit}
							className={localClasses.actionIcon}
						/>
					</Tooltip>
				</Stack>
			</Group>
		</Group>
	);
}
