import { useApiGetSupportedMonitors } from '@repo/api-codegen';
import { Banner } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router';
import { closeModal } from '../../ModalManager';
import WidgetSelector from '../../WidgetSelector';
import type { WidgetData } from '../../WidgetSelector/types';
import { MONITOR_SPECS } from '../monitors';
import { MonitorSpecCard } from '../MonitorSpecCard/MonitorSpecCard';
import type { MonitorSpec, MonitorType } from '../types';
import { equalMonitorTypes } from '../types';
import { useStyles } from './AddMonitorModal.styles.ts';
import { ADD_MONITOR_MODAL_ID } from './constants';

interface AddMonitorModalProps {
	initialTable?: string;
	initialIntegration?: string;
}

function AddMonitorModal({
	initialTable,
	initialIntegration,
}: AddMonitorModalProps) {
	const { theme, classes } = useStyles();
	const navigate = useNavigate();
	const location = useLocation();

	const { data: supportedMonitorTypes, isLoading } = useApiGetSupportedMonitors(
		{
			queryParams: initialIntegration
				? {
						integration_id: initialIntegration,
					}
				: undefined,
		}
	);

	const data: WidgetData<MonitorSpec>[] = MONITOR_SPECS.filter(
		(spec) =>
			isLoading ||
			supportedMonitorTypes?.some((type) =>
				equalMonitorTypes(type as MonitorType, spec.type)
			)
	).map((spec) => ({
		data: spec,
		title: spec.title,
		description: spec.description,
		group: spec.group,
	}));

	const handleMonitorSelect = async (widgetData: WidgetData<MonitorSpec>) => {
		const params: Record<string, string> = widgetData.data.type;
		if (initialTable) {
			params['initialTable'] = initialTable;
		}
		if (initialIntegration) {
			params['initialIntegration'] = initialIntegration;
		}
		navigate(
			`/monitors/new?${new URLSearchParams(params).toString()}`,
			// Store the current url path so that AddMonitorPage knows where to return back to
			{ state: { prev_url_path: location.pathname } }
		);
		closeModal(ADD_MONITOR_MODAL_ID);
	};

	const showEmptyBanner = !isLoading && isEmpty(supportedMonitorTypes);

	return (
		<>
			{showEmptyBanner && (
				<Banner
					tone="warning"
					message="No integrations that support monitoring are connected. Please connect to a supported integration before creating monitors."
					inCard
				/>
			)}
			<WidgetSelector
				data={data}
				type="filter"
				onWidgetSelect={handleMonitorSelect}
				widgetGroupProps={{
					classNames: {
						widgetItemsWrapper: classes.widgetItemsWrapper,
						widgetWrapper: classes.widgetWrapper,
					},
				}}
				scrollAreaHeight={theme.other.space[160]}
				cols={3}
				isLoading={isLoading}
				renderer={MonitorSpecCard}
			/>
		</>
	);
}

export default AddMonitorModal;
