import type { MantineThemeOverride } from '@mantine/core';
import { modifyOpacityOfRgbaColor } from '../../utils';

export const Menu: MantineThemeOverride['components'] = {
	Menu: {
		styles: (theme) => ({
			dropdown: {
				minWidth: theme.other.space[60],
				borderRadius: theme.other.borderRadius.md,
				boxShadow: `0px 0px 0px 1px ${theme.other.getColor('shadow/shadow-border')},
   0px 1px 4px 0px ${modifyOpacityOfRgbaColor(theme.other.getColor('shadow/button-shadow'), 0.08)},
   0px 4px 8px 0px ${modifyOpacityOfRgbaColor(theme.other.getColor('shadow/button-shadow'), 0.08)}`,
				border: 'none',
				backgroundColor: theme.other.getColor('surface/primary/default'),
				paddingLeft: theme.spacing['2xs'],
				paddingRight: theme.spacing['2xs'],

				'@media print': {
					display: 'none',
				},
			},
			item: {
				padding: theme.spacing['2xs'],
				'&:hover': {
					backgroundColor: theme.other.getColor('fill/primary/hover'),
				},
				'&:active': {
					backgroundColor: theme.other.getColor('fill/primary/active'),
				},
			},
			label: {
				color: theme.other.getColor('text/secondary/default'),
			},
			itemLabel: {
				color: theme.other.getColor('text/primary/default'),
				fontSize: theme.other.typography.text.sm,
				lineHeight: theme.other.typography.lineHeight.text.sm,
			},
			itemIcon: {
				height: theme.other.space[5],
				width: theme.other.space[5],
				color: theme.other.getColor('icon/primary/default'),
			},
		}),
	},
};
