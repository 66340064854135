import { Box, createStyles, type Sx } from '@mantine/core';
import type {
	ChangeEventHandler,
	CSSProperties,
	MouseEventHandler,
	ReactNode,
} from 'react';
import { memo } from 'react';
import { DataTableRowCellWrapper } from './DataTableRowCell';
import DataTableRowExpansion from './DataTableRowExpansion';
import DataTableRowSelectorCell from './DataTableRowSelectorCell';
import type { useRowExpansion } from './hooks';
import type {
	DataTableCellClickHandler,
	DataTableColumn,
	DataTableDefaultColumnProps,
} from './types';
import { getRecordId } from './utils';

const useStyles = createStyles((theme) => {
	const baseColor = theme.colors[theme.primaryColor][6];
	return {
		withPointerCursor: {
			cursor: 'pointer',
		},
		selected: {
			'&&': {
				'tr&': {
					background:
						theme.colorScheme === 'dark'
							? theme.fn.darken(baseColor, 0.6)
							: theme.fn.lighten(baseColor, 0.9),
				},
				'table[data-striped] tbody &:nth-of-type(odd)': {
					background:
						theme.colorScheme === 'dark'
							? theme.fn.darken(baseColor, 0.55)
							: theme.fn.lighten(baseColor, 0.85),
				},
			},
		},
		contextMenuVisible: {
			'&&': {
				'tr&': {
					background:
						theme.colorScheme === 'dark'
							? theme.fn.darken(baseColor, 0.5)
							: theme.fn.lighten(baseColor, 0.7),
				},
				'table[data-striped] tbody &:nth-of-type(odd)': {
					background:
						theme.colorScheme === 'dark'
							? theme.fn.darken(baseColor, 0.45)
							: theme.fn.lighten(baseColor, 0.65),
				},
			},
		},
	};
});

type DataTableRowProps<T> = {
	record: T;
	recordIndex: number;
	columns: DataTableColumn<T>[];
	defaultColumnProps: DataTableDefaultColumnProps<T> | undefined;
	defaultColumnRender:
		| ((record: T, index: number, accessor: string) => ReactNode)
		| undefined;
	selectionVisible: boolean;
	selectionChecked: boolean;
	onSelectionChange: ChangeEventHandler<HTMLInputElement> | undefined;
	isRecordSelectable: ((record: T, index: number) => boolean) | undefined;
	getSelectionCheckboxProps: (
		record: T,
		recordIndex: number
	) => Record<string, unknown>;
	onClick: MouseEventHandler<HTMLTableRowElement> | undefined;
	onCellClick: DataTableCellClickHandler<T> | undefined;
	onContextMenu: MouseEventHandler<HTMLTableRowElement> | undefined;
	expansion: ReturnType<typeof useRowExpansion<T>>;
	customAttributes?: (
		record: T,
		recordIndex: number
	) => Record<string, unknown>;
	className?: string | ((record: T, recordIndex: number) => string | undefined);
	style?:
		| CSSProperties
		| ((record: T, recordIndex: number) => CSSProperties | undefined);
	sx?: Sx;
	contextMenuVisible: boolean;
	leftShadowVisible: boolean;
	virtuosoRowStyle?: CSSProperties;
	virtuosoRowProps?: Record<string, unknown>;
};

function DataTableRowComponent<T>({
	record,
	recordIndex,
	columns,
	defaultColumnProps,
	defaultColumnRender,
	selectionVisible,
	selectionChecked,
	onSelectionChange,
	isRecordSelectable,
	getSelectionCheckboxProps,
	onClick,
	onCellClick,
	onContextMenu,
	expansion,
	customAttributes,
	className,
	style,
	sx,
	contextMenuVisible,
	leftShadowVisible,
	virtuosoRowStyle,
	virtuosoRowProps,
}: DataTableRowProps<T>) {
	const { cx, classes } = useStyles();

	let mergedStyle =
		typeof style === 'function' ? style(record, recordIndex) : style || {};

	if (virtuosoRowStyle) {
		mergedStyle = { ...mergedStyle, ...virtuosoRowStyle };
	}

	return (
		<>
			<Box
				component="tr"
				className={cx(
					{
						[classes.withPointerCursor]: onClick || expansion?.expandOnClick,
						[classes.selected]: selectionChecked,
						[classes.contextMenuVisible]: contextMenuVisible,
					},
					typeof className === 'function'
						? className(record, recordIndex)
						: className
				)}
				onClick={(e) => {
					if (expansion) {
						const { isRowExpanded, expandOnClick, expandRow, collapseRow } =
							expansion;
						if (expandOnClick) {
							if (isRowExpanded(record)) {
								collapseRow(record);
							} else {
								expandRow(record);
							}
						}
					}
					onClick?.(e);
				}}
				onMouseEnter={() => {
					document.body.setAttribute(
						'table-hovered-data-id',
						getRecordId(record, 'id') as string
					);
				}}
				onMouseLeave={() => {
					document.body.removeAttribute('table-hovered-data-id');
				}}
				style={mergedStyle}
				sx={sx}
				{...customAttributes?.(record, recordIndex)}
				onContextMenu={onContextMenu}
				{...virtuosoRowProps}
			>
				{selectionVisible && (
					<DataTableRowSelectorCell<T>
						record={record}
						recordIndex={recordIndex}
						withRightShadow={leftShadowVisible}
						checked={selectionChecked}
						disabled={
							!onSelectionChange ||
							(isRecordSelectable
								? !isRecordSelectable(record, recordIndex)
								: false)
						}
						onChange={onSelectionChange}
						getCheckboxProps={getSelectionCheckboxProps}
					/>
				)}
				{columns.map((columnProps, columnIndex) => (
					<DataTableRowCellWrapper<T>
						key={columnProps.accessor}
						columnProps={columnProps}
						record={record}
						recordIndex={recordIndex}
						defaultColumnProps={defaultColumnProps}
						defaultColumnRender={defaultColumnRender}
						onCellClick={onCellClick}
						columnIndex={columnIndex}
					/>
				))}
			</Box>
			{expansion && (
				<DataTableRowExpansion
					colSpan={
						columns.filter(({ hidden }) => !hidden).length +
						(selectionVisible ? 1 : 0)
					}
					open={expansion.isRowExpanded(record)}
					content={expansion.content(record, recordIndex)}
					collapseProps={expansion.collapseProps}
				/>
			)}
		</>
	);
}

export const DataTableRow = memo(
	DataTableRowComponent
) as typeof DataTableRowComponent;
export default DataTableRow;
