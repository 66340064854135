import { Button } from '@repo/foundations';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import type { IUser, IWorkspace } from '../../api';
import { useUserOnboarding } from '../../hooks/useUserOnboarding';
import { trackEvent } from '../../utils/analytics';

interface OnboardingViewerButtonsProps {
	step: number;
	setStep: Dispatch<SetStateAction<number>>;
	finalStep: number;
	user: IUser;
	workspace: IWorkspace;
}

export default function OnboardingViewerButtons({
	step,
	setStep,
	finalStep,
	user,
	workspace,
}: OnboardingViewerButtonsProps) {
	const navigate = useNavigate();
	const { dismissViewerWelcomePage } = useUserOnboarding();

	const markAsCompleteAndNavigateAway = useCallback(() => {
		const eventName =
			step === finalStep
				? 'viewer_onboarding/post_login_education_page_chrome_extension_skip/click'
				: 'viewer_onboarding/post_login_education_page_skip/click';
		trackEvent(eventName, {}, user, workspace);
		dismissViewerWelcomePage();
		navigate('/');
	}, [navigate, dismissViewerWelcomePage, user, workspace, step, finalStep]);

	const nextStep = useCallback(() => {
		if (step === 3 && finalStep === 3) {
			trackEvent(
				'viewer_onboarding/post_login_education_page_chrome_extension/click',
				{},
				user,
				workspace
			);
			dismissViewerWelcomePage();
			navigate('/');
			window.open(
				'https://chromewebstore.google.com/search/Secoda?hl=en-US&utm_source=ext_sidebar',
				'_blank'
			);
		} else if (step === 2) {
			// Might not be the final continue but tracking this as final step to not change the meaning
			// of the event. If logic was to trackEvent when step === finalStep, then the number could be reduced
			// after chrome extension goes live and users don't want to add the chrome extension
			trackEvent(
				'viewer_onboarding/post_login_education_page_final_continue/click',
				{},
				user,
				workspace
			);

			if (step !== finalStep) {
				setStep((curr) => curr + 1);
			} else {
				dismissViewerWelcomePage();
				navigate('/');
			}
		} else {
			setStep((curr) => curr + 1);
		}
	}, [
		setStep,
		navigate,
		dismissViewerWelcomePage,
		user,
		workspace,
		step,
		finalStep,
	]);

	if (finalStep === 3 && step === 3) {
		return (
			<>
				<Button variant="primary" onClick={nextStep}>
					Get the Google Chrome extension
				</Button>
				<Button onClick={markAsCompleteAndNavigateAway} variant="tertiary">
					Skip
				</Button>
			</>
		);
	}

	return (
		<>
			<Button variant="primary" onClick={nextStep}>
				{step !== finalStep ? 'Continue' : "Let's go"}
			</Button>
			{step !== finalStep && (
				<Button onClick={markAsCompleteAndNavigateAway} variant="tertiary">
					Skip
				</Button>
			)}
		</>
	);
}
