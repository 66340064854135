import { Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import type { PreferenceOption } from '@repo/common/constants/integration/integrations.preferences';
import { Switch } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useIntegration, useUpdateIntegration } from '../../api';

interface IIntegrationPreferencesProps {
	id: string;
	preferenceOptions: PreferenceOption[];
}

export const IntegrationPreferences = observer(
	({ id, preferenceOptions }: IIntegrationPreferencesProps) => {
		const { data: integration } = useIntegration({
			id,
			options: {
				enabled: !!id,
			},
		});

		const { mutateAsync } = useUpdateIntegration({
			options: {
				onSuccess() {
					showNotification({
						title: 'Preference saved',
						message: 'Run a sync to update resources.',
						color: 'teal',
					});
				},
			},
		});

		return (
			<Stack spacing={'md'}>
				{preferenceOptions.map((option) => (
					<Switch
						key={option.credentialFieldName}
						label={option.title}
						description={option.description}
						onChange={(e) => {
							const credentials = {
								...(integration?.credentials ?? {}),
								[option.credentialFieldName]: e.target.checked,
							};
							mutateAsync({ data: { id, credentials } });
						}}
						checked={Boolean(
							integration?.credentials[option.credentialFieldName]
						)}
					/>
				))}
			</Stack>
		);
	}
);
