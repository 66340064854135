import { Box, Stack } from '@mantine/core';
import { EmptyState } from '@repo/common/components';
import { useStyles } from './AIChatEmpty.styles';

function AIChatEmpty() {
	const { classes } = useStyles();

	return (
		<Stack className={classes.root}>
			<Box className={classes.emptyStateContainer}>
				<EmptyState
					className={classes.emptyState}
					illustrationName="ai"
					title="Hi, I'm Secoda AI"
					description="Ask me anything about your company data."
					includeGoBack={false}
					size="lg"
					descWidth="100%"
					width="100%"
				/>
			</Box>
		</Stack>
	);
}

export default AIChatEmpty;
