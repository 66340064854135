import { Box } from '@mantine/core';
import { isEmpty } from 'lodash-es';
import { memo, useEffect, useRef, useState } from 'react';
import { RichEditor } from '../../RichEditor';

interface AnimatedContentProps {
	content: string;
	isTyping?: boolean;
	typingSpeed?: number; // Characters per second
	onTypingComplete?: () => void;
	viewportRef?: React.RefObject<HTMLDivElement>;
	cacheKey: string;
}

export const AnimatedContent = memo(
	({
		content,
		isTyping = false,
		typingSpeed = 400,
		onTypingComplete,
		viewportRef,
		cacheKey,
	}: AnimatedContentProps) => {
		const [displayedContent, setDisplayedContent] = useState('');
		const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

		// Keep track of the animation state
		const animationState = useRef({
			intervalId: null as NodeJS.Timeout | null,
			currentLength: 0,
			isAnimating: false,
		});

		// Load saved progress on mount
		useEffect(() => {
			const savedProgress = localStorage.getItem(`typing-progress-${cacheKey}`);
			if (savedProgress) {
				const progress = parseInt(savedProgress, 10);
				if (!isNaN(progress) && progress > 0 && progress <= content.length) {
					animationState.current.currentLength = progress;
					setDisplayedContent(content.slice(0, progress));
				}
			}
		}, [cacheKey, content]);

		// Handle scroll behavior
		useEffect(() => {
			if (!viewportRef?.current) return;

			const viewport = viewportRef.current;
			const handleScroll = () => {
				const isAtBottom =
					viewport.scrollHeight - viewport.scrollTop <=
					viewport.clientHeight + 55;
				setShouldAutoScroll(isAtBottom);
			};

			viewport.addEventListener('scroll', handleScroll);
			return () => viewport.removeEventListener('scroll', handleScroll);
		}, [viewportRef]);

		// Handle auto-scrolling
		useEffect(() => {
			if (!isTyping || !shouldAutoScroll || !viewportRef?.current) return;

			const scrollInterval = setInterval(() => {
				viewportRef.current?.scrollTo({
					top: viewportRef.current?.scrollHeight,
					behavior: 'smooth',
				});
			}, 300);

			return () => clearInterval(scrollInterval);
		}, [isTyping, shouldAutoScroll, viewportRef]);

		// Handle typing animation
		useEffect(() => {
			if (isEmpty(content)) return;

			const state = animationState.current;

			// If not typing, show full content immediately
			if (!isTyping) {
				if (state.intervalId) {
					clearInterval(state.intervalId);
					state.intervalId = null;
				}
				setDisplayedContent(content);
				state.currentLength = content.length;
				state.isAnimating = false;
				localStorage.setItem(
					`typing-progress-${cacheKey}`,
					content.length.toString()
				);
				return;
			}

			// If we're already animating, just update the target content
			if (state.isAnimating) {
				return;
			}

			// Start a new animation
			state.isAnimating = true;
			const interval = 1000 / typingSpeed;

			const animate = () => {
				state.currentLength += 1;

				if (state.currentLength <= content.length) {
					setDisplayedContent(content.slice(0, state.currentLength));
					localStorage.setItem(
						`typing-progress-${cacheKey}`,
						state.currentLength.toString()
					);
				} else {
					// Animation complete
					if (state.intervalId) {
						clearInterval(state.intervalId);
						state.intervalId = null;
					}
					state.isAnimating = false;
					onTypingComplete?.();
				}
			};

			// Clear any existing interval
			if (state.intervalId) {
				clearInterval(state.intervalId);
			}

			// Start new interval
			state.intervalId = setInterval(animate, interval);

			// Cleanup
			return () => {
				if (state.intervalId) {
					clearInterval(state.intervalId);
					state.intervalId = null;
				}
			};
		}, [content, isTyping, typingSpeed, onTypingComplete, cacheKey]);

		return (
			<Box>
				<RichEditor readOnly value={displayedContent} />
			</Box>
		);
	}
);
