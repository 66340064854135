import type { ColorScheme } from '@mantine/core';
import {
	ColorSchemeProvider,
	createEmotionCache,
	MantineProvider,
	Tooltip,
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { ApiConfigProvider } from '@repo/api-codegen/api/codegen/apiConfigContext';
import type { NormalizerConfig } from '@repo/react-query-cache';
import { QueryNormalizerProvider } from '@repo/react-query-cache';
import { SecodaTheme } from '@repo/theme/mantine/secodaTheme';
import { QueryClientProvider } from '@tanstack/react-query';
import 'focus-visible/dist/focus-visible';
import { isNil } from 'lodash-es';
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { queryClient, useAuthUser } from './api';
import { getPreferredColorScheme } from './components/Settings/Preferences';
import { TableV2Spotlight } from './components/Spotlight/components/TableV2Spotlight';
import { trackEvent } from './utils/analytics';
import { FeatureFlagsProvider, useFeatureFlags } from './utils/featureFlags';
import { LaunchDarklyProvider } from './utils/featureFlags/LaunchDarklyProvider';

const QUERY_NORMALIZER_CONFIG: NormalizerConfig = {
	devLogging: false,
};

const myCache = createEmotionCache({
	key: 'mantine',
	prepend: true,
	speedy: true,
});

function ThemedContent({ children }: { children: React.ReactNode }) {
	const { user } = useAuthUser();
	const { darkMode } = useFeatureFlags();

	const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
		key: 'mantine-color-scheme',
		defaultValue: 'light',
		getInitialValueInEffect: true,
	});

	const toggleColorScheme = (value?: ColorScheme) =>
		setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

	useEffect(() => {
		if (!darkMode) {
			setColorScheme('light');
			return () => {};
		}

		if (!isNil(user?.user_appearance) && user.user_appearance !== 'system') {
			setColorScheme(user.user_appearance);
			return () => {};
		} else {
			const systemPreference = getPreferredColorScheme();
			setColorScheme(systemPreference);
			const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

			const handleSystemPreferenceChange = (event: MediaQueryListEvent) => {
				const newColorScheme = event.matches ? 'dark' : 'light';
				if (!darkMode) {
					setColorScheme('light');
				} else if (
					isNil(user?.user_appearance) ||
					user.user_appearance === 'system'
				) {
					setColorScheme(newColorScheme);
				}
			};

			mediaQuery.addEventListener('change', handleSystemPreferenceChange);

			return () => {
				mediaQuery.removeEventListener('change', handleSystemPreferenceChange);
			};
		}
	}, [user, setColorScheme, darkMode]);

	useEffect(() => {
		if (!darkMode && colorScheme === 'dark') {
			setColorScheme('light');
		}
	}, [darkMode, colorScheme, setColorScheme]);

	useEffect(() => {
		trackEvent('theme', { colorScheme });
	}, [colorScheme]);

	const effectiveColorScheme = !darkMode ? 'light' : colorScheme;

	return (
		<ColorSchemeProvider
			colorScheme={effectiveColorScheme}
			toggleColorScheme={toggleColorScheme}
		>
			<MantineProvider
				theme={{ ...SecodaTheme, colorScheme: effectiveColorScheme }}
				withNormalizeCSS
				withGlobalStyles
				emotionCache={myCache}
			>
				<Tooltip.Group openDelay={1000} closeDelay={200}>
					<TableV2Spotlight>{children}</TableV2Spotlight>
				</Tooltip.Group>
			</MantineProvider>
		</ColorSchemeProvider>
	);
}

export function Providers({ children }: { children: React.ReactNode }) {
	return (
		<DndProvider backend={HTML5Backend}>
			<QueryNormalizerProvider
				queryClient={queryClient}
				normalizerConfig={QUERY_NORMALIZER_CONFIG}
			>
				<QueryClientProvider client={queryClient}>
					<ApiConfigProvider>
						<LaunchDarklyProvider>
							<FeatureFlagsProvider>
								<ThemedContent>{children}</ThemedContent>
							</FeatureFlagsProvider>
						</LaunchDarklyProvider>
					</ApiConfigProvider>
				</QueryClientProvider>
			</QueryNormalizerProvider>
		</DndProvider>
	);
}
