import type {
	ClassNames,
	GroupProps,
	MantineStyleSystemProps,
	ScrollAreaProps,
} from '@mantine/core';
import {
	CloseButton,
	createStyles,
	Group,
	Stack,
	TextInput,
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { Button, Icon, Text, TitleSkeleton } from '@repo/foundations';
import { map, uniqBy } from 'lodash-es';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useFuseSearch from '../../hooks/useFuseSearch.ts';
import type { WidgetData } from './types';
import WidgetSelectorFilterView from './WidgetSelectorFilterView';
import type { IWidgetSelectorGroupProps } from './WidgetSelectorGroup';
import WidgetSelectorScrollView from './WidgetSelectorScrollView';

const useStyles = createStyles((theme) => ({
	root: {
		alignItems: 'flex-start',
		flexWrap: 'nowrap',
		height: '100%',
		gap: 0,
	},
	groupButton: {
		display: 'flex',
		width: '100%',
	},
	selectorColumn: {
		padding: theme.spacing.lg,
		width: theme.other.space[60],
		gap: theme.spacing.sm,
	},
	searchInput: {
		borderRadius: theme.radius.sm,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.other.getColor('border/input/default'),
		'&:hover': {
			borderColor: theme.other.getColor('border/input/hover'),
		},
		'&:active, &:focus': {
			borderColor: theme.other.getColor('fill/transparent/default'),
			boxShadow: `0px 0px 0px 2px ${theme.other.getColor('border/emphasis/default')}`,
		},
	},
	selectorViewWrapper: {
		flex: 1,
		// This applies to the component itself to override Mantine's styles
		// Without this flexGrow: 0 is applied to the children in the Stack component
		'&&': {
			flexGrow: 1,
		},
		alignItems: 'flex-start',
		width: '100%',
		padding: theme.spacing.lg,
		paddingRight: 0,
	},
}));

type WidgetSelectorStylesNames =
	| 'root'
	| 'selectorColumn'
	| 'searchInput'
	| 'selectorViewWrapper'
	| 'groupButton';

export interface IWidgetSelectorProps<T extends object = object>
	extends Omit<GroupProps, 'children' | 'classNames'>,
		MantineStyleSystemProps {
	data: WidgetData<T>[];
	isLoading?: boolean;
	onWidgetSelect: (widget: WidgetData<T>) => void;
	type?: 'filter' | 'scroll';
	widgetGroupProps?: Omit<
		IWidgetSelectorGroupProps<T>,
		| 'groupName'
		| 'widgets'
		| 'onHidden'
		| 'onVisible'
		| 'onWidgetSelect'
		| 'renderer'
	>;
	header?: JSX.Element;
	scrollAreaHeight?: ScrollAreaProps['h'];
	scrollAreaProps?: Omit<ScrollAreaProps, 'viewportRef' | 'h'>;
	cols?: number;
	renderer?: IWidgetSelectorGroupProps<T>['renderer'];
	fuzzySearchThreshold?: number;
	className?: string;
	classNames?: ClassNames<WidgetSelectorStylesNames>;
}

function WidgetSelector<T extends object = object>({
	className,
	classNames: classNamesProp,
	styles,
	unstyled,
	data,
	isLoading,
	type = 'scroll',
	onWidgetSelect,
	widgetGroupProps = {},
	header,
	scrollAreaHeight = '400px',
	scrollAreaProps = {},
	cols = 1,
	renderer,
	fuzzySearchThreshold = 0.2,
	...others
}: IWidgetSelectorProps<T>) {
	const { theme, classes, cx } = useStyles();

	const viewportRef = useRef<HTMLDivElement>(null);

	const [searchTerm, setSearchTerm] = useInputState('');
	const [groupNames, setGroupNames] = useState<string[]>([]);

	const handleSearchTermChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (event.target.value !== '') {
				setGroupNames(['All']);
			}
			setSearchTerm(event.target.value);
		},
		[setSearchTerm]
	);

	const filteredData = useFuseSearch({
		term: searchTerm,
		items: data,
		keys: ['group', 'title'],
		options: { threshold: fuzzySearchThreshold },
	});

	const partitionedData = useMemo(
		() =>
			filteredData.reduce(
				(acc, item) => {
					const widgetData = acc[item.group] || [];
					return {
						...acc,
						[item.group]: [...widgetData, item],
					};
				},
				{} as Record<string, WidgetData<T>[]>
			),
		[filteredData]
	);

	const allGroupNames = useMemo(() => {
		const groups =
			type === 'filter'
				? ['All', ...uniqBy(data, 'group').map((item) => item.group)]
				: Object.keys(partitionedData);

		// Keeping 'All' at the start if it exists, sorting the rest alphabetically
		return groups.sort((a, b) => {
			if (a === 'All') return -1;
			if (b === 'All') return 1;
			return a.localeCompare(b);
		});
	}, [partitionedData, type, data]);

	const activeButton = groupNames[0];

	useEffect(() => {
		if (allGroupNames.length === 0) return;

		const filteredGroupNames = groupNames.filter((name) =>
			allGroupNames.includes(name)
		);

		if (filteredGroupNames.length === 0) {
			setGroupNames([allGroupNames[0]]);
		} else if (filteredGroupNames.length !== groupNames.length) {
			setGroupNames([
				...filteredGroupNames,
				...allGroupNames.filter((name) => !filteredGroupNames.includes(name)),
			]);
		}
	}, [allGroupNames, groupNames]);

	const handleGroupButtonClick = useCallback(
		(index: number) => {
			if (type === 'scroll') {
				viewportRef.current?.querySelectorAll('h1')[index]?.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
					inline: 'start',
				});
			} else {
				setGroupNames([allGroupNames[index]]);
			}
		},
		[type, allGroupNames]
	);

	const classNames = useMemo(
		() => ({
			root: cx(classes.root, classNamesProp?.root),
			selectorColumn: cx(
				classes.selectorColumn,
				classNamesProp?.selectorColumn
			),
			searchInput: cx(classes.searchInput, classNamesProp?.searchInput),
			selectorViewWrapper: cx(
				classes.selectorViewWrapper,
				classNamesProp?.selectorViewWrapper
			),
			groupButton: cx(classes.groupButton, classNamesProp?.groupButton),
		}),
		[classes, cx, classNamesProp]
	);

	return (
		<Group className={classNames.root} {...others}>
			<Stack className={classNames.selectorColumn}>
				<TextInput
					classNames={{
						input: classNames.searchInput,
					}}
					type="search"
					value={searchTerm}
					onChange={handleSearchTermChange}
					placeholder="Search"
					icon={<Icon name="search" />}
					rightSection={
						searchTerm && <CloseButton onClick={() => setSearchTerm('')} />
					}
					autoFocus
					data-autofocus
				/>
				<Stack spacing={theme.spacing['4xs']}>
					{isLoading
						? map(Array(3), (i) => <TitleSkeleton key={i} py="md" />)
						: allGroupNames.map((groupName, index) => {
								const groupIconName = data.find(
									(item) => item.group === groupName
								)?.groupIconName;
								const count =
									groupName === 'All'
										? filteredData.length
										: (partitionedData[groupName]?.length ?? 0);

								return (
									<Button
										key={groupName}
										className={cx(classNames?.groupButton)}
										variant="tertiary"
										size="lg"
										onClick={() => handleGroupButtonClick(index)}
										data-active={activeButton === groupName}
									>
										<Group spacing="xs">
											{groupIconName && <Icon name={groupIconName} />}
											<Text size="xs" weight="semibold">
												{`${groupName} (${count})`}
											</Text>
										</Group>
									</Button>
								);
							})}
				</Stack>
			</Stack>
			<Stack className={classNames.selectorViewWrapper}>
				{header}
				{type === 'scroll' ? (
					<WidgetSelectorScrollView
						data={partitionedData}
						groupNames={groupNames}
						setGroupNames={setGroupNames}
						viewportRef={viewportRef}
						onWidgetSelect={onWidgetSelect}
						scrollAreaHeight={scrollAreaHeight}
						scrollAreaProps={scrollAreaProps}
						widgetGroupProps={widgetGroupProps}
						cols={cols}
						renderer={renderer}
					/>
				) : (
					<WidgetSelectorFilterView
						data={partitionedData}
						isLoading={isLoading}
						groupNames={groupNames}
						onWidgetSelect={onWidgetSelect}
						scrollAreaHeight={scrollAreaHeight}
						widgetGroupProps={widgetGroupProps}
						cols={cols}
						renderer={renderer}
					/>
				)}
			</Stack>
		</Group>
	);
}

export default WidgetSelector;
