import { Group } from '@mantine/core';
import { TextSkeleton } from '@repo/foundations';

export function DataAccessRequestControlsSkeleton() {
	return (
		<Group spacing="xs">
			<TextSkeleton text="Deny access" />
			<TextSkeleton text="Approve access" />
		</Group>
	);
}
