import type { TimelineItemProps } from '@mantine/core';
import { Timeline } from '@mantine/core';
import { IconSkeleton, TextSkeleton } from '@repo/foundations';

export type ResourceLogItemSkeletonProps = TimelineItemProps;

export function ResourceLogItemSkeleton(props: ResourceLogItemSkeletonProps) {
	return (
		<Timeline.Item
			bullet={<IconSkeleton />}
			title={<TextSkeleton size="sm" />}
			{...props}
		/>
	);
}
