import { Box, createStyles, Group, Stack } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import type { APIKeyOut } from '@repo/api-codegen';
import { queryKeyFn, useApiUpdateApiKey } from '@repo/api-codegen';
import { Button, TextInput } from '@repo/foundations';
import { queryClient } from '../../../api';
import { closeAllModals } from '../../ModalManager';

interface EditAPIKeyNameModalProps {
	apiKey: APIKeyOut;
}

const useStyles = createStyles((theme) => ({
	body: {
		padding: 0,
	},
	header: {
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		padding: theme.spacing.sm,
		justifyContent: 'space-between',
		flexWrap: 'nowrap',
	},
	content: {
		padding: theme.spacing.md,
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
	},
	disabledTextInputWrapper: {
		'&:has(input:disabled) .mantine-TextInput-rightSection': {
			display: 'flex',
		},
	},
	disabledTextInputInput: {
		'&:disabled': {
			color: `${theme.other.getColor('text/primary/default')} !important`,
			opacity: 'unset',
		},
	},
	footer: {
		padding: theme.spacing.md,
		justifyContent: 'flex-end',
		gap: theme.spacing.xs,
	},
}));

export function EditAPIKeyNameModal({ apiKey }: EditAPIKeyNameModalProps) {
	const [description, setDescription] = useInputState(apiKey.description ?? '');

	const { classes } = useStyles();

	const { mutateAsync: updateKey } = useApiUpdateApiKey({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: queryKeyFn({
					path: '/auth/api_keys/',
					operationId: 'apiListApiKeys',
					variables: {},
				}),
			});
		},
	});

	const handleEditKey = async () => {
		await updateKey({
			body: {
				description,
			},
			pathParams: {
				apiKeyId: apiKey.id,
			},
		});
		closeAllModals();
		queryClient.invalidateQueries({
			queryKey: queryKeyFn({
				path: '/auth/api_keys/',
				operationId: 'apiListApiKeys',
				variables: {},
			}),
		});
	};

	return (
		<Stack spacing={0}>
			<Box className={classes.content}>
				<TextInput
					label={'Name'}
					value={description}
					onChange={setDescription}
				/>
			</Box>
			<Group className={classes.footer}>
				<Button variant="default" onClick={() => closeAllModals()}>
					Cancel
				</Button>
				<Button
					variant="primary"
					disabled={description === ''}
					onClick={handleEditKey}
				>
					Done
				</Button>
			</Group>
		</Stack>
	);
}
