import { useApiGroupItemsRefreshSupport } from '@repo/api-codegen';
import { Button } from '@repo/foundations';
import type { IIntegration } from '../../../../api';

export function RefreshButton({
	integration,
	onRefreshGroupItems,
	isRefreshing,
}: {
	integration: IIntegration;
	onRefreshGroupItems: () => void;
	isRefreshing: boolean;
}) {
	const { data: supportRefresh } = useApiGroupItemsRefreshSupport({
		queryParams: {
			integration_type: integration.type,
		},
	});

	if (!supportRefresh) {
		return null;
	}

	return (
		<Button
			variant="default"
			size="md"
			leftIconName="refresh"
			loading={isRefreshing}
			disabled={isRefreshing}
			onClick={onRefreshGroupItems}
		>
			Refresh
		</Button>
	);
}
