import { createStyles, Modal, Stack } from '@mantine/core';
import type { MonitorsOut } from '@repo/api-codegen';
import { SegmentedControl } from '@repo/foundations';
import { map, size } from 'lodash-es';
import { useEffect, useState } from 'react';
import type { ILineageTableTest } from '../../../api';
import MonitorItem from './MonitorItem';
import TestRunItem from './TestRunItem';

interface ITestsModalProps {
	opened: boolean;
	onClose: () => void;
	tests?: ILineageTableTest[];
	monitors?: MonitorsOut[];
}

const useStyles = createStyles((theme) => ({
	list: {
		gap: 0,
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.other.borderRadius.md,
		'& > *:not(:last-child)': {
			borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		},
	},
}));

export function TestsModal({
	opened,
	onClose,
	tests,
	monitors,
}: ITestsModalProps) {
	const { classes } = useStyles();

	const hasMonitors = size(monitors) > 0;
	const hasTests = size(tests) > 0;

	const [controlValue, setControlValue] = useState<'monitors' | 'tests'>(
		'monitors'
	);

	useEffect(() => {
		if (hasMonitors && hasTests) setControlValue('monitors');
		else if (hasMonitors) setControlValue('monitors');
		else if (hasTests) setControlValue('tests');
	}, [hasMonitors, hasTests]);

	const handleModalClose = () => {
		onClose();
	};

	if (size(tests) === 0 && size(monitors) === 0) {
		return null;
	}

	const getModalTitle = () => {
		if (hasTests && hasMonitors) return 'Monitors and tests';
		if (hasMonitors) return 'Monitors';
		if (hasTests) return 'Tests';
		return 'Monitors and tests';
	};

	return (
		<Modal
			opened={opened}
			size="lg"
			title={getModalTitle()}
			onClose={handleModalClose}
		>
			<Stack>
				{hasMonitors && hasTests && (
					<SegmentedControl
						value={controlValue}
						onChange={(value) => setControlValue(value as 'monitors' | 'tests')}
						data={[
							{ label: 'Monitors', value: 'monitors' },
							{ label: 'Tests', value: 'tests' },
						]}
						size="xs"
						fullWidth
					/>
				)}
				<Stack className={classes.list}>
					{controlValue === 'monitors'
						? map(monitors, (monitor) => (
								<MonitorItem key={monitor.id} monitor={monitor} />
							))
						: map(tests, (test) => <TestRunItem key={test.id} test={test} />)}
				</Stack>
			</Stack>
		</Modal>
	);
}
