import { Box, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { APIKeyOut } from '@repo/api-codegen';
import {
	queryKeyFn,
	useApiDeleteApiKey,
	useApiListApiKeys,
} from '@repo/api-codegen';
import { SettingsBlock, SettingsButton } from '@repo/common/components';
import { Icon, IconButton, ResourceIcon } from '@repo/foundations';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { queryClient, useAuthUser } from '../../../api';
import { useFeatureAccess } from '../../../api/hooks/workspace/useFeatureAccess';
import { usePlan } from '../../../hooks/usePlans';
import { EmptyState } from '../../EmptyState';
import { LoadingSpinner } from '../../LoadingSpinner';
import { openConfirmModal, openModal } from '../../ModalManager';
import { UpgradeButton } from '../UpgradeButton';
import { ApiFiltersGenerator } from './ApiFiltersGenerator/ApiFiltersGenerator';
import { CreateAPIKeyModal } from './CreateAPIKeyModal';
import { EditAPIKeyNameModal } from './EditAPIKeyNameModal';

export function APISettings() {
	const [isCreateKeyModalOpened, { open, close }] = useDisclosure(false);
	const { plan } = usePlan();
	const { isEditorOrAdminUser } = useAuthUser();
	const { apiAccess } = useFeatureAccess();
	const { data: keys, isLoading } = useApiListApiKeys({});

	const openEditKeyModal = useCallback((key: APIKeyOut) => {
		openModal({
			title: 'Rename API key',
			children: <EditAPIKeyNameModal apiKey={key} />,
		});
	}, []);

	const { mutate: deleteKey } = useApiDeleteApiKey({
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: queryKeyFn({
					path: '/auth/api_keys/',
					operationId: 'apiListApiKeys',
					variables: {},
				}),
			});
		},
	});

	const handleDeleteKey = useCallback(
		(apiKey: APIKeyOut) => {
			openConfirmModal({
				title: 'Delete API key',
				children: `Are you sure you want to delete the API key "${apiKey.description}"?`,
				confirmProps: {
					variant: 'primary',
					tone: 'critical',
				},
				labels: { confirm: 'Delete', cancel: 'Cancel' },
				onConfirm: () =>
					deleteKey({
						pathParams: { apiKeyId: apiKey.id },
					}),
			});
		},
		[deleteKey]
	);

	if (isLoading) {
		return <LoadingSpinner />;
	}

	if (!apiAccess) {
		return (
			<EmptyState
				title="Upgrade to access the API"
				description="You can use an API to programatically access information in Secoda."
				illustrationName="upgrade"
				includeGoBack={false}
				stateHeight="50vh"
				size="lg"
				withActions={<UpgradeButton feature="API" size="md" />}
			/>
		);
	}

	return (
		<Box>
			<Box>
				<SettingsButton
					title={
						keys?.results.length === 0
							? 'No API keys'
							: `${keys?.results.length} API key${keys?.results.length === 1 ? '' : 's'}`
					}
				>
					<Box style={{ position: 'relative' }}>
						{plan?.api_support ? (
							<IconButton
								iconName="plus"
								variant="tertiary"
								size="md"
								onClick={open}
								disabled={!isEditorOrAdminUser}
							/>
						) : (
							<UpgradeButton feature="API" />
						)}
					</Box>
				</SettingsButton>
				{keys?.results.map((key) => (
					<SettingsButton
						leftComponent={<ResourceIcon iconName="key" />}
						key={key.id}
						title={key.description}
						description={
							<>
								Last used by: {key.last_used_ip && `${key.last_used_ip}@`}
								{key.last_used_at
									? dayjs(key.last_used_at).format('YYYY-MM-DD HH:mm:ss')
									: 'None'}
								<br />
								{`Used ${key.times_used || 0} times`}
							</>
						}
					>
						<Menu position="bottom-end">
							<Menu.Target>
								<IconButton
									iconName="dots"
									variant="tertiary"
									disabled={!isEditorOrAdminUser}
								/>
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item
									icon={<Icon name="pencil" />}
									onClick={() => openEditKeyModal(key)}
									disabled={!isEditorOrAdminUser}
								>
									Rename key
								</Menu.Item>
								<Menu.Item
									icon={<Icon name="trash" />}
									onClick={() => handleDeleteKey(key)}
									disabled={!isEditorOrAdminUser}
									color="red"
								>
									Delete key
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					</SettingsButton>
				))}
			</Box>
			<CreateAPIKeyModal opened={isCreateKeyModalOpened} onClose={close} />
			<Box mt="xl">
				<SettingsButton
					title="API Specification"
					description="View the complete OpenAPI specification"
					buttonText="View schema"
					onClick={() => {
						window.open(
							'https://api.secoda.co/api/schema/redoc/#tag/Resource/paths/~1resource~1catalog/get',
							'_blank'
						);
					}}
				/>
				<SettingsBlock
					title="API Tool"
					description="Generate filters for the Resource API endpoint"
				>
					<ApiFiltersGenerator />
				</SettingsBlock>
			</Box>
		</Box>
	);
}
