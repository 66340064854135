import { TextInput } from '@repo/foundations';
import { kebabCase } from 'lodash-es';
import { SettingsBlock } from '../SettingsBlock';

interface SettingsTextInputProps {
	title: string;
	description?: string;
	value: string;
	onChange: (value: string) => void;
	placeholder?: string;
	disabled?: boolean;
}

export function SettingsTextInput({
	title,
	description,
	value,
	onChange,
	placeholder,
	disabled = false,
}: SettingsTextInputProps) {
	const id = kebabCase(`settings-text-input-${title}`);

	return (
		<SettingsBlock
			title={<label htmlFor={id}>{title}</label>}
			description={description}
			rightComponent={
				<TextInput
					value={value}
					onChange={(event) => onChange(event.currentTarget.value)}
					placeholder={placeholder}
					disabled={disabled}
					data-testid={id}
					id={id}
					size="sm"
				/>
			}
		/>
	);
}
