import {
	ActionIcon,
	Center,
	Group,
	Menu,
	RingProgress,
	Space,
	Stack,
} from '@mantine/core';
import { Icon, Text, Title } from '@repo/foundations';
import { openConfirmModal } from '../../../../../components/ModalManager';
import { useStyles } from './OnboardingHeader.styles';

interface IOnboardingHeader {
	stepsCompleted: number;
	totalSteps: number;
	description?: string;
	onDismissOnboarding: VoidFunction;
}

function OnboardingHeader({
	stepsCompleted,
	totalSteps,
	description = 'Complete these steps to get up and running with Secoda',
	onDismissOnboarding,
}: IOnboardingHeader) {
	const { classes } = useStyles();

	const handleClick = () => {
		openConfirmModal({
			title: 'Are you sure you want to dismiss the onboarding steps?',
			labels: { confirm: 'Confirm', cancel: 'Cancel' },
			confirmProps: { variant: 'primary' },
			onConfirm: onDismissOnboarding,
		});
	};

	return (
		<Group noWrap>
			<RingProgress
				sections={[
					{
						value: Math.floor((stepsCompleted / totalSteps) * 100),
						color: classes.progressBar,
					},
				]}
				styles={(theme) => ({
					root: {
						svg: {
							stroke: theme.other.getColor('icon/brand/default'),
						},
					},
				})}
				size={64}
				thickness={6}
				label={
					<Center>
						<Text size="md" weight="bold">
							{stepsCompleted}/{totalSteps}
						</Text>
					</Center>
				}
			/>
			<Stack spacing={4}>
				<Title order={4}>Getting started guide</Title>
				<Text size="sm" color="text/secondary/default">
					{description}
				</Text>
			</Stack>
			<Space className={classes.spacer} />
			<Menu position="bottom-end">
				<Menu.Target data-testid="onboarding-header-dropdown-target">
					<ActionIcon>
						<Icon name="dots" />
					</ActionIcon>
				</Menu.Target>
				<Menu.Dropdown>
					<Menu.Item color="red" onClick={handleClick}>
						Dismiss Onboarding
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
		</Group>
	);
}

export default OnboardingHeader;
