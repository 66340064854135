import { Center, Flex } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import type { TeamOut } from '@repo/api-codegen';
import { useApiGetTeam } from '@repo/api-codegen';
import { UserRole } from '@repo/common/enums/UserRole';
import { Icon, IconButton, Text } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTeamEmptyStates } from '../../../api/hooks/team/emptyStates';
import { getSideBarSettingValue } from '../../../api/types/models/team';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { Guard } from '../../Guard/Guard';
import { CatalogTreeV2 } from '../CatalogTree/CatalogTreeV2';
import NewCatalogTree from '../CatalogTree/NewCatalogTree';
import CollectionsTree from '../CollectionsTree/CollectionsTree';
import { DocumentTree } from '../DocumentTree/DocumentTree';
import { GlossaryTree } from '../GlossaryTree/GlossaryTree';
import { isActive } from '../utils';
import type { Link } from './SideBarLink';
import SideBarLink from './SideBarLink';
import SidebarTooltipWrapper from './SidebarTooltipWrapper';
import { sideBarStore } from './store';
import { TeamSettingMenu } from './TeamSettingMenu';
import { useTeamSideBarItemStyle } from './TeamSideBarItem.styles';

export interface TeamSideBarItemHandle {
	open: () => void;
}

interface TeamSideBarItemProps {
	team: TeamOut;
	sideBarCollapsed: boolean;
}

export const TeamSideBarItem = forwardRef<
	TeamSideBarItemHandle,
	TeamSideBarItemProps
>(({ team: initialTeamFromList, sideBarCollapsed }, ref) => {
	const location = useLocation();
	const { catalogTreeV2 } = useFeatureFlags();

	const [collapsableOpened, setCollapsableOpened] = useLocalStorage({
		key: `team-${initialTeamFromList.id}-sidebar-collapsable-opened`,
		defaultValue: initialTeamFromList.is_default_team,
	});

	const { data: teamDetail } = useApiGetTeam(
		{
			pathParams: {
				teamId: initialTeamFromList.id,
			},
		},
		{
			enabled: !isNil(initialTeamFromList.id),
		}
	);

	useImperativeHandle(ref, () => ({
		open() {
			setCollapsableOpened(true);
		},
	}));

	const { cx, classes, theme } = useTeamSideBarItemStyle({
		open: collapsableOpened,
		sideBarCollapsed,
	});

	const emptyStates = useTeamEmptyStates(initialTeamFromList.id);

	const team = teamDetail || initialTeamFromList;

	const toggleCollapsable = useCallback(() => {
		setCollapsableOpened(!collapsableOpened);
	}, [collapsableOpened, setCollapsableOpened]);

	const contentLinks: Link[] = useMemo(
		() => [
			{
				id: 'nav::home',
				label: 'Home',
				icon: <Icon name="home" color="icon/primary/default" />,
				href: [`/teams/${team.id}/`],
				show: getSideBarSettingValue(team.sidebar_settings, 'home'),
				underTeam: true,
			},
			{
				id: 'nav::catalog',
				label: 'Catalog',
				icon: <Icon name="listDetails" color="icon/primary/default" />,
				href: [`/teams/${team.id}/catalog`],
				show: getSideBarSettingValue(team.sidebar_settings, 'catalog'),
				collapsable: true,
				collapsedComponent: catalogTreeV2 ? (
					<CatalogTreeV2 open={collapsableOpened} teamId={team.id} />
				) : (
					<NewCatalogTree teamId={team.id} />
				),
				count: emptyStates?.data?.exist_dict?.catalog?.is_empty ? 0 : 1,
				underTeam: true,
			},
			{
				id: 'nav::collections',
				label: 'Collections',
				icon: <Icon name="folders" color="icon/primary/default" />,
				href: [`/teams/${team.id}/collections`],
				show: getSideBarSettingValue(team.sidebar_settings, 'collections'),
				collapsable: true,
				collapsedComponent: <CollectionsTree teamId={team.id} />,
				count: emptyStates?.data?.exist_dict?.collection?.is_empty ? 0 : 1,
				underTeam: true,
			},
			{
				id: 'nav::glossary',
				label: 'Glossary',
				icon: <Icon name="book" color="icon/primary/default" />,
				href: [`/teams/${team.id}/glossary`],
				show: getSideBarSettingValue(team.sidebar_settings, 'glossary'),
				collapsable: true,
				collapsedComponent: <GlossaryTree teamId={team.id} />,
				count: emptyStates?.data?.exist_dict?.glossary?.is_empty ? 0 : 1,
				underTeam: true,
			},
			{
				id: 'nav::docs',
				label: 'Documents',
				icon: <Icon name="file" color="icon/primary/default" />,
				href: [`/teams/${team.id}/docs`],
				show: getSideBarSettingValue(team.sidebar_settings, 'documents'),
				collapsable: true,
				collapsedComponent: <DocumentTree teamId={team.id} />,
				count: emptyStates?.data?.exist_dict?.document?.is_empty ? 0 : 1,
				underTeam: true,
			},
			{
				id: 'nav::questions',
				label: 'Questions',
				icon: (
					<Icon name="messageCircleQuestion" color="icon/primary/default" />
				),
				href: [`/teams/${team.id}/questions`],
				show: getSideBarSettingValue(team.sidebar_settings, 'questions'),
				underTeam: true,
			},
		],
		[
			emptyStates?.data?.exist_dict?.catalog?.is_empty,
			emptyStates?.data?.exist_dict?.collection?.is_empty,
			emptyStates?.data?.exist_dict?.document?.is_empty,
			emptyStates?.data?.exist_dict?.glossary?.is_empty,
			team.id,
			team.sidebar_settings,
			collapsableOpened,
		]
	);

	return (
		<>
			<Flex className={classes.wrapper}>
				<Flex className={classes.innerWrapper}>
					<SidebarTooltipWrapper
						label={team.name}
						showTooltip={sideBarCollapsed}
					>
						<Flex
							role="button"
							onClick={toggleCollapsable}
							className={classes.clickArea}
						>
							<Center w={theme.other.space[7]} h={theme.other.space[7]}>
								<Center
									w={theme.other.space[5]}
									h={theme.other.space[5]}
									p={theme.other.space[0.5]}
								>
									{team.icon}
								</Center>
							</Center>
							{!sideBarStore.collapsed && (
								<Flex align="center">
									<Text
										size="sm"
										color="text/primary/default"
										weight="semibold"
										lineClamp={1}
									>
										{team.name}
									</Text>
									<Icon
										name="chevronRight"
										className={cx(classes.displayOnHover, classes.chevron)}
									/>
								</Flex>
							)}
						</Flex>
					</SidebarTooltipWrapper>
					{!sideBarStore.collapsed ? (
						<Guard
							v1AllowedRoles={[UserRole.ADMIN]}
							v2Permission="Teams.Update"
							defaultResult={false}
						>
							<TeamSettingMenu team={team} position="right-start" withinPortal>
								<IconButton
									iconName="dots"
									variant="tertiary"
									size="sm"
									className={cx(classes.hiddenButton, classes.displayOnHover)}
									tooltip="Show actions"
									tooltipProps={{ position: 'right', withArrow: true }}
									mr="xs"
								/>
							</TeamSettingMenu>
						</Guard>
					) : null}
				</Flex>
			</Flex>

			<Flex
				w="100%"
				direction="column"
				pb="xs"
				sx={{
					display: collapsableOpened ? 'flex' : 'none',
				}}
			>
				{contentLinks
					.filter((link) => link.show)
					.map((link) => (
						<SideBarLink
							key={link.id}
							link={link}
							selected={isActive(link.href, location, true)}
						/>
					))}
			</Flex>
		</>
	);
});

TeamSideBarItem.displayName = 'TeamSideBarItem';
