import { Box, Center, Stack } from '@mantine/core';
import { Text } from '@repo/foundations';
import type { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useLocation, useNavigate, useRouteError } from 'react-router';
import FullWidthLoadingSpinner from '../../components/LoadingSpinner';
import { captureError } from '../../web-tracing';

export const REDIRECT_FROM_AUTH_PATH_KEY = 'redirectFromAuthPath';

export function RootAuthRedirect() {
	const error = useRouteError();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (error) {
			captureError(error);
		}

		if ((error as AxiosError)?.message?.includes('403')) {
			const fullPath = `${location.pathname}${location.search}`;
			localStorage.setItem(REDIRECT_FROM_AUTH_PATH_KEY, fullPath);
			navigate('/auth');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, error]);

	return (
		<Box p={20}>
			<Center>
				<Stack>
					<Text>Redirecting to the login page...</Text>
					<FullWidthLoadingSpinner />
				</Stack>
			</Center>
		</Box>
	);
}
