import type { PermissionType } from '@repo/api-codegen';
import type { UserRole } from '@repo/common/enums/UserRole';
import type { ReactNode } from 'react';
import { useFeatureAccessCheck } from '../../utils/authorization/accessMessages';
import { AccessRestrictionMessage } from '../AccessRestrictionMessage';
import type { IllustrationNames } from './../Illustration';

interface FeatureGuardProps {
	children: ReactNode;
	v1AllowedRoles: Array<UserRole>;
	v2Permission: PermissionType;
	isFeatureIncludedInPlan?: boolean;
	isAlwaysIncludedFeature?: boolean;
	featureName: string;
	description?: string;
	illustrationName?: IllustrationNames;
}

export function FeatureGuard({
	children,
	v1AllowedRoles,
	v2Permission,
	isFeatureIncludedInPlan = true,
	isAlwaysIncludedFeature = true,
	featureName,
	description,
	illustrationName,
}: FeatureGuardProps) {
	const { restrictionMessage, isLoading: restrictionMessageIsLoading } =
		useFeatureAccessCheck({
			v1AllowedRoles,
			v2Permission,
			isFeatureIncludedInPlan,
			isAlwaysIncludedFeature,
		});

	if (restrictionMessage && !restrictionMessageIsLoading) {
		return (
			<AccessRestrictionMessage
				illustrationName={illustrationName}
				message={restrictionMessage}
				feature={featureName}
				title={restrictionMessage}
				description={description}
			/>
		);
	}

	return <>{children}</>;
}
