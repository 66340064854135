import { createMockableHook } from '@repo/common/utils/createMockableHook';
import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IQuestion, IQuestionReply } from '../../types';

export const QUESTIONS_NAMESPACE = ['question', 'questions'];
export const questionsQueryKeyFactory = createQueryKeys(QUESTIONS_NAMESPACE);

export const QUESTION_REPLY_NAMESPACE = ['question', 'replies'];
export const questionReplyQueryKeyFactory = createQueryKeys(
	QUESTION_REPLY_NAMESPACE
);

const { prefetchQuestion, prefetchQuestionList } = prefetchFunctionsFactory(
	'question',
	questionsQueryKeyFactory
);

const {
	useQuestion,
	useSuspenseQuestion,
	useQuestionInfiniteList,
	useQuestionList: useQuestionListInternal,
	useCreateQuestion,
	useDeleteQuestion,
	useUpdateQuestion,
	fetchQuestionList,
	updateQuestion,
	createQuestion,
} = baseQueryHooksFactory<IQuestion, 'question'>(
	'question',
	questionsQueryKeyFactory
);

export const [useQuestionList, MockUseQuestionList] = createMockableHook(
	useQuestionListInternal
);

export {
	createQuestion,
	fetchQuestionList,
	prefetchQuestion,
	prefetchQuestionList,
	updateQuestion,
	useCreateQuestion,
	useDeleteQuestion,
	useQuestion,
	useQuestionInfiniteList,
	useSuspenseQuestion,
	useUpdateQuestion,
};

const {
	useQuestionReply,
	useQuestionReplyInfiniteList,
	useQuestionReplyList,
	useCreateQuestionReply,
	useDeleteQuestionReply,
	useUpdateQuestionReply,
} = baseQueryHooksFactory<IQuestionReply, 'questionReply'>(
	'questionReply',
	questionReplyQueryKeyFactory
);

export {
	useCreateQuestionReply,
	useDeleteQuestionReply,
	useQuestionReply,
	useQuestionReplyInfiniteList,
	useQuestionReplyList,
	useUpdateQuestionReply,
};
