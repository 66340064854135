import { Box, Tooltip } from '@mantine/core';
import { Switch } from '@repo/foundations';

export interface IntegrationToggleProps {
	enabled: boolean;
	onChange: (enabled: boolean) => void;
}

export function IntegrationToggle({
	enabled,
	onChange,
}: IntegrationToggleProps) {
	return (
		<Tooltip
			label={
				enabled
					? 'Integration is active. Turning it off will pause scheduled syncs.'
					: 'Integration is paused. Turning it on will resume its scheduled runs.'
			}
		>
			<Box>
				<Switch
					labelPosition="left"
					checked={enabled}
					onChange={(event) => onChange(event.target.checked)}
				/>
			</Box>
		</Tooltip>
	);
}
