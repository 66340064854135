import { Box, createStyles, Group, Stack } from '@mantine/core';
import type { MetricType, MonitorsOut } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import type { Monitor } from '../../../api';
import MonitorStatusBadge from '../../../pages/MonitorPage/components/MonitorStatusBadge';
import { formatValue } from '../../../pages/MonitorPage/utils';
import { toTitleCase } from '../../SearchListItem/helpers';

interface IMonitorItemProps {
	monitor: MonitorsOut;
}

const useStyles = createStyles((theme) => ({
	container: {
		cursor: 'pointer',
		padding: `${theme.spacing.sm} ${theme.spacing.md}`,
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
		'&:active, &:focus': {
			backgroundColor: theme.other.getColor('surface/primary/active'),
		},
	},
	group: {
		width: '100%',
	},
}));

function MonitorItem({ monitor }: IMonitorItemProps) {
	const { classes } = useStyles();

	const navigate = useNavigate();

	const handleMonitorClick = () => {
		navigate(`/monitor/${monitor.id}`);
	};

	const subTitle = useMemo(
		() =>
			monitor.metric_value
				? `${toTitleCase(monitor.metric_type || '')} · ${formatValue(
						monitor.metric_type as MetricType,
						parseFloat(monitor.metric_value)
					)}`
				: `${toTitleCase(monitor.metric_type || '')}`,
		[monitor.metric_type, monitor.metric_value]
	);

	return (
		<Box className={classes.container} onClick={handleMonitorClick}>
			<Group className={classes.group} position="apart">
				<Stack spacing={0}>
					<Text size="sm" weight="semibold">
						{monitor.title}
					</Text>
					<Text size="xs" color="text/secondary/default">
						{subTitle}
					</Text>
				</Stack>
				<MonitorStatusBadge monitor={monitor as Monitor} withAction={false} />
			</Group>
		</Box>
	);
}

export default MonitorItem;
