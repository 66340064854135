import { Group } from '@mantine/core';
import type { PolicyOut } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { FrameworkSelector } from '../../../PoliciesListPage/components/FrameworkSelector';
import { InlineFrameworkPill } from './InlineFrameworkPill';
import { INLINE_TEXT_HEIGHT, INLINE_TEXT_WIDTH } from './constants';

export interface InlineFrameworkProps {
	policy: PolicyOut;
}

export default function InlineFramework({ policy }: InlineFrameworkProps) {
	return (
		<Group spacing="xs" noWrap align="flex-start">
			<Text
				w={INLINE_TEXT_WIDTH}
				style={{ lineHeight: `${INLINE_TEXT_HEIGHT}px` }}
				size="sm"
				color="text/secondary/default"
			>
				Frameworks
			</Text>
			{policy.frameworks && policy.frameworks.length > 0 ? (
				<Group spacing="xs" h={INLINE_TEXT_HEIGHT} pl="3xs">
					{policy.frameworks?.map((framework) => (
						<InlineFrameworkPill
							key={framework}
							policy={policy}
							framework={framework}
						/>
					))}
				</Group>
			) : (
				<FrameworkSelector
					hideOnEmpty={false}
					policy={policy}
					placeholder="Add framework"
					placeholderIconName="plus"
				/>
			)}
		</Group>
	);
}
