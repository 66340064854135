import { useFeatureFlags } from '../../utils/featureFlags/useFeatureFlags';
import WorkspaceActivityLog from '../ActivityLog/WorkspaceActivityLog';
import { AuditLogs } from './AuditLogs';

export function AuditLogWrapper() {
	const { feActivityLog } = useFeatureFlags();

	if (feActivityLog) {
		return <AuditLogs />;
	}

	return <WorkspaceActivityLog />;
}
