import { createMockableHook } from '@repo/common/utils/createMockableHook';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { USERS_NAMESPACE } from '.';
import { apiClient, getEndpoints } from '../../common';
import type { IUser } from '../../types';

interface IUseThisUserArgs {
	id?: string;
	options?: UseQueryOptions<IUser>;
}

export const thisUserQueryKey = ['me'];

export const thisUserQuery = () => ({
	queryKey: thisUserQueryKey,
	queryFn: async () => {
		const res = await apiClient.get<IUser>(
			getEndpoints(USERS_NAMESPACE).byPath(['me'])
		);
		return res.data;
	},
});

function useThisUserInternal({ options }: IUseThisUserArgs = {}) {
	const query = thisUserQuery();
	return useQuery<IUser>({
		...options,
		queryKey: query.queryKey,
		queryFn: query.queryFn,
	});
}

export const [useThisUser, MockUseThisUser] =
	createMockableHook(useThisUserInternal);
