import type { HoverCardProps } from '@mantine/core';
import { Avatar, Group, HoverCard, Stack, createStyles } from '@mantine/core';
import { pluralize } from '@repo/common/utils';
import { getSummaryAndBreadCrumbs } from '@repo/common/utils/breadcrumb';
import { Icon, Text } from '@repo/foundations';
import { lowerCase } from 'lodash-es';
import type { LiteSecodaEntity } from '../../../../../packages/api-codegen';
import { type ISecodaEntity, type IUser } from '../../api';
import { MONITOR_ICON_MAPPING } from '../../pages/MonitorPage/constants.ts';
import { getMarkdownAsPlainText } from '../../utils/getMarkdownAsPlainText';
import { type useResourceLink } from '../Link/useResourceLink';
import { SecodaEntityIcon } from '../SecodaEntity';
import type { ISecodaEntityIcon } from '../SecodaEntity/SecodaEntityIcon/SecodaEntityIcon';
import { UserAvatar } from '../UserAvatar';
import type {
	AIChatPreview,
	EntityPreview,
	MonitorPreview,
	UserPreview,
} from './ResourcePreview.types.ts';
import {
	isAIChatPreview,
	isEntityPreview,
	isMonitorPreview,
	isUserPreview,
} from './ResourcePreview.utils.tsx';

interface UseStylesProps {
	hasDetails: boolean;
}

const useStyles = createStyles((theme, { hasDetails }: UseStylesProps) => ({
	dropdown: {
		maxWidth: '268px',
		borderRadius: theme.radius.md,
		padding: 0,
		boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.12)',
		overflow: 'hidden',
	},
	title: {
		cursor: 'pointer',
		padding: hasDetails
			? `${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm}`
			: theme.spacing.sm,
		overflowWrap: 'anywhere',
	},
	body: {
		padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.md} ${theme.spacing.sm}`,
		backgroundColor: theme.other.getColor('surface/secondary/default'),
	},
	description: {
		fontSize: theme.fontSizes.sm,
		display: '-webkit-box',
		lineClamp: 2,
		WebkitLineClamp: 2,
		boxOrient: 'vertical',
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
	},
	iconWrapper: {
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		borderRadius: theme.radius.xs,
		minWidth: theme.other.space['7'],
		height: theme.other.space['7'],
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: theme.other.space['1'],
	},
}));

interface ResourcePreviewProps
	extends Omit<ReturnType<typeof useResourceLink>, 'data'>,
		HoverCardProps {
	initiallyOpen?: boolean;
	data:
		| EntityPreview
		| UserPreview
		| MonitorPreview
		| AIChatPreview
		| LiteSecodaEntity;
}

export function ResourcePreview({
	data,
	hasError,
	isLoading,
	children,
	initiallyOpen,
	...props
}: ResourcePreviewProps) {
	const showError = hasError || (!isLoading && !data);

	let title: string;
	if (isUserPreview(data)) {
		title = data.display_name;
	} else if (isAIChatPreview(data)) {
		title = data.prompt;
	} else {
		title = data.title ?? '';
	}

	const description =
		isUserPreview(data) || isAIChatPreview(data)
			? null
			: (data.description ?? data?.definition);
	const entityType = isUserPreview(data)
		? 'user'
		: (data as ISecodaEntity).native_type ||
			(data as ISecodaEntity).entity_type;
	const breadcrumbs = getSummaryAndBreadCrumbs(
		entityType,
		isEntityPreview(data) ? data.search_metadata : {}
	);

	let owners: IUser[] = [];

	if (isMonitorPreview(data)) {
		owners = data.owners;
	} else if (isEntityPreview(data)) {
		owners = data.display_metadata?.owners as IUser[];
	} else if (isAIChatPreview(data)) {
		owners = data.user ? [data.user] : [];
	}

	const hasOwners = owners?.length > 0;

	const { classes } = useStyles({ hasDetails: !!description || hasOwners });

	const cleanedDescription = getMarkdownAsPlainText(description ?? '', 2);
	return (
		<HoverCard
			openDelay={500}
			initiallyOpened={initiallyOpen}
			withinPortal
			{...props}
		>
			<HoverCard.Target>{children}</HoverCard.Target>
			<HoverCard.Dropdown className={classes.dropdown}>
				{isLoading && <div className={classes.title}>Loading...</div>}
				{showError && (
					<div className={classes.title}>Could not load preview</div>
				)}
				{!isLoading && !showError && (
					<Stack spacing={0}>
						<Group
							spacing="xs"
							align="flex-start"
							className={classes.title}
							noWrap
						>
							{isUserPreview(data) && <UserAvatar user={data} size="xxs" />}
							<div className={classes.iconWrapper}>
								{isMonitorPreview(data) && (
									<Icon
										name="activity"
										color={
											MONITOR_ICON_MAPPING[data.status].iconColor ??
											MONITOR_ICON_MAPPING[data.status].iconFillColor
										}
									/>
								)}
								{isAIChatPreview(data) && <Icon name="sparkles" />}
								{isEntityPreview(data) && (
									<SecodaEntityIcon
										entity={data as ISecodaEntityIcon}
										inline
										size={20}
									/>
								)}
							</div>
							<Stack spacing={0}>
								<Text size="sm" fw="bold" color="text/primary/default">
									{title}
								</Text>
								<Text size="xs" color="text/secondary/default">
									{isAIChatPreview(data)
										? `Secoda AI Chat from ${owners[0]?.display_name || owners[0]?.email || 'Unknown User'}`
										: breadcrumbs}
								</Text>
							</Stack>
						</Group>
						{(description || hasOwners) && (
							<Stack className={classes.body}>
								{description && (
									<div className={classes.description}>
										{cleanedDescription}
									</div>
								)}
								{hasOwners && (
									<Group spacing="xs">
										<Avatar.Group>
											{owners.slice(0, 3).map((owner) => (
												<UserAvatar
													key={owner.id}
													user={owner as IUser}
													size="sm"
												/>
											))}
										</Avatar.Group>
										<Text size="sm" color="text/primary/default">
											{owners.length === 1
												? owners[0].display_name
												: pluralize(lowerCase('owner'), owners.length, true)}
										</Text>
									</Group>
								)}
							</Stack>
						)}
					</Stack>
				)}
			</HoverCard.Dropdown>
		</HoverCard>
	);
}
