import { Stack, TextInput, useMantineTheme } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useCallback, useContext, useEffect } from 'react';
import { AuthStepStartContext } from './AuthStepStartContext';

const REGEX_EMAIL = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;

interface AuthStepEmailComponentProps {
	cta: string;
}

export function AuthStepEmailComponent({ cta }: AuthStepEmailComponentProps) {
	const theme = useMantineTheme();

	const context = useContext(AuthStepStartContext);
	if (!context) {
		throw new Error(
			'AuthStepEmailComponent must be used within a AuthStepStartContextProvider'
		);
	}
	const {
		emailAuthLoading,
		startEmailAuth,
		forceSSO,
		email,
		setEmail,
		error,
		setError,
	} = context;

	const validateAndSendEmail = useCallback(
		(emailToValidate: string) => {
			setError(null);
			if (
				emailToValidate &&
				!isEmpty(emailToValidate) &&
				emailToValidate.match(REGEX_EMAIL)
			) {
				setEmail(emailToValidate);
				startEmailAuth();
				return true;
			} else {
				setError('Please enter a valid email address');
				return false;
			}
		},
		[setEmail, setError, startEmailAuth]
	);

	const handleClick = useCallback(() => {
		validateAndSendEmail(email);
	}, [email, validateAndSendEmail]);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const emailParam = params.get('email');

		if (!email && emailParam && !emailAuthLoading) {
			validateAndSendEmail(emailParam);
		}
	}, [email, emailAuthLoading, validateAndSendEmail]);

	return (
		<Stack pt={theme.other.space[2]}>
			{!forceSSO && (
				<>
					<TextInput
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleClick();
							}
						}}
						onChange={(e) => {
							setError(null);
							setEmail(e.currentTarget.value);
						}}
						value={email}
						type="email"
						label="Work email"
						error={!!error}
						styles={{ input: { borderRadius: theme.other.space[2] } }}
					/>
					<Button
						onClick={handleClick}
						w="100%"
						size="lg"
						loading={emailAuthLoading}
					>
						{cta}
					</Button>
				</>
			)}
			{error && (
				<Text weight="semibold" size="xs" color="text/critical/default">
					{error}
				</Text>
			)}
		</Stack>
	);
}
