import { useMemo } from 'react';
import type { MonitorJobResource } from '../../../../../../packages/api-codegen';
import { DateRenderer } from '../../../components/CatalogView/TableView/DateRenderer';
import {
	BoldTextRender,
	DurationRender,
	ReadOnlyBadgeRender,
} from '../../../components/TableV2/render';
import type { ExtendedDataTableColumn } from '../../../components/TableV2/types';
export function useColumns(): ExtendedDataTableColumn<MonitorJobResource>[] {
	const columns = useMemo(
		() => [
			{
				accessor: 'title',
				title: 'Name',
				render: (record: MonitorJobResource) => (
					<BoldTextRender<MonitorJobResource> record={record} field="title" />
				),
				width: 200,
			},
			{
				accessor: 'run_status',
				title: 'Status',
				render: (record) => (
					<ReadOnlyBadgeRender
						record={record}
						field="run_status"
						options={[
							{
								color: 'text/success/default',
								option: 'success',
								label: 'Success',
							},
							{
								color: 'text/critical/default',
								option: 'failed',
								label: 'Failed',
							},
							{
								color: 'text/info/default',
								option: 'running',
								label: 'Failed',
							},
							{
								color: 'text/critical/default',
								option: 'error',
								label: 'Error',
							},
						]}
						nilOption={{ color: 'text/secondary/default', option: 'unknown' }}
					/>
				),
				width: 120,
			},
			{
				accessor: 'run_started_at',
				title: 'Started',
				render: (record) => (
					<DateRenderer
						value={record.created_at}
						format="MMM DD, YYYY [at] hh:mm A"
						size="xs"
					/>
				),
				width: 160,
			},
			{
				accessor: 'run_duration',
				title: 'Duration',
				render: (record) => (
					<DurationRender<MonitorJobResource>
						record={record}
						field1={'run_started_at'}
						field2={'run_finished_at'}
					/>
				),
				width: 120,
			},
		],
		[]
	);
	return columns;
}
