import type { ESQueryModel } from '@repo/api-codegen';
import {
	FilterDropdownType,
	FilterOperator,
	FilterOptionType,
	FilterType,
} from '@repo/common/components/Filter/types';
import { UserRole } from '@repo/common/enums/UserRole';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { useCallback } from 'react';
import type { IBaseModel, ISecodaEntity } from '../../api';
import {
	usersGetItemsByIdFn,
	usersGetItemsFn,
} from '../../components/Filter/filterConfigUtils';
import { FeatureGuard } from '../FeatureGuard/FeatureGuard';
import { TableV2 } from '../TableV2';
import { useActions, useColumns, useEntityQueryList } from './hooks';

const FILTER_OPTIONS = [
	{
		label: 'User',
		type: FilterOptionType.OWNERS,
		field: 'user_id',
		filterDropdownConfig: {
			searchPlaceholder: 'Search users',
			multipleCountsSuffix: 'users',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Multiple,
			getItems: usersGetItemsFn,
			getItemsById: usersGetItemsByIdFn,
			hasIsNotSetOption: true,
		},
	},
	{
		label: 'Last Run Time',
		type: FilterOptionType.RUN_DATE,
		field: 'finished_at',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.Date,
			defaultOperator: FilterOperator.IsOnOrBefore,
			getItems: [],
		},
	},
];

export interface IQueryListProps {
	entity: ISecodaEntity;
}

export function QueryList({ entity }: IQueryListProps) {
	const columns = useColumns();
	const actions = useActions();
	const navigate = useNavigate();

	const entityQueryList = useEntityQueryList(entity?.id);

	const handleCellClick = useCallback(
		(cell) => {
			navigate(`/query/${cell.record.id}`);
		},
		[navigate]
	);

	return (
		<FeatureGuard
			featureName="Queries"
			v1AllowedRoles={[UserRole.VIEWER, UserRole.GUEST]}
			v2Permission="Queries.Read"
			isFeatureIncludedInPlan={true}
			isAlwaysIncludedFeature={false}
		>
			<TableV2<ESQueryModel & IBaseModel>
				pluralTypeString="queries"
				columns={columns}
				// @ts-ignore
				usePaginationList={entityQueryList}
				paginationSize={20}
				withSearch
				withCheckbox={false}
				withFilterOptions={FILTER_OPTIONS}
				withActions={actions}
				withStickyColumn={false}
				withInteractiveHeader
				defaultSort={null}
				onCellClick={handleCellClick}
			/>
		</FeatureGuard>
	);
}
