import { createStyles } from '@mantine/core';

export const useSpecVersionFormStyles = createStyles((theme) => ({
	input: {
		borderRadius: theme.radius.sm,
		borderColor: theme.other.getColor('border/input/default'),
		borderWidth: 0.5,
		backgroundColor: theme.other.getColor('surface/input/default'),
	},
	noBorder: {
		borderStyle: 'none',
	},
	inputRoot: {
		flex: 1,
		flexGrow: 1,
	},
	pictureDropzoneInner: {
		width: 35,
		height: 35,
	},
	icon: {
		color: theme.other.getColor('icon/primary/default'),
	},
	pydropzone: {
		borderRadius: theme.radius.sm,
		backgroundColor: theme.other.getColor('surface/tertiary/default'),
	},
	checkboxBody: {
		width: '100%',
	},
}));
