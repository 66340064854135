import { EntityType } from '@repo/common/enums/entityType';

// The entity types that are allowed to be children of an integration node
// We specifically set them as they are very visible and if phantom entities (i.e. tables without a parent)
// are created during integration syncs users will be confused by them.
export const CATALOG_TREE_INTEGRATION_NODE_CHILD_ENTITY_TYPES = [
	EntityType.database,
	EntityType.schema,
	EntityType.dashboard_group,
	EntityType.category,
	EntityType.job,
	EntityType.job_group,
];
