import { Box, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useApiListTeams } from '@repo/api-codegen';
import { Icon, IconButton } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useAuthUser } from '../../../api';
import { useRefs } from '../../../hooks/useRefs';
import { NewTeamModal } from '../../NewTeamModal/NewTeamModal';
import { SideBarCategoryToggle } from './SideBarCategoryToggle';
import SideBarLink from './SideBarLink';
import { sideBarStore } from './store';
import type { TeamSideBarItemHandle } from './TeamSideBarItem';
import { TeamSideBarItem } from './TeamSideBarItem';
import { useTeamSideBarItemStyle } from './TeamSideBarItem.styles';

function SideBarTeamsLinks() {
	const [
		createTeamModalOpened,
		{ close: closeCreateTeamModal, open: openCreateTeamModal },
	] = useDisclosure(false);

	const { isAdminUser, isGuestUser } = useAuthUser();

	const { data: teams } = useApiListTeams({
		queryParams: {
			only_joined: true,
		},
	});
	const { classes, cx } = useTeamSideBarItemStyle({
		open: true,
		sideBarCollapsed: !!sideBarStore.collapsed,
	});

	const { refs: teamMenuRefs, setRefFromKey } =
		useRefs<TeamSideBarItemHandle>();
	const navigate = useNavigate();

	const onTeamCreationFinish = (teamId: string) => {
		const teamMenuRef = teamMenuRefs[teamId];
		if (teamMenuRef) {
			teamMenuRef.open();
		}

		navigate(`/teams/${teamId}/`);
	};

	return (
		<Stack spacing={0}>
			<NewTeamModal
				opened={createTeamModalOpened}
				onClose={closeCreateTeamModal}
				onFinish={onTeamCreationFinish}
			/>
			<SideBarCategoryToggle
				label="Teams"
				labelRightSection={
					isAdminUser && (
						<IconButton
							onClick={openCreateTeamModal}
							className={cx(classes.hiddenButton, classes.displayOnHover)}
							iconName="plus"
							variant="tertiary"
							size="sm"
							data-testid="sidebar-create-team-button"
							tooltip="Create new team"
							tooltipProps={{ position: 'right', withArrow: true }}
							mr="4xs"
						/>
					)
				}
				toggleKey="your-teams-sidebar-collapsable-opened"
			>
				<Stack
					spacing={0}
					align={sideBarStore.collapsed ? 'center' : 'flex-start'}
				>
					<Box pb="5xs" w="100%">
						<SideBarLink
							link={{
								id: 'nav::teams',
								label: 'Browse teams',
								icon: <Icon name="users" color="icon/primary/default" />,
								href: ['/teams'],
								matchExact: true,
								show: !isGuestUser,
							}}
							selected={location.pathname === '/teams'}
						/>
					</Box>
					{teams?.results?.map((team) => (
						<TeamSideBarItem
							ref={setRefFromKey(team.id)}
							key={team.id}
							team={team}
							sideBarCollapsed={!!sideBarStore.collapsed}
						/>
					))}
				</Stack>
			</SideBarCategoryToggle>
		</Stack>
	);
}

export default observer(SideBarTeamsLinks);
