import {
	Box,
	createStyles,
	Divider,
	Group,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { Text } from '@repo/foundations';
import { typography } from '@repo/theme/primitives';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import type { INotification } from '../../../api/types/models/notification';
import { NotificationTitle } from '../NotificationTitle';
import InboxListItemIcon from './InboxListHeader/InboxListItemIcon';

interface IInboxListItemProps {
	item: INotification;
	selected: boolean;
	onSelect: (id: string, read: boolean) => void;
}

const useStyles = createStyles(
	(theme, { selected, read }: { selected: boolean; read: boolean }) => {
		let opacity = read ? 0.65 : 1;
		if (selected) {
			opacity = 1;
		}

		return {
			container: {
				backgroundColor: selected
					? theme.other.getColor('surface/primary/active')
					: 'transparent',
				borderRadius: theme.radius.sm,
				padding: theme.spacing.sm,
				'&:hover': {
					backgroundColor: selected
						? theme.other.getColor('surface/primary/active')
						: theme.other.getColor('surface/primary/hover'),
				},
				opacity,
			},
			indicator: {
				width: 8,
				height: 8,
				borderRadius: 8,
				backgroundColor: !read
					? theme.other.getColor('icon/emphasis/default')
					: 'transparent',
			},
			titleContainer: {
				width: '100%',
			},
			title: {
				fontSize: theme.fontSizes.sm,
				fontWeight: typography.weight.regular,
				lineHeight: '20px',
				display: '-webkit-box',
				WebkitLineClamp: 2,
				WebkitBoxOrient: 'vertical',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
			subtitle: {
				fontSize: theme.fontSizes.xs,
				fontWeight: typography.weight.regular,
				lineHeight: '16px',
				color: theme.other.getColor('text/secondary/default'),
			},
		};
	}
);

function InboxListItem({ item, selected, onSelect }: IInboxListItemProps) {
	const { classes } = useStyles({ selected, read: item.read });

	const subtitle = useMemo(() => {
		let date;

		if (dayjs(item.created_at).isBefore(dayjs().subtract(7, 'days'))) {
			date = dayjs(item.created_at).format('MMM D, YYYY');
		} else {
			date = dayjs(item.created_at).fromNow();
		}

		return date;
	}, [item]);

	const handleOnClick = () => {
		onSelect(item.id, item.read);
	};

	return (
		<Stack spacing={0} data-testid="inbox-list-item-5030e6174">
			<UnstyledButton onClick={handleOnClick}>
				<Group className={classes.container} spacing="xs" align="start" noWrap>
					<Group spacing="xs" noWrap>
						<Box className={classes.indicator} />
						<InboxListItemIcon item={item} />
					</Group>
					<Stack className={classes.titleContainer} spacing={4}>
						<Text className={classes.title}>
							<NotificationTitle notification={item} />
						</Text>
						<Box className={classes.subtitle}>{subtitle}</Box>
					</Stack>
				</Group>
			</UnstyledButton>
			<Divider mx={20} />
		</Stack>
	);
}

export default InboxListItem;
