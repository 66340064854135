import { useMutation } from '@tanstack/react-query';
import { deleteParamsFromUrl } from '../../../utils/url';
import { apiClient, getEndpoints } from '../../common';
import { NOTIFICATION_NAMESPACE } from './constants';
import { invalidateNotificationListQuery } from './utils';

export function useDeleteAllNotifications() {
	const mutationFn = async ({ events = '', read = false }) => {
		const url = getEndpoints(NOTIFICATION_NAMESPACE).byPath(['delete_all']);
		const params = read ? { events, read } : { events };

		const { data } = await apiClient.post(url, {}, { params });
		return data;
	};

	return useMutation({
		mutationFn,
		onSuccess: () => {
			deleteParamsFromUrl('id');
			invalidateNotificationListQuery();
		},
	});
}
