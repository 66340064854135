import type { PrimitivePermissionType } from '@repo/api-codegen';
import type { INotificationSettings } from '../../api/types/models/notification';
import type { FeatureFlags } from '../../utils/featureFlags/types';

export const notifications: Array<{
	attribute: keyof INotificationSettings;
	description: string;
	category: string;
	allowedTypes?: Array<'app' | 'email' | 'slack'>;
	featureFlag?: keyof FeatureFlags;
}> = [
	// General
	{
		attribute: 'notify_announcement',
		description: 'When a new announcement is posted',
		category: 'General',
	},
	{
		attribute: 'notify_mentions',
		description: 'When someone mentions you in content',
		category: 'General',
	},
	// Questions
	{
		attribute: 'notify_question_new',
		description: 'When a new question is posted',
		category: 'Questions',
	},
	{
		attribute: 'notify_question_owner_assigned',
		description: 'When you are assigned as an owner to a question',
		category: 'Questions',
	},
	{
		attribute: 'notify_question_reply',
		description: 'When someone replies to a question you follow',
		category: 'Questions',
	},
	{
		attribute: 'notify_question_reply_edited',
		description: 'When a reply to a question you follow is edited',
		category: 'Questions',
	},
	{
		attribute: 'notify_question_reply_removed',
		description: 'When a reply to a question you follow is deleted',
		category: 'Questions',
	},
	{
		attribute: 'notify_question_answer',
		description:
			'When an answer is marked as accepted for a question you follow',
		category: 'Questions',
	},
	// Resources
	{
		attribute: 'notify_resource_description_updated',
		description: 'When a resource description is modified',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_owner_assigned',
		description: 'When someone is designated as a resource owner',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_published',
		description: 'When a resource becomes publicly available',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_unpublished',
		description: 'When a resource is reverted to draft status',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_definition_updated',
		description: 'When resource documentation is revised',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_tag_added',
		description: 'When a tag is applied to a resource you follow',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_tag_removed',
		description: 'When a tag is removed from a resource you follow',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_collection_added',
		description: 'When a resource is included in a collection',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_collection_removed',
		description: 'When a resource is excluded from a collection',
		category: 'Resources',
	},
	{
		attribute: 'notify_change_request',
		description: 'When someone proposes changes to a resource',
		category: 'Resources',
	},
	{
		attribute: 'notify_applied_change_request',
		description: 'When proposed changes to a resource are implemented',
		category: 'Resources',
	},
	{
		attribute: 'notify_resource_archived',
		description: 'When a resource is permanently removed',
		category: 'Resources',
	},
	// Comments
	{
		attribute: 'notify_comment_new_thread',
		description: 'When a new comment thread starts in a document you follow',
		category: 'Comments',
	},
	{
		attribute: 'notify_comment_reply',
		description: 'When someone responds in a comment thread you participate in',
		category: 'Comments',
	},
	{
		attribute: 'notify_comment_mention',
		description: 'When your name is referenced in a comment',
		category: 'Comments',
	},
	{
		attribute: 'notify_comment_reaction',
		description: 'When someone reacts to one of your comments',
		category: 'Comments',
	},
	{
		attribute: 'notify_comment_resolved',
		description: 'When a comment thread you participate in is marked complete',
		category: 'Comments',
	},
	// Digest
	{
		attribute: 'secoda_weekly',
		description: 'Weekly summary of important activities and updates',
		category: 'Digest',
		allowedTypes: ['email'],
	},
	{
		attribute: 'secoda_monthly',
		description: 'Monthly digest of system activities and trends',
		category: 'Digest',
		allowedTypes: ['email'],
	},
	// Extractions
	{
		attribute: 'notify_extraction_job_failed',
		description: 'When a metadata extraction process encounters an error',
		category: 'Extractions',
	},
	{
		attribute: 'notify_extraction_job_completed',
		description: 'When a metadata extraction process completes successfully',
		category: 'Extractions',
	},
	{
		attribute: 'notify_table_schema_changed',
		description: 'When the structure of a table is modified',
		category: 'Extractions',
	},
	{
		attribute: 'notify_tables_dropped',
		description: 'When tables are removed from the database',
		category: 'Extractions',
	},
	// Metadata push
	{
		attribute: 'notify_metadata_push_job_failed',
		description: 'When metadata synchronization to external systems fails',
		category: 'Metadata push',
	},
	{
		attribute: 'notify_metadata_push_job_completed',
		description:
			'When metadata is successfully synchronized to external systems',
		category: 'Metadata push',
	},
	// Teams
	{
		attribute: 'notify_joined_team',
		description: 'When new members join one of your teams',
		category: 'Teams',
	},
	// Monitoring
	{
		attribute: 'notify_monitor_incident_created',
		description: 'When a system monitoring alert is triggered',
		category: 'Monitoring',
	},
	{
		attribute: 'notify_monitor_incident_resolved',
		description: 'When a system monitoring issue is fixed',
		category: 'Monitoring',
	},
	// Data Access Requests
	{
		attribute: 'notify_data_access_request_created',
		description: 'When someone submits a new data access request',
		category: 'Requests',
		featureFlag: 'dataAccessRequest',
	},
	{
		attribute: 'notify_data_access_request_approved',
		description:
			'When your data access request is approved by an administrator',
		category: 'Requests',
		featureFlag: 'dataAccessRequest',
	},
	{
		attribute: 'notify_data_access_request_approved_admin',
		description: 'When any data access request is approved (for admins only)',
		category: 'Requests',
		featureFlag: 'dataAccessRequest',
	},
	{
		attribute: 'notify_data_access_request_rejected',
		description: 'When a data access request is declined by an administrator',
		category: 'Requests',
		featureFlag: 'dataAccessRequest',
	},
	{
		attribute: 'notify_data_access_request_cancelled',
		description: 'When a data access request is withdrawn by the requester',
		category: 'Requests',
		featureFlag: 'dataAccessRequest',
	},
];

export const MINIMUM_REQUIRED_PERMISSIONS: PrimitivePermissionType[] = [
	'Integrations.Read',
	'Teams.Read',
	'Users.Read',
	'Resources.Read',
];
