import type { PermissionType } from '@repo/api-codegen';
import { UserRole } from '@repo/common/enums/UserRole';
import type { ISecodaEntity } from '../../api';
import { useAuthUser } from '../../api';
import { useCheckIAMPermission } from './roles';

const CONTACT_ADMIN_FOR_ACCESS_MESSAGE = 'Contact admin for access';
const UPGRADE_MESSAGE = 'Upgrade';

/**
 * Determines the appropriate access restriction message based on plan, role, and permissions
 *
 * @param options.isFeatureIncludedInPlan Whether the feature is included in the current plan
 * @param options.hasPermission Whether the user has permission to use this feature
 * @param options.isAlwaysIncludedFeature Whether this feature is always in base plan e.g. Monitors and Secoda AI
 * @returns The appropriate message to show or null if the feature is accessible
 */
export function useAccessRestrictionMessage({
	isFeatureIncludedInPlan,
	hasPermission,
	isAlwaysIncludedFeature = false,
}: {
	isFeatureIncludedInPlan: boolean;
	hasPermission: boolean;
	isAlwaysIncludedFeature?: boolean;
}): typeof UPGRADE_MESSAGE | typeof CONTACT_ADMIN_FOR_ACCESS_MESSAGE | null {
	const { user } = useAuthUser();

	// If the user has permission and the feature is included in the plan, no message needed
	if (hasPermission && (isFeatureIncludedInPlan || isAlwaysIncludedFeature)) {
		return null;
	}

	// Check if user has a default role or a custom role
	const isDefaultRole = Object.values(UserRole).includes(user.role as UserRole);
	const isAdminOrEditor =
		user.role === UserRole.ADMIN || user.role === UserRole.EDITOR;

	// For features that are always included (Monitors and Secoda AI)
	if (isAlwaysIncludedFeature) {
		// If user doesn't have permission, always show "Contact admin for access"
		return CONTACT_ADMIN_FOR_ACCESS_MESSAGE;
	}

	// For standard features
	if (!isFeatureIncludedInPlan) {
		// Default roles (Admin/Editor) - Show "Upgrade"
		if (isDefaultRole && isAdminOrEditor) {
			return UPGRADE_MESSAGE;
		}
		// Default roles (Viewer/Guest) or Custom roles - Show "Contact admin for access"
		return CONTACT_ADMIN_FOR_ACCESS_MESSAGE;
	}

	// Feature is in plan but user doesn't have permission
	return CONTACT_ADMIN_FOR_ACCESS_MESSAGE;
}

/**
 * Combined function that checks both IAM permissions and feature access in one call
 *
 * @param options.v1AllowedRoles Roles allowed in v1 permission system
 * @param options.v2Permission Permission type for v2 permission system
 * @param options.isFeatureIncludedInPlan Whether the feature is included in the current plan
 * @param options.isAlwaysIncludedFeature Whether this feature is always in base plan e.g. Monitors and Secoda AI
 * @param options.entityId Optional entity ID for permission check
 * @param options.entity Optional entity object for permission check
 * @param options.defaultResult Default result for permission check
 * @param options.onlyShowForCustomRoles Whether to only show restriction messages for users with UserRole.CUSTOM
 * @returns Object containing hasPermission boolean and restrictionMessage string or null
 */
export function useFeatureAccessCheck({
	v1AllowedRoles,
	v2Permission,
	isFeatureIncludedInPlan,
	isAlwaysIncludedFeature = false,
	entityId,
	entity,
	defaultResult = false,
	onlyShowForCustomRoles = true,
}: {
	v1AllowedRoles: Array<UserRole>;
	v2Permission: PermissionType;
	isFeatureIncludedInPlan: boolean;
	isAlwaysIncludedFeature?: boolean;
	entityId?: string;
	entity?: ISecodaEntity;
	defaultResult?: boolean;
	onlyShowForCustomRoles?: boolean;
}): {
	hasPermission: boolean;
	restrictionMessage:
		| typeof UPGRADE_MESSAGE
		| typeof CONTACT_ADMIN_FOR_ACCESS_MESSAGE
		| null;
	isLoading: boolean;
} {
	const { user } = useAuthUser();
	const { hasPermission, isLoading } = useCheckIAMPermission({
		v1AllowedRoles,
		v2Permission,
		entityId,
		entity,
		defaultResult,
	});

	// Get the base restriction message
	const baseRestrictionMessage = useAccessRestrictionMessage({
		isFeatureIncludedInPlan,
		hasPermission,
		isAlwaysIncludedFeature,
	});

	// check if the user has the custom role
	if (onlyShowForCustomRoles && baseRestrictionMessage) {
		// Only show restriction message if user has UserRole.CUSTOM
		if (user.role !== UserRole.CUSTOM) {
			return {
				hasPermission,
				restrictionMessage: null,
				isLoading,
			};
		}
	}

	return {
		hasPermission,
		restrictionMessage: baseRestrictionMessage,
		isLoading,
	};
}
