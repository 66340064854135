import { Anchor, Box, createStyles, Stack } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';
import { usePlan } from '../../hooks/usePlans';
import type { IntegrationSpec } from '../../interfaces/IntegrationSpec';
import { formatIntegrationType } from '../../utils/stringUtils';

interface IIntegrationHelpProps {
	spec: IntegrationSpec;
}

const useStyles = createStyles((theme) => ({
	title: {
		display: 'flex',
		alignItems: 'center',
		gap: 5,
		marginBottom: theme.spacing.xs,
	},
	body: { display: 'flex', gap: 3 },
}));

export function IntegrationHelp({ spec }: IIntegrationHelpProps) {
	const { classes } = useStyles();
	const { trial } = usePlan();
	const { boot, show } = useIntercom();

	const openIntercom = useCallback(async () => {
		await boot();
		show();
	}, [boot, show]);

	const helpArticle =
		spec.type === 'builtin' ? spec.value.helpArticle : undefined;
	const type = spec.type === 'builtin' ? spec.value.type : undefined;

	if (!helpArticle || !type) {
		return null;
	}

	return (
		<Box my={24}>
			<Box className={classes.title}>
				<Icon name="lifebuoy" />
				<Text weight="semibold" size="sm">
					Need help?
				</Text>
			</Box>
			<Stack className={classes.body}>
				<Anchor target="_blank" href={helpArticle} rel="noreferrer" size="sm">
					{formatIntegrationType(type)} docs
				</Anchor>
				{!trial && (
					<Anchor href="#intercom" size="sm" onClick={openIntercom}>
						Contact support
					</Anchor>
				)}
			</Stack>
		</Box>
	);
}
