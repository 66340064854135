import { useMutation } from '@tanstack/react-query';
import { forEach } from 'lodash-es';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import type {
	DeleteRequestParams,
	IBaseModel,
	IBaseModelDeleteArgs,
	Namespace,
} from '../../types';

export function getDefaultDeleteFn<
	TApiResponseData extends IBaseModel,
	TRequestData extends DeleteRequestParams = DeleteRequestParams,
>(namespace: Namespace) {
	const mutationFn = async ({ id, signal }: TRequestData) => {
		const url = getEndpoints(namespace).byId(id);

		const { data: responseData } = await apiClient.delete<TApiResponseData>(
			url,
			{ signal }
		);
		return responseData;
	};

	return mutationFn;
}

function useDeleteBaseModel<
	TApiResponseData extends IBaseModel,
	TRequestData extends DeleteRequestParams = DeleteRequestParams,
	TContext = unknown,
	TError = Error,
>({
	namespace,
	mutationFn: customMutationFn,
	invalidationKeys = [],
	options = {},
}: IBaseModelDeleteArgs<TApiResponseData, TRequestData, TContext, TError>) {
	const mutationFn =
		customMutationFn ||
		getDefaultDeleteFn<TApiResponseData, TRequestData>(namespace);

	return useMutation({
		mutationFn,
		...options,
		onSettled: (
			data: TApiResponseData | undefined,
			error: TError | null,
			variables: TRequestData,
			context: TContext | undefined
		) => {
			if (error) {
				// Invoke the custom onError function if it exists
				options?.onSettled?.(data, error, variables, context);

				return;
			}

			// Invalidate the cache of all given invalidation keys
			forEach(invalidationKeys, (invalidationKey) => {
				queryClient.invalidateQueries({ queryKey: invalidationKey });
			});

			options?.onSettled?.(data, error, variables, context);
		},
	});
}

export default useDeleteBaseModel;
