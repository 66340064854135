import { showNotification } from '@mantine/notifications';
import { Icon } from '@repo/foundations';
import type { MutationFunction } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import type { ISecodaEntity } from '../../types';
import { metricsQueryKeyFactory } from '../metricTerm';

async function createDuplicateResult(entity: ISecodaEntity) {
	const url = getEndpoints(metricsQueryKeyFactory.namespace).byPath([
		entity.id,
		'duplicate',
	]);

	const response = await apiClient.post<ISecodaEntity>(url);

	return response.data;
}

/**
 * A react-query mutation hook that can be used to duplicate a Secoda entity.
 *
 * @returns Returns a react-query mutation hook that can be used to duplicate a Secoda entity
 */
export function useDuplicateMetric() {
	const mutationFn: MutationFunction<
		ISecodaEntity,
		{ entity: ISecodaEntity }
	> = async ({ entity }) => createDuplicateResult(entity);

	return useMutation({
		mutationFn,
		onError: () => {
			showNotification({
				title: 'Error duplicating metric',
				message: 'If the issues persists, please contact support',
				color: 'red',
				icon: <Icon name="alertCircle" />,
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: metricsQueryKeyFactory.allLists(),
			});
		},
	});
}
