import { colors } from '@repo/theme/primitives';
import type { IIntegrationMetric, IMetricWidgetSample } from '../../../../api';
import { MetricName, useIntegrationMetricList } from '../../../../api';
import { WidgetType } from '../../../../interfaces';
import { LOOKBACK_KEY, useChartColors } from '../../utils/utils';
import { WORKSPACE_ANALYTICS_ALLOWED_WIDGETS_TO_ADD } from './constants';

type IMetricWidgetSampleVariant = Partial<IMetricWidgetSample> &
	Partial<IMetricWidgetSample['metric_metadata']>;

const baseMetricWidgetMetadata = (
	metric: IIntegrationMetric
): Partial<IMetricWidgetSample['metric_metadata']> => ({
	integration_id: metric.integration_id,
	integration_type: metric.integration_type,
	metric_id: metric.id,
	fixed_filter: {
		[LOOKBACK_KEY]: 'weekly',
	},
	user_filter: {},
	filter_options: metric.filter_options,
});

const genTestWidgets = (metric: IIntegrationMetric) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Test Execution Time',
			group: 'Costs',
			groupIconName: 'coin',
			value: { execution_time: 'avg' },
			suffix: ' seconds',
			type: WidgetType.METRIC_LINE_CHART,
		},
		{
			title: 'Test Results',
			value: {
				fail: 'sum',
				pass: 'sum',
				error: 'sum',
				skipped: 'sum',
				warn: 'sum',
			},
		},
		{
			title: 'Test Failures',
			value: { fail: 'sum' },
		},
		{
			title: 'Test Passes',
			value: { pass: 'sum' },
		},
		{
			title: 'Test Errors',
			value: { error: 'sum' },
		},
		{
			title: 'Test Skips',
			value: { skipped: 'sum' },
		},
		{
			title: 'Test Warnings',
			value: { warn: 'sum' },
		},
	];
	return variants.map(
		(variant) =>
			({
				group: variant.group || 'Data quality',
				groupIconName: variant.groupIconName || 'ribbonHealth',
				type: variant?.type || WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.DBT_TEST_RESULTS,

					suffix: variant?.suffix || ' tests',
					value: variant.value,
					value_color: {
						execution_time: colors.cyan[4],
						fail: colors.orange[4],
						pass: colors.green[4],
						error: colors.red[4],
						skipped: colors.gray[4],
						warn: colors.yellow[4],
					},
				},
			}) as IMetricWidgetSample
	);
};

const genModelTestWidgets = (metric: IIntegrationMetric) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Model Test Coverage',
			value: { has_test: 'max' },
		},
		{
			title: 'Model Test Failures',
			value: { test_failed: 'sum' },
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Tests',
				groupIconName: 'ribbonHealth',
				type: variant?.type || WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.DBT_TABLE_TEST_RESULTS,
					suffix: 'has_test' in (variant.value ?? {}) ? '' : ' models',
					value: variant.value,
					is_percentage: 'has_test' in (variant.value ?? {}) ? true : false,
				},
			}) as IMetricWidgetSample
	);
};

const genSnowflakeCostWidgets = (
	metric: IIntegrationMetric,
	chartColors: string[]
) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Snowflake Costs',
			value: { cost: 'sum' },
			prefix: '$',
		},
		{
			title: 'Snowflake Costs Total',
			value: { cost: 'sum' },
			prefix: '$',
			type: WidgetType.METRIC_SINGLE_VALUE,
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Costs',
				groupIconName: 'coin',
				type: variant.type || WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.SNOWFLAKE_COST,
					value: variant.value,
					value_color: {
						cost: chartColors[0],
					},
					prefix: variant.prefix,
				},
			}) as IMetricWidgetSample
	);
};

export const genSnowflakeCreditPerWarehouseWidgets = (
	metric: IIntegrationMetric,
	chartColors: string[]
) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Snowflake Warehouse Metering History',
			value: { compute_credits: 'sum', cloud_services_credits: 'sum' },
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Costs',
				groupIconName: 'coin',
				type: WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.SNOWFLAKE_CREDITS_PER_WAREHOUSE,
					value: variant.value,
					value_color: {
						compute_credits: chartColors[0],
						cloud_services_credits: chartColors[1],
					},
					suffix: ' credits',
				},
			}) as IMetricWidgetSample
	);
};

export const genSnowflakeCreditPerUserWidgets = (
	metric: IIntegrationMetric
) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Snowflake User Query Credits',
			value: { credits: 'sum' },
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Costs',
				groupIconName: 'coin',
				type: WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.SNOWFLAKE_CREDITS_PER_USER,
					value: variant.value,
					suffix: ' credits',
				},
			}) as IMetricWidgetSample
	);
};

export const genSnowflakeQueryVolumeWidgets = (metric: IIntegrationMetric) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Snowflake Query Volume',
			value: { query_count: 'sum' },
		},
		{
			title: 'Snowflake Average Daily Query Volume',
			value: { query_count: 'avg' },
			type: WidgetType.METRIC_SINGLE_VALUE,
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Costs',
				groupIconName: 'coin',
				type: variant.type || WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.SNOWFLAKE_QUERY_VOLUME,
					value: variant.value,
					suffix: ' queries',
				},
			}) as IMetricWidgetSample
	);
};

export const genSnowflakeStorageCreditsWidgets = (
	metric: IIntegrationMetric,
	chartColors: string[]
) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Snowflake Storage Credits Usage',
			value: { average_database_bytes: 'sum', average_failsafe_bytes: 'sum' },
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Costs',
				groupIconName: 'coin',
				type: variant.type || WidgetType.METRIC_LINE_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					is_bytes: true,
					metric_name: MetricName.SNOWFLAKE_STORAGE_USAGE,
					value: variant.value,
					value_color: {
						average_database_bytes: chartColors[0],
						average_failsafe_bytes: chartColors[1],
					},
				},
			}) as IMetricWidgetSample
	);
};

export const genSnowflakeComputeCreditsWidgets = (
	metric: IIntegrationMetric,
	chartColors: string[]
) => {
	const variants: IMetricWidgetSampleVariant[] = [
		{
			title: 'Snowflake Compute Credits Usage',
			value: {
				compute_credits: 'sum',
				cloud_services_credits: 'sum',
				adjustment_cloud_services_credits: 'sum',
			},
		},
	];
	return variants.map(
		(variant) =>
			({
				group: 'Costs',
				groupIconName: 'coin',
				type: variant?.type || WidgetType.METRIC_BAR_CHART,
				title: variant.title,

				metric_metadata: {
					...baseMetricWidgetMetadata(metric),
					metric_name: MetricName.SNOWFLAKE_COMPUTE_USAGE,
					value: variant.value,
					value_color: {
						compute_credits: chartColors[0],
						cloud_services_credits: chartColors[1],
						adjustment_cloud_services_credits: chartColors[2],
					},
				},
			}) as IMetricWidgetSample
	);
};

function sortAlphabetically(
	items: IMetricWidgetSample[]
): IMetricWidgetSample[] {
	return items.sort((a, b) => {
		const titleA = (typeof a === 'string' ? a : a.title).toLowerCase();
		const titleB = (typeof b === 'string' ? b : b.title).toLowerCase();
		return titleA.localeCompare(titleB);
	});
}

export const useWidgetsToAdd = () => {
	const chartColors = useChartColors();
	const { data: metrics } = useIntegrationMetricList({
		options: {
			select: (data) => data.results,
		},
	});

	let widgetSamples = [...WORKSPACE_ANALYTICS_ALLOWED_WIDGETS_TO_ADD];

	// TODO: cleanup logic https://github.com/secoda/secoda/pull/2091#discussion_r1388179528
	const testMetric = metrics?.find(
		(metric) => metric.metric_type === 'dbt_test'
	);

	if (testMetric) {
		widgetSamples = widgetSamples.concat(genTestWidgets(testMetric));
	}

	const tableTestMetric = metrics?.find(
		(metric) => metric.metric_type === 'dbt_table_test'
	);

	if (tableTestMetric) {
		widgetSamples = widgetSamples.concat(genModelTestWidgets(tableTestMetric));
	}

	const snowflakeCostMetric = metrics?.find(
		(metric) => metric.metric_type === 'snowflake_cost'
	);

	if (snowflakeCostMetric) {
		widgetSamples = widgetSamples.concat(
			genSnowflakeCostWidgets(snowflakeCostMetric, chartColors)
		);
	}

	const snowflakeCreditsPerWarehouseMetric = metrics?.find(
		(metric) => metric.metric_type === 'snowflake_credits_per_warehouse'
	);

	if (snowflakeCreditsPerWarehouseMetric) {
		widgetSamples = widgetSamples.concat(
			genSnowflakeCreditPerWarehouseWidgets(
				snowflakeCreditsPerWarehouseMetric,
				chartColors
			)
		);
	}

	const snowflakeCreditsPerUserMetric = metrics?.find(
		(metric) => metric.metric_type === 'snowflake_credits_per_user'
	);

	if (snowflakeCreditsPerUserMetric) {
		widgetSamples = widgetSamples.concat(
			genSnowflakeCreditPerUserWidgets(snowflakeCreditsPerUserMetric)
		);
	}

	const snowflakeQueryVolumeMetric = metrics?.find(
		(metric) => metric.metric_type === 'snowflake_query_volume'
	);

	if (snowflakeQueryVolumeMetric) {
		widgetSamples = widgetSamples.concat(
			genSnowflakeQueryVolumeWidgets(snowflakeQueryVolumeMetric)
		);
	}

	const snowflakeStorageCreditsMetric = metrics?.find(
		(metric) => metric.metric_type === 'snowflake_storage_usage'
	);

	if (snowflakeStorageCreditsMetric) {
		widgetSamples = widgetSamples.concat(
			genSnowflakeStorageCreditsWidgets(
				snowflakeStorageCreditsMetric,
				chartColors
			)
		);
	}

	const snowflakeComputeCreditsMetric = metrics?.find(
		(metric) => metric.metric_type === 'snowflake_compute_usage'
	);

	if (snowflakeComputeCreditsMetric) {
		widgetSamples = widgetSamples.concat(
			genSnowflakeComputeCreditsWidgets(
				snowflakeComputeCreditsMetric,
				chartColors
			)
		);
	}

	return sortAlphabetically(widgetSamples);
};
