import { createStyles, Group } from '@mantine/core';
import type { ActivityLogOut } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { EventBlock } from '../EventBlock/EventBlock';

interface ResourceLogItemProps {
	log: ActivityLogOut;
}

const useStyles = createStyles({
	wrapper: {
		whiteSpace: 'pre',
		gap: 0,
	},
});

export function ResourceLogItem({ log }: ResourceLogItemProps) {
	const { classes } = useStyles();
	const performer =
		log.performer_metadata?.name ?? log.performer_metadata?.display_name;
	const formattedDate = dayjs(log.logged_at).format('MMM D [at] h:mm A');

	return (
		<Group className={classes.wrapper}>
			{performer && (
				<Text size="sm" weight="bold" span>
					{`${performer} `}
				</Text>
			)}
			{log.event_blocks.map((block, index) => (
				<EventBlock
					// eslint-disable-next-line react/no-array-index-key
					key={index}
					block={block}
					capitalizeFirst={isNil(performer) && index === 0}
					last={index === log.event_blocks.length - 1}
				/>
			))}
			<Text size="sm" color="text/secondary/default" span>
				{` · ${formattedDate}`}
			</Text>
		</Group>
	);
}
