import { Divider, Flex, Skeleton, Stack } from '@mantine/core';
import { PlainButton, Text, Title } from '@repo/foundations';
import { isNil, size } from 'lodash-es';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router';
import { AuthCard } from './AuthCard';
import { AuthLayoutBase } from './AuthLayouts/AuthLayoutBase';
import { AuthStepEmailComponent } from './AuthStepEmailComponent';
import { AuthStepSsoComponent } from './AuthStepSsoComponent';
import { AuthStepStartContext } from './AuthStepStartContext';
import { AuthTermsAndPolicy } from './AuthTermsAndPolicy';

export function AuthStepSignup() {
	const context = useContext(AuthStepStartContext);
	if (!context) {
		throw new Error(
			'AuthStepSignup must be used within a AuthStepStartContextProvider'
		);
	}
	const navigate = useNavigate();
	const { setError, setEmail, resetSSO, forceSSO, supportedMethods } = context;

	const handleSwitchToLogin = useCallback(() => {
		setError(null);
		navigate('/auth');
	}, [navigate, setError]);

	const handleBackToRegister = useCallback(() => {
		setError(null);
		setEmail('');
		resetSSO();
	}, [setError, setEmail, resetSSO]);

	return (
		<AuthLayoutBase>
			<Flex direction="column" gap="xl">
				<AuthCard>
					<Title order={1}>Sign up for Secoda</Title>
					<Flex direction="column" py="xl">
						{isNil(supportedMethods) ? (
							<Stack spacing={'xs'}>
								<Skeleton height={36} radius="sm" />
								<Skeleton height={36} radius="sm" />
								<Skeleton height={36} radius="sm" />
								<Skeleton height={36} radius="sm" />
								<Skeleton height={36} radius="sm" />
							</Stack>
						) : (
							<>
								<AuthStepSsoComponent supportedMethods={supportedMethods} />
								{supportedMethods?.includes('email') && (
									<>
										{size(supportedMethods) > 1 && (
											<Divider
												hidden={!!forceSSO}
												label={<Text color="text/secondary/default">OR</Text>}
												labelPosition="center"
											/>
										)}
										<AuthStepEmailComponent cta="Log in with email" />
									</>
								)}
							</>
						)}
					</Flex>
					<AuthTermsAndPolicy />
				</AuthCard>

				{forceSSO ? (
					<PlainButton onClick={handleBackToRegister} size="md">
						Back to signup
					</PlainButton>
				) : (
					<Flex gap="xs" justify="center">
						<Text size="md">Already have an account?</Text>
						<PlainButton
							onClick={handleSwitchToLogin}
							rightIconName="arrowRight"
							size="md"
						>
							Log in
						</PlainButton>
					</Flex>
				)}
			</Flex>
		</AuthLayoutBase>
	);
}
