import { Box, Skeleton } from '@mantine/core';
import type { ItemIconType } from '@repo/common/components/ItemIcon/ItemIcon';
import MultiSelector from '@repo/common/components/MultiSelector/MultiSelector';
import { SELECTABLE_PROPERTY_OPTIONS } from '@repo/common/components/SelectableProperty/constants';
import type { SelectablePropertyType } from '@repo/common/components/SelectableProperty/types';
import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import type { ButtonVariants } from '@repo/foundations';
import type { IconNames } from '@repo/foundations/components/Icon/Icon';
import { isNil, reject } from 'lodash-es';
import { useCallback, type ReactNode } from 'react';
import IntegrationTooltipSelectorItem from '../RichTooltip/IntegrationTooltipSelectorItem';

export interface PropertySelectorProps {
	variant?: ButtonVariants;
	selected: boolean | string | string[];
	inheritedValues?: string[];
	inheritedValuesTooltip?: string;
	type: 'single' | 'multi' | 'tags';
	value: SelectablePropertyType;
	iconType: ItemIconType;
	options?: SelectablePropertyItem[];
	permittedId?: string;
	isMenuItemBadge?: boolean;
	isViewerUser: boolean;
	searchable?: boolean;
	readOnly?: boolean;
	placeholder?: string;
	placeholderIconName?: IconNames;
	onChange?: (
		value: string | string[] | boolean | number
	) => void | Record<string, (value: boolean | string[]) => void>;
	displayIcon?: boolean;
	emptyState?: ReactNode;
	onSearchChange?: (value: string) => void;
	itemSize?: number;
	isLoading?: boolean;
}

export function PropertySelector({
	variant,
	selected,
	type,
	value,
	iconType,
	options,
	permittedId = '',
	isViewerUser,
	isMenuItemBadge = false,
	searchable = false,
	onChange,
	inheritedValues,
	inheritedValuesTooltip,
	readOnly = false,
	displayIcon = true,
	placeholder,
	placeholderIconName,
	emptyState,
	onSearchChange,
	itemSize,
	isLoading = false,
}: PropertySelectorProps) {
	const handleSingleChange = useCallback(
		(newValue: boolean | string | string[] | number) => {
			if (onChange) {
				onChange(newValue);
			}
		},
		[onChange]
	);

	const handleMultiChange = useCallback(
		(newValues: (string | boolean)[]) => {
			const updatedValues = reject(newValues as string[], isNil);
			if (onChange) {
				onChange(updatedValues);
			}
		},
		[onChange]
	);

	if (isLoading) {
		return <Skeleton height={28} width={100} />;
	}

	return (
		<Box>
			{type === 'single' && (
				<SingleSelector
					variant={variant}
					initialSelected={selected as string}
					property={value}
					iconType={iconType}
					placeholder={placeholder}
					placeholderIconName={placeholderIconName}
					options={options || SELECTABLE_PROPERTY_OPTIONS[value]}
					isViewerUser={isViewerUser}
					searchable={searchable}
					onChange={handleSingleChange}
					readOnly={readOnly}
					displayIcon={displayIcon}
					emptyState={emptyState}
					onSearchTermChange={onSearchChange}
					itemSize={itemSize}
				/>
			)}
			{type === 'multi' && (
				<MultiSelector
					inheritedValues={inheritedValues}
					inheritedValuesTooltip={inheritedValuesTooltip}
					initialSelected={selected as string[]}
					property={value}
					iconType={iconType}
					placeholder={placeholder}
					placeholderIconName={placeholderIconName}
					options={options || []}
					permittedId={permittedId}
					isMenuItemBadge={isMenuItemBadge}
					isViewerUser={isViewerUser}
					onChange={handleMultiChange}
					readOnly={readOnly}
					emptyState={emptyState}
					onSearchTermChange={onSearchChange}
					selectorItem={
						value === 'owners' ? IntegrationTooltipSelectorItem : undefined
					}
					itemSize={itemSize}
				/>
			)}
		</Box>
	);
}
