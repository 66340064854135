import { useMemo } from 'react';
import {
	useAuthUser,
	type IIntegration,
	type ISecodaEntity,
	type IUser,
} from '../../api';
import {
	useExtendedUserList,
	useGuestUserList,
} from '../../api/hooks/user/useExtendedUserList';
import type { DjangoValueType } from '../../pages/TemplatePage/types';
import type { SidebarEntityKeys } from '../EntityPageLayout/types';
import { getOwnerAndGroupSelectorOptions } from '../EntityPageLayout/utils';
import { externalUsers } from '../UserAvatar/helpers';
import type { PropertySelectorProps } from './PropertySelector';
import { PropertySelector } from './PropertySelector';

export interface OwnersPropertySelectorProps
	extends Pick<PropertySelectorProps, 'onChange'> {
	entity: ISecodaEntity;
	viewerOfEntity: boolean;
	integration?: IIntegration;
	handleEntityChange: (
		key: SidebarEntityKeys
	) => (value: DjangoValueType, saveRemotely?: boolean) => void;
	preventNavigate?: boolean;
}

export function OwnersPropertySelector({
	entity,
	viewerOfEntity,
	integration,
	handleEntityChange,
	preventNavigate = false,
	...propertySelectorProps
}: OwnersPropertySelectorProps) {
	const { isGuestUser } = useAuthUser();

	const {
		userGroups: groups,
		disabledUsers,
		activeUsers,
		serviceAccountsActive,
		isLoading: isUserLoading,
	} = useExtendedUserList();
	const { usersVisibleToGuests, isLoading: isUsersVisibleToGuestsLoading } =
		useGuestUserList();

	const maintainOwners = !!integration?.credentials?.import_owners_preference;
	const noOwners = maintainOwners && entity.owners.length === 0;

	const serviceAccountOptions = useMemo(
		() =>
			(serviceAccountsActive?.filter((serviceAccount) =>
				entity.owners.includes(serviceAccount.id)
			) as unknown as IUser[]) ?? [],
		[entity.owners, serviceAccountsActive]
	);

	const ownerOptions = useMemo(
		() =>
			isGuestUser
				? ((usersVisibleToGuests ?? []) as unknown as IUser[])
				: [
						...((disabledUsers ?? []) as unknown as IUser[]),
						...((activeUsers ?? []) as unknown as IUser[]),
						...serviceAccountOptions,
						...externalUsers(
							activeUsers ?? [],
							serviceAccountsActive ?? [],
							entity
						),
					].filter((owner) =>
						maintainOwners ? entity.owners.includes(owner.id) : true
					),
		[
			activeUsers,
			disabledUsers,
			entity,
			isGuestUser,
			maintainOwners,
			serviceAccountOptions,
			serviceAccountsActive,
			usersVisibleToGuests,
		]
	);

	const owners = useMemo(
		() =>
			getOwnerAndGroupSelectorOptions(
				ownerOptions,
				!maintainOwners && groups ? groups : [],
				handleEntityChange('owners'),
				handleEntityChange('owners_groups')
			).map((owner) => ({
				...owner,
				itemTypeName: 'owners',
				entityIntegrationId: integration?.id,
				maintainProp: maintainOwners,
				navigateTo: preventNavigate ? undefined : owner.navigateTo,
			})),
		[
			groups,
			handleEntityChange,
			integration?.id,
			maintainOwners,
			ownerOptions,
			preventNavigate,
		]
	);

	const selectedOwners = useMemo(
		() => [...(entity?.owners ?? []), ...(entity?.owners_groups ?? [])],
		[entity]
	);

	const serviceAccountOwners = useMemo(
		() =>
			entity.owners.filter((owner) =>
				serviceAccountsActive?.find(
					(serviceAccount: { id: string }) => serviceAccount.id === owner
				)
			),
		[entity.owners, serviceAccountsActive]
	);

	return (
		<PropertySelector
			isLoading={isUserLoading || isUsersVisibleToGuestsLoading}
			readOnly={noOwners || viewerOfEntity}
			selected={selectedOwners}
			inheritedValues={maintainOwners ? serviceAccountOwners : undefined}
			type="multi"
			value="owners"
			iconType="avatar"
			isViewerUser={viewerOfEntity}
			options={owners}
			placeholder={noOwners || viewerOfEntity ? 'No owners' : 'Add owner'}
			{...propertySelectorProps}
		/>
	);
}
