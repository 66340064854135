import { Anchor, Flex, Stack, Tabs, Textarea } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import TabsList from '@repo/common/components/TabsList';
import { Banner, Button, Text } from '@repo/foundations';
import type { DataTableColumn } from '@repo/mantine-datatable';
import ed25519 from 'ed25519-keygen/ssh';
import { randomBytes } from 'ed25519-keygen/utils';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import type { ITunnel } from '../../api';
import {
	queryClient,
	tunnelsQueryKeyFactory,
	useCreateTunnel,
	useTunnelList,
	useUpdateTunnel,
} from '../../api';
import { usePublicEnv } from '../../api/hooks/utils/usePublicEnv';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import { EmptyState } from '../../components/EmptyState';
import { closeAllModals, openModal } from '../../components/ModalManager';
import { UpgradeButton } from '../../components/Settings/UpgradeButton';
import type { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import TableV2 from '../../components/TableV2/TableV2';
import DeleteWithWarningModal from '../IntegrationsPage/DeleteWithWarningModal';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { TUNNEL_TEMPLATE } from './constants';

type TunnelListPageTab = 'forward' | 'reverse';

const forwardColumns = [
	{ accessor: 'host', title: 'Host', sortable: false },
	{ accessor: 'port', title: 'Port', sortable: false },
	{ accessor: 'username', title: 'Username', sortable: false },
	{
		accessor: 'status',
		title: 'Status',
		sortable: false,

		render: (record: { status: boolean }) =>
			record.status ? 'Connected' : 'Disconnected',
	},
	{
		accessor: 'created_at',
		title: 'Created at',
		sortable: false,

		render: (record: { created_at: string }) =>
			moment(record.created_at).format('YYYY-MM-DD'),
	},
];

const reverseColumns = [
	{ accessor: 'port', title: 'Port' },
	{
		accessor: 'status',
		title: 'Status',
		sortable: false,
		render: (record: { status: boolean }) =>
			record.status ? 'Connected' : 'Disconnected',
	},
	{
		accessor: 'created_at',
		sortable: false,

		title: 'Created at',
		render: (record: { created_at: string }) =>
			moment(record.created_at).format('YYYY-MM-DD'),
	},
];

function TunnelsListPage() {
	const clipboard = useClipboard();
	const [activeTab, setActiveTab] = useState<TunnelListPageTab>('forward');
	const { classes, theme } = useStyles();
	const navigate = useNavigate();
	const { mutateAsync: createTunnel } = useCreateTunnel({});
	const { mutateAsync: updateTunnel } = useUpdateTunnel({});
	const { data: publicEnv } = usePublicEnv();
	const { tunnelAccess } = useFeatureAccess();

	const handleDelete = useCallback(
		async (tunnels: ITunnel[], onClose?: () => void) => {
			openModal({
				variant: 'default',
				title: 'Delete tunnel',
				children: (
					<DeleteWithWarningModal
						text="This will permanently remove this tunnel, and remove it from any integrations."
						onConfirm={async () => {
							// We do not delete, but set `archived` to `true`.
							// This is because we need to make sure the reverse tunnel ports are not reused.
							await Promise.all(
								tunnels.map(async (tunnel) => {
									await updateTunnel({
										data: {
											id: tunnel.id,
											archived: true,
										},
									});
								})
							);

							closeAllModals();
							queryClient.invalidateQueries({
								queryKey: tunnelsQueryKeyFactory.allLists(),
							});
							onClose?.();
						}}
						onClose={closeAllModals}
					/>
				),
			});
		},
		[updateTunnel]
	);

	const handleNewForwardTunnel = useCallback(async () => {
		navigate('/tunnels/new');
	}, [navigate]);

	const handleNewReverseTunnel = useCallback(async () => {
		const sseed = randomBytes(32);
		const skeys = await ed25519(sseed, 'ssh@secoda.co');

		const tunnel = await createTunnel({
			data: {
				public_key: skeys.publicKey,
				reverse: true,
			},
		});

		queryClient.invalidateQueries({
			queryKey: tunnelsQueryKeyFactory.list(1, { reverse: true }),
		});

		const template = TUNNEL_TEMPLATE.replaceAll(
			'{HOST}',
			publicEnv?.TUNNEL_URL ?? ''
		)
			.replaceAll('{HOST_PORT}', tunnel.port?.toString())
			.replaceAll('{LISTEN_PORT}', tunnel?.port_socks?.toString())
			.replaceAll('{KEY}', btoa(skeys.privateKey));

		const handleClick = () => {
			clipboard.copy(template);
			showNotification({
				title: 'Configuration copied',
				color: 'green',
				message:
					'The docker compose configuration has been copied to your clipboard',
			});
		};

		openModal({
			variant: 'default',
			title: 'Reverse tunnel generated',
			children: (
				<Stack spacing={theme.spacing.md} mt={theme.spacing.md}>
					<Stack spacing={theme.spacing.xs}>
						<Text size="sm" weight="semibold">
							Docker Compose
							<br />
							<Text size="xs">
								Learn more{' '}
								<Anchor
									size="xs"
									target="_blank"
									href="https://docs.secoda.co/integrations/connecting-via-tunnels/connecting-via-secoda-agent-reverse-ssh-tunnel"
								>
									here
								</Anchor>
							</Text>
						</Text>
						<Textarea minRows={6} value={template} />
					</Stack>
					<Flex>
						<Button onClick={handleClick}>Copy configuration</Button>
					</Flex>
				</Stack>
			),
		});
	}, [
		createTunnel,
		publicEnv?.TUNNEL_URL,
		theme.spacing.md,
		theme.spacing.xs,
		clipboard,
	]);

	const actions = useMemo(
		() => [
			{
				id: 'actions::delete',
				name: 'Delete',
				title: 'Delete',
				type: 'delete',
				iconName: 'trash',
				show: true,
				onClick: handleDelete,
			},
		],
		[handleDelete]
	);

	const handleTabChange = (value: string): void => {
		if (value === 'forward' || value === 'reverse') {
			setActiveTab(value as TunnelListPageTab);
		}
	};

	if (!tunnelAccess) {
		return (
			<EmptyState
				illustrationName="upgrade"
				title="Upgrade to access Tunnels"
				description="Connect integrations to Secoda using SSH tunnels instead of a direction connection."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={
					<UpgradeButton
						tooltip="Upgrade to access Tunnels"
						feature="Tunnels"
						size="md"
					/>
				}
			/>
		);
	}

	return (
		<Stack
			className={classes.wrapper}
			spacing={0}
			mt={`-${theme.other.space[2]}px`}
		>
			<Tabs
				color="fill/brand/default"
				value={activeTab}
				onTabChange={handleTabChange}
			>
				<TabsList
					tabs={[
						{ value: 'forward', label: 'Forward Tunnels' },
						...(publicEnv?.REVERSE_TUNNEL_ENABLED
							? [{ value: 'reverse', label: 'Reverse Tunnels' }]
							: []),
					]}
				/>
				<Tabs.Panel value="forward" pt={'sm'}>
					<Stack>
						<Banner
							title="Forward tunnels"
							tone="info"
							message={
								<>
									Connect through SSH to your bastion server and select this
									tunnel when creating a new integration. For multiple
									integrations using the same forward tunnel, try a reverse
									tunnel to avoid concurrency limits.{' '}
									<Anchor
										href="https://docs.secoda.co/integrations/connecting-via-tunnels/connecting-via-ssh-tunnel"
										target="_blank"
									>
										Learn more
									</Anchor>
								</>
							}
						/>
						<TableV2
							pluralTypeString="forward tunnels"
							columns={forwardColumns as DataTableColumn<ITunnel>[]}
							usePaginationList={useTunnelList}
							defaultRequiredSearchParams={{ reverse: false }}
							usePaginationListOptions={{
								filters: { reverse: false, archived: false },
							}}
							withActions={actions as ICommandListItem<ITunnel>[]}
							withDialog
							withCheckbox
							withAdditionalButtons={
								<Button variant="primary" onClick={handleNewForwardTunnel}>
									Add forward tunnel
								</Button>
							}
						/>
					</Stack>
				</Tabs.Panel>
				{publicEnv?.REVERSE_TUNNEL_ENABLED && (
					<Tabs.Panel value="reverse" pt={'sm'}>
						<Stack>
							<Banner
								title="Reverse tunnels"
								tone="info"
								message={
									<>
										Create a reverse tunnel on this page and run the Secoda
										reverse tunnel agent on your server.{' '}
										<Anchor
											href="https://docs.secoda.co/integrations/connecting-via-tunnels/connecting-via-secoda-agent-reverse-ssh-tunnel"
											target="_blank"
										>
											Learn more
										</Anchor>
									</>
								}
							/>
							<TableV2
								pluralTypeString="reverse tunnels"
								columns={reverseColumns as DataTableColumn<ITunnel>[]}
								usePaginationList={useTunnelList}
								defaultRequiredSearchParams={{ reverse: true }}
								usePaginationListOptions={{
									filters: { reverse: true, archived: false },
								}}
								withActions={actions as ICommandListItem<ITunnel>[]}
								withDialog
								withCheckbox
								withAdditionalButtons={
									<Button variant="primary" onClick={handleNewReverseTunnel}>
										Add reverse tunnel
									</Button>
								}
							/>
						</Stack>
					</Tabs.Panel>
				)}
			</Tabs>
		</Stack>
	);
}

export default TunnelsListPage;
