import { useMutation } from '@tanstack/react-query';
import produce from 'immer';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import type { Catalog } from '../../types';
import { catalogQueryKeyFactory } from './constants';

type RequestData = Pick<
	Catalog,
	'id' | 'entity_id' | 'catalog_type' | 'properties'
>;

export function useUpdateCatalogProperties() {
	return useMutation({
		mutationFn: async ({ id, properties }: RequestData) => {
			const url = getEndpoints(catalogQueryKeyFactory.namespace).byPath([
				id,
				'update_properties',
			]);

			const { data } = await apiClient.patch<Catalog>(url, {
				properties,
			});

			return data;
		},
		onMutate: async ({
			entity_id: entityId,
			catalog_type: catalogType,
			properties,
		}) => {
			const queryKey = catalogQueryKeyFactory.byArgs('properties', {
				catalog_type: catalogType,
				entity_id: entityId,
			});

			await queryClient.cancelQueries({
				queryKey,
			});
			const previousData = queryClient.getQueryData<Catalog>(queryKey);

			queryClient.setQueryData<Catalog>(
				queryKey,
				produce(previousData, (draft) => {
					if (draft) {
						draft.properties = properties;
					}

					return draft;
				})
			);

			return { previousData, queryKey };
		},
		onError: (err, variables, context) => {
			if (context?.previousData && context?.queryKey) {
				queryClient.setQueryData<Catalog>(
					context.queryKey,
					context.previousData
				);
			}
		},
		onSuccess: async (data, variables, context) => {
			await queryClient.invalidateQueries({
				queryKey: context?.queryKey,
			});
		},
	});
}
