import { SimpleGrid, Skeleton } from '@mantine/core';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import type { Incident, MeasurementChartData } from '../../api';
import { useFeatureFlags } from '../../utils/featureFlags';
import MeasurementsChart from '../MonitorPage/components/MeasurementsChart';
import { formatValueCompact, metricTypeLabel } from '../MonitorPage/utils';
import IncidentMeasurementChart from '../MonitorPage/v2/IncidentDrawer/IncidentMeasurmentChart';
import StatusCard from './components/StatusCard';
import DownstreamResourcesTable from './containers/DownstreamResourcesTable';

interface IIncidentContentProps {
	incident?: Incident;
	measurements?: MeasurementChartData[];
	title: string;
}

function IncidentContent({
	incident,
	measurements = [],
	title,
}: IIncidentContentProps) {
	const { monitoringV2 } = useFeatureFlags();

	if (!incident || isNil(incident)) {
		return null;
	}

	return (
		<>
			<SimpleGrid cols={3}>
				<StatusCard
					label="Created by"
					variant="button"
					content="Monitor"
					url={`/monitor/${incident?.monitor}`}
				/>
				{monitoringV2 ? (
					<StatusCard
						label={metricTypeLabel(incident.metric_type)}
						content={formatValueCompact(
							incident.metric_type,
							parseFloat(incident.value)
						)}
					/>
				) : (
					<StatusCard
						label="Value"
						content={Number(incident?.value).toFixed(2)}
					/>
				)}
				<StatusCard
					label="Last seen"
					content={dayjs(incident?.last_seen_at).fromNow()}
				/>
			</SimpleGrid>
			{monitoringV2 ? (
				<IncidentMeasurementChart incident={incident} />
			) : (
				<MeasurementsChart title={title} values={measurements || []} />
			)}
			{incident ? (
				<DownstreamResourcesTable incident={incident} />
			) : (
				<Skeleton width="100%" height={40} />
			)}
		</>
	);
}

export default IncidentContent;
