import { map, noop } from 'lodash-es';
import { useMemo } from 'react';
import { useCollectionListAll, type ISecodaEntity } from '../../api';
import type { PropertySelectorProps } from './PropertySelector';
import { PropertySelector } from './PropertySelector';

export interface CollectionsPropertySelectorProps
	extends Pick<PropertySelectorProps, 'onChange'> {
	entity: ISecodaEntity;
	viewerOfEntity: boolean;
	preventNavigate?: boolean;
}

export function CollectionsPropertySelector({
	entity,
	viewerOfEntity,
	preventNavigate = false,
	...propertySelectorProps
}: CollectionsPropertySelectorProps) {
	const { data: collections } = useCollectionListAll();

	const collectionOptions = useMemo(
		() =>
			map(collections?.results, (collection) => ({
				label: collection.title || 'Untitled',
				value: collection.id,
				icon: collection.icon,
				navigateTo: preventNavigate
					? undefined
					: `/collections/${collection.id}`,
				onClick: noop,
			})),
		[collections, preventNavigate]
	);

	return (
		<PropertySelector
			selected={entity?.collections}
			type="multi"
			value="collections"
			iconType="emoji"
			options={collectionOptions}
			isViewerUser={viewerOfEntity}
			readOnly={viewerOfEntity}
			placeholder={viewerOfEntity ? 'No collections' : 'Add to collection'}
			{...propertySelectorProps}
		/>
	);
}
