import {
	Center,
	Group,
	Image,
	Loader,
	Select,
	Stack,
	TextInput,
} from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import type { UseFormReturnType } from '@mantine/form';

import { Text, Title } from '@repo/foundations';
import { IconPhoto } from '@tabler/icons-react';
import { useSpecVersionFormStyles } from './MarketplaceIntegrationSpecVersionForm.helpers';
import type { MarketplaceIntegrationSpecVersionFormValues } from './MarketplaceIntegrationSpecVersionForm.hook';

const CATEGORY_OPTIONS = [
	{
		value: 'DATA_WAREHOUSES',
		label: 'Data warehouses',
	},
	{
		value: 'DATABASES',
		label: 'Databases',
	},
	{
		value: 'DATA_VISUALIZATION',
		label: 'Data visualization',
	},
	{
		value: 'DATA_PIPELINE',
		label: 'Data pipeline',
	},
	{
		value: 'DATA_QUALITY',
		label: 'Data quality',
	},
	{
		value: 'PRODUCTIVITY',
		label: 'Productivity',
	},
	{
		value: 'DATA_TRANSFORMATION_TOOLS',
		label: 'Data transformation tools',
	},
	{
		value: 'CUSTOM',
		label: 'Custom',
	},
];

export function BasicInformationSection({
	form,
	isUploadingImage,
	disabled,
	onDropImageFile,
	inSteps,
}: {
	form: UseFormReturnType<MarketplaceIntegrationSpecVersionFormValues>;
	disabled: boolean;
	isUploadingImage: boolean;
	onDropImageFile: (files: File[]) => void;
	inSteps: boolean;
}) {
	const { classes, cx } = useSpecVersionFormStyles();

	const renderImageUpload = () => {
		if (isUploadingImage) {
			return <Loader size="xs" />;
		}

		if (form.values.icon_url) {
			return (
				<Image
					radius="sm"
					src={form.values.icon_url}
					fit="contain"
					width={35}
					height={35}
				/>
			);
		}

		return <IconPhoto size={20} />;
	};
	return (
		<>
			<Stack spacing="xs">
				<Title size="lg">
					{inSteps
						? 'Add basic details about your integration'
						: 'Integration information'}
				</Title>
				<Text size="sm" color="text/secondary/default">
					The logo, name, and description you add will appear on the integration
					marketplace.
				</Text>
			</Stack>
			<Stack spacing="3xs">
				<Text weight="semibold" size="sm">
					Icon & name
				</Text>
				{form.errors.icon_url}
				<Group spacing="sm" align="start">
					<Dropzone
						maxFiles={1}
						disabled={isUploadingImage || disabled}
						padding={0}
						accept={IMAGE_MIME_TYPE}
						onDrop={onDropImageFile}
						classNames={{
							root: cx(
								classes.input,
								{
									[classes.noBorder]: !!form.values.icon_url,
								},
								classes.icon
							),
							inner: classes.pictureDropzoneInner,
						}}
					>
						<Center h="100%">{renderImageUpload()}</Center>
					</Dropzone>
					<TextInput
						disabled={disabled}
						classNames={{
							input: classes.input,
							root: classes.inputRoot,
						}}
						{...form.getInputProps('name')}
					/>
				</Group>
				<Text size="sm" color="text/secondary/default">
					Upload a clear, simple, and recognizable logo with a minimum size of
					62x62 pixels in PNG, JPEG, or SVG format for best results.
				</Text>
			</Stack>

			<Stack spacing="3xs">
				<Text weight="semibold" size="sm">
					Category
				</Text>
				<Select
					disabled={disabled}
					data={CATEGORY_OPTIONS}
					classNames={{
						input: classes.input,
						root: classes.inputRoot,
					}}
					name="category"
					{...form.getInputProps('category')}
				/>
			</Stack>
			<Stack spacing="3xs">
				<Text weight="semibold" size="sm">
					Description
				</Text>
				<TextInput
					disabled={disabled}
					classNames={{
						input: classes.input,
						root: classes.inputRoot,
					}}
					name="description"
					{...form.getInputProps('description')}
				/>
			</Stack>
		</>
	);
}
