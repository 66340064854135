import { UserRole } from '@repo/common/enums/UserRole';
import { useParams } from 'react-router';
import { usePublicEnv } from '../../api/hooks/utils/usePublicEnv';
import { AIChat, ChatHistory } from '../../components/AIAssistant';
import { AIAssistantProvider } from '../../components/AIAssistant/context';
import { AIAssistantMode } from '../../components/AIAssistant/types';
import { EmptyState } from '../../components/EmptyState';
import { FeatureGuard } from '../../components/FeatureGuard/FeatureGuard';
import {
	PageLayoutContentWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import AIPageNavBar from '../../components/SecodaAppShell/SecodaNavBar/AIPageNavBar';
import { useAiEnabled } from '../../hooks/useAIEnabled';

function AIAssistantPage() {
	const { id } = useParams();
	const enableAi = useAiEnabled();
	const { data: publicEnv } = usePublicEnv();

	if (!enableAi) {
		return (
			<EmptyState
				illustrationName="upgrade"
				title="Secoda AI is disabled"
				description="Secoda AI is not enabled for this workspace. Please contact your administrator to turn on access."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
			/>
		);
	}

	if (publicEnv?.AI_ENV_CONFIGURED === false) {
		return (
			<EmptyState
				illustrationName="upgrade"
				title="Secoda AI needs to be configured"
				description="Secoda AI requires an API key to be configured. Please contact your administrator to add one of the following environment variables: OPENAI_API_KEY, ANTHROPIC_API_KEY, or XAI_API_KEY to the on-premise environment."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
			/>
		);
	}

	return (
		<FeatureGuard
			featureName="Secoda AI"
			v1AllowedRoles={[UserRole.ADMIN, UserRole.EDITOR, UserRole.VIEWER]}
			v2Permission="SecodaAI.Read"
			description="Use Secoda AI to easily access all your company data and find answers to common questions."
			isFeatureIncludedInPlan={enableAi}
			isAlwaysIncludedFeature={true}
		>
			<AIAssistantProvider mode={AIAssistantMode.PAGE} id={id ?? 'new'}>
				<PageLayoutWrapper name="ai-assistant">
					<PageLayoutContentWrapper name="ai-assistant">
						<AIPageNavBar />
						<AIChat />
					</PageLayoutContentWrapper>
					<ChatHistory />
				</PageLayoutWrapper>
			</AIAssistantProvider>
		</FeatureGuard>
	);
}

export default AIAssistantPage;
