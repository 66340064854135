import type { MantineTheme } from '@mantine/core';
import { createStyles, Group, TextInput } from '@mantine/core';
import type { PolicyOut } from '@repo/api-codegen';
import { typography } from '@repo/theme/primitives';
import { useDebounceFn } from 'ahooks';
import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import { useUpdatePolicy } from '../PoliciesListPage/hooks';

const useStyles = createStyles((theme: MantineTheme) => ({
	textAreaRoot: {
		flex: 1,
	},
	textAreaInput: {
		fontSize: typography.text.xxl,
		fontWeight: typography.weight.bold,
		lineHeight: '32px',
		padding: 0,
		overflowY: 'hidden',

		'&:focus': {
			boxShadow: 'none',
			borderColor: 'transparent',
			backgroundColor: 'transparent',
		},

		'&:disabled': {
			opacity: 1,
			backgroundColor: theme.other.getColor('fill/transparent/default'),
			color: theme.other.getColor('text/primary/default'),
			cursor: 'text',
		},
	},
}));

interface PolicyPageTitleProps {
	policy: PolicyOut;
}

export default function PolicyPageTitle({ policy }: PolicyPageTitleProps) {
	const { classes } = useStyles();
	const [title, setTitle] = useState(policy.name);

	const { mutateAsync: updatePolicy } = useUpdatePolicy({});
	const { run: debouncedUpdatePolicy } = useDebounceFn(
		(newTitle: string) => {
			updatePolicy({
				body: {
					name: newTitle,
				},
				pathParams: {
					policyId: policy.id,
				},
			});
		},
		{ wait: 150 }
	);

	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setTitle(event.target.value);
			debouncedUpdatePolicy(event.target.value);
		},
		[debouncedUpdatePolicy]
	);

	return (
		<Group spacing="sm" align="flex-start" noWrap style={{ flex: 1 }}>
			<TextInput
				classNames={{
					root: classes.textAreaRoot,
					input: classes.textAreaInput,
				}}
				data-testid="input-title"
				value={title}
				onChange={handleChange}
				placeholder={`Untitled Policy`}
				variant="unstyled"
				size="md"
			/>
		</Group>
	);
}
