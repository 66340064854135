import { Divider, Group, Stack } from '@mantine/core';
import type { IMarketplaceIntegrationSpec } from '@repo/common/models/marketplace';
import { Banner, Button, Text, TextInput, Title } from '@repo/foundations';
import { useFormik } from 'formik';
import { isNil, keys, mapValues, omit, omitBy } from 'lodash-es';
import { useMemo, useState } from 'react';
import type { ObjectSchema } from 'yup';
import * as Yup from 'yup';
import type { IIntegration } from '../../../../../api';
import {
	integrationsQueryKeyFactory,
	useAuthUser,
	useCreateIntegration,
	useUpdateIntegration,
} from '../../../../../api';
import { trackEvent } from '../../../../../utils/analytics';
import { snakeCaseToTitleCase } from '../../../../../utils/shared.utils';
import { MultiTeamsSelector } from '../../../../MultiTeamsSelector/MultiTeamsSelector';

interface MarketplaceConnectionStepProps {
	spec: IMarketplaceIntegrationSpec;
	integration?: IIntegration;
	setIntegration?: (integration: IIntegration) => void;
	nextStep?: () => void;
	hideTitle?: boolean;
}

export function MarketplaceConnectionStep({
	spec,
	integration,
	setIntegration,
	nextStep,
	hideTitle,
}: MarketplaceConnectionStepProps) {
	const { versions } = spec;

	const { user, workspace } = useAuthUser();

	const version = versions?.[0];
	const autoUpdate = true;

	const [error, setError] = useState<string | undefined>();

	const form = useMemo<ObjectSchema<any>>(
		() =>
			Yup.object().shape(
				mapValues(version.form_spec, (field) => {
					let yupField = Yup.string().meta({
						htmlType: field.type,
						description: field.placeholder,
					});

					if (field.required) {
						yupField = yupField.required('Required');
					}

					return yupField;
				})
			),
		[version.form_spec]
	);

	const initialValues = {
		name: integration?.name || version.name,
		teams: integration?.teams || [],
		...(integration?.credentials || {}),
	};

	const defaultValues = form.cast({});
	const defaultNonEmptyValues = omitBy(defaultValues, isNil);

	const { mutateAsync: createIntegration } = useCreateIntegration({
		invalidationKeys: [integrationsQueryKeyFactory.allLists()],
	});

	const { mutateAsync: updateIntegration } = useUpdateIntegration({});

	const formik = useFormik({
		initialValues: {
			...initialValues,
			...defaultNonEmptyValues,
		} as any,
		validationSchema: form.concat(
			Yup.object().shape({
				teams: Yup.array(Yup.string()),
			})
		),
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: async (values) => {
			try {
				const { teams } = values;

				if (!integration) {
					const params = {
						type: 'marketplace',
						name: version.name,
						marketplace_integration_spec_version_id: version.id,
						marketplace_auto_update_latest_version: autoUpdate,
					};

					const integration = await createIntegration({
						data: {
							...params,
							teams,
							credentials: omit(values, ['teams']),
						},
					});

					// Keep this event for historic activation tracking
					trackEvent(
						'integration/connection/update',
						{
							label: params.name,
							type: params.type,
						},
						user,
						workspace!
					);

					setIntegration?.(integration);
					nextStep?.();
					return integration;
				}

				const updatedIntegration = await updateIntegration({
					data: {
						id: integration.id,
						name: values.name || name,
						credentials: omit(values, ['teams']),
						teams: values.teams,
					},
				});

				setIntegration?.(updatedIntegration);
				return updatedIntegration;
			} catch (error: any) {
				setError(
					`Encountered the following error: \n${JSON.stringify(error?.message ?? error, null, 2)}`
				);
			}
		},
	});

	return (
		<>
			{!hideTitle && <Title size="xl">Sync</Title>}
			{error && (
				<Banner tone="critical" message={error} title="Connection failed" />
			)}
			<form onSubmit={formik.handleSubmit}>
				<Stack spacing="2xl">
					<Stack>
						<Title size="md">Name and workspaces</Title>
						<TextInput
							key="name"
							name="name"
							label="Integration name"
							placeholder={version.name}
							value={formik.values.name}
							defaultValue={version.name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<Stack spacing="3xs">
							<MultiTeamsSelector
								label="Teams"
								value={formik.values.teams}
								setValue={(value) => formik.setFieldValue('teams', value)}
								required
							/>
							<Text size="sm" color="text/secondary/default">
								The selected teams will have access to the integration
								resources.
							</Text>
						</Stack>
					</Stack>
					<Divider />
					{keys(form.fields).map((field) => {
						const spec = form.fields[field]?.spec;

						return (
							<TextInput
								key={field}
								name={field}
								type={spec.meta?.htmlType}
								label={spec.meta?.label ?? snakeCaseToTitleCase(field)}
								help={spec.meta?.description}
								value={formik.values[field]}
								error={formik.errors[field] as string}
								defaultValue={spec.default}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								optional={spec.presence !== 'required'}
								disabled={spec.meta?.disabled}
								autoComplete="off"
							/>
						);
					})}
					<Group position="right">
						<Button
							variant="primary"
							size="md"
							type="submit"
							w="fit-content"
							loading={formik.isSubmitting}
						>
							Submit
						</Button>
					</Group>
				</Stack>
			</form>
		</>
	);
}
