/* eslint-disable no-plusplus */
import type { QueryKey } from '@tanstack/react-query';

export function isQueryKeyPrefix(array: QueryKey, prefix: QueryKey): boolean {
	if (prefix.length > array.length) {
		return false;
	}

	for (let i = 0; i < prefix.length; i++) {
		if (array[i] !== prefix[i]) {
			return false;
		}
	}

	return true;
}

export function isQueryKeySuffix(array: QueryKey, suffix: QueryKey): boolean {
	if (suffix.length > array.length) {
		return false;
	}

	for (let i = 0; i < suffix.length; i++) {
		if (array[array.length - 1 - i] !== suffix[suffix.length - 1 - i]) {
			return false;
		}
	}

	return true;
}

export function isQueryKeyEqual(array: QueryKey, other: QueryKey): boolean {
	if (array.length !== other.length) {
		return false;
	}

	return array.every((value, index) => value === other[index]);
}

export function isQueryKeyList(array: QueryKey, listPrefix: QueryKey): boolean {
	if (array.length === listPrefix.length) {
		return isQueryKeyEqual(array, listPrefix);
	}

	if (array.length === listPrefix.length + 1) {
		return (
			listPrefix.every((value, index) => value === array[index]) &&
			typeof array[array.length - 1] === 'object' &&
			array[array.length - 1] !== null &&
			'filter' in (array[array.length - 1] as object)
		);
	}

	return false;
}
