import { Box, createStyles } from '@mantine/core';
import type { DataAccessRequestStatus, LiteUserOut } from '@repo/api-codegen';
import type { IconNames, IconSizes } from '@repo/foundations';
import { Icon } from '@repo/foundations';
import { IconSizeMap } from '@repo/foundations/components/Icon/Icon';
import type { ColorNames } from '@repo/theme/utils';
import { UserAvatar } from '../../UserAvatar';

const StatusIconMap: Record<
	DataAccessRequestStatus,
	{ iconName: IconNames; color: ColorNames }
> = {
	deleted: { iconName: 'trash', color: 'icon/critical/default' },
	pending: { iconName: 'lock', color: 'icon/primary/default' },
	approved: { iconName: 'lockOpen', color: 'icon/success/default' },
	rejected: { iconName: 'lock', color: 'icon/primary/default' },
	expired: { iconName: 'lock', color: 'icon/primary/default' },
	cancelled: { iconName: 'lock', color: 'icon/primary/default' },
};

const STATUS_ICON_SIZE: IconSizes = 'md';

const useStyles = createStyles((theme) => ({
	container: {
		position: 'relative',
	},
	statusIcon: {
		position: 'absolute',
		backgroundColor: theme.other.getColor('surface/primary/default'),
		borderRadius: '50%',
		bottom: `-${IconSizeMap[STATUS_ICON_SIZE] * 0.4}px`,
		right: `-${IconSizeMap[STATUS_ICON_SIZE] * 0.4}px`,
	},
}));

interface DataAccessRequestIconProps {
	user: LiteUserOut;
	status: DataAccessRequestStatus;
}

export function DataAccessRequestIcon({
	user,
	status,
}: DataAccessRequestIconProps) {
	const { classes } = useStyles();

	return (
		<Box className={classes.container}>
			<UserAvatar user={user} size="md" />
			<Box className={classes.statusIcon}>
				<Icon
					name={StatusIconMap?.[status]?.iconName ?? 'lock'}
					size={STATUS_ICON_SIZE}
					color={StatusIconMap?.[status]?.color ?? 'icon/primary/default'}
				/>
			</Box>
		</Box>
	);
}
