import { Box, createStyles, Stack } from '@mantine/core';
import { SELECTABLE_PROPERTY_OPTIONS } from '@repo/common/components/SelectableProperty/constants';
import type { IconNames } from '@repo/foundations';
import {
	Icon,
	MultiSelect,
	Select,
	TextInput,
	TextInputLabel,
	Title,
} from '@repo/foundations';
import type { ColorNames } from '@repo/theme/utils';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { ExpandCollapse } from '../../../components/ExpandCollapse/ExpandCollapse';
import { RichEditor } from '../../../components/RichEditor';
import { useCreatePolicyStore } from './context';

const useStyles = createStyles((theme) => ({
	input: {
		width: '100%',
		minHeight: theme.other.space[24],
		borderColor: theme.other.getColor('border/input/default'),
		padding: `${theme.spacing['2xs']} ${theme.spacing.sm}`,
		borderRadius: theme.radius.sm,
		borderWidth: 0.5,
		borderStyle: 'solid',
		backgroundColor: theme.other.getColor('surface/input/default'),
		'&:has(.ProseMirror-focused)': {
			boxShadow: `0 0 0 0 ${theme.other.getColor('border/input/default')},
					0 0 0 1px ${theme.white},
					0 0 0 3px ${theme.other.getColor('border/emphasis/default')}`,
		},
	},
	editor: {
		'& .ProseMirror': {
			wordBreak: 'break-word',
			fontSize: theme.fontSizes.sm,
			minHeight: theme.other.space[24],
		},
	},
}));

function PropertiesStack() {
	const { classes, theme } = useStyles();
	const store = useCreatePolicyStore();

	const handleDescriptionChange = useCallback(
		(value: string | undefined) => {
			store.setDescription(value ?? '');
		},
		[store]
	);

	return (
		<Stack p={0} spacing="lg">
			<Title size="md">Overview</Title>
			<TextInput
				size="sm"
				label="Policy name"
				value={store.name}
				onChange={(e) => store.setName(e.target.value)}
				error={store.nameError}
			/>
			<Stack spacing={theme.spacing['3xs']}>
				<TextInputLabel label="Description" inputId="" />
				<Box className={classes.input}>
					<ExpandCollapse maxHeight={200} enabled>
						<RichEditor
							placeholder="Add policy description..."
							readOnly={false}
							initialValue={store.description}
							onChangeCallback={handleDescriptionChange}
							disableTopGap
							limited
							autoFocus={false}
							dataTestId="description"
							className={classes.editor}
						/>
					</ExpandCollapse>
				</Box>
			</Stack>
			<MultiSelect
				label="Frameworks"
				placeholder="Select frameworks"
				data={SELECTABLE_PROPERTY_OPTIONS.complianceFramework.map((item) => ({
					value: item.value as string,
					label: item.label as string,
				}))}
				value={store.frameworks}
				setValue={store.setFrameworks}
				dropdownPosition="flip"
			/>
			<Select
				label="Severity"
				placeholder="Select severity level"
				data={SELECTABLE_PROPERTY_OPTIONS.severity.map((item) => ({
					value: item.value as string,
					label: item.label as string,
					iconName: item.iconName as IconNames,
					color: item.color as ColorNames,
				}))}
				value={store.severity}
				onChange={store.setSeverity}
				renderIcon={(item) => <Icon name={item.iconName} color={item.color} />}
			/>
		</Stack>
	);
}

export default observer(PropertiesStack);
