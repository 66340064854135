import type { AccessControlEntityOut } from '@repo/api-codegen';
import { useGetEntityAccessControlRoles } from '@repo/api-codegen';
import { useCallback } from 'react';
import type { IBaseModel, ISecodaEntity } from '../../api';
import { openModal } from '../ModalManager';
import { TableV2 } from '../TableV2';
import type { OnCellClickHandlerParams } from '../TableV2/types';
import { AccessControlRoleDetailsModal } from './AccessControlRoleDetailsModal';
import { useColumns } from './EntityAccessControlList.hooks';

export interface EntityAccessControlListProps {
	entity: ISecodaEntity;
}

export function EntityAccessControlList({
	entity,
}: EntityAccessControlListProps) {
	const columns = useColumns();

	const handleCellClick = useCallback(
		({
			record,
		}: OnCellClickHandlerParams<AccessControlEntityOut & IBaseModel>) => {
			openModal({
				title: 'View role details',
				children: (
					<AccessControlRoleDetailsModal
						entity={entity}
						role={record.grantee}
					/>
				),
			});
		},
		[entity]
	);

	return (
		<TableV2<AccessControlEntityOut & IBaseModel>
			pluralTypeString="roles"
			withInfiniteScroll
			// @ts-expect-error codegen interface
			usePaginationList={useGetEntityAccessControlRoles}
			usePaginationListOptions={{
				pathParams: {
					entityId: entity.id,
				},
			}}
			useCodegenListInterface
			onCellClick={handleCellClick}
			columns={columns}
			withSorting={false}
		/>
	);
}
