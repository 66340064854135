import { Group, Stack, Timeline } from '@mantine/core';
import { IconButtonSkeleton, TextSkeleton } from '@repo/foundations';
import { useResourceLogStyles } from './ResourceLog.styles';
import { ResourceLogItemSkeleton } from './ResourceLogItemSkeleton';

export function ResourceLogsSkeleton() {
	const { classes } = useResourceLogStyles();

	return (
		<Stack spacing="xl">
			<Stack spacing="sm">
				<Group spacing="2xs">
					<TextSkeleton width={100} />
					<IconButtonSkeleton />
				</Group>
				<Timeline lineWidth={2} classNames={classes}>
					<ResourceLogItemSkeleton />
					<ResourceLogItemSkeleton />
				</Timeline>
			</Stack>
		</Stack>
	);
}
