import type { TabItem } from '@repo/common/components/TabsList';
import type { EntityType } from '@repo/common/enums/entityType';
import { getEntityTypeDisplayInfo } from '@repo/common/utils/entityDisplayUtils';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { includes, keys } from 'lodash-es';
import { useCallback } from 'react';
import {
	showLoadingNotification,
	showWarningNotification,
	updateCollectionNotification,
} from '../../../components/CollectionsEntityTabs/utils';
import type { SecodaEntity } from '../../../lib/models';
import { authHeaders, getEndpoints } from '../../common';
import {
	baseQueryHooksFactory,
	createQueryKeys,
	fetchAllPages,
	prefetchFunctionsFactory,
} from '../../factories';
import queryClient from '../../queryClient';
import type { ICollection, ISecodaEntity } from '../../types';
import { filterFromListCacheByIds } from '../../utils';
import { toArray } from '../../utils/iterator';
import { resourceCatalogQueryKeyFactory } from '../resourceCatalog/constants';
import { updateSecodaEntity } from '../secodaEntity';
import { fetchSecodaEntity } from '../secodaEntity/fetchSecodaEntities';

export const COLLECTIONS_NAMESPACE = ['collection', 'collections'];

export const collectionsQueryKeyFactory = createQueryKeys(
	COLLECTIONS_NAMESPACE
);

const { prefetchCollection, prefetchCollectionList } = prefetchFunctionsFactory(
	'collection',
	collectionsQueryKeyFactory
);

export const filterCollectionsByIds = (ids: string[]) =>
	filterFromListCacheByIds<ICollection>(collectionsQueryKeyFactory, ids);

const {
	useCollection,
	useSuspenseCollection,
	useCollectionInfiniteList,
	useCollectionList,
	useCreateCollection,
	useDeleteCollection,
	useUpdateCollection,
	fetchCollectionList,
	updateCollection,
	createCollection,
} = baseQueryHooksFactory<ICollection, 'collection'>(
	'collection',
	collectionsQueryKeyFactory
);

export async function fetchCollection(id: string) {
	const res = await axios.get<ICollection>(
		getEndpoints(COLLECTIONS_NAMESPACE).byId(id),
		{
			...authHeaders(),
		}
	);
	return res.data;
}

export async function getEntitiesCount(id: string) {
	const { data } = await axios.get<Record<string, number>>(
		getEndpoints(COLLECTIONS_NAMESPACE).byPath([id, 'entities_count']),
		{
			...authHeaders(),
		}
	);
	return data;
}

export const useCollectionListAll = () =>
	useQuery({
		queryKey: collectionsQueryKeyFactory.allLists(),
		queryFn: async () => ({
			results: (await toArray(
				fetchAllPages(collectionsQueryKeyFactory, {})
			)) as ICollection[],
		}),
	});

export function useAddToCollection() {
	const addToCollection = useCallback(
		async (collectionId: string, newEntity: ISecodaEntity | SecodaEntity) => {
			const entity = await fetchSecodaEntity(newEntity.id);

			if (includes(entity.collections, collectionId)) {
				showWarningNotification(newEntity.title ?? 'Untitled');
				return;
			}

			showLoadingNotification(newEntity.title ?? 'Untitled');
			const updatedCollections = [...entity.collections, collectionId];

			try {
				await updateSecodaEntity({
					data: {
						id: entity.id,
						collections: updatedCollections,
					},
				});

				queryClient.invalidateQueries({
					queryKey: collectionsQueryKeyFactory.byArgs(
						collectionId,
						'tabs_list'
					),
				});
				queryClient.invalidateQueries({
					queryKey: resourceCatalogQueryKeyFactory.allLists(),
				});

				updateCollectionNotification('success', newEntity.title ?? 'Untitled');
			} catch (error) {
				updateCollectionNotification('error', entity.title ?? 'Untitled');
			}
		},
		[]
	);

	return { addToCollection };
}

export function useCollectionTabs(collectionId: string) {
	return useQuery({
		queryKey: collectionsQueryKeyFactory.byArgs(collectionId, 'tabs_list'),
		queryFn: async () => {
			const response = await axios.get<Record<string, number>>(
				getEndpoints(COLLECTIONS_NAMESPACE).byPath([
					collectionId,
					'entities_count',
				]),
				{
					...authHeaders(),
				}
			);

			const tabs = response.data;

			const tabNames = keys(tabs);

			return tabNames
				?.map((tabName) => {
					const { label } = getEntityTypeDisplayInfo(tabName as EntityType);

					// getEntityTypeDisplayInfo returns Resource if value doesn't have a specific
					// matching in the switch case. Resource needs to be ignored as a tab as it does
					// not have proper column definition
					if (label === 'Resource') {
						return undefined;
					}

					const count = tabs?.[tabName] ?? 0;

					return {
						value: tabName,
						label: `${label} (${count})`,
					};
				})
				.concat({
					value: 'documentation',
					label: 'Documentation',
				})
				.filter((element) => element !== undefined) as TabItem[];
		},
	});
}

export {
	createCollection,
	fetchCollectionList,
	prefetchCollection,
	prefetchCollectionList,
	updateCollection,
	useCollection,
	useCollectionInfiniteList,
	useCollectionList,
	useCreateCollection,
	useDeleteCollection,
	useSuspenseCollection,
	useUpdateCollection,
};
