import { createStyles, Drawer, ScrollArea, Stack } from '@mantine/core';
import type { MantineNumberSize } from '@mantine/styles';

const useStyles = createStyles((theme) => ({
	drawerRoot: {
		boxShadow:
			'0px 8px 24px 0px #23222133 , 0px 4px 8px 0px #2322211A , 0px 0px 0px 1px #2322210D',
	},
	title: {
		fontSize: theme.fontSizes.md,
		fontWeight: theme.other.typography.weight.bold,
		paddingLeft: theme.spacing.lg,
	},
	header: {
		borderBottom: `thin solid ${theme.other.getColor('border/secondary/default')}`,
		padding: `${theme.spacing.md} 0`,
		marginBottom: 0,
	},
	scrollContainer: {
		height: 'calc(100vh - 68px)',
	},
	drawerBody: {
		padding: 0,
	},
}));

export function SideDrawer({
	children,
	onClose,
	opened,
	title,
	size = 360,
}: {
	opened: boolean;
	onClose: VoidFunction;
	children: React.ReactNode;
	title: string;
	size?: MantineNumberSize;
}) {
	const { classes } = useStyles();

	return (
		<Drawer
			opened={opened}
			onClose={onClose}
			position="right"
			size={size}
			title={title}
			classNames={{
				content: classes.drawerRoot,
				header: classes.header,
				title: classes.title,
				body: classes.drawerBody,
			}}
			overlayProps={{
				opacity: 0.1,
			}}
		>
			<ScrollArea className={classes.scrollContainer}>
				<Stack pt="sm">{children}</Stack>
			</ScrollArea>
		</Drawer>
	);
}
