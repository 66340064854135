import { Group, Stack } from '@mantine/core';
import type {
	SecodaEntityPropertySnapshot,
	VersionHistoryOut,
} from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import equal from 'fast-deep-equal';
import { isNil } from 'lodash-es';
import { useIntegrationPrefetched, type ISecodaEntity } from '../../api';
import { CollectionsPropertySelector } from '../Properties/CollectionsPropertySelector';
import { GovernancePropertySelector } from '../Properties/GovernancePropertySelector';
import { OwnersPropertySelector } from '../Properties/OwnersPropertySelector';
import { StatusPropertySelector } from '../Properties/StatusPropertySelector';
import TagPropertySelector from '../Properties/TagPropertySelector';
import { TeamsPropertySelector } from '../Properties/TeamsPropertySelector';
import { VerificationPropertySelector } from '../Properties/VerificationPropertySelector';
import { PropertyWrapper } from './PropertyWrapper';

const VALID_PROPERTIES: (keyof SecodaEntityPropertySnapshot)[] = [
	'published',
	'tags',
	'collections',
	'owners',
	'owners_groups',
	'pii',
	'verified',
	'teams',
];

export interface SnapshotPropertiesProps {
	versionHistory: VersionHistoryOut;
	integrationId?: string;
}

export function SnapshotProperties({
	versionHistory,
	integrationId,
}: SnapshotPropertiesProps) {
	const snapshot = versionHistory.metadata.secoda_entity_property_snapshot;

	const { data: integration } = useIntegrationPrefetched({
		id: integrationId ?? '',
		options: {
			enabled: Boolean(integrationId),
		},
	});

	const previousValues = versionHistory.metadata.prev;
	const currentValues = versionHistory.metadata.current;

	const consolidatedEntity = (snapshot ?? {
		teams: currentValues.team_ids,
		tags: currentValues.tag_ids,
		collections: currentValues.collection_ids,
		owners: currentValues.owner_ids,
		owners_groups: currentValues.owner_group_ids,
		pii: currentValues.pii,
		verified: currentValues.verified,
		published: currentValues.published,
	}) as ISecodaEntity;

	const properties = (
		Object.keys(consolidatedEntity) as (keyof SecodaEntityPropertySnapshot)[]
	)
		// this check is to ensure that we don't show properties when we don't have a snapshot
		// a snapshot allows us to show a consolidated view of the entity at that point in time
		// without a snapshot, we only have the current values, which may not be complete
		.filter(
			(property) =>
				VALID_PROPERTIES.includes(property) &&
				(snapshot || !isNil(consolidatedEntity[property]))
		);

	if (properties.length === 0) {
		return null;
	}

	return (
		<Stack spacing="sm">
			<Text size="sm" color="text/secondary/default">
				Properties
			</Text>
			<Group spacing="3xs">
				{properties.includes('published') && (
					<PropertyWrapper
						hasChanged={
							!equal(previousValues.published, currentValues.published)
						}
					>
						<StatusPropertySelector
							entity={consolidatedEntity}
							viewerOfEntity={true}
						/>
					</PropertyWrapper>
				)}
				{properties.includes('teams') && (
					<PropertyWrapper
						hasChanged={!equal(previousValues.team_ids, currentValues.team_ids)}
					>
						<TeamsPropertySelector
							entity={{
								...consolidatedEntity,
								teams: consolidatedEntity.teams ?? [],
							}}
							viewerOfEntity={true}
						/>
					</PropertyWrapper>
				)}
				{properties.includes('tags') && (
					<PropertyWrapper
						hasChanged={!equal(previousValues.tag_ids, currentValues.tag_ids)}
					>
						<TagPropertySelector
							initialValue={consolidatedEntity.tags ?? []}
							readOnly
							placeholder="No tags"
							forceVariant="tertiary"
							tagsManagedByIntegration={
								!!integration?.credentials?.import_tags_preference
							}
							entityIntegrationId={integration?.id}
						/>
					</PropertyWrapper>
				)}
				{properties.includes('collections') && (
					<PropertyWrapper
						hasChanged={
							!equal(
								previousValues.collection_ids,
								currentValues.collection_ids
							)
						}
					>
						<CollectionsPropertySelector
							key={consolidatedEntity.collections?.join(',') ?? 'collections'}
							entity={{
								...consolidatedEntity,
								collections: consolidatedEntity.collections ?? [],
							}}
							preventNavigate={true}
							viewerOfEntity={true}
						/>
					</PropertyWrapper>
				)}
				{(properties.includes('owners') ||
					properties.includes('owners_groups')) && (
					<PropertyWrapper
						hasChanged={
							!equal(previousValues.owner_ids, currentValues.owner_ids) ||
							!equal(
								previousValues.owner_group_ids,
								currentValues.owner_group_ids
							)
						}
					>
						<OwnersPropertySelector
							key={`${consolidatedEntity.owners?.join(',') ?? 'owners'}${consolidatedEntity.owners_groups?.join(',') ?? 'owners_groups'}`}
							entity={{
								...consolidatedEntity,
								owners: consolidatedEntity.owners ?? [],
								owners_groups: consolidatedEntity.owners_groups ?? [],
							}}
							integration={integration}
							viewerOfEntity={true}
							handleEntityChange={() => () => {}}
							preventNavigate={true}
						/>
					</PropertyWrapper>
				)}
				{properties.includes('pii') && (
					<PropertyWrapper
						hasChanged={!equal(previousValues.pii, currentValues.pii)}
					>
						<GovernancePropertySelector
							entity={consolidatedEntity}
							viewerOfEntity={true}
						/>
					</PropertyWrapper>
				)}
				{properties.includes('verified') && (
					<PropertyWrapper
						hasChanged={!equal(previousValues.verified, currentValues.verified)}
					>
						<VerificationPropertySelector
							entity={consolidatedEntity}
							viewerOfEntity={true}
						/>
					</PropertyWrapper>
				)}
			</Group>
		</Stack>
	);
}
