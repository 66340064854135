import { size } from 'lodash-es';
import { useMemo } from 'react';
import type { IIntegration } from '../../api';
import { useAuthUser, useIntegrationList, useWorkspace } from '../../api';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList';
import { HOMEPAGE_ONBOARDING_STEPS } from '../../pages/HomePage/components/Onboarding/constants';
import {
	getConnectIntegrationComplete,
	getExploreWorkspaceComplete,
	getInviteTeamMembersComplete,
} from '../../pages/HomePage/components/Onboarding/utils';

export function useOnboarding() {
	const { workspace } = useWorkspace();
	const { isAdminUser } = useAuthUser();

	const { activeUsers: users } = useExtendedUserList();

	const { data: integrations } = useIntegrationList({});

	const isConnectIntegrationComplete = getConnectIntegrationComplete(
		integrations?.results as unknown as IIntegration[]
	);

	const isInviteTeamMembersComplete = getInviteTeamMembersComplete(users);

	const isExploreWorkspaceComplete = getExploreWorkspaceComplete();

	const stepsCompleted = useMemo(
		() =>
			[
				isConnectIntegrationComplete,
				isExploreWorkspaceComplete,
				isInviteTeamMembersComplete,
			].reduce((total, n) => total + Number(n), 0),
		[
			isConnectIntegrationComplete,
			isExploreWorkspaceComplete,
			isInviteTeamMembersComplete,
		]
	);

	return {
		showOnboarding: !workspace.onboarding_completed && isAdminUser,
		stepsTotal: size(HOMEPAGE_ONBOARDING_STEPS),
		stepsCompleted,
		isConnectIntegrationComplete,
		isInviteTeamMembersComplete,
		isExploreWorkspaceComplete,
	};
}
