import { Group } from '@mantine/core';
import { IconButton, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef } from 'react';
import { usePropertyStyles } from '../styles';

export interface ISelectablePropertyProps {
	wideLabel?: boolean;
	label?: string;
	labelTooltip?: string;
	wrapValueWith?: (children: ReactNode) => ReactNode;
	children: ReactNode;
	dataTestId?: string;
}

function SelectablePropertyInternal(
	{
		wideLabel,
		label,
		labelTooltip,
		wrapValueWith,
		children,
		dataTestId,
	}: ISelectablePropertyProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	const { classes, theme, cx } = usePropertyStyles();

	const rightColContent = (
		<Group
			className={classes.rightCol}
			data-testid={`selectable-property-${dataTestId}`}
		>
			{children}
		</Group>
	);

	return (
		<Group spacing={0} noWrap ref={ref}>
			{label && (
				<Group
					className={cx(classes.leftCol, {
						[classes.leftColWide]: wideLabel,
					})}
				>
					<Group spacing="xs" p={0}>
						<Text className={classes.label}>{label}</Text>
						{labelTooltip && (
							<IconButton
								iconName="infoCircle"
								tooltip={labelTooltip}
								variant="tertiary"
								tooltipProps={{
									withinPortal: true,
									w: theme.other.space[90],
									multiline: true,
								}}
							/>
						)}
					</Group>
				</Group>
			)}
			{wrapValueWith ? wrapValueWith(rightColContent) : rightColContent}
		</Group>
	);
}

export const SelectableProperty = observer(
	forwardRef(SelectablePropertyInternal)
);
