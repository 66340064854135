/* eslint-disable no-theme-colors/no-theme-colors */
import type { MantineThemeOverride } from '@mantine/styles';
import { rem } from '@mantine/styles';
import * as primitives from '../primitives';
import { colors } from '../primitives/colors';
import { calculateSpace, getColor, themeColor } from '../utils';
import { componentOverrides } from './overrides';

export const SecodaTheme: MantineThemeOverride = {
	primaryShade: {
		dark: 7,
		light: 6,
	},
	activeStyles: { transform: 'unset' },
	components: { ...componentOverrides },
	radius: {
		xs: rem(primitives.space[1.5]), // 6px
		sm: rem(primitives.space[2]), // 8px
		md: rem(primitives.space[3]), // 12px
		lg: rem(primitives.space[4]), // 16px
		xl: rem(primitives.space[32]), // 128px For rounded elements
	},
	fontFamily:
		'InterVariable, Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif',
	fontSizes: {
		xs: primitives.typography.text.xs,
		sm: primitives.typography.text.sm,
		md: primitives.typography.text.md,
		lg: primitives.typography.text.lg,
		xl: primitives.typography.text.xl,
	},
	headings: {
		sizes: {
			h1: {
				fontSize: primitives.typography.title.xl,
				fontWeight: primitives.typography.weight.bold,
			},
			h2: {
				fontSize: primitives.typography.title.lg,
				fontWeight: primitives.typography.weight.bold,
			},
			h3: {
				fontSize: primitives.typography.title.md,
				fontWeight: primitives.typography.weight.bold,
			},
		},
	},
	spacing: {
		none: rem(0),
		'5xs': rem(primitives.space[0.25]), // 1px
		'4xs': rem(primitives.space[0.5]), // 2px
		'3xs': rem(primitives.space[1]), // 4px
		'2xs': rem(primitives.space[1.5]), // 6px
		xs: rem(primitives.space[2]), // 8px
		sm: rem(primitives.space[3]), // 12px
		md: rem(primitives.space[4]), // 16px
		lg: rem(primitives.space[5]), // 20px
		xl: rem(primitives.space[6]), // 24px
		'2xl': rem(primitives.space[8]), // 32px
		'3xl': rem(primitives.space[10]), // 48px
		'4xl': rem(primitives.space[12]), // 64px
		'5xl': rem(primitives.space[16]), // 96px
		'6xl': rem(primitives.space[20]), // 128px
		'7xl': rem(primitives.space[32]), // 192px
		'8xl': rem(primitives.space[40]), // 256px
	},
	shadows: {
		sm: `
    0px 0px 0px 1px ${getColor('shadow/shadow-border')},
    0px 4px 4px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px 0px ${colors['black-alpha'][2]}
  `,
		md: `
    0px 0px 0px 1px ${getColor('shadow/shadow-border')},
    0px 1px 4px 0px ${colors['black-alpha'][3]},
    0px 4px 8px 0px ${colors['black-alpha'][3]}
  `,
		lg: `
    0px 4px 32px 0px ${colors['black-alpha'][4]},
    0px 4px 16px 0px ${colors['black-alpha'][1]},
    0px 2px 8px 0px ${colors['black-alpha'][1]},
    0px 1px 1px 0px ${colors['black-alpha'][1]},
    0px 0px 0px 1px ${getColor('shadow/shadow-border')}
  `,
	},
	colors: primitives.colors,
	primaryColor: 'primary',
	globalStyles: (theme) => ({
		body: {
			color: theme.colors.gray[9],
		},
		a: {
			color: getColor('text/emphasis/default'),
			'&:hover': {
				color: getColor('text/emphasis/hover'),
			},
			'&:active': {
				color: getColor('text/emphasis/active'),
			},
		},
		// React flow animates all `path` elements, including
		// tabler icons, we remove that animation here.
		'.react-flow__edge.animated .tabler-icon path': {
			strokeDasharray: 'none',
			animation: 'none',
			transition: 'none',
		},
	}),
	other: {
		width: primitives.width,
		height: primitives.height,
		iconSize: primitives.iconSize,
		borderWidth: primitives.borderWidth,
		borderRadius: primitives.borderRadius,
		typography: primitives.typography,
		space: primitives.space,
		neutralColors: primitives.neutralColors,
		getColor,
		themeColor,
		calculateSpace,
	},
};
