import { Box, Group, createStyles } from '@mantine/core';
import type { ButtonProps } from '@repo/foundations';
import { Button, Icon } from '@repo/foundations';
import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef } from 'react';

const useStyles = createStyles((theme) => ({
	group: {
		flex: 1,
		gap: theme.spacing['4xs'],
	},
}));

interface SelectorTargetProps extends ButtonProps {
	children: ReactNode;
}

function SelectorTargetInternal(
	{ children, ...buttonProps }: SelectorTargetProps,
	ref: ForwardedRef<HTMLButtonElement>
) {
	const { classes } = useStyles();

	return (
		<Button size="lg" {...buttonProps} ref={ref}>
			<Group spacing="2xs" position="apart" noWrap className={classes.group}>
				<Box style={{ flex: 1 }}>{children}</Box>
				<Icon name="selector" />
			</Group>
		</Button>
	);
}

export const SelectorTarget = forwardRef<
	HTMLButtonElement,
	SelectorTargetProps
>(SelectorTargetInternal);
