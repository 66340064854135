import { Skeleton, useMantineTheme } from '@mantine/core';
import type { IconButtonSizes } from '../Buttons/IconButton';

interface IconButtonSkeletonProps {
	size?: IconButtonSizes;
}

export function IconButtonSkeleton({ size = 'md' }: IconButtonSkeletonProps) {
	const theme = useMantineTheme();

	let height: number = theme.other.space[7];
	let width: number = theme.other.space[7];

	if (size === 'lg') {
		height = theme.other.space[8];
		width = theme.other.space[8];
	} else if (size === 'sm') {
		height = theme.other.space[6];
		width = theme.other.space[6];
	}

	return <Skeleton width={width} height={height} />;
}
