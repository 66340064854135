import { AppShell, Box, createStyles } from '@mantine/core';
import { Outlet, useNavigation } from 'react-router';
import FullWidthLoadingSpinner from '../LoadingSpinner';
import { PropagateMetadataModal } from '../PropagateMetadata';
import Spotlight from '../Spotlight';
import SideBar from './SideBar';

const useStyles = createStyles((theme) => ({
	children: {
		height: '100%',
		backgroundColor: theme.other.getColor('surface/app/default'),
	},
}));

function SecodaAppShell() {
	const { classes } = useStyles();
	const navigation = useNavigation();

	return (
		<AppShell navbar={<SideBar />} padding={0}>
			<PropagateMetadataModal />
			<Box className={classes.children}>
				{navigation.state === 'loading' && <FullWidthLoadingSpinner />}
				<Outlet />
			</Box>
			<Spotlight />
		</AppShell>
	);
}

export default SecodaAppShell;
