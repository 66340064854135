import { useCallback, useMemo, useState } from 'react';
import {
	type IDatabuilderJob,
	integrationsQueryKeyFactory,
	queryClient,
	useAuthUser,
	useCreateDatabuilderJob,
} from '../../../api';
import {
	forceFetchJobs,
	usePollDatabuilderJobList,
} from '../../../api/hooks/databuilderJob/usePollDatabuilderJob';
import { DatabuilderJobType } from '../../../interfaces';
import { trackEvent } from '../../../utils/analytics';

interface UseIntegrationSyncProps {
	id: string;
	type?: 'PULL' | 'PUSH';
}

const useIntegrationExtraction = ({
	id,
	type = 'PULL',
}: UseIntegrationSyncProps) => {
	const { user, workspace } = useAuthUser();

	const {
		databuilderJobs: extractionJobs,
		polling,
		startPolling,
		stopPolling,
	} = usePollDatabuilderJobList({
		integrationId: id,
		type,
	});

	const { mutate: createJob } = useCreateDatabuilderJob({
		options: {
			onSuccess: () => {
				startPolling();
				forceFetchJobs();
				queryClient.invalidateQueries({
					queryKey: integrationsQueryKeyFactory.allLists(),
				});
			},
		},
	});

	const [extractionDetailId, setExtractionDetailId] = useState<string | null>(
		null
	);

	const handleRunExtraction = useCallback(async () => {
		if (polling) {
			return;
		}

		try {
			await createJob({
				data: { integration_id: id, type: DatabuilderJobType.METADATA },
			});
			trackEvent('integrations/run_extraction/click', {}, user, workspace!);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
	}, [createJob, id, polling, user, workspace]);

	const handleOpenActiveExtraction = useCallback((job: IDatabuilderJob) => {
		setExtractionDetailId(job.id);
	}, []);

	const handleCloseExtractionDetail = useCallback(() => {
		setExtractionDetailId(null);
	}, []);

	const extractionDetailJob = useMemo(
		() => extractionJobs?.find((job) => job.id === extractionDetailId),
		[extractionJobs, extractionDetailId]
	);

	return {
		extractionJobs,
		polling,
		stopPolling,
		handleRunExtraction,
		handleOpenActiveExtraction,
		handleCloseExtractionDetail,
		extractionDetailJob,
	};
};

export default useIntegrationExtraction;
