import { Box, Center, Code, createStyles } from '@mantine/core';
import { useApiGetTablePreview } from '@repo/api-codegen';
import { isEmpty } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { DataDisplayTable } from '../DataDisplayTable';
import { EmptyState } from '../EmptyState';
import FullWidthLoadingSpinner from '../LoadingSpinner';

interface ITableDataPreviewProps {
	tableKey: string;
}

const useStyles = createStyles(() => ({
	previewWrapper: {
		width: '100%',
		height: '100%',
		overflowX: 'auto',
	},
}));

function TableDataPreview({ tableKey }: ITableDataPreviewProps) {
	const { classes, theme } = useStyles();

	const {
		data: tablePreview,
		error,
		isLoading,
		isError,
	} = useApiGetTablePreview({
		pathParams: { entityId: tableKey },
	});

	if (isError || tablePreview?.error) {
		return (
			<Center h="100%">
				<EmptyState
					iconName="serverBolt"
					size="lg"
					title="Preview error"
					description="The following error occurred fetching the data preview."
					withActions={
						<Code
							block
							data-testid="table-data-preview-error-code-block-81de3d78b"
						>
							{error?.detail ?? tablePreview?.error ?? 'Unknown error'}
						</Code>
					}
					includeGoBack={false}
				/>
			</Center>
		);
	}

	if (isLoading || !tablePreview) {
		return (
			<Box my={20}>
				<FullWidthLoadingSpinner />
			</Box>
		);
	}

	if (isEmpty(tablePreview.data)) {
		return (
			<Center h="100%" my={theme.spacing.xl} w="100%">
				<EmptyState
					size="lg"
					iconName="tableRow"
					title="No data"
					description="This table has no data. Ensure Secoda is granted permissions to view the data."
					includeGoBack={false}
				/>
			</Center>
		);
	}

	if (tablePreview.columns.length === 0) {
		return (
			<Center h="100%" my={theme.spacing.xl} w="100%">
				<EmptyState
					size="lg"
					iconName="tableRow"
					title="No columns"
					description="This table has no columns that can be preview"
					includeGoBack={false}
				/>
			</Center>
		);
	}

	return (
		<Box className={classes.previewWrapper}>
			<DataDisplayTable
				width={`${theme.other.space[80] * tablePreview.columns.length}px`}
				height="calc(100vh - 300px)"
				results={[...[tablePreview.columns], ...tablePreview.data]}
			/>
		</Box>
	);
}

export default observer(TableDataPreview);
