import { Group, Stack } from '@mantine/core';
import { Button } from '@repo/foundations';
import { useMemo } from 'react';

import { useApiListPolicies } from '@repo/api-codegen';
import { UserRole } from '@repo/common/enums/UserRole';
import { usePolicyAccess } from '../../api';
import { EmptyState } from '../../components/EmptyState';
import { FeatureGuard } from '../../components/FeatureGuard/FeatureGuard';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import AddPolicyButton from './AddPolicyButton';
import PoliciesList from './PoliciesList';

function PoliciesListPage() {
	const { data: policies, isLoading } = useApiListPolicies({});
	const hasPolicyAccess = usePolicyAccess();

	const { classes } = useStyles();

	const learnMoreAction = useMemo(
		() => (
			<Group>
				<Button
					component="a"
					// TODO[tan-policies]: update this link
					href="https://docs.secoda.co/features/policies#how-to-create-policies"
					target="_blank"
					rel="noreferrer noopener"
					size="md"
					leftIconName="externalLink"
				>
					Learn more
				</Button>
				<AddPolicyButton />
			</Group>
		),
		[]
	);

	if (policies && policies?.results.length === 0) {
		if (isLoading) {
			return <FullWidthLoadingSpinner />;
		}

		return (
			<EmptyState
				className={classes.emptySpace}
				// TODO[tan-policies]: empty state for policies
				illustrationName="policies"
				title="Enable policies to govern your data effectively"
				description="Activate policies to help monitor, validate, and enforce data governance."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={learnMoreAction}
			/>
		);
	}

	return (
		<FeatureGuard
			v1AllowedRoles={[UserRole.VIEWER]}
			v2Permission="Policies.Read"
			isFeatureIncludedInPlan={hasPolicyAccess}
			isAlwaysIncludedFeature={false}
			description="Create policies to ensure compliance and security across your data stack."
			featureName="Policies"
		>
			<Stack className={classes.wrapper} spacing={0}>
				<PoliciesList />
			</Stack>
		</FeatureGuard>
	);
}

export default PoliciesListPage;
