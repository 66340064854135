import { Box, createStyles, Stack, Tabs } from '@mantine/core';
import { useApiGetPolicy, useApiListPolicyIssues } from '@repo/api-codegen';
import IconEmojiSelector from '@repo/common/components/IconEmojiSelector';
import TabsList from '@repo/common/components/TabsList';
import { UserRole } from '@repo/common/enums/UserRole';
import { Badge } from '@repo/foundations';
import { useMount } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import { useAuthUser, usePolicyAccess, useWorkspace } from '../../api';
import { AIAssistantSidebar } from '../../components/AIAssistant/AIAssistantSidebar/AIAssistantSidebar';
import DescriptionEditor from '../../components/DescriptionEditor';
import { DOCUMENTATION_WIDTH } from '../../components/Documentation/constants';
import { FeatureGuard } from '../../components/FeatureGuard/FeatureGuard';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import {
	RightSidebarMode,
	rightSidebarStore,
	RightSidebarWrapper,
} from '../../components/RightSidebar';
import { useFullWidthToggle } from '../../hooks/useFullWidthToggle';
import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import { useUpdatePolicy } from '../PoliciesListPage/hooks';
import type { DjangoValueType } from '../TemplatePage/types';
import PolicyPageNavBar from './Navbar';
import PolicyPageSidebar from './PolicyPageSidebar';
import PolicyPageTitle from './PolicyPageTitle';
import PolicyIssues from './tabs/PolicyIssues/PolicyIssues';
import PolicyOverview from './tabs/PolicyOverview/PolicyOverview';

const useStyles = createStyles(
	(theme, { shouldSetFullWidth }: { shouldSetFullWidth: boolean }) => ({
		wrapper: {
			margin: '0 auto',
			width: '100%',
			maxWidth: shouldSetFullWidth ? '100%' : `${DOCUMENTATION_WIDTH}px`,
			paddingTop: theme.spacing['3xl'],
		},
		descriptionSpacer: {
			marginTop: theme.spacing.xs,
			marginBottom: theme.spacing.sm,
		},
	})
);

type PolicyTab = 'overview' | 'issues';

function PolicyPage() {
	const id = useParamsIdSuffixUuid();
	const navigate = useNavigate();
	const { tab } = useParams();
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();

	const { shouldSetFullWidth } = useFullWidthToggle(id);
	const { classes } = useStyles({ shouldSetFullWidth });

	const { data: policy } = useApiGetPolicy({
		pathParams: { policyId: id },
	});

	useMount(() => {
		trackEvent('policy/open', {}, user, workspace);
	});

	const { mutateAsync: updatePolicy } = useUpdatePolicy({});
	const handlePolicyChange = useCallback(
		(key: string) => async (value: DjangoValueType) => {
			await updatePolicy({
				pathParams: { policyId: id },
				body: { [key]: value },
			});
		},
		[id, updatePolicy]
	);

	const { data: policyIssues } = useApiListPolicyIssues({
		pathParams: {
			policyId: id,
		},
		queryParams: {
			page: 1,
		},
	});

	const [activeTab, setActiveTab] = useState<PolicyTab>(
		(tab as PolicyTab) ?? 'overview'
	);
	const navigateToTab = useCallback(
		(t: PolicyTab) => {
			setActiveTab(t);
			navigate(`/policy/${id}/${t}`);
		},
		[id, navigate]
	);
	const handleTabChange = useCallback(
		(t: PolicyTab) => {
			setActiveTab(t);
			navigateToTab(t);
		},
		[navigateToTab]
	);

	const hasPolicyAccess = usePolicyAccess();

	if (!policy) {
		return null;
	}

	return (
		<FeatureGuard
			v1AllowedRoles={[UserRole.VIEWER]}
			v2Permission="Policies.Read"
			isFeatureIncludedInPlan={hasPolicyAccess}
			isAlwaysIncludedFeature={false}
			featureName="Policies"
			description="Create policies to ensure compliance and security across your data stack."
		>
			<PageLayoutOuterWrapper key={policy.id}>
				<Helmet>
					<title>{policy.name ?? 'Policy'}</title>
				</Helmet>
				<PageLayoutWrapper name="policies">
					<PageLayoutContentWrapper name="policies">
						<PolicyPageNavBar policy={policy} />
						<PageLayoutContent>
							<Stack className={classes.wrapper} spacing={0}>
								<Stack>
									<IconEmojiSelector
										value={
											policy.icon ?? 'iconName:checklist iconColor:#4a4a4a'
										}
										onChange={handlePolicyChange('icon')}
										iconSize={28}
										readOnly={false}
									/>
									<PolicyPageTitle policy={policy} />
									<Box className={classes.descriptionSpacer}>
										<DescriptionEditor
											entityId={policy.id}
											readOnly={false}
											value={policy?.description ?? ''}
											onChange={handlePolicyChange('description')}
											placeholder={'Policy description...'}
											withAi={false}
										/>
									</Box>
								</Stack>
								<Tabs value={activeTab} onTabChange={handleTabChange}>
									<TabsList
										tabs={[
											{ value: 'overview', label: 'Overview' },
											{
												value: 'issues',
												label: 'Issues',
												rightSection:
													policyIssues?.count && policyIssues.count > 0 ? (
														<Badge size="sm" variant="warning">
															{policyIssues.count}
															{policyIssues.count >= 50 && '+'}
														</Badge>
													) : undefined,
											},
										]}
									/>
									<Tabs.Panel value="overview">
										<PolicyOverview policy={policy} />
									</Tabs.Panel>
									<Tabs.Panel value="issues">
										<PolicyIssues policy={policy} />
									</Tabs.Panel>
								</Tabs>
							</Stack>
						</PageLayoutContent>
					</PageLayoutContentWrapper>
					<RightSidebarWrapper>
						{rightSidebarStore.mode === RightSidebarMode.AI && (
							<AIAssistantSidebar />
						)}
						{rightSidebarStore.mode === RightSidebarMode.INFO && (
							<PolicyPageSidebar
								policy={policy}
								handlePolicyChange={handlePolicyChange}
							/>
						)}
					</RightSidebarWrapper>
				</PageLayoutWrapper>
			</PageLayoutOuterWrapper>
		</FeatureGuard>
	);
}

PolicyPage.displayName = 'PolicyPage';
export default observer(PolicyPage);
