/* eslint-disable no-await-in-loop */
import type { Filter, MonitorsOut } from '@repo/api-codegen';
import type { EntityType } from '@repo/common/enums/entityType';
import { isNil } from 'lodash-es';
import {
	queryClient,
	type IApiListResponse,
	type ILineage,
	type ILineageEntityChildren,
	type ILineageTableQuery,
	type ILineageTableTest,
} from '../../api';
import { apiClient, getEndpoints } from '../../api/common';
import {
	LINEAGE_NAMESPACE,
	lineageQueryKeyFactory,
} from '../../api/hooks/lineage';
import type { LineageDirectionEnum } from '../../components/LineageGraph/types';
import { delay } from '../../components/LineageGraph/utils';
import { LINEAGE_QUERY_OPTIONS } from './constants';

export const getLineage = async (
	id?: string,
	entityType?: EntityType,
	direction?: LineageDirectionEnum,
	filter?: Filter
): Promise<ILineage[]> => {
	if (isNil(id) || isNil(direction)) {
		return [];
	}

	const results = [];
	let page = 1;
	let hasNextPage = true;

	const fetchPage = async (currentPage: number) => {
		const endpoint = getEndpoints(LINEAGE_NAMESPACE).root();
		return queryClient.fetchQuery({
			queryKey: lineageQueryKeyFactory.list(currentPage, {
				id,
				entity_type: entityType,
				direction,
				filter,
				page: currentPage,
			}),
			queryFn: async () => {
				try {
					const response = await apiClient.get<IApiListResponse<ILineage>>(
						endpoint,
						{
							params: {
								id,
								entity_type: entityType,
								direction,
								filter,
								page: currentPage,
							},
						}
					);
					return response.data;
				} catch (error) {
					return { results: [], meta: { next_page: null } };
				}
			},
		});
	};

	while (hasNextPage) {
		if (page > 10) {
			await delay(100);
		}

		const response = await fetchPage(page);

		results.push(...response.results);
		hasNextPage = !!response.meta.next_page;
		page += 1;
	}

	return results;
};

const getLineageEntitiesForIds = async <T>(
	ids: string[],
	path: 'children' | 'table-tests' | 'table-creation-queries' | 'table-monitors'
) => {
	const results = [];
	let page = 1;
	let hasNextPage = true;

	const fetchPage = async (currentPage: number) => {
		const endpoint = getEndpoints(LINEAGE_NAMESPACE).byPath([path]);
		return queryClient.fetchQuery({
			queryKey: lineageQueryKeyFactory.byArgs(path, {
				tableIds: ids,
				page: currentPage,
			}),
			queryFn: async () =>
				apiClient
					.post<IApiListResponse<T>>(
						endpoint,
						{
							ids: ids.join(','),
						},
						{
							params: { page: currentPage },
						}
					)
					.then((response) => response.data),
			...LINEAGE_QUERY_OPTIONS,
		});
	};

	while (hasNextPage) {
		if (page > 10) {
			await delay(100);
		}

		const response = await fetchPage(page);

		results.push(...response.results);
		hasNextPage = !!response.meta.next_page;
		page += 1;
	}

	return results;
};

export const getEntityChildren = async (
	ids: string[]
): Promise<ILineageEntityChildren[]> => {
	let hasMore = true;
	let cursor = undefined;
	const results = [];

	const fetchPage = async (searchAfter?: string) => {
		const endpoint = getEndpoints(LINEAGE_NAMESPACE).byPath(['children']);
		const params = searchAfter ? { search_after: searchAfter } : {};

		return queryClient.fetchQuery({
			queryKey: lineageQueryKeyFactory.byArgs('children', {
				tableIds: ids,
				searchAfter,
			}),
			queryFn: () =>
				apiClient
					.post(
						endpoint,
						{
							ids: ids.join(','),
						},
						{
							params,
						}
					)
					.then((response) => response.data),
			...LINEAGE_QUERY_OPTIONS,
		});
	};

	while (hasMore) {
		const response = await fetchPage(cursor);

		results.push(...response.results);
		hasMore = !!response.meta.has_more;
		cursor = response.meta.search_after;
	}

	return results;
};

export const getTableTests = async (
	ids: string[]
): Promise<ILineageTableTest[]> =>
	getLineageEntitiesForIds<ILineageTableTest>(ids, 'table-tests');

export const getTableMonitors = async (ids: string[]): Promise<MonitorsOut[]> =>
	getLineageEntitiesForIds(ids, 'table-monitors');

export const getTableCreationQueries = async (
	ids: string[]
): Promise<ILineageTableQuery[]> =>
	getLineageEntitiesForIds<ILineageTableQuery>(ids, 'table-creation-queries');
