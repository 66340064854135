import { Group, Tooltip, UnstyledButton } from '@mantine/core';
import { Text } from '@repo/foundations';

export function MenuItem({
	children,
	onClick,
	rightSection,
	disabled = false,
	tooltip,
}: {
	children: React.ReactNode;
	onClick?: () => void;
	rightSection?: React.ReactNode;
	disabled?: boolean;
	tooltip?: string;
}) {
	return (
		<Tooltip
			withinPortal
			disabled={!tooltip}
			label={
				<Text size="xs" style={{ maxWidth: 200, whiteSpace: 'pre-wrap' }}>
					{tooltip}
				</Text>
			}
			position="right"
		>
			<UnstyledButton
				px="sm"
				py="xs"
				w={164}
				disabled={disabled}
				sx={(theme) => ({
					borderRadius: !disabled ? theme.radius.md : 'none',
					backgroundColor: disabled
						? theme.other.getColor('fill/primary/disabled')
						: theme.other.getColor('surface/input/default'),
					'&:hover': {
						backgroundColor: theme.other.getColor('surface/secondary/default'),
					},
				})}
				onClick={onClick}
			>
				<Group position="apart" noWrap>
					{children}
					{rightSection}
				</Group>
			</UnstyledButton>
		</Tooltip>
	);
}
