import { ScrollArea, Stack, useMantineTheme } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { useApiListTeams } from '@repo/api-codegen';
import { fuzzySearchFilter } from '@repo/common/utils/fuse';
import { isNil } from 'lodash-es';
import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMatch } from 'react-router';
import { useNavigate } from 'react-router-dom';
import type { IBaseModel } from '../../../../api';
import { useAuthUser } from '../../../../api';
import PaletteInput from '../PaletteInput';
import CommandPaletteResults from './components/CommandPaletteResults';
import type { ICommandListItem } from './constants';
import { staticCommandList } from './constants';
import {
	generateCreateBreadcrumbs,
	generateCreateCommandList,
	generateTeamBreadcrumbs,
	generateTeamCommandsList,
	getInitialResults,
	getSearchResults,
	handleCommandPaletteItem,
} from './utils';

interface CommandPaletteProps {
	withAI?: boolean;
	actions?: ICommandListItem<IBaseModel>[];
}

function CommandPalette({ withAI = true, actions }: CommandPaletteProps) {
	const navigate = useNavigate();
	const [searchTerm, setSearchTerm] = useInputState('');
	const viewport = useRef<HTMLInputElement>(null);
	const [selectedItem, setSelectedItem] = useState<number>(0);
	const [containsBreadcrumbs, setContainsBreadcrumbs] = useState(false);
	const { workspace, user } = useAuthUser();

	const urlTeam = useMatch('/teams/:teamId/*');
	const currentTeamId = urlTeam?.params.teamId ?? null;

	const { data: teams } = useApiListTeams(
		{
			queryParams: {
				only_joined: true,
			},
		},
		{
			select: ({ results }) => results,
		}
	);

	const actionsCommandList = useMemo(() => actions ?? [], [actions]);

	const teamsCommandList = useMemo(
		() => teams?.map((team) => generateTeamCommandsList(team))?.flat() ?? [],
		[teams]
	);

	const [result, setResult] = useState(
		getInitialResults(workspace, teamsCommandList, actionsCommandList)
	);

	const displayedResults = fuzzySearchFilter(searchTerm, result, [
		'name',
		'hotkey',
	]);

	const hotkeyList = useMemo(
		() => [...staticCommandList, ...generateCreateCommandList(workspace)],
		[workspace]
	);

	useEffect(() => {
		// TODO: Make readable constants for these magic number used to scroll items
		// in CommandPalette.
		if (viewport.current !== null) {
			const top =
				selectedItem * 36 - 324 === -360 ? 288 : selectedItem * 36 - 324;
			viewport.current.scrollTo({
				top,
			});
		}
	}, [selectedItem]);

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setSelectedItem(0);
			const { value } = event.target;
			const commandListItem = hotkeyList.find(
				(item) => item.hotkey?.toLowerCase() === value.toLowerCase()
			);
			if (commandListItem) {
				handleCommandPaletteItem(
					commandListItem,
					navigate,
					user,
					currentTeamId ?? commandListItem.team
				);
			}
			if (value === '') {
				setContainsBreadcrumbs(false);
				setResult(
					getInitialResults(workspace, teamsCommandList, actionsCommandList)
				);
			} else {
				setResult(
					getSearchResults(workspace, teamsCommandList, actionsCommandList)
				);
			}
			setSearchTerm(value);
		},
		[
			actionsCommandList,
			currentTeamId,
			hotkeyList,
			navigate,
			setSearchTerm,
			teamsCommandList,
			user,
			workspace,
		]
	);

	const handleSearchInputChange = useCallback(
		(event: ICommandListItem<IBaseModel>) => {
			if (event.type === 'OPEN' && !containsBreadcrumbs) {
				setSearchTerm(`${event.name} / `);
				setResult(generateTeamBreadcrumbs(event, workspace));
				setContainsBreadcrumbs(true);
				return;
			}
			if (event.type !== 'static' && !containsBreadcrumbs) {
				setSearchTerm(`${event.name} / `);
				setResult(generateCreateBreadcrumbs(event, teams ?? []));
				setContainsBreadcrumbs(true);
			}
		},
		[containsBreadcrumbs, setSearchTerm, teams, workspace]
	);

	const theme = useMantineTheme();

	return (
		<Stack spacing={0} data-testid="command-palette">
			<PaletteInput
				type="command"
				value={searchTerm}
				placeholder="Search actions"
				onChange={handleChange}
				withAI={withAI}
			/>
			<ScrollArea mt={theme.spacing.xs} h={400} viewportRef={viewport}>
				<CommandPaletteResults
					searchTerm={searchTerm}
					searchResults={
						displayedResults.filter(
							(item) => !isNil(item)
						) as ICommandListItem<IBaseModel>[]
					}
					onSearchInputChange={handleSearchInputChange}
					selectedItem={selectedItem}
					setSelectedItem={setSelectedItem}
				/>
			</ScrollArea>
		</Stack>
	);
}

export default CommandPalette;
