import { Stack } from '@mantine/core';
import type { EntityPolicyOut } from '@repo/api-codegen';
import { Text, Title } from '@repo/foundations';
import { useMemo } from 'react';
import { SelectorWithIconRender } from '../../components/TableV2/render';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { FrameworkSelector } from '../../pages/PoliciesListPage/components/FrameworkSelector';
import {
	CreatedByRender,
	PolicyIssueStatus,
	PolicyOKStatus,
} from '../../pages/PoliciesListPage/render';

export function useColumns(): ExtendedDataTableColumn<EntityPolicyOut>[] {
	const columns: ExtendedDataTableColumn<EntityPolicyOut>[] = useMemo(
		() => [
			{
				accessor: 'name',
				title: 'Policies',
				render: (record) => (
					<Stack role="link" spacing={0} py="xs">
						<Title size="sm" truncate>
							{record.name}
						</Title>
						<Text size="xs" color="text/secondary/default" truncate>
							{record.description}
						</Text>
					</Stack>
				),
				width: 'auto',
			},
			{
				accessor: 'severity',
				title: 'Severity',
				navigate: false,
				render: (record) => (
					<SelectorWithIconRender
						key="severity"
						accessor="severity"
						nilOption="NONE"
						onChange={undefined}
						record={record}
					/>
				),
				width: 100,
			},
			{
				accessor: 'status',
				title: 'Status',
				navigate: false,
				render: (record) => {
					if (record.violating) {
						return <PolicyIssueStatus />;
					}
					return <PolicyOKStatus />;
				},
				width: 100,
			},
			{
				accessor: 'frameworks',
				title: 'Frameworks',
				navigate: false,
				render: (record) => <FrameworkSelector policy={record} readOnly />,
				width: 150,
			},
			{
				accessor: 'created_by',
				title: 'Created By',
				navigate: false,
				render: (record) => <CreatedByRender record={record} />,
				width: 150,
			},
		],
		[]
	);

	return columns;
}
