import { createStyles, getStylesRef } from '@mantine/core';

export const useTeamSideBarItemStyle = createStyles(
	(
		theme,
		{
			open,
			sideBarCollapsed,
		}: {
			open: boolean;
			sideBarCollapsed: boolean;
		}
	) => ({
		displayOnHover: {
			ref: getStylesRef('displayOnHover'),
			opacity: 0,
		},
		chevron: {
			transform: open ? 'rotate(90deg)' : 'none',
			transition: 'transform 100ms ease',
			color: theme.other.getColor('icon/secondary/default'),
		},
		wrapper: {
			width: '100%',
			height: theme.other.space[7],
			paddingRight: 0,
		},
		innerWrapper: {
			width: '100%',
			justifyContent: sideBarCollapsed ? 'center' : 'space-between',
			alignItems: 'center',
			borderRadius: theme.other.borderRadius.sm,
			':hover': {
				backgroundColor: theme.other.getColor('fill/transparent/hover'),
			},
			':active': {
				backgroundColor: theme.other.getColor('fill/transparent/active'),
			},
			[`&:hover .${getStylesRef('displayOnHover')}`]: {
				opacity: 1,
			},
		},
		clickArea: {
			display: 'flex',
			justifyContent: sideBarCollapsed ? 'center' : 'start',
			flex: '1 0 auto',
			gap: theme.other.space[0.5],
			width: '80%',
		},
		hiddenButton: {
			':focus': {
				opacity: 1,
			},
		},
		nonButtonParent: {
			[`&:hover.${getStylesRef('displayOnHover')}`]: {
				opacity: 1,
			},
		},
	})
);
