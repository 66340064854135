import { Tooltip } from '@mantine/core';
import MarkdownRenderer from '@repo/common/components/MarkdownRenderer';
import { SELECTABLE_PROPERTY_OPTIONS } from '@repo/common/components/SelectableProperty/constants';
import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import { Text } from '@repo/foundations';
import { truncate } from 'lodash-es';
import type { ReactNode } from 'react';
import type { ICollection, ITag, IUser, IUserGroup } from '../../../api';
import CollectionSelector from '../../EntityModal/Metadata/CollectionSelector';
import { OwnerSelector } from '../../EntityModal/Metadata/OwnerSelector';
import TagPropertySelector from '../../Properties/TagPropertySelector';
import type { Metadata, MetadataMapKey } from '../types';

interface IEntityValueProps {
	type: MetadataMapKey;
	value: Metadata[MetadataMapKey];
}

function EntityValue({ type, value }: IEntityValueProps) {
	switch (type) {
		case 'verified':
			return (
				<SingleSelector
					variant="tertiary"
					searchable
					readOnly
					property="verified"
					options={SELECTABLE_PROPERTY_OPTIONS['verified']}
					isViewerUser={true}
					initialSelected={Boolean(value)}
					iconType="tabler"
					hideOnEmpty
				/>
			);
		case 'pii':
			return (
				<SingleSelector
					variant="tertiary"
					searchable
					readOnly
					property="pii"
					options={SELECTABLE_PROPERTY_OPTIONS['pii']}
					isViewerUser={true}
					initialSelected={Boolean(value)}
					iconType="tabler"
					hideOnEmpty
				/>
			);

		case 'tags':
			const tags = value as unknown as ITag[];
			const noTags = tags.length === 0;
			return (
				<TagPropertySelector
					readOnly
					placeholder={noTags ? 'No tags' : 'Add tag'}
					forceVariant="tertiary"
					initialValue={tags.map((tag) => tag.id)}
					tagsManagedByIntegration
					hideOnEmpty
				/>
			);

		case 'collections':
			const collections = value as unknown as ICollection[];
			return (
				<CollectionSelector
					hideOnEmpty
					forceVariant="tertiary"
					initialValue={collections.map((collection) => collection.id)}
					readOnly
					placeholder={'No collections'}
				/>
			);

		case 'owners':
			const owners = value as (IUser | IUserGroup)[];
			return (
				<OwnerSelector
					forceVariant="tertiary"
					hideOnEmpty
					initialValue={owners.map((owner) => owner.id)}
					placeholder={'No owners'}
					readOnly
				/>
			);
		case 'description':
		case 'definition':
			return (
				<Tooltip
					key={type}
					withinPortal
					multiline
					position="top-start"
					width={400}
					label={
						<MarkdownRenderer>
							{truncate(value as string, { length: 600 })}
						</MarkdownRenderer>
					}
					disabled={!value}
				>
					<Text>{value as ReactNode}</Text>
				</Tooltip>
			);

		default:
			return null;
	}
}

export default EntityValue;
