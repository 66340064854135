import { Title } from '@repo/foundations';
import React from 'react';

interface SettingsSubtitleProps {
	children: React.ReactNode;
}

function SettingsSubtitle({ children }: SettingsSubtitleProps) {
	return (
		<Title order={2} size="md">
			{children}
		</Title>
	);
}

export { SettingsSubtitle };
