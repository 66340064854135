import { Group } from '@mantine/core';
import { AddFilter } from '@repo/common/components/Filter/AddFilter/AddFilter';
import { TopLevelOperatorToggle } from '@repo/common/components/Filter/TopLevelOperatorToggle';
import { isArray, size } from 'lodash-es';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { FilterOption } from '../../Filter';
import { Filter, FILTER_OPTIONS_DIVIDER, OPERATORS_CONFIG } from '../../Filter';
import type { SearchFilterV2Store } from '../../Filter/store';

export interface FilterBarProps {
	searchFilterV2Store: SearchFilterV2Store;
	filterOptions: Array<FilterOption | typeof FILTER_OPTIONS_DIVIDER>;
}

export const FilterBar = observer(
	({ searchFilterV2Store, filterOptions }: FilterBarProps) => (
		<Group position="apart" spacing="md" noWrap align="baseline">
			<Group spacing={'2xs'}>
				{size(searchFilterV2Store.values) > 0 &&
					// `isArray(searchFilterV2Store.values)` is a defensive
					// check to ensure that the legacy filters are properly
					// parsed
					isArray(searchFilterV2Store.values) && (
						<>
							{searchFilterV2Store.values.map((value, idx) => {
								const filterOption = filterOptions.find(
									(option) =>
										option !== FILTER_OPTIONS_DIVIDER &&
										option.type === value.filterType
								) as FilterOption;

								if (!filterOption) {
									return null;
								}

								return (
									<Filter
										// eslint-disable-next-line react/no-array-index-key
										key={`filter-${idx}}`}
										value={toJS(value)}
										filterOption={filterOption}
										onChange={searchFilterV2Store.onChangeValue(idx)}
										onClear={searchFilterV2Store.onClearValue(idx)}
										showDetailedLabel
										operatorConfig={
											OPERATORS_CONFIG[
												filterOption.filterDropdownConfig.dropdownType
											]
										}
									/>
								);
							})}
						</>
					)}
				<AddFilter
					options={filterOptions}
					onAddFilter={searchFilterV2Store.onAddValue}
				/>
			</Group>
			{size(searchFilterV2Store.values) >= 2 && (
				<TopLevelOperatorToggle
					value={searchFilterV2Store.topLevelOperator}
					onChange={searchFilterV2Store.setTopLevelOperator}
				/>
			)}
		</Group>
	)
);
