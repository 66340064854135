import type { SelectablePropertyItem } from '../SingleSelector/types';

export const SELECTABLE_PROPERTY_OPTIONS: Record<
	string,
	SelectablePropertyItem[]
> = {
	published: [
		{
			label: 'Published',
			value: true,
			iconName: 'circleCheckFilled',
			color: 'icon/success/default',
		},
		{
			label: 'Draft',
			value: false,
			iconName: 'circle',
			color: 'icon/secondary/default',
		},
	],
	pii: [
		{
			label: 'PII',
			value: true,
			iconName: 'shieldCheckFilled',
			color: 'icon/primary/default',
		},
		{
			label: 'Not PII',
			value: false,
			textColor: 'text/secondary/default',
			iconName: 'shield',
			color: 'icon/secondary/default',
			shouldHideIcon: true,
		},
	],
	verified: [
		{
			label: 'Verified',
			value: true,
			iconName: 'verified',
			color: 'fill/emphasis/default',
		},
		{
			label: 'Not verified',
			value: false,
			textColor: 'text/secondary/default',
			color: 'icon/secondary/default',
			iconName: 'verifiedUnfilled',
			shouldHideIcon: true,
		},
	],
	priority: [
		{
			label: 'None',
			value: 'NONE',
			iconName: 'priorityNone',
			color: 'icon/secondary/default',
		},
		{
			label: 'Low',
			value: 'LOW',
			iconName: 'priorityLow',
			color: 'icon/secondary/default',
		},
		{
			label: 'Medium',
			value: 'MEDIUM',
			iconName: 'priorityMedium',
			color: 'icon/secondary/default',
		},
		{
			label: 'High',
			value: 'HIGH',
			iconName: 'priorityHigh',
			color: 'icon/secondary/default',
		},
	],
	severity: [
		{
			label: 'None',
			value: 'NONE',
			iconName: 'priorityNone',
		},
		{
			label: 'Low',
			value: 'LOW',
			iconName: 'priorityLow',
		},
		{
			label: 'Medium',
			value: 'MEDIUM',
			iconName: 'priorityMedium',
		},
		{
			label: 'High',
			value: 'HIGH',
			iconName: 'priorityHigh',
		},
	],
	status: [
		{
			label: 'Answered',
			value: 'ANSWERED',
			color: 'fill/success-secondary/default',
			textColor: 'text/success/default',
		},
		{
			label: 'In progress',
			value: 'IN_PROGRESS',
			color: 'fill/info-secondary/default',
			textColor: 'text/info/default',
		},
		{
			label: 'In review',
			value: 'IN_REVIEW',
			hidden: true,
			color: 'fill/warning-secondary/default',
			textColor: 'text/warning/default',
		},
		{
			label: 'Unanswered',
			value: 'UNANSWERED',
			color: 'fill/caution-secondary/default',
			textColor: 'text/caution/default',
		},
		{
			label: 'Cancelled',
			value: 'CANCELLED',
			color: 'fill/transparent-secondary/default',
			textColor: 'text/primary/default',
		},
	],
	automationStatus: [
		{
			color: 'fill/success-secondary/default',
			textColor: 'text/success/default',
			label: 'On',
			value: 'enabled',
		},
		{
			color: 'fill/transparent-secondary/default',
			textColor: 'text/primary/default',
			label: 'Off',
			value: 'disabled',
		},
	],
	complianceFramework: [
		{
			label: 'CCPA',
			value: 'CCPA',
			color: 'icon/decorative/grape',
		},
		{
			label: 'CIS',
			value: 'CIS',
			color: 'icon/decorative/sunset',
		},
		{
			label: 'PCI DSS',
			value: 'PCI_DSS',
			color: 'icon/decorative/cyan',
		},
		{
			label: 'HIPAA',
			value: 'HIPAA',
			color: 'icon/decorative/lime',
		},
		{
			label: 'GDPR',
			value: 'GDPR',
			color: 'icon/decorative/teal',
		},
		{
			label: 'ISO27001',
			value: 'ISO27001',
			color: 'icon/decorative/orange',
		},
		{
			label: 'NIST',
			value: 'NIST',
			color: 'icon/decorative/indigo',
		},
		{
			label: 'GLBA',
			value: 'GLBA',
			color: 'icon/decorative/indigo',
		},
		{
			label: 'HITECH',
			value: 'HITECH',
			color: 'icon/decorative/pink',
		},
		{
			label: 'LGPD',
			value: 'LGPD',
			color: 'icon/decorative/grape',
		},
	],
};
