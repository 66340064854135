import { Box, Group, Stack, createStyles } from '@mantine/core';
import dayjs from 'dayjs';

import { Icon, Text, Title } from '@repo/foundations';
import EmojiIconSelector from '../../../components/EmojiIconSelector';

const useStyles = createStyles(() => ({
	headerWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: '20px',
		marginBottom: 40,
	},
}));

interface IUserGroupProfileHeaderProps {
	icon: string;
	name: string;
	usersCount: number;
	createdAt: string;
	onIconChange: (emoji: string) => void;
}

function UserGroupProfileHeader({
	icon,
	name,
	usersCount,
	createdAt,
	onIconChange,
}: IUserGroupProfileHeaderProps) {
	const { classes } = useStyles();

	return (
		<Box className={classes.headerWrapper}>
			<EmojiIconSelector icon={icon} title={name} onChange={onIconChange} />
			<Stack spacing="xs">
				<Title order={2}>{name}</Title>
				<Group>
					<Group spacing="xs">
						<Icon name="users" />
						<Text>{usersCount} members</Text>
					</Group>
					<Group spacing="xs">
						<Icon name="calendar" />
						<Text>Created at {dayjs(createdAt).format('MMM YYYY')}</Text>
					</Group>
				</Group>
			</Stack>
		</Box>
	);
}

export default UserGroupProfileHeader;
