import { Avatar, Stack, useMantineTheme } from '@mantine/core';
import type { MantineNumberSize } from '@mantine/styles';
import {
	getUserInitialsFromName,
	pickColor,
	pickColorType,
} from '@repo/common/utils';
import type { AvatarUser } from './common';
import { TooltipAvatar } from './common';
import { colorGroupToFillMap } from './helpers';

export interface IUserAvatarArrayProps {
	users: (AvatarUser & {
		isEditing?: boolean;
		isLive?: boolean;
	})[];
	limit?: number;
	size?: MantineNumberSize;
	spacing?: MantineNumberSize;
	disableZIndex?: boolean;
}

interface SingleAvatarProps {
	user: AvatarUser & {
		isEditing?: boolean;
		isLive?: boolean;
	};
	index: number;
	limit: number;
	size: MantineNumberSize;
	disableZIndex?: boolean;
}

function SingleAvatar({
	user,
	index,
	limit,
	size,
	disableZIndex = false,
}: SingleAvatarProps) {
	const theme = useMantineTheme();

	const getAvatarIcon = (aUser: AvatarUser) => {
		let icon =
			aUser.profile_picture ||
			(getUserInitialsFromName(aUser.display_name ?? '') ?? '').slice(0, 2);

		if (aUser.id === 'more-users') {
			icon = aUser.display_name;
		}

		return icon;
	};

	const colorGroup = pickColorType(user?.display_name, user?.disabled);
	const { fillStart, fillEnd, textColor } = colorGroupToFillMap(colorGroup);

	return (
		<Avatar
			sx={{
				border: '2px solid',
				borderColor: user?.isEditing
					? pickColor(user.display_name)
					: theme.other.getColor('surface/app/default'),
				zIndex: disableZIndex ? undefined : limit - index,
				backgroundImage: theme.fn.gradient({
					from: fillStart,
					to: fillEnd,
					deg: 0,
				}),
				color: textColor,
			}}
			opacity={user?.isLive === false ? 0.5 : 1}
			size={size}
			src={user.profile_picture}
			alt={user.display_name}
			color={pickColorType(user.display_name)}
			component={TooltipAvatar}
			user={user}
		>
			{getAvatarIcon(user)}
		</Avatar>
	);
}

export function UserAvatarArray({
	users,
	limit = 5,
	size = 'xs',
	spacing = 'xs',
	disableZIndex = false,
}: IUserAvatarArrayProps) {
	return (
		<Stack>
			<Avatar.Group spacing={spacing}>
				{users.slice(0, limit).map((user, index) => (
					<SingleAvatar
						key={user.id}
						user={user}
						index={index}
						size={size}
						limit={limit}
						disableZIndex={disableZIndex}
					/>
				))}
			</Avatar.Group>
		</Stack>
	);
}
