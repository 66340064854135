import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import type { IUserGroup } from '../../../api';
import { useUserGroupList } from '../../../api';
import { TableV2 } from '../../../components/TableV2';
import type { ExtendedDataTableColumn } from '../../../components/TableV2/types';
import { useColumns } from './GroupList.hooks';

function GroupList({
	defaultRequiredSearchParams,
}: {
	defaultRequiredSearchParams?: Record<string, any>;
}) {
	const columns = useColumns();

	const navigate = useNavigate();

	const handleClick = useCallback(
		(id: string) => {
			navigate(`/group/${id}`);
		},
		[navigate]
	);

	const onCellClick = useCallback(
		(cell: {
			column: ExtendedDataTableColumn<IUserGroup>;
			record: { id: string };
		}) => {
			if (cell.column.navigate !== false) {
				handleClick(cell.record.id);
			}
		},
		[handleClick]
	);

	return (
		<TableV2<IUserGroup>
			pluralTypeString="groups"
			withCheckbox={false}
			withInteractiveHeader
			withSearch
			defaultSort={null}
			withCsvExport
			columns={columns}
			usePaginationList={useUserGroupList}
			defaultRequiredSearchParams={defaultRequiredSearchParams}
			onCellClick={onCellClick}
		/>
	);
}

export default GroupList;
