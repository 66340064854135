import { NOTIFICATION_EVENT_TYPES } from '../../../pages/InboxPage/InboxList/constants';
import { inboxPageStore } from '../../../pages/InboxPage/store';
import {
	getEventsFilter,
	getInboxFiltersFromUrl,
} from '../../../pages/InboxPage/utils';
import { getParamsFromUrl } from '../../../utils/url';
import queryClient from '../../queryClient';
import { notificationQueryKeyFactory } from './constants';

export const getFilters = () => {
	const params = getParamsFromUrl();

	const { page, searchTerm, events, isInclusive } =
		getInboxFiltersFromUrl(params);

	const filters = {
		search_term: searchTerm,
		events: getEventsFilter(NOTIFICATION_EVENT_TYPES, events, isInclusive),
		read: inboxPageStore.showRead,
	};

	return {
		page,
		filters,
	};
};

export const invalidateNotificationListQuery = () => {
	queryClient.invalidateQueries({
		queryKey: notificationQueryKeyFactory.allLists(),
	});
};
