import { showNotification } from '@mantine/notifications';
import { Icon } from '@repo/foundations';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { BackgroundJob } from '../../../lib/models';
import { apiClient, getEndpoints } from '../../common';
import type { IBackgroundJob } from '../../types/models/backgroundJob';
import { monitorsQueryKeyFactory } from './constants';

export function useRunMonitors() {
	const [backgroundJob, setBackgroundJob] = useState<BackgroundJob | null>(
		null
	);

	const resetBackgroundJob = () => {
		setBackgroundJob(null);
	};

	const mutation = useMutation({
		mutationFn: async (monitorIds: string[]) => {
			const url = getEndpoints([
				...monitorsQueryKeyFactory.namespace,
				'run',
			]).root();

			try {
				const { data } = await apiClient.post<IBackgroundJob>(url, {
					monitors: monitorIds,
				});

				const job = new BackgroundJob({ id: data.id });
				await job.sync();
				setBackgroundJob(job);
			} catch (error) {
				setBackgroundJob(null);
				showNotification({
					title: 'Could not run monitors',
					message: 'Please contact customer support if the issue continues',
					color: 'red',
					icon: <Icon name="alertCircle" />,
				});
			}

			return backgroundJob;
		},
	});

	return {
		backgroundJob,
		resetBackgroundJob,
		runMonitors: mutation.mutateAsync,
	};
}
