import { Flex, Group } from '@mantine/core';
import type { PolicyOut, PolicySeverity } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { useCallback } from 'react';
import { SelectorWithIconRender } from '../../../../components/TableV2/render';
import { useUpdatePolicy } from '../../../PoliciesListPage/hooks';
import { INLINE_TEXT_HEIGHT, INLINE_TEXT_WIDTH } from './constants';

interface InlineSeverityProps {
	policy: PolicyOut;
}

export default function InlineSeverity({ policy }: InlineSeverityProps) {
	const { mutateAsync: updatePolicy } = useUpdatePolicy({});

	const handleUpdateSeverity = useCallback(
		(id: string) => (severity: string) => {
			updatePolicy({
				pathParams: { policyId: id },
				body: { severity: severity as PolicySeverity },
			});
		},
		[updatePolicy]
	);

	return (
		<Group spacing="xs" noWrap align="flex-start">
			<Text
				w={INLINE_TEXT_WIDTH}
				style={{ lineHeight: `${INLINE_TEXT_HEIGHT}px` }}
				size="sm"
				color="text/secondary/default"
			>
				Severity
			</Text>
			<Flex pl="2xs">
				<SelectorWithIconRender
					key="severity"
					accessor="severity"
					nilOption="NONE"
					onChange={handleUpdateSeverity}
					record={policy}
				/>
			</Flex>
		</Group>
	);
}
