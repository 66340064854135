import { EntityType } from '@repo/common/enums/entityType';
import type { IconNames } from '@repo/foundations';
import type { QueryKey } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { queryClient, useUpdateSecodaEntity } from '../../../api';
import type { BulkUpdateData } from '../../../api/hooks/secodaEntity/useBulkUpdateSecodaEntities';
import { onClickGenericAction } from '../../../components/TableV2/SecodaEntity.hooks';

export const useParentAction = (queryKey: QueryKey) => {
	const { mutateAsync: updateEntity } = useUpdateSecodaEntity({});

	// We don't use the native bulk update here because there is middleware needed
	// to properly handle moving parents.
	const emulateBulkUpdate = useCallback(
		async (
			{ data }: { data: BulkUpdateData },
			{ onSuccess }: { onSuccess: () => void }
		) => {
			await Promise.all(
				data.map((item) =>
					updateEntity({ data: { id: item.id, ...item.data } }, {})
				)
			);
			await onSuccess();
			queryClient.invalidateQueries({
				queryKey,
			});
		},
		[queryKey, updateEntity]
	);

	const action = useMemo(
		() => ({
			id: 'actions::parent',
			title: 'Set parent',
			name: 'Set parent',
			iconName: 'arrowUp' as IconNames,
			hotkey: '/sp',
			type: EntityType.all,
			team: undefined,
			category: 'actions',
			show: true,
			onClick: onClickGenericAction(
				'parent',
				true,
				emulateBulkUpdate as never,
				undefined,
				{}
			),
		}),
		[emulateBulkUpdate]
	);
	return action;
};
