import { createStyles, Group, Skeleton, UnstyledButton } from '@mantine/core';
import type { MantineNumberSize } from '@mantine/styles';
import type { TeamOut } from '@repo/api-codegen';
import { useApiGetTeamListUsers } from '@repo/api-codegen';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { Text } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { openModal } from '../ModalManager';
import { UserAvatarArray } from '../UserAvatar';
import { TEAM_MEMBERS_LIST_MODAL_ID } from './constants';
import { TeamMembersList } from './TeamMembersList';

export interface ITeamMembersListButtonProps {
	className?: string;
	team: TeamOut;
	size?: MantineNumberSize;
	spacing?: MantineNumberSize;
}

const useStyles = createStyles((theme) => ({
	button: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing.xs,
		flexWrap: 'nowrap',
		padding: `${theme.spacing.xs} 6px`,
		borderRadius: theme.radius.sm,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},
	},
}));

function TeamMembersListButton({
	className,
	team,
	size = 'xs',
	spacing = 'sm',
}: ITeamMembersListButtonProps) {
	const { cx, classes } = useStyles();

	const { isLoading, isError, data } = useApiGetTeamListUsers(
		{
			pathParams: {
				teamId: team.id,
			},
		},
		{
			select: ({ count, results }) => ({
				userCount: count,
				users: results,
			}),
			enabled: !isEmpty(team.id),
		}
	);

	if (isLoading || !data) {
		return <Skeleton height={32} width="75%" />;
	}

	if (isError) {
		return null;
	}

	const { userCount, users } = data;

	const handleClick = () => {
		openModal({
			modalId: TEAM_MEMBERS_LIST_MODAL_ID,
			title: (
				<Group spacing={8} noWrap>
					<IconWrapper>{team.icon}</IconWrapper>
					<Text lineClamp={1} weight="bold">
						{team.name} members
					</Text>
				</Group>
			),
			children: <TeamMembersList teamId={team.id} />,
			styles: { content: { paddingBottom: '0 !important' } },
		});
	};

	return (
		<UnstyledButton
			className={cx(classes.button, className)}
			onClick={handleClick}
		>
			<UserAvatarArray size={size} spacing={spacing} users={users} />
			<Text weight="semibold" size="sm">
				{userCount} member{userCount !== 1 && 's'}
			</Text>
		</UnstyledButton>
	);
}

export default TeamMembersListButton;
