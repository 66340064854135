import { Box, Group, Loader, Space, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { Message } from '@repo/api-codegen';
import { Icon, Text } from '@repo/foundations';
import { IconBulb, IconChevronDown } from '@tabler/icons-react';
import { useRef } from 'react';
import { LoadingDots } from '../../../LoadingDots';
import { AnimatedContent } from '../AnimatedContent';
import { useMessageContentStyles } from '../styles/MessageContent.styles';

interface ToolTimelineItemProps {
	name: string;
	isComplete: boolean;
	isLast?: boolean;
	args?: Record<string, unknown>;
	humanReadable?: string;
}

function formatArgValue(value: unknown): string {
	if (typeof value === 'string') {
		return value;
	}
	if (typeof value === 'number' || typeof value === 'boolean') {
		return String(value);
	}
	if (Array.isArray(value)) {
		return value.map((v) => formatArgValue(v)).join(', ');
	}
	if (value && typeof value === 'object') {
		return JSON.stringify(value);
	}
	return '';
}

function ToolTimelineItem({
	name,
	isComplete,
	isLast,
	args,
	humanReadable,
}: ToolTimelineItemProps) {
	const { classes } = useMessageContentStyles();
	const isActive = !isComplete;

	if (!humanReadable) {
		return null;
	}

	return (
		<Group
			noWrap
			spacing="sm"
			align="flex-start"
			className={classes.timelineItem}
		>
			<Stack
				spacing={0}
				align="center"
				style={{ alignSelf: !isLast ? 'stretch' : undefined }}
			>
				{isComplete ? (
					<Icon name="circleCheckFilled" color="icon/primary/default" />
				) : (
					<Box p={2}>
						<Loader color="dark" size={16} />
					</Box>
				)}
				{!isLast && (
					<Box
						className={classes.timelineLine}
						data-active={isActive ? 'true' : undefined}
					/>
				)}
			</Stack>
			<Stack spacing={0} pb={!isLast ? 'xs' : undefined}>
				<Text size="sm" fw={600}>
					{humanReadable}
				</Text>

				{name === 'run_sql' && args && (
					<Stack spacing={0}>
						{Object.entries(args).map(([key, value]) => (
							<Text key={key} size="sm" color="text/primary/default">
								{key}: {formatArgValue(value)}
							</Text>
						))}
					</Stack>
				)}
			</Stack>
		</Group>
	);
}

interface ThinkingSectionProps {
	messages: Message[];
	promptRunning: boolean;
	uniqueId: string;
}

export function ThinkingSection({
	messages,
	promptRunning,
	uniqueId,
}: ThinkingSectionProps) {
	const { classes } = useMessageContentStyles();
	const [opened, { toggle }] = useDisclosure(promptRunning);
	const viewportRef = useRef<HTMLDivElement>(null);

	return (
		<Stack spacing="md">
			<Box className={classes.expandHeader} onClick={toggle}>
				<Stack spacing={'2xs'}>
					<Group position="apart" align="center">
						<Group spacing="xs">
							<IconBulb size={20} />
							<Text color="text/primary/default" fw={500} size="sm">
								{promptRunning ? (
									<Group spacing="xs" noWrap>
										Thinking
										<LoadingDots />
									</Group>
								) : (
									'Reasoning'
								)}
							</Text>
						</Group>
						<IconChevronDown
							className={classes.chevron}
							data-expanded={opened ? 'true' : undefined}
							size={20}
						/>
					</Group>
				</Stack>
				<Stack
					spacing={0}
					className={classes.expandContent}
					data-expanded={opened ? 'true' : undefined}
				>
					<Space h="md" />
					{messages.map((msg, index) => {
						if (msg.content !== '' && msg.use_tools?.length === 0) {
							return (
								<AnimatedContent
									cacheKey={uniqueId + index}
									content={msg.content}
									isTyping={promptRunning}
									key={msg.content + msg.sender}
									viewportRef={viewportRef}
								/>
							);
						} else if (msg.use_tools?.length > 0) {
							return (
								<ToolTimelineItem
									key={msg.use_tools[0].name + index}
									name={msg.use_tools[0].name}
									args={msg.use_tools[0].args}
									humanReadable={msg.tool_response?.human_readable || undefined}
									isComplete={index !== messages.length - 1 || !promptRunning}
									isLast={index === messages.length - 1}
								/>
							);
						}
						return null;
					})}
				</Stack>
			</Box>
			<Box id={uniqueId} />
		</Stack>
	);
}
