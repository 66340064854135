import { createStyles, Group, Stack } from '@mantine/core';
import {
	useRetrieveQuality,
	useRetrieveQualityEntitySuggestions,
} from '@repo/api-codegen';
import { HoverCard } from '@repo/common/components/HoverCard';
import { Title } from '@repo/foundations';
import { DataQualityBenchmarkTooltip } from '../../../components/DataQualityScore/DataQualityBenchmarkTooltip/DataQualityBenchmarkTooltip';
import { DataQualityBreakdown } from '../../../components/DataQualityScore/DataQualityBreakdown/DataQualityBreakdown';

import { UserRole } from '@repo/common/enums/UserRole';
import { memo } from 'react';
import type { ISecodaEntity } from '../../../api';
import { useDataQualityAccess } from '../../../api';
import { DataQualityBreakdownLoading } from '../../../components/DataQualityScore/DataQualityBreakdown';
import { QualityScoreRingLoading } from '../../../components/DataQualityScore/QualityScoreRing';
import { QualityScoreRing } from '../../../components/DataQualityScore/QualityScoreRing/QualityScoreRing';
import { parseDataQualitySuggestions } from '../../../components/DataQualityScore/utils';
import { FeatureGuard } from '../../../components/FeatureGuard/FeatureGuard';

const useStyles = createStyles((theme) => ({
	tooltipTarget: {
		borderBottom: `dotted 1px ${theme.other.getColor('border/secondary/default')}`,
	},
	ringCard: {
		display: 'flex',
		flexDirection: 'column',
		border: `solid 1px ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.radius.md,
	},
	cardTitle: {
		paddingTop: theme.spacing.md,
		paddingLeft: theme.spacing.md,
		paddingRight: theme.spacing.md,
		paddingBottom: 0,
	},
	ring: {
		paddingRight: theme.spacing.md,
		paddingLeft: theme.spacing.md,
		paddingBottom: theme.spacing.xl,
	},
	breakdownCard: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 !important',
		border: `solid 1px ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.radius.md,
	},
	breakdownTitle: {
		paddingBottom: theme.spacing.md,
	},
}));

interface DataQualityTabProps {
	entity: ISecodaEntity;
}

export const DataQualityTab = memo(({ entity }: DataQualityTabProps) => {
	const { classes, cx } = useStyles();
	const dqsEnabled = useDataQualityAccess();
	const canShowDataQualityScore =
		dqsEnabled && entity?.entity_type?.toLowerCase() === 'table';
	const { data: qualityScore, isLoading: isLoadingQualityScore } =
		useRetrieveQuality(
			{
				pathParams: {
					entityId: entity.id,
				},
			},
			{
				enabled: canShowDataQualityScore,
				refetchOnMount: 'always',
			}
		);
	const { data: suggestions, isLoading: isLoadingSuggestions } =
		useRetrieveQualityEntitySuggestions(
			{
				pathParams: {
					entityId: entity.id,
				},
			},
			{
				enabled: canShowDataQualityScore && !!qualityScore,
				select: (response) =>
					parseDataQualitySuggestions(qualityScore!, response, 'resource'),
			}
		);

	if (!canShowDataQualityScore || qualityScore === null) {
		return null;
	}

	return (
		<FeatureGuard
			v1AllowedRoles={[UserRole.VIEWER, UserRole.EDITOR]}
			v2Permission="DQS.Read"
			isFeatureIncludedInPlan={dqsEnabled}
			isAlwaysIncludedFeature={false}
			featureName="Data Quality Scores"
		>
			<Group spacing="md" align="baseline">
				<Stack spacing="xl" className={classes.ringCard} w={228}>
					<Group spacing={0} className={classes.cardTitle}>
						<HoverCard
							target={
								<Title order={2} size="sm" className={classes.tooltipTarget}>
									Quality score
								</Title>
							}
						>
							<DataQualityBenchmarkTooltip />
						</HoverCard>
					</Group>
					<Group align="center" position="center" className={classes.ring}>
						{isLoadingQualityScore && <QualityScoreRingLoading />}
						{qualityScore && <QualityScoreRing score={qualityScore.total} />}
					</Group>
				</Stack>
				<Stack spacing={0} className={classes.breakdownCard}>
					<Group
						spacing={0}
						className={cx(classes.cardTitle, classes.breakdownTitle)}
					>
						<Title order={2} size="sm">
							Quality score breakdown
						</Title>
					</Group>
					{isLoadingSuggestions && <DataQualityBreakdownLoading />}
					{suggestions && <DataQualityBreakdown items={suggestions} />}
				</Stack>
			</Group>
		</FeatureGuard>
	);
});
DataQualityTab.displayName = 'DataQualityTab';
