import { Center } from '@mantine/core';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { useEffect } from 'react';
import { api } from '../../../network';
import { authHeaders } from '../../api/common';
import type { ButtonDetails } from '../../components/EmptyState';
import { EmptyState } from '../../components/EmptyState';

/**
 * DownloadPage component displays an empty stage with a "Downloading..." message
 * while it prepares and initiates the download of an attachment using the provided URL.
 *
 * This component fetches the attachment URL from the query parameter 'path' in the current
 * window location, then makes an HTTP GET request to the fetched URL with authentication headers.
 * It uses a debounce function to prevent multiple rapid clicks on the download button and initiates
 * the download of the attachment when the debounce period is over.
 *
 * @returns {JSX.Element} JSX element representing the DownloadPage component.
 */
function DownloadPage() {
	const debounceClick = debounce(async () => {
		const stripped = atob(
			new URLSearchParams(window.location.search).get('path') as string
		);
		const { data } = await axios.get(`${api()}${stripped}/`, authHeaders());
		window.open(data, '_self');
	}, 500);

	useEffect(() => {
		if (debounceClick) {
			debounceClick();
		}
	}, [debounceClick]);

	const buttons: ButtonDetails[] = [
		{
			name: 'Initiate download',
			action: debounceClick,
			isPrimary: true,
			size: 'md',
		},
	];

	return (
		<Center>
			<EmptyState
				title="Downloading attachment"
				description="Please wait as we prepare the attachment..."
				includeGoBack={false}
				buttons={buttons}
				stateHeight="80vh"
				size="lg"
			/>
		</Center>
	);
}

export default DownloadPage;
