import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	breadcrumbWrapper: {
		flexGrow: 1,
		// This applies to the component itself to override Mantine's styles
		// Without this flexGrow: 0 is applied to the children in the Group component
		'&&': {
			flexGrow: 1,
		},
		flexShrink: 1,
		overflowX: 'auto',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		paddingRight: theme.spacing.md,
	},
}));
