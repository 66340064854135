import type {
	ApiCreatePolicyError,
	ApiCreatePolicyVariables,
	ApiPatchPolicyError,
	ApiPatchPolicyVariables,
	ApiTogglePolicyEnableError,
	ApiTogglePolicyEnableVariables,
	PolicyOut,
} from '@repo/api-codegen';
import {
	useApiCreatePolicy,
	useApiPatchPolicy,
	useApiTogglePolicyEnable,
} from '@repo/api-codegen';
import type * as Schemas from '@repo/api-codegen/api/codegen/apiSchemas';
import type { UseMutationOptions } from '@tanstack/react-query';
import { queryClient } from '../../api';
import { policiesQueryKeyFactory } from '../../api/hooks/policy';

export function useTogglePolicyEnable(props: {
	options?: Omit<
		UseMutationOptions<
			PolicyOut,
			ApiTogglePolicyEnableError,
			ApiTogglePolicyEnableVariables
		>,
		'mutationFn'
	>;
}) {
	return useApiTogglePolicyEnable(props.options);
}

export function useUpdatePolicy(props: {
	options?: Omit<
		UseMutationOptions<
			Schemas.PolicyOut,
			ApiPatchPolicyError,
			ApiPatchPolicyVariables
		>,
		'mutationFn'
	>;
}) {
	return useApiPatchPolicy(props.options);
}

export function useCreatePolicy(props: {
	options?: Omit<
		UseMutationOptions<
			Schemas.PolicyOut,
			ApiCreatePolicyError,
			ApiCreatePolicyVariables
		>,
		'mutationFn'
	>;
}) {
	return useApiCreatePolicy({
		...props.options,
		onSuccess: (data) => {
			queryClient.invalidateQueries({
				queryKey: policiesQueryKeyFactory.all(),
			});
		},
	});
}
