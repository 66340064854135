import { Box, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { SettingsSubtitle, SettingsSwitch } from '@repo/common/components';
import { capitalize } from 'lodash-es';
import { memo, useCallback, useMemo } from 'react';
import {
	queryClient,
	useNotificationSettingsList,
	useUpdateNotificationSettings,
} from '../../api';
import { notificationSettingsQueryKeyFactory } from '../../api/hooks/notification/constants';
import type { INotificationSettings } from '../../api/types/models/notification';
import { notifications } from './constants';

interface NotificationSettingsListProps {
	type: 'app' | 'email' | 'slack';
}

const NOTIFICATION_SECTIONS = new Set(notifications.map((n) => n.category));

function NotificationSettingsList({ type }: NotificationSettingsListProps) {
	const { data } = useNotificationSettingsList({});
	const notificationSettings = data
		?.results?.[0] as unknown as INotificationSettings;
	const { mutateAsync: updateNotificationSettings } =
		useUpdateNotificationSettings({
			options: {
				onSuccess: () => {
					queryClient.invalidateQueries({
						queryKey: notificationSettingsQueryKeyFactory.allLists(),
					});
					showNotification({
						title: 'Settings saved',
						message: 'Notification settings have been saved.',
						color: 'green',
					});
				},
				onError: () => {
					showNotification({
						title: 'Error saving settings',
						message: 'Failed to update notification settings.',
						color: 'red',
					});
				},
			},
		});

	const handleToggle = useCallback(
		(attribute: keyof INotificationSettings) => async (checked: boolean) => {
			if (!notificationSettings || !data?.results?.[0]?.id) return;

			const updatedSettings = {
				...notificationSettings,
				[attribute]: {
					...(notificationSettings[attribute] as Record<string, boolean>),
					[type]: checked,
				},
			};

			await updateNotificationSettings({
				data: {
					id: data?.results?.[0]?.id,
					[attribute]: updatedSettings[attribute],
				},
			});
		},
		[data?.results, notificationSettings, type, updateNotificationSettings]
	);

	const handleToggleMasterSwitch = useCallback(
		async (checked: boolean) => {
			if (!notificationSettings || !data?.results?.[0]?.id) return;

			const allAttributes = notifications
				.filter((n) => !n.allowedTypes || n.allowedTypes.includes(type))
				.map((n) => n.attribute);

			const updatedData: Record<string, Record<string, boolean>> = {};
			allAttributes.forEach((attribute) => {
				updatedData[attribute] = {
					...(notificationSettings[attribute] as Record<string, boolean>),
					[type]: checked,
				};
			});

			await updateNotificationSettings({
				data: {
					id: data?.results?.[0]?.id,
					...updatedData,
				},
			});
		},
		[data?.results, notificationSettings, type, updateNotificationSettings]
	);

	const masterSwitchState = useMemo(() => {
		if (!notificationSettings) return false;

		const allNotifications = notifications.filter(
			(n) => !n.allowedTypes || n.allowedTypes.includes(type)
		);

		if (allNotifications.length === 0) return false;

		const enabledCount = allNotifications.filter(
			(n) => notificationSettings[n.attribute]?.[type]
		).length;

		return enabledCount > 0;
	}, [notificationSettings, type]);

	return (
		<Stack spacing="4xl">
			<Box>
				<SettingsSwitch
					title={`${capitalize(type)} notifications`}
					description={`Enable ${capitalize(type)} notifications`}
					checked={masterSwitchState}
					onChange={handleToggleMasterSwitch}
					aria-label="Toggle all notifications"
				/>
			</Box>
			{masterSwitchState && (
				<Stack spacing="4xl">
					{Array.from(NOTIFICATION_SECTIONS).map((section) => {
						const sectionNotifications = notifications.filter(
							(n) =>
								n.category === section &&
								(!n.allowedTypes || n.allowedTypes.includes(type))
						);
						if (sectionNotifications.length === 0) return null;

						return (
							<Stack key={section}>
								<SettingsSubtitle>{section}</SettingsSubtitle>
								<Box>
									{sectionNotifications.map((n) => (
										<SettingsSwitch
											key={n.attribute}
											title={capitalize(
												n.attribute.replace('notify_', '').replaceAll('_', ' ')
											)}
											description={n.description}
											defaultChecked={
												notificationSettings[n.attribute]?.[type] || false
											}
											onChange={(checked) => handleToggle(n.attribute)(checked)}
											disabled={
												n.allowedTypes && !n.allowedTypes.includes(type)
											}
											aria-label={`Toggle ${n.attribute.replace('notify_', '').replaceAll('_', ' ')} notification`}
										/>
									))}
								</Box>
							</Stack>
						);
					})}
				</Stack>
			)}
		</Stack>
	);
}

export default memo(NotificationSettingsList);
