import { createStyles, Group, Stack } from '@mantine/core';
import type { EntityPolicyOut, PolicyOut } from '@repo/api-codegen';
import { UserAvatar } from '@repo/common/components';
import { getBreadCrumbsFromSearchMetadata } from '@repo/common/utils/breadcrumb';
import { Icon, Text } from '@repo/foundations';
import { capitalize, isEmpty } from 'lodash-es';
import type { ISecodaEntity } from '../../api/types';
import { SecodaEntityIcon } from '../../components/SecodaEntity/SecodaEntityIcon';

const useStyles = createStyles((theme) => ({
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		maxWidth: theme.other.width.sm,
	},
}));

export function PolicyIssueStatus() {
	return (
		<Group spacing="3xs">
			<Icon name="alertTriangleFilled" fillColor="icon/warning/default" />
			<Text size="sm">Issue</Text>
		</Group>
	);
}

export function PolicyOKStatus() {
	return (
		<Group spacing="3xs">
			<Icon name="circleCheckFilled" fillColor="icon/success/default" />
			<Text size="sm">OK</Text>
		</Group>
	);
}
export function PolicyStatusRender({ record }: { record: PolicyOut }) {
	if (record.issues_count > 0) {
		return <PolicyIssueStatus />;
	}
	return <PolicyOKStatus />;
}

export function CreatedByRender({
	record,
}: {
	record: PolicyOut | EntityPolicyOut;
}) {
	const { classes } = useStyles();

	if (!record.created_by) {
		return <Text size="sm">Secoda</Text>;
	}

	return (
		<Group spacing="2xs" noWrap>
			<UserAvatar size="xxs" user={record.created_by} />
			<Text size="sm" className={classes.ellipsis}>
				{record.created_by.display_name}
			</Text>
		</Group>
	);
}

export function ResourceDisplay({
	record,
}: {
	record: Pick<
		ISecodaEntity,
		'title' | 'integration' | 'search_metadata' | 'entity_type' | 'id'
	>;
}) {
	const { classes } = useStyles();
	const breadcrumbs = getBreadCrumbsFromSearchMetadata(record.search_metadata);

	return (
		<Group spacing="sm" noWrap w="100%">
			<SecodaEntityIcon size={28} entity={record} />
			<Stack spacing={0}>
				<Text size="sm" role="link" weight="bold" className={classes.ellipsis}>
					{isEmpty(record.title) ? 'Untitled' : record.title}
				</Text>
				<Group>
					<Text
						size="xs"
						color="text/secondary/default"
						className={classes.ellipsis}
					>
						{capitalize(record.entity_type)} · {breadcrumbs.join(' / ')}
					</Text>
				</Group>
			</Stack>
		</Group>
	);
}
