import { Group } from '@mantine/core';
import type { PolicyOut } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { OwnerSelector } from '../../../../components/EntityModal/Metadata/OwnerSelector';
import { useUpdatePolicy } from '../../../PoliciesListPage/hooks';
import { INLINE_TEXT_HEIGHT, INLINE_TEXT_WIDTH } from './constants';
interface InlineOwnersProps {
	policy: PolicyOut;
}

export default function InlineOwners({ policy }: InlineOwnersProps) {
	const { mutate: updatePolicy } = useUpdatePolicy({});

	const handleUpdateOwners = (owners: string[]) => {
		updatePolicy({
			body: {
				owners,
			},
			pathParams: {
				policyId: policy.id,
			},
		});
	};

	const handleUpdateGroupOwners = (owners: string[]) => {
		updatePolicy({
			body: {
				owners_groups: owners,
			},
			pathParams: {
				policyId: policy.id,
			},
		});
	};

	return (
		<Group spacing="xs" noWrap align="flex-start">
			<Text
				w={INLINE_TEXT_WIDTH}
				style={{ lineHeight: `${INLINE_TEXT_HEIGHT}px` }}
				size="sm"
				color="text/secondary/default"
			>
				Owners
			</Text>
			<OwnerSelector
				property="owners"
				propertyLabel="Owners"
				placeholder="Add owner"
				placeholderIconName="user"
				forceVariant="tertiary"
				initialValue={[...policy.owners, ...policy.owners_groups]}
				onChangeGroupOwners={handleUpdateGroupOwners}
				onChangeUserOwners={handleUpdateOwners}
			/>
		</Group>
	);
}
