import { showNotification, updateNotification } from '@mantine/notifications';
import { Icon } from '@repo/foundations';

const LOADING_NOTIFICATION_ID = 'collection-loading';

export const showWarningNotification = (title: string) => {
	showNotification({
		title: 'Warning',
		color: 'orange',
		message: `${title} is already in this collection.`,
		icon: <Icon name="exclamationMark" />,
	});
	return;
};

export const updateCollectionNotification = (
	status: 'success' | 'error',
	title: string
) => {
	if (status === 'success') {
		updateNotification({
			id: LOADING_NOTIFICATION_ID,
			title: 'Success',
			message: `Successfully added ${title} to collection.`,
			color: 'green',
			loading: false,
		});
		return;
	}

	if (status === 'error') {
		updateNotification({
			id: LOADING_NOTIFICATION_ID,
			title: 'Error',
			color: 'red',
			message: `Error adding ${title} to collection.`,
			icon: <Icon name="x" />,
			loading: false,
		});
		return;
	}
};

export const showLoadingNotification = (title: string) => {
	showNotification({
		id: LOADING_NOTIFICATION_ID,
		loading: true,
		title: 'Adding to collection',
		message: `${title} is being added to collection...`,
		autoClose: false,
		withCloseButton: false,
	});
};
