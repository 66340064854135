import { useApiListVersionHistories } from '@repo/api-codegen';
import { useState } from 'react';
import { createMockableHook } from '../../../utils/createMockableHook';

export const [useGetVersionHistories, MockGetVersionHistoriesProvider] =
	createMockableHook(useApiListVersionHistories);

export const useInfinitePagination = () => {
	const [page, setPage] = useState(1);
	const [fetchedPages, setFetchedPages] = useState(new Set());
	const [hasNextPage, setHasNextPage] = useState(true);
	const [loading, setLoading] = useState(false);

	return {
		page,
		setPage,
		fetchedPages,
		setFetchedPages,
		hasNextPage,
		setHasNextPage,
		loading,
		setLoading,
	};
};
