import { useListCustomProperties } from '@repo/api-codegen';
import { useMemo } from 'react';
import { AutomationField, useDataQualityAccess } from '../../../../../api';
import { isValidEnumValue } from '../../../../../utils/enumUtils';
import AutomationCardButton from '../../AutomationCardButton';
import type { AutomationValueType } from '../../constants';
import { getAutomationValueType } from '../../constants';
import { AUTOMATION_FIELD_OPTIONS } from './constants';
import { useAutomationActionCardFilterStore } from './context';
import {
	getFilterConditionOptions,
	getFilterConditionOptionsForCustomProperties,
	getFilterFieldDisplayValue,
	isCustomPropertyField,
} from './utils';

interface AutomationActionCardFilterFieldButtonProps {
	filterSearchKey: string;
	field?: AutomationField;
	setValueType: (value: React.SetStateAction<AutomationValueType>) => void;
}

function AutomationActionCardFilterFieldButton({
	field,
	filterSearchKey,
	setValueType,
}: AutomationActionCardFilterFieldButtonProps) {
	const label = getFilterFieldDisplayValue(field) || 'Property';
	const store = useAutomationActionCardFilterStore();
	const dqsEnabled = useDataQualityAccess();
	const { data: customProperties, isLoading: isCustomPropertyLoading } =
		useListCustomProperties({});

	const buttonOptions = useMemo(() => {
		if (customProperties) {
			for (const customProperty of customProperties) {
				let fieldName: string;
				if (
					!['boolean', 'date', 'number'].includes(customProperty.value_type)
				) {
					fieldName = `${AutomationField.CUSTOM_PROPERTIES}.${customProperty.id}.keyword`;
				} else {
					fieldName = `${AutomationField.CUSTOM_PROPERTIES}.${customProperty.id}`;
				}

				if (
					!AUTOMATION_FIELD_OPTIONS.find(
						(option) => option.value === fieldName
					) &&
					customProperty.value_type !== 'resource'
				) {
					AUTOMATION_FIELD_OPTIONS.push({
						label: customProperty.name,
						value: fieldName,
					});
				}
			}
		}

		if (!dqsEnabled) {
			return AUTOMATION_FIELD_OPTIONS.filter(
				(option) =>
					![
						AutomationField.QUALITY,
						AutomationField.QUALITY_ACCURACY,
						AutomationField.QUALITY_RELIABILITY,
						AutomationField.QUALITY_STEWARDSHIP,
						AutomationField.QUALITY_USABILITY,
					].includes(option.value as AutomationField)
			);
		}

		return AUTOMATION_FIELD_OPTIONS;
	}, [dqsEnabled, customProperties]);

	const handleFieldSelection = (option: string | boolean) => {
		if (isValidEnumValue(AutomationField, option)) {
			store.setFilterField(filterSearchKey, option);

			// Set the condition as the first available option.

			const [firstCondition] = getFilterConditionOptions(option);
			store.setFilterCondition(filterSearchKey, firstCondition);

			setValueType(getAutomationValueType(option));
		} else if (isCustomPropertyField(option) && !isCustomPropertyLoading) {
			// This check is done in isCustomPropertyField function
			// But ts is not smart enough to understand that when sending
			// option into setFilterField
			if (typeof option !== 'string') {
				throw new Error('Invalid custom property field');
			}

			store.setFilterField(filterSearchKey, option);
			const [_, searchId, __] = option.split('.');
			const customProperty = customProperties?.find(
				(property) => property.id === searchId
			);
			const [firstCondition] = getFilterConditionOptionsForCustomProperties(
				customProperty?.value_type
			);
			store.setFilterCondition(filterSearchKey, firstCondition);
			setValueType(
				getAutomationValueType(
					AutomationField.CUSTOM_PROPERTIES,
					customProperty?.value_type
				)
			);
		}
	};

	return (
		<AutomationCardButton
			addFilter
			buttonLabel={label}
			buttonOptions={buttonOptions}
			onClick={handleFieldSelection}
			defaultOpened={!field}
			property="filter"
		/>
	);
}

export default AutomationActionCardFilterFieldButton;
