import { Box, createStyles, Divider, Group, Stack } from '@mantine/core';

const useStyles = createStyles((theme) => ({
	leftColumn: {
		height: '100%',
		overflow: 'auto',
		padding: `${theme.spacing['2xl']} ${theme.spacing['4xl']}`,
		flex: '1 !important',
	},
	rightColumn: {
		width: theme.other.width.xs,
		height: '100%',
	},
}));

export interface SnapshotModalBodyProps {
	leftColumn: React.ReactNode;
	rightColumn: React.ReactNode;
	actions: React.ReactNode;
}

export function SnapshotModalBody({
	leftColumn,
	rightColumn,
	actions,
}: SnapshotModalBodyProps) {
	const { classes } = useStyles();

	return (
		<Group spacing={0} align="flex-start" h="100%">
			<Box className={classes.leftColumn}>{leftColumn}</Box>
			<Divider orientation="vertical" />
			<Stack spacing={0} className={classes.rightColumn}>
				{rightColumn}
				<Divider orientation="horizontal" />
				<Group p="md" spacing="xs" position="right">
					{actions}
				</Group>
			</Stack>
		</Group>
	);
}
