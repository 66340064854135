import { Select } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import { isNil } from 'lodash-es';
import type { FormEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useIntegration, useUpdateIntegration } from '../../../api';
import type { SlackDataRequestResponsePreference } from './constants';
import {
	CHANNEL_RESPONSE_PREFERENCE_OPTIONS,
	DEFAULT_CHANNEL_RESPONSE_LEVEL,
	DEFAULT_THREAD_RESPONSE_LEVEL,
	THREAD_RESPONSE_PREFERENCE_OPTIONS,
} from './constants';

interface IntegrationSlackAIPreferencesProps {
	integrationId: string;
}

function IntegrationSlackAIPreferences({
	integrationId,
}: IntegrationSlackAIPreferencesProps): JSX.Element {
	// Fetch the integration data by its id
	const { data: integration } = useIntegration({
		id: integrationId,
		options: {
			enabled: !!integrationId,
		},
	});

	const [channelResponseLevel, setChannelResponseLevel] =
		useState<SlackDataRequestResponsePreference>(
			DEFAULT_CHANNEL_RESPONSE_LEVEL
		);
	const [threadResponseLevel, setThreadResponseLevel] =
		useState<SlackDataRequestResponsePreference>(DEFAULT_THREAD_RESPONSE_LEVEL);

	// Initialize the mutation function for updating the integration
	const { mutate: updateIntegration, isLoading } = useUpdateIntegration({
		disableOptimisticUpdate: true,
		disableInvalidation: true,
	});

	const updatePreferencesCallback = useCallback(() => {
		if (isNil(integration)) {
			return;
		}

		setChannelResponseLevel(
			(integration.credentials
				?.channel_response_level as SlackDataRequestResponsePreference) ||
				DEFAULT_CHANNEL_RESPONSE_LEVEL
		);
		setThreadResponseLevel(
			(integration.credentials
				?.thread_response_level as SlackDataRequestResponsePreference) ||
				DEFAULT_THREAD_RESPONSE_LEVEL
		);
	}, [integration]);

	// Use the callback to update the state whenever the integration data changes
	useEffect(() => {
		updatePreferencesCallback();
	}, [updatePreferencesCallback]);

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();
		updateIntegration({
			data: {
				id: integrationId,
				credentials: {
					...integration?.credentials,
					thread_response_level:
						threadResponseLevel || DEFAULT_THREAD_RESPONSE_LEVEL,
					channel_response_level:
						channelResponseLevel || DEFAULT_CHANNEL_RESPONSE_LEVEL,
				},
			},
		});
	};

	const handleChannelResponseLevelChange = (
		value: SlackDataRequestResponsePreference
	) => {
		setChannelResponseLevel(value);
	};

	const handleThreadResponseLevelChange = (
		value: SlackDataRequestResponsePreference
	) => {
		setThreadResponseLevel(value);
	};

	return (
		<form onSubmit={handleSubmit}>
			<Text size="md" weight="bold" mb={6}>
				Channel Responses
			</Text>
			<Text size="sm" mb={12}>
				How Secoda AI responds when a user messages in the connected data
				request channel
			</Text>
			<Select
				mb={24}
				data={CHANNEL_RESPONSE_PREFERENCE_OPTIONS}
				label=""
				value={channelResponseLevel}
				onChange={handleChannelResponseLevelChange}
			/>

			<Text size="md" weight="bold" mb={6}>
				Thread Responses
			</Text>
			<Text size="sm" mb={12}>
				How Secoda AI responds when a user messages in a thread in the connected
				data request channel
			</Text>
			<Select
				data={THREAD_RESPONSE_PREFERENCE_OPTIONS}
				label=""
				value={threadResponseLevel}
				onChange={handleThreadResponseLevelChange}
			/>

			<Button
				loading={isLoading}
				mt={24}
				size="md"
				type="submit"
				disabled={isLoading}
			>
				Submit
			</Button>
		</form>
	);
}

export default IntegrationSlackAIPreferences;
