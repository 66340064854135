import { Stack } from '@mantine/core';
import { type DataAccessRequestOut } from '@repo/api-codegen';
import { UserAvatar } from '@repo/common/components';
import { Icon, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { sortBy } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import {
	commentsQueryKeyFactory,
	queryClient,
	useCommentList,
} from '../../../api';
import { ActivityLogV2 } from '../../ActivityLogV2/ActivityLogV2';
import { ActivityLogV2Skeleton } from '../../ActivityLogV2/ActivityLogV2Skeleton';
import type {
	ActivityLogItem,
	CommentItemType,
} from '../../ActivityLogV2/types';
import {
	CommentStoreProvider,
	useCommentStoreContext,
} from '../../Comment/context';
import { NewCommentForm } from './NewCommentForm';

export interface DataAccessRequestActivityLogProps {
	request: DataAccessRequestOut;
}

function DataAccessRequestActivityLogInternal({
	request,
}: DataAccessRequestActivityLogProps) {
	const { setUploadingEditorID } = useCommentStoreContext();

	const invalidateComments = useCallback(() => {
		queryClient.invalidateQueries({
			queryKey: commentsQueryKeyFactory.list(1, {
				data_access_request_id: request.id,
			}),
		});
	}, [request.id]);

	const { data: comments, isLoading: isLoadingComments } = useCommentList({
		filters: { data_access_request_id: request.id },
	});

	useEffect(() => {
		if (!!comments && !isLoadingComments) {
			setUploadingEditorID('');
		}
	}, [comments, isLoadingComments, setUploadingEditorID]);

	const activities = useMemo(() => {
		const list: ActivityLogItem[] = [];

		list.push({
			id: 'created-key',
			type: 'audit',
			bullet: <UserAvatar size="xs" user={request.created_by} />,
			title: (
				<Text size="sm" color="text/secondary/default">
					<Text span weight="bold" size="sm" color="text/primary/default">
						{request.created_by.display_name}
					</Text>{' '}
					created the access request
				</Text>
			),
			date: request.created_at,
		});

		if (request.approved_at && request.approved_by) {
			list.push({
				id: 'approved-key',
				type: 'audit',
				bullet: <Icon name="lockOpen" color="icon/success/default" />,
				title: (
					<Text size="sm" color="text/secondary/default">
						<Text span weight="bold" size="sm" color="text/primary/default">
							{request.approved_by.display_name}
						</Text>{' '}
						approved the access request
					</Text>
				),
				date: request.approved_at,
			});

			if (request.approved_expires_at) {
				const expiresAt = dayjs(request.approved_expires_at);

				const expirationInHours = expiresAt.diff(dayjs(), 'hours');
				if (expiresAt.isAfter(dayjs()) && expirationInHours <= 24) {
					list.push({
						id: 'expiring-key',
						type: 'audit',
						bullet: <Icon name="lock" color="icon/warning/default" />,
						title: (
							<Text size="sm" color="text/secondary/default">
								Access will expire in {expirationInHours} hours
							</Text>
						),
						date: dayjs().toISOString(),
					});
				} else if (expiresAt.isBefore(dayjs())) {
					list.push({
						id: 'expired-key',
						type: 'audit',
						bullet: <Icon name="lock" color="icon/primary/default" />,
						title: (
							<Text size="sm" color="text/secondary/default">
								Access expired
							</Text>
						),
						date: request.approved_expires_at,
					});
				}
			}
		} else if (request.rejected_at && request.rejected_by) {
			list.push({
				id: 'rejected-key',
				type: 'audit',
				bullet: <Icon name="lock" color="icon/primary/default" />,
				title: (
					<Text size="sm" color="text/secondary/default">
						<Text span weight="bold" size="sm" color="text/primary/default">
							{request.rejected_by.display_name}
						</Text>{' '}
						rejected the access request
					</Text>
				),
				date: request.rejected_at,
			});
		}

		if (request.cancelled_at && request.cancelled_by) {
			list.push({
				id: 'cancelled-key',
				type: 'audit',
				bullet: <Icon name="lock" color="icon/primary/default" />,
				title: (
					<Text size="sm" color="text/secondary/default">
						<Text span weight="bold" size="sm" color="text/primary/default">
							{request.cancelled_by.display_name}
						</Text>{' '}
						revoked the access request
					</Text>
				),
				date: request.cancelled_at,
			});
		}

		// Add execution logs
		if (request.logs && request.logs.length > 0) {
			request.logs.forEach((log, index) => {
				list.push({
					id: `log-${index}`,
					type: 'audit',
					bullet: <Icon name="code" color="icon/primary/default" />,
					title: (
						<Text size="sm" color="text/secondary/default">
							{log.message}
						</Text>
					),
					date: log.timestamp,
				});
			});
		}

		list.push(
			...(comments?.results ?? []).map(
				(comment) =>
					({
						type: 'comment',
						comment,
						date: comment.created_at,
					}) as CommentItemType
			)
		);

		return sortBy(list, 'date');
	}, [request, comments]);

	return (
		<Stack spacing="xl">
			<Text size="md" fw={500}>
				Activity
			</Text>
			{isLoadingComments ? (
				<ActivityLogV2Skeleton />
			) : (
				<ActivityLogV2
					items={activities}
					onCommentChange={invalidateComments}
				/>
			)}
			{request.status !== 'deleted' && (
				<NewCommentForm request={request} onCreate={invalidateComments} />
			)}
		</Stack>
	);
}

export function DataAccessRequestActivityLog(
	props: DataAccessRequestActivityLogProps
) {
	return (
		<CommentStoreProvider>
			<DataAccessRequestActivityLogInternal {...props} />
		</CommentStoreProvider>
	);
}
