import { Group } from '@mantine/core';
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type { IUser } from '../../../../../api';
import { useUserList } from '../../../../../api';
import { UserAvatarArray } from '../../../../UserAvatar';
import { multiplayerStore } from './MultiplayerEditor.store';
import { ReadOnlyIndicator } from './ReadOnlyIndicator';

interface IMultiplayerUsersProps {
	isReadOnly: boolean;
}

export const MultiplayerUsers = observer(
	({ isReadOnly }: IMultiplayerUsersProps) => {
		const { data: users } = useUserList({});

		const { entityId } = multiplayerStore;
		if (isNil(entityId)) return null;

		const multiplayerUsers: Array<
			IUser & {
				isEditing?: boolean;
				userId?: string;
			}
		> =
			users?.results
				.filter(
					(user) =>
						multiplayerStore.data.get(entityId)?.get(user.id) ||
						multiplayerStore.multiplayerUserHistory.includes(user.id)
				)
				.map((user) => {
					const presence = multiplayerStore.data.get(entityId)?.get(user.id);
					return {
						isEditing: false,
						isLive: !!presence,
						userId: user.id,
						...(presence ?? {}),
						...user,
					};
				})
				.sort((a) => (a.isEditing || a.isLive ? 1 : -1)) ?? [];

		return (
			<Group spacing={6} py="sm">
				<ReadOnlyIndicator isReadOnly={isReadOnly} />
				<UserAvatarArray users={multiplayerUsers} limit={16} />
			</Group>
		);
	}
);
