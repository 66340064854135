import { Divider, Stack } from '@mantine/core';
import type { ApiGetPermissionsTreeResponse } from '@repo/api-codegen';
import { Text, TextArea, TextInput, Title } from '@repo/foundations';
import { FeatureGroup } from './FeatureGroup';
import { toFeatureGroups } from './FeaturePermissionSetting';
import type { IamRoleFormValues } from './types';

export interface IamRoleFormProps {
	form: IamRoleFormValues;
	permissionsTree: ApiGetPermissionsTreeResponse;
	disabled?: boolean;
}

export function IamRoleForm({
	form,
	permissionsTree,
	disabled = false,
}: IamRoleFormProps) {
	const groups = toFeatureGroups(permissionsTree);

	return (
		<Stack spacing="lg">
			<Stack spacing="xs">
				<Title order={3}>General</Title>
				<Stack spacing="md">
					<TextInput
						size="sm"
						label="Name"
						{...form.getInputProps('name')}
						disabled={disabled}
					/>
					<Stack spacing="xs">
						<TextArea
							label="Description"
							minRows={4}
							{...form.getInputProps('description')}
							disabled={disabled}
							maxLength={500}
						/>
					</Stack>
				</Stack>
			</Stack>
			<Divider />
			<Stack spacing="sm">
				<Stack spacing={0}>
					<Title order={3}>Permissions</Title>
					<Text color="text/primary/default" size="sm">
						Set permissions for tailored access control.
					</Text>
				</Stack>
				<Stack spacing="md">
					{groups.map((group) => (
						<FeatureGroup
							key={group.group}
							group={group.group}
							features={group.features.sort((a, b) =>
								a.name.localeCompare(b.name)
							)}
							form={form}
							disabled={disabled}
						/>
					))}
				</Stack>
			</Stack>
		</Stack>
	);
}
