import { createStyles } from '@mantine/core';
import { APP_HEADER_HEIGHT } from './InboxList/constants';

export default createStyles((theme) => ({
	inboxPageStack: {
		height: `calc(100vh - ${APP_HEADER_HEIGHT}px)`,
		borderTopWidth: 1,
		borderTopStyle: 'solid',
		borderTopColor: theme.other.getColor('border/secondary/default'),
	},
	tabsList: {
		borderBottomWidth: 1,
	},
}));
