import {
	createStyles,
	Divider,
	Flex,
	Menu,
	UnstyledButton,
} from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { isEmpty } from 'lib0/object';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useAuthUser, type IWorkspace } from '../../../../api';
import { usePublicEnv } from '../../../../api/hooks/utils/usePublicEnv';
import { handleLogout, isImpersonating } from '../../../../utils/cache/utils';
import { useFeatureFlags } from '../../../../utils/featureFlags';
import { workspaceSelectorStore } from './store';
import { WorkspaceSelectorRegionSubMenu } from './WorkspaceSelectorRegionSubMenu';
import { WorkspaceSelectorSubMenu } from './WorkspaceSelectorSubMenu';

interface WorkspaceSelectorDropdownProps {
	workspaces: IWorkspace[];
	setOpened: (opened: boolean) => void;
}

const useStyles = createStyles((theme) => ({
	container: {
		flexDirection: 'column',
		position: 'relative',
		width: theme.other.space[60],
		paddingTop: theme.spacing['2xs'],
		paddingBottom: theme.spacing['2xs'],
	},
	submenuDropdown: {
		width: theme.other.space[80],
		maxWidth: theme.other.space[80],
		padding: 0,
		marginLeft: 0,
	},
	menuRowButtonContainer: {
		width: '100%',
		padding: `0 ${theme.spacing['2xs']}`,
	},
	menuRowButton: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',

		width: '100%',
		height: theme.other.height.sm,
		padding: `0 ${theme.spacing['2xs']}`,
		borderRadius: theme.radius.sm,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/primary/selected'),
		},
	},
}));

export const WorkspaceSelectorDropdown = observer(
	({ workspaces, setOpened }: WorkspaceSelectorDropdownProps) => {
		const { classes } = useStyles();
		const navigate = useNavigate();
		const { user, isAdminUser } = useAuthUser();

		const { regionSelector, darkMode } = useFeatureFlags();
		const { data } = usePublicEnv();

		const handleSettingsClick = useCallback(
			(e: React.MouseEvent<HTMLButtonElement>) => {
				e.stopPropagation();
				navigate(darkMode ? '/settings/preferences' : '/settings/profile');
				setOpened(false);
			},
			[navigate, setOpened, darkMode]
		);

		return (
			<Flex className={classes.container}>
				<Flex className={classes.menuRowButtonContainer} pb="2xs">
					<UnstyledButton
						className={classes.menuRowButton}
						onClick={handleSettingsClick}
					>
						<Text size="sm">Settings</Text>
					</UnstyledButton>
				</Flex>

				<Divider pb="2xs" />

				{regionSelector && !isEmpty(data?.REGION_OPTIONS) && (
					<Menu
						classNames={{ dropdown: classes.submenuDropdown }}
						offset={{ mainAxis: 2 }}
						position="right-start"
						shadow="md"
						transitionProps={{ transition: 'fade' }}
						trigger="hover"
					>
						<Menu.Target>
							<Flex className={classes.menuRowButtonContainer}>
								<UnstyledButton className={classes.menuRowButton}>
									<Text size="sm">{data?.REGION}</Text>
									<Icon name="chevronRight" color="icon/secondary/default" />
								</UnstyledButton>
							</Flex>
						</Menu.Target>
						<Menu.Dropdown>
							<WorkspaceSelectorRegionSubMenu />
						</Menu.Dropdown>
					</Menu>
				)}

				{(workspaces.length > 1 ||
					isAdminUser ||
					workspaceSelectorStore.account?.is_superuser ||
					user?.email.endsWith('@secoda.co')) &&
					!isImpersonating() && (
						<Menu
							classNames={{ dropdown: classes.submenuDropdown }}
							offset={{ mainAxis: 2 }}
							position="right-start"
							shadow="md"
							transitionProps={{ transition: 'fade' }}
							trigger="hover"
						>
							<Menu.Target>
								<Flex className={classes.menuRowButtonContainer}>
									<UnstyledButton className={classes.menuRowButton}>
										<Text size="sm">Switch workspace</Text>
										<Icon name="chevronRight" color="icon/secondary/default" />
									</UnstyledButton>
								</Flex>
							</Menu.Target>
							<Menu.Dropdown>
								<WorkspaceSelectorSubMenu workspaces={workspaces} />
							</Menu.Dropdown>
						</Menu>
					)}

				<Flex className={classes.menuRowButtonContainer}>
					<UnstyledButton
						className={classes.menuRowButton}
						onClick={() => handleLogout()}
					>
						<Text size="sm">Log out</Text>
					</UnstyledButton>
				</Flex>
			</Flex>
		);
	}
);
