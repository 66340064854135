import type { GithubPullRequestDownstreamEntitiesOut } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { useMemo } from 'react';
import { toTitleCase } from '../../SearchListItem/helpers';
import { IntegrationRender } from '../../TableV2/render';
import type { ExtendedDataTableColumn } from '../../TableV2/types';

export function useColumns(): ExtendedDataTableColumn<GithubPullRequestDownstreamEntitiesOut>[] {
	const columns = useMemo(
		() => [
			{
				accessor: 'title',
				title: 'Title',
				render: (record: GithubPullRequestDownstreamEntitiesOut) => (
					<Text size="sm" truncate>
						{record.title}
					</Text>
				),
			},
			{
				accessor: 'native_type',
				title: 'Type',
				render: (record: GithubPullRequestDownstreamEntitiesOut) => (
					<Text size="sm" truncate>
						{toTitleCase(record.native_type)}
					</Text>
				),
			},
			{
				accessor: 'integration_id',
				title: 'Integration',
				render: (record: GithubPullRequestDownstreamEntitiesOut) => (
					<IntegrationRender record={{ integration: record.integration_id }} />
				),
			},
			{
				accessor: 'depth',
				title: 'Depth',
				render: (record: GithubPullRequestDownstreamEntitiesOut) => (
					<Text size="sm" truncate>
						{record.depth}
					</Text>
				),
			},
		],
		[]
	);

	return columns;
}
