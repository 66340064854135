import {
	createStyles,
	getStylesRef,
	Group,
	Menu,
	Stack,
	Timeline,
	type TimelineItemProps,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { UserAvatar } from '@repo/common/components';
import { Icon, IconButton, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import type { IComment } from '../../../api';
import { useAuthUser, useDeleteComment, useUpdateComment } from '../../../api';
import { formatDateTime } from '../../../utils/time';
import CommentForm from '../../Comment/components/CommentForm';
import { CommentReactionButton } from './CommentReactionButton';
import { CommentReactions } from './CommentReactions';

const useStyles = createStyles(
	(theme, { forceShowActions }: { forceShowActions: boolean }) => ({
		wrapper: {
			[`&:hover .${getStylesRef('actionsGroup')}`]: {
				visibility: 'visible',
			},
		},
		actionsGroup: {
			ref: getStylesRef('actionsGroup'),
			visibility: forceShowActions ? 'visible' : 'hidden',
		},
		editorWrapperEditing: {
			padding: theme.spacing.xs,
			border: `1px solid ${theme.other.getColor('border/primary/default')}`,
			borderRadius: theme.radius.sm,
		},
	})
);

export interface CommentItemProps extends TimelineItemProps {
	comment: IComment;
	onChange?: () => void;
}

export function CommentItem({ comment, onChange, ...props }: CommentItemProps) {
	const { user: currentUser } = useAuthUser();
	const [menuOpened, { toggle: toggleMenu, close: closeMenu }] =
		useDisclosure(false);
	const [editing, { toggle: toggleEdit, close: cancelEditing }] =
		useDisclosure(false);

	const { classes, cx } = useStyles({
		forceShowActions: menuOpened || editing,
	});

	const { mutateAsync: updateComment } = useUpdateComment({
		options: {
			onSuccess: onChange,
		},
	});

	const handleCommentUpdate = useCallback(
		async (commentDefinition: string) => {
			await updateComment({
				data: { id: comment.id, definition: commentDefinition },
			});
			cancelEditing();
		},
		[updateComment, comment.id, cancelEditing]
	);

	const { mutateAsync: deleteComment } = useDeleteComment({
		options: {
			onSuccess: onChange,
		},
	});

	const handleDeleteComment = useCallback(() => {
		deleteComment({ id: comment.id });
	}, [comment.id, deleteComment]);

	const isEdited = dayjs(comment.updated_at).diff(comment.created_at, 's') > 0;
	const isCurrentUserOwner = currentUser?.id === comment.owner.id;

	return (
		<Timeline.Item
			key={comment.id}
			{...props}
			className={cx(props.className, classes.wrapper)}
			bullet={<UserAvatar size="xs" user={comment.owner} />}
			title={
				<Group spacing="sm" noWrap align="flex-start" position="apart">
					<Group spacing="3xs" style={{ flex: 1 }}>
						<Text size="sm" weight="bold">
							{comment.owner.display_name}
						</Text>
						<Text size="sm" color="text/secondary/default">
							·
						</Text>
						<Text size="sm" color="text/secondary/default">
							{formatDateTime(comment.created_at)} {isEdited && '(edited)'}
						</Text>
					</Group>
					<Group spacing={0} className={classes.actionsGroup}>
						<CommentReactionButton comment={comment} />
						{isCurrentUserOwner && (
							<Menu
								shadow="md"
								position="left-start"
								opened={menuOpened}
								onChange={toggleMenu}
								onClose={closeMenu}
							>
								<Menu.Target>
									<IconButton
										iconName="dots"
										variant="tertiary"
										tooltip="More actions"
									/>
								</Menu.Target>
								<Menu.Dropdown>
									<Menu.Item icon={<Icon name="pencil" />} onClick={toggleEdit}>
										Edit
									</Menu.Item>
									<Menu.Item
										icon={<Icon name="trash" />}
										onClick={handleDeleteComment}
									>
										Delete
									</Menu.Item>
								</Menu.Dropdown>
							</Menu>
						)}
					</Group>
				</Group>
			}
		>
			<Stack spacing="sm">
				<CommentForm
					autoFocus
					isEditExisting
					definition={comment.definition}
					onSubmit={handleCommentUpdate}
					onCancel={cancelEditing}
					readOnly={!editing}
					persistEditedValue
				/>
				<CommentReactions comment={comment} />
			</Stack>
		</Timeline.Item>
	);
}
