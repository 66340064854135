import type { IMetricWidgetSample } from '../../../../api';
import { MetricName } from '../../../../api';
import { WidgetType } from '../../../../interfaces';

export const WORKSPACE_ANALYTICS_ALLOWED_WIDGETS_TO_ADD: IMetricWidgetSample[] =
	[
		{
			title: 'Monitor',
			group: 'Monitor',
			groupIconName: 'activity',
			type: WidgetType.MONITOR,
			metric_metadata: {
				metric_name: MetricName.MONITOR,
				user_filter: {},
			},
		},
		{
			title: 'Monitor Count',
			group: 'Monitor',
			groupIconName: 'activity',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.MONITOR_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Incident Count',
			group: 'Monitor',
			groupIconName: 'activity',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.INCIDENT_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'AI chat history',
			group: 'AI',
			groupIconName: 'messageCircleQuestion',
			type: WidgetType.AI_CHAT_HISTORY,
			metric_metadata: {
				metric_name: MetricName.AI_CHAT_HISTORY,
				user_filter: {},
			},
		},
		{
			title: 'AI Chats',
			group: 'AI',
			groupIconName: 'messageCircleQuestion',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.AI_CHAT_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Automation Run Count',
			group: 'Automation',
			groupIconName: 'bolt',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.AUTOMATION_RUN_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Automation Resources Affected Count',
			group: 'Automation',
			groupIconName: 'bolt',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.AUTOMATION_AFFECTED_RECORDS_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Daily Active Users',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.DAILY_ACTIVE_USER_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Weekly Active Users',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.WEEKLY_ACTIVE_USER_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Monthly Active Users',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.MONTHLY_ACTIVE_USER_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Users with Most Views',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_USER_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_USERS,
				fixed_filter: { type: 'view' },
				user_filter: {},
			},
		},
		{
			title: 'Users with Most Edits',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_USER_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_USERS,
				fixed_filter: { type: 'edit' },
				user_filter: {},
			},
		},
		{
			title: 'Users with Most Resources',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_USER_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_RESOURCE_OWNERS,
				user_filter: {},
			},
		},
		{
			title: 'Users with Least Edits',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_USER_TABLE,
			metric_metadata: {
				metric_name: MetricName.LEAST_ACTIVE_EDITORS,
				user_filter: {},
			},
		},
		{
			title: 'Users with Most Searches',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_USER_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_USER_SEARCHES,
				user_filter: {},
			},
		},
		{
			title: 'Teams and Groups with Most Edits',
			group: 'Teams',
			groupIconName: 'usersGroup',
			type: WidgetType.METRIC_GROUP_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_GROUPS,
				fixed_filter: { type: 'edit' },
				user_filter: {},
			},
		},
		{
			title: 'Teams and Groups with Most Views',
			group: 'Teams',
			groupIconName: 'usersGroup',
			type: WidgetType.METRIC_GROUP_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_GROUPS,
				fixed_filter: { type: 'view' },
				user_filter: {},
			},
		},
		{
			title: 'Teams and Groups with Most Glossary Terms',
			group: 'Teams',
			groupIconName: 'usersGroup',
			type: WidgetType.METRIC_GROUP_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_GROUPS_BY_ENTITIES,
				fixed_filter: { entity_type: 'glossary' },
				user_filter: {},
			},
		},
		{
			title: 'Teams and Groups with Most Collections',
			group: 'Teams',
			groupIconName: 'usersGroup',
			type: WidgetType.METRIC_GROUP_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_GROUPS_BY_ENTITIES,
				fixed_filter: { entity_type: 'collection' },
				user_filter: {},
			},
		},
		{
			title: 'Teams and Groups with Most Documents',
			group: 'Teams',
			groupIconName: 'usersGroup',
			type: WidgetType.METRIC_GROUP_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_GROUPS_BY_ENTITIES,
				fixed_filter: { entity_type: 'document' },
				user_filter: {},
			},
		},
		{
			title: 'Teams and Groups with Most Questions',
			group: 'Teams',
			groupIconName: 'usersGroup',
			type: WidgetType.METRIC_GROUP_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_GROUPS_BY_ENTITIES,
				fixed_filter: { entity_type: 'question' },
				user_filter: {},
			},
		},

		{
			title: 'Popular Searches',
			group: 'Searches',
			groupIconName: 'search',
			type: WidgetType.METRIC_SEARCH_TERM_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_SEARCHES,
				user_filter: {},
			},
		},
		{
			title: 'Number of Searches',
			group: 'Searches',
			groupIconName: 'search',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.DAILY_SEARCH_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Questions Asked',
			group: 'Questions',
			groupIconName: 'messageCircleQuestion',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.QUESTIONS_ASKED_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Question Answer Time',
			group: 'Questions',
			groupIconName: 'messageCircleQuestion',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.TIME_QUESTION_AVERAGE,
				user_filter: {},
			},
		},
		{
			title: 'Popular Resources',
			group: 'Resources',
			groupIconName: 'fileAnalytics',
			type: WidgetType.METRIC_RESOURCE_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_RESOURCES,
				user_filter: {},
			},
		},
		{
			title: 'Total Number of Resources',
			group: 'Resources',
			groupIconName: 'fileAnalytics',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.RESOURCE_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Resource Views',
			group: 'Resources',
			groupIconName: 'fileAnalytics',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.DAILY_RESOURCE_VIEW_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Documented Resources Percentage',
			group: 'Resources',
			groupIconName: 'fileAnalytics',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				is_percentage: true,
				metric_name: MetricName.PERCENTAGE_DOCUMENTED_RESOURCES,
				user_filter: {},
			},
		},
		{
			title: 'Top Queried Tables',
			group: 'Resources',
			groupIconName: 'fileAnalytics',
			type: WidgetType.METRIC_QUERIES_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_QUERIED_TABLES,
				user_filter: {},
			},
		},
		{
			title: 'Popular Tags',
			group: 'Resources',
			groupIconName: 'fileAnalytics',
			type: WidgetType.METRIC_TAG_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_TAGS,
				user_filter: {},
			},
		},
	];
