import type { ReactNode } from 'react';

function formatTooltipText(text: string) {
	return (
		<>
			{text.split(/(`[^`]+`)/).map((part, index) => {
				if (part.startsWith('`') && part.endsWith('`')) {
					return (
						<span key={index} style={{ fontFamily: 'monospace' }}>
							{part.slice(1, -1)}
						</span>
					);
				}
				return <span key={index}>{part}</span>;
			})}
		</>
	);
}

export function getFormattedTooltipLabel(label: ReactNode): ReactNode {
	if (typeof label === 'string') {
		return formatTooltipText(label);
	}
	return label;
}
