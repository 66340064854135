import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	item: {
		fontFamily: theme.fontFamily || 'sans-serif',
		WebkitTapHighlightColor: 'transparent',
		fontSize: theme.fontSizes.sm,
		border: 0,
		backgroundColor: 'transparent',
		outline: 0,
		textAlign: 'left',
		textDecoration: 'none',
		boxSizing: 'border-box',
		cursor: 'pointer',
		borderRadius: theme.radius.sm,
		color: theme.other.getColor('text/primary/default'),
		display: 'flex',
		alignItems: 'center',
		margin: `0 ${theme.spacing['2xs']}`,
		width: `calc(100% - (2 * ${theme.spacing['2xs']}))`,
		padding: theme.spacing['2xs'],
		gap: theme.spacing.xs,

		'&:disabled': {
			color: theme.other.getColor('text/primary/disabled'),
			pointerEvents: 'none',
			userSelect: 'none',
		},

		'&[data-hovered], &:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
	},

	itemLabel: {
		flex: 1,
	},

	itemIcon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));
