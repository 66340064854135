import { every } from 'lodash-es';
import { useCallback } from 'react';
import { useDatabuilderJobList } from '..';
import { DatabuilderJobType } from '../../../interfaces';
import queryClient from '../../queryClient';
import type { IDatabuilderJob } from '../../types';
import { databuilderJobQueryKeyFactory } from './constants';
import { useDatabuilderJobPollingContext } from './context';

type IPollArgs = {
	integrationId: string;
	type: 'PUSH' | 'PULL';
	enabled?: boolean;
};

type IPollResults = {
	databuilderJobs?: IDatabuilderJob[];
	isLoading: boolean;
	polling: boolean;
	startPolling: () => void;
	stopPolling: () => void;
};

export const usePollDatabuilderJobList = ({
	integrationId,
	type,
	enabled,
}: IPollArgs): IPollResults => {
	const store = useDatabuilderJobPollingContext();
	const polling = store.isPolling(integrationId, type);

	const setExtractionPolling = useCallback(
		(state: boolean) => {
			store.setPollingState(integrationId, type, state);
		},
		[integrationId, store, type]
	);

	const jobTypeFilter =
		type === 'PULL'
			? [
					DatabuilderJobType.SCHEMA,
					DatabuilderJobType.DUMMY,
					DatabuilderJobType.METADATA,
				]
			: [DatabuilderJobType.PUSH];
	const listFilter = {
		integration_id: integrationId,
		type__in: jobTypeFilter.join(','),
	};

	const startPolling = useCallback(() => {
		setExtractionPolling(true);
	}, [setExtractionPolling]);

	const stopPolling = useCallback(() => {
		setExtractionPolling(false);
	}, [setExtractionPolling]);

	const { data: databuilderJobs, isLoading } = useDatabuilderJobList({
		filters: listFilter,
		options: {
			enabled: enabled || polling,
			refetchInterval: (data) => {
				if (
					!data?.results?.length ||
					every(
						data?.results,
						(job) =>
							job.failed || job.completed || job.terminated || job.timed_out
					)
				) {
					stopPolling();
					return false;
				}
				startPolling();
				return 2000;
			},
		},
	});

	return {
		databuilderJobs: databuilderJobs?.results,
		isLoading,
		polling,
		startPolling,
		stopPolling,
	};
};

// Force fetching for synchronous UI actions without waiting for polling.
export const forceFetchJobs = () => {
	queryClient.invalidateQueries({
		queryKey: databuilderJobQueryKeyFactory.all(),
	});
};
