import { Icon } from '@repo/foundations';
import { ACTIVITY_TYPE_TO_ICON } from './constants';

interface ResourceLogItemIconProps {
	activityType: string;
}

export function ResourceLogItemIcon({
	activityType,
}: ResourceLogItemIconProps) {
	if (!(activityType in ACTIVITY_TYPE_TO_ICON)) {
		return <Icon name="pencil" />;
	}

	return <Icon name={ACTIVITY_TYPE_TO_ICON[activityType]} />;
}
