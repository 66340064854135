import { Box, Menu, Tooltip, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Icon, IconButton, Text } from '@repo/foundations';
import { isNil, size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type { MouseEvent } from 'react';
import type { ILineageTableQuery, ILineageTableTest } from '../../../../api';
import { useAuthUser } from '../../../../api';
import entityDrawerStore from '../../../EntityDrawer/store';
import { impactAnalysisStore } from '../../Modals/ImpactAnalysisModal/store';
import { lineageStore } from '../../store';
import type { LineageBoolean, LineageCount, LineageTypes } from '../../types';
import { LineageDirectionEnum } from '../../types';

interface IMenuButtonProps {
	id: string;
	types: LineageTypes;
	published: boolean;
	count: LineageCount;
	collapsed: LineageBoolean;
	fetched: LineageBoolean;
	selected: boolean;
	creationQuery?: ILineageTableQuery;
	tests?: ILineageTableTest[];
	direction?: LineageDirectionEnum;
	isManual?: boolean;
	isRoot?: boolean;
}

function MenuButton({
	id,
	types,
	published,
	count,
	collapsed,
	fetched,
	selected,
	creationQuery,
	tests,
	direction,
	isManual,
	isRoot,
}: IMenuButtonProps) {
	const theme = useMantineTheme();

	const [opened, { close, toggle }] = useDisclosure(false);

	const { isEditorOrAdminUser, isViewerOrGuestUser } = useAuthUser();

	const canEditInSidebar = !isRoot && !lineageStore.isFullscreen;
	const canFocusOnNode = !isRoot && !lineageStore.isFocused;
	const canViewTestResults = size(tests) > 0;
	const canViewCreationQuery = !isNil(creationQuery && creationQuery?.sql);
	let canCollapse = false;

	if (!isRoot) {
		const directionKey =
			direction === LineageDirectionEnum.UPSTREAM ? 'upstream' : 'downstream';

		canCollapse =
			count[directionKey] > 0 &&
			!collapsed[directionKey] &&
			fetched[directionKey];
	}

	const handleToggle = () => {
		toggle();
	};

	const handleEditInSidebar = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		if (isViewerOrGuestUser && !published) {
			entityDrawerStore.openEntityDrawerWithError();
		} else {
			entityDrawerStore.openEntityDrawerById(isEditorOrAdminUser, id).then();
		}
	};

	const handleFocusOnNode = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		lineageStore.focus(id);
	};

	const handleViewImpactAnalysis = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		impactAnalysisStore.setId(id);
		impactAnalysisStore.setOpened(true);
	};

	const handleViewTestResults = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		lineageStore.setTestsModalOpen(true, tests);
	};

	const handleViewCreationQuery = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		lineageStore.setCreationQueryModalOpen(true, creationQuery);
	};

	const handleDeleteManualLineage = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		lineageStore.deleteManualLineage(id);
	};

	const handleCollapseNode = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		lineageStore.collapseLineage(id, direction!);
	};

	return (
		<Menu
			shadow="md"
			width={theme.other.width.xs}
			position="right-start"
			offset={theme.other.space[4]}
			opened={opened && selected}
			onClose={close}
		>
			<Menu.Target>
				<Tooltip label="More actions" withArrow>
					<IconButton
						iconName="dots"
						onClick={handleToggle}
						data-testid="lineage-entity-node-header-menu-button-YYUo3cjhfc"
						variant="tertiary"
						size="md"
					/>
				</Tooltip>
			</Menu.Target>
			<Menu.Dropdown>
				<Box data-testid="lineage-entity-node-header-menu-dropdown-BCTnGkPsh">
					{canEditInSidebar && (
						<Menu.Item
							icon={<Icon name="layoutSidebarRightExpand" />}
							onClick={handleEditInSidebar}
						>
							Edit in sidebar
						</Menu.Item>
					)}
					{canFocusOnNode && (
						<Menu.Item
							icon={<Icon name="focusCentered" />}
							onClick={handleFocusOnNode}
						>
							Focus lineage
						</Menu.Item>
					)}
					<Menu.Item
						icon={<Icon name="reportSearch" />}
						onClick={handleViewImpactAnalysis}
					>
						Analyze impact
					</Menu.Item>
					{canViewTestResults && (
						<Menu.Item
							icon={<Icon name="circleCheck" />}
							onClick={handleViewTestResults}
						>
							View test results
						</Menu.Item>
					)}
					{canViewCreationQuery && (
						<Menu.Item
							icon={<Icon name="code" />}
							onClick={handleViewCreationQuery}
						>
							View creation query
						</Menu.Item>
					)}
					{canCollapse && (
						<Menu.Item
							icon={
								<Icon
									name={
										direction === LineageDirectionEnum.UPSTREAM
											? 'layoutSidebarRightCollapse'
											: 'layoutSideBarLeftCollapse'
									}
								/>
							}
							onClick={handleCollapseNode}
						>
							Collapse lineage
						</Menu.Item>
					)}
					{isManual && (
						<>
							<Menu.Divider />
							<Menu.Label>Manual Lineage</Menu.Label>
							<Menu.Item
								icon={<Icon name="trash" color={'icon/critical/default'} />}
								onClick={handleDeleteManualLineage}
							>
								<Text size="sm" color="text/critical/default">
									Remove
								</Text>
								<Text size="xxs" color="text/secondary/default">
									{`Remove manually created ${
										direction === LineageDirectionEnum.UPSTREAM
											? 'downstream'
											: 'upstream'
									} connection`}
								</Text>
							</Menu.Item>
						</>
					)}
				</Box>
			</Menu.Dropdown>
		</Menu>
	);
}

export default observer(MenuButton);
