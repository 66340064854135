import { Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
	useApiDeletePolicy,
	useApiDuplicatePolicy,
	type PolicyOut,
} from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { Text, Title } from '@repo/foundations';
import { useCallback, useMemo } from 'react';
import { queryClient } from '../../api';
import { policiesQueryKeyFactory } from '../../api/hooks/policy';
import { closeSpotlight } from '../../components/Spotlight';
import type { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import { useDeleteManyAction } from '../../components/TableV2/BaseModel.hooks';
import { SelectorWithIconRender } from '../../components/TableV2/render';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { PolicyToggle } from '../PolicyPage/PolicyToggle';
import { FrameworkSelector } from './components/FrameworkSelector';
import { useTogglePolicyEnable, useUpdatePolicy } from './hooks';
import { CreatedByRender, PolicyStatusRender } from './render';

export function useColumns(): ExtendedDataTableColumn<PolicyOut>[] {
	const { mutateAsync: togglePolicy } = useTogglePolicyEnable({});
	const toggleCallBack = useCallback(
		(record: PolicyOut, enabled: boolean) => {
			togglePolicy({
				body: {
					enabled,
				},
				pathParams: {
					policyId: record.id,
				},
			});
		},
		[togglePolicy]
	);

	const { mutateAsync: updatePolicy } = useUpdatePolicy({});
	const updateCallBack = useCallback(
		(key: string) => (id: string) => (value: string[] | string | boolean) => {
			updatePolicy({
				body: {
					[key]: value,
				},
				pathParams: {
					policyId: id,
				},
			});
		},
		[updatePolicy]
	);

	const columns: ExtendedDataTableColumn<PolicyOut>[] = useMemo(
		() => [
			{
				accessor: 'name',
				title: 'Policies',
				render: (record) => (
					<Stack role="link" spacing={0} py="xs">
						<Title size="sm" truncate>
							{record.name}
						</Title>
						{/* TODO[tan-policies]: use rich editor for the description render */}
						<Text size="xs" color="text/secondary/default" truncate>
							{record.description}
						</Text>
					</Stack>
				),
				width: 'auto',
			},
			{
				accessor: 'severity',
				title: 'Severity',
				navigate: false,
				render: (record) => (
					<SelectorWithIconRender
						key="severity"
						accessor="severity"
						nilOption="NONE"
						onChange={updateCallBack('severity')}
						record={record}
					/>
				),
				width: 100,
			},
			{
				accessor: 'status',
				title: 'Status',
				navigate: false,
				render: (record) => <PolicyStatusRender record={record} />,
				width: 80,
			},
			{
				accessor: 'Issues',
				title: 'Issues',
				navigate: false,
				render: (record) =>
					record.issues_count > 0 && (
						<Text size="sm">{record.issues_count}</Text>
					),
				width: 80,
			},
			{
				accessor: 'framework',
				title: 'Frameworks',
				navigate: false,
				render: (record) => <FrameworkSelector policy={record} />,
				width: 200,
			},
			{
				accessor: 'created_by',
				title: 'Created by',
				navigate: false,
				render: (record) => <CreatedByRender record={record} />,
				width: 200,
			},
			{
				accessor: 'enabled',
				title: 'State',
				navigate: false,
				render: (record) => (
					<PolicyToggle
						enabled={record.enabled}
						onChange={(enabled) => toggleCallBack(record, enabled)}
					/>
				),
				width: 60,
			},
		],
		[toggleCallBack, updateCallBack]
	);

	return columns;
}

export const useActions = (): ICommandListItem<PolicyOut>[] => {
	const { mutateAsync: deletePolicy } = useApiDeletePolicy({});
	const { mutateAsync: duplicatePolicy } = useApiDuplicatePolicy({});

	const onDelete = useCallback(
		(id: string) => deletePolicy({ pathParams: { policyId: id } }),
		[deletePolicy]
	);

	const { action: deleteManyAction } = useDeleteManyAction<PolicyOut>({
		name: 'policy',
		queryKeys: ['policy', 'policies'],
		onDelete,
	});

	const actions = useMemo(
		() => [
			{
				id: 'actions::duplicate',
				title: 'Duplicate',
				name: 'Duplicate',
				iconName: 'copy' as const,
				hotkey: '/dp',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: (selected: PolicyOut[]) => selected.length === 1,
				onClick: async (selected: PolicyOut[], clearSelected) => {
					if (selected.length === 1) {
						try {
							await duplicatePolicy({
								pathParams: { policyId: selected[0].id },
							});
							queryClient.invalidateQueries({
								queryKey: policiesQueryKeyFactory.all(),
							});
							showNotification({
								message: 'Successfully duplicated policy',
								color: 'green',
							});
						} catch {
							showNotification({
								message: 'Failed to duplicate policy',
								color: 'red',
							});
						} finally {
							clearSelected();
							closeSpotlight('bulkActions');
						}
					}
				},
			},
			deleteManyAction,
		],
		[deleteManyAction, duplicatePolicy]
	);

	return actions;
};
