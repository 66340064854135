import { Icon } from '@repo/foundations';

interface TagIconProps {
	color: string;
}

function TagIcon({ color }: TagIconProps) {
	return (
		<Icon
			iconPadding={0}
			iconWidth={14}
			name="circle"
			style={{ color, fill: color, width: 14, stroke: 'none' }}
		/>
	);
}

export default TagIcon;
