import type { Transform } from '@dnd-kit/utilities';
import { useMantineTheme } from '@mantine/core';
import { integrationList } from '@repo/common/constants/integration/integrations';
import type { EntityType } from '@repo/common/enums/entityType';
import dayjs from 'dayjs';
import produce from 'immer';
import {
	get,
	groupBy,
	isEmpty,
	isEqual,
	map,
	round,
	size,
	uniq,
} from 'lodash-es';
import type {
	IIntegrationMetricMeasurement,
	IMetricWidget,
	IMetricWidgetSample,
} from '../../../api';
import {
	isIntegrationMetricWidget,
	MetricName,
	SearchableEntities,
} from '../../../api';
import type { IAnalyticsMetric } from '../../../api/types/models/analyticsMetric';
import { WidgetSize, WidgetType } from '../../../interfaces';
import { WIDGET_SIZE_MAP } from '../constants';

export function useChartColors() {
	const theme = useMantineTheme();
	return [
		theme.other.getColor('icon/info/default'),
		theme.other.getColor('icon/decorative/grape'),
		theme.other.getColor('icon/decorative/indigo'),
		theme.other.getColor('icon/decorative/orange'),
	];
}

const DEFAULT_WIDGET_NAME_BY_METRIC_NAME: Record<MetricName, string> = {
	DAILY_ACTIVE_USER_COUNT: 'Daily active users',
	WEEKLY_ACTIVE_USER_COUNT: 'Weekly active users',
	MONTHLY_ACTIVE_USER_COUNT: 'Monthly active users',
	RESOURCE_COUNT: 'Total number of resources',
	DAILY_RESOURCE_VIEW_COUNT: 'Resource views',
	DAILY_SEARCH_COUNT: 'Number of searches',
	TOP_RESOURCES: 'Popular resources',
	TOP_SEARCHES: 'Popular searches',
	TOP_USERS: 'Top users',
	TOP_RESOURCE_OWNERS: 'Top resource owners',
	LEAST_ACTIVE_EDITORS: 'Least active editors',
	TOP_USER_SEARCHES: 'Top user searches',
	TOP_GROUPS: 'Teams and Groups with most actions',
	TOP_GROUPS_BY_ENTITIES: 'Teams and Groups with most entities',
	PERCENTAGE_DOCUMENTED_RESOURCES: 'Documented resources percentage',
	QUESTIONS_ASKED_COUNT: 'Questions asked',
	TIME_QUESTION_AVERAGE: 'Question answer time',
	TOP_QUERIED_TABLES: 'Top queried tables',
	TOP_TAGS: 'Top tags',

	SNOWFLAKE_COST: 'Snowflake cost',
	SNOWFLAKE_CREDITS_PER_WAREHOUSE: 'Snowflake credits per warehouse',
	SNOWFLAKE_CREDITS_PER_USER: 'Snowflake credits per user',
	SNOWFLAKE_QUERY_VOLUME: 'Snowflake query volume',
	SNOWFLAKE_STORAGE_USAGE: 'Snowflake storage usage',
	SNOWFLAKE_COMPUTE_USAGE: 'Snowflake compute usage',

	DBT_TEST_RESULTS: 'dbt test results',
	DBT_TABLE_TEST_RESULTS: 'dbt table test results',
	MONITOR: 'Monitor',
	INCIDENT_COUNT: 'Incident count',
	AI_CHAT_HISTORY: 'AI chat history',
	AI_CHAT_COUNT: 'AI chat count',
	MONITOR_COUNT: 'Monitor count',
	AUTOMATION_RUN_COUNT: 'Automation run count',
	AUTOMATION_AFFECTED_RECORDS_COUNT: 'Automation resources affected count',
};

// TODO[tan] Deprecate this. MetricWidget already has a field `title`.
// Keep the util function to avoid breaking changes for existing widgets
export function formatMetricWidgetTitle(
	metricWidget: IMetricWidgetSample | IMetricWidget
): string {
	if (metricWidget?.title) {
		return metricWidget.title;
	}

	if (!metricWidget?.metric_metadata) {
		return 'Metric';
	}

	const metricMetadata = metricWidget.metric_metadata;
	if (metricMetadata.metric_name === MetricName.TOP_USERS) {
		if (metricMetadata.fixed_filter?.type === 'view') {
			return 'Top viewers';
		}

		if (metricMetadata.fixed_filter?.type === 'edit') {
			return 'Top editors';
		}
	}

	return (
		DEFAULT_WIDGET_NAME_BY_METRIC_NAME[metricMetadata.metric_name] ||
		metricMetadata.metric_name
	);
}

export const INTEGRATION_TYPE_KEY = 'integration_type';
export const ENTITY_TYPE_KEY = 'entity_type';
export const NATIVE_TYPE_KEY = 'native_type';
export const GROUP_NAME_KEY = 'group_name';
export const ROLE_KEY = 'role';
export const GROUP_KEY = 'group';
export const TEAM_NAME_KEY = 'teams_name';
export const TIME_TYPE_KEY = 'time_type';
export const DOCUMENTATION_KEY = 'documentation';
export const TIME_SPAN_KEY = 'time_span';
export const LOOKBACK_KEY = 'lookback';
export const OWNER_KEY = 'owner_id';
export const AUTOMATION_STATUS_KEY = 'status';

export function getIntegrationTypeName(integrationType: string) {
	const integration = integrationList.find(
		(f) => f.type === integrationType.toLowerCase()
	);

	return integration?.name || integrationType;
}

export const NUM_TICKS_BY_WIDGET_SIZE: Record<WidgetSize, number> = {
	[WidgetSize.SMALL]: 4,
	[WidgetSize.MEDIUM]: 6,
	[WidgetSize.LARGE]: 8,
	[WidgetSize.FULL]: 12,
};

export function getSelections(
	allFilters: Array<Record<string, string>>,
	key: string
): string[] {
	const selections = uniq(
		allFilters.filter((obj) => obj[key]).map((obj) => obj[key])
	);
	if (key === ENTITY_TYPE_KEY) {
		return selections.filter((entityType) =>
			SearchableEntities.includes(entityType as EntityType)
		);
	}
	return selections;
}

export function getNumActivatedFilter(metricWidget: IMetricWidget) {
	if (metricWidget.type === WidgetType.MONITOR) {
		return 0;
	}
	return size(metricWidget.metric_metadata.user_filter);
}

export function filterOncePerDay(
	metrics: IAnalyticsMetric[]
): IAnalyticsMetric[] {
	return map(
		groupBy(metrics, (metric) =>
			dayjs(metric.created_at).startOf('day').toISOString()
		),
		(group) => group[0]
	);
}

export function findDefaultInterval(metric: IAnalyticsMetric): number {
	switch (metric.name) {
		case MetricName.WEEKLY_ACTIVE_USER_COUNT:
			return 7;

		case MetricName.TIME_QUESTION_AVERAGE:
			return 7;

		case MetricName.MONTHLY_ACTIVE_USER_COUNT:
			return 30;

		default:
			return 1;
	}
}

export function getMetricNameTooltip(metricName: MetricName) {
	switch (metricName) {
		// dbt metrics
		case MetricName.DBT_TEST_RESULTS:
			return 'Results from all dbt test runs, collected from dbt `run_results.json`';
		case MetricName.DBT_TABLE_TEST_RESULTS:
			return 'Results from mapping dbt models on `manifest.json` to the corresponding test results on `run_results.json` file';
		// snowflake metrics
		case MetricName.SNOWFLAKE_COST:
			return 'Cost of Snowflake in currency, from `USAGE_IN_CURRENCY_DAILY` view';
		case MetricName.SNOWFLAKE_CREDITS_PER_WAREHOUSE:
			return 'Credits consumed per warehouse, from `WAREHOUSE_METERING_HISTORY` view';
		case MetricName.SNOWFLAKE_CREDITS_PER_USER:
			return 'Credits consumed per user when querying, from `QUERY_HISTORY` view';
		case MetricName.SNOWFLAKE_QUERY_VOLUME:
			return 'Number of queries executed, from `QUERY_HISTORY` view';
		case MetricName.SNOWFLAKE_STORAGE_USAGE:
			return 'Storage usage in bytes, from `DATABASE_STORAGE_USAGE_HISTORY` view';
		case MetricName.SNOWFLAKE_COMPUTE_USAGE:
			return 'Compute usage in credits, from `METERING_DAILY_HISTORY` view';
		// ai metrics
		case MetricName.AI_CHAT_COUNT:
			return 'Number of AI chats started by members of your workspace';
		case MetricName.AI_CHAT_HISTORY:
			return 'History of AI chats';
		// automation metrics
		case MetricName.AUTOMATION_RUN_COUNT:
			return 'Number of automation runs';
		case MetricName.AUTOMATION_AFFECTED_RECORDS_COUNT:
			return 'Number of resources affected by automation runs';
		// monitor metrics
		case MetricName.MONITOR:
			return 'Number of monitors';
		case MetricName.MONITOR_COUNT:
			return 'Number of monitors';
		case MetricName.INCIDENT_COUNT:
			return 'Number of incidents for monitors';
		// user metrics
		case MetricName.DAILY_ACTIVE_USER_COUNT:
			return 'Number of daily active users';
		case MetricName.WEEKLY_ACTIVE_USER_COUNT:
			return 'Number of weekly active users';
		case MetricName.MONTHLY_ACTIVE_USER_COUNT:
			return 'Number of monthly active users';
		// resource metrics
		case MetricName.RESOURCE_COUNT:
			return 'Number of resources';
		case MetricName.DAILY_RESOURCE_VIEW_COUNT:
			return 'Number of daily resource views';
		case MetricName.TOP_RESOURCES:
			return 'Top resources by views';
		case MetricName.PERCENTAGE_DOCUMENTED_RESOURCES:
			return 'Percentage of documented resources';
		// search metrics
		case MetricName.TOP_SEARCHES:
			return 'Most popular searches';
		case MetricName.DAILY_SEARCH_COUNT:
			return 'Number of daily searches';
		case MetricName.TOP_USERS:
			return 'Users with the highest count';
		case MetricName.TOP_RESOURCE_OWNERS:
			return 'Owners with the most resources owned';
		case MetricName.TOP_USER_SEARCHES:
			return 'Top searches made by users';
		// group metrics
		case MetricName.TOP_GROUPS:
			return 'Top groups';
		case MetricName.TOP_GROUPS_BY_ENTITIES:
			return 'Top 20 teams by number of entities owned';
		case MetricName.LEAST_ACTIVE_EDITORS:
			return 'Least active editors in the group';
		// question metrics
		case MetricName.QUESTIONS_ASKED_COUNT:
			return 'Number of questions asked';
		case MetricName.TIME_QUESTION_AVERAGE:
			return 'Average time to answer questions';
		case MetricName.TOP_QUERIED_TABLES:
			return 'Top queried tables in the past month';
		case MetricName.TOP_TAGS:
			return 'Top 20 most used tags';
		default:
			return '';
	}
}

export function downsample(
	metrics: IAnalyticsMetric[],
	daysDelta: number
): IAnalyticsMetric[] {
	if (metrics.length === 0 || daysDelta === 1) return metrics;

	const result: IAnalyticsMetric[] = [];
	let current = metrics[metrics.length - 1];
	let currentDate = dayjs(current.created_at).startOf('day');
	let nextThreshold = currentDate.subtract(daysDelta, 'day');
	result.push(current);

	for (let i = metrics.length - 2; i >= 0; i -= 1) {
		const metric = metrics[i];
		const metricDate = dayjs(metric.created_at).startOf('day');
		if (!metricDate.isAfter(nextThreshold)) {
			// More than daysDelta between this metric and current
			current = metric;
			currentDate = metricDate;
			nextThreshold = currentDate.subtract(daysDelta, 'day');
			if (metric.name !== 'TIME_QUESTION_AVERAGE' || metric.value !== 0) {
				result.push(current);
			}
		}
	}

	return result.reverse();
}

export const getTransition = (isDragging = false) => ({
	duration: !isDragging ? 0.25 : 0,
	easings: {
		type: 'spring',
	},
	scale: {
		duration: 0.25,
	},
	zIndex: {
		delay: isDragging ? 0 : 0.25,
	},
});

export const getAnimation = (
	transform: Transform | null,
	isDragging = false
) => ({
	x: transform?.x ?? 0,
	y: transform?.y ?? 0,
	scale: isDragging ? 1.05 : 1,
	zIndex: isDragging ? 1 : 0,
});

export const getWidgetSpan = (widgetSize: WidgetSize) =>
	`span ${WIDGET_SIZE_MAP[widgetSize]}`;

// TODO[tan]: Rewrite this to the formatValueDisplay function
export const formatMinutesToReadableTime = (minutes: string | number) => {
	const time = dayjs.duration(Number(minutes), 'minutes');
	const seconds = time.asSeconds();
	let mins = time.asMinutes();
	let hours = time.asHours();
	const days = time.days();
	if (seconds < 60) {
		return `${Math.floor(seconds)} seconds`;
	}
	if (mins < 60) {
		mins = time.minutes();
		return `${mins} minute${mins === 1 ? '' : 's'}`;
	}
	if (hours < 24) {
		hours = time.hours();
		mins = time.minutes();
		if (hours === 1) {
			if (mins === 0) return '1 hour';
			return `1 hr ${mins} minutes`;
		}
		if (mins === 0) return `${hours} hours`;
		return `${hours} hrs ${mins} minutes`;
	}
	hours = time.hours();
	if (days === 1) {
		return `1 day ${hours} hrs`;
	}
	return `${days} days ${hours} hrs`;
};

// When joined, user defined filters overwrite fixed filters
export const joinFilters = (
	metricWidget: IMetricWidget | IMetricWidgetSample
): Record<string, string> =>
	produce({}, (draft: Record<string, string>) => {
		const fixedFilter = metricWidget?.metric_metadata?.fixed_filter || {};
		const userFilter = metricWidget?.metric_metadata?.user_filter || {};
		Object.keys(fixedFilter).forEach((key) => {
			draft[key] = fixedFilter[key];
		});
		Object.keys(userFilter).forEach((key) => {
			draft[key] = userFilter[key];
		});
	});

export const lookbackLabel = (value: string | null) => {
	if (value === 'weekly') {
		return 'Last week';
	}
	if (value === 'monthly') {
		return 'Last month';
	}
	return 'All time';
};

export const hasLookback = (metricWidget: IMetricWidget) => {
	if (isIntegrationMetricWidget(metricWidget)) {
		return true;
	}
	return [
		MetricName.TOP_RESOURCES,
		MetricName.TOP_SEARCHES,
		MetricName.TOP_USERS,
		MetricName.LEAST_ACTIVE_EDITORS,
		MetricName.TOP_USER_SEARCHES,
		MetricName.TOP_GROUPS,
		MetricName.TOP_GROUPS_BY_ENTITIES,
		MetricName.AI_CHAT_HISTORY,
	].includes(metricWidget.metric_metadata.metric_name);
};

/**
 * Convert the internal value to the proper scale for graphing
 */
export const convertValue = (value: number): number => round(value, 2);

/**
 * Keep it as an object to allow flexibility in displaying prefix/suffix
 */
export type ValueDisplay = {
	value: string;
	prefix: string;
	suffix: string;
};

/**
 * Format float to string, max 2 decimals but avoid trailing zeros
 */
export const formatFloat = (value: number): string => {
	if (Number.isInteger(value)) {
		return value.toString();
	}
	return value.toFixed(2);
};

/**
 * Format the value to an object of prefix, value and suffix
 */
export const formatValueDisplay = (
	value: number | string,
	metricMetadata: IMetricWidget['metric_metadata']
): ValueDisplay => {
	if (typeof value === 'string') {
		value = Number(value);
	}

	const prefix = metricMetadata.prefix || '';
	let suffix = '';
	if (
		metricMetadata.suffix &&
		(metricMetadata.metric_name !== MetricName.DBT_TABLE_TEST_RESULTS ||
			!('has_test' in (metricMetadata.value ?? {})))
	) {
		suffix = metricMetadata.suffix;
	}

	if (
		metricMetadata.is_percentage ||
		(metricMetadata.metric_name === MetricName.DBT_TABLE_TEST_RESULTS &&
			'has_test' in (metricMetadata.value ?? {}))
	) {
		return { value: formatFloat(value * 100), prefix, suffix: '%' };
	}

	if (metricMetadata.is_bytes) {
		if (value < 1024) return { value: formatFloat(value), prefix, suffix: 'B' };
		if (value < 1048576)
			return { value: formatFloat(value / 1024), prefix, suffix: 'KB' };
		if (value < 1073741824)
			return { value: formatFloat(value / 1048576), prefix, suffix: 'MB' };
		if (value < 1099511627776)
			return { value: formatFloat(value / 1073741824), prefix, suffix: 'GB' };
		return { value: formatFloat(value / 1099511627776), prefix, suffix: 'TB' };
	}

	if (value < 1000) return { value: formatFloat(value), prefix, suffix };
	if (value < 1000000)
		return { value: `${formatFloat(value / 1000)}K`, prefix, suffix };
	if (value < 1000000000)
		return { value: `${formatFloat(value / 1000000)}M`, prefix, suffix };
	if (value < 1000000000000)
		return { value: `${formatFloat(value / 1000000000)}B`, prefix, suffix };
	return { value: `${formatFloat(value / 1000000000000)}T`, prefix, suffix };
};

export const mergeWorkspaceAnalyticsFilters = (
	currentFilters: Record<string, string>,
	availableFilters: Record<string, string>[] | undefined,
	key: string,
	value: string | null
) =>
	produce(currentFilters, (draft) => {
		if (value) {
			const mergedFilter = {
				...draft,
				[key]: value,
			};

			if (availableFilters?.find((filter) => isEqual(filter, mergedFilter))) {
				// Allow for multiple filter keys if they are available
				return mergedFilter;
			}
			// Otherwise, reset and only use the new filter key
			return { [key]: value };
		}

		if (!value) {
			delete draft[key];
		}
		return draft;
	});

export const isEmptyMetric = (
	datapoints: IAnalyticsMetric[] | IIntegrationMetricMeasurement | undefined
): datapoints is undefined => !datapoints || isEmpty(datapoints);

export function getPointValueFromChannel<T, P>(
	channel: keyof T | ((d: T) => P),
	item: T
) {
	return typeof channel === 'function'
		? channel(item)
		: (get(item, channel) as P);
}
