import { createStyles, Stack } from '@mantine/core';
import type { Filter } from '@repo/api-codegen/api';
import { Button, Icon, Text, TextInputError, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';

import FilterGroup from './FilterGroup';
import { useCreatePolicyStore } from './context';

const useStyles = createStyles((theme) => ({
	addConditionButton: {
		border: `1px dashed ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.sm,
		width: 'fit-content',
		whiteSpace: 'nowrap',
	},
}));

const ConditionsStack = observer(() => {
	const { classes } = useStyles();
	const store = useCreatePolicyStore();

	return (
		<Stack spacing="md">
			<Stack spacing="3xs">
				<Title size="md">Conditions</Title>
				<Text size="sm">An issue is triggered if conditions are not met</Text>
				{store.policyFiltersError && (
					<TextInputError error={store.policyFiltersError} />
				)}
			</Stack>
			{store.policyFilters.map(({ id, filter }) => (
				<FilterGroup
					key={id}
					condition={filter}
					onConditionChange={(new_filter) =>
						store.updatePolicyFilter(id, new_filter)
					}
					onConditionDelete={() => store.removePolicyFilter(id)}
					groupText="Require that "
					addFilterLabelText="Add condition"
				/>
			))}

			<Button
				variant="tertiary"
				className={classes.addConditionButton}
				onClick={() =>
					store.addPolicyFilter({ operator: 'or', operands: [] } as Filter)
				}
			>
				<Text size="sm">Add Condition</Text>
				<Icon name="plus" />
			</Button>
		</Stack>
	);
});

ConditionsStack.displayName = 'ConditionsStack';
export default ConditionsStack;
