import { Box, createStyles, Flex, Group, Stack } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import type { Filter } from '@repo/api-codegen/api';
import { IconButton, Text } from '@repo/foundations';
import { useState } from 'react';
import { useCatalogList } from '../../../api/hooks/resourceCatalog';
import SearchBox from '../../../components/SearchBox/SearchBox';
import { ResourceDisplay } from '../../PoliciesListPage/render';
import { getCountDisplay } from '../../PoliciesListPage/utils';
import PolicySection from '../../PolicyPage/PolicySection';

const useStyles = createStyles((theme) => ({
	row: {
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		padding: theme.spacing.xs,
		paddingLeft: theme.spacing.sm,
	},
}));

export default function ResourceSelectionModalContent({
	filter,
}: {
	filter: Filter;
}) {
	const { classes } = useStyles();
	const [search, setSearch] = useState('');
	const [debouncedSearch] = useDebouncedValue(search, 100);
	const [page, setPage] = useState(1);

	const { data: dataWithSearch, isLoading } = useCatalogList({
		page,
		filters: {
			filter,
			search_term: debouncedSearch,
		},
	});

	return (
		<Stack p={0}>
			<SearchBox
				variant="tertiary"
				placeholder="Search resources"
				onSearch={setSearch}
			/>
			<PolicySection
				title="Resources"
				content={
					<Flex h="50vh" direction="column" sx={{ overflowY: 'auto' }}>
						{dataWithSearch?.results.map((result) => (
							<Box key={result.id} className={classes.row}>
								<ResourceDisplay record={result} />
							</Box>
						))}
					</Flex>
				}
				footer={
					<Group position="apart" p="xs" noWrap>
						<Text color="text/secondary/default" size="xs">
							{isLoading
								? 'Loading...'
								: `${(page - 1) * 50 + 1}-${Math.min(page * 50, dataWithSearch?.count ?? 0)} of ${getCountDisplay(dataWithSearch?.count)} results`}
						</Text>
						<Group spacing="xs">
							<IconButton
								disabled={!dataWithSearch?.meta.previous_page}
								variant="tertiary"
								iconName="chevronLeft"
								onClick={() => setPage(page - 1)}
							/>
							<IconButton
								disabled={!dataWithSearch?.meta.next_page}
								variant="tertiary"
								iconName="chevronRight"
								onClick={() => setPage(page + 1)}
							/>
						</Group>
					</Group>
				}
			/>
		</Stack>
	);
}
