import type { SecodaEditorComponentProps } from '@repo/secoda-editor/types';
import { isNil } from 'lodash-es';
import type { PluginSimple } from 'markdown-it';
import { baseKeymap } from 'prosemirror-commands';
import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';
import type { InputRule } from 'prosemirror-inputrules';
import { inputRules } from 'prosemirror-inputrules';
import { keymap } from 'prosemirror-keymap';
import type { MarkdownParser, MarkdownSerializer } from 'prosemirror-markdown';
import type {
	MarkSpec,
	NodeSpec,
	Node as ProsemirrorNode,
} from 'prosemirror-model';
import { Schema } from 'prosemirror-model';
import type { Plugin, Transaction } from 'prosemirror-state';
import { EditorState } from 'prosemirror-state';
import { AddMarkStep, RemoveMarkStep } from 'prosemirror-transform';
import type { Decoration, NodeViewConstructor } from 'prosemirror-view';
import { EditorView } from 'prosemirror-view';
import { captureError } from '../../web-tracing';
import type { IProseMirrorEditorProps } from './outline/src';
import ComponentView from './outline/src/components/ComponentView';
import type { NodeViewRenderer } from './outline/src/components/NodeViewRenderer';
import type Extension from './outline/src/lib/Extension';
import type { CommandFactory } from './outline/src/lib/Extension';
import ExtensionManager from './outline/src/lib/ExtensionManager';
import Code from './outline/src/marks/Code';
import type Mark from './outline/src/marks/Mark';
import Doc from './outline/src/nodes/Doc';
import type Node from './outline/src/nodes/Node';
import Paragraph from './outline/src/nodes/Paragraph';
import ProseMirrorReactNode from './outline/src/nodes/ReactNode';
import Text from './outline/src/nodes/Text';
import PreventTab from './outline/src/plugins/PreventTab';
import light from './outline/src/styles/theme';

export type SecodaEditorProps = Omit<IProseMirrorEditorProps, 'extensions'> & {
	extensions: Array<Extension | Node | Mark>;
	handleChange?: () => void;
	onFinishedTransactions?: () => void;
};

export class SecodaEditor {
	extensions: ExtensionManager;
	private nodes: { [name: string]: NodeSpec };
	private marks: { [name: string]: MarkSpec };
	schema: Schema;
	plugins: Plugin[];
	private rulePlugins: PluginSimple[];
	private keymaps: Plugin[];
	inputRules: InputRule[];
	serializer: MarkdownSerializer;
	parser: MarkdownParser;
	pasteParser: MarkdownParser;
	private state?: EditorState;
	nodeViews: { [name: string]: NodeViewConstructor };
	commands: Record<string, CommandFactory> = {};
	renderers: Set<NodeViewRenderer<SecodaEditorComponentProps>> = new Set();
	options: SecodaEditorProps;
	view?: EditorView;

	constructor(props: SecodaEditorProps) {
		const {
			extensions = [],
			disableExtensions = [],
			disableInputExtensions = [],
		} = props;

		this.options = props;
		this.extensions = this.createExtensions(
			extensions,
			disableExtensions,
			disableInputExtensions
		);
		this.nodes = this.createNodes();
		this.marks = this.createMarks();
		this.schema = this.createSchema();
		this.plugins = this.createPlugins();
		this.rulePlugins = this.createRulePlugins();
		this.inputRules = this.createInputRules();
		this.keymaps = this.createKeymaps();
		// @ts-expect-error TS(2739): Type 'MarkdownSerializer' is missing the following... Remove this comment to see the full error message
		this.serializer = this.createSerializer();
		this.parser = this.createParser();
		this.pasteParser = this.createPasteParser();
		this.nodeViews = this.createNodeViews();
	}

	setValue(value: string) {
		this.state = this.createState(value);

		return this;
	}

	setReadOnly(readOnly?: boolean) {
		this.options.readOnly = readOnly;

		if (this.view) {
			this.view.update({
				...this.view.props,
				editable: () => !readOnly,
			});
		}

		return this;
	}

	dispatchTransaction(fn: (tr: Transaction) => void) {
		if (!this.state) {
			throw new Error('Editor state is not initialized');
		}

		const { tr } = this.state;
		fn(tr);
		this.state = this.state?.apply(tr);

		return this;
	}

	serialize() {
		if (!this.state) {
			return undefined;
		}

		return this.serializer.serialize(this.state.doc);
	}

	render() {
		if (!this.state) {
			throw new Error('Editor state is not initialized');
		}

		const div = document.createElement('div');
		const view = new EditorView(div, {
			state: this.state,
			nodeViews: this.nodeViews,
		});

		return view.dom.innerHTML;
	}

	private createExtensions(
		extensions?: Array<Extension | Node | Mark>,
		disabledExtensions: string[] = [],
		disableInputExtensions: string[] = []
	) {
		const allExtensions = [
			...[new Doc(), new Paragraph(), new Text(), ...(extensions ?? [])],
		] as (Node | Mark | Extension)[];

		// Need a list of extension before constructing Code plugin type.
		// Can't construct excludes on the fly
		allExtensions.push(
			// Code mark exclude all marks but comment
			new Code({
				excludes: allExtensions
					.filter((ext) => ext.type === 'mark' && ext.name !== 'comment')
					.map((ext) => ext.name)
					.join(' '),
			})
		);

		allExtensions.push(
			// This plugin has to be the last one or it will block the Tab shortcut in other nodes/extensions
			new PreventTab({})
		);

		// mocking out the RichMarkdownEditor react component for now
		return new ExtensionManager(
			allExtensions,
			disabledExtensions,
			disableInputExtensions,
			this
		);
	}

	private createPlugins() {
		return this.extensions.plugins;
	}

	private createRulePlugins() {
		return this.extensions.rulePlugins;
	}

	private createInputRules() {
		return this.extensions.inputRules({
			schema: this.schema,
		});
	}

	private createKeymaps() {
		return this.extensions.keymaps({
			schema: this.schema,
		});
	}

	private createNodes() {
		return this.extensions.nodes;
	}

	private createMarks() {
		return this.extensions.marks;
	}

	private createSchema() {
		return new Schema({
			nodes: this.nodes,
			marks: this.marks,
		});
	}

	private createSerializer() {
		return this.extensions.serializer();
	}

	private createParser() {
		return this.extensions.parser({
			schema: this.schema,
			plugins: this.rulePlugins,
		});
	}

	private createPasteParser() {
		return this.extensions.parser({
			schema: this.schema,
			rules: { linkify: true, disable: ['emoji'] },
			plugins: this.rulePlugins,
		});
	}

	createState(value?: string) {
		const doc = this.createDocument(value ?? '');

		if (isNil(doc)) {
			const error = new Error('Failed to parse document');
			captureError(error);
			throw error;
		}

		return EditorState.create({
			schema: this.schema,
			doc,
			plugins: [
				...this.plugins,
				...this.keymaps,
				dropCursor({ color: light.cursor }),
				gapCursor(),
				inputRules({
					rules: this.inputRules,
				}),
				keymap(baseKeymap),
			],
		});
	}

	private createDocument(content: string) {
		return this.parser.parse(content) ?? undefined;
	}

	private createNodeViews() {
		return (
			this.extensions.extensions.filter(
				(extension: Node | Mark | Extension) =>
					extension instanceof ProseMirrorReactNode && !!extension.component
			) as Array<ProseMirrorReactNode>
		).reduce((nodeViews, extension: ProseMirrorReactNode) => {
			const nodeView = (
				node: ProsemirrorNode,
				view: EditorView,
				getPos: () => number,
				decorations: Array<Decoration>
			) =>
				new ComponentView({
					component: extension.component,
					editor: this,
					node,
					view,
					getPos,
					decorations,
				});

			return {
				...nodeViews,
				[extension.name]: nodeView,
			};
		}, {});
	}

	createView(element?: HTMLElement | null, value?: string) {
		if (!element) {
			throw new Error('createView called before ref available');
		}

		const isEditingCheckbox = (tr: Transaction) =>
			tr.steps.some(
				(step: any) =>
					this.schema.nodes?.checkbox_item?.name &&
					step.slice?.content?.firstChild?.type.name ===
						this.schema.nodes.checkbox_item?.name
			);

		const isCommentTransaction = (tr: Transaction) =>
			tr.steps.some(
				(step: any) =>
					(step instanceof AddMarkStep || step instanceof RemoveMarkStep) &&
					step.mark.type.name === 'comment'
			);

		const self = this;
		this.view = new EditorView(element, {
			state: this.createState(value),
			editable: () => !this.options.readOnly,
			nodeViews: this.nodeViews,
			dispatchTransaction(transaction) {
				// Callback is bound to have the view instance as its this binding
				const { state, transactions } =
					this.state.applyTransaction(transaction);

				// @ts-expect-error TS(2339): Property 'updateState' does not exist on type 'Dir... Remove this comment to see the full error message
				this.updateState(state);

				// If any of the transactions being dispatched resulted in the doc
				// changing then call our own change handler to let the outside world
				// know
				const canEditDoc =
					self.view?.editable ||
					transactions.some(isCommentTransaction) ||
					(self.options.readOnlyWriteCheckboxes &&
						transactions.some(isEditingCheckbox));

				if (transactions.some((tr) => tr.docChanged) && canEditDoc) {
					self.options.handleChange?.();
				}

				self.options.onFinishedTransactions?.();
			},
		});
		// Tell third-party libraries and screen-readers that this is an input
		this.view.dom.setAttribute('role', 'textbox');

		if (this.options.onCreateView) {
			this.options.onCreateView();
		}

		this.commands = this.extensions.commands({
			schema: this.schema,
			view: this.view,
		});

		return this.view;
	}
}
