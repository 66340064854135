import { createStyles, Stack } from '@mantine/core';
import { Prism } from '@mantine/prism';
import { Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { apiWithoutTrailSlash } from '../../../../../network';
import { SearchFilterV2StoreContext } from '../../../Filter';

const useStyles = createStyles((theme) => ({
	root: {
		backgroundColor: theme.other.getColor('surface/secondary/default'),
	},
	code: {
		backgroundColor: 'transparent !important',
	},
	scrollArea: {
		height: 300,
	},
}));

export const ApiFilterPreview = observer(() => {
	const store = useContext(SearchFilterV2StoreContext);
	const { classes } = useStyles();

	if (!store.catalogFilter) {
		return null;
	}

	const encodedURI = encodeURIComponent(
		JSON.stringify(store.catalogFilter, null, 0)
	);
	const fullURL = `${apiWithoutTrailSlash()}/resource/catalog/?filter=${encodedURI}&page=1`;

	return (
		<Stack>
			<Title order={3}>JSON</Title>
			<Prism
				language="json"
				classNames={{
					scrollArea: classes.scrollArea,
					root: classes.root,
					code: classes.code,
				}}
			>
				{JSON.stringify(store.catalogFilter, null, 2)}
			</Prism>

			<Title order={3}>Encoded Params</Title>
			<Prism
				language="bash"
				classNames={{
					root: classes.root,
					code: classes.code,
				}}
			>
				{`filter=${encodedURI}`}
			</Prism>

			<Title order={3}>URL example</Title>
			<Prism
				language="bash"
				classNames={{
					root: classes.root,
					code: classes.code,
				}}
			>
				{fullURL}
			</Prism>

			<Title order={3}>cURL Request Example</Title>
			<Prism
				language="bash"
				classNames={{
					root: classes.root,
					code: classes.code,
				}}
			>
				{`curl '${fullURL}' -H 'Authorization: Bearer <API_KEY>'`}
			</Prism>
		</Stack>
	);
});
