import { useMutation } from '@tanstack/react-query';
import { find, isEmpty, isNil } from 'lodash-es';
import { useCallback } from 'react';
import { useInboxPage } from '../../../pages/InboxPage/InboxPage.hooks';
import { deleteParamsFromUrl } from '../../../utils/url';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import type { IApiListResponse } from '../../types';
import type { INotification } from '../../types/models/notification';
import {
	NOTIFICATION_NAMESPACE,
	notificationQueryKeyFactory,
	notificationUnreadCountQueryKeyFactory,
} from './constants';
import { invalidateNotificationListQuery } from './utils';

export function useMarkNotificationAsRead() {
	const { page, filters } = useInboxPage();

	const mutationFn = async ({
		id,
		all = false,
		events = '',
		status = true,
	}: {
		id?: string;
		all?: boolean;
		events?: string;
		status?: boolean;
	}) => {
		if (!all && isNil(id)) {
			throw new Error('Notification ID is required');
		}

		const path = all ? 'read_all' : 'read';
		const url = getEndpoints(NOTIFICATION_NAMESPACE).byPath([path]);
		const params = all ? { events } : { id, status };

		const { data } = await apiClient.post(url, {}, { params });
		return data;
	};

	const updateNotificationInList = useCallback(
		(id: string, data: Partial<INotification>) => {
			const queryData: IApiListResponse<INotification> | undefined =
				queryClient.getQueryData(
					notificationQueryKeyFactory.list(page, filters)
				);

			if (!isNil(queryData) && !isEmpty(queryData.results)) {
				const notification = find(queryData.results, { id });

				if (!isNil(notification)) {
					queryClient.setQueryData(
						notificationQueryKeyFactory.list(page, filters),
						{
							...queryData,
							results: queryData.results.map((item) =>
								item.id === id ? { ...item, ...data } : item
							),
						}
					);
				}
			}
		},
		[page, filters]
	);

	return useMutation({
		mutationFn,
		onSuccess: (data, variables) => {
			if (variables.all === true) {
				deleteParamsFromUrl('id');
				invalidateNotificationListQuery();

				queryClient.invalidateQueries({
					queryKey: notificationUnreadCountQueryKeyFactory.all(),
				});
			} else if (!isNil(variables.id)) {
				updateNotificationInList(variables.id, {
					read: isNil(variables.status) ? true : variables.status,
				});

				const notificationCountQueryData: Record<'count', number> | undefined =
					queryClient.getQueryData(
						notificationUnreadCountQueryKeyFactory.all()
					);

				if (!isNil(notificationCountQueryData)) {
					queryClient.setQueryData(
						notificationUnreadCountQueryKeyFactory.all(),
						{
							...notificationCountQueryData,
							count: isNil(variables.status)
								? notificationCountQueryData.count - 1
								: notificationCountQueryData.count + 1,
						}
					);
				}
			}
		},
	});
}
