import { Divider, Group, Stack } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import type { DataAccessRequestOut, GrantType } from '@repo/api-codegen';
import {
	apiQueryKey,
	useApiIntegrationShowUsers,
	useApproveAccessRequest,
} from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { Button, MultiSelect, Select } from '@repo/foundations';
import { useFormik } from 'formik';
import { noop } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { queryClient, useIntegration } from '../../../api';
import { ErrorLogs } from '../../ErrorLogs/ErrorLogs';
import { openModal } from '../../ModalManager';
import { ResourceSelector } from '../DataAccessRequestCreateModal/ResourceSelector';
import { DataAccessRequestCredentialsModal } from '../DataAccessRequestCredentialsModal/DataAccessRequestCredentialsModal';
import { GrantNewRole } from './GrantNewRole';

export interface DataAccessRequestApproveModalProps {
	onClose: () => void;
	request: DataAccessRequestOut;
}

export function DataAccessRequestApproveModal({
	onClose,
	request,
}: DataAccessRequestApproveModalProps) {
	const [selectedUser, setSelectedUser] = useState<string | null>(null);
	const [selectedRole, setSelectedRole] = useState<string | null>(null);
	const [selectedPrivileges, setSelectedPrivileges] = useState<string[]>([]);

	const [error, setError] = useState<string | null>(null);

	// Fetch integration details to get the access_request_preference
	const { data: integrationData } = useIntegration({
		id: request.integration_id || '',
	});

	// Use useMemo instead of useEffect to determine the grant type
	const grantType = useMemo(() => {
		let selectedGrantType: GrantType = 'create_and_grant_temporary_role';
		if (integrationData && integrationData.access_request_preference) {
			selectedGrantType = integrationData.access_request_preference;
		}
		return selectedGrantType;
	}, [integrationData]);

	const allowedPrivileges = useMemo(() => {
		// if no privileges are set, allow all of them
		if (
			integrationData &&
			!!integrationData.access_request_allowed_privileges?.length
		) {
			return integrationData.access_request_allowed_privileges;
		}
		return ['SELECT', 'INSERT', 'UPDATE', 'DELETE'];
	}, [integrationData]);

	const { data: grantResult, isLoading: isLoadingUsers } =
		useApiIntegrationShowUsers(
			{
				pathParams: {
					integrationId: request.integration_id || '',
				},
			},
			{
				refetchOnMount: false,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			}
		);

	const { mutate: approveAccessRequest, isLoading } = useApproveAccessRequest({
		onSuccess: (response) => {
			queryClient.invalidateQueries({
				queryKey: apiQueryKey('integration/data-access-requests'),
			});
			queryClient.invalidateQueries({
				queryKey: apiQueryKey('integration/data-access-requests/{request_id}', {
					request_id: request.id,
				}),
			});
			onClose();
			if (response.temporary_user_name && response.temporary_user_password) {
				openModal({
					title: 'View credentials',
					children: <DataAccessRequestCredentialsModal request={response} />,
					size: 'lg',
				});
			}
		},
		// eslint-disable-next-line @typescript-eslint/no-shadow
		onError: (error: unknown) => {
			setError(
				typeof error === 'object' && error !== null && 'detail' in error
					? (error.detail as string)
					: (error as string)
			);
		},
	});

	const formik = useFormik({
		initialValues: {
			duration: request.requested_expires_at
				? new Date(request.requested_expires_at)
				: null,
			reason: '',
		},
		validationSchema: Yup.object().shape({
			duration: Yup.date().nullable(),
			reason: Yup.string(),
		}),
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: async (values) => {
			await approveAccessRequest({
				body: {
					approved_resources: request.requested_resources?.map((resource) => ({
						id: resource.id,
						database_name:
							resource.database_name ??
							(resource.native_type === EntityType.database
								? resource.title
								: null),
						schema_name:
							resource.schema_name ??
							(resource.native_type === EntityType.schema
								? resource.title
								: null),
						table_name:
							resource.table_name ??
							(resource.native_type === EntityType.table
								? resource.title
								: null),
					})),
					approved_expires_at: values.duration
						? values.duration.toISOString()
						: undefined,
					privileges:
						grantType === 'grant_privileges_to_role' ||
						grantType === 'create_and_grant_temporary_role' ||
						grantType === 'create_temporary_user_and_role'
							? selectedPrivileges
							: undefined,
					approved_text: values.reason,
					username: selectedUser,
					grant_type: grantType,
					role: selectedRole,
				},
				pathParams: {
					requestId: request.id,
				},
			});
		},
	});

	const handleClose = useCallback(() => {
		formik.resetForm();
		onClose();
	}, [formik, onClose]);

	return (
		<>
			<form onSubmit={formik.handleSubmit}>
				<Stack spacing="md">
					<ResourceSelector
						integrationId={request.integration_id || ''}
						label="Resources"
						name="resources"
						placeholder="Select"
						readOnly
						initialSelectedValues={request.requested_resources ?? []}
						onChange={noop}
						onBlur={noop}
					/>
					<Divider orientation="horizontal" />

					{grantType === 'create_and_grant_temporary_role' && (
						<Select
							label="Select user"
							placeholder="Choose a user"
							data={
								grantResult?.results?.map((user) => ({
									value: user.name,
									label: `${user.name} ${user.email || ''}`,
								})) || []
							}
							value={selectedUser}
							onChange={setSelectedUser}
							isLoading={isLoadingUsers}
							searchable
						/>
					)}

					{grantType === 'grant_privileges_to_role' && (
						<GrantNewRole
							integrationId={request.integration_id || ''}
							selectedRole={selectedRole}
							database_name={
								request.requested_resources?.[0]?.database_name || ''
							}
							schema_name={request.requested_resources?.[0]?.schema_name || ''}
							table_name={request.requested_resources?.[0]?.table_name || ''}
							onRoleSelect={setSelectedRole}
						/>
					)}

					<MultiSelect
						label="Select privileges"
						placeholder="Select"
						data={allowedPrivileges.map((privilege) => ({
							value: privilege,
							label: privilege,
						}))}
						value={selectedPrivileges}
						onChange={setSelectedPrivileges}
						required
						setValue={noop}
						searchable
					/>

					<DateTimePicker
						label="Access end date"
						placeholder="Indefinite"
						name="duration"
						value={formik.values.duration}
						onChange={(durationValue) =>
							formik.setFieldValue('duration', durationValue)
						}
						clearable
						error={formik.errors.duration}
						onBlur={formik.handleBlur}
						popoverProps={{
							withinPortal: true,
						}}
					/>
				</Stack>
				<Divider my="md" orientation="horizontal" />
				<Group spacing="xs" position="right">
					<Button onClick={handleClose} disabled={isLoading}>
						Cancel
					</Button>
					<Button
						variant="primary"
						type="submit"
						disabled={
							isLoading ||
							!formik.isValid ||
							!grantType ||
							(grantType === 'create_and_grant_temporary_role' &&
								!selectedUser) ||
							((grantType === 'grant_privileges_to_role' ||
								grantType === 'create_and_grant_temporary_role' ||
								grantType === 'create_temporary_user_and_role') &&
								selectedPrivileges.length === 0)
						}
						loading={isLoading}
					>
						{isLoading ? 'Approving...' : 'Approve request'}
					</Button>
				</Group>
			</form>

			{error && (
				<ErrorLogs title="Approval error" logs={error ? [error] : []} />
			)}
		</>
	);
}
