import {
	createStyles,
	Image,
	SimpleGrid,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { Button, Icon, Text } from '@repo/foundations';
import { filter, includes, isEmpty, map } from 'lodash-es';
import { Link } from 'react-router-dom';
import type { IIntegration } from '../../../../../../../api';
import { useIntegrationList } from '../../../../../../../api';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';
import { WIDGET_CONTENT_HEIGHT } from '../../../constants';
import type { IWidgetItemProps } from '../../../types';
import { getIntegrationsToDisplay } from '../../../utils';

const useStyles = createStyles((theme) => ({
	emptyIntegrationsWidget: {
		height: WIDGET_CONTENT_HEIGHT,
		flexDirection: 'column',
		gap: theme.spacing.md,
	},
	integrationLink: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: theme.spacing.sm,
	},
}));

function IntegrationsWidget({ widget }: IWidgetItemProps) {
	const { classes } = useStyles();
	const { isTeamViewerUser, isTeamsPage, team } = useHomepageTeam();

	const { data } = useIntegrationList({});

	let integrations = (data?.results ?? []) as unknown as IIntegration[];

	if (isTeamsPage) {
		integrations = filter(integrations, ({ teams = [] }) =>
			includes(teams, team.id)
		);
	}

	if (isEmpty(integrations)) {
		return (
			<Stack
				h={
					widget.size === 'full'
						? WIDGET_CONTENT_HEIGHT / 2
						: WIDGET_CONTENT_HEIGHT
				}
				justify="center"
				align="center"
				px="sm"
			>
				<Icon name="plugConnected" />
				<Text align="center" size="sm" color="text/secondary/default">
					Explore the integrations gallery and build the exact workflow you
					need.
				</Text>
				<Button
					disabled={isTeamViewerUser}
					size="sm"
					component={Link}
					to={
						isTeamsPage ? `/integration/new?teamId=${team.id}` : '/integrations'
					}
				>
					Add integrations
				</Button>
			</Stack>
		);
	}

	const integrationsToDisplay = getIntegrationsToDisplay(integrations);

	return (
		<SimpleGrid pt="sm" cols={widget.size === 'full' ? 8 : 4}>
			{map(integrationsToDisplay, ({ id, name, logoSrc, url }) => (
				<UnstyledButton
					key={id}
					component={Link}
					to={url}
					className={classes.integrationLink}
				>
					<Image width={40} src={logoSrc} />
					<Text size="xs" align="center">
						{name}
					</Text>
				</UnstyledButton>
			))}
		</SimpleGrid>
	);
}

export default IntegrationsWidget;
