import { createStyles, Stack } from '@mantine/core';
import { Pagination } from '@repo/common/components/Pagination';
import { map } from 'lodash-es';
import { useCallback } from 'react';
import { useAuthUser } from '../../../api';
import { useMarkNotificationAsRead } from '../../../api/hooks/notification';
import type { INotification } from '../../../api/types/models/notification';
import { trackEvent } from '../../../utils/analytics';
import { setParamsInUrl } from '../../../utils/url';
import { APP_HEADER_HEIGHT } from './constants';
import InboxListHeader from './InboxListHeader/InboxListHeader';
import InboxListItem from './InboxListItem';

interface IInboxListProps {
	data: INotification[];
	selected?: INotification;
	setSelectedId: (id: string) => void;
	searchTerm: string;
	setSearchTerm: (searchTerm: string) => void;
	page: number;
	setPage: (page: number) => void;
	totalPages: number;
	events: string;
	setEvents: (events: string) => void;
	isInclusive: boolean;
	setIsInclusive: (isInclusive: boolean) => void;
}

const useStyles = createStyles((theme) => ({
	container: {
		width: 360,
		minWidth: 360,
		height: `calc(100vh - ${APP_HEADER_HEIGHT}px)`,
		borderRightWidth: 1,
		borderRightStyle: 'solid',
		borderRightColor: theme.other.getColor('border/secondary/default'),
	},
	listContainer: {
		overflowY: 'auto',
		padding: theme.spacing.sm,
	},
}));

function InboxList({
	data,
	selected,
	setSelectedId,
	searchTerm,
	setSearchTerm,
	page,
	setPage,
	totalPages,
	events,
	setEvents,
	isInclusive,
	setIsInclusive,
}: IInboxListProps) {
	const { classes } = useStyles();
	const { user, workspace } = useAuthUser();

	const { mutate: markAsRead } = useMarkNotificationAsRead();

	const handleOnPageChange = useCallback(
		(p: number) => {
			setPage(p);
			setParamsInUrl('page', p);
		},
		[setPage]
	);

	const handleOnSelect = useCallback(
		(id: string, read: boolean) => {
			trackEvent(
				'inbox/notification/click',
				{
					notificationId: id,
				},
				user,
				workspace!
			);
			setSelectedId(id);
			setParamsInUrl('id', id);

			if (!read) {
				markAsRead({
					id,
				});
			}
		},
		[markAsRead, setSelectedId, user, workspace]
	);

	return (
		<Stack className={classes.container} spacing={0}>
			<InboxListHeader
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				events={events}
				setEvents={setEvents}
				isInclusive={isInclusive}
				setIsInclusive={setIsInclusive}
				selected={selected}
			/>
			<Stack className={classes.listContainer} spacing={0}>
				{map(data, (item) => (
					<InboxListItem
						key={item.id}
						item={item}
						selected={selected?.id === item.id}
						onSelect={handleOnSelect}
					/>
				))}
			</Stack>
			<Pagination
				page={page}
				total={totalPages}
				siblings={1}
				onChange={handleOnPageChange}
			/>
		</Stack>
	);
}

export default InboxList;
