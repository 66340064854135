import type { ISecodaEntity } from '../../api';
import type { PropertySelectorProps } from './PropertySelector';
import { PropertySelector } from './PropertySelector';

export interface GovernancePropertySelectorProps
	extends Pick<PropertySelectorProps, 'onChange'> {
	entity: ISecodaEntity;
	viewerOfEntity: boolean;
}

export function GovernancePropertySelector({
	entity,
	viewerOfEntity,
	...propertySelectorProps
}: GovernancePropertySelectorProps) {
	return (
		<PropertySelector
			selected={!!entity.pii}
			type="single"
			value="pii"
			iconType="tabler"
			isViewerUser={viewerOfEntity}
			readOnly={viewerOfEntity}
			{...propertySelectorProps}
		/>
	);
}
