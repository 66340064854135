import { Center, Divider, Group, Stack, createStyles } from '@mantine/core';
import { capitalize, filter, isEmpty, isNil, isString, map } from 'lodash-es';

import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Icon, Text, Title } from '@repo/foundations';
import type React from 'react';
import ReactMarkdown from 'react-markdown';
import { useUser } from '../../../../../../../api';
import { useNotificationList } from '../../../../../../../api/hooks/notification';
import type { INotification } from '../../../../../../../api/types/models/notification';
import { UserAvatar } from '../../../../../../../components/UserAvatar';
import { setParamsInUrl } from '../../../../../../../utils/url';
import { WIDGET_CONTENT_HEIGHT } from '../../../constants';

const useStyles = createStyles((theme) => ({
	emptyState: {
		height: WIDGET_CONTENT_HEIGHT,
		flexDirection: 'column',
		gap: theme.spacing.md,
	},
	announcement: {
		cursor: 'pointer',
		borderRadius: theme.radius.xs,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/transparent/hover'),
		},
	},
	markdown: {
		color: theme.fn.themeColor('gray', 6),
		fontSize: theme.fontSizes.sm,
	},
}));

function AnnoucementUser({ notification }: { notification: INotification }) {
	const { classes } = useStyles();
	const { data: user } = useUser({
		id: notification.sender.id,
		options: {
			enabled: !isNil(notification.sender?.id),
		},
	});
	const { content, metadata } = notification;
	return (
		<>
			<Group align="self-start" noWrap>
				{user && <UserAvatar size="md" user={user} />}
				<Stack spacing={2}>
					<Title order={6} lineClamp={1}>
						{capitalize(content)}
					</Title>
					<Text lineClamp={2}>
						<ReactMarkdown className={classes.markdown}>
							{isString(metadata?.definition) ? metadata.definition : ''}
						</ReactMarkdown>
					</Text>
				</Stack>
			</Group>
			<Divider />
		</>
	);
}

function AnnouncementWidget() {
	const { classes } = useStyles();
	const navigateHandler = useNavigateHandler();

	const { isFetched, data: notifications } = useNotificationList({
		filters: {
			distinct: 'dispatch_id',
			include_myself: true,
		},
		options: {
			select: ({ results }) => filter(results, { event: 'announcement' }),
		},
	});

	if (isFetched && isEmpty(notifications)) {
		return (
			<Center className={classes.emptyState}>
				<Icon name="speakerphone" size="lg" color="icon/primary/default" />
				<Stack spacing="none">
					<Text
						size="sm"
						weight="bold"
						align="center"
						color="text/secondary/default"
					>
						No new announcements
					</Text>
					<Text size="sm" align="center" color="text/secondary/default">
						Check back later for updates
					</Text>
				</Stack>
			</Center>
		);
	}

	const handleAnnouncementClick =
		(notification: INotification) => (event: React.MouseEvent) => {
			navigateHandler(event)('/inbox');
			setParamsInUrl('id', notification.id);
		};

	return (
		<Stack spacing={0}>
			{map(notifications, (notification) => (
				<Stack
					key={notification.id}
					pt="sm"
					px="sm"
					spacing="sm"
					className={classes.announcement}
					onClick={handleAnnouncementClick(notification)}
				>
					<AnnoucementUser notification={notification} />
				</Stack>
			))}
		</Stack>
	);
}

export default AnnouncementWidget;
