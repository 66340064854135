import type { PermissionType } from '@repo/api-codegen';
import type { UserRole } from '@repo/common/enums/UserRole';
import type { ISecodaEntity } from '../../api';
import type { SecodaEntity } from '../../lib/models/entity';
import { useCheckIAMPermission } from '../../utils/authorization/roles';

export function Guard({
	children,
	v1AllowedRoles,
	v2Permission,
	entityId,
	entity,
	defaultResult,
}: {
	children?: React.ReactNode;
	v1AllowedRoles: Array<UserRole>;
	v2Permission: PermissionType;
	entityId?: string;
	entity?: SecodaEntity | ISecodaEntity;
	defaultResult?: boolean;
}) {
	const { hasPermission } = useCheckIAMPermission({
		v1AllowedRoles,
		v2Permission,
		entityId,
		entity,
		defaultResult,
	});

	if (hasPermission) {
		return <>{children}</>;
	}

	return null;
}
