import { useLocalStorage } from '@mantine/hooks';
import type { FeatureAccess } from '@repo/api-codegen';
import { useGetWorkspaceFeatureAccess } from '@repo/api-codegen';
import { useEffect } from 'react';
import { usePublicEnv } from '../utils/usePublicEnv';
import { useWorkspace } from '../workspace';

// Local storage key for caching feature access
const FEATURE_ACCESS_KEY = 'feature_access';

// All features are disabled by default
const DEFAULT_FEATURES: FeatureAccess = {
	monitoring_access: false,
	automations_access: false,
	ai_access: false,
	api_access: false,
	analytics_access: false,
	saml_access: false,
	tunnel_access: false,
	group_access: false,
	dqs_access: false,
	policies_access: false,
};

/**
 * Hook to manage feature access flags for the workspace.
 * Features are stored in local storage and synced with the server.
 * For on-premise installations, all features are automatically enabled.
 */
export function useFeatureAccess() {
	const { workspace } = useWorkspace();
	const { data: publicEnv } = usePublicEnv({});

	// Initialize local storage with default features
	const [cachedFeatures, setCachedFeatures] = useLocalStorage<FeatureAccess>({
		key: FEATURE_ACCESS_KEY,
		defaultValue: DEFAULT_FEATURES,
	});

	// Fetch current features from server
	const {
		data: serverFeatures,
		isLoading,
		isError,
	} = useGetWorkspaceFeatureAccess(
		{},
		{
			enabled: Boolean(workspace?.id && publicEnv),
			placeholderData: cachedFeatures,
		}
	);

	useEffect(() => {
		if (serverFeatures && !isLoading && !isError) {
			setCachedFeatures(serverFeatures);
		} else if (isError && publicEnv?.IS_ON_PREMISE) {
			// Enable all features for on-premise installations
			const allEnabled = Object.fromEntries(
				Object.keys(DEFAULT_FEATURES).map((key) => [key, true])
			) as FeatureAccess;
			setCachedFeatures(allEnabled);
		}
	}, [isError, isLoading, publicEnv, serverFeatures, setCachedFeatures]);

	// Convert snake_case to camelCase for consistent API
	return {
		monitoringAccess: cachedFeatures.monitoring_access,
		automationsAccess: cachedFeatures.automations_access,
		aiAccess: cachedFeatures.ai_access,
		apiAccess: cachedFeatures.api_access,
		analyticsAccess: cachedFeatures.analytics_access,
		samlAccess: cachedFeatures.saml_access,
		tunnelAccess: cachedFeatures.tunnel_access,
		groupAccess: cachedFeatures.group_access,
		dqsAccess: cachedFeatures.dqs_access,
		// TODO[tan-policies]: remove this on release
		policiesAccess: true,
		isLoading,
	};
}
