/* eslint-disable no-use-before-define */
import { Box, Group, Menu, Skeleton, Stack } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import {
	useListCustomProperties,
	useUpdateEntityCustomProperty,
	type Filter as CatalogFilter,
} from '@repo/api-codegen';
import { FILTER_OPTIONS_DIVIDER } from '@repo/common/components/Filter/constants';

import { pluralize } from '@repo/common/utils';
import { Icon, IconButton } from '@repo/foundations';
import type { DataTableColumn, DataTableProps } from '@repo/mantine-datatable';
import { DataTable } from '@repo/mantine-datatable';

import type { FilterOptionType } from '@repo/common/components/Filter';
import type { FilterOption } from '@repo/common/components/Filter/types';
import { EntityType } from '@repo/common/enums/entityType';
import { IconArrowDown } from '@tabler/icons-react';
import { isEmpty } from 'lib0/object';
import { floor, isNil, noop } from 'lodash-es';
import { comparer, reaction, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { ChangeEvent, MouseEvent } from 'react';
import React, {
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import type { ApiCatalogSort, IBaseModel } from '../../api';
import { useAuthUser } from '../../api';
import type {
	FetchModelInfiniteListHook,
	FetchModelList,
	FetchModelListHook,
} from '../../api/factories/types';
import { useCurrentTeam } from '../../api/hooks/team/myMemberships';
import { saveBlob } from '../../lib/models';
import AddCustomProperty from '../../pages/TableEntityPage/TableEntityTabs/ColumnsTab/AddCustomProperty';
import { getParamsFromUrl } from '../../utils/url';
import { useBackgroundJob2 } from '../BackgroundJobProgress/BackgroundJob2.hooks';
import CustomizeColumnsPanel from '../CatalogView/CustomizeColumnsPanel';
import type { ColumnName } from '../CatalogView/helpers';
import type { CatalogServerType } from '../CatalogView/types';
import {
	SearchFilterV2StoreContext,
	useFilterStoreWithPersistence,
} from '../Filter';
import { useFilterViews } from '../Filter/Views/useFilterViews';
import SearchBox from '../SearchBox/SearchBox';
import type { ICommandListItem } from '../Spotlight/components/CommandPalette/constants';
import { FilterBar } from './Filters/FilterBar';
import { useFilterViewsNavbar } from './Filters/useFilterViewsNavbar';
import { rowSx, useTableStyles } from './TableV2.styles';
import { generateCsvFromResults } from './TableV2.utils';
import { TableV2Dialog } from './TableV2Dialog';
import {
	DEFAULT_MAX_RECORD_SIZE,
	DEFAULT_PAGINATION_SIZE,
	SKIP_RESTRICTED_FILTERS,
} from './constants';

import { useFeatureFlags } from '../../utils/featureFlags';
import { getCustomPropertyAsFilter } from '../Filter/customPropertyUtils';
import { TableV2Skeleton } from './TableV2Skeleton';
import { handleCsvImport } from './helpers/csvImport';
import { handleServerCsvExport } from './helpers/serverCsvExport';
import { useStyles } from './styles';
import type {
	ExtendedDataTableColumn,
	OnCellClickHandlerParams,
} from './types';
import { useTableShortcuts } from './useTableShortcuts';
import { useTableV2Columns } from './useTableV2Columns';
import { useTableV2Data } from './useTableV2Data';
import { useTableV2Height } from './useTableV2Height';

const SORT_ICONS = {
	sorted: <IconArrowDown size={14} />,
	unsorted: null,
};

const DEFAULT_QUICK_ACTIONS = [
	'actions::pii',
	'actions::verified',
	'actions::sidebar',
	'actions::ai',
	'actions::delete',
] as const;

const DEFAULT_REQUIRED_OPTIONS = {};
const DEFAULT_REQUIRED_SEARCH_PARAMS = {};
const NO_RECORDS_ICON = <Icon size="lg" name="search" />;

export interface ITableV2Props<T extends IBaseModel> {
	pluralTypeString?: string;
	onSelectedRecordsStateChange?: (selectedRecordsState: {
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	}) => void;
	withInfiniteScroll?: boolean;
	withDialog?: boolean;
	tableCacheKey?: string;
	height?: string | number;
	withStickyColumn?: boolean;
	withStickyColumnBorder?: boolean;
	withInteractiveHeader?: boolean;
	withCsvExport?: boolean;
	withServerCsvExport?: boolean;
	withAddProperty?: string;
	withCustomProperties?: EntityType[];
	withCheckbox?: boolean;
	showFilterViews?: boolean;
	defaultSort?: ApiCatalogSort | null;
	withSearch?: boolean;
	withFilterOptions?: Array<FilterOption | typeof FILTER_OPTIONS_DIVIDER>;
	defaultRequiredOptions?: Record<string, unknown>;
	defaultRequiredSearchParams?: Record<string, unknown>;
	defaultRequiredSearchParamsNesting?: Record<string, string | boolean>;
	defaultRequiredCatalogFilters?: {
		operands: readonly CatalogFilter[];
	};
	nestingFilter?: string;
	withActions?: ICommandListItem<T>[];
	withAdditionalButtons?: React.ReactNode | null;
	withQuickActions?: readonly string[];
	columnVisibility?: {
		catalogType?: string;
		catalogServerType?: CatalogServerType;
		catalogEntityId?: string;
	};
	onCellClick?: (params: OnCellClickHandlerParams<T>) => void;
	onRowClick?: (row: T, index: number, event: MouseEvent<unknown>) => void;
	onTotalRowCountChange?: (totalCount: number) => void;
	onAddProperty?: (columnName: ColumnName) => void;
	columns: DataTableColumn<T>[];
	usePaginationList: FetchModelListHook<T> | FetchModelInfiniteListHook<T>;
	fetchPaginationList?: FetchModelList<T>;
	additionalFilters?: readonly string[];
	listFilterFunction?: (el: T) => boolean;
	usePaginationListOptions?: Record<string, unknown>;
	excludeItems?: { readonly [key in FilterOptionType]?: string[] };
	useCodegenListInterface?: boolean;
	noRecordsText?: string;
	paginationSize?: number;
}

const DataTableComponent = DataTable as <T>(
	props: Omit<DataTableProps<T>, 'page'> &
		Partial<{
			withStickyColumnBorder?: boolean;
			page: number & Partial<{ paginationSize: 'sm' | 'md' | 'lg' }>;
			nested: boolean;
			endReached?: () => void;
			nextPageFetching?: boolean;
			maxHeight?: number;
		}>
) => JSX.Element;

export function TableV2Loader() {
	const { theme } = useStyles();
	const ref = useRef<HTMLDivElement>(null);

	return (
		<Stack
			ref={ref}
			h="100%"
			w="100%"
			bg={theme.other.getColor('surface/app/default')}
			py={theme.other.space[3]}
			px={theme.other.space[3]}
			spacing={theme.other.space[4]}
		>
			{Array.from({
				length:
					floor(
						(ref.current?.getBoundingClientRect().height ?? 1000) /
							(theme.other.space[4] * 2)
					) + 1,
			}).map((_, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<Skeleton radius="lg" key={i} h={theme.other.space[4]} />
			))}
		</Stack>
	);
}

const TableV2 = observer(<T extends IBaseModel>(props: ITableV2Props<T>) => {
	const {
		tableCacheKey,
		pluralTypeString,
		onSelectedRecordsStateChange,
		onRowClick,
		onCellClick,
		onAddProperty,
		withCustomProperties,
		withInfiniteScroll = false,
		withStickyColumn = true,
		withStickyColumnBorder = true,
		withDialog = true,
		showFilterViews = false,
		withCsvExport = false,
		withServerCsvExport = false,
		withAddProperty = '',
		withCheckbox: withCheckboxParam,
		withSearch = false,
		withAdditionalButtons = null,
		withActions = [],
		withQuickActions = DEFAULT_QUICK_ACTIONS,
		defaultRequiredOptions = DEFAULT_REQUIRED_OPTIONS,
		defaultRequiredSearchParams = DEFAULT_REQUIRED_SEARCH_PARAMS,
		additionalFilters,
		withFilterOptions,
		excludeItems,
		columnVisibility,
		nestingFilter,
		height,
		noRecordsText,
		paginationSize = DEFAULT_PAGINATION_SIZE,
	} = props;

	// The key for storing the sort and filter preferences in local storage.
	const pathKey = btoa(window.location.pathname);
	const storeColumnsKey = `${pathKey}${tableCacheKey ?? ''}${
		columnVisibility?.catalogEntityId
	}${columnVisibility?.catalogServerType}`;
	const sortPreferenceKey = `sort-v2-${storeColumnsKey}`;
	const filterPreferenceKey = `filters-v2-${storeColumnsKey}`;
	const searchPreferenceKey = `search-v2-${storeColumnsKey}`;

	// ------------------------------ FILTERS ------------------------------
	const filtersQueryStringKey = 'filters';
	const hasFiltersInUrl =
		getParamsFromUrl()?.get(filtersQueryStringKey) !== null;

	const { customPropertiesFilter } = useFeatureFlags();

	const { data: customProperties } = useListCustomProperties(
		{},
		{
			enabled: !!withCustomProperties,
			select: (data) =>
				data.filter(
					(cp) =>
						withCustomProperties &&
						(cp.entity_types.includes(EntityType.all) ||
							cp.entity_types.some((et) =>
								withCustomProperties.includes(et as EntityType)
							))
				),
			suspense: true,
		}
	);

	const filterOptions = useMemo(() => {
		if (withFilterOptions) {
			if (
				customPropertiesFilter &&
				withCustomProperties &&
				!!customProperties?.length
			) {
				return [
					...withFilterOptions,
					FILTER_OPTIONS_DIVIDER,
					...(customProperties
						.map(getCustomPropertyAsFilter)
						.filter(Boolean) as FilterOption[]),
				];
			}

			return withFilterOptions;
		}

		return [];
	}, [
		withFilterOptions,
		withCustomProperties,
		customProperties,
		customPropertiesFilter,
	]);

	const { searchFilterV2Store } = useFilterStoreWithPersistence({
		filterOptions,
		filterLocalStorageKey: filterPreferenceKey,
		sortLocalStorageKey: sortPreferenceKey,
		filtersQueryStringKey,
		excludeItems,
	});

	useFilterViewsNavbar({
		showFilterViews,
		searchFilterV2Store,
	});

	// ------------------------------ /FILTERS ------------------------------

	const { allColumns, effectiveColumns } = useTableV2Columns({
		...props,
		storeColumnsKey,
		searchFilterV2Store,
		customProperties,
	});

	const { isViewerOrGuestUser } = useAuthUser();
	const withCheckbox = withCheckboxParam && !isViewerOrGuestUser;

	let stickyColumn = withStickyColumn ? 1 : 0;
	if (withCheckbox) {
		stickyColumn += 1;
	}

	const { classes } = useStyles();
	const { classes: tableClasses } = useTableStyles({
		hasNestingFilter: !isNil(nestingFilter),
		hideCheckbox: false,
		stickyColumn,
		withStickyColumnBorder,
	});

	const [selectedRecordsState, setSelectedRecordsState] = useState<{
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	}>({
		selectedRecords: [],
		lastSelectedIndex: null,
	});

	useEffect(() => {
		onSelectedRecordsStateChange?.(selectedRecordsState);
	}, [onSelectedRecordsStateChange, selectedRecordsState]);

	const [page, setPage] = useState(1);

	const defaultSearchTerm = useMemo(
		() =>
			localStorage.getItem(searchPreferenceKey) && !hasFiltersInUrl
				? (localStorage.getItem(searchPreferenceKey) ?? '')
				: '',
		[hasFiltersInUrl, searchPreferenceKey]
	);

	const [debouncedSearch, setDebouncedSearch] = useDebouncedState<string>(
		defaultSearchTerm,
		// Make negligible to avoid unnecessary re-renders.
		15
	);

	const handleSearch = useCallback(
		(value: string) => {
			localStorage.setItem(searchPreferenceKey, value);
			setDebouncedSearch(value);
			// If we change the search term, reset to the first page
			if (!withInfiniteScroll) setPage(1);
		},
		[searchPreferenceKey, setDebouncedSearch, withInfiniteScroll]
	);

	const { views } = useFilterViews();

	const { currentTeamId } = useCurrentTeam();
	const defaultView = views.find((v) =>
		v.is_default_for_teams?.includes(currentTeamId ?? '')
	);

	useEffect(() => {
		if (
			showFilterViews &&
			defaultView &&
			!searchFilterV2Store.view &&
			!hasFiltersInUrl
		) {
			searchFilterV2Store.setFilterView(defaultView);
		}
	}, [defaultView, searchFilterV2Store, hasFiltersInUrl, showFilterViews]);

	// Reset pagination if filters change.
	useEffect(
		() =>
			reaction(
				() => searchFilterV2Store.catalogFilter,
				() => setPage(1),
				{
					equals: comparer.structural,
				}
			),
		[searchFilterV2Store]
	);

	const {
		records,
		results,
		totalCountWithNesting,
		expandedRecords,
		sort,
		catalogFilter,
		handleEndReached,
		isFetchingNextPage,
		isFetching,
		totalCount,
	} = useTableV2Data({
		...props,
		searchFilterV2Store,
		debouncedSearch,
		sortPreferenceKey,
		filterPreferenceKey,
		searchPreferenceKey,
		setSelectedRecordsState,
		page,
	});

	useTableShortcuts({
		setSelectedRecordsState,
		results,
		selectedRecordsState,
	});

	const { tableRef, tableHeight, tableMaxHeight } = useTableV2Height({
		totalCountWithNesting,
		paginationSize,
		height,
	});

	const allowedFilterOptions = useMemo(() => {
		const restrictFilters = allColumns.reduce(
			(prv: string[], cur: ExtendedDataTableColumn<T>) => {
				if (cur.accessor) {
					prv.push(cur.accessor);
				}
				if (cur.esAccessor) {
					prv.push(cur.esAccessor);
				}
				if (cur.filterOptionType) {
					prv.push(cur.filterOptionType);
				}
				return prv;
			},
			[...SKIP_RESTRICTED_FILTERS, ...(additionalFilters ?? [])]
		);

		return toJS(searchFilterV2Store.filterOptions).filter(
			(option) =>
				option === 'divider' ||
				option.type.startsWith('custom_property_') ||
				(restrictFilters
					? restrictFilters?.includes(option.type) ||
						restrictFilters?.includes(option.field)
					: true)
		);
	}, [additionalFilters, allColumns, searchFilterV2Store.filterOptions]);

	const { mutateAsync: updateEntityCustomProperty } =
		useUpdateEntityCustomProperty({});

	const handleCsvImportCallback = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			handleCsvImport(event, customProperties, updateEntityCustomProperty);
		},
		[customProperties, updateEntityCustomProperty]
	);

	const [startJob] = useBackgroundJob2(undefined, 'CSV Export Completed', true);

	const serverCsvExportAvailable = withServerCsvExport && currentTeamId;

	const handleServerCsvExportCallback = useCallback(async () => {
		await handleServerCsvExport({
			currentTeamId,
			defaultRequiredOptions,
			withSearch,
			defaultRequiredSearchParams,
			catalogFilter: catalogFilter as CatalogFilter,
			debouncedSearch,
			sort: sort as ApiCatalogSort,
			startJob,
			nestingField: nestingFilter,
			nestedIds: Object.keys(expandedRecords),
		});
	}, [
		currentTeamId,
		defaultRequiredOptions,
		withSearch,
		defaultRequiredSearchParams,
		catalogFilter,
		debouncedSearch,
		sort,
		startJob,
		nestingFilter,
		expandedRecords,
	]);

	const handleCsvExport = useCallback(() => {
		const csv = generateCsvFromResults(
			records as Record<string, unknown>[],
			customProperties
		);
		saveBlob(new Blob([csv], { type: 'text/csv' }), `${page}.csv`);
	}, [records, page, customProperties]);

	return (
		<SearchFilterV2StoreContext.Provider value={searchFilterV2Store}>
			<Stack spacing="sm" h="100%">
				{(withSearch || withAdditionalButtons) && (
					<Stack spacing="sm">
						<Group className={classes.searchControls}>
							<Group sx={{ flexGrow: 1 }}>
								{withSearch && (
									<SearchBox
										key={defaultSearchTerm}
										variant="tertiary"
										placeholder={
											searchFilterV2Store.view
												? `Search in ${searchFilterV2Store.view.label}`
												: `Search ${pluralize(pluralTypeString ?? 'resources')}`
										}
										onSearch={handleSearch}
										onlySearchOnEnter
										defaultSearchTerm={defaultSearchTerm}
										autoFocus={!isEmpty(debouncedSearch)}
									/>
								)}
							</Group>
							<Group>
								{withAdditionalButtons}

								{!isViewerOrGuestUser &&
									columnVisibility?.catalogType &&
									columnVisibility?.catalogServerType &&
									(isNil(searchFilterV2Store.view) ||
										searchFilterV2Store?.view?.isOwner) && (
										<CustomizeColumnsPanel<T>
											defaultColumns={allColumns}
											catalogType={columnVisibility.catalogType}
											catalogServerType={columnVisibility.catalogServerType}
											referenceId={
												// If there is a view, use the `id` of that view.
												// Otherwise, use the `entityId` from the column visibility.
												searchFilterV2Store.view?.id ??
												columnVisibility?.catalogEntityId
											}
										/>
									)}
								{(withCsvExport || withAddProperty) && (
									<Menu
										position="bottom-end"
										closeOnItemClick={!withAddProperty}
									>
										<Menu.Target>
											<IconButton
												data-testid="dots-button"
												iconName="dots"
												variant="tertiary"
											/>
										</Menu.Target>
										<Menu.Dropdown>
											{withAddProperty && (
												<AddCustomProperty
													onAddPropertyName={onAddProperty ?? (() => {})}
												/>
											)}
											{(withCsvExport || serverCsvExportAvailable) && (
												<>
													<Menu.Item
														icon={<Icon name="download" />}
														onClick={
															serverCsvExportAvailable
																? handleServerCsvExportCallback
																: handleCsvExport
														}
													>
														Export page as CSV
													</Menu.Item>
													{withCustomProperties && (
														<Menu.Item
															icon={<Icon name="upload" />}
															onClick={() => {
																const input = document.createElement('input');
																input.type = 'file';
																input.accept = '.csv';
																input.onchange = (e) => {
																	handleCsvImportCallback(
																		e as unknown as ChangeEvent<HTMLInputElement>
																	);
																};
																input.click();
															}}
														>
															Import custom properties from CSV
														</Menu.Item>
													)}
												</>
											)}
										</Menu.Dropdown>
									</Menu>
								)}
							</Group>
						</Group>
						{!!withFilterOptions?.length && (
							<FilterBar
								searchFilterV2Store={searchFilterV2Store}
								filterOptions={allowedFilterOptions}
							/>
						)}
					</Stack>
				)}
				<Box ref={tableRef}>
					<DataTableComponent
						idAccessor={'_id'}
						withStickyColumnBorder={withStickyColumnBorder}
						endReached={handleEndReached}
						nextPageFetching={isFetchingNextPage}
						maxHeight={tableMaxHeight}
						height={tableHeight}
						storeColumnsKey={storeColumnsKey}
						classNames={tableClasses}
						columns={effectiveColumns}
						fetching={isFetching}
						loadingText="Loading..."
						noRecordsIcon={NO_RECORDS_ICON}
						noRecordsText={
							noRecordsText ??
							`No ${pluralize(pluralTypeString ?? 'resources')} found`
						}
						onCellClick={onCellClick}
						onPageChange={withInfiniteScroll ? undefined : setPage}
						onRowClick={onRowClick}
						page={totalCount > paginationSize ? page : undefined}
						paginationSize="md"
						records={records}
						recordsPerPage={paginationSize}
						onRecordsPerPageChange={noop}
						recordsPerPageOptions={[paginationSize]}
						recordsPerPageLabel=""
						sortStatus={toJS(searchFilterV2Store.tableSort)}
						sortIcons={SORT_ICONS}
						totalRecords={totalCount}
						withBorder
						rowSx={rowSx}
						paginationText={({ from, to, totalRecords }) => {
							const totalRecordsString =
								totalRecords >= DEFAULT_MAX_RECORD_SIZE
									? `${DEFAULT_MAX_RECORD_SIZE}+`
									: totalCountWithNesting.toLocaleString();

							return withInfiniteScroll
								? `Showing ${to.toLocaleString()} of ${totalRecordsString} ${pluralize(
										pluralTypeString ?? 'resources'
									)}`
								: `Showing ${from.toLocaleString()} to ${to.toLocaleString()} of ${totalRecordsString} ${pluralize(
										pluralTypeString ?? 'resources'
									)}`;
						}}
						selectedRecordsState={
							withCheckbox ? selectedRecordsState : undefined
						}
						onSelectedRecordsStateChange={
							withCheckbox ? setSelectedRecordsState : undefined
						}
						customLoader={<TableV2Loader />}
					/>
				</Box>
				{withDialog && (
					<TableV2Dialog
						actions={withActions}
						results={results}
						setSelectedRecordsState={setSelectedRecordsState}
						selectedRecordsState={selectedRecordsState}
						withQuickActions={withQuickActions}
						count={selectedRecordsState.selectedRecords.length}
						totalCount={totalCountWithNesting}
					/>
				)}
			</Stack>
		</SearchFilterV2StoreContext.Provider>
	);
});

function TableV2Wrapper<T extends IBaseModel>(props: ITableV2Props<T>) {
	const { withSearch, withFilterOptions } = props;

	return (
		<Suspense
			fallback={
				<TableV2Skeleton
					withSearch={withSearch}
					withFilters={!!withFilterOptions?.length}
				/>
			}
		>
			<TableV2 {...props} />
		</Suspense>
	);
}

// eslint-disable-next-line react-refresh/only-export-components
export default observer(TableV2Wrapper);
