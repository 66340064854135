import type { MantineTheme } from '@mantine/core';
import { Box, useMantineTheme } from '@mantine/core';
import { SettingsButton } from '@repo/common/components';
import { ResourceIcon } from '@repo/foundations';
import { omitBy } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useNotificationSettingsList } from '../../api';
import type { INotificationSettings } from '../../api/types/models/notification';
import { LoadingSpinner } from '../LoadingSpinner';
import NotificationSettingsList from './NotificationSettingsList';
import { notifications } from './constants';

// Get summary text for notification type
const getNotificationSummary = (
	settings: INotificationSettings,
	theme: MantineTheme,
	type: 'app' | 'email' | 'slack'
): React.ReactNode => {
	if (!settings) {
		return (
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
				<Box
					sx={{
						width: 8,
						height: 8,
						borderRadius: '50%',
						backgroundColor: theme.other.getColor('icon/primary/disabled'),
					}}
				/>
				<span>Off</span>
			</Box>
		);
	}

	const enabledNotificationsKeys = Object.keys(
		omitBy(settings, (n) => !n?.[type])
	).filter((key) => notifications.find((n) => n.attribute === key));

	if (enabledNotificationsKeys.length === 0) {
		return (
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
				<Box
					sx={{
						width: 8,
						height: 8,
						borderRadius: '50%',
						backgroundColor: theme.other.getColor('icon/primary/disabled'),
					}}
				/>
				<span>Off</span>
			</Box>
		);
	}

	let content: string;
	if (enabledNotificationsKeys.length === Object.keys(settings).length) {
		content = 'On for all notifications';
	} else if (enabledNotificationsKeys.length <= 3) {
		content = `On for ${enabledNotificationsKeys
			.map((n) =>
				n.toLowerCase().replaceAll('notify_', '').replaceAll('_', ' ')
			)
			.join(', ')}`;
	} else {
		const firstTwoNotifications = enabledNotificationsKeys
			.slice(0, 2)
			.map((n) =>
				n.toLowerCase().replaceAll('notify_', '').replaceAll('_', ' ')
			)
			.join(', ');
		const remainingCount = enabledNotificationsKeys.length - 2;
		content = `On for ${firstTwoNotifications}, ${remainingCount} others`;
	}

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
			<Box
				sx={{
					width: 8,
					height: 8,
					borderRadius: '50%',
					backgroundColor: theme.other.getColor('icon/success/default'),
				}}
			/>
			<span>{content}</span>
		</Box>
	);
};

export const NotificationSettings = observer(() => {
	const navigate = useNavigate();
	const location = useLocation();

	const { subtab } = useParams();
	const theme = useMantineTheme();

	const { data } = useNotificationSettingsList({});
	const settings = data?.results?.[0] as unknown as INotificationSettings;

	if (!settings) {
		return <LoadingSpinner />;
	}

	// If we're on a detail route, render the appropriate NotificationSettingsList
	if (
		['app', 'email', 'slack'].some((type) => location.pathname.includes(type))
	) {
		return (
			<NotificationSettingsList type={subtab as 'app' | 'email' | 'slack'} />
		);
	}

	// Otherwise, render the settings buttons list
	return (
		<Box>
			<SettingsButton
				title="App"
				description={getNotificationSummary(settings, theme, 'app')}
				iconName="chevronRight"
				variant="tertiary"
				onClick={() => navigate('app')}
				onClickRow={() => navigate('app')}
				leftComponent={<ResourceIcon iconName="desktop" />}
				disabled={true}
			/>
			<SettingsButton
				title="Email"
				description={getNotificationSummary(settings, theme, 'email')}
				iconName="chevronRight"
				variant="tertiary"
				onClick={() => navigate('email')}
				onClickRow={() => navigate('email')}
				leftComponent={<ResourceIcon iconName="mail" />}
				disabled={true}
			/>
			<SettingsButton
				title="Slack"
				description={getNotificationSummary(settings, theme, 'slack')}
				iconName="chevronRight"
				variant="tertiary"
				onClick={() => navigate('slack')}
				onClickRow={() => navigate('slack')}
				leftComponent={<ResourceIcon iconName="slack" />}
				disabled={true}
			/>
		</Box>
	);
});
