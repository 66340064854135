import { Group, Table, ThemeIcon, useMantineTheme } from '@mantine/core';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Icon, Text } from '@repo/foundations';
import type { TableComponentProps } from './MetricWidgetTable';

export function MetricSearchTermTable({
	values,
	infoColumns,
}: TableComponentProps) {
	const theme = useMantineTheme();
	const navigateHandler = useNavigateHandler();

	return (
		<Table highlightOnHover>
			<thead>
				<tr>
					<th>
						<Text color="text/secondary/default" fz="xs" fw={500}>
							Term
						</Text>
					</th>
					{infoColumns.map((col) => (
						<th key={col.fieldName}>
							<Text color="text/secondary/default" fz="xs" fw={500}>
								{col.label}
							</Text>
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{values.map((searchTerm) => (
					<tr
						style={{
							cursor: 'pointer',
						}}
						key={searchTerm.term}
						onClick={(e) =>
							navigateHandler(e)(`/search?term=${searchTerm.term}`)
						}
					>
						<td>
							<Group noWrap h={52}>
								<ThemeIcon
									color={theme.other.getColor('fill/secondary/default')}
									size={32}
								>
									<Icon name="search" color="icon/primary/default" />
								</ThemeIcon>
								<Text
									lineClamp={1}
									color="text/primary/default"
									size="sm"
									fw={500}
								>
									{searchTerm.term}
								</Text>
							</Group>
						</td>
						{infoColumns.map((col) => (
							<td key={col.fieldName}>{searchTerm[col.fieldName]}</td>
						))}
					</tr>
				))}
			</tbody>
		</Table>
	);
}
