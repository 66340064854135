import { Box, createStyles } from '@mantine/core';
import { size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import { useUpdateUserGroup, useUserGroup } from '../../api';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import UserGroupProfileHeader from './components/UserGroupProfileHeader';
import UserGroupProfileTabs from './components/UserGroupProfileTabs';

const useStyles = createStyles(() => ({
	wrapper: {
		padding: 42,
	},
}));

function UserGroupProfilePage() {
	const { id } = useParams();
	const styles = useStyles();

	const { data: group } = useUserGroup({ id: id as string });
	const { mutateAsync: updateUserGroup } = useUpdateUserGroup({});

	const handleIconChange = useCallback(
		async (emoji: string) => {
			if (!group) {
				return;
			}

			await updateUserGroup({
				data: {
					id: group.id,
					icon: emoji,
				},
			});
		},
		[group, updateUserGroup]
	);

	if (!group) {
		return <FullWidthLoadingSpinner />;
	}

	return (
		<Box className={styles.classes.wrapper}>
			<UserGroupProfileHeader
				onIconChange={handleIconChange}
				icon={group.icon ?? ''}
				name={group.name}
				usersCount={size(group.users)}
				createdAt={group.created_at}
			/>
			<UserGroupProfileTabs userGroup={group} />
		</Box>
	);
}

export default observer(UserGroupProfilePage);
