import { Box, Input, Space, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
	SettingsBlock,
	SettingsButton,
	SettingsTextInput,
} from '@repo/common/components';
import { Button, Text } from '@repo/foundations';
import { useDebounceFn } from 'ahooks';
import { useCallback, useRef, useState } from 'react';
import { useAuthUser } from '../../api';
import { usePublicEnv } from '../../api/hooks/utils/usePublicEnv';
import { useUpdateWorkspace } from '../../api/hooks/workspace/useUpdateWorkspace';
import { useWorkspaceImage } from '../../api/hooks/workspace/useWorkspaceImage';
import { openModal } from '../ModalManager';
import { SettingsWorkspaceImageUpload } from './SettingsWorkspaceImageUpload';

function WorkspaceForm() {
	const { workspace } = useAuthUser();
	const { mutateAsync } = useUpdateWorkspace(workspace.id);
	const { mutateAsync: uploadImage } = useWorkspaceImage();

	const [workspaceName, setWorkspaceName] = useState(workspace.name);

	const onUpload = useCallback(
		async (file: File | null) => {
			if (!file) return;
			await uploadImage({
				workspaceId: workspace.id,
				file,
			});
			showNotification({
				title: 'Workspace logo updated',
				message: 'The workspace logo has been updated',
				color: 'green',
			});
		},
		[uploadImage, workspace.id]
	);

	const { run: updateWorkspace } = useDebounceFn(
		async (name: string) => {
			await mutateAsync({
				data: {
					id: workspace.id,
					name,
				},
			});
			showNotification({
				title: 'Workspace name updated',
				message: 'The workspace name has been updated',
				color: 'green',
			});
		},
		{
			wait: 1000,
		}
	);

	return (
		<Box>
			<SettingsWorkspaceImageUpload
				title="Logo"
				onChange={onUpload}
				imageUrl={workspace.logo}
			/>
			<SettingsTextInput
				title="Name"
				value={workspaceName}
				onChange={(value) => {
					setWorkspaceName(value);
					updateWorkspace(value);
				}}
			/>
		</Box>
	);
}

interface IWorkspaceNameCheckModalProps {
	callback: () => void;
	workspace: { name: string };
}

function WorkspaceNameCheckModal({
	callback,
	workspace,
}: IWorkspaceNameCheckModalProps) {
	const theme = useMantineTheme();

	const ref = useRef<HTMLInputElement>(null);
	const [error, setError] = useState<string | null>(null);

	const handleCallback = () => {
		if (ref.current?.value !== workspace.name) {
			setError('The workspace name does not match');
			return;
		}
		callback();
	};

	return (
		<Box>
			<Text>Enter the current workspace name:</Text>
			<Input
				data-testid="workspace-name-delete-input"
				error={error}
				ref={ref}
				placeholder={workspace.name}
			/>
			<Space my={theme.spacing.md} />
			<Button
				data-testid="delete-workspace-modal"
				variant="primary"
				tone="critical"
				onClick={handleCallback}
			>
				Delete workspace
			</Button>
		</Box>
	);
}

export function WorkspaceSettings() {
	const { isAdminUser, workspace } = useAuthUser();
	const { data: publicEnv } = usePublicEnv();
	const theme = useMantineTheme();

	const { mutateAsync } = useUpdateWorkspace(workspace.id);

	const handleDeleteWorkspaceModal = useCallback(() => {
		const handleDelete = async () => {
			await mutateAsync({
				data: {
					id: workspace.id,
					disabled: true,
					scheduled_for_deletion: true,
				},
			});
			window.location.reload();
		};

		openModal({
			title: 'Are you sure you want to permanently delete this workspace?',
			children: (
				<WorkspaceNameCheckModal
					callback={handleDelete}
					workspace={workspace}
				/>
			),
		});
	}, [mutateAsync, workspace]);

	return (
		<Box>
			<WorkspaceForm />

			<Box my={theme.spacing.xl}>
				<SettingsBlock
					title="Version"
					description={`${publicEnv?.TAG} ${publicEnv?.COMMIT_TAG}`}
				/>
				{isAdminUser && (
					<SettingsButton
						title="Delete workspace"
						description="Disable the current workspace and queue it for deletion"
						buttonText="Delete workspace"
						onClick={handleDeleteWorkspaceModal}
					/>
				)}
			</Box>
		</Box>
	);
}
