import { createStyles, Loader, Tabs } from '@mantine/core';

import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';

import { useApiGetEntityCounts } from '@repo/api-codegen';
import type { TabItem } from '@repo/common/components/TabsList';
import TabsList from '@repo/common/components/TabsList';
import type { IEvent } from '../../../api';
import Documentation from '../../../components/Documentation';
import EntityDiscussions from '../../../components/EntityDiscussions';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { useTabNavigate } from '../../../utils/hook/useTabNavigate';
import { withParanthesis } from '../../../utils/shared.utils';
import EventPropertiesTab from '../components/EventPropertiesTab';

const useStyles = createStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		marginTop: theme.spacing.md,
	},
	tab: {
		paddingLeft: theme.spacing.sm,
		paddingRight: theme.spacing.sm,
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
}));

export interface IEventEntityTabsProps {
	event: IEvent;
	tab: string | undefined;
}

function TabLoader() {
	return <Loader size={12} />;
}

function EventEntityTabs({ event, tab }: IEventEntityTabsProps) {
	const { classes } = useStyles();

	const documentationFirst = (event.definition?.length || 0) > 2;
	const defaultTab = documentationFirst ? 'documentation' : 'properties';

	const currentTab = tab ?? defaultTab;

	const navigateToTab = useTabNavigate();

	const { data: entityCounts, isLoading } = useApiGetEntityCounts(
		{
			queryParams: {
				entity_id: event.id || '',
			},
		},
		{ enabled: !!event.id }
	);

	const propertiesActive = (entityCounts?.event_properties_count ?? 0) > 0;
	const showPropertiesTab = isLoading || propertiesActive;

	const handleTabChange = (updatedTab: string) => {
		navigateToTab(updatedTab);
	};

	const tabsList: TabItem[] = [
		{
			value: 'properties',
			label: `Properties ${withParanthesis(entityCounts?.event_properties_count ?? 0)}`,
			icon: isLoading && <TabLoader />,
			disabled: isLoading,
			hidden: !showPropertiesTab,
		},
		{
			value: 'discussions',
			label: `Questions`,
		},
	];

	const documentationTab = {
		value: 'documentation',
		label: 'Documentation',
	};

	if (documentationFirst) {
		tabsList.unshift(documentationTab);
	} else {
		tabsList.push(documentationTab);
	}

	return (
		<Tabs
			color="dark"
			classNames={{
				root: classes.root,
				tab: classes.tab,
				panel: classes.panel,
			}}
			value={currentTab}
			onTabChange={handleTabChange}
		>
			<TabsList tabs={tabsList} />
			<Tabs.Panel value="documentation" pt="sm">
				<Documentation entity={event} />
			</Tabs.Panel>

			<Tabs.Panel value="properties" pt="sm">
				<ErrorBoundary>
					<Suspense fallback={<Loader />}>
						<EventPropertiesTab event={event} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="discussions" pt="sm">
				<ErrorBoundary>
					<Suspense fallback={<Loader />}>
						<EntityDiscussions entity={event} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>
		</Tabs>
	);
}

export default observer(EventEntityTabs);
