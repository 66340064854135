import { Box, Tooltip } from '@mantine/core';
import { Switch } from '@repo/foundations';

interface PolicyToggleProps {
	enabled: boolean;
	onChange: (enabled: boolean) => void;
}

export function PolicyToggle({ enabled, onChange }: PolicyToggleProps) {
	return (
		<Tooltip
			label={
				enabled
					? 'Policy is active. Turning it off will pause syncs.'
					: 'Policy is paused. Turning it on will immediately start a sync.'
			}
		>
			<Box>
				<Switch
					checked={enabled}
					onChange={(event) => onChange(event.target.checked)}
				/>
			</Box>
		</Tooltip>
	);
}
