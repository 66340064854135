import type { ISearchView } from '@repo/common/models/searchView';
import { baseQueryHooksFactory } from '../../factories';
import queryClient from '../../queryClient';
import { searchViewQueryKeyFactory } from './constants';

const {
	useCreateSearchView,
	useDeleteSearchView,
	useSearchViewInfiniteList,
	useSearchViewList,
	useSuspenseSearchViewList,
	useUpdateSearchView,
	useSearchView,
	fetchSearchViewList,
} = baseQueryHooksFactory<ISearchView, 'SearchView'>(
	'SearchView',
	searchViewQueryKeyFactory
);

const invalidateSearchViews = (
	page: number,
	filters?: Record<string, unknown>
) => {
	queryClient.invalidateQueries({
		queryKey: searchViewQueryKeyFactory.list(page, filters),
	});
};

export {
	fetchSearchViewList,
	invalidateSearchViews,
	useCreateSearchView,
	useDeleteSearchView,
	useSearchView,
	useSearchViewInfiniteList,
	useSearchViewList,
	useSuspenseSearchViewList,
	useUpdateSearchView,
};
