import type { IconNames } from '@repo/foundations';
import type { ColorNames } from '@repo/theme/utils';
import type { IntegrationStatus } from '../../api';

export const INTEGRATION_STATUS_MAPPING: Record<
	IntegrationStatus,
	{
		label: string;
		iconName: IconNames;
		iconFillColor?: ColorNames;
		iconColor?: ColorNames;
	}
> = {
	new: {
		label: 'New',
		iconName: 'circleCheckFilled',
		iconFillColor: 'icon/success/default',
	},
	success: {
		label: 'Completed',
		iconName: 'circleCheckFilled',
		iconFillColor: 'icon/success/default',
	},
	failed: {
		label: 'Failed',
		iconName: 'alertCircleFilled',
		iconFillColor: 'icon/critical/default',
	},
	running: {
		label: 'Running',
		iconName: 'refresh',
		iconColor: 'icon/info/default',
	},
	queued: {
		label: 'Pending',
		iconName: 'clock',
		iconColor: 'icon/info/default',
	},
	pending: {
		label: 'Pending',
		iconName: 'clock',
		iconColor: 'icon/info/default',
	},
	terminated: {
		label: 'Terminated',
		iconName: 'circleXFilled',
		iconFillColor: 'icon/warning/default',
	},
};
