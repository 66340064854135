import { Menu } from '@mantine/core';
import type { EntityType } from '@repo/common/enums/entityType';
import { Icon } from '@repo/foundations';
import { useAuthUser } from '../../../../api';
import { trackEvent } from '../../../../utils/analytics';

interface ActivityLogActionProps {
	entityType: EntityType;
	openLogDrawer: VoidFunction;
}

export function ActivityLogAction({
	entityType,
	openLogDrawer,
}: ActivityLogActionProps) {
	const { user, workspace } = useAuthUser();

	const handleClickActivityLog = () => {
		openLogDrawer();
		trackEvent(`${entityType}/activity/click`, {}, user, workspace);
	};

	return (
		<Menu.Item icon={<Icon name="history" />} onClick={handleClickActivityLog}>
			View activity log
		</Menu.Item>
	);
}
