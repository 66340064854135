import { useApiListIamRolesWithUsers } from '@repo/api-codegen';
import type { ISlackChannel, IUser, IUserGroup } from '../../api';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList';
import { useSlackChannels } from '../../api/hooks/workspace/useSlackChannels';
import { selectChannelMap, selectGroupMap, selectUserMap } from './helpers';

type GroupType =
	| 'Workspace admins'
	| 'Workspace editors'
	| 'Workspace viewers'
	| 'Other groups'
	| 'Other slack channels'
	| 'Resource recipients'
	| string; // Allow for dynamic custom role group names

export const useAnnouncementGlobalRecipientsList = () => {
	const { activeUsers: usersData, userGroups: groupsData } =
		useExtendedUserList();
	const { data: channelsData } = useSlackChannels({});
	const { data: iamRolesWithUsers } = useApiListIamRolesWithUsers({});

	const users = (usersData ?? []) as unknown as IUser[];
	const groups = (groupsData ?? []) as unknown as IUserGroup[];
	const channels = (channelsData ?? []) as unknown as ISlackChannel[];
	const customRoles = (iamRolesWithUsers?.results || []).filter(
		(role) => !role.is_legacy
	);

	const optionsAdmins = users
		.filter((u) => u.role === 'admin')
		.map(selectUserMap<GroupType>('Workspace admins'));

	const optionsEditors = users
		.filter((u) => u.role === 'editor')
		.map(selectUserMap<GroupType>('Workspace editors'));

	const optionsViewers = users
		?.filter((u) => u.role === 'viewer')
		.map(selectUserMap<GroupType>('Workspace viewers'));

	const optionsGroups = groups?.map(selectGroupMap<GroupType>('Other groups'));

	const optionsChannels = channels?.map(
		selectChannelMap<GroupType>('Other slack channels')
	);

	const optionsCustomRoles = customRoles.flatMap((role) => {
		const groupLabel = `Workspace ${role.name}`;

		const usersInThisRole = users.filter((user) =>
			role.users.some((roleUser) => roleUser.id === user.id)
		);

		return usersInThisRole.map(selectUserMap<GroupType>(groupLabel));
	});

	return {
		channels,
		users,
		groups,
		options: [
			...optionsAdmins,
			...optionsEditors,
			...optionsViewers,
			...optionsGroups,
			...optionsChannels,
			...optionsCustomRoles,
		],
	};
};
