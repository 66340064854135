import { Box, Collapse, createStyles, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Prism } from '@mantine/prism';
import type { ToolResponse, ToolUse } from '@repo/api-codegen';
import { Button } from '@repo/foundations';
import { useStreamingContent } from '../../../hooks/useStreamingContent';

const useStyles = createStyles((theme) => ({
	container: {},
	wrapper: {
		borderRadius: theme.radius.md,
		border: `solid ${theme.other.borderWidth.sm}px ${theme.other.getColor('border/secondary/default')}`,
		background: theme.other.getColor('surface/secondary/default'),
	},
	wrapperLoading: {},
	header: {
		cursor: 'pointer',
	},
	functionCall: {
		padding: theme.spacing.md,
		'& .mantine-Prism-root': {
			background: 'transparent !important',
			border: 'none',
			padding: 0,
		},
		'& .mantine-Prism-code': {
			fontFamily: theme.fontFamilyMonospace,
			fontSize: theme.fontSizes.sm,
			whiteSpace: 'pre-wrap',
			wordBreak: 'break-word',
			background: 'transparent !important',
		},
		'& pre': {
			background: 'transparent !important',
			margin: 0,
		},
	},
	showResults: {
		cursor: 'pointer',
		marginTop: theme.spacing.xs,
	},
	output: {
		background: theme.other.getColor('surface/primary/default'),
		padding: theme.spacing.md,
		fontFamily: theme.fontFamilyMonospace,
		fontSize: theme.fontSizes.sm,
		whiteSpace: 'pre-wrap',
		wordBreak: 'break-word',
		maxHeight: 400,
		overflow: 'auto',
		borderBottomLeftRadius: theme.radius.md,
		borderBottomRightRadius: theme.radius.md,
		'& a': {
			color: theme.other.getColor('border/emphasis-secondary/active'),
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline',
			},
		},
	},
	button: {
		color: theme.other.getColor('text/secondary/default'),
		'& .mantine-Button-inner': {
			gap: 0,
		},
	},
}));

interface ToolResponseProps {
	toolUse: ToolUse;
	toolResponse?: ToolResponse | null;
	isRunning?: boolean;
	content?: string;
	isLastMessage?: boolean;
}

function formatFunctionCall(
	name: string,
	args: Record<string, unknown>
): JSX.Element {
	const jsonObj = {
		function: name,
		args: args,
	};

	return (
		<Prism
			language="json"
			withLineNumbers={false}
			copyLabel="Copy code"
			copiedLabel="Copied!"
		>
			{JSON.stringify(jsonObj, null, 2)}
		</Prism>
	);
}

export function ToolResponse({
	toolUse,
	toolResponse,
	isRunning,
	content,
	isLastMessage,
}: ToolResponseProps) {
	const [functionExpanded, { toggle: toggleFunction }] = useDisclosure(false);
	const [resultExpanded, { toggle: toggleResult }] = useDisclosure(false);
	const { classes, cx } = useStyles();

	const formattedToolName = content || toolUse.name.replace(/_/g, ' ');
	const isLoading = !toolResponse;
	const isSuccess = !!toolResponse;

	const streamedContent = useStreamingContent(
		(isLastMessage && isLoading) || false,
		formattedToolName
	);

	const theme = useMantineTheme();

	const displayContent = (
		isLastMessage ? streamedContent : formattedToolName
	).replace(/\.$/, '');

	const getRightIconName = () =>
		functionExpanded ? 'chevronUp' : 'chevronDown';

	return (
		<Box className={classes.container}>
			<Box>
				<Button
					ml={`-${theme.spacing.xs}`}
					rightIconName={getRightIconName()}
					leftIconName={isRunning ? 'loader2' : undefined}
					leftIconAnimation={isRunning ? 'spin' : undefined}
					onClick={toggleFunction}
					variant="tertiary"
					iconColor="text/secondary/default"
					size="sm"
					className={classes.button}
				>
					{displayContent}
				</Button>

				<Collapse in={functionExpanded} transitionDuration={200}>
					<Box
						mt="xs"
						className={cx(classes.wrapper, {
							[classes.wrapperLoading]: isLoading,
						})}
					>
						<Box className={classes.functionCall}>
							{formatFunctionCall(toolUse.name, toolUse.args)}

							{isSuccess && (
								<Button
									variant="tertiary"
									onClick={toggleResult}
									rightIconName={resultExpanded ? 'chevronUp' : 'chevronDown'}
								>
									{resultExpanded ? 'Hide Results' : 'Show Results'}
								</Button>
							)}
						</Box>

						<Collapse in={isSuccess && resultExpanded} transitionDuration={200}>
							<Box className={classes.output}>{toolResponse?.output}</Box>
						</Collapse>
					</Box>
				</Collapse>
			</Box>
		</Box>
	);
}
