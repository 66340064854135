import {
	IconFileDescription,
	IconFolder,
	IconFolders,
	IconLink,
	IconQuestionMark,
	IconSearch,
	IconTable,
} from '@tabler/icons-react';

import { Center } from '@mantine/core';
import type { IWidgetSample } from '../../../../../../api';
import { isMetricWidgetSample } from '../../../../../../api';
import { QualityScoreRing } from '../../../../../../components/DataQualityScore/QualityScoreRing/QualityScoreRing';
import { WidgetType } from '../../../../../../interfaces';
import { MetricWidgetContent } from '../../../../../AnalyticsPage/components/MetricWidgetContent';
import {
	formatMetricWidgetTitle,
	getMetricNameTooltip,
} from '../../../../../AnalyticsPage/utils/utils';
import type { ICardWrapperProps } from './CardWrapper';
import CardWrapper from './CardWrapper';
import IntegrationsCard from './IntegrationsCard';
import ListCard from './ListCard';
import PersonsCard from './PersonsCard';
import TextBlockCard from './TextBlockCard';

interface IAddWidgetCardProps {
	widgetSample: IWidgetSample;
	onClick: VoidFunction;
	isTeamsPage?: boolean;
}

function AddWidgetCard({
	widgetSample,
	onClick,
	isTeamsPage = false,
}: IAddWidgetCardProps) {
	let name = '';
	let tooltipLabel = '';
	let children: React.ReactNode = null;
	let containerProps: ICardWrapperProps['containerProps'] = {};
	let textProps: ICardWrapperProps['textProps'] = {};
	let recommended = false;

	const widgetType = widgetSample.type;

	if (widgetType === WidgetType.INTEGRATIONS) {
		name = 'Integrations';
		children = <IntegrationsCard />;
	}

	if (widgetType === WidgetType.TEXT_BLOCK) {
		name = isTeamsPage ? 'Notepad' : 'Private notepad';
		children = <TextBlockCard />;
	}

	if (widgetType === WidgetType.INBOX) {
		name = 'Inbox';
		children = <PersonsCard persons={['user-3.webp', 'user-4.webp']} />;
	}

	if (widgetType === WidgetType.ANNOUNCEMENTS) {
		name = 'Announcements';
		children = <PersonsCard persons={['user-1.webp', 'user-2.webp']} />;
	}

	if (widgetType === WidgetType.BOOKMARKED_RESOURCES) {
		name = 'Resources';
		recommended = true;
		children = (
			<ListCard
				colors={['icon/decorative/indigo', 'icon/decorative/lime']}
				icons={[IconFileDescription, IconTable]}
			/>
		);
	}

	if (widgetType === WidgetType.PINNED_COLLECTIONS) {
		name = 'Collections';
		children = (
			<ListCard
				colors={['icon/caution/default', 'icon/decorative/orange']}
				icons={[IconFolders, IconFolders]}
			/>
		);
	}

	if (widgetType === WidgetType.COLLECTION_RESOURCES) {
		name = 'Collection resources';
		children = (
			<ListCard
				colors={['icon/decorative/indigo', 'icon/decorative/lime']}
				icons={[IconFolder, IconTable]}
			/>
		);
	}

	if (widgetType === WidgetType.RECENT_SEARCHES) {
		name = 'Recent searches';
		children = (
			<ListCard
				colors={['icon/inverse/default', 'icon/inverse/default']}
				icons={[IconSearch, IconSearch]}
				iconProps={{
					size: 16,
					color: 'icon/secondary/default',
				}}
			/>
		);
	}

	if (widgetType === WidgetType.HELPFUL_LINKS) {
		name = 'Helpful links';
		children = (
			<ListCard
				colors={['icon/inverse/default', 'icon/inverse/default']}
				icons={[IconLink, IconLink]}
				iconProps={{
					size: 16,
					color: 'icon/secondary/default',
				}}
			/>
		);
	}

	if (widgetType === WidgetType.DATA_QUALITY_SCORE) {
		name = 'Quality score';
		children = (
			<Center w="100%">
				<QualityScoreRing score={100} />
			</Center>
		);
	}

	if (widgetType === WidgetType.QUESTIONS_POPULAR) {
		name = 'Questions';
		children = (
			<ListCard
				colors={['icon/decorative/pink', 'icon/decorative/orange']}
				icons={[IconQuestionMark, IconQuestionMark]}
			/>
		);
	}

	if (isMetricWidgetSample(widgetSample)) {
		name = formatMetricWidgetTitle(widgetSample);
		tooltipLabel = getMetricNameTooltip(
			widgetSample.metric_metadata.metric_name
		);
		children = (
			<MetricWidgetContent source="sample" metricWidget={widgetSample} />
		);
	}

	return (
		<CardWrapper
			name={name}
			tooltipLabel={tooltipLabel}
			onClick={onClick}
			containerProps={containerProps}
			textProps={textProps}
			recommended={recommended}
		>
			{children}
		</CardWrapper>
	);
}

export default AddWidgetCard;
