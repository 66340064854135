import { createMockableHook } from '@repo/common/utils/createMockableHook';
import type {
	DefaultContext,
	IBaseModelUpdateArgs,
	ISecodaEntity,
	UpdateRequestParams,
} from '../../types';
import { useUpdateBaseModel } from '../base';
import {
	SECODA_ENTITIES_NAMESPACE,
	secodaEntitiesQueryKeyFactory,
} from './constants';

/**
 * A react-query mutation hook that can be used to update a Secoda entity.
 * By default, it performs an optimistic update and invalidates the query for the updated entity.
 * allowing us to update the UI immediately and then refresh the UI again once the mutation is successful.
 *
 * @param params Params for the underlying useUpdateBaseModel hook
 * - Set disableOptimisticUpdate to disable the optimistic update.
 * - queryKeyFactory is used to get queryKey for optimistic updates.
 * - Caches of queries from invalidationKeys are invalidated after the mutation is successful.
 * @returns Returns a react-query mutation hook that can be used to update a Secoda entity
 */
function useUpdateSecodaEntityInternal<
	TApiResponseData extends ISecodaEntity,
	TRequestData extends
		UpdateRequestParams<TApiResponseData> = UpdateRequestParams<TApiResponseData>,
	TContext = DefaultContext<TApiResponseData>,
	TError = Error,
>({
	namespace = SECODA_ENTITIES_NAMESPACE,
	queryKeyFactory = secodaEntitiesQueryKeyFactory,
	disableOptimisticUpdate = false,
	disableInvalidation = false,
	optimisticUpdatePrefixKeys,
	invalidationKeys = [],
	options,
}: Partial<
	IBaseModelUpdateArgs<TApiResponseData, TRequestData, TContext, TError>
>) {
	return useUpdateBaseModel<TApiResponseData, TRequestData, TContext, TError>({
		namespace,
		queryKeyFactory,
		disableOptimisticUpdate,
		disableInvalidation,
		optimisticUpdatePrefixKeys,
		invalidationKeys,
		options,
	});
}

export const [useUpdateSecodaEntity, MockUseUpdateSecodaEntity] =
	createMockableHook(useUpdateSecodaEntityInternal);

export default useUpdateSecodaEntity;
