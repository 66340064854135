import { Button } from '@repo/foundations';
import { useAuthUser, type ISecodaEntity } from '../../../api';
import entityDrawerStore from '../../EntityDrawer/store';

interface SetDescriptionActionProps {
	entity: ISecodaEntity;
}

export function SetDescriptionAction({ entity }: SetDescriptionActionProps) {
	const { isEditorOrAdminUser } = useAuthUser();

	const onClick = async () => {
		await entityDrawerStore.openEntityDrawerById(
			isEditorOrAdminUser,
			entity.id
		);
	};

	return (
		<Button onClick={onClick} variant="primary">
			Set description
		</Button>
	);
}
