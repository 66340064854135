import { Box, createStyles, Stack } from '@mantine/core';
import { IconEmoji } from '@repo/common/components/IconEmoji/IconEmoji';
import { MultiSelect, Text, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useExtendedUserList } from '../../../api/hooks/user/useExtendedUserList';
import type { IUser, IUserGroup } from '../../../api/types';
import { ExpandCollapse } from '../../../components/ExpandCollapse/ExpandCollapse';
import { RichEditor } from '../../../components/RichEditor';
import { UserAvatar } from '../../../components/UserAvatar';
import { getDisplayName } from '../../../utils/userUtils';
import { useCreatePolicyStore } from './context';

const useStyles = createStyles((theme) => ({
	editorWrapper: {
		width: '100%',
		minHeight: theme.other.space[24],
		padding: theme.spacing.xs,
		borderWidth: 0.5,
		borderStyle: 'solid',
		borderColor: theme.other.getColor('border/input/default'),
		borderRadius: theme.radius.md,
		'&:hover': {
			borderColor: theme.other.getColor('border/input/hover'),
		},
		// TODO[tan-policy]: This isn't getting triggered when editor is focused.
		'&:focus-within, &[data-focused="true"], &:has(.ProseMirror-focused)': {
			borderColor: theme.other.getColor('border/input/active'),
		},
	},
	editor: {
		'& .ProseMirror': {
			wordBreak: 'break-word',
			fontSize: theme.fontSizes.sm,
		},
	},
}));

type UserOrUserGroup =
	| {
			value: string;
			label: string;
			user: IUser;
			group: 'User';
	  }
	| {
			value: string;
			label: string;
			userGroup: IUserGroup;
			group: 'User group';
	  };

const RemediationStack = observer(() => {
	const { classes } = useStyles();

	const store = useCreatePolicyStore();
	const { activeUsers, userGroups } = useExtendedUserList();

	const options: UserOrUserGroup[] = useMemo(
		() => [
			...(activeUsers?.map((u) => ({
				value: u.id,
				label: getDisplayName(u),
				user: u,
				group: 'User' as const,
			})) || []),
			...(userGroups?.map((g) => ({
				value: g.id,
				label: g.name,
				userGroup: g,
				group: 'User group' as const,
			})) || []),
		],
		[activeUsers, userGroups]
	);

	const handleRemediationGuidelinesChange = useCallback(
		(value: string | undefined) => {
			store.setRemediationGuidelines(value ?? '');
		},
		[store]
	);

	const renderIcon = useCallback((item: UserOrUserGroup) => {
		if (item.group === 'User') {
			return <UserAvatar user={item.user} size="xs" />;
		}
		return (
			item.userGroup.icon && <IconEmoji value={item.userGroup.icon} size="md" />
		);
	}, []);

	return (
		<Stack p={0} spacing="md">
			<Stack spacing="3xs">
				<Title size="md">Remediation</Title>
				<Text size="sm">
					Describe the steps or actions required to resolve a policy violation
					and ensure compliance is restored.
				</Text>
			</Stack>
			<Box className={classes.editorWrapper}>
				<ExpandCollapse maxHeight={200} enabled>
					<RichEditor
						placeholder="Add remediation guidelines..."
						readOnly={false}
						initialValue={store.remediationGuidelines}
						onChangeCallback={handleRemediationGuidelinesChange}
						disableTopGap
						limited
						autoFocus={false}
						dataTestId="remediation-guidelines"
						className={classes.editor}
					/>
				</ExpandCollapse>
			</Box>
			<MultiSelect<UserOrUserGroup>
				label="Owner"
				placeholder="Select owners"
				data={options}
				value={[...store.owners, ...store.ownersGroups]}
				setValue={(values) => {
					store.setOwners(
						values.filter(
							(v) => options.find((o) => o.value === v)?.group === 'User'
						)
					);
					store.setOwnersGroups(
						values.filter(
							(v) => options.find((o) => o.value === v)?.group === 'User group'
						)
					);
				}}
				renderIcon={renderIcon}
				error={store.ownersError}
				dropdownPosition="flip"
			/>
			<MultiSelect
				label="Subscribers"
				placeholder="Select subscribers"
				data={options}
				value={[...store.subscribers, ...store.subscribersGroups]}
				setValue={(values) => {
					store.setSubscribers(
						values.filter(
							(v) => options.find((o) => o.value === v)?.group === 'User'
						)
					);
					store.setSubscribersGroups(
						values.filter(
							(v) => options.find((o) => o.value === v)?.group === 'User group'
						)
					);
				}}
				renderIcon={renderIcon}
				dropdownPosition="flip"
			/>
		</Stack>
	);
});

RemediationStack.displayName = 'RemediationStack';
export default RemediationStack;
