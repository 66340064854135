import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { SettingsButton, SettingsSwitch } from '@repo/common/components';
import { Icon } from '@repo/foundations';
import { useMutation } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useAuthUser } from '../../api';
import { useUpdateWorkspace } from '../../api/hooks/workspace/useUpdateWorkspace';
import { publishAll } from '../../lib/models';
function PublishSettingsComponent() {
	const { workspace } = useAuthUser();
	const baseUpdateWorkspace = useUpdateWorkspace(workspace.id);

	const { mutateAsync: updateWorkspace } = useMutation({
		mutationFn: baseUpdateWorkspace.mutateAsync,
		onSuccess: () => {
			showNotification({
				title: 'Settings saved',
				message: 'Publishing settings updated',
				color: 'green',
			});
		},
		onError: () => {
			showNotification({
				title: 'Error saving settings',
				message: 'Failed to update publishing settings.',
				color: 'red',
			});
		},
	});

	const handlePublishAllClick = useCallback(async () => {
		// Check if the user has clicked Publish All in the last 1 hour
		const lastPublishAll = localStorage.getItem('publishAll');
		if (lastPublishAll) {
			const lastPublishAllDate = new Date(lastPublishAll);
			const oneHourAgo = new Date();
			oneHourAgo.setHours(oneHourAgo.getHours() - 1);
			if (lastPublishAllDate > oneHourAgo) {
				showNotification({
					title: 'Publish all resources process already started',
					message: 'Please wait one hour for the current process to complete.',
					color: 'blue',
					icon: <Icon name="alertCircle" />,
				});
				return;
			}
		}

		try {
			await publishAll();
			showNotification({
				title: 'Published all resources process started',
				message: 'This can take an hour to complete.',
				color: 'green',
				icon: <Icon name="check" />,
			});
			// Set localStorage to the time Publish All was clicked
			localStorage.setItem('publishAll', new Date().toISOString());
		} catch (error) {
			showNotification({
				title: 'Error publishing all resources',
				message: 'If the issues persists, please contact support',
				color: 'red',
				icon: <Icon name="alertCircle" />,
			});
		}
	}, []);

	const handleDefaultPublishingCheck = async (checked: boolean) => {
		await updateWorkspace({
			data: { id: workspace.id, default_publishing_enabled: checked },
		});
	};

	return (
		<Box>
			<SettingsButton
				title="Publish all resources"
				description="Published data resources are available to all viewers in a workspace"
				onClick={handlePublishAllClick}
				buttonText="Publish"
			/>
			<SettingsSwitch
				title="Publish all resources by default"
				description="All new resources will be published by default"
				checked={!!workspace?.default_publishing_enabled}
				onChange={handleDefaultPublishingCheck}
			/>
		</Box>
	);
}

export const PublishSettings = observer(PublishSettingsComponent);
