import { Box, Space } from '@mantine/core';
import { SettingsButton } from '@repo/common/components';
import { Text } from '@repo/foundations';
import { useAIMemory } from '../../api/hooks/ai/useAIMemory';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import { EmptyState } from '../EmptyState';

export function AIViewerSettings() {
	const enableAi = useAiEnabled();

	const { showMemoriesModal } = useAIMemory();

	if (enableAi) {
		return (
			<>
				<Text pb="lg" size="lg" weight="bold">
					Personalization
				</Text>
				<Box>
					<SettingsButton
						title="Memory"
						description="Secoda AI will become more helpful as you chat, picking up on details and preferences to tailor its responses to you"
						buttonText="Manage Memories"
						onClick={showMemoriesModal}
					/>
				</Box>
				<Space h="5xl" />
			</>
		);
	} else {
		return (
			<EmptyState
				title="Enable Secoda AI to personalize your experience"
				description="Your workspace admin can enable Secoda AI for your workspace"
				illustrationName="ai"
				includeGoBack={false}
				stateHeight="50vh"
				size="lg"
			/>
		);
	}
}
