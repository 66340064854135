import { createStyles, Group, Stack, Table } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import type { GroupItemSettingSchema, GroupSetting } from '@repo/api-codegen';
import {
	useApiGetIntegrationGroupItems,
	useApiRefreshIntegrationGroupItems,
	useApiUpdateIntegrationGroupSettings,
} from '@repo/api-codegen';
import { Icon } from '@repo/foundations';
import { useQueryNormalizer } from '@repo/react-query-cache';
import { useCallback, useEffect, useMemo } from 'react';
import type { IIntegration } from '../../../../api';
import type { IntegrationSpec } from '../../../../interfaces/IntegrationSpec.ts';
import SearchBox from '../../../SearchBox/SearchBox.tsx';
import {
	filterGroupItems,
	getAllDescendants,
} from '../../IntegrationSelectionPage/StepComponents/ImportStep/utils.ts';
import { RefreshButton } from './RefreshButton.tsx';
import IntegrationGroupsSkeleton from './SelectIntegrationGroupsPanelSkeleton';
import {
	SelectIntegrationGroupsPanelTableHeaders,
	SelectIntegrationGroupsPanelTableRow,
	VisibilityState,
} from './SelectIntegrationGroupsPanelTableRow.tsx';

interface SelectPanelProps {
	integration: IIntegration;
	spec: IntegrationSpec;
	refreshOnLoad?: boolean;
}

const useStyles = createStyles((theme) => ({
	table: {
		borderCollapse: 'separate',
		border: `1px solid ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.md,
		overflow: 'hidden',
		width: '100%',
		height: '100%',
		tableLayout: 'fixed',
		'thead tr th': {
			padding: theme.spacing.xs,
			fontSize: theme.other.typography.text.sm,
			fontWeight: theme.other.typography.weight.semibold,
			color: theme.other.getColor('text/secondary/default'),
			backgroundColor: theme.other.getColor('surface/secondary/default'),
			borderBottomColor: theme.other.getColor('border/secondary/default'),
		},
		'thead tr th:first-of-type': {
			paddingLeft: theme.spacing.md,
		},
		'tbody tr td': {
			padding: theme.spacing.xs,
			fontSize: theme.other.typography.text.xs,
			borderTopColor: theme.other.getColor('border/secondary/default'),
		},
		'tbody tr td:first-of-type': {
			paddingLeft: theme.spacing.md,
			fontWeight: theme.other.typography.weight.bold,
		},
		'tbody tr': {
			cursor: 'pointer',
		},
	},
}));

export function SelectIntegrationGroupsPanel({
	integration,
	spec,
	refreshOnLoad = false,
}: SelectPanelProps) {
	const { classes } = useStyles();

	const queryNormalizer = useQueryNormalizer();

	const [searchTerm, setSearchTerm] = useInputState('');

	const { data: groupItems, isLoading } = useApiGetIntegrationGroupItems({
		pathParams: {
			integrationId: integration.id,
		},
	});

	const filteredGroupItems = useMemo(
		() =>
			!!searchTerm
				? filterGroupItems(groupItems ?? [], searchTerm)
				: groupItems,
		[groupItems, searchTerm]
	);

	const {
		mutateAsync: apiRefreshIntegrationGroupItems,
		isLoading: isRefreshing,
	} = useApiRefreshIntegrationGroupItems({});

	const { mutateAsync: apiUpdateIntegrationGroupSettings } =
		useApiUpdateIntegrationGroupSettings({
			meta: {
				normalize: false,
			},
		});

	const handleRefreshGroupItems = useCallback(() => {
		apiRefreshIntegrationGroupItems({
			pathParams: {
				integrationId: integration.id,
			},
		});
	}, [apiRefreshIntegrationGroupItems, integration.id]);

	useEffect(() => {
		if (refreshOnLoad) {
			handleRefreshGroupItems();
		}
	}, [handleRefreshGroupItems, refreshOnLoad]);

	const persistGroupSettings = useCallback(
		async (newState: Record<string, GroupSetting>) => {
			try {
				await apiUpdateIntegrationGroupSettings({
					body: {
						group_settings: newState,
					},
					pathParams: {
						integrationId: integration.id,
					},
				});

				// optimist update the group settings in the cache after the mutation is successful
				Object.keys(newState).forEach((id) => {
					queryNormalizer.setNormalizedData({
						[id]: newState[id],
					});
				});
			} catch {
				showNotification({
					message: 'Unable to update group settings',
					color: 'red',
					icon: <Icon name="x" />,
				});
			}
		},
		[integration.id, apiUpdateIntegrationGroupSettings, queryNormalizer]
	);

	const handleVisibilityChange = useCallback(
		async (item: GroupItemSettingSchema, visible: boolean) => {
			const descendants = getAllDescendants(item);
			const newState = descendants.reduce<Record<string, GroupSetting>>(
				(acc, descendant) => ({
					...acc,
					[descendant.databuilder_id]: {
						...descendant,
						visible,
					},
				}),
				{}
			);

			await persistGroupSettings(newState);
		},
		[persistGroupSettings]
	);

	const updateCustomMappingTeams = useCallback(
		async (item: GroupItemSettingSchema, teamIds: string[]) => {
			const newState: Record<string, GroupSetting> = {
				[item.databuilder_id]: {
					visible: item.visible,
					mapping_teams: teamIds,
					custom_mapping: teamIds.length > 0,
				},
			};

			await persistGroupSettings(newState);
		},
		[persistGroupSettings]
	);

	const isVisible = useCallback(
		(node: GroupItemSettingSchema) => {
			if (spec.type === 'builtin') {
				const descendants = getAllDescendants(node).filter(
					(d) => d.databuilder_id !== node.databuilder_id
				);

				// If there are no descendants, check the visibility of the current node
				if (descendants.length === 0) {
					return (node.visible ?? true)
						? VisibilityState.VISIBLE
						: VisibilityState.HIDDEN;
				}

				const allVisible = descendants.every(
					(descendant) => descendant.visible ?? true
				);
				const someVisible = descendants.some(
					(descendant) => descendant.visible ?? true
				);

				if (allVisible) {
					return VisibilityState.VISIBLE;
				}
				if (someVisible) {
					return VisibilityState.INDETERMINATE;
				}
				return VisibilityState.HIDDEN;
			}

			return node.visible ? VisibilityState.VISIBLE : VisibilityState.HIDDEN;
		},
		[spec.type]
	);

	return (
		<Stack spacing="sm" h="100%">
			<Group spacing="sm" noWrap>
				<SearchBox
					variant="tertiary"
					placeholder="Search"
					onSearch={setSearchTerm}
				/>
				<RefreshButton
					integration={integration}
					onRefreshGroupItems={handleRefreshGroupItems}
					isRefreshing={isRefreshing}
				/>
			</Group>
			<Stack mah={'100%'} pos="relative" style={{ overflowY: 'auto' }}>
				<Table className={classes.table}>
					{isLoading ? (
						<tbody>
							<tr>
								<td colSpan={100} style={{ textAlign: 'center' }}>
									<IntegrationGroupsSkeleton />
								</td>
							</tr>
						</tbody>
					) : (
						<>
							<thead>
								<SelectIntegrationGroupsPanelTableHeaders />
							</thead>
							<tbody>
								{filteredGroupItems?.map((item) => (
									<SelectIntegrationGroupsPanelTableRow
										key={item.databuilder_id}
										item={item}
										isVisible={isVisible}
										handleVisibilityChange={handleVisibilityChange}
										updateCustomMappingTeams={updateCustomMappingTeams}
										customMappingTeamOptions={integration.teams}
									/>
								))}
							</tbody>
						</>
					)}
				</Table>
			</Stack>
		</Stack>
	);
}
