import { Group, Stack, createStyles } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { queryClient } from '../../../../../api';
import { fetchAutomationFilterCount } from '../../../../../api/hooks/automation/fetchAutomationFilterCount';
import { automationPreviewQueryKeyFactory } from '../../../../../api/hooks/automationPreview/constants.ts';
import { useAutomationStore } from '../../../../../pages/AutomationPage/context';
import { openModal } from '../../../../ModalManager';
import { AutomationActionCardFilterPreviewModal } from './AutomationActionCardFilterPreviewModal';

const useStyles = createStyles((theme) => ({
	root: {
		justifyContent: 'space-between',
	},
	group: {
		gap: theme.spacing['2xs'],
	},
	modalContent: {
		overflow: 'hidden',
		padding: 0,
	},
}));

interface AutomationActionCardFilterCountContentProps {
	icon?: JSX.Element;
}

function AutomationActionCardFilterCountContent({
	icon,
}: AutomationActionCardFilterCountContentProps) {
	const { classes } = useStyles();
	const [entityCount, setEntityCount] = useState<number>(0);
	const { automation } = useAutomationStore();

	const updateEntityCount = useCallback(async () => {
		if (automation) {
			const { results } = await fetchAutomationFilterCount({
				automationId: automation.id,
			});

			setEntityCount(results);
		}
	}, [automation]);

	// Get the filter count on component mount
	useEffect(() => {
		if (
			automation?.filter_config &&
			automation?.filter_config.filter_sets.length > 0 &&
			automation?.filter_config.filter_sets.some(
				(set) => set.filters.length > 0
			)
		) {
			updateEntityCount();
			queryClient.invalidateQueries({
				queryKey: automationPreviewQueryKeyFactory.list(),
			});
		}
	}, [updateEntityCount, automation]);

	const handleOnClick = useCallback(() => {
		openModal({
			modalId: 'automation-filter-preview',
			variant: 'default',
			title: 'Preview',
			children: (
				<Stack mt={'sm'}>
					<AutomationActionCardFilterPreviewModal
						modalId="automation-filter-preview"
						automationId={automation?.id}
					/>
				</Stack>
			),
			lockScroll: true,
			size: 640,
			padding: 0,
		});
	}, [automation?.id]);

	return (
		<Group className={classes.root} noWrap>
			<Group className={classes.group} noWrap>
				{icon}
				<Text>Found {entityCount} resources matching the filter</Text>
			</Group>
			<Button onClick={handleOnClick}>View resources</Button>
		</Group>
	);
}

export default observer(AutomationActionCardFilterCountContent);
