import { capitalize } from 'lodash-es';

import type { IconNames } from '@repo/foundations';
import type { ColorNames } from '@repo/theme/utils';
import type {
	IDatabuilderJob,
	IDatabuilderJobStage,
} from '../../../api/types/models/databuilderJob';
import { DatabuilderJobType } from '../../../interfaces';
import { humanReadableTimeElapsed } from '../../../utils/time';
import type { ObjectValues } from '../../../utils/utils';

export const EXTRACTION_STATUS = {
	queued: 'Pending',
	running: 'Running',
	completed: 'Completed',
	failed: 'Failed',
	terminated: 'Terminated',
	timed_out: 'Timed Out',
} as const;

export type ExtractionStatus = ObjectValues<typeof EXTRACTION_STATUS>;

export const getExtractionBadgeStyles = (
	status: ExtractionStatus
): {
	iconColor?: ColorNames;
	iconFillColor?: ColorNames;
	iconName: IconNames;
} => {
	if (
		status === EXTRACTION_STATUS.failed ||
		status === EXTRACTION_STATUS.timed_out ||
		status === EXTRACTION_STATUS.terminated
	) {
		return {
			iconFillColor: 'icon/critical/default',
			iconName: 'alertCircleFilled',
		};
	}

	if (status === EXTRACTION_STATUS.completed) {
		return {
			iconFillColor: 'icon/success/default',
			iconName: 'circleCheckFilled',
		};
	}

	if (status === EXTRACTION_STATUS.running) {
		return {
			iconColor: 'icon/info/default',
			iconName: 'refresh',
		};
	}

	if (status === EXTRACTION_STATUS.queued) {
		return {
			iconColor: 'icon/info/default',
			iconName: 'clock',
		};
	}

	return {
		iconColor: 'icon/info/default',
		iconName: 'clock',
	};
};

export const getExtractionStatus = (job: IDatabuilderJob): ExtractionStatus => {
	if (job.timed_out) {
		return EXTRACTION_STATUS.timed_out;
	}

	// Terminated check needs to be above failed check
	if (job.terminated) {
		return EXTRACTION_STATUS.terminated;
	}

	if (job.failed) {
		return EXTRACTION_STATUS.failed;
	}

	if (job.active) {
		return EXTRACTION_STATUS.running;
	}

	if (job.completed) {
		return EXTRACTION_STATUS.completed;
	}

	return EXTRACTION_STATUS.queued;
};

export const getExtractionLabel = (job: IDatabuilderJob) => {
	if (job.description) {
		if (job.description.toLowerCase().includes('scheduled')) {
			return 'Scheduled';
		}
		return capitalize(job.description);
	}

	if (job.type === DatabuilderJobType.DUMMY) {
		return 'Files Extraction';
	}

	if (
		job.type === DatabuilderJobType.METADATA ||
		job.type === DatabuilderJobType.PUSH
	) {
		return 'Manual';
	}

	return capitalize(job.type);
};

export const getExtractionDescription = (job: IDatabuilderJob) => {
	const status = getExtractionStatus(job);

	if (status === EXTRACTION_STATUS.queued) {
		if (job.stages?.length === 1) {
			return 'Queued for extraction';
		}
		if (job.stages?.length > 1) {
			// If an extraction is in between stages, its status won't be active.
			return `Running for ${humanReadableTimeElapsed(
				Math.floor((Date.now() - new Date(job.created_at).getTime()) / 1000)
			)}`;
		}
		return 'Verifying connection';
	}

	if (status === EXTRACTION_STATUS.running) {
		return `Running for ${humanReadableTimeElapsed(
			Math.floor((Date.now() - new Date(job.created_at).getTime()) / 1000)
		)}`;
	}

	return humanReadableTimeElapsed(job.runtime);
};

export const getExtractionStageStatus = (
	stage: IDatabuilderJobStage,
	isJobTerminated: boolean
) => {
	if (stage.failed) {
		return EXTRACTION_STATUS.failed;
	}

	if (stage.active) {
		return isJobTerminated
			? EXTRACTION_STATUS.terminated
			: EXTRACTION_STATUS.running;
	}

	return EXTRACTION_STATUS.completed;
};

export const getExtractionStageDescription = (
	stage: IDatabuilderJobStage,
	isJobTerminated: boolean
) => {
	const status = getExtractionStageStatus(stage, isJobTerminated);

	if (status === EXTRACTION_STATUS.running) {
		if (isJobTerminated) {
			return '';
		}
		return `Running for ${humanReadableTimeElapsed(
			Math.floor((Date.now() - new Date(stage.created_at).getTime()) / 1000)
		)}`;
	}

	return humanReadableTimeElapsed(stage.runtime);
};
