import { createStyles } from '@mantine/core';
import { useMemo } from 'react';
import type { ITest } from '../../../api';
import {
	ReadOnlyBadgeRender,
	RelativeTimeRender,
	RenderWithIntegrationLogo,
} from '../../../components/TableV2/render';
import type { ExtendedDataTableColumn } from '../../../components/TableV2/types';

const useStyles = createStyles({});

export const useColumns = (): ExtendedDataTableColumn<ITest>[] => {
	const { theme } = useStyles();

	const columns: ExtendedDataTableColumn<ITest>[] = useMemo(
		() => [
			{
				title: 'Test',
				accessor: 'title',
				width: theme.other.space[60], // 240px
				render: (record: ITest) => (
					<RenderWithIntegrationLogo
						integrationId={record.integration}
						textContent={record.title}
					/>
				),
			},
			{
				title: 'Columns',
				accessor: 'columns',
			},
			{
				title: 'Status',
				accessor: 'latest_run.status',
				render: (record: ITest) => (
					<ReadOnlyBadgeRender
						nilOption={{
							color: 'text/secondary/default',
							label: 'Not run',
							option: '', // This value is redundant because latest_run will be null if the test has not been run
						}}
						options={[
							{
								color: 'text/success/default',
								label: 'Success',
								option: 'pass',
							},
							{
								color: 'text/success/default',
								label: 'Success',
								option: 'success',
							},
							{
								color: 'text/critical/default',
								label: 'Fail',
								option: 'fail',
							},
							{
								color: 'text/critical/default',
								label: 'Error',
								option: 'error',
							},
						]}
						record={record}
						field="latest_run.status"
					/>
				),
			},
			{
				title: 'Last run',
				accessor: 'latest_run.run_created_at',
				render: (record: unknown) => (
					<RelativeTimeRender
						record={record}
						field="latest_run.run_created_at"
					/>
				),
			},
		],
		[theme.other.space]
	);

	return columns;
};
