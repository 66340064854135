import type {
	QueryFunctionContext,
	QueryKey,
	UseQueryOptions,
} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { filter, isNil, isString, size, uniqBy } from 'lodash-es';

import { UserRole } from '@repo/common/enums/UserRole';
import { usePlan } from '../../../hooks/usePlans';
import { isBetween } from '../../../utils/numberUtils';
import { apiClient, getEndpoints } from '../../common';
import { createQueryKeys } from '../../factories';
import type { IIntegration } from '../../types';
import type { Namespace } from '../../types/queryArgs';
import { useIntegrationList } from '../integration';
import { useUserList } from '../user';
import type { AdditionalCosts } from './types';
import { useWorkspace } from './useWorkspace';

export interface ITier {
	flat_amount?: number | string | null;
	flat_amount_decimal?: number | string | null;
	unit_amount?: number | null;
	unit_amount_decimal?: number | string | null;
	up_to?: number | string | null;
}

export enum StripeRecurringInterval {
	DAY = 'day',
	WEEK = 'week',
	MONTH = 'month',
	YEAR = 'year',
}

export interface ILimits {
	editors_limit: ITier[];
	viewers_limit: ITier[];
	integrations_limit: ITier[];
	trial_integrations_limit: number | null;
	interval: StripeRecurringInterval;
}

interface IUseLimitsArgs {
	workspaceId: string;
	options?: Omit<UseQueryOptions<ILimits>, 'queryKey' | 'queryFn'>;
}

export function getStripeLimitsQueryFn(namespace: Namespace) {
	const defaultQueryFn = async ({ signal }: QueryFunctionContext<QueryKey>) => {
		const url = getEndpoints(namespace).root();

		const { data } = await apiClient.get<ILimits>(url, { signal });
		return data;
	};

	return defaultQueryFn;
}

function useStripeLimits({ workspaceId, options }: IUseLimitsArgs) {
	const queryKeyFactory = createQueryKeys(['workspace', workspaceId, 'limits']);
	const queryFn = getStripeLimitsQueryFn(queryKeyFactory.namespace);
	return useQuery<ILimits>({
		...options,
		queryKey: queryKeyFactory.namespace,
		queryFn,
	});
}

export const calculateLimit = (tier: ITier[] | undefined | null) => {
	if (isNil(tier)) return { count: undefined, additional: undefined };
	let limit = tier.find((l) => !isNil(l.up_to))?.up_to;
	let additional = tier.find((l) => isNil(l.up_to))?.unit_amount;
	if (!isNil(limit)) {
		limit = parseInt(limit.toString(), 10);
	}
	if (!isNil(additional)) {
		additional = parseInt(additional.toString(), 10);
	}
	return { limit, additional };
};

export function usedIntegrationSeats(integrations: Partial<IIntegration>[]) {
	// If making changes here, also update the logic in the backend,
	// `used_integration_seats`.
	return size(
		uniqBy(
			filter(
				integrations,
				(integration) => !integration?.name?.toLowerCase().includes('demo')
			),
			'type'
		)
	);
}

export function useLimits() {
	const { data: users } = useUserList({
		filters: {
			is_service_account: false,
			disabled: false,
		},
		options: {
			select: ({ results }) => results,
		},
	});
	const { data: integrations } = useIntegrationList({});
	const { workspace } = useWorkspace();
	const { data, isLoading } = useStripeLimits({
		workspaceId: workspace.id,
		options: {
			enabled: !!workspace.id,
		},
	});

	const { limit: editorLimit } = calculateLimit(data?.editors_limit);
	const { limit: viewerLimit } = calculateLimit(data?.viewers_limit);
	const { limit: integrationLimit } = calculateLimit(data?.integrations_limit);
	const trialIntegrationsLimit = data?.trial_integrations_limit;

	const { plan } = usePlan();

	const editorOrAdminSeats = editorLimit ?? plan?.editors ?? 0;
	const viewerSeats = viewerLimit ?? plan?.viewers ?? 0;
	const integrationSeats = integrationLimit ?? plan?.integrations ?? 0;

	const usedEditorOrAdminSeats =
		users?.filter(
			(user) => user.role === UserRole.ADMIN || user.role === UserRole.EDITOR
		).length ?? 0;
	const usedViewerSeats =
		users?.filter(
			(user) => user.role === UserRole.VIEWER || user.role === UserRole.GUEST
		).length ?? 0;

	return {
		usedEditorOrAdminSeats,
		usedViewerSeats,
		usedIntegrationSeats: usedIntegrationSeats(integrations?.results ?? []),
		editorOrAdminSeats,
		viewerSeats,
		integrationSeats,
		trialIntegrationsLimit,
		isLoading,
	};
}

export const calculateAdditionalPlanResourceCost = (
	tiers: ITier[],
	nominalCurrentCount: number,
	nominalNewCount: number,
	interval: StripeRecurringInterval = StripeRecurringInterval.MONTH
) => {
	// Growth Plan has only one tier
	if (size(tiers) === 1 && !isNil(tiers[0].up_to)) {
		const ceiling = isString(tiers[0].up_to)
			? parseInt(tiers[0].up_to, 10)
			: tiers[0].up_to;
		const tierUnitAmount = tiers[0].unit_amount || 0;

		if (nominalNewCount <= ceiling) {
			return {
				additionalCosts: [
					{
						from: 0,
						to: ceiling,
						tierUnitAmount: 0,
						additionalAmount: 0,
					},
				],
				isIncludedInPlan: true,
				includedInPlanCount: ceiling,
				interval,
			};
		}

		return {
			additionalCosts: [
				{
					from: ceiling + 1,
					to: Infinity,
					tierUnitAmount: tiers[0].unit_amount || 0,
					additionalAmount: (nominalNewCount - ceiling) * tierUnitAmount,
				},
			],
			isIncludedInPlan: true,
			includedInPlanCount: ceiling,
			interval,
		};
	}

	tiers.sort((a, b) => {
		if (isNil(a.up_to)) return 1;
		if (isNil(b.up_to)) return -1;

		const ceilingA = isString(a.up_to) ? parseInt(a.up_to, 10) : a.up_to;
		const ceilingB = isString(b.up_to) ? parseInt(b.up_to, 10) : b.up_to;

		return ceilingA - ceilingB;
	});

	const additionalCosts: AdditionalCosts = [];
	let onGoingCount = nominalNewCount - nominalCurrentCount;
	let from = 0;

	for (const tier of tiers) {
		let ceiling = Infinity;

		if (!isNil(tier.up_to)) {
			ceiling = isString(tier.up_to) ? parseInt(tier.up_to, 10) : tier.up_to;
		}

		const tierUnitAmount = tier.unit_amount || 0;

		if (nominalCurrentCount > ceiling) {
			break;
		}

		if (onGoingCount > 0) {
			let diff = Math.min(ceiling - nominalCurrentCount, onGoingCount);
			if (from > 0 && isBetween(onGoingCount, 0, ceiling - from + 1)) {
				diff = onGoingCount;
			} else if (from > 0 && onGoingCount >= ceiling - from + 1) {
				diff = ceiling - from + 1;
			}

			const additionalAmount = diff * tierUnitAmount;

			additionalCosts.push({
				from,
				to: ceiling,
				tierUnitAmount,
				additionalAmount,
			});
			onGoingCount -= diff;
		}
		from = ceiling + 1;
	}

	let isIncludedInPlan = false;
	let includedInPlanCount = 0;

	if (
		size(tiers) > 0 &&
		!isNil(tiers[0].unit_amount) &&
		!isNil(tiers[0].up_to) &&
		tiers[0].unit_amount === 0
	) {
		isIncludedInPlan = true;
		includedInPlanCount = isString(tiers[0].up_to)
			? parseInt(tiers[0].up_to, 10)
			: tiers[0].up_to;
	}

	return { additionalCosts, isIncludedInPlan, includedInPlanCount, interval };
};

interface IUsePlanAdditionalAmountArgs {
	type: 'viewers' | 'editors' | 'integrations';
	nominalCurrentCount: number;
	nominalNewCount: number;
}

export function useAdditionalPlanResourceCost({
	type,
	nominalCurrentCount,
	nominalNewCount,
}: IUsePlanAdditionalAmountArgs) {
	const { workspace } = useWorkspace();
	const { data } = useStripeLimits({
		workspaceId: workspace.id,
		options: {
			enabled: !!workspace.id,
		},
	});

	let tiers: ITier[] = [];

	switch (type) {
		case 'viewers':
			tiers = data?.viewers_limit ?? [];
			break;
		case 'editors':
			tiers = data?.editors_limit ?? [];
			break;
		case 'integrations':
			tiers = data?.integrations_limit ?? [];
			break;
		default:
			break;
	}

	return calculateAdditionalPlanResourceCost(
		tiers,
		nominalCurrentCount,
		nominalNewCount,
		data?.interval
	);
}
