import { Box, Tooltip } from '@mantine/core';
import { Switch } from '@repo/foundations';

interface AutomationToggleProps {
	enabled: boolean;
	onChange: (enabled: boolean) => void;
}

export function AutomationToggle({ enabled, onChange }: AutomationToggleProps) {
	return (
		<Tooltip
			label={
				enabled
					? 'Automation is active. Turning it off will pause scheduled runs.'
					: 'Automation is paused. Turning it on will resume its scheduled runs.'
			}
		>
			<Box>
				<Switch
					checked={enabled}
					onChange={(event) => onChange(event.target.checked)}
				/>
			</Box>
		</Tooltip>
	);
}
