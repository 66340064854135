import { type Filter as CatalogFilter } from '@repo/api-codegen';
import { SortValue } from '@repo/common/components/Filter/types';
import {
	useQueryClient,
	type InfiniteData,
	type UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { cloneDeep, isEqual, isNil, omitBy, reduce } from 'lodash-es';
import { toJS } from 'mobx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	type ApiCatalogSort,
	type IApiListResponse,
	type IBaseModel,
} from '../../api';
import type { SearchFilterV2Store } from '../Filter';
import type {
	ExpandableRecord,
	ExpandedRecords,
	NestedParams,
} from './helpers';
import { makeRecordsExpandable } from './helpers';
import type { ITableV2Props } from './TableV2';

const DEFAULT_SORT = {
	field: SortValue.POPULARITY,
	order: 'desc' as const,
};
const DEFAULT_REQUIRED_OPTIONS = {};
const DEFAULT_REQUIRED_SEARCH_PARAMS = {};
const DEFAULT_REQUIRED_CATALOG_FILTERS = { operands: [] };

export function useTableV2Data<T extends IBaseModel>({
	nestingFilter,
	fetchPaginationList,
	usePaginationList,
	defaultRequiredSearchParamsNesting,
	defaultRequiredSearchParams = DEFAULT_REQUIRED_SEARCH_PARAMS,
	defaultRequiredOptions = DEFAULT_REQUIRED_OPTIONS,
	usePaginationListOptions = {},
	useCodegenListInterface,
	withSearch,
	withFilterOptions,
	defaultSort = DEFAULT_SORT,
	listFilterFunction,
	onTotalRowCountChange,
	withInfiniteScroll,
	defaultRequiredCatalogFilters = DEFAULT_REQUIRED_CATALOG_FILTERS,
	searchFilterV2Store,
	debouncedSearch,
	sortPreferenceKey,
	filterPreferenceKey,
	searchPreferenceKey,
	setSelectedRecordsState,
	page,
}: ITableV2Props<T> & {
	searchFilterV2Store: SearchFilterV2Store;
	debouncedSearch: string;
	sortPreferenceKey: string;
	filterPreferenceKey: string;
	searchPreferenceKey: string;
	setSelectedRecordsState: React.Dispatch<
		React.SetStateAction<{
			selectedRecords: T[];
			lastSelectedIndex: number | null;
		}>
	>;
	page: number;
}) {
	const queryClient = useQueryClient();

	const [expandedRecords, setExpandedRecords] = useState<
		ExpandedRecords<T & NestedParams>
	>({});

	// This will update the nested records when they are updated in the query
	// cache. This is necessary to show optimistic updates on TableV2.
	// TODO: Abstract and move this to a custom hook.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		const updatedRecords: ExpandedRecords<T> = {};

		Object.keys(expandedRecords).forEach((key) => {
			const record = expandedRecords[key];
			if (!record.queryKey) {
				return;
			}
			const output = { ...expandedRecords[key] };
			const data = queryClient.getQueryData(
				record.queryKey
			) as unknown as IApiListResponse<IBaseModel>;
			if (data) {
				const merged = data?.results.map((r) => {
					const expanded = record.nested.find((nested) => nested.id === r.id);
					return { ...expanded, ...r };
				}) as (T & NestedParams)[];
				output.nested = merged;
			}
			if (!isEqual(output, expandedRecords[key])) {
				updatedRecords[key] = {
					...expandedRecords[key],
					nested: output.nested,
				};
			}
		});

		if (Object.keys(updatedRecords).length > 0) {
			setExpandedRecords(
				(prev) =>
					({
						...prev,
						...updatedRecords,
					}) as ExpandedRecords<T & NestedParams>
			);
		}
	});

	const catalogFilter = useMemo(() => {
		// eslint-disable-next-line no-underscore-dangle
		const _catalogFilter = { ...toJS(searchFilterV2Store.catalogFilter) };
		if (defaultRequiredCatalogFilters && _catalogFilter) {
			if (isNil(_catalogFilter.operator)) {
				_catalogFilter.operator = 'and';
				_catalogFilter.operands =
					defaultRequiredCatalogFilters.operands as CatalogFilter[];
			} else {
				_catalogFilter.operands = [
					...(_catalogFilter?.operands ?? []),
					...defaultRequiredCatalogFilters.operands,
				];
			}
		}

		return _catalogFilter;
	}, [defaultRequiredCatalogFilters, searchFilterV2Store.catalogFilter]);

	const sort: Readonly<ApiCatalogSort> | null = useMemo(() => {
		const tableSort = toJS(searchFilterV2Store.tableSort);
		// eslint-disable-next-line no-underscore-dangle
		let _sort = null;
		if (tableSort) {
			_sort = {
				field: tableSort.columnAccessor,
				order: tableSort.direction,
			};
			// If there is a default sort, but no search term, use the default sort.
		} else if (defaultSort && !debouncedSearch) {
			_sort = defaultSort;
		} else if (defaultSort && debouncedSearch) {
			_sort = {
				field: SortValue.RELEVANCE,
				order: 'desc' as const,
			};
		}
		return _sort;
	}, [debouncedSearch, defaultSort, searchFilterV2Store.tableSort]);

	const onError = useCallback(() => {
		// Delete all the filters and sort preferences if the query fails,
		// to avoid breaking this page for the end-user.
		localStorage.removeItem(sortPreferenceKey);
		localStorage.removeItem(filterPreferenceKey);
		localStorage.removeItem(searchPreferenceKey);
	}, [sortPreferenceKey, filterPreferenceKey, searchPreferenceKey]);

	const queryParams = !withSearch
		? { ...defaultRequiredSearchParams }
		: omitBy(
				{
					...defaultRequiredSearchParams,
					filter: !!withFilterOptions?.length ? catalogFilter : undefined,
					search_term: debouncedSearch,
					sort,
				},
				isNil
			);

	const paginationListArgs = useCodegenListInterface
		? {
				queryParams: {
					...queryParams,
					page,
					// If `useCodegenListInterface` is true, we need to stringify the `catalogFilter` and `sort`
					filter: catalogFilter ? JSON.stringify(catalogFilter) : undefined,
					sort: sort ? JSON.stringify(sort) : undefined,
				},
				...usePaginationListOptions,
			}
		: omitBy(
				{
					...defaultRequiredOptions,
					page,
					filters: queryParams,
					options: {
						onError,
						suspense: true,
						...usePaginationListOptions,
					},
				},
				isNil
			);

	const paginationListData = usePaginationList(
		paginationListArgs,
		// @ts-expect-error
		useCodegenListInterface
			? {
					onError,
					suspense: true,
				}
			: undefined
	);

	// This cannot be in `onSuccess`, because on navigating between pages,
	// the `onSuccess` is not called.
	useEffect(() => {
		const data = paginationListData?.data as { count: number } | undefined;
		if (!isNil(data?.count)) {
			onTotalRowCountChange?.(data?.count);
		}
	}, [onTotalRowCountChange, paginationListData?.data]);

	const { data: paginationData, isFetching } = paginationListData;

	const handleEndReached = useCallback(() => {
		if (
			withInfiniteScroll &&
			(paginationListData as UseInfiniteQueryResult<T>).hasNextPage
		) {
			(paginationListData as UseInfiniteQueryResult<T>).fetchNextPage();
		}
	}, [paginationListData, withInfiniteScroll]);

	// eslint-disable-next-line no-console
	console.log('rendered TableV2');

	const listData = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const listData = cloneDeep(paginationData as IApiListResponse<T>);
		if ((paginationData && (paginationData as InfiniteData<T>))?.pages) {
			listData['results'] =
				(paginationData as InfiniteData<T> | undefined)?.pages ?? [];
		}

		if (listData?.results) {
			listData['results'] = listData['results'].map((record) => ({
				...record,
				_id: record.id,
			}));
		}

		Object.keys(expandedRecords)
			?.sort((a, b) => a.localeCompare(b))
			.forEach((key) => {
				let record = expandedRecords[key];

				let insertionIndex = -1;
				// We know that the deep `expandedRecord` is already nested, if a
				// period exists. So we can search for `paramExpandUid`.
				if (key.includes('.') && record.expanded.paramExpandUid) {
					insertionIndex = listData?.results?.findIndex(
						(result) =>
							(result as T & { paramExpandUid: string })?.paramExpandUid &&
							(result as T & { paramExpandUid: string })?.paramExpandUid ===
								record.expanded.paramExpandUid
					);
				} else {
					insertionIndex = listData?.results?.findIndex(
						(result) => result.id === key
					);
				}

				if (record && insertionIndex !== -1) {
					const newArr = listData?.results?.slice(0, insertionIndex + 1);
					// Refresh the expandedRecord values from the cache.
					const data = queryClient.getQueryData(
						record.queryKey
					) as unknown as IApiListResponse<IBaseModel>;
					const merged = data?.results.map((r) => {
						const expanded = record.nested.find((nested) => nested.id === r.id);
						// There are properties that are not present in the cache we want.
						return { ...expanded, ...r };
					}) as T[];
					newArr?.push(...merged);
					listData.results = newArr.concat(
						listData?.results?.slice(insertionIndex + 1)
					);
				}
			});
		return listData;
	}, [paginationData, expandedRecords]);

	useEffect(() => {
		// List of selected records must be consistent with the list of records
		setSelectedRecordsState((currentState) => ({
			selectedRecords: currentState.selectedRecords.map(
				(r) => listData?.results.find((result) => result.id === r.id) ?? r
			),
			lastSelectedIndex: currentState.lastSelectedIndex,
		}));
	}, [listData?.results, setSelectedRecordsState]);

	const results = listFilterFunction
		? listData?.results.filter(listFilterFunction)
		: listData?.results;

	const totalCount = listData?.count ?? 0;

	// Count the number of `nested` records.
	const totalCountWithNesting = useMemo(
		() =>
			totalCount +
			reduce(expandedRecords, (acc, record) => acc + record.nested.length, 0),
		[expandedRecords, totalCount]
	);

	const records = useMemo(() => {
		// If we don't have nesting functionality enabled, just return the results array
		if (!nestingFilter || !fetchPaginationList) {
			return results ?? [];
		}

		// Otherwise, transform the results to support expandable rows
		return makeRecordsExpandable<T>(
			results ?? [],
			expandedRecords,
			setExpandedRecords,
			nestingFilter,
			defaultRequiredSearchParamsNesting,
			fetchPaginationList,
			sort
		);
	}, [
		nestingFilter,
		results,
		expandedRecords,
		defaultRequiredSearchParamsNesting,
		fetchPaginationList,
		sort,
	]);

	useEffect(() => {
		// If we don't have nesting functionality enabled, don't do anything
		if (!nestingFilter || !fetchPaginationList) {
			return;
		}

		// re-fetch the expanded records whenever the sort changes
		Object.keys(expandedRecords).forEach((key) => {
			let expandedRecord = expandedRecords[key];

			let parentRecord = records.find(
				(r) => r.id === expandedRecord.expanded.id
			) as ExpandableRecord<T>;

			// nested list is sorted differently
			if (
				expandedRecord &&
				(expandedRecord.currentSort?.field !== sort?.field ||
					expandedRecord.currentSort?.order !== sort?.order) &&
				parentRecord
			) {
				parentRecord?.onSortChanged?.(sort);
			}
		});
	}, [sort, expandedRecords, records, nestingFilter, fetchPaginationList]);

	return {
		results,
		records,
		totalCountWithNesting,
		expandedRecords,
		sort,
		catalogFilter,
		handleEndReached,
		paginationListData,
		isFetching,
		totalCount,
		isFetchingNextPage: (paginationListData as UseInfiniteQueryResult<T>)
			?.isFetchingNextPage,
	};
}
