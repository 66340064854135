import type { BuiltinIntegrationSpec } from '@repo/common/interfaces/integrationSpec';

/**
 * Returns the integration-specific permission requirements for data access requests.
 * These are the privileges required on the database for DAR to function correctly.
 *
 * @param integrationType The type of integration (e.g., 'snowflake', 'postgres')
 * @returns HTML string with integration-specific permission requirements or null if not applicable
 */
export function getPermissionRequirementsForIntegrationType(
	integrationType: BuiltinIntegrationSpec['type']
): string | null {
	switch (integrationType.toLowerCase()) {
		case 'snowflake':
			return `
        To enable data access requests, the Secoda connection account requires the following Snowflake permissions:
        <ul>
          <li>MANAGE GRANTS privilege on the database to create roles and grant privileges</li>
          <li>CREATE USER privilege at the account level to create temporary users</li>
          <li>Access to SNOWFLAKE.ACCOUNT_USAGE schema to read role and privilege information</li>
          <li>USAGE privilege on relevant warehouses</li>
        </ul>
      `;

		case 'postgres':
			return `
        To enable data access requests, the Secoda connection account requires the following PostgreSQL permissions:
        <ul>
          <li>CREATEROLE privilege to create new roles</li>
          <li>Membership in pg_read_all_settings role or superuser to read role information</li>
          <li>CREATE USER privilege to create temporary users</li>
          <li>GRANT OPTION privileges on resources you want to allow access to</li>
        </ul>
      `;

		case 'redshift':
			return `
        To enable data access requests, the Secoda connection account requires the following Redshift permissions:
        <ul>
          <li>Superuser role privileges or ACCESS SYSTEM TABLE permission</li>
          <li>CREATE USER privilege to create temporary users</li>
          <li>CREATE ROLE permission to create temporary roles</li>
          <li>GRANT OPTION on schemas and tables that will be shared</li>
        </ul>
      `;

		case 'bigquery':
			return `
        To enable data access requests, the Secoda connection account requires the following BigQuery permissions:
        <ul>
          <li>BigQuery Data Editor role (roles/bigquery.dataEditor) or equivalent permissions</li>
          <li>BigQuery Job User role (roles/bigquery.jobUser) to run queries</li>
          <li>BigQuery User role (roles/bigquery.user) to access datasets and tables</li>
          <li>Service Account Token Creator role (roles/iam.serviceAccountTokenCreator) to create temporary credentials</li>
        </ul>
      `;

		// Return null for integration types that don't have specific permission requirements
		default:
			return null;
	}
}
