import { apiQueryKey } from '@repo/api-codegen';
import { useCallback } from 'react';
import type { ISecodaEntity } from '../../../api';
import { queryClient, useUpdateSecodaEntity } from '../../../api';
import TagPropertySelector from '../../Properties/TagPropertySelector';

interface AddTagActionProps {
	entity: ISecodaEntity;
}

export function AddTagAction({ entity }: AddTagActionProps) {
	const { mutateAsync: updateEntity } = useUpdateSecodaEntity({});

	const handleChange = useCallback(
		(tags: string[]) =>
			updateEntity(
				{
					data: {
						id: entity.id,
						tags,
					},
				},
				{
					onSuccess: () => {
						queryClient.invalidateQueries({
							queryKey: apiQueryKey('quality/{entity_id}', {
								entity_id: entity.id,
							}),
						});
					},
				}
			),
		[entity.id, updateEntity]
	);

	return (
		<TagPropertySelector
			initialValue={entity.tags ?? []}
			onChange={handleChange}
			forceVariant="primary"
			placeholder="Add tags"
		/>
	);
}
