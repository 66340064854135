import { Box, Menu, Select } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { useUpdateMonitor, type Monitor } from '../../../api';
import { useSlackChannels } from '../../../api/hooks/workspace/useSlackChannels';
import PopoverMenuItem from '../../../components/EntityNotificationHook/PopoverMenuItem';

interface IMonitorSlackChannelsProps {
	monitor: Monitor;
}

function MonitorSlackChannels({ monitor }: IMonitorSlackChannelsProps) {
	const { data: channels } = useSlackChannels({});

	const { mutateAsync: updateMonitor } = useUpdateMonitor({});

	// If no channels data or user can't edit, don't render the menu item
	if (!channels) {
		return null;
	}

	const channelOptions = channels.map((channel) => ({
		label: channel.name,
		value: channel.id,
	}));

	const updateSlackChannel = async (channel: string | null) => {
		if (!monitor?.id) {
			return;
		}

		await updateMonitor({
			data: {
				id: monitor.id,
				notify_slack_channel: channel,
			},
		});
	};

	return (
		<PopoverMenuItem
			target={
				<Menu.Item
					icon={<Icon name="brandSlack" />}
					closeMenuOnClick={false}
					rightSection={<Icon name="chevronRight" />}
				>
					Connected channels
				</Menu.Item>
			}
		>
			<Box>
				<Select
					label="Connect to Slack channel"
					placeholder="Pick a channel"
					data={channelOptions}
					value={monitor.notify_slack_channel}
					onChange={updateSlackChannel}
					searchable
					clearable
				/>
			</Box>
		</PopoverMenuItem>
	);
}

export default MonitorSlackChannels;
