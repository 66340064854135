import { createStyles, Group } from '@mantine/core';
import { Text } from '@repo/foundations';
import { memo, useCallback } from 'react';
import { useSecodaEntity, useUpdateSecodaEntity } from '../../api';
import { useCheckEntityUpdatePermission } from '../../utils/authorization/roles';
import { AddGlossarySynonym } from './AddGlossarySynonym';
import { GlossarySynonymPill } from './GlossarySynonymPill';

const useStyles = createStyles(() => ({
	wrapper: {
		flex: '1 !important',
	},
}));

export interface InlineGlossarySynonymsProps {
	glossaryTermId: string;
}

export const InlineGlossarySynonyms = memo(
	({ glossaryTermId }: InlineGlossarySynonymsProps) => {
		const { classes } = useStyles();

		const { data: glossaryTerm } = useSecodaEntity({
			id: glossaryTermId,
			options: {
				enabled: !!glossaryTermId,
				meta: {
					normalize: false,
				},
			},
		});

		const { mutateAsync: updateGlossaryTerm } = useUpdateSecodaEntity({});

		const editorOfEntity = useCheckEntityUpdatePermission({
			entity: glossaryTerm,
		});

		const handleOnAdd = useCallback(
			async (synonym: string) => {
				if (!glossaryTerm) {
					return;
				}

				const synonyms = [...glossaryTerm.synonyms, synonym];
				await updateGlossaryTerm({
					data: {
						id: glossaryTerm.id,
						synonyms,
					},
				});
			},
			[glossaryTerm, updateGlossaryTerm]
		);

		const handleOnRemove = useCallback(
			(synonym: string) => async () => {
				if (!glossaryTerm) {
					return;
				}

				const synonyms = glossaryTerm.synonyms.filter((s) => s !== synonym);
				await updateGlossaryTerm({
					data: {
						id: glossaryTerm.id,
						synonyms,
					},
				});
			},
			[glossaryTerm, updateGlossaryTerm]
		);

		return (
			<Group spacing="xs" noWrap align="flex-start">
				<Text w={145} size="sm" py="4xs">
					Synonyms
				</Text>
				<Group spacing="xs" className={classes.wrapper}>
					{glossaryTerm?.synonyms?.map((synonym, idx) => (
						<GlossarySynonymPill
							// eslint-disable-next-line react/no-array-index-key
							key={`${synonym}-${idx}`}
							synonym={synonym}
							onRemove={editorOfEntity ? handleOnRemove(synonym) : undefined}
						/>
					))}
					{editorOfEntity && <AddGlossarySynonym onAdd={handleOnAdd} />}
				</Group>
			</Group>
		);
	}
);
InlineGlossarySynonyms.displayName = 'InlineGlossarySynonyms';
