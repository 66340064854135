import type { MantineThemeOverride } from '@mantine/core';

export const DatePicker: MantineThemeOverride['components'] = {
	DatePicker: {
		styles: (theme) => ({
			day: {
				color: theme.other.getColor('text/primary/default'),

				'&:hover': {
					backgroundColor: theme.other.getColor('fill/brand/hover'),
					color: theme.other.getColor('text/brand-on-fill/default'),
				},
				'&[data-today]': {
					backgroundColor: theme.other.getColor('fill/primary/active'),
					'&:hover': {
						backgroundColor: theme.other.getColor('fill/brand/hover'),
						color: theme.other.getColor('text/brand-on-fill/default'),
					},
				},
				'&[data-outside]': {
					color: theme.other.getColor('text/primary/disabled'),
					'&:hover': {
						backgroundColor: theme.other.getColor('fill/brand/hover'),
						color: theme.other.getColor('text/brand-on-fill/default'),
					},
				},
				'&[data-in-range]': {
					backgroundColor: theme.other.getColor('surface/primary/selected'),
					color: theme.other.getColor('text/primary/default'),
					'&:hover': {
						backgroundColor: theme.other.getColor('fill/brand/hover'),
						color: theme.other.getColor('text/brand-on-fill/default'),
					},
				},
				'&[data-selected]': {
					backgroundColor: theme.other.getColor('fill/brand/selected'),
					color: theme.other.getColor('text/brand-on-fill/default'),

					'&:hover': {
						backgroundColor: theme.other.getColor('fill/brand/hover'),
						color: theme.other.getColor('text/brand-on-fill/default'),
					},
				},
				'&[data-weekend]': {
					color: theme.other.getColor('text/primary/default'),

					'&[data-selected], &:hover': {
						color: theme.other.getColor('text/brand-on-fill/default'),
					},
				},
			},
		}),
	},
};
