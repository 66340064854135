import { Group, Stack } from '@mantine/core';
import { find, isEmpty, isNil } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useAuthUser } from '../../api';
import {
	useNotification,
	useNotificationList,
} from '../../api/hooks/notification';
import { trackEvent } from '../../utils/analytics';
import InboxList from './InboxList/InboxList';
import { useInboxPage } from './InboxPage.hooks';
import useStyles from './InboxPage.styles';
import InboxView from './InboxView';

function InboxPage() {
	const {
		page,
		filters,
		searchTerm,
		setSearchTerm,
		setPage,
		events,
		setEvents,
		isInclusive,
		setIsInclusive,
		selectedId,
		setSelectedId,
	} = useInboxPage();
	const { user, workspace } = useAuthUser();

	const { data } = useNotificationList({
		page,
		filters,
		options: {
			refetchOnMount: true,
			staleTime: 1000 * 60 * 2, // 2 minutes
		},
	});

	const notificationFromList = useMemo(
		() => find(data?.results, { id: selectedId }),
		[data?.results, selectedId]
	);

	const useNotificationEnabled = useMemo(
		() => !isEmpty(selectedId) && isNil(notificationFromList),
		[selectedId, notificationFromList]
	);

	const { data: fetchedNotification, isLoading } = useNotification({
		id: selectedId,
		options: {
			enabled: useNotificationEnabled,
		},
	});

	const selected = useMemo(
		() => notificationFromList || fetchedNotification,
		[notificationFromList, fetchedNotification]
	);

	const handleSetEvents = (value: string) => {
		trackEvent(
			'inbox/filter/click',
			{
				events: value,
			},
			user,
			workspace!
		);
		setEvents(value);
	};

	const { classes } = useStyles();

	return (
		<Stack className={classes.inboxPageStack} spacing={0}>
			<Group spacing={0} noWrap>
				<InboxList
					data={data?.results || []}
					selected={selected}
					setSelectedId={setSelectedId}
					page={page}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					setPage={setPage}
					totalPages={data?.total_pages || 1}
					events={events}
					setEvents={handleSetEvents}
					isInclusive={isInclusive}
					setIsInclusive={setIsInclusive}
				/>
				<InboxView
					selected={selected}
					setSelectedId={setSelectedId}
					data={data?.results || []}
					searchTerm={searchTerm}
					events={events}
					isLoading={useNotificationEnabled && isLoading}
				/>
			</Group>
		</Stack>
	);
}

export default observer(InboxPage);
