import { Group } from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';
import type { MetricType } from '@repo/api-codegen';
import { apiQueryKey } from '@repo/api-codegen';
import { Button } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { queryClient, useCreateMonitor } from '../../../../api';
import { monitorsQueryKeyFactory } from '../../../../api/hooks/monitoring/constants';
import { supportsGroupedResources } from '../../../../pages/MonitorPage/utils';
import { useAddMonitorStoreContext } from '../context';

interface CreateMonitorButtonProps {
	exitPage: VoidFunction;
}

function CreateMonitorButton({ exitPage }: CreateMonitorButtonProps) {
	const store = useAddMonitorStoreContext();
	const { mutateAsync: createMonitor } = useCreateMonitor({});

	const handleCreateMonitor = async () => {
		if (!store.isFormValid) {
			return;
		}

		// Set up notification
		const notificationId = 'CREATE_MONITOR_NOTIFICATION';

		let completedCount = 0;
		let failedIds: string[] = [];

		showNotification({
			id: notificationId,
			title: 'Creating monitors...',
			message: `${completedCount} of ${store.monitorsToCreate.length} monitors created`,
			color: 'blue',
			loading: true,
			autoClose: false,
		});

		await Promise.allSettled(
			store.monitorsToCreate.map(async (data) =>
				createMonitor({ data })
					.then((r) => {
						completedCount += 1;
						updateNotification({
							id: notificationId,
							title: 'Creating monitors...',
							message: `${completedCount} of ${store.monitorsToCreate.length} monitors created`,
						});
						return r;
					})
					.catch((error) => {
						if (data.target) {
							failedIds.push(data.target);
						}
						return error;
					})
			)
		);

		queryClient.invalidateQueries({
			queryKey: monitorsQueryKeyFactory.list(),
		});
		// Navigate away if all monitors were created successfully
		if (completedCount === store.monitorsToCreate.length) {
			updateNotification({
				id: notificationId,
				message: `${store.monitorsToCreate.length} monitor${
					store.monitorsToCreate.length < 2 ? '' : 's'
				} created successfully`,
				color: 'green',
				loading: false,
				autoClose: 5000,
			});
			queryClient.invalidateQueries({
				queryKey: apiQueryKey('monitor/recommend/supported_entities'),
			});
			exitPage();
		} else {
			// Otherwise, show error notification
			// Refresh the entity list and only select the failed monitors
			const monitorMessage =
				failedIds.length < 2 ? 'monitor' : `${failedIds.length} monitors`;
			updateNotification({
				id: notificationId,
				message: `Failed to create ${monitorMessage}. Please try again or contact support.`,
				color: 'red',
				loading: false,
				autoClose: 5000,
			});
			queryClient.invalidateQueries({
				queryKey: apiQueryKey('monitor/recommend/supported_entities'),
			});
			store.setTargetEntities(failedIds);
		}
	};

	const count: number = useMemo(() => {
		if (store.monitorSpec.group === 'Custom') {
			return 0;
		}
		if (['Table', 'Column'].includes(store.monitorSpec.group)) {
			return store.targetEntities.length;
		}
		if (['Jobs'].includes(store.monitorSpec.group)) {
			if (
				supportsGroupedResources(
					store.monitorSpec.type.metric_type as MetricType
				)
			) {
				return 0;
			}
			return store.targetEntities.length;
		}

		return 0;
	}, [
		store.monitorSpec.group,
		store.monitorSpec.type.metric_type,
		store.targetEntities.length,
	]);

	return (
		<Group position="right">
			<Button variant="primary" onClick={handleCreateMonitor}>
				Create {count ? count + ' ' : ''}monitor
				{count > 1 ? 's' : ''}
			</Button>
		</Group>
	);
}

export default observer(CreateMonitorButton);
