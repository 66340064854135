import { Anchor, Group, ThemeIcon } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';

function SearchTipsFooter() {
	return (
		<Anchor
			href="https://docs.secoda.co/features/search"
			target="_blank"
			rel="noreferrer"
		>
			<Group spacing="xs" pl="xs" py="sm">
				<ThemeIcon
					size={16}
					radius="sm"
					color="icon/secondary/default"
					variant="filled"
				>
					<Icon name="questionMark" />
				</ThemeIcon>
				<Text size="xs">Search tips</Text>
			</Group>
		</Anchor>
	);
}

export default SearchTipsFooter;
