import { Container, Group, Stack } from '@mantine/core';
import type { VersionHistoryOut } from '@repo/api-codegen';
import {
	fetchApiListVersionHistories,
	useApiRevertToHistory,
} from '@repo/api-codegen';
import { useInfinitePagination } from '@repo/common/components/ActivityLog/ResourceLogs/hooks';
import { Banner, Button, Text, Title } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { RichEditor } from '../RichEditor';
import { SnapshotModalBody } from './SnapshotModalBody';
import { SnapshotProperties } from './SnapshotProperties';
import { VersionHistoryButton } from './VersionHistoryButton/VersionHistoryButton';

export interface SnapshotModalContentProps {
	resourceId: string;
	initialSelectedId?: string;
	onClose: () => void;
}

export function SnapshotModalContent({
	resourceId,
	initialSelectedId,
	onClose,
}: SnapshotModalContentProps) {
	const {
		page,
		setPage,
		fetchedPages,
		setFetchedPages,
		hasNextPage,
		setHasNextPage,
		loading,
		setLoading,
	} = useInfinitePagination();

	const [selectedId, setSelectedId] = useState(initialSelectedId);
	const [versionHistories, setVersionHistories] = useState<VersionHistoryOut[]>(
		[]
	);

	const loadMore = useCallback(async () => {
		if (loading || fetchedPages.has(page) || !hasNextPage) return;
		setLoading(true);

		const response = await fetchApiListVersionHistories({
			queryParams: {
				resource_id: resourceId,
				page: page,
			},
		});

		setVersionHistories((prev) => [...prev, ...response.results]);

		setPage(page + 1);
		setFetchedPages((prev) => new Set(prev).add(page));
		setHasNextPage(!isNil(response.meta.next_page));
		setLoading(false);
	}, [
		loading,
		fetchedPages,
		page,
		hasNextPage,
		setLoading,
		resourceId,
		setPage,
		setFetchedPages,
		setHasNextPage,
	]);

	const { mutate: revertToHistory, isLoading: isReverting } =
		useApiRevertToHistory();

	const handleRestoreVersion = useCallback(() => {
		if (!selectedId) {
			return;
		}

		revertToHistory({
			queryParams: {
				id: selectedId,
			},
		});
	}, [revertToHistory, selectedId]);

	useEffect(() => {
		if (page === 1) {
			loadMore();
		}
	}, []);

	useEffect(() => {
		if (!initialSelectedId && versionHistories?.length) {
			setSelectedId(versionHistories[0].id);
		}
	}, [initialSelectedId, versionHistories]);

	const itemContent = useCallback(
		(index: number, item: VersionHistoryOut) => (
			<VersionHistoryButton
				key={item.id}
				versionHistory={item}
				selected={selectedId === item.id}
				onClick={() => setSelectedId(item.id)}
			/>
		),
		[selectedId, setSelectedId]
	);

	if (!versionHistories) {
		return null;
	}

	const selectedVersionHistory = versionHistories.find(
		(versionHistory) => versionHistory.id === selectedId
	);

	if (!selectedVersionHistory) {
		return null;
	}

	// Legacy version histories only support restoring the definition. We don't have any other historical information from them.
	const isLegacy = selectedVersionHistory.is_legacy;
	const snapshot =
		selectedVersionHistory.metadata.secoda_entity_property_snapshot;

	const title =
		selectedVersionHistory.metadata.current.title ?? snapshot?.title;
	const description =
		selectedVersionHistory.metadata.description_diff.markdown ??
		selectedVersionHistory.metadata.current.description ??
		snapshot?.description;

	return (
		<SnapshotModalBody
			leftColumn={
				<Stack spacing="2xl">
					{(title || description) && (
						<Group pt="xl" pb="xs" spacing="xs">
							<Stack spacing="md">
								{title && <Title size="xl">{title}</Title>}
								{description && <RichEditor readOnly value={description} />}
							</Stack>
						</Group>
					)}
					<SnapshotProperties versionHistory={selectedVersionHistory} />
					{isLegacy && (
						<Banner
							message="This is a legacy version history. We only have information about the document."
							tone="info"
							inCard
						/>
					)}
					<Stack spacing="sm">
						<Text size="sm" color="text/secondary/default">
							Documentation
						</Text>
						<RichEditor
							readOnly
							value={
								selectedVersionHistory.metadata.definition_diff.markdown ??
								snapshot?.definition ??
								selectedVersionHistory.metadata.current.definition ??
								''
							}
						/>
					</Stack>
				</Stack>
			}
			rightColumn={
				<Container fluid w="100%" h="100%" p="sm">
					<Virtuoso
						height={'calc(100vh - 5dvh - 5dvh - 54 - 60 - 1)px'}
						data={versionHistories}
						endReached={loadMore}
						itemContent={itemContent}
					/>
				</Container>
			}
			actions={[
				<Button key="cancel" variant="default" onClick={onClose}>
					Cancel
				</Button>,
				<Button
					key="restore"
					variant="primary"
					onClick={handleRestoreVersion}
					loading={isReverting}
				>
					Restore version
				</Button>,
			]}
		/>
	);
}
