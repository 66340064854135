import { Group, Skeleton, Stack, ThemeIcon } from '@mantine/core';
import { IconPlugConnected } from '@tabler/icons-react';

function IntegrationsCard() {
	return (
		<Group w="100%" position="apart">
			{['icon/decorative/grape', 'icon/decorative/indigo'].map((color) => (
				<Stack align="center" key={color} spacing="sm">
					<ThemeIcon color={color} size="lg" radius={24}>
						<IconPlugConnected size={18} />
					</ThemeIcon>
					<Skeleton animate={false} width={48} height={8} />
				</Stack>
			))}
		</Group>
	);
}

export default IntegrationsCard;
