import {
	Box,
	Flex,
	Image,
	UnstyledButton,
	createStyles,
	rem,
} from '@mantine/core';
import type { Properties } from 'csstype';
import { useState } from 'react';
import { useAuthUser } from '../../api/hooks';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { SecodaMinimalLogo } from '../../components/SecodaMinimalLogo';
import { useFeatureFlags } from '../../utils/featureFlags';
import OnboardingViewerButtons from './OnboardingViewButtons';
import OnboardingViewerContent from './OnboardingViewerContent';

const useStyles = createStyles((theme) => ({
	page: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		alignItems: 'center',
		minHeight: '100vh',
		minWidth: theme.other.space[160],
		padding: theme.spacing['3xl'],
	},
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		maxWidth: theme.other.space[320],
		width: '100%',
	},
	rightPanelContainer: {
		display: 'flex',
		alignItems: 'center',
		width: theme.other.space[120],
		[`@media (max-width: ${rem(theme.other.space[200])})`]: {
			display: 'none',
		},
	},
	rightPanel: {
		position: 'relative',
		overflow: 'hidden',
	},
	backgroundImage: {},
	stepIndicator: {
		height: theme.other.space[2],
		width: theme.other.space[16],
		backgroundColor: theme.other.getColor('fill/tertiary/hover'),
		borderRadius: theme.other.space[3],
		opacity: 0.5,
		':hover': {
			backgroundColor: theme.other.getColor('fill/tertiary/active'),
		},
	},
	selectedStepIndicator: {
		backgroundColor: theme.other.getColor('fill/brand/default'),
		':hover': {
			backgroundColor: theme.other.getColor('fill/brand/hover'),
		},
	},
	educationImage: {
		position: 'absolute',
		top: '25%',
		left: '5%',
		width: '90%',
	},
	contents: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
		justifyContent: 'center',
		gap: theme.spacing['3xl'],
		height: theme.other.space[160] - theme.other.space[8],
		width: theme.other.space[120],
		paddingRight: theme.other.space[20],
	},
	logo: {
		width: theme.other.space[8],
	},
}));

interface OnboardingImageStyle {
	top: string;
	left?: string;
	right?: string;
	width: string;
	position: Properties['position'];
}
interface OnboardingImage {
	src: string;
	alt: string;
	style: OnboardingImageStyle;
}
const onboardingImage: OnboardingImage[] = [
	{
		src: '/images/onboarding/education_1_4x.webp',
		alt: "An example of Secoda's search UI",
		style: {
			top: '5%',
			right: '0',
			width: '90%',
			position: 'absolute',
		},
	},
	{
		src: '/images/onboarding/education_2_4x.webp',
		alt: "An example of Secoda's AI Assistant",
		style: {
			top: '15%',
			left: '5%',
			width: '90%',
			position: 'absolute',
		},
	},
	{
		src: '/images/onboarding/education_3_4x.webp',
		alt: "An example of your company's data within Secoda",
		style: {
			top: '2%',
			left: '5%',
			width: '90%',
			position: 'absolute',
		},
	},
	{
		src: '/images/onboarding/education_4_4x.webp',
		alt: 'An example of Secoda in Chrome',
		style: {
			top: '9%',
			left: '5%',
			width: '90%',
			position: 'absolute',
		},
	},
];

interface OnboardingViewerWelcomePageProps {
	startStep?: 0 | 1 | 2 | 3;
}

export default function OnboardingViewerWelcomePage({
	startStep,
}: OnboardingViewerWelcomePageProps) {
	const { classes, cx } = useStyles();
	const [step, setStep] = useState<number>(startStep || 0);
	const { user, isLoading, workspace } = useAuthUser();
	const { chromeExtensionOnboarding } = useFeatureFlags();
	const finalStep = chromeExtensionOnboarding ? 3 : 2;

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	return (
		<Box className={classes.page} data-testid="page">
			<Box className={classes.container} data-testid="container">
				<Flex direction="column" justify="space-between">
					<Box className={classes.contents}>
						<SecodaMinimalLogo className={classes.logo} />
						<Flex gap="xl" direction="column" h="200px">
							<OnboardingViewerContent
								step={step}
								firstName={user?.first_name || ''}
								workspaceName={workspace.name}
							/>
						</Flex>
						<Flex direction="row" gap="sm">
							<OnboardingViewerButtons
								step={step}
								setStep={setStep}
								finalStep={finalStep}
								user={user}
								workspace={workspace}
							/>
						</Flex>
					</Box>
					<Box>
						<Flex direction="row" gap="md">
							{Array.from({ length: finalStep + 1 }, (_, i) => i).map(
								(value, index) => (
									<UnstyledButton
										key={`step-${value}`}
										onClick={() => setStep(index)}
										className={
											step === index
												? cx(
														classes.stepIndicator,
														classes.selectedStepIndicator
													)
												: classes.stepIndicator
										}
									/>
								)
							)}
						</Flex>
					</Box>
				</Flex>
				<Box className={classes.rightPanelContainer}>
					<Box className={classes.rightPanel}>
						<Image
							className={classes.backgroundImage}
							src="/images/onboarding/gradient_2x.webp"
						/>
						<Box style={onboardingImage[step].style}>
							<Image
								src={onboardingImage[step].src}
								alt={onboardingImage[step].alt}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
