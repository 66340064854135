import { Anchor, Box, createStyles, Skeleton, Stack } from '@mantine/core';
import { Text } from '@repo/foundations';
import { memo, Suspense } from 'react';
import { Link } from 'react-router-dom';
import type { Measurement } from '../../../api';
import { useMeasurementList } from '../../../api';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { TableV2 } from '../../../components/TableV2';
import { LastUpdatedRender } from '../../../components/TableV2/render';

export interface IMeasurementsTableProps {
	monitorId?: string;
}

const useStyles = createStyles((theme) => ({
	root: {
		height: '100%',
		minHeight: 450,
		flexGrow: 1,
	},
	tableWrapper: {
		height: '100%',
	},
	title: {
		fontWeight: theme.other.typography.weight.bold,
	},
}));

function MeasurementsTable({ monitorId }: IMeasurementsTableProps) {
	const { classes } = useStyles();

	if (!monitorId) {
		return null;
	}

	return (
		<Stack className={classes.root}>
			<Text className={classes.title}>History</Text>
			<Box className={classes.tableWrapper}>
				<ErrorBoundary>
					<Suspense fallback={<Skeleton h="70vh" w="100%" />}>
						<TableV2<Measurement>
							usePaginationList={useMeasurementList}
							pluralTypeString="measurements"
							withCheckbox={false}
							withInteractiveHeader={false}
							defaultSort={{ field: 'updated_at', order: 'desc' }}
							defaultRequiredSearchParams={{
								monitor: monitorId,
							}}
							columns={[
								{
									accessor: 'updated_at',
									title: 'Last run',
									width: 200,
									render: (record) => (
										<LastUpdatedRender record={record} field={'updated_at'} />
									),
								},
								{
									accessor: 'value',
									title: 'Value',
									width: 200,
									render: (record: Measurement) => (
										<span>{parseFloat(record.value).toFixed(2)}</span>
									),
								},
								{
									accessor: 'incident',
									title: 'Linked incident',
									width: 200,
									render: (record: Measurement) => (
										<Anchor>
											<Link to={`/incident/${record.incident}`}>
												{(
													record?.incident_metadata?.incident as {
														label?: string;
													}
												)?.label ?? ''}
											</Link>
										</Anchor>
									),
								},
							]}
						/>
					</Suspense>
				</ErrorBoundary>
			</Box>
		</Stack>
	);
}

export default memo(MeasurementsTable);
