import type { Filter } from '@repo/api-codegen';
import { fetchApiListTeams, queryKeyFn } from '@repo/api-codegen';

import type {
	FilterItem,
	FilterOption,
	FilterValueType,
} from '@repo/common/components/Filter/types';
import {
	FilterDropdownType,
	FilterOperator,
	FilterOptionType,
	FilterType,
} from '@repo/common/components/Filter/types';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import ItemIcon from '@repo/common/components/ItemIcon';
import { SELECTABLE_PROPERTY_OPTIONS } from '@repo/common/components/SelectableProperty/constants';
import { DataQualityClassification } from '@repo/common/enums/dataQualityClassification';
import { EntityType } from '@repo/common/enums/entityType';
import { getDataQualityClassificationCatalogFilter } from '@repo/common/utils/getDataQualityClassificationCatalogFilter';
import type { IconNames } from '@repo/foundations';
import { Icon } from '@repo/foundations';
import type { ColorNames } from '@repo/theme/utils';
import {
	includes,
	isEmpty,
	lowerCase,
	map,
	sortBy,
	startCase,
	uniqBy,
	uniqWith,
} from 'lodash-es';
import type {
	ICollection,
	IIntegration,
	ISecodaEntity,
	ISlackChannel,
	ITag,
} from '../../api';
import {
	COLLECTIONS_NAMESPACE,
	collectionsQueryKeyFactory,
	fetchCollectionList,
	fetchIntegrationList,
	fetchTagList,
	INTEGRATIONS_NAMESPACE,
	integrationsQueryKeyFactory,
	queryClient,
	TAGS_NAMESPACE,
	tagsQueryKeyFactory,
} from '../../api';
import { fetchSecodaEntity } from '../../api/hooks/secodaEntity/fetchSecodaEntities';
import { slackChannelsQuery } from '../../api/hooks/workspace/useSlackChannels';
import { type SecodaEntity } from '../../lib/models';
import { METRIC_TYPE_INFORMATION } from '../../pages/MonitorPage/constants';
import {
	getEntityFilters,
	getSearchResults,
} from '../../pages/SearchPage/utils';
import { captureError } from '../../web-tracing';
import IntegrationLogo from '../IntegrationLogo';
import { SecodaEntityIcon } from '../SecodaEntity';
import {
	activeUsersAndGroupsGetItemsFn,
	buildEntityGetItemsFn,
	buildGetItemsById,
	buildGetItemsByIdFromSearch,
	convertBooleanFilterToIsSetFilter,
	convertLineageFilterToCatalogFilter,
	convertOwnerFilterToCatalogFilter,
	createdByGetItemsFn,
	fetchQuery,
	renderFilterItemWithBreadcrumbs,
} from './filterConfigUtils';

export const FILTER_OPTIONS_CONFIG: Record<string, FilterOption> = {
	[FilterOptionType.NATIVE_TYPE]: {
		label: 'Type',
		type: FilterOptionType.NATIVE_TYPE,
		field: 'native_type',
		filterDropdownConfig: {
			searchPlaceholder: 'Search types',
			multipleCountsSuffix: 'types',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: async (page?: number, searchTerm?: string) => {
				const filters = await getEntityFilters();
				const nativeTypes = sortBy(uniqBy(filters.native_types, 'label'), [
					(nativeType) => lowerCase(nativeType.value as string),
				]);
				const nativeTypeFilters = nativeTypes?.map((n) => ({
					label: n.label,
					value: n.value,
					entityType: n.entityType,
				}));
				return uniqWith(
					nativeTypeFilters,
					(a, b) => a.value === b.value && a.entityType === b.entityType
				).filter(
					(item) =>
						!searchTerm ||
						item.label.toLowerCase().includes(searchTerm.toLowerCase())
				);
			},
		},
	},
	[FilterOptionType.INTEGRATION]: {
		label: 'Integration',
		type: FilterOptionType.INTEGRATION,
		field: 'integration_id',
		filterDropdownConfig: {
			searchPlaceholder: 'Search integrations',
			multipleCountsSuffix: 'integrations',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: async (page: number = 1, searchTerm: string = '') => {
				const getQueryKey = (filterPage: number, filterSearchTerm: string) =>
					integrationsQueryKeyFactory.list(filterPage, {
						search_term: filterSearchTerm,
					});
				const getQueryFn = (filterPage: number, filterSearchTerm: string) =>
					fetchIntegrationList({
						page: filterPage,
						filters: { search_term: filterSearchTerm },
					});
				const filterFn = (filterSearchTerm: string, result: IIntegration[]) =>
					result.filter(
						(integration) =>
							!searchTerm ||
							integration.name
								.toLowerCase()
								.includes(filterSearchTerm.toLowerCase())
					);

				const results = await fetchQuery<IIntegration>(
					getQueryKey,
					getQueryFn,
					page,
					searchTerm,
					filterFn
				);

				return (
					results?.map((integration) => ({
						label: integration.name,
						value: integration.id,
						icon: (
							<IntegrationLogo
								entityType={EntityType.integration}
								integrationId={integration.id}
								integrationType={integration.type}
								size={16}
							/>
						),
					})) ?? []
				);
			},
			getItemsById: buildGetItemsById(INTEGRATIONS_NAMESPACE),
		},
	},
	[FilterOptionType.INTEGRATION_TYPE]: {
		label: 'Integration type',
		type: FilterOptionType.INTEGRATION_TYPE,
		field: 'integration_type',
		filterDropdownConfig: {
			searchPlaceholder: 'Search integrations',
			multipleCountsSuffix: 'integrations',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: async (page: number = 1, searchTerm: string = '') => {
				const getQueryKey = (filterPage: number) =>
					integrationsQueryKeyFactory.list(filterPage);
				const getQueryFn = (filterPage: number) =>
					fetchIntegrationList({
						page: filterPage,
					});
				const filterFn = (filterSearchTerm: string, result: IIntegration[]) =>
					result.filter(
						(integration) =>
							!searchTerm ||
							integration.type
								.toLowerCase()
								.includes(filterSearchTerm.toLowerCase())
					);

				const results = await fetchQuery<IIntegration>(
					getQueryKey,
					getQueryFn,
					page,
					searchTerm,
					filterFn
				);

				return (
					uniqBy(results, 'type')?.map((integration) => ({
						label: integration.type,
						value: integration.type,
						icon: (
							<IntegrationLogo
								entityType={EntityType.integration}
								integrationId={integration.id}
								integrationType={integration.type}
								size={16}
							/>
						),
					})) ?? []
				);
			},
			getItemsById: async (types: string[]): Promise<FilterItem[]> => {
				const getQueryKey = (filterPage: number) =>
					integrationsQueryKeyFactory.list(filterPage);
				const getQueryFn = (filterPage: number) =>
					fetchIntegrationList({
						page: filterPage,
					});
				const filterFn = (filterSearchTerm: string, result: IIntegration[]) =>
					result.filter((integration) =>
						integration.type
							.toLowerCase()
							.includes(filterSearchTerm.toLowerCase())
					);

				const results = await fetchQuery<IIntegration>(
					getQueryKey,
					getQueryFn,
					1,
					'',
					filterFn
				);

				return results
					.filter((integration) => types.includes(integration.type))
					.map((integration) => ({
						label: integration.type,
						value: integration.type,
						icon: (
							<IntegrationLogo
								entityType={EntityType.integration}
								integrationId={integration.id}
								integrationType={integration.type}
								size={16}
							/>
						),
					}));
			},
		},
	},
	[FilterOptionType.DATABASE]: {
		label: 'Database',
		type: FilterOptionType.DATABASE,
		field: 'database',
		filterDropdownConfig: {
			searchPlaceholder: 'Search databases',
			multipleCountsSuffix: 'databases',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: async (page?: number, searchTerm?: string) => {
				const filters = await getEntityFilters();
				return uniqBy(filters.databases, 'value')
					.map((item) => ({
						label: item.label,
						value: item.value,
					}))
					.filter(
						(item) =>
							!searchTerm ||
							item.label.toLowerCase().includes(searchTerm.toLowerCase())
					);
			},
		},
	},
	[FilterOptionType.SCHEMA]: {
		label: 'Schema',
		type: FilterOptionType.SCHEMA,
		field: 'parent_id',
		filterDropdownConfig: {
			searchPlaceholder: 'Search schemas',
			multipleCountsSuffix: 'schemas',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: buildEntityGetItemsFn(EntityType.schema),
			getItemsById: buildGetItemsByIdFromSearch,
			renderMenuItem: renderFilterItemWithBreadcrumbs(false),
			convertToCatalogFilter: async (value: FilterValueType | null) => {
				if (!value) {
					return null;
				}

				let entity: ISecodaEntity | null = null;

				try {
					entity = await fetchSecodaEntity(value as string);
				} catch (error) {
					captureError(error);
				}

				if (!entity) {
					return {
						operands: [],
						operator: 'exact',
						field: 'parent_id',
						value,
					};
				}

				const operands: Filter[] = [
					{
						operands: [],
						operator: 'exact',
						field: 'integration_id',
						value: entity.integration,
					},
					{
						operands: [],
						operator: 'exact',
						field: 'schema',
						value: entity.title,
					},
				];

				if (!isEmpty(entity.search_metadata?.database)) {
					operands.push({
						operands: [],
						operator: 'exact',
						field: 'database',
						value: entity.search_metadata?.database,
					});
				}

				return {
					operator: 'and',
					operands,
				};
			},
			convertFromCatalogFilter: async (filter: Filter | null) => {
				if (!filter) {
					return null;
				}

				const fields = (filter.operands ?? []).map((operand) => operand.field);

				const isValidSchemaFilter =
					filter.operator === 'and' &&
					((fields.length === 2 &&
						fields.includes('integration_id') &&
						fields.includes('schema')) ||
						(fields.length === 3 &&
							fields.includes('integration_id') &&
							fields.includes('schema') &&
							fields.includes('database')));

				if (!isValidSchemaFilter) {
					return null;
				}

				const integrationId = filter.operands.find(
					(operand) => operand.field === 'integration_id'
				)?.value;
				const schema = filter.operands.find(
					(operand) => operand.field === 'schema'
				)?.value;
				const database = filter.operands.find(
					(operand) => operand.field === 'database'
				)?.value;

				const getItemsFn = buildEntityGetItemsFn(EntityType.schema);
				const items = (await getItemsFn(undefined, String(schema))) ?? [];

				const foundFilterItem = items.find(
					(item) =>
						item.metadata?.integration === integrationId &&
						item.metadata?.title === schema &&
						(database
							? item.metadata?.search_metadata?.database === database
							: true)
				);

				if (foundFilterItem) {
					return {
						filterType: FilterOptionType.SCHEMA,
						value: foundFilterItem.value,
						operator: FilterOperator.Is,
					};
				}

				return null;
			},
		},
	},
	[FilterOptionType.TAGS]: {
		label: 'Tags',
		type: FilterOptionType.TAGS,
		field: 'tags',
		filterDropdownConfig: {
			searchPlaceholder: 'Search tags',
			multipleCountsSuffix: 'tags',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			hasIsNotSetOption: true,
			hasIsSetOption: true,
			getItems: async (page: number = 1, searchTerm: string = '') => {
				const getQueryKey = (filterPage: number, filterSearchTerm: string) =>
					tagsQueryKeyFactory.list(filterPage, {
						name__icontains: filterSearchTerm,
						visible: true,
					});
				const getQueryFn = (filterPage: number, filterSearchTerm: string) =>
					fetchTagList({
						filters: { name__icontains: filterSearchTerm, visible: true },
					});
				const filterFn = (filterSearchTerm: string, result: ITag[]) =>
					result.filter(
						(tag) =>
							!searchTerm ||
							tag.name.toLowerCase().includes(filterSearchTerm.toLowerCase())
					);

				const results = await fetchQuery<ITag>(
					getQueryKey,
					getQueryFn,
					page,
					searchTerm,
					filterFn
				);

				return (
					results?.map((tag: ITag) => ({
						label: startCase(tag.name),
						value: tag.id,
						icon: (
							<Icon
								name="circle"
								style={{ fill: tag.color, color: tag.color, width: 12 }}
							/>
						),
					})) ?? []
				);
			},
			getItemsById: buildGetItemsById(TAGS_NAMESPACE),
		},
	},
	[FilterOptionType.PUBLISHED]: {
		label: 'Published',
		type: FilterOptionType.PUBLISHED,
		field: 'published',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			getItems: [
				{
					label: 'Published',
					value: true,
					icon: <Icon name="circleFilled" fillColor="icon/success/default" />,
				},
				{
					label: 'Draft',
					value: false,
					icon: <Icon name="circleFilled" fillColor="icon/caution/default" />,
				},
			],
		},
	},
	[FilterOptionType.VERIFICATION]: {
		label: 'Verification',
		type: FilterOptionType.VERIFICATION,
		field: 'verified',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			getItems: [
				{
					label: 'Verified',
					value: true,
					icon: <Icon name="verified" fillColor="icon/emphasis/default" />,
				},
				{
					label: 'Unverified',
					value: false,
					icon: <Icon name="notVerified" />,
				},
			],
		},
	},
	[FilterOptionType.PII]: {
		label: 'Governance',
		type: FilterOptionType.PII,
		field: 'pii',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			getItems: [
				{
					label: 'PII',
					value: true,
					icon: <Icon name="PII" fillColor="icon/caution/default" />,
				},
				{
					label: 'Non-PII',
					value: false,
					icon: <Icon name="notPII" />,
				},
			],
		},
	},
	[FilterOptionType.COLLECTIONS]: {
		label: 'Collections',
		type: FilterOptionType.COLLECTIONS,
		field: 'collections',
		filterDropdownConfig: {
			searchPlaceholder: 'Search collections',
			multipleCountsSuffix: 'collections',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			hasIsNotSetOption: true,
			getItems: async (page: number = 1, searchTerm: string = '') => {
				const getQueryKey = (filterPage: number, filterSearchTerm: string) =>
					collectionsQueryKeyFactory.list(filterPage, {
						filters: { search_term: filterSearchTerm },
					});
				const getQueryFn = (filterPage: number, filterSearchTerm: string) =>
					fetchCollectionList({
						page: filterPage,
						filters: { search_term: filterSearchTerm },
					});
				const filterFn = (filterSearchTerm: string, result: ICollection[]) =>
					result.filter(
						(collection) =>
							!searchTerm ||
							collection.title
								?.toLowerCase()
								.includes(filterSearchTerm.toLowerCase())
					);

				const results = await fetchQuery<ICollection>(
					getQueryKey,
					getQueryFn,
					page,
					searchTerm,
					filterFn
				);

				return results?.map((collection: ICollection) => ({
					label: startCase(collection.title),
					value: collection.id,
					icon: (
						<IconWrapper>
							<ItemIcon
								type="emoji"
								item={{ icon: collection.icon }}
								color="icon/brand/default"
							/>
						</IconWrapper>
					),
				}));
			},
			getItemsById: buildGetItemsById(COLLECTIONS_NAMESPACE),
		},
	},
	[FilterOptionType.OWNERS]: {
		label: 'Owners',
		type: FilterOptionType.OWNERS,
		field: 'owners',
		filterDropdownConfig: {
			searchPlaceholder: 'Search owners',
			multipleCountsSuffix: 'owners',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			hasIsNotSetOption: true,
			hasIsSetOption: true,
			getItems: activeUsersAndGroupsGetItemsFn,
			getItemsById: buildGetItemsByIdFromSearch,
			convertToCatalogFilter: convertOwnerFilterToCatalogFilter,
			isSetOverride: {
				operator: 'or',
				operands: [
					{
						operands: [],
						field: 'owners',
						operator: 'is_set',
						value: null,
					},
					{
						operands: [],
						field: 'owner_groups',
						operator: 'is_set',
						value: null,
					},
				],
			},
		},
	},
	[FilterOptionType.SOURCES]: {
		label: 'Metadata source',
		type: FilterOptionType.SOURCES,
		field: 'sources',
		filterDropdownConfig: {
			searchPlaceholder: 'Search sources',
			multipleCountsSuffix: 'sources',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: async (page: number = 1, searchTerm: string = '') => {
				const getQueryKey = (filterPage: number, filterSearchTerm: string) =>
					integrationsQueryKeyFactory.list(filterPage, {
						search_term: filterSearchTerm,
					});
				const getQueryFn = (filterPage: number, filterSearchTerm: string) =>
					fetchIntegrationList({
						page: filterPage,
						filters: { search_term: filterSearchTerm },
					});
				const filterFn = (filterSearchTerm: string, result: IIntegration[]) =>
					result.filter(
						(integration) =>
							!searchTerm ||
							integration.name
								.toLowerCase()
								.includes(filterSearchTerm.toLowerCase())
					);

				const results = await fetchQuery<IIntegration>(
					getQueryKey,
					getQueryFn,
					page,
					searchTerm,
					filterFn
				);

				return map(
					uniqBy(
						results?.filter((integration) =>
							includes(['dbt', 'dbt_core', 'airflow'], integration.type)
						),
						'type'
					),
					(integration) => ({
						label: integration.name,
						value: integration.id,
						icon: (
							<IntegrationLogo
								entityType={EntityType.integration}
								integrationId={integration.id}
								integrationType={integration.type}
								size={16}
							/>
						),
					})
				);
			},
			getItemsById: buildGetItemsById(INTEGRATIONS_NAMESPACE),
		},
	},
	[FilterOptionType.PARENT_ID]: {
		label: 'Group',
		type: FilterOptionType.PARENT_ID,
		field: 'parent_id',
		filterDropdownConfig: {
			searchPlaceholder: 'Search groups',
			multipleCountsSuffix: 'groups',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: async (page?: number, searchTerm?: string) => {
				const filters = await getEntityFilters();
				return uniqBy(filters.groups, 'value')
					.map((item) => ({
						label: item.label,
						value: item.value,
					}))
					?.filter(
						(item) =>
							!searchTerm ||
							item.label.toLowerCase().includes(searchTerm.toLowerCase())
					);
			},
		},
	},
	[FilterOptionType.RELATED]: {
		label: 'Related',
		type: FilterOptionType.RELATED,
		field: 'related',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			searchPlaceholder: 'Search related resources',
			getItems: async (page?: number, searchTerm?: string) => {
				if (!searchTerm) {
					return [];
				}

				const { results: searchResults } = (await getSearchResults(
					searchTerm,
					page
				)) as { results: SecodaEntity[]; totalPages: number };

				return searchResults.map((item) => ({
					label: item.title_cased ?? item.title ?? 'Untitled',
					value: item.id,
					metadata: item,
					icon: <SecodaEntityIcon entity={item} size={20} />,
				})) as FilterItem[];
			},
			getItemsById: buildGetItemsByIdFromSearch,
			renderMenuItem: renderFilterItemWithBreadcrumbs(true),
			disabledInNestedSearch: true,
		},
	},
	[FilterOptionType.SLACK_CHANNELS]: {
		label: 'Slack channel',
		type: FilterOptionType.SLACK_CHANNELS,
		field: 'slack_channels',
		filterDropdownConfig: {
			searchPlaceholder: 'Search slack channels',
			multipleCountsSuffix: 'channels',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: async (page?: number, searchTerm?: string) => {
				const { queryKey, queryFn } = slackChannelsQuery(false);
				const query = await queryClient.fetchQuery({
					queryKey,
					queryFn,
				});

				return query
					?.map((channel: ISlackChannel) => ({
						label: startCase(channel.name),
						value: channel.name,
					}))
					?.filter(
						(item) =>
							!searchTerm ||
							item.label.toLowerCase().includes(searchTerm.toLowerCase())
					);
			},
		},
	},
	[FilterOptionType.QUESTION_STATUS]: {
		label: 'Status',
		type: FilterOptionType.QUESTION_STATUS,
		field: 'question_status',
		filterDropdownConfig: {
			searchPlaceholder: 'Search question status',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: [
				{
					label: 'Answered',
					value: 'ANSWERED',
					icon: <Icon name="check" color="icon/secondary/default" />,
				},
				{
					label: 'In review',
					value: 'IN_REVIEW',
					icon: <Icon name="refresh" color="icon/secondary/default" />,
				},
				{
					label: 'In progress',
					value: 'IN_PROGRESS',
					icon: <Icon name="loader" color="icon/secondary/default" />,
				},
				{
					label: 'Unanswered',
					value: 'UNANSWERED',
					icon: <Icon name="hourglassEmpty" color="icon/secondary/default" />,
				},
				{
					label: 'Cancelled',
					value: 'CANCELLED',
					icon: <Icon name="calendarCancel" color="icon/secondary/default" />,
				},
			],
		},
	},
	[FilterOptionType.QUESTION_PRIORITY]: {
		label: 'Priority',
		type: FilterOptionType.QUESTION_PRIORITY,
		field: 'question_priority',
		filterDropdownConfig: {
			searchPlaceholder: 'Search question priority',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: [
				{
					label: 'No priority',
					value: 'NONE',
					icon: <Icon name="priorityNone" color="icon/secondary/default" />,
				},
				{
					label: 'Low',
					value: 'LOW',
					icon: <Icon name="priorityLow" color="icon/secondary/default" />,
				},
				{
					label: 'Medium',
					value: 'MEDIUM',
					icon: <Icon name="priorityMedium" color="icon/secondary/default" />,
				},
				{
					label: 'High',
					value: 'HIGH',
					icon: <Icon name="priorityHigh" color="icon/secondary/default" />,
				},
			],
		},
	},
	[FilterOptionType.TITLE]: {
		label: 'Title',
		type: FilterOptionType.TITLE,
		field: 'title',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.String,
			defaultOperator: FilterOperator.Contains,
			inputPlaceholder: 'Enter a title',
			hasIsNotSetOption: true,
		},
	},
	[FilterOptionType.DESCRIPTION]: {
		label: 'Description',
		type: FilterOptionType.DESCRIPTION,
		field: 'description',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.String,
			defaultOperator: FilterOperator.Contains,
			inputPlaceholder: 'Enter a description',
			hasIsNotSetOption: true,
		},
	},
	[FilterOptionType.TEAMS]: {
		label: 'Teams',
		type: FilterOptionType.TEAMS,
		field: 'teams',
		filterDropdownConfig: {
			searchPlaceholder: 'Search teams',
			multipleCountsSuffix: 'teams',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			hasIsNotSetOption: true,
			getItems: async (page?: number, searchTerm?: string) => {
				const teams = await queryClient.fetchQuery({
					queryKey: queryKeyFn({
						path: '/auth/teams/',
						operationId: 'apiListTeams',
						variables: {},
					}),
					queryFn: ({ signal }) => fetchApiListTeams({}, signal),
				});

				return teams?.results
					?.map((team) => ({
						label: team.name,
						value: team.id,
						icon: <IconWrapper>{team.icon}</IconWrapper>,
					}))
					?.filter(
						(item) =>
							!searchTerm ||
							item.label.toLowerCase().includes(searchTerm.toLowerCase())
					);
			},
		},
	},
	[FilterOptionType.CREATED_TIME]: {
		label: 'Created time',
		type: FilterOptionType.CREATED_TIME,
		field: 'created_at',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.Date,
			defaultOperator: FilterOperator.IsOnOrBefore,
		},
	},
	[FilterOptionType.UPDATED_TIME]: {
		label: 'Updated time',
		type: FilterOptionType.UPDATED_TIME,
		field: 'updated_at',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.Date,
			defaultOperator: FilterOperator.IsOnOrBefore,
		},
	},
	[FilterOptionType.EXTERNALLY_UPDATED_TIME]: {
		label: 'Externally updated time',
		type: FilterOptionType.EXTERNALLY_UPDATED_TIME,
		field: 'external_updated_at',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.Date,
			defaultOperator: FilterOperator.IsOnOrBefore,
		},
	},
	[FilterOptionType.FREQUENT_USERS]: {
		label: 'Frequent users',
		type: FilterOptionType.FREQUENT_USERS,
		field: 'frequent_users',
		filterDropdownConfig: {
			searchPlaceholder: 'Search users',
			multipleCountsSuffix: 'users',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: buildEntityGetItemsFn(EntityType.user),
			getItemsById: buildGetItemsByIdFromSearch,
		},
	},
	[FilterOptionType.TABLE]: {
		label: 'Table',
		type: FilterOptionType.TABLE,
		field: 'parent_id',
		filterDropdownConfig: {
			searchPlaceholder: 'Search tables',
			multipleCountsSuffix: 'tables',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: buildEntityGetItemsFn(EntityType.table),
			getItemsById: buildGetItemsByIdFromSearch,
			renderMenuItem: renderFilterItemWithBreadcrumbs(false),
			disabledInNestedSearch: true,
		},
	},
	[FilterOptionType.DATA_QUALITY]: {
		label: 'Quality',
		type: FilterOptionType.DATA_QUALITY,
		field: 'dqs.total',
		filterDropdownConfig: {
			hasIsNotSetOption: true,
			defaultOperator: FilterOperator.Is,
			dropdownType: FilterDropdownType.List,
			convertToCatalogFilter: async (value: FilterValueType | null) => {
				if (!value) {
					return null;
				}

				return getDataQualityClassificationCatalogFilter(
					value as DataQualityClassification,
					'dqs.total'
				);
			},
			getItems: [
				{
					label: 'Good',
					value: DataQualityClassification.GOOD,
				},
				{
					label: 'Moderate',
					value: DataQualityClassification.MODERATE,
				},
				{
					label: 'Poor',
					value: DataQualityClassification.POOR,
				},
			],
		},
	},
	[FilterOptionType.IS_MONITORED]: {
		label: 'Is monitored',
		type: FilterOptionType.IS_MONITORED,
		field: 'monitors',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			getItems: [
				{
					label: 'True',
					value: true,
				},
				{
					label: 'False',
					value: false,
				},
			],
			convertToCatalogFilter: convertBooleanFilterToIsSetFilter('monitors'),
		},
	},
	[FilterOptionType.IS_TESTED]: {
		label: 'Has tests',
		type: FilterOptionType.IS_TESTED,
		field: 'all_tests_passing',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			getItems: [
				{
					label: 'True',
					value: true,
				},
				{
					label: 'False',
					value: false,
				},
			],
			convertToCatalogFilter:
				convertBooleanFilterToIsSetFilter('all_tests_passing'),
		},
	},
	[FilterOptionType.TESTS_PASSING]: {
		label: 'Is passing tests',
		type: FilterOptionType.TESTS_PASSING,
		field: 'all_tests_passing',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			getItems: [
				{
					label: 'True',
					value: true,
				},
				{
					label: 'False',
					value: false,
				},
			],
		},
	},
	[FilterOptionType.MONITORS_PASSING]: {
		label: 'Monitors passing',
		type: FilterOptionType.MONITORS_PASSING,
		field: 'passing_monitors',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			getItems: [
				{
					label: 'True',
					value: true,
				},
				{
					label: 'False',
					value: false,
				},
			],
			convertToCatalogFilter:
				convertBooleanFilterToIsSetFilter('passing_monitors'),
		},
	},
	[FilterOptionType.LINEAGE]: {
		label: 'Lineage',
		type: FilterOptionType.LINEAGE,
		field: 'lineage',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Multiple,
			getItems: [
				{
					label: 'Upstream',
					value: 'UPSTREAM',
				},
				{
					label: 'Downstream',
					value: 'DOWNSTREAM',
				},
			],
			convertToCatalogFilter: convertLineageFilterToCatalogFilter(),
		},
	},
	[FilterOptionType.AI]: {
		label: 'AI filter',
		type: FilterOptionType.AI,
		field: 'ai', // TODO: fix this
		icon: <Icon name="sparkles" color="text/ai/default" />,
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.AI,
			defaultOperator: FilterOperator.Is,
		},
	},
	// Monitor measurements
	[FilterOptionType.HAS_INCIDENT]: {
		label: 'Incident occurred',
		type: FilterOptionType.HAS_INCIDENT,
		field: 'has_incident',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			getItems: [
				{
					label: 'True',
					value: true,
				},
				{
					label: 'False',
					value: false,
				},
			],
		},
	},

	[FilterOptionType.MONITOR_METRIC_TYPE]: {
		label: 'Metric Type',
		type: FilterOptionType.MONITOR_METRIC_TYPE,
		field: 'metric_type',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			getItems: Object.entries(METRIC_TYPE_INFORMATION).map(([key, info]) => ({
				label: info.label,
				value: info.value,
			})),
		},
	},
	[FilterOptionType.MONITOR_TARGET]: {
		label: 'Monitored Resource',
		type: FilterOptionType.MONITOR_TARGET,
		field: 'target_id',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			searchPlaceholder: 'Search targets',
			getItems: async (page?: number, searchTerm?: string) => {
				if (!searchTerm) {
					return [];
				}

				const { results: searchResults } = (await getSearchResults(
					searchTerm,
					page
				)) as { results: SecodaEntity[]; totalPages: number };

				return searchResults.map((item) => ({
					label: item.title_cased ?? item.title ?? 'Untitled',
					value: item.id,
					metadata: item,
					icon: <SecodaEntityIcon entity={item} size={20} />,
				})) as FilterItem[];
			},
			getItemsById: buildGetItemsByIdFromSearch,
			renderMenuItem: renderFilterItemWithBreadcrumbs(true),
			disabledInNestedSearch: true,
		},
	},
	[FilterOptionType.RUN_DATE]: {
		label: 'Run date',
		type: FilterOptionType.RUN_DATE,
		field: 'created_at',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.Date,
			defaultOperator: FilterOperator.IsOnOrBefore,
		},
	},
	[FilterOptionType.DIRECTION]: {
		label: 'Direction',
		type: FilterOptionType.DIRECTION,
		field: 'direction',
		filterDropdownConfig: {
			searchPlaceholder: 'Search direction',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			hasIsSetOption: false,
			hasIsNotSetOption: false,
			getItems: [
				{
					label: 'Upstream',
					value: 'UPSTREAM',
				},
				{
					label: 'Downstream',
					value: 'DOWNSTREAM',
				},
			],
		},
	},
	[FilterOptionType.UPSTREAM_DEPTH]: {
		label: 'Upstream',
		type: FilterOptionType.UPSTREAM_DEPTH,
		field: 'upstream_depth',
		filterDropdownConfig: {
			searchPlaceholder: 'Set depth limit...',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			getItems: [
				{
					label: '1 level',
					value: 1,
				},
				{
					label: '2 levels',
					value: 2,
				},
				{
					label: '3 levels',
					value: 3,
				},
				{
					label: '4 levels',
					value: 4,
				},
				{
					label: '5 levels',
					value: 5,
				},
				{
					label: '6 levels',
					value: 6,
				},
				{
					label: '7 levels',
					value: 7,
				},
				{
					label: '8 levels',
					value: 8,
				},
				{
					label: '9 levels',
					value: 9,
				},
				{
					label: '10 levels',
					value: 10,
				},
			],
		},
	},
	[FilterOptionType.DOWNSTREAM_DEPTH]: {
		label: 'Downstream',
		type: FilterOptionType.DOWNSTREAM_DEPTH,
		field: 'downstream_depth',
		filterDropdownConfig: {
			searchPlaceholder: 'Set depth limit...',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			getItems: [
				{
					label: '1 level',
					value: 1,
				},
				{
					label: '2 levels',
					value: 2,
				},
				{
					label: '3 levels',
					value: 3,
				},
				{
					label: '4 levels',
					value: 4,
				},
				{
					label: '5 levels',
					value: 5,
				},
				{
					label: '6 levels',
					value: 6,
				},
				{
					label: '7 levels',
					value: 7,
				},
				{
					label: '8 levels',
					value: 8,
				},
				{
					label: '9 levels',
					value: 9,
				},
				{
					label: '10 levels',
					value: 10,
				},
			],
		},
	},
	[FilterOptionType.CREATED_BY]: {
		label: 'Created by',
		type: FilterOptionType.CREATED_BY,
		field: 'created_by',
		filterDropdownConfig: {
			searchPlaceholder: 'Search creator',
			multipleCountsSuffix: 'creators',
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: createdByGetItemsFn,
			getItemsById: buildGetItemsByIdFromSearch,
		},
	},
	[FilterOptionType.SEVERITY]: {
		label: 'Severity',
		type: FilterOptionType.SEVERITY,
		field: 'severity',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			getItems: SELECTABLE_PROPERTY_OPTIONS.severity.map((item) => ({
				value: item.value as string,
				label: item.label as string,
				icon: (
					<Icon
						name={item.iconName as IconNames}
						color={item.color as ColorNames}
					/>
				),
			})),
		},
	},
	[FilterOptionType.FRAMEWORK]: {
		label: 'Frameworks',
		type: FilterOptionType.FRAMEWORK,
		field: 'frameworks',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			getItems: SELECTABLE_PROPERTY_OPTIONS.complianceFramework.map((item) => ({
				value: item.value as string,
				label: item.label as string,
			})),
		},
	},
	[FilterOptionType.ENABLED]: {
		label: 'Enabled',
		type: FilterOptionType.ENABLED,
		field: 'enabled',
		filterDropdownConfig: {
			dropdownType: FilterDropdownType.List,
			defaultOperator: FilterOperator.Is,
			filterType: FilterType.Single,
			getItems: [
				{
					label: 'True',
					value: true,
				},
				{
					label: 'False',
					value: false,
				},
			],
		},
	},
};
