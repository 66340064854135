import type { MantineTheme } from '@mantine/core';
import {
	createStyles,
	FocusTrap,
	Group,
	TextInput,
	Tooltip,
} from '@mantine/core';
import { Text } from '@repo/foundations';
import { isNil } from 'lodash-es';
import type { ChangeEvent, ReactNode } from 'react';
import { useState } from 'react';
import { usePropertyStyles } from '../styles';

type PropertyValue = number | null | undefined;

interface INumericPropertyProps {
	label: string;
	value: PropertyValue;
	tooltip?: string;
	forceInline?: boolean;
	onValueChange: (newValue: PropertyValue) => void;
	readOnly?: boolean;
	displayValue?: string;
	unit?: string;
	wrapValueWith?: (children: ReactNode) => ReactNode;
}

const useStyles = createStyles((theme: MantineTheme) => ({
	rightColStatic: {
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},
		borderRadius: theme.spacing.xs,
		padding: theme.spacing.xs,
	},
	input: {
		width: 150,
	},
	rightSection: {
		width: 70,
	},
	value: {
		fontSize: theme.fontSizes.sm,
		fontWeight: theme.other.typography.weight.regular,
		lineHeight: '16px',
		color: theme.other.getColor('fill/brand/default'),
	},
}));

export function NumericProperty({
	label,
	value,
	tooltip,
	forceInline,
	onValueChange,
	displayValue,
	unit,
	readOnly = false,
	wrapValueWith,
}: INumericPropertyProps) {
	const { classes, cx } = useStyles();
	const { classes: propertyClasses } = usePropertyStyles();
	const [editing, setEditing] = useState<boolean>(false);
	const [inputChanged, setInputChanged] = useState(false);

	const formattedValue =
		value === null || value === undefined ? 'None' : value.toString();
	const [inputValue, setInputValue] = useState(
		value === null || value === undefined ? '' : value.toString()
	);

	let valueCol = (
		<Tooltip label={tooltip} withArrow withinPortal disabled={isNil(tooltip)}>
			<Text
				className={classes.value}
				ml={0}
				data-testid={`numeric-property-${label.toLowerCase()}`}
			>
				{displayValue ? displayValue : formattedValue}
			</Text>
		</Tooltip>
	);

	const startEditing = () => {
		if (!editing && !readOnly) {
			setInputValue(
				value === null || value === undefined ? '' : value.toString()
			);
			setEditing(true);
			setInputChanged(false);
		}
	};

	const finishEditing = () => {
		if (editing) {
			setEditing(false);
			let parsedValue: number | null = null;

			if (typeof inputValue === 'string') {
				const floatValue = parseFloat(inputValue);
				if (!Number.isNaN(floatValue)) {
					parsedValue = floatValue;
				}
			}

			if (inputChanged) {
				onValueChange(parsedValue);
			}
		}
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		// Allow numbers with optional negative sign and decimal point
		if (/^-?[0-9]*\.?[0-9]*$/.test(event.currentTarget.value)) {
			setInputValue(event.currentTarget.value);
			setInputChanged(true);
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		// If Enter key is pressed
		if (event.key === 'Enter') {
			finishEditing();
		}
	};

	const textInputRightSection = unit && (
		<Text color="text/secondary/default" size="sm">
			{unit}
		</Text>
	);

	if (editing) {
		valueCol = (
			<FocusTrap active>
				<TextInput
					size="xs"
					data-testid={`numeric-property-editor-${label.toLowerCase()}`}
					onBlur={finishEditing}
					onChange={handleChange}
					value={inputValue}
					onKeyDown={handleKeyDown}
					classNames={{
						input: classes.input,
						rightSection: classes.rightSection,
					}}
					rightSection={textInputRightSection}
				/>
			</FocusTrap>
		);
	}

	const rightColClasses = cx(propertyClasses.rightCol, {
		[classes.rightColStatic]: !editing,
	});

	const rightColContent = (
		<Group className={rightColClasses} onClick={startEditing}>
			{valueCol}
		</Group>
	);

	return (
		<Group spacing={0}>
			<Group
				className={cx(propertyClasses.leftCol, {
					[propertyClasses.leftColForceInline]: Boolean(forceInline),
				})}
				noWrap
			>
				<Group noWrap>
					<Text size="sm" color="text/secondary/default" lineClamp={1}>
						{label}
					</Text>
				</Group>
			</Group>
			{wrapValueWith ? wrapValueWith(rightColContent) : rightColContent}
		</Group>
	);
}
