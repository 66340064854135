export enum UpdateMethod {
	POST = 'POST',
	PUT = 'PUT',
	DELETE = 'DELETE',
}

export enum FilterType {
	CHECKBOX_SELECT = 'checkboxFilter',
	INPUT_SELECT = 'inputFilter',
}

export enum SearchType {
	CLEAR_TERM = 'clear_search_term',
	FILTER = 'update_filter',
	INLINE_SEARCH = 'inline_search',
	INLINE_SELECT = 'inline_select',
	LOAD_URL = 'load_url',
	PAGINATION = 'update_page',
	SUBMIT_TERM = 'submit_search_term',
}

export enum WidgetSize {
	SMALL = 'sm',
	MEDIUM = 'md',
	LARGE = 'lg',
	FULL = 'full',
}

export enum WidgetType {
	HELPFUL_LINKS = 'helpful_links',
	INTEGRATIONS = 'integrations',
	TEXT_BLOCK = 'text_block',
	INBOX = 'inbox',
	ANNOUNCEMENTS = 'announcements',
	PINNED_COLLECTIONS = 'pinned_collections',
	POPULAR_COLLECTIONS = 'popular_collections',
	COLLECTION_RESOURCES = 'collection_resources',
	BOOKMARKED_RESOURCES = 'bookmarked_resources',
	POPULAR_RESOURCES = 'popular_resources',
	LOWEST_QUALITY_SCORE = 'lowest_quality_score',
	RECENTLY_EDITED_RESOURCES = 'recently_edited',
	RECENTLY_ACCESSED_RESOURCES = 'recently_accessed',
	RECENT_SEARCHES = 'recent_searches',
	METRIC_LINE_CHART = 'metric_line_chart',
	METRIC_BAR_CHART = 'metric_bar_chart',
	METRIC_SINGLE_VALUE = 'metric_single_value',
	METRIC_PIE_CHART = 'metric_pie_chart',
	METRIC_RESOURCE_TABLE = 'metric_resource_table',
	METRIC_USER_TABLE = 'metric_user_table',
	METRIC_GROUP_TABLE = 'metric_group_table',
	METRIC_SEARCH_TERM_TABLE = 'metric_search_term_table',
	METRIC_TAG_TABLE = 'metric_tag_table',
	DATA_QUALITY_SCORE = 'data_quality_score',
	QUESTIONS_POPULAR = 'questions_popular',
	QUESTIONS_BOOKMARKED = 'questions_bookmarked',
	QUESTIONS_RECENTLY_EDITED = 'questions_recently_edited',
	QUESTIONS_RECENTLY_ACCESSED = 'questions_recently_accessed',
	MONITOR = 'monitor',
	AI_CHAT_HISTORY = 'ai_chat_history',
	METRIC_QUERIES_TABLE = 'metric_queries_table',
}

export enum TeamType {
	OPEN = 'OPEN',
	CLOSED = 'CLOSED',
}

export enum DatabuilderJobType {
	PUSH = 'push',
	METADATA = 'metadata',
	DUMMY = 'dummy',
	SCHEMA = 'schema',
}
