import { createStyles, Modal } from '@mantine/core';
import { Suspense } from 'react';
import { SnapshotModalContent } from './SnapshotModalContent';
import { SnapshotModalContentSkeleton } from './SnapshotModalContentSkeleton';

const useStyles = createStyles(() => ({
	content: {
		height: '100%',
	},
	header: {
		position: 'initial',
	},
	body: {
		height: 'calc(100% - 54px)', // 54px is the height of the modal header
		padding: '0 !important',
	},
}));

export interface SnapshotsModalProps {
	resourceId: string;
	initialSelectedVersionHistoryId?: string;
	onClose: () => void;
}

export function SnapshotsModal({
	resourceId,
	initialSelectedVersionHistoryId,
	onClose,
}: SnapshotsModalProps) {
	const { classes } = useStyles();

	return (
		<Modal
			opened
			onClose={onClose}
			size={960}
			classNames={classes}
			title="Version history"
		>
			<Suspense fallback={<SnapshotModalContentSkeleton />}>
				<SnapshotModalContent
					resourceId={resourceId}
					initialSelectedId={initialSelectedVersionHistoryId}
					onClose={onClose}
				/>
			</Suspense>
		</Modal>
	);
}
