import { Flex, Group } from '@mantine/core';
import type { PolicyOut } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { PolicyStatusRender } from '../../../PoliciesListPage/render';
import { INLINE_TEXT_HEIGHT, INLINE_TEXT_WIDTH } from './constants';

interface InlineStatusProps {
	policy: PolicyOut;
}

export default function InlineStatus({ policy }: InlineStatusProps) {
	return (
		<Group spacing="xs" noWrap align="flex-start">
			<Text
				w={INLINE_TEXT_WIDTH}
				style={{ lineHeight: `${INLINE_TEXT_HEIGHT}px` }}
				size="sm"
				color="text/secondary/default"
			>
				Status
			</Text>
			<Flex pt="3xs" pl="3xs">
				<PolicyStatusRender record={policy} />
			</Flex>
		</Group>
	);
}
