import type { IIntegration } from '@repo/common/models/integration';
import { useMemo } from 'react';
import {
	useActions as useIntegrationsActions,
	useColumns as useIntegrationsColumns,
} from '../../../pages/IntegrationsPage/IntegrationsListPage.hooks';
import type { ICommandListItem } from '../../Spotlight/components/CommandPalette/constants';
import type { ExtendedDataTableColumn } from '../../TableV2/types';

export function useColumns<
	T extends IIntegration,
>(): ExtendedDataTableColumn<T>[] {
	const integrationColumns = useIntegrationsColumns();

	const columns: ExtendedDataTableColumn<T>[] = useMemo(
		() => [
			...integrationColumns.filter(
				(column) =>
					column.accessor !== 'status' &&
					column.accessor !== 'teams' &&
					!column.accessor.startsWith('scheduled_extractions')
			),
		],
		[integrationColumns]
	);

	return columns;
}

export const useActions = (): ICommandListItem<IIntegration>[] => {
	const integrationActions = useIntegrationsActions();

	const actions: ICommandListItem<IIntegration>[] = useMemo(
		() => [
			...integrationActions.filter((action) => action.id !== 'actions::run'),
		],
		[integrationActions]
	);

	return actions;
};
