import { Group, useMantineTheme } from '@mantine/core';
import { Button, Icon, Text } from '@repo/foundations';
import { useAuthUser } from '../../api/hooks/authUser/useAuthUser';
import { handleLogout } from '../../utils/cache/utils';
import { NavBarWrapper } from '../SecodaAppShell/SecodaNavBar';
import { useImpersonationBanner } from './useImpersonationBanner';

export function ImpersonationBanner() {
	const { user: currentUser } = useAuthUser();
	const theme = useMantineTheme();

	const { showImpersonationBanner } = useImpersonationBanner();

	if (!showImpersonationBanner) {
		return null;
	}

	return (
		<NavBarWrapper bg={theme.other.getColor('surface/caution/default')}>
			<Group position="apart" w="100%">
				<Group spacing="xs">
					<Icon name="infoCircle" />
					<Text weight="bold" size="sm">
						Logged in as {currentUser?.display_name}
					</Text>
				</Group>
				<Button
					variant="default"
					onClick={() => handleLogout('/settings/members')}
				>
					Stop impersonation
				</Button>
			</Group>
		</NavBarWrapper>
	);
}
