import { useMutation } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import { aiEmbeddedQueryKeyFactory } from './constants';

export function useDeleteAllAIEmbedded() {
	const mutationFn = async () => {
		const url = getEndpoints(aiEmbeddedQueryKeyFactory.namespace).byPath([
			'delete_all',
		]);
		await apiClient.post(url);
	};

	return useMutation({
		mutationFn,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: aiEmbeddedQueryKeyFactory.list(1, {}),
			});
		},
	});
}
