import {
	ActionIcon,
	createStyles,
	Group,
	SimpleGrid,
	Stack,
	Tabs,
	TextInput,
} from '@mantine/core';
import { Text } from '@repo/foundations';
import { map } from 'lodash-es';
import { useState } from 'react';
import {
	COVER_ART_MAPPING,
	COVER_GRADIENT_MAPPING,
	COVER_LANDSCAPE_MAPPING,
	COVER_PATTERN_MAPPING,
	COVER_SPACE_MAPPING,
} from '../constants';
import ColorPaletteChip from './ColorPaletteChip';

const DEFAULT_COLOR_OPTIONS = [
	'#000000',
	'#6867AF',
	'#16AFD0',
	'#E16E79',
	'#4154D6',
	'#FDDBCA',
	'#F2DEC9',
	'#D4A657',
	'#CD4B93',
	'#EA3E23',
	'#F6D3CE',
	'#E3E4E5',
	'#7A7B80',
	'#545554',
	'#027974',
	'#6AC4A2',
	'#FDB814',
];

const useStyles = createStyles((theme) => ({
	colorCircle: {
		borderRadius: theme.radius.xl,
		width: 16,
		height: 16,
	},
	chosenColorCircle: {
		position: 'absolute',
		cursor: 'pointer',
		width: '8px',
		height: '8px',
		backgroundColor: theme.other.getColor('fill/primary/default'),
		borderRadius: theme.radius.sm,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	switchColorButton: {
		marginLeft: 'auto',
		cursor: 'pointer',
		border: `solid 1px ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.xl,
		width: '20px',
		height: '20px',
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		color: theme.other.getColor('icon/secondary/default'),
	},
	hexTextInput: {
		width: '75px',
		height: '20px',
		display: 'flex',
		alignItems: 'center',
	},
}));

export interface IDefaultColorPickerPanelProps {
	backgroundImage?: string;
	onBackgroundImageChange: (backgroundImage: string, fontColor: string) => void;
}

function DefaultColorPickerPanel({
	backgroundImage,
	onBackgroundImageChange,
}: IDefaultColorPickerPanelProps) {
	const { theme, classes } = useStyles();
	const [showColorCircle, setShowColorCircle] = useState(true);

	const handleBackgroundImageChange =
		(backgroundImage: string, fontColor: string) => () => {
			onBackgroundImageChange(backgroundImage, fontColor);
		};

	const isValidHex = (color: string) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

	const handleColorInputChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const inputValue = event.target.value.trim();
		if (isValidHex(inputValue)) {
			handleBackgroundImageChange(inputValue, 'white')();
		}
	};

	return (
		<Tabs.Panel value="defaults">
			<Stack p="md" style={{ maxHeight: '500px', overflowY: 'auto' }}>
				<Stack spacing="xs">
					<Text transform="uppercase" color="text/secondary/default" size="sm">
						Color
					</Text>
					<Group spacing="sm">
						{showColorCircle ? (
							<>
								{DEFAULT_COLOR_OPTIONS.map((col) => (
									<div key={col} style={{ position: 'relative' }}>
										<ActionIcon
											size={16}
											variant="filled"
											style={{ backgroundColor: col }}
											className={classes.colorCircle}
											onClick={handleBackgroundImageChange(col, 'white')}
										/>
										{backgroundImage === col && (
											<div className={classes.chosenColorCircle} />
										)}
									</div>
								))}
								<div
									className={classes.switchColorButton}
									onClick={() => setShowColorCircle(false)}
								>
									#
								</div>
							</>
						) : (
							<Group spacing="sm" align="center" w="100%">
								<ActionIcon
									size={16}
									variant="filled"
									style={{
										backgroundColor:
											backgroundImage && isValidHex(backgroundImage)
												? backgroundImage
												: '#000000',
									}}
									className={classes.colorCircle}
								/>
								<TextInput
									onChange={handleColorInputChange}
									placeholder={
										backgroundImage && isValidHex(backgroundImage)
											? backgroundImage
											: '#000000'
									}
									size="xs"
									className={classes.hexTextInput}
								/>
								<div
									className={classes.switchColorButton}
									style={{
										background: 'conic-gradient(red, yellow, green, blue, red)',
									}}
									onClick={() => setShowColorCircle(true)}
								/>
							</Group>
						)}
					</Group>
				</Stack>
				<Stack spacing="xs">
					<Text transform="uppercase" color="text/secondary/default" size="sm">
						Gradient
					</Text>
					<SimpleGrid cols={4} spacing="xs">
						{map(COVER_GRADIENT_MAPPING, (image, name) => (
							<ColorPaletteChip
								key={name}
								alt={name}
								thumbnail={`/images/home-bg-covers/gradient/${name}`}
								backgroundImage={image}
								onClick={handleBackgroundImageChange(image, 'white')}
							/>
						))}
					</SimpleGrid>
				</Stack>
				<Stack spacing="xs">
					<Text transform="uppercase" color="text/secondary/default" size="sm">
						Pattern
					</Text>
					<SimpleGrid cols={4} spacing="xs">
						{map(COVER_PATTERN_MAPPING, (image, name) => (
							<ColorPaletteChip
								key={name}
								alt={name}
								thumbnail={`/images/home-bg-covers/pattern/${name}`}
								backgroundImage={image}
								onClick={handleBackgroundImageChange(image, 'white')}
							/>
						))}
					</SimpleGrid>
				</Stack>
				<Stack spacing="xs">
					<Text transform="uppercase" color="text/secondary/default" size="sm">
						Landscape
					</Text>
					<SimpleGrid cols={4} spacing="xs">
						{map(COVER_LANDSCAPE_MAPPING, (image, name) => (
							<ColorPaletteChip
								key={name}
								alt={name}
								thumbnail={`/images/home-bg-covers/landscape/${name}`}
								backgroundImage={image}
								onClick={handleBackgroundImageChange(image, 'white')}
							/>
						))}
					</SimpleGrid>
				</Stack>
				<Stack spacing="xs">
					<Text transform="uppercase" color="text/secondary/default" size="sm">
						Space
					</Text>
					<SimpleGrid cols={4} spacing="xs">
						{map(COVER_SPACE_MAPPING, (image, name) => (
							<ColorPaletteChip
								key={name}
								alt={name}
								thumbnail={`/images/home-bg-covers/space/${name}`}
								backgroundImage={image}
								onClick={handleBackgroundImageChange(image, 'white')}
							/>
						))}
					</SimpleGrid>
				</Stack>
				<Stack spacing="xs">
					<Text transform="uppercase" color="text/secondary/default" size="sm">
						Art
					</Text>
					<SimpleGrid cols={4} spacing="xs">
						{map(COVER_ART_MAPPING, (image, name) => (
							<ColorPaletteChip
								key={name}
								alt={name}
								thumbnail={`/images/home-bg-covers/art/${name}`}
								backgroundImage={image}
								onClick={handleBackgroundImageChange(image, 'white')}
							/>
						))}
					</SimpleGrid>
				</Stack>
			</Stack>
		</Tabs.Panel>
	);
}

export default DefaultColorPickerPanel;
