import { Group, createStyles } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import type { ColorNames } from '@repo/theme/utils';
import { capitalize } from 'lodash-es';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import type { IncidentStatus } from '../../../api';
import { INCIDENT_ICON_MAPPING } from '../constants';

const BADGE_DEF: Record<
	IncidentStatus,
	{ textColor: ColorNames; bg: ColorNames; text: string }
> = {
	active: {
		textColor: 'text/critical/default',
		bg: 'fill/critical-secondary/default',
		text: 'Open',
	},
	acknowledged: {
		textColor: 'text/caution/default',
		bg: 'fill/caution-secondary/default',
		text: 'Acknowledged',
	},
	resolved: {
		textColor: 'text/success/default',
		bg: 'fill/success-secondary/default',
		text: 'Resolved',
	},
	expired: {
		textColor: 'text/primary/default',
		bg: 'surface/secondary/default',
		text: 'Expired',
	},
};

const useStyles = createStyles(
	(
		theme,
		{
			incidentStatus,
		}: {
			incidentStatus: IncidentStatus;
		}
	) => ({
		root: {
			background: theme.other.getColor(BADGE_DEF[incidentStatus].bg),
			borderRadius: theme.radius.sm,
			padding: `${theme.spacing['4xs']} ${theme.spacing['2xs']}`,
			margin: 0,
		},
		inner: {
			color: theme.other.getColor(BADGE_DEF[incidentStatus].textColor),
		},
	})
);

export interface IncidentStatusBadgeProps {
	incidentStatus: IncidentStatus;
}

export const IncidentStatusBadge = forwardRef(
	(
		{ incidentStatus }: IncidentStatusBadgeProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const { classes } = useStyles({ incidentStatus });

		return (
			<Group spacing="3xs" noWrap>
				{incidentStatus && (
					<Icon
						size="md"
						name={INCIDENT_ICON_MAPPING[incidentStatus].iconName}
						fillColor={INCIDENT_ICON_MAPPING[incidentStatus].iconFillColor}
						color={INCIDENT_ICON_MAPPING[incidentStatus].iconColor}
					/>
				)}
				<Text size="sm" color="text/primary/default" truncate>
					{incidentStatus
						? capitalize(
								incidentStatus === 'resolved' ||
									incidentStatus === 'acknowledged' ||
									incidentStatus === 'expired'
									? incidentStatus
									: 'open'
							)
						: ''}
				</Text>
			</Group>
		);
	}
);
IncidentStatusBadge.displayName = 'IncidentStatusBadge';
