import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { useMeasurementFromWidget } from '../../../api';
import { snakeCaseToTitleCase } from '../../../utils/shared.utils';
import { type ISampleOrActualMetricWidget } from '../constants';
import { convertValue, isEmptyMetric, useChartColors } from '../utils/utils';
import { MetricLoadingOrEmpty } from './MetricLoadingOrEmpty';
import {
	MetricWidgetLineChart,
	type MetricWidgetLineChartProps,
} from './MetricWidgetLineChart/MetricWidgetLineChart';

export type IMetricIntegrationLineChartProps = ISampleOrActualMetricWidget;

export function MetricIntegrationLineChart(
	props: IMetricIntegrationLineChartProps
) {
	const { metricWidget, source } = props;
	const chartColors = useChartColors();
	const { data: datapoints, isLoading } =
		useMeasurementFromWidget(metricWidget);

	const isLoadingOrEmpty = isEmptyMetric(datapoints) || isLoading;

	const chartProps = useMemo(() => {
		if (isLoadingOrEmpty) {
			return { channels: [], data: [] };
		}

		const valueNames = Object.keys(metricWidget.metric_metadata?.value || {});
		const channels = valueNames.map((value) => ({
			label: snakeCaseToTitleCase(value),
			color:
				valueNames.length === 1
					? chartColors[0]
					: metricWidget.metric_metadata?.value_color?.[value] ||
						chartColors[0],
			x: 'date',
			y: value,
		})) as MetricWidgetLineChartProps<{ date: Date }>['channels'];

		const data = datapoints.map((point) => ({
			date: dayjs(point.timestamp).toDate(),
			...valueNames.reduce(
				(acc, value) => ({
					...acc,
					[value]: convertValue(point[value] as number),
				}),
				{}
			),
		}));

		// TODO [marcio]: to be memoized before release
		const getTooltipData = (dataIndex: number) => {
			const point = data[dataIndex] as Record<string, number> & { date: Date };

			const items = valueNames.map((value) => ({
				label: snakeCaseToTitleCase(value),
				value: convertValue(point[value] as number),
				color:
					valueNames.length === 1
						? chartColors[0]
						: metricWidget.metric_metadata?.value_color?.[value] ||
							chartColors[0],
			}));

			return { date: point.date, items };
		};

		return { data, channels, getTooltipData };
	}, [
		isLoadingOrEmpty,
		datapoints,
		metricWidget.metric_metadata?.value,
		metricWidget.metric_metadata?.value_color,
	]);

	// Passing in updated color to MetricWidgetLineChart
	const updatedMetricProps = useMemo(() => {
		const singleValue =
			Object.keys(metricWidget.metric_metadata?.value || {}).length === 1;
		if (
			source === 'widget' &&
			singleValue &&
			metricWidget.metric_metadata?.value
		) {
			return {
				source: source,
				metricWidget: {
					...metricWidget,
					metric_metadata: {
						...metricWidget.metric_metadata,
						value_color: singleValue
							? { [metricWidget.metric_metadata?.value[0]]: chartColors[0] }
							: metricWidget.metric_metadata?.value_color,
					},
				},
			};
		} else return props;
	}, [props, metricWidget, source]);

	if (isLoadingOrEmpty) {
		return (
			<MetricLoadingOrEmpty
				isLoading={isLoading}
				isEmpty={isEmpty(datapoints)}
				source={source}
			/>
		);
	}

	return <MetricWidgetLineChart {...updatedMetricProps} {...chartProps} />;
}
