export const ROLE_OPTIONS = [
	{ label: 'Data Governance', value: 'data_governance' },
	{ label: 'Data Analyst', value: 'data_analyst' },
	{ label: 'Data Engineer', value: 'data_engineer' },
	{ label: 'Analytics Engineer', value: 'analytics_engineer' },
	{ label: 'Product Manager', value: 'product_manager' },
	{ label: 'Software Engineer', value: 'software_engineer' },
	{ label: 'Customer Support', value: 'customer_support' },
	{ label: 'Marketing', value: 'marketing' },
	{ label: 'Sales', value: 'sales' },
	{ label: 'Design', value: 'design' },
	{ label: 'Executive', value: 'executive' },
	{ label: 'Other', value: 'other' },
];

export const USE_SECODA_FOR_OPTIONS = [
	{
		label:
			'Discovery – Easily find and understand datasets across your organization.',
		value: 'discovery',
	},
	{
		label:
			'Lineage – Visualize how data moves through your systems for better traceability.',
		value: 'lineage',
	},
	{
		label:
			'Compliance – Ensure data governance and meet regulatory requirements like GDPR and HIPAA.',
		value: 'compliance',
	},
	{
		label:
			'Observability – Track and manage data-related incidents with monitoring.',
		value: 'observability',
	},
	{
		label:
			'Documentation – Enable teams to ask questions and write documentation to share knowledge.',
		value: 'documentation',
	},
	{
		label:
			'AI – Ask questions to Secoda AI to quickly get answers and save time in your workflows.',
		value: 'ai',
	},
	{
		label: 'Other',
		value: 'other',
	},
];
