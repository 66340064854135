import {
	createStyles,
	SimpleGrid,
	ThemeIcon,
	UnstyledButton,
} from '@mantine/core';
import { Icon, type IconNames, Text } from '@repo/foundations';
import { map } from 'lodash-es';
import { useIntercom } from 'react-use-intercom';
import { usePlan } from '../../../../../../../hooks/usePlans';
import type { IWidgetItemProps } from '../../../types';

const useStyles = createStyles((theme) => ({
	itemWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: theme.spacing.xs,
		borderRadius: 1000,
		padding: theme.other.space[1],
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		'&:hover': {
			color: theme.other.getColor('text/primary/default'),
			backgroundColor: theme.other.getColor('fill/transparent/hover'),
		},
	},
}));

function HelpfulLinksWidget({ widget }: IWidgetItemProps) {
	const { boot, show } = useIntercom();
	const { trial } = usePlan();
	const { classes } = useStyles();

	const openIntercom: VoidFunction = () => {
		boot();
		show();
	};

	const items = [
		{
			iconName: 'book' as IconNames,
			title: 'Documentation',
			url: 'https://docs.secoda.co/',
		},
		{
			iconName: 'brandSlack' as IconNames,
			title: 'Slack community',
			url: 'https://join.slack.com/t/thedataleap/shared_invite/zt-1ue6lzzj8-O8wDIYwigNMw_P30VtRvvQ',
		},
		...(trial
			? []
			: [
					{
						iconName: 'messages' as IconNames,
						title: 'Talk to an expert',
						onClick: openIntercom,
					},
				]),
	];

	const cols = widget.size === 'full' ? 3 : 1;

	const handleClick = (onClick?: VoidFunction) => (event: React.MouseEvent) => {
		if (onClick === undefined) {
			return;
		}

		event.preventDefault();
		onClick();
	};

	return (
		<SimpleGrid px="sm" pt="sm" cols={cols}>
			{map(items, ({ iconName, title, url, onClick }) => (
				<UnstyledButton
					component="a"
					href={url}
					target="_blank"
					rel="noopener noreferrer"
					key={title}
					className={classes.itemWrapper}
					onClick={handleClick(onClick)}
				>
					<ThemeIcon size={32} radius={24} color="fill/secondary/default">
						<Icon name={iconName} color="icon/primary/default" />
					</ThemeIcon>
					<Text size="sm">{title}</Text>
				</UnstyledButton>
			))}
		</SimpleGrid>
	);
}

export default HelpfulLinksWidget;
