import {
	useApiGetEntityCounts,
	useApiHasPreviewAccess,
} from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { useMount } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import {
	useAuthUser,
	useIntegration,
	useIntegrationSpec,
	useUpdateSecodaEntity,
} from '../../api';
import { useSuspenseSecodaEntity } from '../../api/hooks/secodaEntity/useSuspenseSecodaEntity';
import Documentation from '../../components/Documentation';
import EntityDiscussions from '../../components/EntityDiscussions';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';
import EntityPageLayoutSkeleton from '../../components/EntityPageLayout/EntityPageLayoutSkeleton';
import { LineageGraphWrapper } from '../../components/LineageGraph/LineageGraph';
import type { OverviewPageTab } from '../../components/Overview/OverviewPageTabs';
import OverviewPageTabs from '../../components/Overview/OverviewPageTabs';
import UrlPreview from '../../components/Preview/UrlPreview';
import { QueryList } from '../../components/QueryListV2/QueryList';
import { trackEvent } from '../../utils/analytics';
import { useSilentUrlUpdate } from '../../utils/hook/useSilentUrlUpdate';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';

const InnerChartPage = observer(({ id }: { id: string }) => {
	const { user, workspace } = useAuthUser();

	const { data: chart } = useSuspenseSecodaEntity({
		id,
	});

	useMount(() => {
		trackEvent('chart_entity/open', {}, user, workspace);
	});

	const { mutateAsync } = useUpdateSecodaEntity({});

	const integrationId = chart?.integration || '';

	const { data: integration } = useIntegration({
		id: integrationId,
		options: { enabled: !!integrationId },
	});

	const integrationSpec = useIntegrationSpec(integrationId);

	const { data: previewPermissionGranted } = useApiHasPreviewAccess(
		{
			pathParams: {
				integrationId: integrationId,
			},
		},
		{ enabled: !!integrationId }
	);

	const { data: entityCounts, isLoading } = useApiGetEntityCounts(
		{
			queryParams: {
				entity_id: id || '',
			},
		},
		{
			enabled: !!id,
		}
	);

	const updateChart = useCallback(
		async (key: string, value: DjangoValueType, saveRemotely = true) => {
			if (saveRemotely && chart) {
				await mutateAsync({
					data: {
						id: chart.id,
						[key]: value,
					},
				});
				trackEvent('chart/properties/update', {}, user, workspace!);
			}
		},
		[mutateAsync, chart, user, workspace]
	);

	if (!chart) {
		return <EntityPageLayoutSkeleton />;
	}

	const showPreviewTab =
		integrationSpec &&
		integrationSpec.type === 'builtin' &&
		integrationSpec.value.previewSupport &&
		!!previewPermissionGranted;

	const documentationFirst = (chart.definition?.length || 0) > 2;

	const getDefaultTab = () => {
		if (showPreviewTab) {
			return 'preview';
		}
		if (documentationFirst) {
			return 'documentation';
		}
		return 'lineage';
	};

	const { currentTab, setCurrentTab } = useSilentUrlUpdate(getDefaultTab());

	const handleTabChange = useCallback(
		(updatedTab: string) => {
			if (updatedTab === 'lineage') {
				trackEvent('chart/lineage/open', {}, user, workspace);
			}
			setCurrentTab(updatedTab);
		},
		[setCurrentTab, user, workspace]
	);

	const tabs = useMemo(() => {
		const tabArray: OverviewPageTab[] = [
			...(showPreviewTab
				? [
						{
							value: 'preview',
							label: 'Preview',
							component: (
								<UrlPreview
									integration={integration?.type as string}
									entity={chart}
								/>
							),
						},
					]
				: []),
			{
				value: 'lineage',
				label: 'Lineage',
				component: (
					<LineageGraphWrapper
						id={chart.id}
						entityType={chart.entity_type}
						nativeType={chart.native_type ?? ''}
						published={chart.published}
					/>
				),
			},
			{
				value: 'queries',
				label: 'Queries',
				condition: !isLoading && Boolean(entityCounts?.query_count),
				component: <QueryList entity={chart} />,
			},
			{
				value: 'discussions',
				label: 'Questions',
				component: <EntityDiscussions entity={chart} />,
			},
			{
				value: 'documentation',
				label: 'Documentation',
				component: <Documentation entity={chart} />,
			},
		];

		if (documentationFirst) {
			const docTabIndex = tabArray.findIndex(
				(t) => t.value === 'documentation'
			);
			if (docTabIndex !== -1) {
				const [docTab] = tabArray.splice(docTabIndex, 1);
				tabArray.unshift(docTab);
			}
		}

		return tabArray;
	}, [
		showPreviewTab,
		integration?.type,
		chart,
		isLoading,
		entityCounts?.query_count,
		documentationFirst,
	]);

	return (
		<EntityPageLayout
			key={chart.id}
			name="Chart"
			isReadOnlyTitle
			entity={chart}
			icon={<EntityLogo size={32} entity={chart} />}
			updateEntity={updateChart}
			withDescription
			{...getEntityPageSidebarProps(EntityType.chart)}
		>
			<OverviewPageTabs
				key={chart.id}
				entity={chart}
				currentTab={currentTab || getDefaultTab()}
				tabs={tabs}
				onTabChange={handleTabChange}
			/>
		</EntityPageLayout>
	);
});

// by memoizing the inner component, we can avoid re-rendering the entire page on tab changes and only re-render the tab panel
export default function ChartPage() {
	const id = useParamsIdSuffixUuid();

	return <InnerChartPage id={id} />;
}
