import {
	Checkbox,
	createStyles,
	Group,
	Stack,
	Tooltip,
	UnstyledButton,
} from '@mantine/core';
import { Icon as IconComponent, Text } from '@repo/foundations';
import { map, pickBy, size } from 'lodash-es';
import type { Metadata, MetadataMapKey } from '../types';
import { getMetadataIconAndLabel } from '../utils';
import EntityValue from './EntityValue';

export interface IPropertySelectorProps {
	title: string | undefined;
	metadata: Record<MetadataMapKey, Metadata[MetadataMapKey]>;
	properties: Record<MetadataMapKey, boolean>;
	onPropertyChange: (key: MetadataMapKey, value: boolean) => void;
	showOverrideMetadata: boolean;
	overrideChildrenMetadata: boolean;
	onOverrideChildrenMetadataToggle: VoidFunction;
}

const useStyles = createStyles((theme) => ({
	tableWrapper: {
		border: `thin solid ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.sm,
	},
	row: {
		display: 'grid',
		gridTemplateRows: '1fr',
		gridTemplateColumns: '25ch auto',
		borderBottom: `thin solid ${theme.other.getColor('border/primary/default')}`,
		alignItems: 'center',

		cursor: 'pointer',
		fontSize: theme.fontSizes.sm,

		'&:hover': {
			'.left-cell,.right-cell': {
				// eslint-disable-next-line no-theme-colors/no-theme-colors
				backgroundColor: theme.fn.lighten(theme.colors.primary[0], 0.5),
			},
		},

		'&:first-of-type': {
			'& .left-cell': {
				borderTopLeftRadius: theme.radius.sm,
			},

			'& .right-cell': {
				borderTopRightRadius: theme.radius.sm,
			},
		},

		'&:last-of-type': {
			'& .left-cell': {
				borderBottomLeftRadius: theme.radius.sm,
			},

			'& .right-cell': {
				borderBottomRightRadius: theme.radius.sm,
			},

			borderBottom: 'none',
		},
	},
	selectedRow: {
		// eslint-disable-next-line no-theme-colors/no-theme-colors
		backgroundColor: theme.fn.lighten(theme.colors.primary[0], 0.5),
	},
	cell: {
		padding: theme.spacing.xs,
	},
	leftCell: {
		backgroundColor: theme.other.getColor('surface/input/default'),
		paddingLeft: theme.spacing.md,
		borderRight: `thin solid ${theme.other.getColor('border/primary/default')}`,
	},
	rightCell: {
		backgroundColor: theme.other.getColor('fill/primary/default'),
		minHeight: 36,
		padding: 0,
		paddingLeft: theme.spacing.xs,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	icon: {
		color: theme.other.getColor('fill/primary/selected'),
		width: 16,
		height: 16,
	},
}));

function PropertySelector({
	title,
	metadata,
	properties,
	onPropertyChange,
	showOverrideMetadata,
	overrideChildrenMetadata,
	onOverrideChildrenMetadataToggle,
}: IPropertySelectorProps) {
	const { cx, classes } = useStyles();

	const selectedCount = size(pickBy(properties));

	const handleCheckboxChange =
		(key: MetadataMapKey, checked: boolean) => () => {
			onPropertyChange(key, !checked);
		};

	return (
		<Stack mt={8} spacing={8}>
			<Group position="apart" noWrap>
				<Text lineClamp={1} size="md" weight="semibold">
					Select metadata properties from <strong>{title}</strong>
				</Text>
				{selectedCount && (
					<Text size="sm" color="text/secondary/default" lineClamp={1}>
						{`${selectedCount} selected`}
					</Text>
				)}
			</Group>
			<Stack spacing={0} className={classes.tableWrapper}>
				{map(metadata, (value, key: MetadataMapKey) => {
					const { icon: Icon, label } = getMetadataIconAndLabel(
						key as MetadataMapKey
					);

					const checked = properties[key];

					return (
						<UnstyledButton
							key={key}
							className={cx(classes.row)}
							onClick={handleCheckboxChange(key, checked)}
						>
							<Group
								spacing={12}
								className={cx('left-cell', classes.cell, classes.leftCell, {
									[classes.selectedRow]: checked,
								})}
								noWrap
							>
								<Checkbox checked={checked} readOnly />
								<Icon size={20} className={classes.icon} />
								<Text>{label}</Text>
							</Group>
							<Group
								className={cx('right-cell', classes.cell, classes.rightCell, {
									[classes.selectedRow]: checked,
								})}
								noWrap
							>
								<EntityValue type={key} value={value} />
							</Group>
						</UnstyledButton>
					);
				})}
				{showOverrideMetadata && (
					<UnstyledButton
						className={cx(classes.row)}
						onClick={onOverrideChildrenMetadataToggle}
					>
						<Group
							spacing={12}
							className={cx('left-cell', classes.cell, classes.leftCell, {
								[classes.selectedRow]: overrideChildrenMetadata,
							})}
							noWrap
						>
							<Checkbox checked={overrideChildrenMetadata} readOnly />
							<IconComponent name="columns" className={classes.icon} />
							<Text>Column metadata</Text>
							<Tooltip
								label={`Propagate the properties from each column on the ${title} table to the columns with the same name on the selected tables below.`}
								multiline
								width={248}
							>
								<IconComponent name="infoCircle" />
							</Tooltip>
						</Group>
						<Group
							className={cx('right-cell', classes.cell, classes.rightCell, {
								[classes.selectedRow]: overrideChildrenMetadata,
							})}
							noWrap
						/>
					</UnstyledButton>
				)}
			</Stack>
		</Stack>
	);
}

export default PropertySelector;
