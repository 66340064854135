import { createStyles, getStylesRef, TextInput } from '@mantine/core';
import { useCallback, useState } from 'react';
import { useUpdateMetric, type IMetric } from '../../../../../../api';
import { useDebouncedCallbackWithFlush } from '../../../../../../hooks/useDebouncedCallbackWithFlush';

const useStyles = createStyles((theme) => ({
	wrapper: {
		background: theme.other.getColor('surface/secondary/default'),
		borderRadius: theme.radius.md,
		border: `solid ${theme.other.borderWidth.sm}px ${theme.other.getColor('border/secondary/default')}`,

		[`&:hover .${getStylesRef('hideIfNotHovered')}, &:focus-within .${getStylesRef('hideIfNotHovered')}`]:
			{
				opacity: 1,
			},
	},
	titleInput: {
		background: 'transparent',
		color: theme.other.getColor('text/primary/default'),
		border: 0,
		fontWeight: theme.other.typography.weight.bold,
		fontSize: theme.fontSizes.sm,
		lineHeight: theme.other.typography.lineHeight.text.sm,
		height: 'unset',
		paddingLeft: theme.spacing['3xs'],
	},
	sqlEditor: {
		padding: `0 ${theme.spacing.md} ${theme.spacing.md}`,
	},
	floatingToggleQuery: {
		position: 'absolute',
		top: 0,
		left: -48,
	},
	floatingToggleResults: {
		position: 'absolute',
		top: 0,
		left: -44,
	},
	hideIfNotHovered: {
		ref: getStylesRef('hideIfNotHovered'),
		opacity: 0,
	},
	expandCollapseButton: {
		opacity: 0,
	},
}));

export interface QueryBlockTitleProps {
	metric: IMetric;
	readOnly?: boolean;
}

export function QueryBlockTitle({ metric, readOnly }: QueryBlockTitleProps) {
	const { classes } = useStyles();
	const [title, setTitle] = useState(metric.title);

	const { mutateAsync } = useUpdateMetric({
		disableInvalidation: true,
	});

	const debouncedMutateAsync = useDebouncedCallbackWithFlush(
		(newTitle: string) =>
			mutateAsync({
				data: { id: metric.id, title: newTitle },
				optimisticUpdate: true,
			}),
		200
	);

	const handleOnChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const newTitle = e.currentTarget.value;
			setTitle(newTitle);

			debouncedMutateAsync(newTitle);
		},
		[debouncedMutateAsync]
	);

	return (
		<TextInput
			variant="unstyled"
			classNames={{ input: classes.titleInput }}
			defaultValue={title}
			onChange={handleOnChange}
			readOnly={readOnly}
		/>
	);
}
