import { useEffect } from 'react';
import { useAuthUser } from '../api';
import { captureLog } from '../web-tracing';

export function useAiEnabled() {
	const { user } = useAuthUser();

	useEffect(() => {
		// I need to understand why this is toggling to false sometimes, when the user submits an AI prompt
		// restricting this to Secoda Internal workspace
		if (
			!user?.workspace?.id ||
			user?.workspace?.id === '03ee0cc6-51f8-404c-b4be-ab574a4f9c51'
		) {
			// eslint-disable-next-line no-console
			console.log([
				'workspace.ai_search',
				user?.workspace?.id,
				user?.workspace?.ai_search ? 'True' : 'False',
			]);
			captureLog([
				'workspace.ai_search',
				user?.workspace?.id,
				user?.workspace?.ai_search ? 'True' : 'False',
			]);
		}
	}, [user?.workspace?.ai_search, user?.workspace?.id]);

	return !!user?.workspace?.ai_search;
}
