import { isNil, startCase, toLower } from 'lodash-es';

/**
 * Singularizes a word based on common English pluralization patterns.
 * @param word - The word to singularize.
 * @returns The singularized form of the input word.
 */
export function singularize(word: string) {
	if (word.endsWith('ies')) {
		return `${word.slice(0, -3)}y`;
	}
	if (word.endsWith('es')) {
		return word.slice(0, -2);
	}
	if (word.endsWith('s')) {
		return word.slice(0, -1);
	}
	return word;
}

/**
 * Pluralizes a word based on common English pluralization patterns.
 * @param word - The word to pluralize.
 * @param count - The count associated with the word (optional).
 * @param inclusive - Whether to include the count in the result (optional).
 * @returns The pluralized form of the input word, optionally including the count.
 */
export function pluralize(word: string, count?: number, inclusive?: boolean) {
	let result = word;

	if (isNil(count) || count > 1) {
		if (word.toLowerCase().endsWith('s')) {
			result = word;
		} else if (word.toLowerCase().endsWith('y')) {
			result = `${word.slice(0, -1)}ies`;
		} else {
			result = `${word}s`;
		}
	}

	if (inclusive) {
		result = `${count} ${result}`;
	}

	return result;
}

/**
 * Converts a string to sentence case (lowercase with the first letter of each word capitalized).
 * Uses lodash startCase and toLower functions.
 * @param str - The string to convert to sentence case.
 * @returns The input string converted to sentence case.
 */
export function sentenceCase(str: string): string {
	return startCase(toLower(str));
}

/**
 * Truncates a string if it exceeds a maximum length and adds an ellipsis.
 * @param text - The string to truncate.
 * @param maxLength - The maximum allowed length of the string.
 * @returns The truncated string with an ellipsis if it exceeds maxLength, otherwise the original string.
 */
export function truncateWithEllipsis(text: string, maxLength: number): string {
	// Check if the text length is greater than the maximum length
	if (text.length > maxLength) {
		// Truncate the text and add an ellipsis
		return `${text.substring(0, maxLength - 1)}…`;
	}

	// Return the original text if it's within the length limit
	return text;
}
