import { useDisclosure } from '@mantine/hooks';
import { ResourceLogs, ResourceLogsSkeleton } from '@repo/common/components';
import { Suspense, useState } from 'react';
import { useAuthUser } from '../../api';
import { useResourceActivity } from '../../api/hooks/notification/useResourceActivity';
import type { SecodaEntity } from '../../lib/models';
import { trackEvent } from '../../utils/analytics';
import { useFeatureFlags } from '../../utils/featureFlags';
import { SnapshotsModal } from '../SnapshotsModal';
import Timeline from './Timeline';

interface IActivityLogProps {
	entity?: Pick<SecodaEntity, 'id' | 'entity_type' | 'created_at'>;
}

function ActivityLogInternal({ entity }: IActivityLogProps) {
	const { data: notifications } = useResourceActivity(entity?.id);

	if (!notifications) {
		return null;
	}

	return <Timeline notifications={notifications} entity={entity} />;
}

export default function ActivityLog({ entity }: IActivityLogProps) {
	const { feActivityLog } = useFeatureFlags();
	const { user, workspace } = useAuthUser();

	const [opened, { open, close }] = useDisclosure(false);
	const [selectedVersionHistoryId, setSelectedVersionHistoryId] = useState<
		string | undefined
	>();

	const handleClickVersionHistory = (versionHistoryId: string) => {
		if (!entity) {
			return;
		}

		trackEvent(`${entity.entity_type}/snapshots/click`, {}, user, workspace);

		setSelectedVersionHistoryId(versionHistoryId);
		open();
	};

	if (feActivityLog && entity?.id) {
		return (
			<Suspense fallback={<ResourceLogsSkeleton />}>
				<ResourceLogs
					id={entity.id}
					onClickVersionHistory={handleClickVersionHistory}
				/>
				{opened && (
					<SnapshotsModal
						resourceId={entity.id}
						initialSelectedVersionHistoryId={selectedVersionHistoryId}
						onClose={close}
					/>
				)}
			</Suspense>
		);
	}

	return <ActivityLogInternal entity={entity} />;
}
