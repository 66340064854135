import { useMutation } from '@tanstack/react-query';
import { fromPairs, map } from 'lodash-es';
import { apiClient, getEndpoints } from '../../common';
import { reportsQueryKeyFactory } from '../report';

interface IReorderWidgetParams {
	data: {
		widgetIds: string[];
		from: number;
		to: number;
	};
	reportId: string;
}

export function useReorderWidgets() {
	const mutationFn = async ({
		data: { widgetIds, from, to },
		reportId,
	}: IReorderWidgetParams) => {
		const item = widgetIds[from];

		widgetIds.splice(from, 1);
		widgetIds.splice(to, 0, item);

		const widgetOrder = fromPairs(map(widgetIds, (id, order) => [id, order]));

		const baseUrl = getEndpoints(reportsQueryKeyFactory.namespace).byId(
			reportId
		);

		const url = `${baseUrl}reorder`;

		await apiClient.post(url, { widget_order: widgetOrder });
	};

	return useMutation({
		mutationFn,
	});
}
