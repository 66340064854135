import { useMutation, useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import { createQueryKeys } from '../../factories';
import queryClient from '../../queryClient';
import type {
	IAgentTool,
	IAgentToolsResponse,
	IAIPrivacyPreferences,
} from '../../types';

const AI_TOOLS_NAMESPACE = ['ai', 'preferences', 'tools'];
const AI_PRIVACY_SETTINGS_NAMESPACE = ['ai', 'preferences', 'privacy'];
const AI_GOVERNANCE_INCLUDED_NAMESPACE = [
	'ai',
	'preferences',
	'governance',
	'included',
];
const AI_GOVERNANCE_EXCLUDED_NAMESPACE = [
	'ai',
	'preferences',
	'governance',
	'excluded',
];
const AI_GOVERNANCE_INCLUDED_ENTITIES_NAMESPACE = [
	'ai',
	'preferences',
	'governance',
	'entities',
	'included',
];
const AI_INSTRUCTIONS_NAMESPACE = ['ai', 'preferences', 'instructions'];
const AI_DESCRIPTIONS_NAMESPACE = ['ai', 'preferences', 'descriptions'];

export const aiToolsQueryKeys = createQueryKeys(AI_TOOLS_NAMESPACE);
export const aiPrivacySettingsQueryKeys = createQueryKeys(
	AI_PRIVACY_SETTINGS_NAMESPACE
);
export const aiInstructionsQueryKeys = createQueryKeys(
	AI_INSTRUCTIONS_NAMESPACE
);
export const aiDescriptionsQueryKeys = createQueryKeys(
	AI_DESCRIPTIONS_NAMESPACE
);
export const aiGovernanceIncludedEntitiesQueryKeys = createQueryKeys(
	AI_GOVERNANCE_INCLUDED_ENTITIES_NAMESPACE
);

export const aiGovernanceIncludedQueryKeys = createQueryKeys(
	AI_GOVERNANCE_INCLUDED_NAMESPACE
);
export const aiGovernanceExcludedQueryKeys = createQueryKeys(
	AI_GOVERNANCE_EXCLUDED_NAMESPACE
);

export function useAgentTools() {
	const queryKey = aiToolsQueryKeys.all();

	const queryFn = async () => {
		const url = getEndpoints(AI_TOOLS_NAMESPACE).byPath([]);
		const { data } = await apiClient.get<IAgentToolsResponse>(url, {});

		return data;
	};

	return useQuery({
		queryKey,
		queryFn,
	});
}

export function useAIPrivacySettings() {
	const queryKey = aiPrivacySettingsQueryKeys.all();

	const queryFn = async () => {
		const url = getEndpoints(AI_PRIVACY_SETTINGS_NAMESPACE).byPath([]);
		const { data } = await apiClient.get<IAIPrivacyPreferences>(url, {});

		return data;
	};

	return useQuery({
		queryKey,
		queryFn,
	});
}

export function useToggleAIPrivacySetting() {
	const mutationFn = async ({
		key,
		value,
	}: {
		key: keyof IAIPrivacyPreferences;
		value: boolean;
	}): Promise<IAIPrivacyPreferences> => {
		const url = getEndpoints(AI_PRIVACY_SETTINGS_NAMESPACE).byPath([]);
		const { data } = await apiClient.patch<IAIPrivacyPreferences>(url, {
			[key]: value,
		});

		return data;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: aiPrivacySettingsQueryKeys.all(),
			});
		},
	});
}

export function useEnableAgentTool() {
	const mutationFn = async ({
		tool,
	}: {
		tool: IAgentTool;
	}): Promise<IAgentTool> => {
		const url = getEndpoints(AI_TOOLS_NAMESPACE).byPath([tool.name, 'enable']);
		const { data } = await apiClient.post<IAgentTool>(url, tool);

		return data;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: aiToolsQueryKeys.all(),
			});
		},
	});
}

export function useDisableAgentTool() {
	const mutationFn = async ({
		tool,
	}: {
		tool: IAgentTool;
	}): Promise<IAgentTool> => {
		const url = getEndpoints(AI_TOOLS_NAMESPACE).byPath([tool.name, 'disable']);
		const { data } = await apiClient.post<IAgentTool>(url, tool);

		return data;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: aiToolsQueryKeys.all(),
			});
		},
	});
}

export function useGetAgentInstructions() {
	const queryKey = aiInstructionsQueryKeys.all();

	const queryFn = async () => {
		const url = getEndpoints(AI_INSTRUCTIONS_NAMESPACE).byPath([]);
		const { data } = await apiClient.get<{ instructions: string }>(url, {});

		return data.instructions;
	};

	return useQuery({
		queryKey,
		queryFn,
	});
}

export function useUpdateAgentInstructions() {
	const mutationFn = async ({
		instructions,
	}: {
		instructions: string;
	}): Promise<string> => {
		const url = getEndpoints(AI_INSTRUCTIONS_NAMESPACE).byPath([]);
		const { data } = await apiClient.post<{ instructions: string }>(url, {
			instructions,
		});

		return data.instructions;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: aiInstructionsQueryKeys.all(),
			});
		},
	});
}

export function useGetCustomDescriptionStyle() {
	const queryKey = aiDescriptionsQueryKeys.all();

	const queryFn = async () => {
		const url = getEndpoints(AI_DESCRIPTIONS_NAMESPACE).byPath([]);
		const { data } = await apiClient.get<{ instructions: string }>(url, {});

		return data.instructions;
	};

	return useQuery({
		queryKey,
		queryFn,
	});
}

export function useUpdateCustomDescriptionStyle() {
	const mutationFn = async ({
		instructions,
	}: {
		instructions: string;
	}): Promise<string> => {
		const url = getEndpoints(AI_DESCRIPTIONS_NAMESPACE).byPath([]);
		const { data } = await apiClient.post<{ instructions: string }>(url, {
			instructions,
		});

		return data.instructions;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: aiDescriptionsQueryKeys.all(),
			});
		},
	});
}

export function useGetGovernanceIncludedEntities(entityId: string) {
	const queryKey = [...aiGovernanceIncludedEntitiesQueryKeys.all(), entityId];

	const queryFn = async () => {
		const url = getEndpoints(AI_GOVERNANCE_INCLUDED_ENTITIES_NAMESPACE).byPath(
			[]
		);
		const { data } = await apiClient.get(`${url}?entity_id=${entityId}`, {});

		return data[entityId];
	};

	return useQuery({
		queryKey,
		queryFn,
	});
}
