import type { MantineThemeOverride } from '@mantine/core';

export const Radio: MantineThemeOverride['components'] = {
	Radio: {
		styles: (theme, params, context) => {
			let size: number = theme.other.space[5];
			let fontSize: string = theme.other.typography.text.lg;
			let lineHeight: string = theme.other.typography.lineHeight.text.lg;
			if (context.size === 'xs') {
				// eslint-disable-next-line prefer-destructuring
				size = theme.other.space[4];
				fontSize = theme.other.typography.text.xs;
				lineHeight = theme.other.typography.lineHeight.text.xs;
			} else if (context.size === 'sm') {
				// eslint-disable-next-line prefer-destructuring
				size = theme.other.space[5];
				fontSize = theme.other.typography.text.sm;
				lineHeight = theme.other.typography.lineHeight.text.sm;
			} else if (context.size === 'md') {
				// eslint-disable-next-line prefer-destructuring
				size = theme.other.space[6];
				fontSize = theme.other.typography.text.md;
				lineHeight = theme.other.typography.lineHeight.text.md;
			} else if (context.size === 'lg') {
				// eslint-disable-next-line prefer-destructuring
				size = theme.other.space[7];
				fontSize = theme.other.typography.text.lg;
				lineHeight = theme.other.typography.lineHeight.text.lg;
			} else if (context.size === 'xl') {
				// eslint-disable-next-line prefer-destructuring
				size = theme.other.space[8];
				fontSize = theme.other.typography.text.xl;
				lineHeight = theme.other.typography.lineHeight.text.xl;
			}

			return {
				radio: {
					width: size,
					height: size,
					borderColor: theme.other.getColor('border/input/default'),
					backgroundColor: theme.other.getColor('surface/input/default'),
					'&:focus': {
						outlineColor: theme.other.getColor('border/emphasis/default'),
						outlineWidth: theme.other.space[0.5],
					},
					'&:hover': {
						backgroundColor: theme.other.getColor('surface/input/hover'),
					},
					'&:active': {
						backgroundColor: theme.other.getColor('surface/input/active'),
					},
					'&:checked:hover': {
						background: theme.other.getColor('fill/brand/hover'),
						borderColor: theme.other.getColor('fill/brand/hover'),
					},
					'&:checked:active': {
						background: theme.other.getColor('fill/brand/active'),
						borderColor: theme.other.getColor('fill/brand/active'),
					},
					'&:checked': {
						background: theme.other.getColor('fill/brand/selected'),
						borderColor: theme.other.getColor('fill/brand/selected'),
					},
					'&:disabled': {
						background: theme.other.getColor('fill/brand/disabled'),
						borderColor: theme.other.getColor('fill/brand/disabled'),
					},
					'&:checked:hover:disabled': {
						background: theme.other.getColor('fill/brand/disabled'),
						borderColor: theme.other.getColor('fill/brand/disabled'),
					},
				},
				inner: {
					alignSelf: 'flex-start',
					marginTop: theme.other.space[1],
					marginBottom: theme.other.space[1],
				},
				labelWrapper: {
					color: theme.other.getColor('text/primary/default'),
					justifyContent: 'center',
					marginTop: theme.other.space[1],
					marginBottom: theme.other.space[1],
				},
				icon: {
					color: theme.other.getColor('surface/input/default'),
				},
				label: {
					fontSize,
					lineHeight,
					height: size,
					paddingLeft: theme.other.space[2],
				},
				description: {
					color: theme.other.getColor('text/secondary/default'),
					paddingLeft: theme.other.space[2],
				},
			};
		},
	},
};
