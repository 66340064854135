import {
	Code,
	createStyles,
	Group,
	List,
	ScrollArea,
	Stack,
	useMantineTheme,
} from '@mantine/core';
import { Text } from '@repo/foundations';
import { map } from 'lodash-es';
import type { IDatabuilderJobSummary } from '../../../api';
import type { DatabuilderJobSummary } from '../../../lib/models';
import { snakeCaseToTitleCase } from '../../../utils/shared.utils';

const useStyles = createStyles((theme) => ({
	root: {
		color: theme.other.getColor('text/secondary/default'),
	},
}));

export function ExtractionNumEntitiesSummary(props: {
	databuilderJobSummary: DatabuilderJobSummary;
}) {
	const { classes } = useStyles();
	const { databuilderJobSummary } = props;
	if (!databuilderJobSummary.num_entities_by_entity_type) {
		return null;
	}

	return (
		<List.Item>
			Number of resources synced
			<List classNames={classes}>
				{map(
					databuilderJobSummary.num_entities_by_entity_type,
					(numEntities, entityType) => (
						<List.Item key={entityType}>
							<Group>
								{numEntities} {snakeCaseToTitleCase(entityType)}s
							</Group>
						</List.Item>
					)
				)}
			</List>
		</List.Item>
	);
}

export function ExtractionCsvSummary(props: {
	databuilderJobSummary: DatabuilderJobSummary;
}) {
	const { databuilderJobSummary } = props;
	return (
		<>
			{typeof databuilderJobSummary.num_rows !== 'undefined' && (
				<List.Item>
					Number of rows in CSV: {databuilderJobSummary.num_rows}
				</List.Item>
			)}
			{typeof databuilderJobSummary.num_invalid_rows !== 'undefined' && (
				<List.Item>
					Number of invalid rows in CSV:{' '}
					{databuilderJobSummary.num_invalid_rows}
				</List.Item>
			)}
			{databuilderJobSummary.invalid_rows_and_validation_errors &&
				databuilderJobSummary.invalid_rows_and_validation_errors.length > 0 && (
					<List.Item>
						<Stack>Error samples:</Stack>
						{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
						{/* @ts-ignore poorly typed component by Mantine */}
						<ScrollArea.Autosize mah={480}>
							<Code
								sx={{
									whiteSpace: 'break-spaces',
									tabSize: 2,
								}}
							>
								{JSON.stringify(
									databuilderJobSummary.invalid_rows_and_validation_errors,
									null,
									2
								)}
							</Code>
						</ScrollArea.Autosize>
					</List.Item>
				)}
		</>
	);
}

export function ExtractionSummary(props: {
	databuilderJobSummary: IDatabuilderJobSummary;
}) {
	const { databuilderJobSummary } = props;
	const theme = useMantineTheme();
	const { classes } = useStyles();

	if (
		!databuilderJobSummary ||
		!databuilderJobSummary.num_entities_by_entity_type
	) {
		return null;
	}
	return (
		<Stack spacing="xs">
			<Text fw="semibold" size="lg">
				Sync summary
			</Text>
			<List classNames={classes}>
				<ExtractionNumEntitiesSummary {...props} />
				<ExtractionCsvSummary {...props} />
			</List>
		</Stack>
	);
}
