import {
	createStyles,
	Group,
	Timeline as MantineTimeline,
	Stack,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useApiRestoreEntityFromActivityLog } from '@repo/api-codegen';
import { getEntityDisplayType } from '@repo/common/utils/entityDisplayUtils';
import { Button, Icon, Text } from '@repo/foundations';
import { capitalize, lowerCase } from 'lodash-es';
import moment from 'moment-timezone';
import { useCallback } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useAuthUser } from '../../api';
import { fetchSecodaEntity } from '../../api/hooks/secodaEntity/fetchSecodaEntities';
import type { IActivity } from '../../api/types/models/notification';
import type { SecodaEntity } from '../../lib/models';
import { isImpersonating } from '../../utils/cache/utils';
import { MarkdownDiff } from '../MarkdownDiff/MarkdownDiff';
import { openModal } from '../ModalManager';
import { workspaceSelectorStore } from '../SecodaAppShell/SideBar/WorkspaceSelector/store';
import {
	notificationEventToIconName,
	notificationEventToTitle,
} from './Timeline.helpers';
import ActivityLogDescription from './TimelineDescription';

export interface ITimelineProps {
	notifications: IActivity[];
	// TODO: Remove the following prop. At this level, the concept
	// of entity should not exist. The notifications are sufficient.
	// This currently exists because of the hardcoded notification
	// defined below.
	entity?: Pick<SecodaEntity, 'id' | 'entity_type' | 'created_at'>;
	// This is used to determine whether to fetch more notifications
	// when the user scrolls to the bottom of the timeline, and
	// it marks that the component should render a infinite scroll
	onEndReached?: VoidFunction;
}

const useStyles = createStyles((theme) => ({
	itemBody: {
		paddingLeft: theme.spacing['2xl'],
		paddingBottom: theme.spacing.sm,
	},
}));

function Timeline({ notifications, entity, onEndReached }: ITimelineProps) {
	const { classes } = useStyles();
	const { workspace } = useAuthUser();
	const { mutate: restoreEntityFromActivityLog } =
		useApiRestoreEntityFromActivityLog();

	const openDiff = useCallback(async (notification: IActivity) => {
		try {
			const notificationEntity = await fetchSecodaEntity(
				notification.resource_id
			);
			openModal({
				fullScreen: true,
				title: 'Snapshot diff viewer',
				children: (
					<MarkdownDiff
						oldValue={
							(notification?.metadata?.['previous_value'] as string) ?? ''
						}
						newValue={notificationEntity?.definition ?? ''}
					/>
				),
			});
		} catch (error) {
			showNotification({
				title: 'Error',
				message: 'Failed to fetch entity',
				color: 'red',
			});
		}
	}, []);

	const notificationContent = (notification: IActivity) => {
		const iconName = notificationEventToIconName(notification);

		const title = notificationEventToTitle(
			notification,
			workspace,
			restoreEntityFromActivityLog,
			false,
			false,
			capitalize
		);

		return (
			<MantineTimeline.Item
				key={notification.id}
				data-testid="activity-log-timeline-item"
				bullet={<Icon name={iconName} />}
				title={
					<Group spacing="xs">
						{title}
						{(workspaceSelectorStore?.account?.is_superuser ||
							isImpersonating()) && (
							<Button size="sm" onClick={() => openDiff(notification)}>
								Toggle diff
							</Button>
						)}
					</Group>
				}
				classNames={{
					itemBody: classes.itemBody,
				}}
			>
				<ActivityLogDescription notification={notification} />
				<Text size="xs" mt={4}>
					{moment(notification.created_at).fromNow()}
				</Text>
			</MantineTimeline.Item>
		);
	};

	if (onEndReached) {
		return (
			<Virtuoso
				data={notifications}
				itemContent={(_, notification) => notificationContent(notification)}
				components={{
					List: Stack,
				}}
				endReached={onEndReached}
			/>
		);
	}

	return (
		<MantineTimeline
			data-testid="activity-log-timeline"
			reverseActive
			bulletSize={24}
			lineWidth={2}
		>
			{notifications.map(notificationContent)}
			{/* The following is hardcoded because the backend
                does not currently return creation events. */}
			{entity && (
				<MantineTimeline.Item
					key={0}
					bullet={<Icon name="plus" />}
					title={`New ${lowerCase(getEntityDisplayType(entity?.entity_type))}`}
				>
					<Text color="text/secondary/default" size="sm">
						{`The ${lowerCase(getEntityDisplayType(entity?.entity_type))} was first created with identifier `}
						<Text variant="link" span inherit>
							{entity.id}
						</Text>
					</Text>
					<Text size="xs" mt={4}>
						{moment(entity.created_at).fromNow()}
					</Text>
				</MantineTimeline.Item>
			)}
		</MantineTimeline>
	);
}

export default Timeline;
