import type { ExistDict } from '@repo/api-codegen';
import { useApiGetTeamEmptyState } from '@repo/api-codegen';

export function useTeamEmptyState(
	teamId: string,
	sectionId: keyof ExistDict,
	options: { enabled?: boolean } = {}
) {
	return (
		useApiGetTeamEmptyState(
			{
				pathParams: {
					teamId,
				},
			},
			{
				...options,
			}
		).data?.exist_dict[sectionId] ?? { is_empty: false }
	);
}

export const useTeamEmptyStates = (
	teamId: string,
	options: { enabled?: boolean } = {}
) =>
	useApiGetTeamEmptyState(
		{
			pathParams: {
				teamId,
			},
		},
		{
			...options,
		}
	);
