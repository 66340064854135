import { showNotification } from '@mantine/notifications';
import { UserRole } from '@repo/common/enums/UserRole';
import { observer } from 'mobx-react-lite';
import type { Monitor } from '../../../api';
import { useAuthUser, useDeleteMonitor } from '../../../api';
import { useFeatureAccess } from '../../../api/hooks/workspace/useFeatureAccess';
import AddMonitorButton from '../../../components/AddMonitor/AddMonitorButton';
import { FeatureGuard } from '../../../components/FeatureGuard/FeatureGuard';
import MonitorList from '../../MonitorListPage/MonitorList';

interface IMonitorsTabProps {
	integrationId: string;
	tableId: string;
}

function MonitorsTab({ integrationId, tableId }: IMonitorsTabProps) {
	const { mutateAsync: deleteMonitor } = useDeleteMonitor({});
	const { isEditorOrAdminUser } = useAuthUser();

	const handleDeleteMonitors = async (selected: Monitor[]) => {
		await Promise.all(
			selected.map((monitor) => deleteMonitor({ id: monitor.id }))
		);

		showNotification({
			title: 'Monitors deleted',
			message: `${selected.length} monitors deleted successfully`,
		});
	};

	const { monitoringAccess } = useFeatureAccess();

	return (
		<FeatureGuard
			featureName="Monitors"
			description="Add monitors to your data, set thresholds, and be alerted as soon as incidents are detected."
			v1AllowedRoles={[UserRole.ADMIN, UserRole.EDITOR, UserRole.VIEWER]}
			v2Permission="Monitors.Read"
			isFeatureIncludedInPlan={!!monitoringAccess}
			isAlwaysIncludedFeature={true}
		>
			<MonitorList
				defaultRequiredSearchParams={{
					target_id: tableId,
				}}
				withAdditionalButtons={
					isEditorOrAdminUser && (
						<AddMonitorButton
							initialIntegration={integrationId}
							initialTable={tableId}
						/>
					)
				}
			/>
		</FeatureGuard>
	);
}

export default observer(MonitorsTab);
