import { makeAutoObservable } from 'mobx';
import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

type JobType = 'PULL' | 'PUSH';

type DatabuilderJobPollingState = {
	[integrationId: string]: {
		[jobType in JobType]?: boolean;
	};
};

class DatabuilderJobPollingStore {
	pollingState: DatabuilderJobPollingState = {};

	constructor() {
		makeAutoObservable(this);
	}

	setPollingState(integrationId: string, jobType: JobType, state: boolean) {
		this.pollingState[integrationId] = {
			...this.pollingState[integrationId],
			[jobType]: state,
		};
	}

	isPolling(integrationId: string, jobType: JobType) {
		return this.pollingState[integrationId]?.[jobType] || false;
	}
}

// DO NOT EXPORT THIS
const store = new DatabuilderJobPollingStore();

const DatabuilderJobPollingContext =
	createContext<DatabuilderJobPollingStore>(store);

export function DatabuilderJobPollingProvider({
	children,
}: {
	children: ReactNode;
}) {
	return (
		<DatabuilderJobPollingContext.Provider value={store}>
			{children}
		</DatabuilderJobPollingContext.Provider>
	);
}

export function useDatabuilderJobPollingContext() {
	const context = useContext(DatabuilderJobPollingContext);
	if (context === undefined) {
		throw new Error('usePollingContext must be used within a PollingProvider');
	}
	return context;
}
