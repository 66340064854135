import { PublicClientApplication } from '@azure/msal-browser';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
	auth: {
		// This can be public information.
		clientId: '18afcaea-dc23-45b1-a488-7991a987a207', // Secoda.co specific client ID
		redirectUri: `${window.location.origin}/auth/callback`,
		navigateToLoginRequestUrl: false,
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
	scopes: ['User.Read'],
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Export a function to initialize it
export const initializeMsal = async () => {
	await msalInstance.initialize();
	return msalInstance;
};
