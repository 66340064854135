import { EntityType } from '@repo/common/enums/entityType';
import { useMount } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useAuthUser, usePolicyAccess } from '../../api';
import {
	useSuspenseDatabase,
	useUpdateDatabase,
} from '../../api/hooks/database';
import Documentation from '../../components/Documentation';
import EntityDiscussions from '../../components/EntityDiscussions';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';
import EntityPageLayoutSkeleton from '../../components/EntityPageLayout/EntityPageLayoutSkeleton';
import EntityPolicyList from '../../components/EntityPolicyList/EntityPolicyList';
import type { OverviewPageTab } from '../../components/Overview/OverviewPageTabs';
import OverviewPageTabs from '../../components/Overview/OverviewPageTabs';
import { trackEvent } from '../../utils/analytics';
import { useSilentUrlUpdate } from '../../utils/hook/useSilentUrlUpdate';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import SchemasTab from './components/SchemasTab';
import TablesTab from './components/TablesTab';

const InnerDatabasePage = observer(({ id }: { id: string }) => {
	const { user, workspace } = useAuthUser();
	const hasPolicyAccess = usePolicyAccess();

	const { data: database } = useSuspenseDatabase({
		id,
	});

	useMount(() => {
		trackEvent('database/open', {}, user, workspace);
	});

	const { mutateAsync } = useUpdateDatabase({});

	const updateDatabase = useCallback(
		async (key: string, value: DjangoValueType, saveRemotely = true) => {
			if (saveRemotely && database) {
				await mutateAsync({
					data: {
						id: database.id,
						[key]: value,
					},
				});
				trackEvent('database/properties/update', {}, user, workspace!);
			}
		},
		[mutateAsync, database, user, workspace]
	);

	if (!database) {
		return <EntityPageLayoutSkeleton />;
	}

	const documentationFirst = (database.definition?.length || 0) > 2;

	const getDefaultTab = useCallback(() => {
		if (documentationFirst) {
			return 'documentation';
		}
		return 'schemas';
	}, [documentationFirst]);

	const { currentTab, setCurrentTab } = useSilentUrlUpdate(getDefaultTab());

	const handleTabChange = useCallback(
		(updatedTab: string) => {
			setCurrentTab(updatedTab);
		},
		[setCurrentTab]
	);

	const tabs: OverviewPageTab[] = useMemo(
		() => [
			{
				value: 'schemas',
				label: 'Schemas',
				component: <SchemasTab databaseId={database.id} />,
			},
			{
				value: 'tables',
				label: 'Tables',
				component: (
					<TablesTab
						databaseId={database.id}
						integrationId={database.integration}
					/>
				),
			},
			{
				value: 'discussions',
				label: 'Questions',
				component: <EntityDiscussions entity={database} />,
			},
			{
				value: 'documentation',
				label: 'Documentation',
				component: <Documentation entity={database} />,
			},
			{
				value: 'policies',
				label: 'Policies',
				condition: hasPolicyAccess,
				component: <EntityPolicyList entity={database} />,
			},
		],
		[database, hasPolicyAccess]
	);

	// Move documentation tab to the front if it should be first
	if (documentationFirst) {
		const docTabIndex = tabs.findIndex((t) => t.value === 'documentation');
		if (docTabIndex !== -1) {
			const [docTab] = tabs.splice(docTabIndex, 1);
			tabs.unshift(docTab);
		}
	}

	return (
		<EntityPageLayout
			key={database.id}
			name="Database"
			isReadOnlyTitle
			entity={database}
			icon={<EntityLogo size={32} entity={database} />}
			updateEntity={updateDatabase}
			withDescription
			withFrequentUsers
			withOwnerSelector
			withCollectionSelector
			withCustomPropertyEditors
			withTagSelector
			withEntityPopularity
			withRelatedResourceSelector
			{...getEntityPageSidebarProps(EntityType.database)}
		>
			<OverviewPageTabs
				key={database.id}
				entity={database}
				currentTab={currentTab || getDefaultTab()}
				tabs={tabs}
				onTabChange={handleTabChange}
			/>
		</EntityPageLayout>
	);
});

// By memoizing the inner component, we can avoid re-rendering the entire page on tab changes and only re-render the tab panel
export default function DatabasePage() {
	const id = useParamsIdSuffixUuid();

	return <InnerDatabasePage id={id} />;
}
