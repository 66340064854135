import { createStyles, Group, Stack } from '@mantine/core';
import type { Filter } from '@repo/api-codegen/api';
import { Button, Icon, Text, Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useCatalogList } from '../../../api/hooks/resourceCatalog';

import { openModal } from '../../../components/ModalManager';
import { getCountDisplay } from '../../PoliciesListPage/utils';
import { useCreatePolicyStore } from './context';
import FilterGroup from './FilterGroup';
import ResourceSelectionModalContent from './ResourceSelectionModal';

const useStyles = createStyles((theme) => ({
	addConditionButton: {
		border: `1px dashed ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.sm,
		width: 'fit-content',
		whiteSpace: 'nowrap',
	},
}));

const ResourceSelectionStack = observer(() => {
	const store = useCreatePolicyStore();
	const { classes } = useStyles();

	const { data } = useCatalogList({
		page: 1,
		filters: {
			filter: {
				operator: 'and',
				operands: store.resourceFilters.map(({ filter }) => filter),
			},
		},
	});

	const handleClick = useCallback(() => {
		openModal({
			modalId: 'resource-selection',
			variant: 'default',
			title: 'Resources',
			children: (
				<ResourceSelectionModalContent
					filter={{
						operator: 'and',
						operands: store.resourceFilters.map(({ filter }) => filter),
					}}
				/>
			),
		});
	}, [store.resourceFilters]);

	return (
		<Stack p={0} spacing="md">
			<Title size="md">Resources</Title>
			<Text size="sm">Filter resources to govern</Text>
			{store.resourceFilters.map(({ id, filter }) => (
				<FilterGroup
					key={id}
					condition={filter}
					onConditionChange={(new_filter) =>
						store.updateResourceFilter(id, new_filter)
					}
					onConditionDelete={() => store.removeResourceFilter(id)}
					isResource
				/>
			))}
			<Button
				variant="tertiary"
				className={classes.addConditionButton}
				onClick={() =>
					store.addResourceFilter({ operator: 'or', operands: [] } as Filter)
				}
			>
				<Text size="sm">Add Filter</Text>
				<Icon name="plus" />
			</Button>
			<Group w="100%" align="center" position="apart">
				<Group spacing="2xs">
					<Icon name="infoCircle" color="icon/secondary/default" />
					<Text color="text/secondary/default" size="xs">
						{getCountDisplay(data?.count)} resources found matching the filter
					</Text>
				</Group>
				<Button onClick={handleClick}>View resources</Button>
			</Group>
		</Stack>
	);
});

ResourceSelectionStack.displayName = 'ResourceSelectionStack';
export default ResourceSelectionStack;
