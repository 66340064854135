import { createStyles, Paper, Stack } from '@mantine/core';
import { useApiListPolicyTemplates } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { noop } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthUser } from '../../api';
import { closeAllModals } from '../../components/ModalManager';
import WidgetSelector from '../../components/WidgetSelector';
import type { WidgetData } from '../../components/WidgetSelector/types';

interface PolicyTemplateCardProps {
	data: WidgetData<any>;
}

const useStyles = createStyles((theme) => ({
	widgetItemsWrapper: {
		gap: theme.spacing.md,
	},
	widgetWrapper: {
		flex: `0 0 calc(50% - ${theme.spacing.md}/2)`,
	},
}));

function PolicyTemplateCard({ data }: PolicyTemplateCardProps) {
	const { user } = useAuthUser();
	const navigate = useNavigate();
	const location = useLocation();

	const handleClick = () => {
		closeAllModals();

		navigate('/policies/new', {
			state: {
				prev_url_path: location.pathname,
				policyTemplate: {
					...data.data,
					owners: [user.id],
					subscribers: [user.id],
				},
			},
		});
	};

	return (
		<Paper
			p="md"
			radius="md"
			withBorder
			onClick={handleClick}
			style={{ cursor: 'pointer' }}
		>
			<Stack spacing="xs">
				<Text size="sm" weight="bold">
					{data.title}
				</Text>
				<Text size="sm">{data.data.description}</Text>
			</Stack>
		</Paper>
	);
}

function PolicyTemplatesModal() {
	const { theme, classes } = useStyles();
	const { data: templates, isLoading } = useApiListPolicyTemplates({});

	const widgetData: WidgetData<any>[] =
		templates?.map((template) => ({
			id: template.id,
			data: template,
			title: template.name,
			description: template.description,
			group: template.frameworks?.[0] || 'Other',
		})) || [];

	return (
		<WidgetSelector
			data={widgetData}
			type="filter"
			onWidgetSelect={noop}
			widgetGroupProps={{
				classNames: {
					widgetItemsWrapper: classes.widgetItemsWrapper,
					widgetWrapper: classes.widgetWrapper,
				},
			}}
			scrollAreaHeight={theme.other.space[160]}
			cols={2}
			isLoading={isLoading}
			renderer={PolicyTemplateCard}
		/>
	);
}

export default PolicyTemplatesModal;
