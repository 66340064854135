import {
	createStyles,
	Group,
	ScrollArea,
	Skeleton,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { useApiGetTeamListUsers } from '@repo/api-codegen';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { Pagination } from '@repo/common/components/Pagination';
import { Text } from '@repo/foundations';
import { IconChevronRight } from '@tabler/icons-react';
import { useUnmount } from 'ahooks';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthUser } from '../../api';
import { getDisplayName } from '../../utils/userUtils';
import { closeModal } from '../ModalManager';
import { toTitleCase } from '../SearchListItem/helpers';
import { UserAvatar } from '../UserAvatar';
import { TEAM_MEMBERS_LIST_MODAL_ID } from './constants';

interface ITeamMembersListModal {
	teamId: string;
}

const useStyles = createStyles((theme) => ({
	userRow: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: theme.spacing.md,
		':hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
		cursor: 'pointer',
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		'&:last-of-type': {
			borderBottom: 'none',
		},
	},
}));

export function TeamMembersList({ teamId }: ITeamMembersListModal) {
	const { classes, theme } = useStyles();
	const { isAdminUser } = useAuthUser();
	const [page, setPage] = useState(1);

	const { isLoading, isError, data } = useApiGetTeamListUsers(
		{
			pathParams: {
				teamId: teamId,
			},
			queryParams: {
				page,
			},
		},
		{
			select: ({ count, results, total_pages }) => ({
				userCount: count,
				users: results,
				totalPages: total_pages,
			}),
			enabled: !isEmpty(teamId),
		}
	);

	useUnmount(() => {
		closeModal(TEAM_MEMBERS_LIST_MODAL_ID);
	});

	if (isLoading || !data) {
		return <Skeleton height={32} width="75%" />;
	}

	if (isError) {
		return null;
	}

	const { users, totalPages } = data;

	const handleClick = () => {
		closeModal(TEAM_MEMBERS_LIST_MODAL_ID);
	};

	const handlePageChange = (newPage: number) => {
		setPage(newPage);
	};

	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore poorly typed component by Mantine
		<ScrollArea.Autosize mah={400} offsetScrollbars>
			<Stack spacing={0} pb={12}>
				{users.map((user) => (
					<UnstyledButton
						key={user.id}
						component={Link}
						to={`/user/${user.id}`}
						py={8}
						className={classes.userRow}
						onClick={handleClick}
					>
						<Group noWrap key={user.id} w={240}>
							<UserAvatar user={user} size="md" enableLink />
							<Stack spacing={0}>
								<Text size="sm" fw={500} lineClamp={1}>
									{getDisplayName(user)}
								</Text>
								<Text size="xs" color="text/secondary/default" lineClamp={1}>
									{user.email}
								</Text>
							</Stack>
						</Group>
						{isAdminUser && <Text size="sm">{toTitleCase(user.role)}</Text>}
						<IconWrapper>
							<IconChevronRight
								size={16}
								color={theme.other.getColor('fill/secondary/active')}
							/>
						</IconWrapper>
					</UnstyledButton>
				))}
				<Pagination
					page={page}
					total={totalPages || 1}
					onChange={handlePageChange}
				/>
			</Stack>
		</ScrollArea.Autosize>
	);
}
