import { Box, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
	wrapper: {},
	changed: {
		backgroundColor: theme.other.getColor('surface/emphasis/default'),
		borderRadius: theme.radius.sm,
	},
}));

export function PropertyWrapper({
	children,
	hasChanged,
}: {
	children: React.ReactNode;
	hasChanged: boolean;
}) {
	const { classes, cx } = useStyles();

	return (
		<Box className={cx(classes.wrapper, { [classes.changed]: hasChanged })}>
			{children}
		</Box>
	);
}
