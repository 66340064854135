import type { ActivityLogOut } from '@repo/api-codegen';
import { useMemo } from 'react';
import { DateRenderer } from '../CatalogView/TableView/DateRenderer';
import type { ExtendedDataTableColumn } from '../TableV2/types';
import {
	ActivityLogEventBlocksRender,
	ActivityLogPerformerRender,
	ActivityLogResourceRender,
} from './render';

export function useColumns(): ExtendedDataTableColumn<ActivityLogOut>[] {
	const columns = useMemo(
		() => [
			{
				accessor: 'updated_at',
				title: 'Date',
				render: (record: ActivityLogOut) => (
					<DateRenderer value={record?.logged_at} />
				),
			},
			{
				accessor: 'resource_id',
				title: 'Resource',
				navigate: false,
				render: (record: ActivityLogOut) => (
					<ActivityLogResourceRender record={record} />
				),
			},
			{
				accessor: 'event_blocks',
				title: 'Event',
				render: (record: ActivityLogOut) => (
					<ActivityLogEventBlocksRender blocks={record.event_blocks} />
				),
			},
			{
				accessor: 'performer_id',
				title: 'Initiated by',
				render: (record: ActivityLogOut) => (
					<ActivityLogPerformerRender record={record} />
				),
			},
		],
		[]
	);

	return columns;
}
