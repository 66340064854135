import { Box, createStyles, Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button } from '@repo/foundations';
import { queryClient, useAuthUser } from '../../../api';
import { policiesQueryKeyFactory } from '../../../api/hooks/policy';
import { useCreatePolicy, useUpdatePolicy } from '../../PoliciesListPage/hooks';
import { useCreatePolicyStore } from './context';

const useStyles = createStyles((theme) => ({
	stickyFooter: {
		position: 'fixed' as const,
		bottom: 0,
		left: 0,
		width: '100%',
		backgroundColor: `${theme.other.getColor('surface/primary/default')}`,
		borderTop: `${theme.other.borderWidth.sm}px solid ${theme.other.getColor('border/primary/default')}`,
		padding: `${theme.spacing.md} calc((100vw - ${theme.other.width.lg}px)/2) ${theme.spacing.md} 0`,
	},
	stickyPlaceholder: {
		height: theme.spacing.md,
	},
}));

interface CreatePolicyButtonProps {
	exitPage: () => void;
	editMode?: boolean;
	policyId?: string;
}

function CreatePolicyButton({
	exitPage,
	editMode = false,
	policyId,
}: CreatePolicyButtonProps) {
	const store = useCreatePolicyStore();
	const { classes } = useStyles();
	const { mutateAsync: createPolicy } = useCreatePolicy({});
	const { mutateAsync: updatePolicy } = useUpdatePolicy({});
	const { user } = useAuthUser();

	const handleCreate = async () => {
		if (!store.isFormValid) {
			return;
		}

		try {
			if (editMode && policyId) {
				await updatePolicy({
					pathParams: { policyId },
					body: store.getData(),
				});
				showNotification({
					title: 'Success',
					message: 'Policy updated successfully.',
					color: 'green',
				});
			} else {
				await createPolicy({
					body: {
						...store.getData(),
						created_by_id: user?.id,
					},
				});
				showNotification({
					title: 'Success',
					message: 'New policy created successfully.',
					color: 'green',
				});
			}
			queryClient.invalidateQueries({
				queryKey: policiesQueryKeyFactory.all(),
			});
			exitPage();
		} catch {
			showNotification({
				message: `Failed to ${editMode ? 'update' : 'create'} policy`,
				color: 'red',
			});
		}
	};

	return (
		<Box className={editMode ? classes.stickyPlaceholder : undefined}>
			<Group
				position="right"
				className={editMode ? classes.stickyFooter : undefined}
			>
				<Button variant="primary" onClick={handleCreate}>
					{editMode ? 'Update policy' : 'Create policy'}
				</Button>
			</Group>
		</Box>
	);
}

export default CreatePolicyButton;
