import { Box, createStyles, Group, Stack } from '@mantine/core';
import type { ColorNames } from '@repo/theme/utils';
import { Button, IconButton, type ButtonProps } from '../Buttons';
import { Icon, type IconNames } from '../Icon';
import { Text } from '../Text';

interface BannerStyleProperties {
	icon: IconNames;
	iconColor?: ColorNames;
	iconFillColor?: ColorNames;
	surfaceColor: ColorNames;
	textColor: ColorNames;
}

// eslint-disable-next-line react-refresh/only-export-components
export const ALL_BANNER_TONES = [
	'neutral',
	'critical',
	'info',
	'success',
	'warning',
] as const;

export type BannerTones = (typeof ALL_BANNER_TONES)[number];

const BannerStyleMap: Record<BannerTones, BannerStyleProperties> = {
	critical: {
		icon: 'alertCircleFilled',
		iconFillColor: 'icon/critical/default',
		surfaceColor: 'surface/critical/default',
		textColor: 'text/critical/default',
	},
	info: {
		icon: 'infoCircleFilled',
		iconColor: 'icon/info/default',
		surfaceColor: 'surface/info/default',
		textColor: 'text/info/default',
	},
	neutral: {
		icon: 'infoCircleFilled',
		iconColor: 'icon/info/default',
		surfaceColor: 'surface/info/default',
		textColor: 'text/info/default',
	},
	success: {
		icon: 'circleCheckFilled',
		iconFillColor: 'icon/success/default',
		surfaceColor: 'surface/success/default',
		textColor: 'text/success/default',
	},
	warning: {
		icon: 'alertTriangleFilled',
		iconFillColor: 'icon/warning/default',
		surfaceColor: 'surface/warning/default',
		textColor: 'text/warning/default',
	},
};

export interface BannerProps {
	message: React.ReactNode;
	tone?: BannerTones;
	title?: string;
	onDismiss?: () => void;
	actions?: ButtonProps[];
	inCard?: boolean;
}

const useStyles = createStyles(
	(theme, { tone, condensed }: { tone: BannerTones; condensed: boolean }) => ({
		wrapper: {
			gap: condensed ? theme.spacing['2xs'] : theme.spacing.sm,
			padding: condensed
				? `${theme.spacing.xs} ${theme.spacing['2xl']} ${theme.spacing.xs} ${theme.spacing.xs}`
				: `${theme.spacing.md} ${theme.spacing['4xl']} ${theme.spacing.md} ${theme.spacing.md}`,
			alignItems: 'flex-start',
			backgroundColor: theme.other.getColor(BannerStyleMap[tone].surfaceColor),
			borderRadius: condensed ? theme.radius.sm : theme.radius.md,
			position: 'relative',
			flexWrap: 'nowrap',
		},
		actionsWrapper: {
			position: 'absolute',
			top: condensed ? theme.spacing['3xs'] : theme.spacing.sm,
			right: condensed ? theme.spacing['3xs'] : theme.spacing.sm,
			gap: condensed ? theme.spacing.xs : theme.spacing.sm,
		},
	})
);

export function Banner({
	tone = 'info',
	message,
	title,
	onDismiss,
	actions = [],
	inCard = false,
}: BannerProps) {
	const condensed = inCard;

	const { classes } = useStyles({ tone, condensed: inCard });

	return (
		<Group className={classes.wrapper}>
			<Icon
				name={BannerStyleMap[tone].icon}
				color={BannerStyleMap[tone].iconColor}
				fillColor={BannerStyleMap[tone].iconFillColor}
			/>
			<Stack spacing={condensed ? 'xs' : 'sm'}>
				<Stack spacing="3xs">
					{title && (
						<Text
							size="sm"
							weight="semibold"
							color={BannerStyleMap[tone].textColor}
						>
							{title}
						</Text>
					)}
					<Text size="sm" color={BannerStyleMap[tone].textColor}>
						{message}
					</Text>
				</Stack>
				{actions.length > 0 && (
					<Group spacing="xs">
						{actions.map((action, idx) => (
							// eslint-disable-next-line react/no-array-index-key
							<Button key={idx} {...action} />
						))}
					</Group>
				)}
			</Stack>
			{onDismiss && (
				<Box className={classes.actionsWrapper}>
					<IconButton variant="tertiary" iconName="x" onClick={onDismiss} />
				</Box>
			)}
		</Group>
	);
}
