import { Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { integrationList } from '@repo/common/constants/integration/integrations';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Text } from '@repo/foundations';
import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';
import {
	integrationsQueryKeyFactory,
	useAuthUser,
	useCreateIntegration,
	useIntegrationList,
} from '../../../api';
import { useMarketplaceIntegrationSpecList } from '../../../api/hooks/marketplace';
import { usePublicEnv } from '../../../api/hooks/utils/usePublicEnv';
import { useLimits } from '../../../api/hooks/workspace/useLimits';
import { usePlan } from '../../../hooks/usePlans';
import type { IntegrationSpec } from '../../../interfaces/IntegrationSpec';
import { IS_LOCAL_OR_DEVELOPMENT } from '../../../utils/envs';
import { IntegrationSpecCard } from '../../IntegrationSpecCard/IntegrationSpecCard';
import {
	closeAllModals,
	closeModal,
	openConfirmModal,
} from '../../ModalManager';
import { PricingDetails } from '../../PriceIncreaseModals/PricingDetails';
import { toTitleCase } from '../../SearchListItem/helpers';
import WidgetSelector from '../../WidgetSelector';
import type { WidgetData } from '../../WidgetSelector/types';
import { useStyles } from './AddIntegrationModal.styles';
import { NEW_INTEGRATION_MODAL_ID } from './constants';

function AddIntegrationModal({
	integrationTypes,
	title = 'Connect integration',
}: {
	integrationTypes?: string[];
	title?: string;
}) {
	const { theme, classes } = useStyles();

	const navigate = useNavigate();
	const { workspace } = useAuthUser();
	const { isPayingCustomer, trial } = usePlan();
	const { usedIntegrationSeats, trialIntegrationsLimit, integrationSeats } =
		useLimits();
	const { data: publicEnv } = usePublicEnv();

	const isMarketplaceEnabled =
		IS_LOCAL_OR_DEVELOPMENT || Boolean(workspace.marketplace_enabled);

	const { data: integrations } = useIntegrationList({});

	const { data: marketplaceIntegrationSpecs } =
		useMarketplaceIntegrationSpecList({
			filters: { browsing: true },
			options: {
				enabled: isMarketplaceEnabled,
				select: (res) => res.results,
			},
		});

	const builtinIntegrationSpecsData = integrationList
		.filter(
			(integration) =>
				(!integration.hidden &&
					integration.secodaSupported &&
					(publicEnv?.SLACK_ENABLED || integration.type !== 'slack')) ||
				integration.type === 'marketplace'
		)
		.filter(
			(integration) => integrationTypes?.includes(integration.type) ?? true
		)
		.map((integration) => ({
			group: integration.group,
			title: integration.name,
			data: { type: 'builtin', value: integration } as IntegrationSpec,
		}));

	const marketplaceIntegrationSpecsData = (marketplaceIntegrationSpecs || [])
		.filter((spec) => spec.versions.length > 0)
		.map((spec) => ({
			group: toTitleCase(spec.versions[0].category.toLowerCase()).replace(
				'_',
				' '
			),
			title: spec.versions[0].name,
			data: { type: 'marketplace', value: spec } as IntegrationSpec,
			key: spec.id,
		}));

	const data = [
		...builtinIntegrationSpecsData,
		...marketplaceIntegrationSpecsData,
	];

	const { mutateAsync: createIntegration } = useCreateIntegration({
		invalidationKeys: [integrationsQueryKeyFactory.allLists()],
	});

	const { boot, show } = useIntercom();

	const openIntercom = useCallback(async () => {
		await boot();
		show();
	}, [boot, show]);

	const createCustomIntegration = async () => {
		// In production, trial users and non-paying customers can't create custom
		// integrations.
		if (!IS_LOCAL_OR_DEVELOPMENT && (!isPayingCustomer || trial)) {
			showNotification({
				title: 'Custom integrations are not available in your plan.',
				message:
					'Please upgrade to a paying plan to create custom integrations.',
				color: 'red',
			});
			return;
		}
		const integration = await createIntegration({
			data: {
				name: 'Untitled',
				type: 'custom',
				credentials: {},
			},
		});

		navigate(`/integrations/${integration.id}/`);
	};

	const handleIntegrationSelect = async (
		widgetData: WidgetData<IntegrationSpec>
	) => {
		const { type, value } = widgetData.data;

		const handleNavigation = async () => {
			closeModal(NEW_INTEGRATION_MODAL_ID);

			if (type === 'builtin') {
				if (value.type === 'custom') {
					await createCustomIntegration();
				} else if (value.type === 'marketplace') {
					navigate('/marketplace-integration/new');
				} else {
					navigate(`/integrations/new?type=${value.type}`);
				}
			} else {
				navigate(`/integrations/new?selectedMarketplaceSpec=${value.id}`);
			}
		};

		// Customers can add as many integrations as they want of the same type
		const seatLimitReached =
			usedIntegrationSeats >= integrationSeats &&
			!integrations?.results.some(
				(integration) => type === 'builtin' && integration.type === value?.type
			);

		// Trials can only add one integration of the same type
		const trialLimitReached =
			trial &&
			trialIntegrationsLimit &&
			usedIntegrationSeats >= trialIntegrationsLimit;

		// Extensions such as "Slack", "Linear", "Github" are not subject to the seat limit
		const isExtension = type === 'builtin' && value?.isExtension;

		const requireUpgrade =
			!isExtension && (seatLimitReached || trialLimitReached);

		if (requireUpgrade) {
			if (trial) {
				openConfirmModal({
					title: 'Trial limit reached',
					children: (
						<Stack>
							<Text>
								You&apos;ve reached the integration limit for your trial.
								Contact support to upgrade to a paid plan.
							</Text>
						</Stack>
					),
					labels: {
						cancel: 'Cancel',
						confirm: 'Upgrade',
					},
					onConfirm: () => {
						closeAllModals();
						openIntercom();
					},
					onCancel: closeAllModals,
				});
			} else {
				openConfirmModal({
					title: 'Plan limit reached',
					children: (
						<Stack>
							<PricingDetails
								type="integrations"
								nominalCurrentCount={usedIntegrationSeats}
								nominalChange={1}
								hidden={false}
							/>
							<Text size="xs">
								Your account will be charged for 1 additional Integration seat
								after the configuration wizard is complete
							</Text>
						</Stack>
					),
					labels: {
						cancel: 'Cancel',
						confirm: 'Upgrade',
					},
					onConfirm: handleNavigation,
					onCancel: closeAllModals,
				});
			}
			return;
		} else {
			await handleNavigation();
		}
	};

	return (
		<WidgetSelector
			title={title}
			data={data}
			type="filter"
			onWidgetSelect={handleIntegrationSelect}
			widgetGroupProps={{
				classNames: {
					widgetItemsWrapper: classes.widgetItemsWrapper,
					widgetWrapper: classes.widgetWrapper,
				},
			}}
			scrollAreaHeight={theme.other.space[160]}
			cols={3}
			renderer={IntegrationSpecCard}
		/>
	);
}

export default AddIntegrationModal;
