import { ActionIcon, createStyles, UnstyledButton } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, Text } from '@repo/foundations';
import { noop } from 'lodash-es';
import type { MouseEventHandler } from 'react';
import { useLocation } from 'react-router';
import type { IEmbeddedPrompt } from '../../api';
import { useAuthUser, useDeleteAIEmbedded } from '../../api';
import { aiEmbeddedQueryKeyFactory } from '../../api/hooks/ai/constants';
import { trackEvent } from '../../utils/analytics';
import { openConfirmModal } from '../ModalManager';
import { useAIAssistantContext } from './context';
import { AIAssistantMode } from './types';
import { sanitizePromptMessage } from './utils';

interface IPromptButtonProps {
	chat: IEmbeddedPrompt;
}

const useStyles = createStyles((theme) => ({
	button: {
		width: '100%',
		height: theme.other.space[10],
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
		gap: theme.spacing.xs,
		borderRadius: theme.radius.sm,
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
	},
	buttonActive: {
		backgroundColor: theme.other.getColor('surface/primary/active'),
	},
}));

function ChatHistoryItem({ chat }: IPromptButtonProps) {
	const { classes, cx } = useStyles();

	const { hovered, ref } = useHover<HTMLButtonElement>();
	const location = useLocation();

	const { user, workspace } = useAuthUser();
	const { mode, setId, setIsHistoryOpen, id } = useAIAssistantContext();

	const navigate = useNavigate();

	const isActive = id === chat.id;

	const { mutate: deleteChat } = useDeleteAIEmbedded({
		invalidationKeys: [aiEmbeddedQueryKeyFactory.allLists()],
	});

	const handleSelectChat = () => {
		trackEvent(
			'ai/history/select',
			{
				prompt_id: chat.id,
				prompt: chat.prompt ?? '',
				mode,
				url: location.pathname,
			},
			user,
			workspace
		);

		if (mode === AIAssistantMode.SIDEBAR) {
			setId(chat.id);
			setIsHistoryOpen(false);
		} else {
			navigate(`/ai/${chat.id}`);
		}
	};

	const handleConfirmDelete = () => {
		trackEvent(
			'ai/history/delete',
			{
				prompt_id: chat.id,
				prompt: chat.prompt ?? '',
				mode,
				url: location.pathname,
			},
			user,
			workspace
		);
		deleteChat({ id: chat.id });
	};

	const handleDelete: MouseEventHandler<HTMLButtonElement> = (event) => {
		event.stopPropagation();
		openConfirmModal({
			title: 'Delete chat?',
			centered: true,
			children: <Text size="sm">You can't undo this action.</Text>,
			labels: { confirm: 'Delete', cancel: 'Cancel' },
			confirmProps: { variant: 'primary', tone: 'critical' },
			onCancel: noop,
			onConfirm: handleConfirmDelete,
		});
	};

	return (
		<UnstyledButton
			ref={ref}
			className={cx(classes.button, { [classes.buttonActive]: isActive })}
			onClick={handleSelectChat}
		>
			<Text size="sm" weight="semibold" truncate>
				{sanitizePromptMessage(chat.title ?? chat.prompt)}
			</Text>
			{hovered && (
				<ActionIcon size="sm" onClick={handleDelete}>
					<Icon name="trash" color="icon/primary/default" />
				</ActionIcon>
			)}
		</UnstyledButton>
	);
}

export default ChatHistoryItem;
