import { Group } from '@mantine/core';
import type { GithubPullRequestOut } from '@repo/api-codegen';
import type { BadgeVariant } from '@repo/foundations';
import { Badge, Text } from '@repo/foundations';
import { useMemo } from 'react';
import { DateRenderer } from '../../CatalogView/TableView/DateRenderer';
import type { ExtendedDataTableColumn } from '../../TableV2/types';

export function useColumns(): ExtendedDataTableColumn<GithubPullRequestOut>[] {
	const columns = useMemo(
		() => [
			{
				accessor: 'repo_name',
				title: 'Repository',
				render: (record: GithubPullRequestOut) => (
					<Text size="sm" truncate>
						{record.repo_name}
					</Text>
				),
			},
			{
				accessor: 'pull_request_title',
				title: 'Pull request',
				render: (record: GithubPullRequestOut) => (
					<Group spacing="3xs" noWrap>
						<Badge>{`#${record.pull_request_number}`}</Badge>
						<Text size="sm" truncate>
							{record.pull_request_title}
						</Text>
					</Group>
				),
			},
			{
				accessor: 'pull_request_state',
				title: 'State',
				render: (record: GithubPullRequestOut) => {
					let variant: BadgeVariant = 'default';
					let state = 'Open';

					if (record.pull_request_merged_at) {
						variant = 'ai';
						state = 'Merged';
					} else if (record.pull_request_state === 'open') {
						variant = 'success';
						state = 'Open';
					} else if (record.pull_request_state === 'closed') {
						variant = 'critical';
						state = 'Closed';
					}

					return <Badge variant={variant}>{state}</Badge>;
				},
			},
			{
				accessor: 'changed_dbt_models_count',
				title: 'Changed dbt models',
				render: (record: GithubPullRequestOut) => (
					<Text size="sm">{record.changed_dbt_models_count}</Text>
				),
			},
			{
				accessor: 'downstream_entities_count',
				title: 'Impacted downstream entities',
				render: (record: GithubPullRequestOut) => (
					<Text size="sm">{record.downstream_entities_count}</Text>
				),
			},
			{
				accessor: 'pull_request_updated_at',
				title: 'Last updated',
				render: (record: GithubPullRequestOut) => (
					<DateRenderer value={record.pull_request_updated_at} />
				),
			},
		],
		[]
	);

	return columns;
}
