import { Group, createStyles } from '@mantine/core';
import type {
	ActivityLogEventBlock,
	ActivityLogOut,
	LiteUserOut,
} from '@repo/api-codegen';
import {
	useApiGetIamRole,
	useApiGetPolicy,
	useApiGetTeam,
	useApiListApiKeys,
	useGetAccessRequest,
	useGetCustomProperty,
} from '@repo/api-codegen';
import { UserAvatar } from '@repo/common/components';
import { EventBlock } from '@repo/common/components/ActivityLog/EventBlock';
import { getEntityTypeIcon } from '@repo/common/utils/entityDisplayUtils';
import { Button, Icon, Text } from '@repo/foundations';
import { IconSizeMap } from '@repo/foundations/components/Icon/Icon';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	useMetric,
	useTag,
	useTagGroup,
	useUser,
	useUserGroup,
	useWorkspace,
} from '../../api';
import { useAutomation } from '../../api/hooks/automation';
import { useIntegrationPrefetched } from '../../api/hooks/integration';
import { useIncident, useMonitor } from '../../api/hooks/monitoring';
import { useReport } from '../../api/hooks/report';
import { useSecodaEntity } from '../../api/hooks/secodaEntity';
import { buildResourceUrl } from '../../utils/navigationUtils';
import IntegrationLogo from '../IntegrationLogo';
import { SecodaEntityIcon } from '../SecodaEntity';

const useStyles = createStyles((theme) => ({
	group: {
		padding: `0 ${theme.spacing.sm}`,
	},
}));

export function ActivityLogNoResourceRender() {
	const { classes } = useStyles();
	return (
		<Group spacing="2xs" noWrap className={classes.group}>
			<Text size="sm" color="text/primary/default">
				None
			</Text>
		</Group>
	);
}

function ResourceRender({
	icon,
	displayName,
	url,
}: {
	icon: ReactNode;
	displayName: ReactNode;
	url?: string;
}) {
	const navigate = useNavigate();
	const { classes } = useStyles();
	const children = (
		<>
			{icon}
			<Text size="sm" color="text/primary/default">
				{displayName}
			</Text>
		</>
	);

	if (url) {
		return (
			<Button variant="tertiary" onClick={() => navigate(url)}>
				{children}
			</Button>
		);
	} else {
		return (
			<Group spacing="2xs" noWrap className={classes.group}>
				{children}
			</Group>
		);
	}
}

function IntegrationRender({ record }: { record: ActivityLogOut }) {
	const { data: integration } = useIntegrationPrefetched({
		id: record.resource_id ?? '',
		options: {
			enabled: record.resource_type === 'INTEGRATION' && !!record.resource_id,
		},
	});

	if (!integration) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			url={`/integrations/${integration.id}`}
			icon={
				<IntegrationLogo
					integrationId={integration.id}
					integrationType={integration.type}
					width={IconSizeMap.md}
					height={IconSizeMap.md}
				/>
			}
			displayName={integration.name}
		/>
	);
}

function ApiKeyRender({ record }: { record: ActivityLogOut }) {
	const { data: apiKeys } = useApiListApiKeys(
		{},
		{
			enabled: record.resource_type === 'API_KEY' && !!record.resource_id,
		}
	);
	const apiKey = apiKeys?.results.find((key) => key.id === record.resource_id);

	if (!apiKey) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			icon={<Icon name="key" color="icon/primary/default" />}
			displayName={apiKey.key}
		/>
	);
}

function AutomationRender({ record }: { record: ActivityLogOut }) {
	const { data: automation } = useAutomation({
		id: record.resource_id ?? '',
		options: {
			enabled: record.resource_type === 'AUTOMATION' && !!record.resource_id,
		},
	});

	return (
		<ResourceRender
			url={`/automation/${automation?.id}`}
			icon={<Icon name="bolt" color="icon/primary/default" />}
			displayName={automation?.title ?? 'Untitled'}
		/>
	);
}

function CommentRender({ record }: { record: ActivityLogOut }) {
	const { data: dataAccessRequestForComment } = useGetAccessRequest(
		{
			pathParams: {
				requestId: record.related_resource_ids[0] ?? '',
			},
		},
		{
			enabled:
				record.resource_type === 'COMMENT' && !!record.related_resource_ids[0],
		}
	);

	const { data: incidentForComment } = useIncident({
		id: record.related_resource_ids[0] ?? '',
		options: {
			enabled:
				record.resource_type === 'COMMENT' && !!record.related_resource_ids[0],
		},
	});

	const { data: secodaEntityForComment } = useSecodaEntity({
		id: record.related_resource_ids[0] ?? '',
		options: {
			enabled:
				record.resource_type === 'COMMENT' && !!record.related_resource_ids[0],
		},
	});

	let displayName = 'Comment';
	let icon: ReactNode | null = null;
	let url: string | undefined = undefined;

	if (dataAccessRequestForComment) {
		displayName = `${dataAccessRequestForComment.integration_name} access`;
		icon = <Icon name="squareRoundedCheck" color="icon/primary/default" />;
		url = `/requests/${dataAccessRequestForComment.id}`;
	}

	if (incidentForComment) {
		displayName = incidentForComment.name ?? 'Untitled';
		icon = <Icon name="activity" color="icon/primary/default" />;
		url = `/incident/${incidentForComment.id}`;
	}

	if (secodaEntityForComment) {
		displayName = secodaEntityForComment.title ?? 'Untitled';
		icon = getEntityTypeIcon(secodaEntityForComment.entity_type);
		url = buildResourceUrl(secodaEntityForComment);
	}

	return <ResourceRender icon={icon} displayName={displayName} url={url} />;
}

function CustomPropertyRender({ record }: { record: ActivityLogOut }) {
	const { data: customProperty } = useGetCustomProperty(
		{
			pathParams: {
				customPropertyId: record.resource_id ?? '',
			},
		},
		{
			enabled:
				record.resource_type === 'CUSTOM_PROPERTY' && !!record.resource_id,
		}
	);

	const { data: secodaEntityForCustomProperty } = useSecodaEntity({
		id: record.related_resource_ids[0] ?? '',
		options: {
			enabled:
				record.resource_type === 'CUSTOM_PROPERTY' &&
				!!record.related_resource_ids[0],
		},
	});

	let displayName: string | undefined = customProperty?.name;
	let icon: ReactNode = <Icon name="tag" color="icon/primary/default" />;

	if (
		secodaEntityForCustomProperty &&
		record.activity_type === 'UPDATE_ENTITY_CUSTOM_PROPERTY'
	) {
		displayName = secodaEntityForCustomProperty.title ?? 'Untitled';
		icon = getEntityTypeIcon(secodaEntityForCustomProperty.entity_type);
	}

	return <ResourceRender icon={icon} displayName={displayName} />;
}

function DataAccessRequestRender({ record }: { record: ActivityLogOut }) {
	const { data: dataAccessRequest } = useGetAccessRequest(
		{
			pathParams: {
				requestId: record.resource_id ?? '',
			},
		},
		{
			enabled:
				record.resource_type === 'DATA_ACCESS_REQUEST' && !!record.resource_id,
		}
	);

	return (
		<ResourceRender
			url={`/requests/${dataAccessRequest?.id}`}
			icon={<Icon name="squareRoundedCheck" color="icon/primary/default" />}
			displayName={
				dataAccessRequest
					? `${dataAccessRequest.integration_name} access`
					: 'Access request'
			}
		/>
	);
}

function IamRoleRender({ record }: { record: ActivityLogOut }) {
	const { data: IAMRole } = useApiGetIamRole(
		{
			pathParams: {
				iamRoleId: record.resource_id ?? '',
			},
		},
		{
			enabled: record.resource_type === 'IAM_ROLE' && !!record.resource_id,
		}
	);

	if (!IAMRole) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			icon={<Icon name="user" color="icon/primary/default" />}
			displayName={IAMRole.name}
		/>
	);
}

function IncidentRender({ record }: { record: ActivityLogOut }) {
	const { data: incident } = useIncident({
		id: record.resource_id ?? '',
		options: {
			enabled: record.resource_type === 'INCIDENT' && !!record.resource_id,
		},
	});

	if (!incident) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			url={`/incident/${incident.id}`}
			icon={<Icon name="activity" color="icon/primary/default" />}
			displayName={incident.name}
		/>
	);
}

function MetricRender({ record }: { record: ActivityLogOut }) {
	const { data: metric } = useMetric({
		id: record.resource_id ?? '',
		options: {
			enabled: record.resource_type === 'METRIC' && !!record.resource_id,
		},
	});

	return (
		<ResourceRender
			icon={<Icon name="chartBar" color="icon/primary/default" />}
			displayName={metric?.title ?? 'Untitled'}
		/>
	);
}

function MonitorRender({ record }: { record: ActivityLogOut }) {
	const { data: monitor } = useMonitor({
		id: record.resource_id ?? '',
		options: {
			enabled: record.resource_type === 'MONITOR' && !!record.resource_id,
		},
	});

	if (!monitor) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			url={`/monitor/${monitor.id}`}
			icon={<Icon name="activity" color="icon/primary/default" />}
			displayName={monitor.name}
		/>
	);
}

function PolicyRender({ record }: { record: ActivityLogOut }) {
	const { data: policy } = useApiGetPolicy(
		{
			pathParams: {
				policyId: record.resource_id ?? '',
			},
		},
		{
			enabled: record.resource_type === 'POLICY' && !!record.resource_id,
		}
	);

	if (!policy) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			icon={<Icon name="checklist" color="icon/primary/default" />}
			displayName={policy.name}
		/>
	);
}

function ReportRender({ record }: { record: ActivityLogOut }) {
	const { data: report } = useReport({
		reportId: record.resource_id ?? '',
		options: {
			enabled: record.resource_type === 'REPORT' && !!record.resource_id,
		},
	});

	if (!report) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			url={`/analytics/${report.id}`}
			icon={<Icon name="chartBar" color="icon/primary/default" />}
			displayName={report.name}
		/>
	);
}

function EntityRender({ record }: { record: ActivityLogOut }) {
	const { data: secodaEntity } = useSecodaEntity({
		id: record.resource_id ?? '',
		options: {
			enabled: record.resource_type === 'SECODA_ENTITY' && !!record.resource_id,
		},
	});

	if (!secodaEntity) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			url={buildResourceUrl(secodaEntity)}
			icon={<SecodaEntityIcon entity={secodaEntity} size={20} />}
			displayName={secodaEntity.title}
		/>
	);
}

function TagRender({ record }: { record: ActivityLogOut }) {
	const { data: tag } = useTag({
		id: record.resource_id ?? '',
		options: {
			enabled: record.resource_type === 'TAG' && !!record.resource_id,
		},
	});

	if (!tag) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			icon={<Icon name="tag" color="icon/primary/default" />}
			displayName={tag.name}
		/>
	);
}

function TagGroupRender({ record }: { record: ActivityLogOut }) {
	const { data: tagGroup } = useTagGroup({
		id: record.resource_id ?? '',
		options: {
			enabled: record.resource_type === 'TAG_GROUP' && !!record.resource_id,
		},
	});

	if (!tagGroup) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			icon={<Icon name="tag" color="icon/primary/default" />}
			displayName={tagGroup.name}
		/>
	);
}

function TeamRender({ record }: { record: ActivityLogOut }) {
	const { data: team } = useApiGetTeam(
		{
			pathParams: {
				teamId: record.resource_id ?? '',
			},
		},
		{
			enabled: record.resource_type === 'TEAM' && !!record.resource_id,
		}
	);

	if (!team) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			url="/teams"
			icon={<Icon name="users" color="icon/primary/default" />}
			displayName={team.name}
		/>
	);
}

function UserRender({ record }: { record: ActivityLogOut }) {
	const { data: user } = useUser({
		id: record.resource_id ?? '',
		options: {
			enabled: record.resource_type === 'USER' && !!record.resource_id,
		},
	});

	if (!user) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			url={`/user/${user.id}`}
			icon={<Icon name="users" color="icon/primary/default" />}
			displayName={user.display_name}
		/>
	);
}

function UserGroupRender({ record }: { record: ActivityLogOut }) {
	const { data: userGroup } = useUserGroup({
		id: record.resource_id ?? '',
		options: {
			enabled: record.resource_type === 'USER_GROUP' && !!record.resource_id,
		},
	});

	if (!userGroup) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			icon={<Icon name="users" color="icon/primary/default" />}
			displayName={userGroup.name}
		/>
	);
}
function WorkspaceRender({ record }: { record: ActivityLogOut }) {
	const { workspace } = useWorkspace();

	if (!workspace || workspace.id !== record.resource_id) {
		return <ActivityLogNoResourceRender />;
	}

	return (
		<ResourceRender
			icon={<Icon name="building" color="icon/primary/default" />}
			displayName={workspace.name}
		/>
	);
}

export function ActivityLogResourceRender({
	record,
}: {
	record: ActivityLogOut;
}) {
	const { workspace } = useWorkspace();

	if (!record.resource_type || !record.resource_id) {
		return (
			<ResourceRender
				icon={<Icon name="building" color="icon/primary/default" />}
				displayName={workspace.name}
			/>
		);
	}

	switch (record.resource_type) {
		case 'API_KEY':
			return <ApiKeyRender record={record} />;
		case 'AUTOMATION':
			return <AutomationRender record={record} />;
		case 'BACKGROUND_JOB':
			return (
				<ResourceRender
					icon={<Icon name="settings" color="icon/primary/default" />}
					displayName="System"
				/>
			);
		case 'COMMENT':
			return <CommentRender record={record} />;
		case 'CUSTOM_PROPERTY':
			return <CustomPropertyRender record={record} />;
		case 'DATABUILDER_JOB':
			return (
				<ResourceRender
					icon={<Icon name="settings" color="icon/primary/default" />}
					displayName="System"
				/>
			);
		case 'DATA_ACCESS_REQUEST':
			return <DataAccessRequestRender record={record} />;
		case 'IAM_RESOURCE_POLICY':
			return (
				<ResourceRender
					icon={<Icon name="users" color="icon/primary/default" />}
					displayName="IAM resource policy"
				/>
			);
		case 'IAM_ROLE':
			return <IamRoleRender record={record} />;
		case 'INCIDENT':
			return <IncidentRender record={record} />;
		case 'INTEGRATION':
			return <IntegrationRender record={record} />;
		case 'INVITATION_LINK':
			return (
				<ResourceRender
					icon={<Icon name="user" color="icon/primary/default" />}
					displayName="Invitation link"
				/>
			);
		case 'LINEAGE':
			return (
				<ResourceRender
					icon={<Icon name="listDetails" color="icon/primary/default" />}
					displayName="Lineage"
				/>
			);
		case 'MARKETPLACE_INTEGRATION_SPEC':
			return (
				<ResourceRender
					icon={<Icon name="plugConnected" color="icon/primary/default" />}
					displayName="Marketplace integration spec"
				/>
			);
		case 'MARKETPLACE_INTEGRATION_SPEC_VERSION':
			return (
				<ResourceRender
					icon={<Icon name="plugConnected" color="icon/primary/default" />}
					displayName="Marketplace integration spec version"
				/>
			);
		case 'METRIC':
			return <MetricRender record={record} />;
		case 'MONITOR':
			return <MonitorRender record={record} />;
		case 'POLICY':
			return <PolicyRender record={record} />;
		case 'QUERY':
			return (
				<ResourceRender
					icon={<Icon name="bolt" color="icon/primary/default" />}
					displayName="Query"
				/>
			);
		case 'QUESTION':
			return (
				<ResourceRender
					icon={
						<Icon name="messageCircleQuestion" color="icon/primary/default" />
					}
					displayName="Question"
				/>
			);
		case 'QUESTION_REPLY':
			return (
				<ResourceRender
					icon={
						<Icon name="messageCircleQuestion" color="icon/primary/default" />
					}
					displayName="Question reply"
				/>
			);
		case 'REPORT':
			return <ReportRender record={record} />;
		case 'S3_OBJECT':
			return (
				<ResourceRender
					icon={<Icon name="database" color="icon/primary/default" />}
					displayName="S3 object"
				/>
			);
		case 'SECODA_ENTITY':
			return <EntityRender record={record} />;
		case 'TAG':
			return <TagRender record={record} />;
		case 'TAG_GROUP':
			return <TagGroupRender record={record} />;
		case 'TEAM':
			return <TeamRender record={record} />;
		case 'USER':
			return <UserRender record={record} />;
		case 'USER_GROUP':
			return <UserGroupRender record={record} />;
		case 'WORKSPACE':
			return <WorkspaceRender record={record} />;
		case 'WIDGET':
			return (
				<ResourceRender
					icon={<Icon name="chartBar" color="icon/primary/default" />}
					displayName="Widget"
				/>
			);
		default:
			return <ActivityLogNoResourceRender />;
	}
}

export function ActivityLogEventBlocksRender({
	blocks,
}: {
	blocks: ActivityLogEventBlock[];
}) {
	return (
		<Group spacing={0} sx={{ whiteSpace: 'pre' }}>
			{blocks.map((block, index) => (
				<EventBlock
					key={index}
					block={block}
					capitalizeFirst={index === 0}
					last={index === blocks.length - 1}
				/>
			))}
		</Group>
	);
}

export function ActivityLogPerformerRender({
	record,
}: {
	record: ActivityLogOut;
}) {
	const { data: integration } = useIntegrationPrefetched({
		id: record.performer_id ?? '',
	});

	if (record.performer_type === 'AUTOMATION') {
		return (
			<Group spacing="2xs" noWrap>
				<Icon name="bolt" />
				<Text size="sm" color="text/primary/default">
					Automation
				</Text>
			</Group>
		);
	}
	if (record.performer_type === 'INTEGRATION' && integration) {
		return (
			<Group spacing="2xs" noWrap>
				<IntegrationLogo
					integrationId={integration.id}
					integrationType={integration.type}
					width={IconSizeMap.md}
					height={IconSizeMap.md}
				/>
				<Text size="sm" color="text/primary/default">
					{integration.name}
				</Text>
			</Group>
		);
	}
	if (
		['USER', 'API'].includes(record.performer_type) &&
		record.performer_metadata
	) {
		return (
			<Group spacing="2xs" noWrap>
				<UserAvatar size="xs" user={record.performer_metadata as LiteUserOut} />
				<Text size="sm" color="text/primary/default">
					{record.performer_metadata?.display_name}
					{record.performer_type === 'API' && (
						<Text
							size="sm"
							color="text/secondary/default"
							span
						>{` via API`}</Text>
					)}
				</Text>
			</Group>
		);
	}
	if (record.performer_type === 'UNKNOWN') {
		return (
			<Group spacing="2xs" noWrap>
				<Icon name="user" />
				<Text size="sm" color="text/primary/default">
					Unknown
				</Text>
			</Group>
		);
	}

	return null;
}

export function ActivityLogEventBlockRender({
	blocks,
}: {
	blocks: ActivityLogEventBlock[];
}) {
	if (!blocks) {
		return null;
	}

	return (
		<Text>
			{blocks.map((block) => {
				if (block.type === 'text' && block.style === 'normal') {
					return <Text key={block.value}>{block.value}</Text>;
				}

				if (block.type === 'text' && block.style === 'bold') {
					return (
						<Text key={block.value} weight="bold">
							{block.value}
						</Text>
					);
				}

				return null;
			})}
		</Text>
	);
}
