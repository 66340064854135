import type { FilterView } from '@repo/common/components/Filter/types';
import type { ISearchView } from '@repo/common/models/searchView';
import type { IUser } from '../../../api';

export function convertISearchViewToFilterView(
	view: ISearchView,
	user?: IUser
): FilterView {
	return {
		...view,
		id: view.id,
		label: view.name,
		value: view.id,
		filters: view.selected_filters,
		isOwner: view.owner_id === user?.id,
		teams: view.teams,
	};
}
