import { Group, createStyles } from '@mantine/core';
import { IconButton, Text } from '@repo/foundations';
import { isNumber } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import {
	AutomationCondition,
	AutomationField,
	type Automation,
} from '../../../../../api';
import { AutomationPropagateMetadataRelationship } from '../../../../../api/types/models/automation/automationPropagateMetadata';
import { useAutomationStore } from '../../../../../pages/AutomationPage/context';
import { isValidEnumValue } from '../../../../../utils/enumUtils';
import { useFeatureFlags } from '../../../../../utils/featureFlags';
import { AutomationActionCardType } from '../../../constants';
import AutomationCardButton from '../../AutomationCardButton';
import {
	AUTOMATION_PROPAGATE_METADATA_LEVELS,
	AUTOMATION_PROPAGATE_METADATA_RELATIONSHIP,
} from './constants';
import { getLevelLabel, getRelationshipLabel } from './util';

const useStyles = createStyles((theme) => ({
	root: {
		justifyContent: 'space-between',
		width: '100%',
	},
	group: {
		alignSelf: 'stretch',
		background: theme.other.getColor('surface/secondary/default'),
		width: '100%',
		gap: theme.spacing['2xs'],
	},
}));

interface AutomationActionCardPropagateMetadataHeaderTextProps {
	cardId: string;
	handleAutomationUpdate: (automation: Partial<Automation>) => Promise<void>;
}

function AutomationActionCardPropagateMetadataHeaderText({
	cardId,
	handleAutomationUpdate,
}: AutomationActionCardPropagateMetadataHeaderTextProps) {
	const { classes } = useStyles();
	const automationStore = useAutomationStore();
	const { automation } = automationStore;

	const { automationLineageMetadataPropagation: enableLineagePropagation } =
		useFeatureFlags();

	const [level, setLevel] = useState<string>(
		getLevelLabel(automation?.action_propagate_metadata?.levels?.toString()) ??
			AUTOMATION_PROPAGATE_METADATA_LEVELS[0].label
	);
	const [relationship, setRelationship] = useState<string>(
		getRelationshipLabel(automation?.action_propagate_metadata?.to) ??
			AUTOMATION_PROPAGATE_METADATA_RELATIONSHIP[0].label
	);
	const [showLevel, setShowLevel] = useState<boolean>(
		automation?.action_propagate_metadata?.to !==
			AutomationPropagateMetadataRelationship.EXACT_NAME
	);

	const handleLevelOnClick = async (option: string | boolean) => {
		if (typeof option === 'string' && isNumber(Number(option))) {
			const label = getLevelLabel(option);

			if (label) {
				setLevel(label);

				await handleAutomationUpdate({
					action_propagate_metadata: {
						...automation?.action_propagate_metadata,
						levels: Number(option),
					},
				});
			}
		} else {
			throw new Error(
				'Invalid propagate metadata level option has been provided'
			);
		}
	};

	const handlePropagationRelationshipOnClick = async (
		option: string | boolean
	) => {
		if (
			typeof option === 'string' &&
			isValidEnumValue(AutomationPropagateMetadataRelationship, option)
		) {
			const label = getRelationshipLabel(option);

			if (label) {
				setRelationship(label);

				await handleAutomationUpdate({
					action_propagate_metadata: {
						...automation?.action_propagate_metadata,
						to: option,
					},
				});
			}

			if (option === AutomationPropagateMetadataRelationship.EXACT_NAME) {
				setShowLevel(false);
			} else {
				setShowLevel(true);
			}
		} else {
			throw new Error(
				'Invalid propagate metadata relationship option has been provided'
			);
		}
	};

	const handlePropagateMetadataCardDelete = async () => {
		automationStore.removeCard(
			AutomationActionCardType.PROPAGATE_METADATA,
			cardId
		);

		await handleAutomationUpdate({
			action_propagate_metadata: null,
		});
	};

	const relationshipOptions = useMemo(() => {
		let options = AUTOMATION_PROPAGATE_METADATA_RELATIONSHIP;
		/**
		 * if any filter sets contain a filter with an exact match on title
		 * remove the exact name option
		 */
		if (
			automation?.filter_config?.filter_sets.some((set) =>
				set.filters.some(
					(filter) =>
						filter.field === AutomationField.TITLE &&
						filter.condition === AutomationCondition.IS
				)
			)
		) {
			options = options.filter(
				(prop) =>
					prop.value !== AutomationPropagateMetadataRelationship.EXACT_NAME
			);
		}

		if (!enableLineagePropagation) {
			options = options.filter(
				(prop) =>
					prop.value !== AutomationPropagateMetadataRelationship.LINEAGE_UP &&
					prop.value !== AutomationPropagateMetadataRelationship.LINEAGE_DOWN
			);
		}

		return options;
	}, [automation?.filter_config?.filter_sets, enableLineagePropagation]);

	return (
		<Group className={classes.root} noWrap>
			<Group className={classes.group} noWrap>
				Propagate the following properties to
				<AutomationCardButton
					buttonLabel={relationship}
					buttonOptions={relationshipOptions}
					onClick={handlePropagationRelationshipOnClick}
				/>
				{showLevel && (
					<>
						<Text>up to</Text>
						<AutomationCardButton
							buttonLabel={level}
							buttonOptions={AUTOMATION_PROPAGATE_METADATA_LEVELS}
							onClick={handleLevelOnClick}
						/>
					</>
				)}
			</Group>
			<IconButton
				variant="tertiary"
				onClick={handlePropagateMetadataCardDelete}
				iconName="x"
			/>
		</Group>
	);
}

export default observer(AutomationActionCardPropagateMetadataHeaderText);
