import { Box, createStyles, Skeleton, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { EntityType } from '@repo/common/enums/entityType';
import { SegmentedControl } from '@repo/foundations';
import type { DataTableColumn } from '@repo/mantine-datatable';
import { map, without } from 'lodash-es';
import { Suspense, useMemo, useState } from 'react';
import type { IColumn, ISecodaEntity } from '../../api';
import { queryClient, useCollection, useUpdateSecodaEntity } from '../../api';
import type { FetchModelList } from '../../api/factories/types';
import { fetchCatalogList } from '../../api/hooks/resourceCatalog';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
import { CapitalizeTextRender } from '../../components/TableV2/render';
import { CatalogTable } from '../../pages/TeamCatalogPage/CatalogTable';
import { useColumns as useCatalogColumns } from '../../pages/TeamCatalogPage/TeamCatalogPage.hooks';
import type { CatalogServer } from '../CatalogView/types';
import { ErrorBoundary } from '../ErrorBoundary';
import { FilterOptionType } from '../Filter';
import LoadingTable from '../LoadingTable';

const useStyles = createStyles((theme) => ({
	tableWrapper: {
		paddingTop: theme.spacing.xs,
		width: '100%',
		minHeight: 350,
		flexGrow: 1,
	},
	entityTableWrapper: {
		height: '100%',
	},
}));

const QUICK_ACTIONS = [
	'actions::pii',
	'actions::verified',
	'actions::sidebar',
	'actions::ai',
	'actions::remove',
] as const;

export function CollectionsCatalogTable({
	collectionId,
	activeTab,
}: {
	collectionId: string;
	activeTab: string;
}) {
	const { classes } = useStyles();

	const [activeCollectionTab, setActiveCollectionTab] = useState<
		'Parents' | 'Children'
	>('Children');

	const { data: collection } = useCollection({
		id: collectionId,
	});

	const { mutateAsync } = useUpdateSecodaEntity({});

	const catalogColumns = useCatalogColumns();

	const columnColumns = useMemo(
		() =>
			catalogColumns.map((cols) => ({
				...cols,
				...(cols.accessor === 'type' && {
					esAccessor: 'type',
					render: (record: IColumn) => (
						<CapitalizeTextRender<IColumn>
							record={record}
							field={'type'}
							field2={'native_type'}
						/>
					),
					filterOptionType: FilterOptionType.NATIVE_TYPE,
				}),
			})),
		[catalogColumns]
	);

	const withAdditionalButtons = useMemo(
		() =>
			activeTab === EntityType.collection ? (
				<SegmentedControl
					size="xs"
					w="fit-content"
					value={activeCollectionTab}
					onChange={(value) =>
						setActiveCollectionTab(value as 'Parents' | 'Children')
					}
					data={['Parents', 'Children']}
				/>
			) : null,
		[activeCollectionTab, activeTab]
	);

	const defaultRequiredCatalogFilters = useMemo(
		() => ({
			operands:
				activeTab === EntityType.collection && activeCollectionTab === 'Parents'
					? [
							{
								operands: [],
								field: 'id',
								operator: 'in' as const,
								value: collection?.collections ?? [],
							},
						]
					: [
							{
								operands: [],
								field: 'collections',
								operator: 'exact' as const,
								value: collection?.id,
							},
						],
		}),
		[activeCollectionTab, activeTab, collection]
	);

	const withCustomProperties = useMemo(
		() => [activeTab as EntityType],
		[activeTab]
	);

	if (!collection || !activeTab) {
		return <LoadingTable />;
	}

	return (
		<Stack mt="3xs" spacing="xs" className={classes.tableWrapper}>
			<Box className={classes.entityTableWrapper}>
				<ErrorBoundary>
					<Suspense fallback={<Skeleton h="70vh" w="100%" />}>
						<CatalogTable
							// Because the hooks change between the two versions of the
							// table, we need to pass a key on the "tab change event" to
							// force a re-render.
							withCustomProperties={withCustomProperties}
							key={activeTab}
							columnVisibility={{
								catalogType: activeTab as CatalogServer['type'],
								catalogServerType: activeTab as CatalogServer['type'],
							}}
							defaultRequiredSearchParams={{
								entity_type: activeTab as CatalogServer['type'],
							}}
							{...(activeTab === EntityType.column && {
								columns: columnColumns as DataTableColumn<ISecodaEntity>[],
							})}
							additionalActions={[
								{
									id: 'actions::remove',
									title: 'Remove from collection',
									name: 'Remove from collection',
									iconName: 'circleX' as const,
									hotkey: '/rm',
									type: EntityType.all,
									team: undefined,
									category: 'actions',
									show: true,
									onClick: async (selected: ISecodaEntity[]) => {
										await Promise.all(
											map(selected, async (entity) => {
												await mutateAsync({
													data: {
														id: entity.id,
														collections: without(
															entity.collections,
															collection?.id
														),
													},
												});
											})
										);
										showNotification({
											title: 'Resources removed',
											message:
												'Resources have been removed from the collection',
										});
										queryClient.invalidateQueries({
											queryKey: resourceCatalogQueryKeyFactory.allLists(),
										});
									},
								},
							]}
							withQuickActions={QUICK_ACTIONS}
							withAdditionalButtons={withAdditionalButtons}
							defaultRequiredCatalogFilters={defaultRequiredCatalogFilters}
							defaultRequiredSearchParamsNesting={{
								entity_type: EntityType.collection,
							}}
							nestingFilter="collections"
							fetchPaginationList={
								fetchCatalogList as unknown as FetchModelList<ISecodaEntity>
							}
						/>
					</Suspense>
				</ErrorBoundary>
			</Box>
		</Stack>
	);
}
