import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import type { Token } from 'markdown-it';
import type { MarkSpec, Mark as ProsemirrorMark } from 'prosemirror-model';
import markInputRule from '../lib/markInputRule';
import markRule from '../rules/mark';
import type { MarkOptions } from './Mark';
import Mark from './Mark';

export default class DataDiff extends Mark {
	get name() {
		return 'data-diff';
	}

	get schema(): MarkSpec {
		return {
			attrs: {
				'data-diff': {
					default: 'default',
				},
			},
			parseDOM: [
				{
					tag: 'span',
				},
			],
			toDOM: (mark) => ['span', { 'data-diff': mark.attrs['data-diff'] }, 0],
		};
	}

	inputRules({ type }: MarkOptions) {
		return [
			markInputRule(/(?:@@)([^@]+)(?:@@){data-diff=(added|removed)}/, type),
		];
	}

	get rulePlugins() {
		return [markRule({ delim: '@@', mark: 'data-diff' })];
	}

	toMarkdown() {
		return {
			open: '@@',
			close(state: MarkdownSerializerState, mark: ProsemirrorMark) {
				return `@@{data-diff=${mark.attrs['data-diff']}}`;
			},
			mixable: true,
			expelEnclosingWhitespace: true,
		};
	}

	parseMarkdown() {
		return {
			mark: 'data-diff',
			getAttrs: (token: Token) => {
				let parsedAttrs = (token.attrs ?? []).reduce(
					(acc, item: [string, string]) => {
						if (item?.length !== 2) {
							return acc;
						}

						return {
							...acc,
							[item[0]]: item[1],
						};
					},
					{} as Record<string, string>
				);

				return {
					'data-diff': parsedAttrs['data-diff'],
				};
			},
		};
	}
}
