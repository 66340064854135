/* eslint-disable react/state-in-constructor */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
import { Box } from '@mantine/core';
import type {
	EditorDictionary,
	EmbedDescriptor,
	ToastType,
} from '@repo/secoda-editor';
import { baseDictionary } from '@repo/secoda-editor';
import { isNil, memoize } from 'lodash-es';
import { Selection } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';
import React from 'react';
import { ThemeProvider as OutlineThemeProvider } from 'styled-components';
import { PARENT_DOC_ID } from '../../../Documentation/utils';
import removeCommentMark from './commands/removeCommentMark';
import { BlockMenu } from './components/BlockMenu';
import { EmojiMenu } from './components/EmojiMenu';
import Flex from './components/Flex';
import type { SearchResult } from './components/LinkEditor';
import LinkToolbar from './components/LinkToolbar';
import { MentionMenu } from './components/MentionMenu';
import SelectionToolbar from './components/SelectionToolbar';
import type Extension from './lib/Extension';
// Marks
import Bold from './marks/Bold';
import Comment, { COMMENT_PLACEHOLDER_ID } from './marks/Comment';
import Highlight from './marks/Highlight';
import Italic from './marks/Italic';
import Link from './marks/Link';
import type Mark from './marks/Mark';
import TemplatePlaceholder from './marks/Placeholder';
import Strikethrough from './marks/Strikethrough';
import Underline from './marks/Underline';
import AIBlock from './nodes/AIBlock';
import Attachment from './nodes/Attachment';
import Blockquote from './nodes/Blockquote';
import BulletList from './nodes/BulletList';
import ChartBlock from './nodes/ChartBlock';
import CheckboxItem from './nodes/CheckboxItem';
import CheckboxList from './nodes/CheckboxList';
import CodeBlock from './nodes/CodeBlock';
import CodeFence from './nodes/CodeFence';
import Embed from './nodes/Embed';
import Emoji from './nodes/Emoji';
import HardBreak from './nodes/HardBreak';
import Heading from './nodes/Heading';
import HorizontalRule from './nodes/HorizontalRule';
import Image from './nodes/Image';
import ListItem from './nodes/ListItem';
import Notice from './nodes/Notice';
import OrderedList from './nodes/OrderedList';
import Page from './nodes/Page';
import QueryBlock from './nodes/QueryBlock';
// Nodes
import type Node from './nodes/Node';
import Table from './nodes/Table';
import TableCell from './nodes/TableCell';
import TableHeadCell from './nodes/TableHeadCell';
import TableRow from './nodes/TableRow';
// Plugins
import ResourceLink from './nodes/ResourceLink';
import AttachmentTrigger from './plugins/AttachmentTrigger';
import BlockMenuTrigger from './plugins/BlockMenuTrigger';
import EmojiTrigger from './plugins/EmojiTrigger';
import Folding from './plugins/Folding';
import History from './plugins/History';
import Keys from './plugins/Keys';
import MaxLength from './plugins/MaxLength';
import MentionTrigger from './plugins/MentionTrigger';
import PasteHandler from './plugins/PasteHandler';
import Placeholder from './plugins/Placeholder';
import SmartText from './plugins/SmartText';
import TrailingNode from './plugins/TrailingNode';

// Styles
import { SecodaEditor } from '../../SecodaEditor';
import { FindAndReplace } from './components/FindAndReplace';
import { PlaceholderToolbar } from './components/PlaceholderToolbar';
import { getHeadings } from './lib/getHeadings';
import { getQueryBlocks } from './lib/getQueryBlocks';
import DataDiff from './marks/DataDiff';
import AISummary from './nodes/AISummary';
import TableOfContents from './nodes/TableOfContents';
import FindAndReplaceTrigger from './plugins/FindAndReplaceTrigger';
import ScrollToHeading from './plugins/ScrollToHeading';
import SpellCheck from './plugins/SpellCheck';
import StyledEditor from './styles/editor';
import { light as lightOutlineTheme } from './styles/theme';

// paragraph cannot be included in this list! it's the node to render any plain text
export type ExtensionsNames =
	| 'strong'
	| 'code_inline'
	| 'highlight'
	| 'em'
	| 'link'
	| 'placeholder'
	| 'strikethrough'
	| 'underline'
	| 'blockquote'
	| 'bullet_list'
	| 'checkbox_item'
	| 'checkbox_list'
	| 'code_block'
	| 'code_fence'
	| 'embed'
	| 'br'
	| 'heading'
	| 'hr'
	| 'image'
	| 'list_item'
	| 'container_notice'
	| 'ordered_list'
	| 'table'
	| 'td'
	| 'th'
	| 'tr'
	| 'emoji'
	| 'emojimenu'
	| 'holy'
	| 'query_block'
	| 'chart_block'
	| 'ai_block'
	| 'page'
	| 'attachment'
	| 'comment'
	| 'blockmenu'
	| 'toc'
	| 'ai_summary'
	| 'resource_link'
	| 'trailing_node'
	| 'find-and-replace';

export type IProseMirrorEditorProps = {
	id?: string;
	dataTestId?: string;
	value?: string;
	defaultValue: string;
	placeholder: string;
	extensions?: Extension[];
	disableTopGap?: boolean;
	disableExtensions?: ExtensionsNames[];
	disableInputExtensions?: ExtensionsNames[];
	autoFocus?: boolean;
	readOnly?: boolean;
	readOnlyWriteCheckboxes?: boolean;
	dictionary?: Partial<EditorDictionary>;
	dir?: string;
	template?: boolean;
	headingsOffset?: number;
	maxLength?: number;
	scrollTo?: string;
	// eslint-disable-next-line react/no-unused-prop-types
	handleDOMEvents?: {
		[name: string]: (view: EditorView, event: Event) => boolean;
	};
	uploadFile?: (file: File, isImage: boolean) => Promise<string>;
	onBlur?: () => void;
	onFocus?: () => void;
	// @ts-expect-error TS(7031): Binding element 'done' implicitly has an 'any' typ... Remove this comment to see the full error message
	onSave?: ({ done }) => void;
	onCancel?: () => void;
	onChange?: (value: () => string | undefined) => void;
	onUnmount?: () => void;
	onFileUploadStart?: () => void;
	onFileUploadStop?: () => void;
	onCreateLink?: (title: string) => Promise<string>;
	onSearchLink?: (term: string) => Promise<SearchResult[]>;
	onClickLink: (href: string, event: MouseEvent) => void;
	focusedCommentID?: string;
	onCreatePlaceholderComment?: (selectedText: string) => void;
	onClickComment?: (commentID: string) => void;
	onCreateView?: () => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
	embeds: EmbedDescriptor[];
	onShowToast: (message: string, code: ToastType | string) => void;
	className?: string;
	style?: React.CSSProperties;
	onTrackEvent?: (
		eventName: string,
		properties?: Record<string, string>
	) => void;
	singleLineEditor?: boolean;
	showMentionMenuButton?: boolean;
	showAttachmentButton?: boolean;

	disableResourceLinking?: boolean;
	onAttachmentUpload?: (url: string) => void;
	onAttachmentRemove?: (url: string) => void;
};

type State = {
	isRTL: boolean;
	isEditorFocused: boolean;
	selectionMenuOpen: boolean;
	blockMenuOpen: boolean;
	findAndReplaceOpen: boolean;
	linkMenuOpen: boolean;
	placeholderMenuOpen: boolean;
	blockMenuSearch: string;
	emojiMenuOpen: boolean;
	mentionMenuOpen: boolean;
};

export class RichMarkdownEditor extends React.PureComponent<
	IProseMirrorEditorProps,
	State
> {
	static defaultProps = {
		defaultValue: '',
		dir: 'auto',
		placeholder: 'Write something nice…',
		onFileUploadStart: () => {
			// No default behavior
		},
		onFileUploadStop: () => {
			// No default behavior
		},
		embeds: [],
		extensions: [],
	};

	state = {
		isRTL: false,
		isEditorFocused: false,
		selectionMenuOpen: false,
		blockMenuOpen: false,
		findAndReplaceOpen: false,
		linkMenuOpen: false,
		placeholderMenuOpen: false,
		blockMenuSearch: '',
		emojiMenuOpen: false,
		mentionMenuOpen: false,
	};

	// @ts-expect-error TS(2564): Property 'isBlurred' has no initializer and is not... Remove this comment to see the full error message
	isBlurred: boolean;

	element?: HTMLElement | null;

	editor: SecodaEditor;

	constructor(props: IProseMirrorEditorProps) {
		super(props);

		const self = this;
		this.editor = new SecodaEditor({
			...this.props,
			extensions: this.getAllExtensions(),
			handleChange: this.handleChange,
			onFinishedTransactions: () => {
				self.calculateDir();

				// Because Prosemirror and React are not linked we must tell React that
				// a render is needed whenever the Prosemirror state changes.
				self.forceUpdate();
			},
		});
	}

	componentDidMount() {
		this.init();

		this.calculateDir();

		if (this.props.scrollTo) {
			this.scrollToAnchor(this.props.scrollTo!);
		} else if (!this.props.readOnly && this.props.autoFocus) {
			this.focusAtEnd();
		}
	}

	componentDidUpdate(prevProps: IProseMirrorEditorProps) {
		// Allow changes to the 'value' prop to update the editor from outside
		if (!isNil(this.props.value) && prevProps.value !== this.props.value) {
			const newState = this.editor.createState(this.props.value);
			this.editor.view!.updateState(newState);
		}

		// Pass readOnly changes through to underlying editor instance
		if (prevProps.readOnly !== this.props.readOnly) {
			this.editor.setReadOnly(this.props.readOnly);
		}

		if (this.props.scrollTo && this.props.scrollTo !== prevProps.scrollTo) {
			this.scrollToAnchor(this.props.scrollTo);
		}

		// Focus at the end of the document if switching from readOnly and autoFocus
		// is set to true
		if (prevProps.readOnly && !this.props.readOnly && this.props.autoFocus) {
			this.focusAtEnd();
		}

		if (prevProps.dir !== this.props.dir) {
			this.calculateDir();
		}

		if (
			!this.isBlurred &&
			!this.state.isEditorFocused &&
			!this.state.blockMenuOpen &&
			!this.state.findAndReplaceOpen &&
			!this.state.linkMenuOpen &&
			!this.state.placeholderMenuOpen &&
			!this.state.selectionMenuOpen
		) {
			this.isBlurred = true;
			if (this.props.onBlur) {
				this.props.onBlur();
			}
		}

		if (
			this.isBlurred &&
			(this.state.isEditorFocused ||
				this.state.blockMenuOpen ||
				this.state.findAndReplaceOpen ||
				this.state.linkMenuOpen ||
				this.state.placeholderMenuOpen ||
				this.state.selectionMenuOpen)
		) {
			this.isBlurred = false;
			if (this.props.onFocus) {
				this.props.onFocus();
			}
		}
	}

	componentWillUnmount(): void {
		removeCommentMark(
			this.editor.view!.state,
			this.editor.view!.dispatch,
			COMMENT_PLACEHOLDER_ID
		);

		if (this.props.onUnmount) {
			this.props.onUnmount();
		}
	}

	init() {
		this.editor.createView(
			this.element,
			this.props.value || this.props.defaultValue || ''
		);
	}

	getAllExtensions() {
		const dictionary = this.dictionary(this.props.dictionary);

		const allExtensions = [
			...[
				new Emoji(),
				new Bold(),
				new Italic(),
				new Underline(),
				new Link({
					onKeyboardShortcut: this.handleOpenLinkMenu,
					onClickLink: this.props.onClickLink,
					scrollToAnchor: this.scrollToAnchor.bind(this),
				}),
				new Strikethrough(),
				new History(),
				new TrailingNode(),
				new Placeholder({
					placeholder: this.props.placeholder,
				}),
				new MaxLength({
					maxLength: this.props.maxLength,
				}),
				new Image({
					dictionary,
					uploadFile: this.props.uploadFile,
					onFileUploadStart: this.props.onFileUploadStart,
					onFileUploadStop: this.props.onFileUploadStop,
					onShowToast: this.props.onShowToast,
				}),
				new HardBreak(),
				new CodeBlock({
					dictionary,
					onShowToast: this.props.onShowToast,
				}),
				new CodeFence({
					dictionary,
					onShowToast: this.props.onShowToast,
				}),
				new Blockquote(),
				new Embed({ embeds: this.props.embeds }),
				new Attachment({
					dictionary,
				}),
				new Notice({
					dictionary,
				}),
				new Heading({
					dictionary,
					onShowToast: this.props.onShowToast,
					offset: this.props.headingsOffset,
				}),
				new HorizontalRule(),
				new Highlight(),
				new DataDiff(),
				new TemplatePlaceholder(),
				new Page(),
				new QueryBlock({
					dictionary,
					onShowToast: this.props.onShowToast,
				}),
				new ChartBlock({
					dictionary,
					onShowToast: this.props.onShowToast,
				}),
				new AIBlock({
					dictionary,
				}),
				new ResourceLink(),
				new AISummary(),
				new TableOfContents({
					scrollToAnchor: this.scrollToAnchor.bind(this),
				}),

				new CheckboxList(),
				new CheckboxItem(),
				new BulletList(),
				new OrderedList(),
				new ListItem(),

				// the order matters here - we want Table to be last so that grip-table events are captured last and not compromise the grip-row / grip-column events defined in TableCell and TableHeadCell
				new TableCell(),
				new TableHeadCell(),
				new TableRow(),
				new Table(),
				// -

				new Comment({
					onCreatePlaceholderComment: this.props.onCreatePlaceholderComment,
					onClickComment: this.props.onClickComment,
					reference: this,
				}),
				new Folding(),
				new SmartText(),
				new PasteHandler(),
				new SpellCheck(),
				new ScrollToHeading(),
				new Keys({
					onBlur: this.handleEditorBlur,
					onFocus: this.handleEditorFocus,
					onSave: this.handleSave,
					onSaveAndExit: this.handleSaveAndExit,
					onCancel: this.props.onCancel,
				}),
				new FindAndReplaceTrigger({
					dictionary,
					onOpen: this.handleOpenFindAndReplace,
					onClose: this.handleCloseFindAndReplace,
				}),
				new BlockMenuTrigger({
					dictionary,
					onOpen: this.handleOpenBlockMenu,
					onClose: this.handleCloseBlockMenu,
					placeholder: this.props.placeholder ?? dictionary.newLineEmpty,
				}),
				new MentionTrigger({
					showMentionMenuButton: this.props.showMentionMenuButton,
					onOpen: (search: string) => {
						this.setState({
							mentionMenuOpen: true,
							blockMenuSearch: search,
						});
					},
					onClose: () => {
						this.setState({ mentionMenuOpen: false });
					},
				}),
				...(this.props.showAttachmentButton &&
				this.props.onAttachmentUpload &&
				this.props.onAttachmentRemove
					? [
							new AttachmentTrigger({
								uploadFile: this.props.uploadFile,
								onAttachmentUpload: this.props.onAttachmentUpload,
								onAttachmentRemove: this.props.onAttachmentRemove,
							}),
						]
					: []),
				new EmojiTrigger({
					onOpen: (search: string) => {
						this.setState({
							emojiMenuOpen: true,
							blockMenuSearch: search,
						});
					},
					onClose: () => {
						this.setState({ emojiMenuOpen: false });
					},
				}),
			],
			...(this.props.extensions || []),
		] as (Node | Mark | Extension)[];

		return allExtensions;
	}

	scrollToAnchor(hash: string) {
		if (!hash) {
			return;
		}

		const nodeIdToSearch = hash.substring(1);

		const headings = getHeadings(this.editor.view!.state.doc);
		const queryBlocks = getQueryBlocks(this.editor.view!.state.doc);
		const activeNode =
			headings.find(
				(heading) =>
					heading.id === nodeIdToSearch || heading.legacyId === nodeIdToSearch
			) ||
			queryBlocks.find((queryBlock) => queryBlock.linkId === nodeIdToSearch);
		if (activeNode) {
			this.editor.view!.focus();
			this.editor.view!.dispatch(
				this.editor
					.view!.state.tr.setSelection(activeNode.selection)
					.scrollIntoView()
			);
		}
	}

	calculateDir = () => {
		if (!this.element) {
			return;
		}

		const isRTL =
			this.props.dir === 'rtl' ||
			getComputedStyle(this.element).direction === 'rtl';

		if (this.state.isRTL !== isRTL) {
			this.setState({ isRTL });
		}
	};

	value = (): string =>
		this.editor.serializer.serialize(this.editor.view!.state.doc);

	handleChange = () => {
		if (!this.props.onChange) {
			return;
		}

		this.props.onChange(() => (this.editor.view ? this.value() : undefined));
	};

	handleSave = () => {
		const { onSave } = this.props;
		if (onSave) {
			onSave({ done: false });
		}
	};

	handleSaveAndExit = () => {
		const { onSave } = this.props;
		if (onSave) {
			onSave({ done: true });
		}
	};

	handleEditorBlur = () => {
		this.setState({ isEditorFocused: false });
	};

	handleEditorFocus = () => {
		this.setState({ isEditorFocused: true });
	};

	handleOpenSelectionMenu = () => {
		this.setState({ blockMenuOpen: false, selectionMenuOpen: true });
	};

	handleCloseSelectionMenu = () => {
		this.setState({ selectionMenuOpen: false });
	};

	handleOpenLinkMenu = () => {
		this.setState({ blockMenuOpen: false, linkMenuOpen: true });
	};

	handleCloseLinkMenu = () => {
		this.setState({ linkMenuOpen: false });
	};

	handleOpenPlaceholderMenu = () => {
		this.setState({ blockMenuOpen: false, placeholderMenuOpen: true });
	};

	handleClosePlaceholderMenu = () => {
		this.setState({ placeholderMenuOpen: false });
	};

	handleOpenBlockMenu = (search: string) => {
		this.setState({ blockMenuOpen: true, blockMenuSearch: search });
	};

	handleCloseBlockMenu = () => {
		if (!this.state.blockMenuOpen) {
			return;
		}
		this.setState({ blockMenuOpen: false });
	};

	handleOpenFindAndReplace = () => {
		this.setState({
			blockMenuOpen: false,
			selectionMenuOpen: false,
			emojiMenuOpen: false,
			linkMenuOpen: false,
			mentionMenuOpen: false,
			placeholderMenuOpen: false,
			findAndReplaceOpen: true,
		});
	};

	handleCloseFindAndReplace = () => {
		if (!this.state.findAndReplaceOpen) {
			return;
		}
		this.setState({ findAndReplaceOpen: false });
	};

	// 'public' methods
	focusAtStart = () => {
		const selection = Selection.atStart(this.editor.view!.state.doc);
		const transaction = this.editor.view!.state.tr.setSelection(selection);
		this.editor.view!.dispatch(transaction);
		this.editor.view!.focus();
	};

	focusAtEnd = () => {
		const selection = Selection.atEnd(this.editor.view!.state.doc);
		const transaction = this.editor.view!.state.tr.setSelection(selection);
		this.editor.view!.dispatch(transaction);
		this.editor.view!.focus();
	};

	createLineAtStart = () => {
		const { state, dispatch } = this.editor.view!;
		dispatch(state.tr.insert(0, state.schema.nodes.paragraph.create({})));
		this.focusAtStart();
	};

	dictionary: (
		providedDictionary?: Partial<EditorDictionary>
	) => EditorDictionary = memoize(
		(providedDictionary?: Partial<EditorDictionary>) => ({
			...baseDictionary,
			...providedDictionary,
		})
	);

	render() {
		const {
			dir,
			readOnly,
			readOnlyWriteCheckboxes,
			style,
			className,
			onKeyDown,
		} = this.props;
		const { isRTL } = this.state;
		const dictionary = this.dictionary(this.props.dictionary);

		return (
			<Flex
				onKeyDown={onKeyDown}
				style={style}
				className={className}
				align="flex-start"
				justify="center"
				dir={dir}
				column
			>
				<OutlineThemeProvider theme={lightOutlineTheme}>
					{!readOnly && !this.props.disableTopGap && (
						<Box
							onClick={this.createLineAtStart}
							sx={{
								marginTop: 5,
								marginBottom: 10,
								height: 2,
								width: '100%',
								':before': {
									cursor: 'pointer',
									content: '""',
									display: 'block',
									height: 30,
									marginTop: -15,
									width: '100%',
								},
								':hover': {
									backgroundColor: 'rgba(0,0,0,0.05)',
								},
							}}
						/>
					)}
					<>
						<StyledEditor
							id={PARENT_DOC_ID}
							data-testid={this.props.dataTestId ?? 'rich-text-editor'}
							focusedCommentID={this.props.focusedCommentID}
							dir={dir}
							rtl={isRTL}
							readOnly={readOnly}
							readOnlyWriteCheckboxes={readOnlyWriteCheckboxes}
							ref={(ref) => (this.element = ref)}
							data-with-mention-menu-button={this.props.showMentionMenuButton}
							data-with-attachment-button={this.props.showAttachmentButton}
							spellCheck={false}
							data-autofocus={this.props.autoFocus ? 'true' : undefined}
							singleLineEditor={this.props.singleLineEditor ? true : undefined}
						/>
						{this.editor.view && (
							<SelectionToolbar
								view={this.editor.view}
								dictionary={dictionary}
								commands={this.editor.commands}
								rtl={isRTL}
								isTemplate={!!this.props.template}
								onOpen={this.handleOpenSelectionMenu}
								onClose={this.handleCloseSelectionMenu}
								onSearchLink={this.props.onSearchLink}
								onClickLink={this.props.onClickLink}
								scrollToAnchor={this.scrollToAnchor}
								onCreateLink={this.props.onCreateLink}
								onShowToast={this.props.onShowToast}
								isRestrictedToComments={readOnly}
								commentsEnabled={!!this.props.onClickComment}
							/>
						)}
						{!readOnly && this.editor.view && (
							<>
								<LinkToolbar
									view={this.editor.view}
									dictionary={dictionary}
									isActive={this.state.linkMenuOpen}
									onCreateLink={this.props.onCreateLink}
									onSearchLink={this.props.onSearchLink}
									onClickLink={this.props.onClickLink}
									scrollToAnchor={this.scrollToAnchor}
									onShowToast={this.props.onShowToast}
									onClose={this.handleCloseLinkMenu}
								/>
								<PlaceholderToolbar
									view={this.editor.view}
									dictionary={dictionary}
									isActive={this.state.placeholderMenuOpen}
									onClose={this.handleClosePlaceholderMenu}
								/>
								{this.state.emojiMenuOpen && (
									<EmojiMenu
										view={this.editor.view}
										commands={this.editor.commands}
										dictionary={dictionary}
										rtl={isRTL}
										search={this.state.blockMenuSearch}
										onClose={() => this.setState({ emojiMenuOpen: false })}
										onTrackEvent={this.props.onTrackEvent}
									/>
								)}
								{this.state.mentionMenuOpen && (
									<MentionMenu
										view={this.editor.view}
										commands={this.editor.commands}
										dictionary={dictionary}
										rtl={isRTL}
										search={this.state.blockMenuSearch}
										onClose={() =>
											this.setState({
												mentionMenuOpen: false,
											})
										}
										onTrackEvent={this.props.onTrackEvent}
									/>
								)}
								{this.state.blockMenuOpen && (
									<BlockMenu
										view={this.editor.view}
										commands={this.editor.commands}
										dictionary={dictionary}
										rtl={isRTL}
										search={this.state.blockMenuSearch}
										onClose={this.handleCloseBlockMenu}
										// @ts-expect-error TS(2322): Type '((file: File, isImage: boolean) => Promise<s... Remove this comment to see the full error message
										uploadFile={this.props.uploadFile}
										onLinkToolbarOpen={this.handleOpenLinkMenu}
										onPlaceholderToolbarOpen={this.handleOpenPlaceholderMenu}
										onFileUploadStart={this.props.onFileUploadStart}
										onFileUploadStop={this.props.onFileUploadStop}
										onShowToast={this.props.onShowToast}
										isTemplate={!!this.props.template}
										embeds={this.props.embeds}
										onTrackEvent={this.props.onTrackEvent}
									/>
								)}
								{this.state.findAndReplaceOpen && (
									<FindAndReplace
										editor={this.editor}
										dictionary={dictionary}
										readOnly={!!readOnly}
										onClose={this.handleCloseFindAndReplace}
									/>
								)}
							</>
						)}
						{Array.from(this.editor.renderers).map((view) => view.content)}
					</>
				</OutlineThemeProvider>
			</Flex>
		);
	}
}
