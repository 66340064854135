import { Box, Group, Menu, createStyles, useMantineTheme } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { showNotification } from '@mantine/notifications';
import { SettingsButton } from '@repo/common/components';
import { Button, Icon, Text } from '@repo/foundations';
import { documentImporter } from '@repo/secoda-editor/importer';
import axios from 'axios';
import { map } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { api } from '../../../network';
import { createDocument, useAuthUser } from '../../api';
import { usePlan } from '../../hooks/usePlans';
import {
	BackgroundJob,
	downloadExportTemplate,
	downloadImportTemplate,
	exportData,
} from '../../lib/models';
import { snakeCaseToTitleCase } from '../../utils/shared.utils';
import { BackgroundJobProgress } from '../BackgroundJobProgress';
import { openModal } from '../ModalManager';
import { UpgradeButton } from './UpgradeButton';

const useStyles = createStyles((theme) => ({
	callToAction: {
		// eslint-disable-next-line no-theme-colors/no-theme-colors
		color: theme.colors.primary[6],
	},
}));

// @ts-expect-error TS(7006): Parameter 'files' implicitly has an 'any' type.
const handleFilesSelected = async (files) => {
	if (files.length === 0) {
		return null;
	}
	const file = files[0];
	const form = new FormData();
	form.append('file', file);
	const response = await axios({
		method: 'post',
		url: `${api()}metadata/data/`,
		data: form,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return new BackgroundJob(response.data);
};

export const ImportSettings = observer(() => {
	const [opened, setOpened] = useState(false);
	const theme = useMantineTheme();
	const { classes } = useStyles();

	const { plan } = usePlan();
	const { isAdminUser } = useAuthUser();

	const disabled = !isAdminUser || !plan?.export_support;
	const [backgroundJob, setBackgroundJob] =
		React.useState<BackgroundJob | null>(null);
	const resetRef = useRef<() => void>(null);
	const [actionType, setActionType] = useState<'import' | 'export'>('import');

	const handleConfluence = async (files: File[]) => {
		const created = [];
		for (const file of files) {
			const map = {
				fileName: file.name,
				mimeType: file.type,
			};
			const { title, text } = await documentImporter(file, map);
			createDocument({ data: { title, definition: text } });
			created.push(title);
		}

		showNotification({
			title: 'Documents imported',
			message: `Imported ${created.length} documents: ${created.join(', ')}`,
			icon: <Icon name="check" />,
			// eslint-disable-next-line no-theme-colors/no-theme-colors
			color: theme.colors.green[theme.colorScheme === 'dark' ? 4 : 6],
		});
	};

	const typesToExport = useMemo(
		() => [
			'table',
			'column',
			'dashboard',
			'chart',
			'job',
			'event',
			'glossary',
			'document',
			'tag',
		],
		[]
	);

	const handleBackgroundJobComplete = () => {
		resetRef.current?.();
	};

	const openImportModal = () => {
		if (!plan?.export_support) {
			return;
		}

		const input = document.createElement('input');
		input.type = 'file';
		input.accept = 'text/csv';
		input.onchange = async (e) => {
			const file = (e.target as HTMLInputElement).files?.[0];
			if (file) {
				const newBackgroundJob = await handleFilesSelected([file]);
				setActionType('import');
				setBackgroundJob(newBackgroundJob);
			}
		};
		input.click();
	};

	const openDocumentsModal = useCallback(() => {
		if (!plan?.export_support) {
			return;
		}

		openModal({
			title: 'Import Documents',
			centered: true,
			children: (
				<Box>
					<Dropzone onDrop={handleConfluence} maxSize={16 * 1024 ** 2}>
						<Group
							position="center"
							spacing="md"
							style={{
								marginTop: 20,
								marginBottom: 20,
								minHeight: 140,
								pointerEvents: 'none',
							}}
						>
							<Dropzone.Accept>
								<Icon name="upload" size="lg" />
							</Dropzone.Accept>
							<Dropzone.Reject>
								<Icon name="x" size="lg" color="icon/critical/default" />
							</Dropzone.Reject>
							<Dropzone.Idle>
								<Icon name="file" color="icon/emphasis/default" size="lg" />
							</Dropzone.Idle>
							<Box>
								<Text
									align="center"
									color="text/secondary/default"
									inline
									mt={7}
								>
									Drag and drop .html or .doc files from Confluence's export
									options, or click to upload
								</Text>
							</Box>
						</Group>
					</Dropzone>
					<Text mt={20} mb={10} size="xs" color="text/secondary/default">
						{
							'For html upload, navigate to Space Settings > Manage space > Export space and choose to export as HTML with the "Normal Export" option. You will need to unzip the file and upload each html file separately. Maximum file size is 16MB.'
						}
					</Text>
				</Box>
			),
		});
	}, [plan?.export_support, handleConfluence]);

	return (
		<Box>
			{!!backgroundJob && (
				<BackgroundJobProgress
					key={backgroundJob.id}
					job={backgroundJob}
					onCompleted={handleBackgroundJobComplete}
				/>
			)}

			<SettingsButton
				title="Import"
				description={
					<>
						Bulk import resource descriptions and custom properties.{' '}
						<Button onClick={downloadImportTemplate} variant="plain">
							Download template
						</Button>
					</>
				}
				buttonText="Choose file..."
				onClick={openImportModal}
			>
				{!plan?.export_support && <UpgradeButton feature="Import" />}
			</SettingsButton>

			<SettingsButton
				title="Export"
				description={
					<>
						Bulk export resource descriptions and custom properties.{' '}
						<Button onClick={downloadExportTemplate} variant="plain">
							Download sample
						</Button>
					</>
				}
				buttonText="Export data"
				onClick={() => setOpened(true)}
			>
				{plan?.export_support ? (
					<Menu
						opened={opened}
						onChange={setOpened}
						position="bottom-start"
						width={150}
					>
						<Menu.Target>
							<Button
								variant="default"
								disabled={disabled}
								rightIconName="chevronDown"
							>
								Export data
							</Button>
						</Menu.Target>
						<Menu.Dropdown>
							{map(typesToExport, (type: string) => (
								<Menu.Item
									key={type}
									onClick={() => {
										exportData(type).then((newBackgroundJob) => {
											setActionType('export');
											setBackgroundJob(newBackgroundJob);
										});
									}}
								>
									{`${snakeCaseToTitleCase(type)} resources`}
								</Menu.Item>
							))}
						</Menu.Dropdown>
					</Menu>
				) : (
					<UpgradeButton feature="Export" />
				)}
			</SettingsButton>

			<SettingsButton
				title="Documents"
				description="Import documents from Confluence, Google Docs, and Microsoft Word"
				buttonText="Import documents"
				onClick={openDocumentsModal}
			>
				{!plan?.export_support && <UpgradeButton feature="Documents Import" />}
			</SettingsButton>
		</Box>
	);
});
