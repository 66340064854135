import type { FilterView } from '@repo/common/components/Filter/types';
import type { ISearchView } from '@repo/common/models/searchView';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { useAuthUser, useSuspenseSearchViewList } from '../../../api';

export function useFilterViews(): { views: FilterView[] } {
	const { user } = useAuthUser();
	const { data } = useSuspenseSearchViewList({});

	const views = useMemo(
		() =>
			(data?.results ?? [])
				.map(
					(view: ISearchView) =>
						({
							...view,
							is_default_for_teams: view.is_default_for_teams,
							label: view.name,
							value: view.id,
							filters: view.selected_filters,
							isOwner: view.owner_id === user.id,
							teams: view.teams,
						}) as FilterView
				)
				.sort((a, b) => {
					if (isNil(a.label)) return 1;
					return a.label.localeCompare(b.label);
				}),
		[data, user]
	);

	return {
		views: views ?? [],
	};
}
