import type { ActivityLogEventBlock } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { upperFirst } from 'lodash-es';

interface EventBlockProps {
	block: ActivityLogEventBlock;
	capitalizeFirst?: boolean;
	last?: boolean;
}

export function EventBlock({
	block,
	capitalizeFirst = false,
	last = false,
}: EventBlockProps) {
	if (block.type === 'text') {
		if (block.style === 'normal') {
			return (
				<Text size="sm" color="text/secondary/default" span>
					{capitalizeFirst ? upperFirst(block.value) : block.value}
					{!last && ' '}
				</Text>
			);
		}
		if (block.style === 'bold') {
			return (
				<Text size="sm" weight="bold" color="text/primary/default" span>
					{capitalizeFirst ? upperFirst(block.value) : block.value}
					{!last && ' '}
				</Text>
			);
		}
	}

	return (
		<Text size="sm" color="text/secondary/default" span>
			{capitalizeFirst ? upperFirst(block.value) : block.value}
			{!last && ' '}
		</Text>
	);
}
