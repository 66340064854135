import { Box, Group, Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { includes, isNil, startCase } from 'lodash-es';
import { useIntegration, useTagList } from '../../../../api';
import type { INotification } from '../../../../api/types/models/notification';
import { SecodaEntityIcon } from '../../../../components/SecodaEntity';
import { UserAvatar } from '../../../../components/UserAvatar';
import { buildResourceUrl } from '../../../../utils/navigationUtils';
import { useStyles } from './styles';

interface IResourceAddRemoveTagContentProps {
	selected: INotification;
}

function ResourceAddRemoveTagContent({
	selected,
}: IResourceAddRemoveTagContentProps) {
	const { classes, cx } = useStyles();

	const navigate = useNavigate();

	const { data: integration } = useIntegration({
		id: selected.entity?.integration,
		options: {
			enabled: !!selected.entity?.integration,
		},
	});

	const { data } = useTagList({
		filters: {
			name: selected.content,
		},
	});

	const tag = data?.results[0];

	const handleNavigateToUser = () => {
		navigate(`/user/${selected.sender.id}`);
	};

	const handleNavigateToResource = () => {
		navigate(
			buildResourceUrl(
				selected.entity || {
					id: selected.resource_id,
					entity_type: selected.resource_type,
				}
			)
		);
	};

	return (
		<Stack spacing="lg">
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="user" />
					User
				</Group>
				<Group
					className={cx(classes.rightCol, classes.cursorPointer)}
					spacing="xs"
					onClick={handleNavigateToUser}
				>
					<UserAvatar user={selected.sender} size="lg" />
					<Stack spacing={0}>
						<Text className={classes.label}>
							{selected.sender.display_name}
						</Text>
						<Text className={classes.subLabel}>{`${startCase(
							selected.sender.role
						)} • ${selected.sender.email}`}</Text>
					</Stack>
				</Group>
			</Group>
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="file" />
					Resource
				</Group>
				<Group
					className={cx(classes.rightCol, classes.cursorPointer)}
					spacing="xs"
					onClick={handleNavigateToResource}
				>
					<SecodaEntityIcon entity={selected.entity} />
					<Stack spacing={0}>
						<Text className={classes.label}>
							{selected.entity?.title || selected.resource_title}
						</Text>
						{!isNil(integration) && (
							<Text className={classes.subLabel}>{`${startCase(
								integration.name
							)} / ... / ${
								selected.entity?.title || selected.resource_title
							}`}</Text>
						)}
					</Stack>
				</Group>
			</Group>
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="tag" />
					{`Tag ${includes(selected.event, 'added') ? 'added' : 'removed'}`}
				</Group>
				<Group className={classes.rightCol} spacing="xs">
					{!isNil(tag) && (
						<Box w={36}>
							<Icon
								name="tag"
								size="lg"
								style={{ color: tag.color, fill: tag.color }}
							/>
						</Box>
					)}
					<Stack spacing={0}>
						<Text className={classes.label}>
							{tag?.name || selected.content}
						</Text>
						{!isNil(tag) && (
							<Text className={classes.subLabel} lineClamp={2}>
								{tag.description}
							</Text>
						)}
					</Stack>
				</Group>
			</Group>
			<Group spacing="xs" align="flex-start">
				<Group className={classes.leftCol} spacing="xs">
					<Icon name="clock" />
					Updated time
				</Group>
				<Group className={classes.rightCol} spacing="xs" noWrap>
					<Stack spacing={0}>
						<Text className={classes.soleLabel}>
							{dayjs(selected.created_at).format('MMMM D, YYYY h:mm A')}
						</Text>
					</Stack>
				</Group>
			</Group>
		</Stack>
	);
}

export default ResourceAddRemoveTagContent;
