import type { DataTableColumn } from '@repo/mantine-datatable';
import produce from 'immer';
import { cloneDeep, isArray, isNil, maxBy, uniqBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type { Catalog } from '../../../api';
import { useDataQualityAccess } from '../../../api';

import {
	useCatalogProperties,
	useCatalogToggleView,
	useUpdateCatalogProperties,
} from '../../../api/hooks/catalog';
import type { ExtendedDataTableColumn } from '../../TableV2/types';

import { EntityType } from '@repo/common/enums/entityType';
import type { CatalogServer, CatalogServerType } from '../types';

export interface UseColumnDefsArgs<T> {
	defaultColumns: DataTableColumn<T>[];
	catalogType: Catalog['catalog_type'];
	catalogServerType: CatalogServer['type'];
	entityId?: Catalog['entity_id'];
	forceReadOnly?: boolean;
	isEditorOrAdminUser?: boolean;
	disableSorting?: boolean;
	checkboxSelection?: boolean;
}

export const CATALOG_TYPES_ENABLE_DATA_QUALITY_SCORE: Array<CatalogServerType> =
	['catalog', EntityType.table];

export function useColumnDefs<T>({
	defaultColumns,
	catalogType,
	catalogServerType,
	entityId = null,
}: UseColumnDefsArgs<T>) {
	const dqsEnabled = useDataQualityAccess();

	// This will be used as the catalog properties.
	const { data: _catalog, refetch: refetchCatalog } = useCatalogProperties({
		catalog_type: catalogType,
		entity_id: entityId,
	});

	const { mutateAsync: updateCatalogProperties } = useUpdateCatalogProperties();
	const { mutateAsync: toggleCatalogView } = useCatalogToggleView();

	const canShowDataQualityScoreColumn =
		dqsEnabled &&
		CATALOG_TYPES_ENABLE_DATA_QUALITY_SCORE.includes(catalogServerType);

	const catalog: Catalog | null = useMemo(() => {
		if (!_catalog) {
			return null;
		}

		if (_catalog && !isArray(_catalog?.properties)) {
			return {
				..._catalog,
				properties:
					defaultColumns?.map((column, idx) => ({
						value: column.accessor as string,
						order: idx,
						hidden: false,
					})) ?? [],
			} as Catalog;
		}

		return {
			..._catalog,
			properties: [
				..._catalog.properties
					.filter((el) => {
						if (el.value === 'dqs' || el.value === 'dqs.total') {
							return canShowDataQualityScoreColumn;
						}
						return true;
					})
					.sort((a, b) => a.order - b.order),
				...defaultColumns
					.filter((column) =>
						isNil(
							_catalog.properties.find(
								(property) =>
									property.value === column.accessor ||
									property.value ===
										(column as ExtendedDataTableColumn<T>)?.esAccessor
							)
						)
					)
					.map((column, idx) => ({
						value: column.accessor as string,
						order:
							(maxBy(_catalog.properties, (el) => el.order)?.order ?? 0) +
							idx +
							1,
						hidden: false,
					})),
			],
		} as Catalog;
	}, [_catalog, defaultColumns, canShowDataQualityScoreColumn]);

	const onColumnReorder = useCallback(
		async (column: string | number, toIndex: number) => {
			if (!catalog) {
				return null;
			}

			const draft = cloneDeep(catalog.properties ?? []);

			if (typeof column === 'number') {
				// Reorder column by index.
				const [removed] = draft.splice(column, 1);
				draft.splice(toIndex, 0, removed);
			} else {
				// Reorder column by name.
				const [removed] = draft.splice(
					draft.findIndex((f) => f.value === column),
					1
				);
				draft.splice(toIndex, 0, removed);
			}

			// Update order of all properties.
			draft.forEach((draftProperty, index) => {
				draftProperty.order = index;
			});

			const result = await updateCatalogProperties({
				...catalog,
				properties: uniqBy(draft, (el) => el.value),
			});

			return result;
		},
		[catalog, updateCatalogProperties]
	);

	const onColumnVisibilityChange = useCallback(
		async (columnName: string, isVisible: boolean) => {
			if (!catalog) {
				return null;
			}

			const properties = produce(catalog?.properties ?? [], (draft) => {
				const index = draft.findIndex(({ value }) => value === columnName);
				draft[index].hidden = !isVisible;
			});

			const result = await updateCatalogProperties({
				...catalog,
				properties,
			});

			return result;
		},
		[catalog, updateCatalogProperties]
	);

	const onAddColumn = useCallback(
		async (columnName: string) => {
			if (!catalog) {
				return;
			}

			const properties = produce(catalog?.properties ?? [], (draft) => {
				draft.push({
					value: columnName,
					order: draft.length,
					hidden: false,
				});
			});

			await updateCatalogProperties({
				...catalog,
				properties,
			});
		},
		[catalog, updateCatalogProperties]
	);

	const switchView = useCallback(
		async (view: Catalog['view']) => {
			if (!catalog) {
				return;
			}

			await toggleCatalogView({
				...catalog,
				view,
			});

			await refetchCatalog();
		},
		[catalog, refetchCatalog, toggleCatalogView]
	);

	return {
		catalog,
		view: catalog?.view,
		switchView,
		onColumnReorder,
		onColumnVisibilityChange,
		onAddColumn,
	};
}
