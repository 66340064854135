import { observer } from 'mobx-react-lite';
import IncidentList from '../../MonitorListPage/IncidentList';

interface IIncidentsTabProps {
	tableId: string;
}

function IncidentsTab({ tableId }: IIncidentsTabProps) {
	return (
		<IncidentList
			defaultRequiredSearchParams={{
				target_id: tableId,
			}}
		/>
	);
}

export default observer(IncidentsTab);
