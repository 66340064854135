import { EmptyState } from './EmptyState/EmptyState';
import type { IllustrationNames } from './Illustration';
import { UpgradeButton } from './Settings/UpgradeButton';

export function AccessRestrictionMessage({
	message,
	feature,
	title,
	description,
	illustrationName,
}: {
	message: 'Upgrade' | 'Contact admin for access';
	feature: string;
	title?: string;
	description?: string;
	illustrationName?: IllustrationNames;
}) {
	const defaultTitle =
		message === 'Upgrade'
			? `Upgrade to access ${feature}`
			: `Contact admin for access to ${feature}`;

	const defaultDescription =
		message === 'Upgrade'
			? `Your current plan doesn't include access to ${feature}. Upgrade your plan to unlock this feature.`
			: `You don't currently have access to ${feature}. Contact your workspace administrator to request access.`;

	return (
		<EmptyState
			title={title || defaultTitle}
			description={description || defaultDescription}
			illustrationName={illustrationName || 'upgrade'}
			includeGoBack={false}
			stateHeight={message === 'Upgrade' ? '50vh' : '80vh'}
			size="lg"
			{...(message === 'Upgrade' && {
				withActions: (
					<UpgradeButton
						tooltip={`Upgrade to access ${feature}`}
						feature={feature}
						size="md"
					/>
				),
			})}
		/>
	);
}
