import { EntityType } from '@repo/common/enums/entityType';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import {
	useAuthUser,
	useJobRunsList,
	useJobTasksList,
	useSuspenseJob,
	useUpdateJob,
} from '../../api';
import Documentation from '../../components/Documentation';
import EntityDiscussions from '../../components/EntityDiscussions';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';
import EntityPageLayoutSkeleton from '../../components/EntityPageLayout/EntityPageLayoutSkeleton';
import { LineageGraphWrapper } from '../../components/LineageGraph/LineageGraph';
import type { OverviewPageTab } from '../../components/Overview/OverviewPageTabs';
import OverviewPageTabs from '../../components/Overview/OverviewPageTabs';
import { trackEvent } from '../../utils/analytics';
import { useTabNavigate } from '../../utils/hook/useTabNavigate';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import RunTable from './components/RunTable';
import TaskTable from './components/TaskTable';

const InnerJobPage = observer(({ id }: { id: string }) => {
	const { tab } = useParams();
	const { user, workspace } = useAuthUser();
	const navigateToTab = useTabNavigate();

	const { data: job } = useSuspenseJob({
		id,
	});

	useEffect(() => {
		trackEvent('job/open', {}, user, workspace);
	}, [user, workspace]);

	const { mutateAsync } = useUpdateJob({});

	const updateJob = useCallback(
		async (key: string, value: DjangoValueType, saveRemotely = true) => {
			if (saveRemotely) {
				await mutateAsync({
					data: {
						id: job!.id,
						[key]: value,
					},
				});
				trackEvent('job/properties/update', {}, user, workspace!);
			}
		},
		[mutateAsync, job, user, workspace]
	);

	if (!job) {
		return <EntityPageLayoutSkeleton />;
	}

	const { data: jobRuns, isLoading: isRunLoading } = useJobRunsList({
		filters: {
			parent_id: job.id,
			sort_by: '-run_finished_at',
		},
		options: {
			select: (res) => res.results,
		},
	});

	const { data: jobTasks, isLoading: isTaskLoading } = useJobTasksList({
		filters: {
			parent_id: job.id,
		},
		options: {
			select: (res) => res.results,
		},
	});

	const documentationFirst = (job.definition?.length || 0) > 2;

	const getDefaultTab = () => {
		if (documentationFirst) {
			return 'documentation';
		}
		return 'lineage';
	};

	const defaultTab = getDefaultTab();
	const currentTab = tab || defaultTab;

	const handleTabChange = useCallback(
		(updatedTab: string) => {
			if (updatedTab === 'lineage') {
				trackEvent('job/lineage/open', {}, user, workspace);
			}
			navigateToTab(updatedTab);
		},
		[navigateToTab, user, workspace]
	);

	const tabs: OverviewPageTab[] = useMemo(
		() => [
			{
				value: 'lineage',
				label: 'Lineage',
				component: (
					<LineageGraphWrapper
						id={job.id}
						entityType={job.entity_type}
						nativeType={job.native_type ?? ''}
						published={job.published}
					/>
				),
			},
			{
				value: 'runs',
				label: 'Runs',
				condition: !isRunLoading && jobRuns && jobRuns.length > 0,
				component: <RunTable job={job} />,
			},
			{
				value: 'tasks',
				label: 'Tasks',
				condition: !isTaskLoading && jobTasks && jobTasks.length > 0,
				component: <TaskTable job={job} />,
			},
			{
				value: 'discussions',
				label: 'Questions',
				component: <EntityDiscussions entity={job} />,
			},
			{
				value: 'documentation',
				label: 'Documentation',
				component: <Documentation entity={job} />,
			},
		],
		[job, isRunLoading, isTaskLoading, jobRuns, jobTasks]
	);

	if (documentationFirst) {
		const docTabIndex = tabs.findIndex((t) => t.value === 'documentation');
		if (docTabIndex !== -1) {
			const [docTab] = tabs.splice(docTabIndex, 1);
			tabs.unshift(docTab);
		}
	}

	return (
		<EntityPageLayout
			key={job.id}
			name="Job"
			isReadOnlyTitle
			entity={job}
			icon={<EntityLogo size={32} entity={job} />}
			updateEntity={updateJob}
			withDescription
			{...getEntityPageSidebarProps(EntityType.job)}
		>
			<OverviewPageTabs
				key={job.id}
				entity={job}
				currentTab={currentTab}
				tabs={tabs}
				onTabChange={handleTabChange}
			/>
		</EntityPageLayout>
	);
});

// By memoizing the inner component, we can avoid re-rendering the entire page on tab changes and only re-render the tab panel
export default function JobPage() {
	const id = useParamsIdSuffixUuid();

	return <InnerJobPage id={id} />;
}
