import { SELECTABLE_PROPERTY_OPTIONS } from '@repo/common/components/SelectableProperty/constants';
import type { IQuestion, ISecodaEntity } from '../../api';
import type { PropertySelectorProps } from './PropertySelector';
import { PropertySelector } from './PropertySelector';

export interface QuestionStatusPropertySelectorProps
	extends Pick<PropertySelectorProps, 'onChange'> {
	entity: ISecodaEntity;
	viewerOfEntity: boolean;
}

export function QuestionStatusPropertySelector({
	entity,
	viewerOfEntity,
	...propertySelectorProps
}: QuestionStatusPropertySelectorProps) {
	return (
		<PropertySelector
			selected={(entity as IQuestion).status}
			type="single"
			value="status"
			iconType="badge"
			isViewerUser={viewerOfEntity}
			readOnly={viewerOfEntity}
			options={SELECTABLE_PROPERTY_OPTIONS.status}
			{...propertySelectorProps}
		/>
	);
}
