import { createStyles, Tooltip } from '@mantine/core';
import { Button, Icon } from '@repo/foundations';
import { useIntercom } from 'react-use-intercom';
import { usePlan } from '../../../../hooks/usePlans';

const useStyles = createStyles((theme) => ({
	targetButton: {
		pointerEvents: 'auto',
		width: theme.other.space[7],
		height: theme.other.space[7],
		borderRadius: '50%',
		backgroundColor: theme.other.getColor('fill/secondary/default'),
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/secondary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/secondary/active'),
		},
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

export function HelpMenu() {
	const { classes } = useStyles();
	const { boot, show } = useIntercom();
	const { isPayingCustomer } = usePlan();

	// Only show help menu for paying customers
	if (!isPayingCustomer) {
		return null;
	}

	const handleHelpClick = async () => {
		await boot();
		show();
	};

	return (
		<Button className={classes.targetButton} onClick={handleHelpClick}>
			<Tooltip label="Help and resources">
				<Icon name="questionMark" size="md" />
			</Tooltip>
		</Button>
	);
}
