import {
	Box,
	createStyles,
	Flex,
	Group,
	Modal,
	Radio,
	Select,
} from '@mantine/core';
import { useApiGetMonitorPreviewMeasurements } from '@repo/api-codegen';
import { Banner, Button, Text, Title } from '@repo/foundations';
import { useState } from 'react';
import type { Monitor } from '../../../api';
import { MODEL_OPTIONS } from '../constants';
import MonitorTimeseriesChart from '../v2/MonitorTimeseriesChart';

interface ModelSelectorModalProps {
	opened: boolean;
	onClose: () => void;
	onSelectModel: (model: string, sensitivity: string) => void;
	monitor?: Monitor;
}

interface PreviewConfig {
	model: string;
	sensitivity: string;
	monitor?: Monitor;
}

function ModelPreview({ model, sensitivity, monitor }: PreviewConfig) {
	const { data: previewData, isLoading } = useApiGetMonitorPreviewMeasurements(
		{
			pathParams: {
				monitorId: monitor?.id ?? '',
			},
			queryParams: {
				algorithm: model,
				sensitivity: sensitivity as 'low' | 'medium' | 'high',
			},
		},
		{
			enabled: !!monitor?.id,
			cacheTime: 0,
			staleTime: 0,
			refetchOnWindowFocus: false,
		}
	);

	if (!monitor?.id) {
		return (
			<Box p="xl" style={{ textAlign: 'center' }}>
				<Text size="sm" color="text/secondary/default">
					Select a monitor to preview model behavior
				</Text>
			</Box>
		);
	}

	if (isLoading) {
		return (
			<Box p="xl" style={{ textAlign: 'center' }}>
				<Text size="sm" color="text/secondary/default">
					Loading preview...
				</Text>
			</Box>
		);
	}

	if (!previewData?.length) {
		return (
			<Box p="xl" style={{ textAlign: 'center' }}>
				<Text size="sm" color="text/secondary/default">
					No data available for preview
				</Text>
			</Box>
		);
	}

	// Transform the data to ensure upper_threshold and lower_threshold are always numbers
	const transformedData = previewData.map((measurement) => ({
		...measurement,
		upper_threshold: measurement.upper_threshold ?? 0,
		lower_threshold: measurement.lower_threshold ?? 0,
	}));

	return (
		<Box style={{ width: '100%', height: '100%' }}>
			<MonitorTimeseriesChart
				data={transformedData as any}
				metricType={monitor.metric_type}
				width="100%"
				height={500}
			/>
		</Box>
	);
}

const useStyles = createStyles((theme) => ({
	modalBody: {
		padding: 0,
	},
	modalContent: {
		position: 'relative',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: theme.spacing.sm,
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
	},
	closeButton: {
		position: 'absolute',
		top: theme.spacing.md,
		right: theme.spacing.md,
		cursor: 'pointer',
	},
	previewTitle: {
		marginBottom: theme.spacing.sm,
		fontWeight: 400,
	},
	modelOptionCard: {
		border: `1px solid ${theme.other.getColor('border/input/default')}`,
		borderRadius: theme.radius.md,
		padding: theme.spacing.md,
		marginBottom: theme.spacing.md,
		backgroundColor: theme.other.getColor('surface/primary/default'),
		position: 'relative',
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/primary/hover'),
		},
	},
	selectedCard: {
		border: `1px solid ${theme.other.getColor('fill/brand/default')} !important`,
	},
	modelContent: {
		marginLeft: theme.spacing.xl,
	},
	modelName: {
		color: theme.other.getColor('text/primary/default'),
		fontSize: theme.fontSizes.md,
		fontWeight: 600,
		lineHeight: 1,
	},
	modelDescription: {
		color: theme.other.getColor('text/secondary/default'),
		fontSize: theme.fontSizes.sm,
		lineHeight: 1.2,
	},
	recommendedLabel: {
		position: 'absolute',
		top: theme.spacing.md,
		right: theme.spacing.md,
	},
	infoIcon: {
		color: theme.other.getColor('icon/info/default'),
		marginRight: theme.spacing.xs,
	},
	infoBox: {
		backgroundColor: theme.other.getColor('surface/info/default'),
		padding: theme.spacing.md,
		borderRadius: theme.radius.md,
		marginBottom: theme.spacing.lg,
	},
	infoText: {
		color: theme.other.getColor('text/secondary/default'),
		fontSize: theme.fontSizes.sm,
	},
	sliderContainer: {
		marginTop: theme.spacing.xl,
		marginBottom: theme.spacing.xl,
	},
	sliderLabels: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing.xs,
	},
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: theme.spacing.md,
		borderTop: `1px solid ${theme.other.getColor('border/secondary/default')}`,
	},
	sensitivityLabel: {
		fontSize: theme.fontSizes.sm,
		fontWeight: 600,
		marginBottom: theme.spacing.xs,
	},
	leftPane: {
		width: '60%',
		padding: theme.spacing.xl,
	},
	rightPane: {
		width: '40%',
		borderLeft: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		padding: theme.spacing.xl,
	},
}));

function ModelSelectorModal({
	opened,
	onClose,
	onSelectModel,
	monitor,
}: ModelSelectorModalProps) {
	const { classes, cx } = useStyles();
	const [selectedModel, setSelectedModel] = useState<string>(
		monitor?.anomaly_detection_model || 'z_score'
	);

	const getSensitivityFromNumber = (value: number): string => {
		if (value <= 3) return 'low';
		if (value >= 7) return 'high';
		return 'medium';
	};

	const [sensitivity, setSensitivity] = useState<string>(
		getSensitivityFromNumber(monitor?.condition_auto_sensitivity || 5)
	);

	return (
		<Modal
			size="85%"
			opened={opened}
			onClose={onClose}
			withCloseButton={false}
			radius="lg"
			classNames={{
				body: classes.modalBody,
			}}
		>
			<Box className={classes.modalContent}>
				<Box className={classes.header}>
					<Title size="md">Select threshold model</Title>
					<Box className={classes.closeButton} onClick={onClose}>
						×
					</Box>
				</Box>

				<Flex gap="xl">
					<Box className={classes.leftPane}>
						<Box w="100%">
							<ModelPreview
								model={selectedModel}
								sensitivity={sensitivity}
								monitor={monitor}
							/>
						</Box>
					</Box>

					<Box className={classes.rightPane}>
						<Banner
							inCard={true}
							tone="info"
							message="Changes will only impact measurements going forward"
						/>

						<Title size="sm" mt="sm" className={classes.previewTitle}>
							Model
						</Title>

						<Radio.Group value={selectedModel} onChange={setSelectedModel}>
							{MODEL_OPTIONS.map((model) => (
								<Box
									key={model.id}
									className={cx(classes.modelOptionCard, {
										[classes.selectedCard]: selectedModel === model.id,
									})}
									onClick={() => setSelectedModel(model.id)}
								>
									<Radio
										value={model.id}
										label={model.name}
										description={model.description}
									/>
								</Box>
							))}
						</Radio.Group>

						<Box mt="xl">
							<Text className={classes.sensitivityLabel}>Sensitivity</Text>
							<Select
								value={sensitivity}
								onChange={(value) => setSensitivity(value || 'medium')}
								data={[
									{ value: 'low', label: 'Less' },
									{ value: 'medium', label: 'Default' },
									{ value: 'high', label: 'More' },
								]}
								styles={(theme) => ({
									input: {
										backgroundColor: theme.other.getColor(
											'surface/secondary/default'
										),
										border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
										'&:focus': {
											borderColor: theme.other.getColor(
												'border/primary/default'
											),
										},
									},
								})}
							/>
						</Box>
					</Box>
				</Flex>

				<Box className={classes.footer}>
					<Group spacing="sm">
						<Button onClick={onClose}>Cancel</Button>
						<Button
							variant="primary"
							onClick={() => onSelectModel(selectedModel, sensitivity)}
						>
							Save
						</Button>
					</Group>
				</Box>
			</Box>
		</Modal>
	);
}

export default ModelSelectorModal;
