import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { ISchema } from '../../types/models/schema';

const SCHEMA_NAMESPACE = ['table', 'schemas'];

export const schemasQueryKeyFactory = createQueryKeys(SCHEMA_NAMESPACE);

const { prefetchSchema, prefetchSchemaList } = prefetchFunctionsFactory(
	'schema',
	schemasQueryKeyFactory
);

const {
	useSchema,
	useSuspenseSchema,
	useSchemaInfiniteList,
	useSchemaList,
	useCreateSchema,
	useDeleteSchema,
	useUpdateSchema,
	fetchSchemaList,
	updateSchema,
} = baseQueryHooksFactory<ISchema, 'schema'>('schema', schemasQueryKeyFactory);

export {
	fetchSchemaList,
	prefetchSchema,
	prefetchSchemaList,
	updateSchema,
	useCreateSchema,
	useDeleteSchema,
	useSchema,
	useSchemaInfiniteList,
	useSchemaList,
	useSuspenseSchema,
	useUpdateSchema,
};
