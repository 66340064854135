import type { MetricType } from '@repo/api-codegen';
import { useApiListTeams } from '@repo/api-codegen';
import { createContext, useContext } from 'react';
import { useIntegrationSupport } from '../../api';
import { AddMonitorStore } from './store';

const AddMonitorStoreContext = createContext<AddMonitorStore | null>(null);

export interface AddMonitorStoreProviderProps {
	children: React.ReactNode;
	initialTable?: string;
	initialIntegration?: string;
	initialMetricType?: MetricType;
	initialSearchTerm?: string;
}

export function AddMonitorStoreProvider({
	children,
	initialIntegration,
	initialTable,
	initialMetricType,
	initialSearchTerm = '',
}: AddMonitorStoreProviderProps) {
	const { data: integrationSupport } = useIntegrationSupport();
	const { data: teams } = useApiListTeams(
		{
			queryParams: {
				only_joined: true,
			},
		},
		{
			select: (res) => res.results,
		}
	);
	return (
		<AddMonitorStoreContext.Provider
			value={
				new AddMonitorStore(
					initialTable,
					initialIntegration,
					initialMetricType,
					initialSearchTerm,
					integrationSupport,
					teams
				)
			}
		>
			{children}
		</AddMonitorStoreContext.Provider>
	);
}

export function useAddMonitorStoreContext() {
	const ctx = useContext(AddMonitorStoreContext);
	if (ctx === null) {
		throw new Error(
			'useFormContext was called outside of FormProvider context'
		);
	}

	return ctx;
}
