import { Box, createStyles, Group, Menu } from '@mantine/core';
import type { Filter as ApiFilter } from '@repo/api-codegen';
import {
	AddFilter,
	Filter,
	FILTER_OPTIONS_DIVIDER,
	FilterOptionType,
	getFilterValueFromApiCatalogFilter,
	OPERATORS_CONFIG,
} from '@repo/common/components/Filter';
import { IconButton, Text } from '@repo/foundations';
import { noop } from 'lodash-es';
import { reaction, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import {
	SearchFilterV2Store,
	SearchFilterV2StoreContext,
} from '../../../components/Filter';
import { FILTER_OPTIONS_CONFIG } from '../../../components/Filter/constants';

const useStyles = createStyles((theme) => ({
	conditionBox: {
		backgroundColor: theme.other.getColor('fill/primary/hover'),
		borderRadius: theme.radius.md,
		padding: theme.spacing.md,
		width: '100%',
	},
	conditionTitle: {
		fontSize: theme.fontSizes.sm,
		marginBottom: theme.spacing.sm,
	},
	operatorButton: {
		backgroundColor: theme.other.getColor('fill/secondary/hover'),
		padding: `0 ${theme.spacing.xs}`,
		borderRadius: theme.radius.sm,
		cursor: 'pointer',
		display: 'inline-flex',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/secondary/active'),
		},
	},
}));

interface FilterGroupProps {
	onConditionDelete: () => void;
	readOnly?: boolean;
	groupText?: string;
	addFilterLabelText?: string;
}

const FilterGroup = observer(
	({
		onConditionDelete,
		readOnly = false,
		groupText = 'Resources that match ',
		addFilterLabelText = 'Add filter',
	}: FilterGroupProps) => {
		const { classes } = useStyles();
		const store = useContext(SearchFilterV2StoreContext);

		return (
			<Box className={classes.conditionBox}>
				<Group position="apart">
					<Text className={classes.conditionTitle}>
						{groupText}
						<Menu position="bottom">
							<Menu.Target>
								<Box
									component="span"
									className={classes.operatorButton}
									onClick={(e) => {
										if (readOnly) {
											e.stopPropagation();
											e.preventDefault();
										}
									}}
								>
									{store.topLevelOperator === 'or' ? 'any' : 'all'}
								</Box>
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item onClick={() => store.setTopLevelOperator('and')}>
									all
								</Menu.Item>
								<Menu.Item onClick={() => store.setTopLevelOperator('or')}>
									any
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>{' '}
						of the following
					</Text>
					{!readOnly && (
						<IconButton
							iconName="x"
							onClick={onConditionDelete}
							variant="tertiary"
							tooltip="Remove condition"
						/>
					)}
				</Group>
				<Group spacing="2xs">
					{store.values.map((value, idx) => (
						<Filter
							// eslint-disable-next-line react/no-array-index-key
							key={`filter-${idx}`}
							value={value}
							filterOption={FILTER_OPTIONS_CONFIG[value.filterType]}
							onChange={!readOnly ? store.onChangeValue(idx) : noop}
							onClear={!readOnly ? store.onClearValue(idx) : undefined}
							showDetailedLabel
							operatorConfig={
								OPERATORS_CONFIG[
									FILTER_OPTIONS_CONFIG[value.filterType].filterDropdownConfig
										.dropdownType
								]
							}
						/>
					))}
					{!readOnly && (
						<AddFilter
							options={toJS(store.filterOptions)}
							onAddFilter={store.onAddValue}
							labelText={addFilterLabelText}
						/>
					)}
				</Group>
			</Box>
		);
	}
);

interface WrappedFilterGroupProps extends FilterGroupProps {
	condition: ApiFilter;
	onConditionChange: (filter: ApiFilter) => void;
	isResource?: boolean;
}

const WrappedFilterGroup = observer(
	({
		condition,
		onConditionChange,
		onConditionDelete,
		readOnly,
		groupText,
		addFilterLabelText,
		isResource = false,
	}: WrappedFilterGroupProps) => {
		const [store, setStore] = useState<SearchFilterV2Store | null>(null);

		useEffect(() => {
			async function initializeStore() {
				const options = [
					FilterOptionType.NATIVE_TYPE,
					FilterOptionType.INTEGRATION,
					FilterOptionType.INTEGRATION_TYPE,
					FILTER_OPTIONS_DIVIDER,
					...(isResource
						? [
								FilterOptionType.TABLE,
								FilterOptionType.SCHEMA,
								FilterOptionType.DATABASE,
								FilterOptionType.SOURCES,
								FILTER_OPTIONS_DIVIDER,
							]
						: []),
					FilterOptionType.TITLE,
					FilterOptionType.DESCRIPTION,
					FilterOptionType.TAGS,
					FilterOptionType.PUBLISHED,
					FilterOptionType.COLLECTIONS,
					FilterOptionType.OWNERS,
					FilterOptionType.PII,
					FilterOptionType.VERIFICATION,
					FilterOptionType.TEAMS,
					FilterOptionType.RELATED,
					FilterOptionType.DATA_QUALITY,
					FILTER_OPTIONS_DIVIDER,
					FilterOptionType.CREATED_TIME,
					FilterOptionType.UPDATED_TIME,
					FilterOptionType.EXTERNALLY_UPDATED_TIME,
				].map((option) =>
					option === FILTER_OPTIONS_DIVIDER
						? FILTER_OPTIONS_DIVIDER
						: FILTER_OPTIONS_CONFIG[option]
				);

				const initialValues = await getFilterValueFromApiCatalogFilter(
					options,
					condition
				);

				setStore(
					new SearchFilterV2Store({
						filterOptions: options,
						initialValues,
					})
				);
			}

			initializeStore();
			// We just need to initialize the store once
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		useEffect(() => {
			reaction(
				() => store?.catalogFilter,
				(filter) => {
					if (filter) {
						onConditionChange(filter);
					}
				}
			);
		}, [onConditionChange, store]);

		if (!store) return null;

		return (
			<SearchFilterV2StoreContext.Provider value={store}>
				<FilterGroup
					onConditionDelete={onConditionDelete}
					readOnly={readOnly}
					groupText={groupText}
					addFilterLabelText={addFilterLabelText}
				/>
			</SearchFilterV2StoreContext.Provider>
		);
	}
);

export default WrappedFilterGroup;
