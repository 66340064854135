import { Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { useAuthUser, type ISecodaEntity } from '../../../../api';
import type { SecodaEntity } from '../../../../lib/models';
import { trackEvent } from '../../../../utils/analytics';
import { useFeatureFlags } from '../../../../utils/featureFlags';
import { openModal } from '../../../ModalManager';
import { DefinitionSnapshots } from '../DefinitionSnapshots';

interface SnapshotsActionProps {
	entity: SecodaEntity | ISecodaEntity;
}

export function SnapshotsAction({ entity }: SnapshotsActionProps) {
	const { user, workspace } = useAuthUser();
	const { feActivityLog } = useFeatureFlags();

	const handleClickSnapshots = () => {
		trackEvent(`${entity.entity_type}/snapshots/click`, {}, user, workspace);

		openModal({
			size: 1200,
			title: entity.title_cased ?? entity.title ?? 'Untitled',
			children: <DefinitionSnapshots entity={entity} />,
		});
	};

	if (feActivityLog) {
		return null;
	}

	return (
		<Menu.Item
			data-testid="entity-actions-menu-item-snapshots"
			icon={<Icon name="clockPlay" />}
			onClick={handleClickSnapshots}
		>
			View snapshots
		</Menu.Item>
	);
}
