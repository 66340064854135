import { Group, Table, ThemeIcon, useMantineTheme } from '@mantine/core';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { Badge, Icon, Text } from '@repo/foundations';
import { useExtendedUserList } from '../../../../api/hooks/user/useExtendedUserList';
import { sanitizePromptMessage } from '../../../../components/AIAssistant/utils';
import type { TableComponentProps } from './MetricWidgetTable';
import { UserCell } from './UserCell';

function MetricAIQueryTable({
	values,
	withUser,
}: TableComponentProps & { withUser: boolean }) {
	const theme = useMantineTheme();
	const { users } = useExtendedUserList();
	const findUserById = (id: string) => users.find((u) => u.id === id);

	const navigateHandler = useNavigateHandler();

	const formatDate = (dateString: string | number) => {
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.toLocaleString('default', { month: 'long' });
		const year = date.getFullYear();
		return `${day} ${month} ${year}`;
	};

	return (
		<Table highlightOnHover width={600}>
			<thead>
				<tr>
					<th style={{ width: '45%' }}>
						<Text color="text/secondary/default" weight="semibold" size="xs">
							Prompt
						</Text>
					</th>
					{withUser && (
						<th style={{ width: '20%' }}>
							<Text color="text/secondary/default" weight="semibold" size="xs">
								User
							</Text>
						</th>
					)}
					<th style={{ width: withUser ? '15%' : '35%' }}>
						<Text color="text/secondary/default" weight="semibold" size="xs">
							Created At
						</Text>
					</th>
					<th style={{ width: '10%' }}>
						<Text color="text/secondary/default" weight="semibold" size="xs">
							Status
						</Text>
					</th>
					<th style={{ width: '10%' }}>
						<Text color="text/secondary/default" weight="semibold" size="xs">
							Score
						</Text>
					</th>
				</tr>
			</thead>
			<tbody>
				{values.map((prompt) => (
					<tr
						style={{
							cursor: 'pointer',
						}}
						key={prompt.id}
						onClick={(e) => navigateHandler(e)(`/ai/${prompt.id}`)}
					>
						<td>
							<Group noWrap>
								<ThemeIcon
									color={theme.other.getColor('fill/secondary/default')}
									size={32}
									radius={32}
								>
									<Icon name="sparkles" color="icon/primary/default" />
								</ThemeIcon>
								<Text
									lineClamp={1}
									color="text/primary/default"
									size="sm"
									weight="semibold"
								>
									{sanitizePromptMessage(String(prompt.prompt))}
								</Text>
							</Group>
						</td>
						{withUser && (
							<td>
								{findUserById(String(prompt.user)) && (
									<UserCell user={findUserById(String(prompt.user))} />
								)}
							</td>
						)}
						<td>
							<Text color="text/secondary/default" size="xs">
								{formatDate(prompt.created_at)}
							</Text>
						</td>
						<td>
							{prompt.status &&
								(prompt.status === 'completed' ? (
									<Badge variant="success">Completed</Badge>
								) : (
									<Badge variant="warning">Failed</Badge>
								))}
						</td>
						<td>
							{prompt.benchmark_raw && (
								<Text color="text/secondary/default" size="xs">
									{prompt.score}/10
								</Text>
							)}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
}

export function MetricAIQueryTableWithUsers({ values }: TableComponentProps) {
	return <MetricAIQueryTable values={values} infoColumns={[]} withUser />;
}

export function MetricAIQueryTableWithoutUsers({
	values,
}: TableComponentProps) {
	return (
		<MetricAIQueryTable values={values} infoColumns={[]} withUser={false} />
	);
}
