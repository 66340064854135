import { ActionIcon, Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import type { IWidget } from '../../../../../../../api';
import { WidgetSize } from '../../../../../../../interfaces';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';
import {
	isFullSizeWidget,
	isHalfSizeWidget,
	isTextWidget,
} from '../../../utils';
import type { IWidgetColorMenuProps } from './WidgetColorMenu';
import WidgetColorMenu from './WidgetColorMenu';

interface IWidgetMenuProps extends Partial<IWidgetColorMenuProps> {
	widgetType: IWidget['type'];
	size: WidgetSize;
	color: IWidget['color'];
	onSizeChange: (size: WidgetSize) => VoidFunction;
	onRemoveWidget: VoidFunction;
}

function WidgetMenu({
	widgetType,
	size,
	color,
	onSizeChange,
	onRemoveWidget,
	onColorChange,
}: IWidgetMenuProps) {
	const { isTeamViewerUser } = useHomepageTeam();

	if (isTeamViewerUser) {
		return null;
	}

	return (
		<Menu shadow="md" width={220} position="bottom-end" withinPortal>
			<Menu.Target>
				<ActionIcon size="sm">
					<Icon name="dots" />
				</ActionIcon>
			</Menu.Target>

			<Menu.Dropdown>
				<Menu.Item
					disabled={isHalfSizeWidget(size)}
					rightSection={
						isHalfSizeWidget(size) && (
							<Icon name="check" color="icon/primary/default" />
						)
					}
					icon={<Icon name="viewportNarrow" />}
					onClick={onSizeChange(WidgetSize.MEDIUM)}
				>
					Half size
				</Menu.Item>
				<Menu.Item
					disabled={isFullSizeWidget(size)}
					rightSection={
						isFullSizeWidget(size) && (
							<Icon name="check" color="icon/primary/default" />
						)
					}
					icon={<Icon name="viewportWide" />}
					onClick={onSizeChange(WidgetSize.FULL)}
				>
					Full size
				</Menu.Item>
				<Menu.Divider />
				{isTextWidget(widgetType) && onColorChange && (
					<>
						<WidgetColorMenu color={color} onColorChange={onColorChange} />
						<Menu.Divider />
					</>
				)}
				<Menu.Item
					color="fill/critical/default"
					icon={<Icon name="trash" />}
					onClick={onRemoveWidget}
				>
					Remove widget
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}

export default WidgetMenu;
