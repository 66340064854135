import { useForm } from '@mantine/form';
import type { IAMRoleIn, IAMRoleOut } from '@repo/api-codegen';
import { useEffect } from 'react';
import { MINIMUM_REQUIRED_PERMISSIONS } from '../../constants';

export const useIamRoleForm = (iamRole?: IAMRoleOut) => {
	// Ensure the permissions from the backend include the minimum required ones
	const initialPermissions = iamRole?.permissions || [];
	MINIMUM_REQUIRED_PERMISSIONS.forEach((permission) => {
		if (!initialPermissions.includes(permission)) {
			initialPermissions.push(permission);
		}
	});

	const form = useForm<IAMRoleIn>({
		initialValues: {
			description: iamRole?.description || '',
			name: iamRole?.name || '',
			permissions: initialPermissions,
			resources_filter_configs: iamRole?.resources_filter_configs || [],
		},
		validate: {
			name: (value) => {
				if (value) {
					// Prevents reserved names from being used/hacked in with trailing or leading spaces.
					const normalizedValue = value.trim().toLowerCase();
					const reservedNames = ['admin', 'editor', 'viewer', 'guest'];

					if (reservedNames.includes(normalizedValue)) {
						return 'Name is reserved';
					}
				}
				return !value || value === '' ? 'Name is required' : null;
			},
			description: (value) =>
				!value || value === '' ? 'Description is required' : null,
		},
		validateInputOnBlur: true,
	});

	// Ensure minimum permissions are enforced whenever form values change
	useEffect(() => {
		const currentPermissions = form.values.permissions;
		let needsUpdate = false;

		MINIMUM_REQUIRED_PERMISSIONS.forEach((permission) => {
			if (!currentPermissions.includes(permission)) {
				currentPermissions.push(permission);
				needsUpdate = true;
			}
		});

		if (needsUpdate) {
			form.setFieldValue('permissions', [...currentPermissions]);
		}
	}, [form.values.permissions]);

	return form;
};
