import { Box, Drawer } from '@mantine/core';
import { Text } from '@repo/foundations';
import { orderBy } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type { IDatabuilderJob, IIntegration } from '../../../api';
import { useIntegrationSpec } from '../../../api';
import { DatabuilderJobType } from '../../../interfaces';
import { sentenceCase } from '../../../utils/stringUtils';
import { hashCode } from '../../../utils/utils';
import { ErrorLogs } from '../../ErrorLogs/ErrorLogs';
import { IntegrationHelp } from '../IntegrationHelp';
import { ExtractionStagesTable } from './ExtractionStagesTable';
import { ExtractionSummary } from './ExtractionSummary';

interface ExtractionDetailProps {
	integration: IIntegration;
	job: IDatabuilderJob;
	onClose: () => void;
}

export const ExtractionDetail = observer(
	({ integration, job, onClose }: ExtractionDetailProps) => {
		const spec = useIntegrationSpec(integration.id);

		// Find the first failed stage with logs
		const firstFailedStage = job.stages?.find(
			(stage) => stage.stage && stage.failed
		);

		return (
			<Drawer
				opened
				onClose={onClose}
				position="right"
				title={
					<Text size="md" weight="bold">
						Sync stages
					</Text>
				}
			>
				<Box p={4}>
					<ExtractionStagesTable
						// Only include stages that include a `stage` title.
						stages={orderBy(
							(job.stages ?? []).filter((stage) => stage.stage),
							(s) => s.created_at
						).map((stage) => ({
							...stage,
							stage: sentenceCase(stage.stage),
						}))}
						isJobTerminated={job.terminated}
					/>
					<Box mb={4} />
					{firstFailedStage && (
						<Box key={hashCode(firstFailedStage.id)}>
							<ErrorLogs
								title={firstFailedStage.logs.split('\n')[0] || 'Runtime Error'}
								logs={firstFailedStage.logs.split('\n').slice(1)}
							/>
							{spec && <IntegrationHelp spec={spec} />}
						</Box>
					)}
					{!job.failed &&
						(job.type === DatabuilderJobType.METADATA ||
							job.type === DatabuilderJobType.DUMMY) && (
							<ExtractionSummary databuilderJobSummary={job.summary} />
						)}
				</Box>
			</Drawer>
		);
	}
);
