import axios from 'axios';
import { api } from '../../../network.ts';
import { REDIRECT_FROM_AUTH_PATH_KEY } from '../../pages/RootPage/RootAuthRedirect.tsx';
import {
	loginRequest,
	msalInstance,
} from '../../utils/authentication/azure/authConfig.ts';

const handleAuthenticationResponse = (
	navigate: (arg0: string) => void,
	authResponse: Record<string, string>
) => {
	if (authResponse.id) {
		localStorage.setItem('id', authResponse.id);
		if (authResponse.workspace_id) {
			const redirectPath =
				localStorage.getItem(REDIRECT_FROM_AUTH_PATH_KEY) ?? '/';
			localStorage.removeItem(REDIRECT_FROM_AUTH_PATH_KEY);
			navigate(redirectPath);
		} else {
			navigate('/onboarding/profile');
		}
	}
	return false;
};

export const handleGoogleCallback = (
	navigate: (arg0: string) => void,
	googleAccessToken: string,
	csrfState: string,
	onError: (error: Error) => void
) => {
	axios
		.post(`${api()}auth/social/google/`, {
			access_token: googleAccessToken,
			csrf_state: csrfState,
		})
		.then((response) => {
			const { data: authResult } = response;
			if (authResult.redirect_to) {
				window.open(authResult.redirect_to, '_self');
				return;
			}
			handleAuthenticationResponse(navigate, response.data);
		})
		.catch((error) => {
			onError(error);
		});
};

export const handleMicrosoftCallback = async (
	navigate: (arg0: string) => void,
	onError: (error: Error) => void
) => {
	const msalRequest = {
		...loginRequest,
		account: msalInstance.getAllAccounts()[0],
	};
	const microsoftAuthResult =
		await msalInstance.acquireTokenSilent(msalRequest);
	if (microsoftAuthResult !== null) {
		const { accessToken: microsoftAccessToken } = microsoftAuthResult;
		axios
			.post(`${api()}auth/social/microsoft/`, {
				access_token: microsoftAccessToken,
			})
			.then((response) => {
				const { data: authResult } = response;
				if (authResult.redirect_to) {
					window.open(authResult.redirect_to, '_self');
					return;
				}
				handleAuthenticationResponse(navigate, response.data);
			})
			.catch((error) => {
				onError(error);
			});
	}
};
