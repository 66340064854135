import { useHotkeys } from '@mantine/hooks';
import { useKeyPress } from 'ahooks';
import { isNil, uniqBy } from 'lodash-es';
import { useCallback } from 'react';
import { queryClient, useAuthUser, type IBaseModel } from '../../api';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
import entityDrawerStore from '../EntityDrawer/store';

function getHoveredId() {
	const hoveredId = document.body.getAttribute('table-hovered-data-id');
	return hoveredId;
}

export function useTableShortcuts<T extends IBaseModel>({
	results,
	selectedRecordsState,
	setSelectedRecordsState,
}: {
	results: T[];
	selectedRecordsState: {
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	};
	setSelectedRecordsState: React.Dispatch<
		React.SetStateAction<{
			selectedRecords: T[];
			lastSelectedIndex: number | null;
		}>
	>;
}) {
	const { isViewerOrGuestUser, isEditorOrAdminUser } = useAuthUser();

	useKeyPress(['esc'], () => {
		setSelectedRecordsState({ selectedRecords: [], lastSelectedIndex: null });
	});

	useKeyPress(['meta.a'], (e) => {
		if (isViewerOrGuestUser) {
			return;
		}

		// Only select all if we are hovering over the table.
		if (isNil(getHoveredId())) {
			return;
		}

		e.preventDefault();
		if (selectedRecordsState.selectedRecords.length === results?.length) {
			setSelectedRecordsState({ selectedRecords: [], lastSelectedIndex: null });
		} else {
			setSelectedRecordsState((currentState) => ({
				selectedRecords: results ?? [],
				lastSelectedIndex: currentState.lastSelectedIndex,
			}));
		}
	});

	useKeyPress(['meta.s'], (e) => {
		const hoveredId = getHoveredId();
		const hoveredEntity = results?.find((entity) => entity.id === hoveredId);
		if (hoveredEntity || selectedRecordsState.selectedRecords.length === 1) {
			const entity = hoveredEntity ?? selectedRecordsState.selectedRecords[0];
			e.preventDefault();
			if ('entity_type' in entity) {
				entityDrawerStore.openEntityDrawerById(
					isEditorOrAdminUser,
					entity.id,
					undefined,
					() => {
						queryClient.invalidateQueries(
							resourceCatalogQueryKeyFactory.allLists()
						);
					}
				);
			}
		}
	});

	const selectHoveredItem = useCallback(
		(e: KeyboardEvent) => {
			if (isViewerOrGuestUser) {
				return;
			}

			const hoveredId = getHoveredId();
			if (hoveredId) {
				e.preventDefault();
			}

			const hoveredIndex = results?.findIndex(
				(entity) => entity.id === hoveredId
			);
			if (hoveredIndex === -1) {
				return;
			}

			const found = results[hoveredIndex];
			if (found) {
				if (
					selectedRecordsState.selectedRecords.some(
						(record) => record.id === hoveredId
					)
				) {
					setSelectedRecordsState((currentState) => ({
						selectedRecords: currentState.selectedRecords.filter(
							(record) => record.id !== hoveredId
						),
						lastSelectedIndex: hoveredIndex,
					}));
				} else {
					setSelectedRecordsState((currentState) => ({
						selectedRecords: uniqBy(
							[...currentState.selectedRecords, found],
							'id'
						),
						lastSelectedIndex: hoveredIndex,
					}));
				}
			}
		},
		[
			isViewerOrGuestUser,
			results,
			selectedRecordsState.selectedRecords,
			setSelectedRecordsState,
		]
	);

	useHotkeys([['x', selectHoveredItem]]);

	useKeyPress(
		['shift'],
		(e) => {
			if (e.key === 'Shift') {
				selectHoveredItem(e);
				e.preventDefault();
			}
			return;
		},
		{
			// Only trigger on keydown, not while holding
			events: ['keydown'],
		}
	);
}
