import { showNotification } from '@mantine/notifications';
import { Icon } from '@repo/foundations';
import type { MutationFunction } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { documentsQueryKeyFactory } from '.';
import type { SecodaEntity } from '../../../lib/models';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import type { ISecodaEntity, Namespace } from '../../types';

async function createDuplicateResult(entity: SecodaEntity | ISecodaEntity) {
	const url = getEndpoints(documentsQueryKeyFactory.namespace).byPath([
		entity.id,
		'duplicate',
	]);

	const response = await apiClient.post<SecodaEntity>(url);

	return response.data;
}

/**
 * A react-query mutation hook that can be used to duplicate a Secoda entity.
 *
 * @returns Returns a react-query mutation hook that can be used to duplicate a Secoda entity
 */
export function useDuplicateDocument({
	onSuccess,
}: {
	namespace?: Namespace;
	onSuccess: (data: SecodaEntity) => void;
}) {
	const mutationFn: MutationFunction<
		SecodaEntity,
		{ entity: SecodaEntity | ISecodaEntity }
	> = async ({ entity }) => createDuplicateResult(entity);

	return useMutation({
		mutationFn,
		onError: () => {
			showNotification({
				title: 'Error duplicating document',
				message: 'If the issues persists, please contact support',
				color: 'red',
				icon: <Icon name="alertCircle" />,
			});
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({
				queryKey: documentsQueryKeyFactory.allLists(),
			});
			onSuccess(data);
		},
	});
}
